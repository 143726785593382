<!-- eslint-disable vue/no-v-html -->
<template>
    <div 
        :id="id"
        :data-page-id="id"
        class="business-data-transaction"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'transactions' }">
                    {{ $t('transactions.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('detailFamilyTree.breadcrumb') }}</li>
        </Breadcrumb>
        
        <InfoBoxDisableMessage />

        <!-- [ report title header ]-->
        <div class="container ">
            <LoadingRing v-if="showLoadingSpinner" />

            <div class="contentHead">
                <div class="row">
                    <div class="col-md-8 col-12">
                        <div
                            v-if="!showLoadingSpinner"
                            class="h1 contentHead__title"
                        >
                            <font-awesome-icon
                                :icon=" favourite ? 'fa-solid fa-star': 'fa-light fa-star' "
                                size="sm"
                                data-qa="inkasso-favourite"
                                class="cursor-pointer"
                                @click="toggleFavouriteTransaction"
                            />
                            <span
                                data-qa="businessName"
                                class="ml"
                            >{{ businessName }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- [ /report title header ]-->

        <div
            v-if="!showLoadingSpinner"
            class="contentMain bg-brand-light-grey box-spacing"
        >
            <div class="container business-header">
                <div class="row">
                    <div
                        class="col-12 col-md-4 box--product-data"
                        data-qa="processBox_2"
                    >
                        <div class="box-shadow-xy small-box-spacing bg-white h-100">
                            <h3>{{ $t('transaction.show.orderData') }}</h3>
                            <div class="box--product-data__container">
                                <div
                                    v-if="productType"
                                    class="box--product-data__container--flex"
                                >
                                    <span class="box--header-data__label box--header-data__label--width-increased">
                                        {{ $t('transaction.show.product') }}
                                    </span>
                                    <span
                                        class="box--header-data__value"
                                        data-qa="process-productType"
                                    >{{ productType }}</span>
                                </div>
                                <div class="pt-small box--product-data__container--flex">
                                    <span class="box--header-data__label box--header-data__label--width-increased">
                                        {{ $t('transaction.show.fetchDate') }}
                                    </span>
                                    <span
                                        class="box--header-data__value"
                                        data-qa="process-infoBoughtLastAt"
                                    >
                                        {{ formatDate(infoBoughtLastAt) }}
                                    </span>
                                </div>
                                <div
                                    v-if="referenceNumber"
                                    class="pt-small box--product-data__container--flex"
                                >
                                    <span class="box--header-data__label box--header-data__label--width-increased">
                                        {{ $t('shared.reference') }}
                                    </span>
                                    <span
                                        class="box--header-data__value"
                                        data-qa="process-referenceNumber-Value"
                                    >
                                        {{ referenceNumber }}
                                    </span>
                                </div>
                                <div
                                    v-if="productOrderId"
                                    class="pt-small box--product-data__container--flex"
                                >
                                    <span class="box--header-data__label box--header-data__label--width-increased">
                                        {{ $t('transaction.show.orderNumber') }}
                                    </span>
                                    <span
                                        class="box--header-data__value"
                                        data-qa="process-productOrderId"
                                    >
                                        {{ productOrderId }}
                                    </span>
                                </div>

                                <div
                                    v-if="orderName"
                                    class="pt-small box--product-data__container--flex"
                                >
                                    <span
                                        class="box--header-data__label box--header-data__label--width-increased"
                                    >
                                        {{ $t('transaction.list.transaction_getby') }}
                                    </span>
                                    <span
                                        class="box--header-data__value"
                                        data-qa="process-orderName"
                                    >
                                        {{ helpers.sbb(t, orderName) }}
                                    </span>
                                </div>
                            </div>

                            <div class="mt clearfix d-flex">
                                <span class="box--header-data__label box--header-data__label--width-increased">
                                    {{ $t('shared.myNote') }}
                                </span>
                                <div
                                    class="box--header-data__value mr"
                                >
                                    <span
                                        v-if="!editMode && myNote"
                                        data-qa="process-myNote-Text"
                                        v-html="helpers.nl2br(myNote)"
                                    />
                                    <Textarea
                                        v-if="editMode"
                                        v-model="myNote"
                                        label=""
                                        name="additionalInfos"
                                        data-qa="process-myNote-Input"
                                        label-hidden
                                    />
                                </div>
                                <span
                                    v-if="!editMode"
                                    :data-qa="isDisabled ? 'process-myNote-Button-disabled': 'process-myNote-Button'"
                                    class="business-data-transaction__editButton"
                                    :class="{'glyphicon-pencil--disabled': isDisabled}"
                                    @click="startEditMode"
                                >
                                    <font-awesome-icon
                                        icon="fa-light fa-pencil"
                                    />
                                </span> 
                                <span
                                    v-if="editMode"
                                    data-qa="process-myNote-Button"
                                    class="business-data-transaction__editButton"
                                    @click="saveMyNote"
                                >
                                    <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                                </span> 
                            </div>
                            <div
                                v-if="!validation"
                                class="validationMessage font-color--red"
                                data-qa="process-myNote-ValidationMessage"
                            >
                                {{ $t('transaction.report.validation.message') }}
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-12 col-md-8 box--business-data"
                        data-qa="processBox_1"
                    >
                        <div class="box-shadow-xy small-box-spacing bg-white h-100">
                            <h3>
                                {{ $t('transaction.chapter.FIRMENIDENTIFIKATION') }}
                            </h3>

                            <WatchlistIcon
                                v-if="businessId && isDAL"
                                class="text-align--right watchlist-icon"
                                :business-id="businessId"
                                :is-dal="true"
                                :use-store="true"
                            />
                            <WatchlistIcon
                                v-if="businessId && !isDAL"
                                class="text-align--right watchlist-icon"
                                :business-id="businessId"
                                :is-dal="false"
                                :use-store="true"
                            />
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div
                                        v-if="postalAddress"
                                        class="box--business-data__address-wrapper d-flex gap"
                                    >
                                        <font-awesome-icon
                                            icon="fa-solid fa-location-dot"
                                            class="location-icon"
                                        />
                                        <address class="box--business-data--address">
                                            <span
                                                class="box--header-data__value"
                                                data-qa="process-postalAddressStreet-Value"
                                            >
                                                {{ postalAddress.street }}&nbsp;</span>
                                            <span
                                                class="box--header-data__value"
                                                data-qa="process-postalAddressHouseNumber-Value"
                                            >{{ postalAddress.houseNumber }}&nbsp;</span>
                                            <span
                                                class="box--header-data__value"
                                                data-qa="process-postalAddressHouseNumberSuffix-Value"
                                            >{{ postalAddress.houseNumberSuffix }}</span><br>
                                            <span
                                                class="box--header-data__value"
                                                data-qa="process-postalAddressPostalCode-Value"
                                            >{{ postalAddress.zipCode }}&nbsp;</span>
                                            <span
                                                class="box--header-data__value"
                                                data-qa="process-postalAddressCity-Value"
                                            >{{ postalAddress.city }}</span>
                                            <br><span
                                                class="box--header-data__value"
                                                data-qa="process-postalAddressCountry-Value"
                                            >{{ postalAddress.country }}</span>
                                        </address>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="mt">
                                        <div>
                                            <template v-if="isDAL">
                                                <span class="box--header-data__label box--header-data__label--width-increased">
                                                    {{ $t('shared.businessId') }}
                                                </span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="businessId"
                                                >
                                                    {{ businessIdDisplay }}
                                                </span>
                                            </template>

                                            <template v-if="!isDAL">
                                                <span class="box--header-data__label box--header-data__label--width-increased">
                                                    {{ $t('search.extended.identnumber') }}
                                                </span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="identnumber"
                                                >
                                                    {{ businessIdDisplay }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container small-content-spacer">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <div class="crefo-ui-alert info info-box m0">
                            <p>
                                {{ $t('transaction.familytree.teaser1part1') }}
                                <a 
                                    :class="{'textlink--white--disabled': isDisabled}"
                                    :data-qa="isDisabled ? 'link-buy-a-report-disabled' : 'link-buy-a-report'"
                                    :href="isDisabled ? 'javascript: void(0);' : '/bonitaetsauskunft/bonitaet-einer-firma-pruefen/detailseite-unternehmen/?businessId=' + businessId + '&country=' + countryCode"
                                >
                                    {{ $t('transaction.familytree.teaser1part2') }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="showHistory"
                class="container small-content-spacer"
            >
                <div class="flexbox flexbox-sm--column flexbox-xs--column">
                    <div class="col-12 box-shadow-xy small-box-spacing bg-white box--history-data">
                        <h3>{{ $t('transaction.ShowFamilyTree.history') }}</h3>
                        <ul
                            class="list-unordered list-timeline"
                            data-qa="family-tree-history-container"
                        >
                            <template v-if="history">
                                <li
                                    v-for="(historyEntry, index) of history.historyEntries"
                                    :key="index"
                                >
                                    <span class="dot" />
                                    <span class="flexbox">
                                        <a
                                            v-if="historyEntry.businessName && historyEntry.postalAddress"
                                            data-qa="family-tree-history-name"
                                            :href="'/bonitaetsauskunft/bonitaet-einer-firma-pruefen/detailseite-unternehmen/?businessId=' + historyEntry.businessId + '&country=' + historyEntry.postalAddress.country"
                                        >{{ historyEntry.businessName }}</a>
                                        <span
                                            v-if="!historyEntry.businessName"
                                            data-qa="family-tree-history-name"
                                        >{{ historyEntry.givenName + ' ' + historyEntry.surName }}</span>
                                        <span
                                            v-if="historyEntry.postalAddress"
                                            data-qa="family-tree-history-city"
                                        >{{ ', ' + historyEntry.postalAddress.city }}</span>
                                    </span>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <Modal
        v-if="modal"
        :id="modal.id"
        :title="modal.title"
        :cancel-text="modal.cancelText"
        :confirm-text="modal.confirmText"
        :error-context="modal.errorContext"
        :icon="modal.icon"
        @on-close="closeModal"
        @on-confirm="confirmModal"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="modal.content" />
    </Modal>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Textarea from '@/components/Input/Textarea.vue'
import LoadingRing from '@/components/LoadingRing.vue'
import Modal from '@/components/Modal.vue'
import WatchlistIcon from '@/components/Watchlist/WatchlistIcon.vue'
import { formatDate } from '@/filters'
import { countryByCode } from '@/helper/countryCodes'
import helpers from '@/helper/misc'
import { showError } from '@/helper/services/error'
import { differentContextActive } from '@/helper/services/memberNavigation'
import { ProductService } from '@/helper/services/product'
import { saveNoteForTransaction, toggleFavourite } from '@/helper/services/transaction'
import { getCbraTransaction } from '@/services'
import { CbraTransactionFamilyTreeResponse } from '@/types/cbra/transactions'
import { ModalProps } from '@/types/components/modal'
import { isAxiosError } from 'axios'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import InfoBoxDisableMessage from '@/components/Transactions/InfoBoxDisableMessage.vue'

const id = 'portal.mc.detail-family-tree'

const route = useRoute()
const { t } = useI18n()

const modal = ref<ModalProps>()
const closeModal = () => modal.value?.onClose?.() || (modal.value = undefined)
const confirmModal = () => modal.value?.onConfirm?.() || (modal.value = undefined)

// class variables
const transactionId =ref(route.query.transactionId?.toString() || '')
const referenceNumber =ref(route.query.referenceNumber?.toString() || '')
const businessId =ref(route.query.businessId?.toString() || '')
const favourite =ref(false)

// status variables
const transactionFetching = ref(false)
const transactionHistoryFetching = ref(false)

// service(api) data
const transactionData = ref()

// mapped transaction data
const reportId = ref()
const orderName = ref()
const productType = ref()
const infoBoughtLastAt = ref()
const productOrderId = ref()
const myNote = ref<null | string>('')
const businessName = ref()
const postalAddress = ref()
const countryCode = ref()

// familyTree history
const history = ref<CbraTransactionFamilyTreeResponse>()

const editMode = ref(false)
const validation = computed(() => myNote.value ? myNote.value.length <= 200 : true)
const showLoadingSpinner = computed(() => {
    return transactionFetching.value || transactionHistoryFetching.value
})
const showHistory = computed(() => {
    return history.value?.historyEntries && history.value.historyEntries.length
})

const isDAL = computed(() => ['DE', 'AT', 'LU'].includes(countryCode.value))

const crefoId = computed(() => {
    if (!businessId.value) {
        return null
    } else {
        return helpers.businessIdToCrefoId(businessId.value)
    }
})
const businessIdDisplay = computed(() => {
    return isDAL.value ? crefoId.value : businessId.value
})

const isDisabled = ref(differentContextActive())

/**
   * loadTransaction load the current transaction with this.transactionId()
   *
   */
async function loadTransaction() {
    transactionFetching.value = true

    try {
        const { data } = await getCbraTransaction(transactionId.value)
        transactionData.value = data
        mapTransaction()
        if (reportId.value !== null) {
            loadHistory()
        }
    } catch (error) {
        console.error('error', error)
        modal.value = {
            id: 'fetching-transaction-fail',
            title: t('error.fetching.familyTree.transaction'),
            content: t('error.fetching.familyTree.transaction.description'),
            icon: 'error',
            dataQa: 'fetching-transaction-fail',
            // error: error
        }
    }
    transactionFetching.value = false
}

/**
   * loadHistory loads the click history within the familyTree, starting with the initial business.
   *
   */
async function loadHistory() {
    transactionFetching.value = true
    try {
        const { data } = await ProductService.getFamilyTreeReportJSON(reportId.value)
        history.value = data
    } catch (error) {
        console.error('error', error)   
        if (isAxiosError(error)) {
            modal.value = showError({ topic: 'ProductService', func: 'getFamilyTreeReportJSON', xhr: error })
        } else {
            modal.value = {
                id: 'fetching-transaction-fail',
                title: t('error.fetching.familyTree.transaction'),
                content: t('error.fetching.familyTree.transaction.description'),
                icon: 'error',
                dataQa: 'fetching-transaction-fail',
                // xhr: error
            }
        }
    }
    transactionFetching.value = false
}

/**
   * mapTransaction map the data from transactionData JSON to usable observables
   */
function mapTransaction() {
    reportId.value = transactionData.value.reportId
    favourite.value = transactionData.value.favourite
    orderName.value = transactionData.value.owner
    productType.value = t('shared.' + transactionData.value.involvementdataTransaction.involvementdataProductType)
    infoBoughtLastAt.value = transactionData.value.involvementdataTransaction.creationTime
    referenceNumber.value = transactionData.value.involvementdataTransaction.referenceNumber
    productOrderId.value = transactionData.value.involvementdataTransaction.productOrderId
    myNote.value = transactionData.value.note

    businessId.value = transactionData.value.involvementdataTransaction.businessId
    businessName.value = transactionData.value.involvementdataTransaction.businessName

    if (transactionData.value.involvementdataTransaction.postalAddress && transactionData.value.involvementdataTransaction.postalAddress.country) {
        countryCode.value = transactionData.value.involvementdataTransaction.postalAddress.country
        const country = countryByCode(transactionData.value.involvementdataTransaction.postalAddress.country)
        transactionData.value.involvementdataTransaction.postalAddress.country = country?.i18nKey ? t(country.i18nKey) : ''
        postalAddress.value = transactionData.value.involvementdataTransaction.postalAddress
    }
}

function startEditMode() {
    editMode.value = isDisabled.value ? false : true
}

async function saveMyNote() {
    if (validation.value) {
        editMode.value = false
        if (transactionData.value.myNote !== myNote.value) {
            transactionData.value.myNote = myNote.value
            const modalprops = await saveNoteForTransaction(transactionData.value.myNote, transactionId.value)
            modal.value = modalprops
        }
    } 
}

function toggleFavouriteTransaction() {
    favourite.value = !favourite.value
    transactionData.value.favourite = favourite.value
    return toggleFavourite(transactionData.value)
}

onMounted(loadTransaction)

</script>