<template>
    <div class="star-rating">
        <span
            v-for="star in 5"
            :key="star"
            :class="{ 'rated': star <= rating || star <= hoveredRating }"
            :data-qa="`rating-star-${star}`"
            @click="rate(star)"
        >
            <font-awesome-icon
                icon="fa-solid fa-star"
                size="lg"
                @mouseenter="hoverRating(star)"
                @mouseleave="resetHover"
            />
        </span>
    </div>
</template>
  
<script setup lang="ts">
import { ref } from 'vue'
  
const props = defineProps({
    modelValue: {
        type: Number,
        required: true
    }
})

const emit = defineEmits(['update:modelValue'])
  
const rating = ref(props.modelValue)
const hoveredRating = ref(0)
  
const rate = (star: number) => {
    rating.value = star
    console.log(star)
    emit('update:modelValue', star)
}
const hoverRating = (star: number) => {
    hoveredRating.value = star
}

const resetHover = () => {
    hoveredRating.value = 0
}
</script>
  
  <style scoped>
  .star-rating {
    font-size: 24px;
  }
  
  .star-rating span {
    cursor: pointer;
    transition: color 0.3s;
    color: white;
  }
  
  .star-rating span.rated, .star-rating span:hover {
  color: gold;
}
  </style>
  