<template>
    <Modal
        v-if="isVisible"
        id="wkv-overlay"
        :title="$t('wkv.overlay.survey.headline')"
        confirm-data-qa="continueToSurvey"
        :confirm-text="$t('wkv.overlay.survey.btn.gotosurvey')"
        cancel-data-qa="survey-overlay-abort-btn"
        :cancel-text="$t('wkv.overlay.survey.btn.gotopage')"
        @on-close="abortSurveyOverlay"
        @on-confirm="gotoSurvey"
    >
        <p>{{ $t('wkv.overlay.survey.description') }}</p>
    </Modal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import Modal from '../Modal.vue'

const props = defineProps({
    returnUrl: {
        type: String,
        required: true
    }
})

const isVisible = ref(true)

const surveyUrl = 'https://umfrage.creditreform.de/index.php/225671?newtest=Y&lang=de'

function abortSurveyOverlay() {
    isVisible.value = false
    setTimeout(() => {
        location.assign(props.returnUrl)
    }, 1000)
}

function gotoSurvey() {
    window.open(surveyUrl, '_blank')
    setTimeout(() => {
        location.assign(props.returnUrl)
    }, 1000)
}
</script>