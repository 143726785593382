import { useUserStore } from '@/stores/user'
import { AxiosError } from 'axios'

export function handleAxiosErrorReason(error: AxiosError) {
    const userStore = useUserStore()
    const errorContext: ErrorContext = {
        information: {
            traceId: error?.config?.headers?.['X-B3-TraceId'],
            correlationId: error?.response?.headers?.['correlationid'],
            url: error?.config?.url,
            httpStatus: error?.response?.status,
            response: error?.response?.data,
            user: {
                email: userStore.user?.email,
                memberUserId: userStore.user.cr_membernumber
            }
        },
        errorMessage: undefined as string | undefined // still unknown
    } 
    return errorContext
}
