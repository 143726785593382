<script setup lang="ts">
import { useMembersStore } from '@/stores/members'
import Card from '@/components/Card.vue'
import InputText from '@/components/Input/InputText.vue'

const membersStore = useMembersStore()
defineProps({
    isAllowedToSave: {
        type: Boolean,
        default: false
    }
})
</script>

<template>
    <Card
        id="memberdata-bankData"
        data-qa="selfcare-member-bankData"
    >
        <h3>{{ $t('selfcare.show.section.unternehmensdaten.bankverbindung') }}</h3>
        <InputText
            v-model="membersStore.updatePayload.iban"
            name="iban"
            :label="$t('selfcare.show.section.mitgliedsdaten.rechnungsadresse.iban')"
            data-qa="selfcare-member-bank-iban"
            error-msg-data-qa="iban-message"
            :readonly="!isAllowedToSave"
            :disabled="!isAllowedToSave"
        />
        <InputText
            v-model="membersStore.updatePayload.bic"
            name="bic"
            :label="$t('selfcare.show.section.mitgliedsdaten.rechnungsadresse.bic')"
            data-qa="selfcare-member-bank-bic"
            error-msg-data-qa="bic-message"
            :readonly="!isAllowedToSave"
            :disabled="!isAllowedToSave"
        />
    </Card>
</template>

