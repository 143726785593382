import { getCbraProductAvailability, validateWatchlistEntries } from '@/services'

const _watchlistAvailabilityGetQueue: any = {}

type WatchlistAvailablityResult = {
    watchlist: boolean
    signals: boolean
}

type HasWatchlistActiveSignalEntriesResult = {
    hasActiveSignals: boolean
    watchlistSignalsSupported: boolean
    watchlistAddingSupported: boolean
    isOnWatchlist: boolean
    id: string | null
}

export namespace WatchlistService {

   export const CONSTS = {
       SORT: {
           EVENT_TIMESTAMP_ASC: 'EVENT_TIMESTAMP_ASC',
           EVENT_TIMESTAMP_DESC: 'EVENT_TIMESTAMP_DESC',
           CREATION_TIMESTAMP_ASC: 'CREATION_TIMESTAMP_ASC',
           CREATION_TIMESTAMP_DESC: 'CREATION_TIMESTAMP_DESC',
           BUSINESS_NAME_ASC: 'BUSINESS_NAME_ASC',
           BUSINESS_NAME_DESC: 'BUSINESS_NAME_DESC',
           OBSERVATION_END_TIME_ASC: 'OBSERVATION_END_TIME_ASC',
           OBSERVATION_END_TIME_DESC: 'OBSERVATION_END_TIME_DESC'
       },
       FILTERS: {
           ALL: 'ALL',
           SUPPLEMENT: 'SUPPLEMENTS_ONLY',
           MONITORING: 'MONITORING_ONLY',
           SIGNAL: 'SIGNALS_ONLY',
           SINCE_LAST_LOGIN: 'ALL_CHANGED_AFTER_GIVEN_DATE_BY_LAST_EVENT_TIME',
           EXPIRING_OBSERVATIONS: 'EXPIRING_OBSERVATIONS',
           EXPIRED_OBSERVATIONS: 'EXPIRED_OBSERVATIONS'
       },
       SIGNAL_ENABLED_PRODUCTS: [
           'TRAFFIC_LIGHT_REPORT', 'BRIEF_REPORT', 'COMPACT_REPORT'
       ],
       HAS_ACTIVE_SIGNALS_STATE: [
           'SUPPLEMENT_ACTIVE', 'MONITORING_ACTIVE', 'SIGNAL_ACTIVE'
       ],
       TOPIC: 'WatchlistService',
       INACTIVE: '002',
       ACT_FOR_MEMBER_ID_CACHE_KEY: 'actForMemberId',
       WATCHLIST_AVAILABILITY_CACHE_KEY: 'WatchlistStatus',
       WATCHLIST_AVAILABILITY_CACHE_DURATION: 120000,
       WATCHLIST_AVAILABILITY_NOTIFIER_PREFIX: 'WatchlistAvailabilityId_'
   }

 /**
 * Get status if watchlist is supported from cbra.
 *
 */
export async function _getWatchlistAvailabilityFromCbra(businessId: string) {
    const response = await getCbraProductAvailability({ businessId })
    const businessData = response.data
  
    if (typeof businessData !== 'object' || businessData === null) {
        throw new Error('Invalid business data')
    } else if (typeof businessData.aggregatedBusinessStatus !== 'object' || businessData.aggregatedBusinessStatus === null) {
        throw new Error('Invalid aggregated business status')
    } else {
        const result: WatchlistAvailablityResult = { watchlist: false, signals: false }
  
        if (businessData.aggregatedBusinessStatus.id !== WatchlistService.CONSTS.INACTIVE) {
            result.watchlist = true // Everything has description except inactive
        }
        if (typeof businessData.productInfos === 'object' && businessData.productInfos !== null) {
            if (Array.isArray(businessData.productInfos.orderable)) {
                businessData.productInfos.orderable.forEach((product) => {
                    if (WatchlistService.CONSTS.SIGNAL_ENABLED_PRODUCTS.includes(product.productType)) {
                        result.signals = true
                    }
                })
            }
            if (Array.isArray(businessData.productInfos.receivable)) {
                businessData.productInfos.receivable.forEach((product) => {
                    if (WatchlistService.CONSTS.SIGNAL_ENABLED_PRODUCTS.includes(product.productType)) {
                        result.signals = true
                    }
                })
            }
  
            // ko.postbox.publish(constants.PB.TRANSACTION_DATA_PRODUCT_INFOS, businessData.productInfos)
            // notifier.notifySubscribers(null, constants.EVENTS.TRANSACTION_DATA_PRODUCT_INFOS_LOADED)
        }
  
        return result
    }
}

     /**
     * Types for checkWatchlistAvailability.
     *
     * @property watchlist - If adding to watchlist is allowed
     * @property signals - If signals for watchlist are available
     */

    /**
     * Check if watchlist and signals are supported for given company.
     *
     * Checks if aggregatedBusinessStatus is not inactive for watchlist availability.
     * Checks product type for signals availability.
     *
     * @param  businessId
     */
    export async function checkWatchlistAvailability(businessId: string) {
        return _getWatchlistAvailabilityFromCbra(businessId)
    }

    /**
     * Respond to all waiting requests.
     *
     */
    export async function _processWatchlistAvailabilityGetQueue(queuePrefix: string, isError = false, parameter1: any, parameter2 = null, parameter3 = null) {
        if (_watchlistAvailabilityGetQueue[queuePrefix] instanceof Array) {
            const waitingRequests = _watchlistAvailabilityGetQueue[queuePrefix]
            delete _watchlistAvailabilityGetQueue[queuePrefix]
            waitingRequests.forEach((waitingRequest: any) => {
                if (isError) {
                    waitingRequest.reject(parameter1, parameter2, parameter3)
                } else {
                    waitingRequest.resolve(parameter1)
                }
            })
        }
    }

/**
 * Check businessId on watchlist for active signals.
 *
 */
export async function hasWatchlistActiveSignalEntries(businessId: string | number) {
    try {
        if (typeof businessId !== 'string' && typeof businessId !== 'number') {
            return Promise.reject()
        }
  
        const response = await validateWatchlistEntries(businessId)
        const watchlistData = response.data
  
        if (!(watchlistData instanceof Array)) {
            return Promise.reject()
        }
  
        const result: HasWatchlistActiveSignalEntriesResult = {
            hasActiveSignals: false,
            watchlistSignalsSupported: watchlistData.length > 0 ? true : false,
            watchlistAddingSupported: watchlistData.length > 0 ? true : false,
            isOnWatchlist: false,
            id: null,
        }
        let hasErrors = false
  
        // It is possible, that the same business id is multiple times on watchlist
        watchlistData.forEach((data: any) => {
            if (typeof data !== 'object' || data === null) {
                hasErrors = true
                return
            }
            if (data.supported !== true) {
                result.watchlistSignalsSupported = false
                result.watchlistAddingSupported = false
            }
            if (typeof data.watchlistEntry === 'object' && data.watchlistEntry !== null) {
                if (data.businessId && data.watchlistEntry.id) {
                    result.isOnWatchlist = true
                    result.id = data.watchlistEntry.id
                }
                if (typeof data.watchlistEntry.observationStateEnum === 'string') {
                    // If observation state includes active signal types => has active signals will be true
                    if (WatchlistService.CONSTS.HAS_ACTIVE_SIGNALS_STATE.includes(data.watchlistEntry.observationStateEnum)) {
                        result.hasActiveSignals = true
                    }
                }
            }
        })
  
        return hasErrors ? Promise.reject() : Promise.resolve<HasWatchlistActiveSignalEntriesResult>(result)
    } catch (error) {
        return Promise.reject(error)
    }
}
    
}