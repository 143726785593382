interface t3Links extends Record<string, string> {
    'de-DE': string
    'de-AT': string
    'en-US': string
    'fr-FR': string
    'fr-LU': string
}

/*
 * Links für Bereich Auskunft
 */

export const reportsLandingpageLinks: t3Links = {
    'de-DE': '/bonitaetsauskunft/',
    'de-AT': '/de-at/bonitaetsauskunft/',
    'en-US': '/en/business-reports/',
    'fr-FR': '/fr/solvabilite/',
    'fr-LU': '/fr-lu/solvabilite/'
}

export const reportsLandingpageB2BLinks: t3Links = {
    'de-DE': '/bonitaetsauskunft/bonitaet-einer-firma-pruefen/',
    'de-AT': '/de-at/bonitaetsauskunft/bonitaet-einer-firma-pruefen/',
    'en-US': '/en/business-reports/business-reports/',
    'fr-FR': '/fr/solvabilite/verifier-la-solvabilite-dune-entreprise/',
    'fr-LU': '/fr-lu/solvabilite/verifier-la-solvabilite-dune-entreprise/'
}

export const udsLinks: t3Links = {
    'de-DE': '/bonitaetsauskunft/bonitaet-einer-firma-pruefen/detailseite-unternehmen/',
    'de-AT': '/de-at/bonitaetsauskunft/bonitaet-einer-firma-pruefen/detailseite-unternehmen/',
    'en-US': '/en/business-reports/business-reports/buy-a-report/',
    'fr-FR': '/fr/solvabilite/verifier-la-solvabilite-dune-entreprise/page-de-detail-de-lentreprise/',
    'fr-LU': '/fr-lu/solvabilite/verifier-la-solvabilite-dune-entreprise/page-de-detail-de-lentreprise/'
}

export const reportsLandingpageConsumerLinks: t3Links = {
    'de-DE': '/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen/',
    'de-AT': '/de-at/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen/',
    'en-US': '/en/business-reports/reports-on-private-persons/',
    'fr-FR': '/fr/solvabilite/verifier-la-solvabilite-dun-particulier/',
    'fr-LU': '/fr-lu/solvabilite/verifier-la-solvabilite-dun-particulier/'
}

export const reportsDetailConsumerLinks: t3Links = {
    'de-DE': '/vorgaenge/detailansicht-privatauskunft/',
    'de-AT': '/de-at/vorgaenge/detailansicht-privatauskunft/',
    'en-US': '/en/files/consumer-report-details/',
    'fr-FR': '/fr/operations/vue-detaillee-des-renseignements-prives/',
    'fr-LU': '/fr-lu/operations/vue-detaillee-des-renseignements-prives/'
}

export const reportsOrderFormLinks: t3Links = {
    'de-DE': '/bonitaetsauskunft/bestellung-einer-wirtschaftsauskunft/',
    'de-AT': '/de-at/bonitaetsauskunft/bestellung-einer-wirtschaftsauskunft/',
    'en-US': '/en/business-reports/ordering-of-business-reports/',
    'fr-FR': '/fr/solvabilite/commande-dun-rapport-commercial/',
    'fr-LU': '/fr-lu/solvabilite/commande-dun-rapport-commercial/'
}

export const reportsCompareProductsLinks: t3Links = {
    'de-DE': '/bonitaetsauskunft/produktvergleich/',
    'de-AT': '/de-at/bonitaetsauskunft/produktvergleich/',
    'en-US': '/en/business-reports/product-comparison/',
    'fr-FR': '/fr/solvabilite/produktvergleich/',
    'fr-LU': '/fr-lu/solvabilite/produktvergleich/'
}

export const watchlistLinks: t3Links = {
    'de-DE': '/bonitaetsauskunft/watchlist-unternehmen/',
    'de-AT': '/de-at/bonitaetsauskunft/watchlist-unternehmen/',
    'en-US': '/en/business-reports/watchlist/',
    'fr-FR': '/fr/solvabilite/watchlist-entreprises/',
    'fr-LU': '/fr-lu/solvabilite/watchlist-entreprises/'
}

/*
 * Links für Bereich Vorgänge
 */

export const transactionsLinks: t3Links = {
    'de-DE': '/vorgaenge',
    'de-AT': '/de-at/vorgaenge',
    'en-US': '/en/files',
    'fr-FR': '/fr/operations',
    'fr-LU': '/fr-lu/operations'
}

export const adsLinks: t3Links = {
    'de-DE': '/vorgaenge/detailansicht-auskunft/',
    'de-AT': '/de-at/vorgaenge/detailansicht-auskunft/',
    'en-US': '/en/files/business-report-details/',
    'fr-FR': '/fr/operations/vue-detaillee-des-renseignements/',
    'fr-LU': '/fr-lu/operations/vue-detaillee-des-renseignements/'
}

export const adsConsumerLinks: t3Links = {
    'de-DE': '/vorgaenge/detailansicht-privatauskunft/',
    'de-AT': '/de-at/vorgaenge/detailansicht-privatauskunft/',
    'en-US': '/en/files/consumer-report-details/',
    'fr-FR': '/fr/operations/detailansicht-privatauskunft/',
    'fr-LU': '/fr-lu/operations/detailansicht-privatauskunft/'
}

export const adsInvolvementLinks: t3Links = {
    'de-DE': '/vorgaenge/detailansicht-verflechtung/',
    'de-AT': '/de-at/vorgaenge/detailansicht-verflechtung/',
    'en-US': '/en/files/detailansicht-verflechtung/',
    'fr-FR': '/fr/operations/detailansicht-verflechtung/',
    'fr-LU': '/fr-lu/operations/detailansicht-verflechtung/'
}

export const idsLinks: t3Links = {
    'de-DE': '/vorgaenge/detailansicht-inkasso/',
    'de-AT': '/de-at/vorgaenge/detailansicht-inkasso/',
    'en-US': '/en/files/detail-view-debt-collection/',
    'fr-FR': '/fr/operations/vue-detaillee-du-recouvrement/',
    'fr-LU': '/fr-lu/operations/vue-detaillee-du-recouvrement/'  
}

/*
 * Links für Bereich Inkasso
 */

export const inkassoLandingpageLinks: t3Links = {
    'de-DE': '/inkasso/inkasso-mit-creditreform/',
    'de-AT': '/de-at/inkasso/inkasso-mit-creditreform/',
    'en-US': '/en/debt-collection/debt-collection-with-creditreform/',
    'fr-FR': '/fr/recouvrement/recouvrement-avec-creditreform/',
    'fr-LU': '/fr-lu/recouvrement/recouvrement-avec-creditreform/'
}

export const inkassoUploadLinks: t3Links = {
    'de-DE': '/inkasso/inkassoauftrag-per-upload/',
    'de-AT': '/de-at/inkasso/inkassoauftrag-per-upload/',
    'en-US': '/en/debt-collection/upload-for-debt-collection/',
    'fr-FR': '/fr/recouvrement/recouvrement-par-telechargement/',
    'fr-LU': '/fr-lu/recouvrement/recouvrement-par-telechargement/',
}

export const inkassoFormLinks: t3Links = {
    'de-DE': '/inkasso/inkassoauftrag-per-formular/',
    'de-AT': '/de-at/inkasso/inkassoauftrag-per-formular/',
    'en-US': '/en/debt-collection/fill-out-forms-for-debt-collection/',
    'fr-FR': '/fr/recouvrement/recouvrement-par-formulaire/',
    'fr-LU': '/fr-lu/recouvrement/recouvrement-par-formulaire/'
}

/*
 * Links für Bereich Suche
 */

export const advancedSearchLinks: t3Links = {
    'de-DE': '/erweiterte-suche',
    'de-AT': '/de-at/erweiterte-suche',
    'en-US': '/en/advanced-search/',
    'fr-FR': '/fr/recherche-avancee/',
    'fr-LU': '/fr-lu/recherche-avancee/'
}

export const advancedSearchResultsLinks: t3Links = {
    'de-DE': '/erweiterte-suche/suchergebnisliste/',
    'de-AT': '/de-at/erweiterte-suche/suchergebnisliste/',
    'en-US': '/en/advanced-search/hit-list/',
    'fr-FR': '/fr/recherche-avancee/liste-des-resultats-de-recherche/',
    'fr-LU': '/fr-lu/recherche-avancee/liste-des-resultats-de-recherche/'
}

export const contactLinks: t3Links = {
    'de-DE': '/kontakt/',
    'de-AT': '/de-at/kontakt/',
    'en-US': '/en/contact/',
    'fr-FR': '/fr/contact/',
    'fr-LU': '/fr-lu/contact/'
}

export const consumerHelpLinks: t3Links = {
    'de-DE': '/hilfe/consumer/',
    'de-AT': '/de-at/hilfe/consumer/',
    'en-US': '/en/help/consumer/',
    'fr-FR': '/fr/aide/consumer/',
    'fr-LU': '/fr-lu/aide/consumer/'
}