import { AxiosHeaderValue, } from 'axios'
import { differentContextActive } from './memberNavigation'
import { ROLES, hasUserRole } from './user'
import { createCbraOrderReport } from '@/services'
import { CbraOrderReportCreateData } from '@/types/cbra/advancedSearch'

export namespace AdvancedSearchService {
   export const CONSTS = {
       TOPIC: 'AdvancedSearchService'
   }
    
   export async function orderReport(payload: CbraOrderReportCreateData) {
       let header: {[key: string]: AxiosHeaderValue} = {}
    
       if (differentContextActive()) {
           if (hasUserRole(ROLES.PORTFOLIO_AUSKUNFT)) {
               header = {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'viewScope': 'MEMBER'
               }
        
               await createCbraOrderReport(payload, header)
           } else {
               await createCbraOrderReport(payload, header)
           }
       } else {
           await createCbraOrderReport(payload, header)
       }
   }
}