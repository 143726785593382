export function getDynamicFileUrl(path: string) {
    let newPath = path
    if (path.includes('@/assets/')) {
        newPath = path.replace('@/assets/', '/src/assets/')
    }
    return new URL(newPath, import.meta.url).href
}

export function getDynamicWidgetFileUrl(name:string) {
    return new URL(`/src/assets/img/widgets/${name}`, import.meta.url).href
}

export function getFileUrlWKV(name:string) {
    return new URL(`/src/assets/img/wkv/${name}`, import.meta.url).href
}

export function getFileUrlUDS(name:string) {
    return new URL(`/src/assets/img/uds/${name}`, import.meta.url).href
}

export function getFileUrlInkasso(name: string) {
    return new URL(`/src/assets/img/help/inkasso/${name}`, import.meta.url).href
}

export function getFileUrlProductComparison(name: string) {
    return new URL(`/src/assets/img/bonitaetsauskunft/product-comparison/${name}`, import.meta.url).href
}
