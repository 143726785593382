import { sendClick } from '../webtrekk'

export const PAGE_FUNCTIONS = {
    LIST: 'watchlist_list',
    WIZARD: 'watchlist_wizard'
}

export const PAGE_ACTIONS = {
    LIST_REQUEST: 'request',
    WIZARD_SUGGESTION_SHOWN: 'watchlist_wizard_suggestion_shown',
    WIZARD_OPEN: 'watchlist_wizard_open',
    WIZARD_CLOSE: 'watchlist_wizard_close',
    WIZARD_ABORT: 'watchlist_wizard_abort',
    WIZARD_SUBMIT: 'watchlist_wizard_submit',
    WIZARD_RESULT: 'watchlist_wizard_result',
    WKV_OFFERS_SHOWN: 'watchlist_wkv_offer_shown',
    WKV_OFFER_OPEN: 'watchlist_wkv_offer_open',
    WKV_CONTRACTS_SHOWN: 'watchlist_wkv_contracts_shown',
    WKV_REJECTS_SHOWN: 'watchlist_wkv_rejects_shown'
}

/*
 * WatchlistWebTrekkService
 */
export function placeNewWizardSuggestionShownAction(numberOfSuggestions: number) {
    sendClick(
        PAGE_FUNCTIONS.WIZARD + '_' + PAGE_ACTIONS.WIZARD_SUGGESTION_SHOWN,
        {
            6: PAGE_FUNCTIONS.WIZARD,
            7: PAGE_ACTIONS.WIZARD_SUGGESTION_SHOWN,
            8: 'Hinweis Vorschläge verfügbar',
            9: numberOfSuggestions > 0 ? String(numberOfSuggestions) : '0'
        }
    )
}

export function placeNewWizardOpenAction() {
    sendClick(
        PAGE_FUNCTIONS.WIZARD + '_' + PAGE_ACTIONS.WIZARD_OPEN,
        {
            6: PAGE_FUNCTIONS.WIZARD,
            7: PAGE_ACTIONS.WIZARD_OPEN,
            8: 'Geöffnet'
        }
    )
}

export function placeNewWizardCloseAction() {
    sendClick(
        PAGE_FUNCTIONS.WIZARD + '_' + PAGE_ACTIONS.WIZARD_CLOSE,
        {
            6: PAGE_FUNCTIONS.WIZARD,
            7: PAGE_ACTIONS.WIZARD_CLOSE,
            8: 'Geschlossen'
        }
    )
}

export function placeNewWizardAbortAction(numberOfSelectedItems: number) {
    sendClick(
        PAGE_FUNCTIONS.WIZARD + '_' + PAGE_ACTIONS.WIZARD_ABORT,
        {
            6: PAGE_FUNCTIONS.WIZARD,
            7: PAGE_ACTIONS.WIZARD_ABORT,
            8: 'Abgebrochen',
            9: numberOfSelectedItems > 0 ? String(numberOfSelectedItems) : '0'
        }
    )
}

export function placeNewWizardSubmitAction(numberOfSelectedItems: number) {
    sendClick(
        PAGE_FUNCTIONS.WIZARD + '_' + PAGE_ACTIONS.WIZARD_SUBMIT,
        {
            6: PAGE_FUNCTIONS.WIZARD,
            7: PAGE_ACTIONS.WIZARD_SUBMIT,
            8: 'Abgesendet',
            9: numberOfSelectedItems > 0 ? String(numberOfSelectedItems) : '0'
        }
    )
}

export function placeNewWizardResultAction(numberOfSuccessfulItems: number, numberOfFailedItems: number) {
    sendClick(
        PAGE_FUNCTIONS.WIZARD + '_' + PAGE_ACTIONS.WIZARD_RESULT,
        {
            6: PAGE_FUNCTIONS.WIZARD,
            7: PAGE_ACTIONS.WIZARD_RESULT,
            8: 'Abgeschlossen',
            9: numberOfSuccessfulItems > 0 ? String(numberOfSuccessfulItems) : '0',
            10: numberOfFailedItems > 0 ? String(numberOfFailedItems) : '0'
        }
    )
}

export function placeNewWkvOfferOpenAction(observationStateEnum: string) {
    sendClick(
        PAGE_FUNCTIONS.LIST + '_' + PAGE_ACTIONS.WKV_OFFER_OPEN,
        {
            6: PAGE_FUNCTIONS.LIST,
            7: PAGE_ACTIONS.WKV_OFFER_OPEN,
            8: 'WKV Angebot angeklickt',
            12: observationStateEnum.length ? observationStateEnum : 'UNDECIDABLE'
        }
    )
}

export function placeNewWkvOfferShownAction(numberOfOffers: number) {
    sendClick(
        PAGE_FUNCTIONS.LIST + '_' + PAGE_ACTIONS.WKV_OFFERS_SHOWN,
        {
            6: PAGE_FUNCTIONS.LIST,
            7: PAGE_ACTIONS.WKV_OFFERS_SHOWN,
            8: 'WKV Angebote verfügbar',
            9: numberOfOffers > 0 ? String(numberOfOffers) : '0'
        }
    )
}

export function placeNewWkvContractsShownAction(numberOfContracts: number) {
    sendClick(
        PAGE_FUNCTIONS.LIST + '_' + PAGE_ACTIONS.WKV_CONTRACTS_SHOWN,
        {
            6: PAGE_FUNCTIONS.LIST,
            7: PAGE_ACTIONS.WKV_CONTRACTS_SHOWN,
            8: 'WKV Vertragsabschlüsse vorhanden',
            9: numberOfContracts > 0 ? String(numberOfContracts) : '0'
        }
    )
}

export function placeNewWkvRejectsShownAction(numberOfRejects: number) {
    sendClick(
        PAGE_FUNCTIONS.LIST + '_' + PAGE_ACTIONS.WKV_REJECTS_SHOWN,
        {
            6: PAGE_FUNCTIONS.LIST,
            7: PAGE_ACTIONS.WKV_REJECTS_SHOWN,
            8: 'WKV Ablehnungen vorhanden',
            9: numberOfRejects > 0 ? String(numberOfRejects) : '0'
        }
    )
}
