<template>
    <div
        class="advantages"
        data-qa="grid-box-876"
    >
        <div class="container advantages__container content-spacer">
            <div class="row mb-small">
                <div class="col-12 col-md-4">
                    <div class="row">
                        <div class="col-8">
                            <h4>{{ $t("AdvantagesTeaser.creditworthiness.title") }}</h4>
                            <ul class="list-unordered mb">
                                <li>{{ $t("AdvantagesTeaser.creditworthiness.company") }}</li>
                                <li v-if="$i18n.locale !== 'de-AT'">
                                    {{ $t("AdvantagesTeaser.creditworthiness.private") }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-4">
                            <i
                                class="crefo-brand-icon icon-shield text-brand-darkest-blue"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="row">
                        <div class="col-8">
                            <h4>{{ $t("AdvantagesTeaser.unpaidBills.title") }}</h4>
                            <ul class="list-unordered mb">
                                <li>{{ $t("AdvantagesTeaser.unpaidBills.information") }}</li>
                                <li>{{ $t("AdvantagesTeaser.unpaidBills.collectionOrder") }}</li>
                            </ul>
                        </div>
                        <div class="col-4">
                            <i
                                class="crefo-brand-icon icon-file-euro text-brand-darkest-blue"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="row">
                        <div class="col-8">
                            <h4>{{ $t("AdvantagesTeaser.businessPartners.title") }}</h4>
                            <ul class="list-unordered mb">
                                <li>{{ $t("AdvantagesTeaser.businessPartners.watchlist") }}</li>
                                <li>{{ $t("AdvantagesTeaser.businessPartners.monitoring") }}</li>
                            </ul>
                        </div>
                        <div class="col-4">
                            <i
                                class="crefo-brand-icon icon-search-person text-brand-darkest-blue"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
li {
    line-height: 1.2rem;
    padding-bottom: 10px;
}

.crefo-brand-icon {
    font-size: 60px;
}
</style>
