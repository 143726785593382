<template>
    <EmptyWidgetContent
        v-if="results.length <= 0"
        :widget="widget"
    />
    <WidgetLoadingOverlay
        v-if="isLoading"
    />
    <div
        v-if="results.length > 0"
        class="list-widget"
    >
        <div class="list-widget-header">
            <div :data-qa="`widget-${widget.dynamicDataType}-col-first`">
                <span :data-qa="`widget-${widget.dynamicDataType}-col-first-name`">
                    {{ headerName }}
                </span>
                
                <span
                    v-if="headerChanges"
                    :data-qa="`widget-${widget.dynamicDataType}-col-first-changes`"
                >
                    / {{ headerChanges }}
                </span>
            </div>
            <div>
                <button
                    class="sort-button"
                    :data-qa="`widget-${widget.dynamicDataType}-sorting`"
                    type="button"
                    @click="onSort"
                >
                    {{ sortName || $t('dashboard.widget.watchlist.list.header.activity') }}
                    <font-awesome-icon
                        v-if="sortingType.includes('ASC')"
                        icon="fa-solid fa-caret-up"
                        :data-qa="`widget-${widget.dynamicDataType}-sorting-triangle-top`"
                    />
                    <font-awesome-icon 
                        v-if="sortingType.includes('DESC')"
                        icon="fa-solid fa-caret-down"
                        :data-qa="`widget-${widget.dynamicDataType}-sorting-triangle-bottom`"
                    />
                </button>
            </div>
        </div>
        <div class="list-widget-content">
            <div
                v-for="(result, index) in results"
                :key="result.transactionId ? `${result.transactionId}-${index}` : index"
                class="list-widget-row"
                :data-qa="`widget-${widget.dynamicDataType}-row-${index}`"
            >
                <div class="list-widget-column">
                    <a
                        :href="result.link"
                        :title="result.name"
                        :data-qa="`widget-${widget.dynamicDataType}-row-${index}-name`"
                    >{{ result.name }}</a>
                    <span
                        class="list-widget-change-reason"
                        :title="result.changeReason"
                        :data-qa="`widget-${widget.dynamicDataType}-row-${index}-changeReason`"
                    >{{ result.changeReason }}</span>
                </div>
                <div>
                    <span
                        :data-qa="`widget-${widget.dynamicDataType}-row-${index}-date`"
                    >{{ result.date }}</span>
                </div>
            </div>
        </div>
        <div class="list-widget-footer">
            <div
                v-if="hasPagination"
                class="list-widget-pagination"
            >
                <button
                    class="list-widget-pagination-button" 
                    :disabled="isFirstPage"
                    :data-qa="`widget-${widget.dynamicDataType}-prev`"
                    type="button"
                    @click="onPreviousPage"
                >
                    <font-awesome-icon
                        icon="fa-sharp fa-solid fa-chevron-left"
                        size="lg"
                    />
                </button>
                <button
                    class="list-widget-pagination-button"
                    :disabled="isLastPage"
                    :data-qa="`widget-${widget.dynamicDataType}-next`"
                    type="button"
                    @click="onNextPage"
                >
                    <font-awesome-icon
                        icon="fa-sharp fa-solid fa-chevron-right"
                        size="lg"
                    />
                </button>
            </div>
            <div
                v-if="listLink"
                class="list-widget-list-link"
            >
                <a :href="listLink">
                    {{ $t('dashboard.widget.expiringMonitoring.link.name') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType, computed } from 'vue'
import type { EnhancedResults, Widget } from '@/types/widget'
import EmptyWidgetContent from '../EmptyWidgetContent.vue'
import WidgetLoadingOverlay from '../../WidgetLoadingOverlay.vue'

const props = defineProps({
    widget: {
        type: Object as PropType<Widget>,
        required: true
    },
    results: {
        type: Object as PropType<EnhancedResults[]>,
        required: true
    },
    headerName: {
        type: String,
        required: false,
        default: undefined
    },
    headerChanges: {
        type: String,
        required: false,
        default: undefined
    },
    sortName: {
        type: String,
        required: false,
        default: undefined
    },
    sortingType: {
        type: String,
        required: true
    },
    isLoading: {
        type: Boolean,
        required: true
    },
    pageSize: {
        type: Number,
        required: true
    },
    pageStartIndex: {
        type: Number,
        required: true
    },
    listSize: {
        type: Number,
        required: true
    },
    listLink: {
        type: String,
        required: false,
        default: undefined
    }
})

const emit = defineEmits(['onSort', 'onPreviousPage', 'onNextPage'])
function onSort() {
    emit('onSort')
}
function onPreviousPage() {
    emit('onPreviousPage')
}
function onNextPage() {
    emit('onNextPage')
}

const hasPagination = computed(() => {
    return props.listSize > props.pageSize
})

const isFirstPage = computed(() => {
    return props.pageStartIndex === 0
})

const isLastPage = computed(() => {
    return (props.pageStartIndex + 1) === Math.ceil(props.listSize / props.pageSize)
})

</script>

<style scoped lang="less">

.list-widget {
    font-size: 12px;
    font-weight: 600;
}

.list-widget-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 1rem;
    color: #f8b820;
    svg {
        color: initial;
    }
}

.sort-button {
    all: unset;
    cursor: pointer;
}

.list-widget-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d1d1d1;
    padding: 0.8rem 0;
    align-items: center;
    gap: 10px;
}

.list-widget-column {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    a {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.list-widget-change-reason {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.list-widget-footer {
    position: absolute;
    bottom: 15px;
}

.list-widget-pagination-button {
    all: unset;
    padding: 0 7px;
    cursor: pointer;
    color: var(--color-c-p1);
    &:disabled {
        background-color: transparent;
        color: var(--color-c-grey2)
    }
}

</style>