<template>
    <div class="crefo-ui-alert info">
        <div>
            <span
                class="alert-close"
                :data-qa="dataQaClose"
                @click="onClose"
            />
        </div>
        <div
            v-if="title"
            class="mb-small"
        >
            <b :data-qa="dataQaTitle">{{ title }}</b>
        </div>
        <div class="alert-text">
            <slot />
        </div>
    </div>
</template>

<script lang="ts" setup>
defineProps({
    title: {
        type: String,
        required: false,
        default: undefined
    },
    dataQaClose: {
        type: String,
        required: false,
        default: undefined
    },
    dataQaTitle: {
        type: String,
        required: false,
        default: undefined
    }
})

const emit = defineEmits(['onClose'])

function onClose() {
    emit('onClose')
}

</script>

<style scoped lang="less">
.alert-close {
    top: auto !important;
    transform: none !important;
}
.alert-text {
    margin-right: 20px;
}
.crefo-ui-alert {
    font-size: 15px;
    letter-spacing: normal;

    :deep(p) {
        letter-spacing: normal;
    }

    :deep(p:last-of-type) {
        margin-bottom: 0;
    }

    :deep(a) {
        color: #fff;
        text-decoration: underline;
        &:hover {
            text-decoration: none
        }
    }
}
</style>

