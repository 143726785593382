<!-- eslint-disable vue/no-v-html -->
<template>
    <div 
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'advanced-search' }">
                    {{ $t('advancedSearch.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('advancedSearchResults.breadcrumb') }}</li>
        </Breadcrumb>
    </div>

    <CompanySearch
        :hide-title="true"
        :is-search-results="true"
    />

    <AnchorLinks
        id="anchors"
        :links="anchorLinks"
        :selected-link="currentTabId"
        justify-content=""
        @change-selected="changeTab($event)"
    />

    <div class="bg-brand-light-grey box-spacing">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="results-list">
                        <LoadingRing v-if="showLoadingSpinner" />
                        <template v-if="!showLoadingSpinner && currentTabId == '1'">
                            <div
                                v-if="orderCommercialReport"
                                class="crefo-ui-alert info vspace--1"
                                data-qa="solvencyCheck-searchResult-infobox"
                            >
                                <b data-qa="solvencyCheck-searchResult-infobox-orderCommerialReportIntro">{{ $t('search.orderCommercialReport') }}</b>
                                <br>
                                <span
                                    v-if="businessSearchResearchCommericalReport"
                                    data-qa="solvencyCheck-searchResult-infobox-orderCommerialReportText"
                                    v-html="businessSearchResearchCommericalReport"
                                />
                            </div>
                            <template v-if="!showNoBusinessResult">
                                <div
                                    v-for="(business, index) in businesses"
                                    :key="index"
                                    class="searchresult__items vspace--2"
                                    data-qa="searchResults"
                                >
                                    <BusinessResult
                                        :business="business"
                                        :is-dal="isDAL"
                                        :transactions-count="transactionsCount"
                                        :country="country"
                                    />
                                </div>  
                            </template>
                            <div
                                v-if="showNoBusinessResult"
                                class="crefo-ui-alert info vspace--1"
                                data-qa="noSearchResult"
                            >
                                <b>{{ $t('report.result.businessSearch.noResults') }}</b>
                                <br>
                                <span
                                    v-if="businessSearchResearchText"
                                    v-html="businessSearchResearchText"
                                />
                            </div>
                        </template>
                        <template v-if="!showLoadingSpinner && currentTabId == '2'">
                            <div
                                v-for="(transaction, index) in transactions"
                                :id="`transaction-${transaction.id}`"
                                :key="`transaction-${transaction.id}`"
                                class="row"
                                :class="{'mb-mini': index !== transactions.length - 1}"
                                data-qa="transaction-searchResult"
                            > 
                                <div class="col-12">
                                    <ListItemConsumer
                                        v-if="transaction.transactionType === CONSTANTS.TRANSACTION_TYPES.CONSUMER"
                                        :transaction="transaction"
                                        :hide-delete-action="true"
                                    />
                                    <ListItemCollection
                                        v-if="transaction.transactionType === CONSTANTS.TRANSACTION_TYPES.COLLECTION"
                                        :transaction="transaction" 
                                    />
                                    <ListItemInvolvementdata
                                        v-if="transaction.transactionType === CONSTANTS.TRANSACTION_TYPES.INVOLVEMENTDATA"
                                        :transaction="transaction" 
                                        :hide-delete-action="true"
                                    />
                                    <ListItemReport
                                        v-if="transaction.transactionType === CONSTANTS.TRANSACTION_TYPES.REPORT"
                                        :transaction="transaction" 
                                        :hide-delete-action="true"
                                        :hide-buy-button="true"
                                    />
                                </div>
                            </div>

                            <div
                                v-if="showNoTransactionResult"
                                class="searchresult__empty"
                            >
                                {{ $t('report.result.noResults') }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import BusinessResult from '@/components/AdvancedSearch/BusinessResult.vue'
import AnchorLinks, { AnchorLink } from '@/components/AnchorLinks.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CompanySearch from '@/components/CompanySearch.vue'
import LoadingRing from '@/components/LoadingRing.vue'
import ListItemCollection from '@/components/Transactions/ListItemCollection.vue'
import ListItemConsumer from '@/components/Transactions/ListItemConsumer.vue'
import ListItemInvolvementdata from '@/components/Transactions/ListItemInvolvementdata.vue'
import ListItemReport from '@/components/Transactions/ListItemReport.vue'
import CONSTANTS from '@/data/constants'
import { TransactionService } from '@/helper/services/transaction'
import { postCbraCountTransactions, postCbraSimpleSearch, sendAdvancedSearch } from '@/services'
import { useUserStore } from '@/stores/user'
import { SimpleSearchResult, } from '@/types/cbra/simplesearch'
import { CbraTransaction, CbraTransactionCountResponse } from '@/types/cbra/transactions'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const id = 'portal.mc.advanced-search-results'
const businessSearchResearchPageUrl = '/bonitaetsauskunft/bestellung-einer-wirtschaftsauskunft/'

const route = useRoute()
const { t , locale } = useI18n()
const userStore = useUserStore()

const currentTabId = ref('1')
const transactionsCount = ref<CbraTransactionCountResponse>()

const isLoadingBusiness = ref(false)
const businesses = ref<SimpleSearchResult[]>([])
const showNoBusinessResult = ref(false)

const isLoadingTransaction = ref(false)
const transactions = ref<CbraTransaction[]>([])
const showNoTransactionResult = ref(false)

const country = computed(() => {
    if (route.query.country) {
        return String(route.query.country)
    } else {
        return 'DE'
    }
})
const showLoadingSpinner = computed(() => isLoadingBusiness.value || isLoadingTransaction.value)
const orderCommercialReport = computed(() => businesses.value.length > 0)
const isDAL = computed(() => ['DE', 'AT', 'LU'].includes(country.value))

const businessSearchResearchPageUrlWithParams = computed(() => {
    const url = new URL(businessSearchResearchPageUrl, window.location.origin)
    const params = new URLSearchParams()
    params.append('query', String(route.query.query))
    params.append('highlightPrefilledFields', 'true')
    params.append('country', country.value)
    url.search = params.toString()
    return url.href
})

const businessSearchResearchCommericalReport = computed(() => {
    return t('report.result.businessSearch.noResults.research', {
        link: `<a href="${businessSearchResearchPageUrlWithParams.value}" data-qa="search-business-commercialReport-research-form-link">${t('report.result.businessSearch.noResults.research.form')}</a>`
    })
})

const businessSearchResearchText = computed(() => {
    return t('report.result.businessSearch.noResults.research', {
        link: `<a href="${businessSearchResearchPageUrlWithParams.value}" data-qa="search-business-noresult-research-form-link">${t('report.result.businessSearch.noResults.research.form')}</a>`
    })
})

const businessIdsForTransactionCount = computed(() => {
    return businesses.value.map((business) => {
        return { businessId: business.businessId }
    })
})

watch(businessIdsForTransactionCount, async() => {
    const { data } = await postCbraCountTransactions(businessIdsForTransactionCount.value)
    transactionsCount.value = data
})

watch(() => route.query.query, async(newQuery) => {
    await fetchBusinesses(country.value)
    await fetchTransactions(String(newQuery))
})

watch(() => route.query.country, async(newCountry) => {
    await fetchBusinesses(String(newCountry))
})

onMounted(async() => {
    await fetchBusinesses(country.value)
    await fetchTransactions(String(route.query.query))
})

const anchorLinks = computed(()=> {
    const anchorLinks: AnchorLink[] = [
        {
            id: '1',
            i18nKey: 'shared.company',
            html: `${t('shared.company')} <span data-qa="businessResultSearchHits-desktop">(${businesses.value.length})</span>`,
            dataQa: 'businessResult-desktop',

        },
        {
            id: '2',
            i18nKey: 'search.transactions',
            html: `${t('search.transactions')} <span data-qa="transactionsResultSearchHits-desktop">(${transactions.value.length})</span>`,
            dataQa: 'transactionsResult-desktop',
        }
    ]
    return anchorLinks
})

const changeTab = (tab: AnchorLink) => {
    currentTabId.value = tab.id
}

async function fetchTransactions(query: string) {
    isLoadingTransaction.value = true
    const { data } = await TransactionService.search(query)
    transactions.value = data.transactions
    isLoadingTransaction.value = false
    if (!showLoadingSpinner.value && businesses.value.length === 0 && transactions.value && transactions.value.length > 0) {
        currentTabId.value = '2'
    }
    showNoTransactionResult.value = transactions.value.length <= 0
}

async function fetchBusinesses(country: string) {
    isLoadingBusiness.value = true
    const advancedSearchQuery = route.query.advancedSearchQuery
    let searchHits: SimpleSearchResult[] = []

    try {
        if (advancedSearchQuery) {
            const { data } = await sendAdvancedSearch(JSON.parse(JSON.stringify(advancedSearchQuery)))
            searchHits = data.searchHits
        } else {
            const { data } = await postCbraSimpleSearch({
                crId: userStore.user.cr_id ?? '',
                communicationLanguage: locale.value,
                country: country,
                text: String(route.query.query)
            })
            searchHits = data.searchHits
        }
    } catch (error) {
        console.error(error)
    }

    try {
        businesses.value = searchHits
        isLoadingBusiness.value = false
        showNoBusinessResult.value = businesses.value.length <= 0
        if (businesses.value.length > 0) {
            currentTabId.value = '1'
        }
    } catch (error) {
        isLoadingBusiness.value = false
        showNoBusinessResult.value = true
    }
}

</script>

<style scoped lang="less">
.mb-mini {
    margin-bottom: 10px;
}

.searchresult__empty {
    margin: 40px;
    text-align: center;
}
</style>