<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'
import TableComponent from '@/components/TableComponent.vue'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const id = 'portal.mc.help.e-mails'

const { t } = useI18n()

const tableHeaders = computed(() => [
    t('helpMails.table.headers.emailNotification'),
    t('helpMails.table.headers.area'),
    t('helpMails.table.headers.category'),
])

const tableRows = computed(() => [
    [ t('helpMails.table.emailNotification.1'), t('helpMails.table.area.1'), t('helpMails.table.category.1') ],
    [ t('helpMails.table.emailNotification.2'), t('helpMails.table.area.2'), t('helpMails.table.category.2') ],
    [ t('helpMails.table.emailNotification.3'), t('helpMails.table.area.3'), t('helpMails.table.category.3') ],
    [ t('helpMails.table.emailNotification.4'), t('helpMails.table.area.4'), t('helpMails.table.category.4') ],
    [ t('helpMails.table.emailNotification.5'), t('helpMails.table.area.5'), t('helpMails.table.category.5') ],
    [ t('helpMails.table.emailNotification.6'), t('helpMails.table.area.6'), t('helpMails.table.category.6') ],
    [ t('helpMails.table.emailNotification.7'), t('helpMails.table.area.7'), t('helpMails.table.category.7') ],
    [ t('helpMails.table.emailNotification.8'), t('helpMails.table.area.8'), t('helpMails.table.category.8') ],
    [ t('helpMails.table.emailNotification.9'), t('helpMails.table.area.9'), t('helpMails.table.category.9') ],
    [ t('helpMails.table.emailNotification.10'), t('helpMails.table.area.10'), t('helpMails.table.category.10') ],
    [ t('helpMails.table.emailNotification.11'), t('helpMails.table.area.11'), t('helpMails.table.category.11') ],
    [ t('helpMails.table.emailNotification.12'), t('helpMails.table.area.12'), t('helpMails.table.category.12') ],
    [ t('helpMails.table.emailNotification.13'), t('helpMails.table.area.13'), t('helpMails.table.category.13') ],
    [ t('helpMails.table.emailNotification.14'), t('helpMails.table.area.14'), t('helpMails.table.category.14') ],
    [ t('helpMails.table.emailNotification.15'), t('helpMails.table.area.15'), t('helpMails.table.category.15') ],
    [ t('helpMails.table.emailNotification.16'), t('helpMails.table.area.16'), t('helpMails.table.category.16') ],
    [ t('helpMails.table.emailNotification.17'), t('helpMails.table.area.17'), t('helpMails.table.category.17') ],
    [ t('helpMails.table.emailNotification.18'), t('helpMails.table.area.18'), t('helpMails.table.category.18') ],
    [ t('helpMails.table.emailNotification.19'), t('helpMails.table.area.19'), t('helpMails.table.category.19') ],
    [ t('helpMails.table.emailNotification.20'), t('helpMails.table.area.20'), t('helpMails.table.category.20') ],
    [ t('helpMails.table.emailNotification.21'), t('helpMails.table.area.21'), t('helpMails.table.category.21') ],
    [ t('helpMails.table.emailNotification.22'), t('helpMails.table.area.22'), t('helpMails.table.category.22') ],
    [ t('helpMails.table.emailNotification.23'), t('helpMails.table.area.23'), t('helpMails.table.category.23') ],
    [ t('helpMails.table.emailNotification.24'), t('helpMails.table.area.24'), t('helpMails.table.category.24') ],
    [ t('helpMails.table.emailNotification.25'), t('helpMails.table.area.25'), t('helpMails.table.category.25') ],
    [ t('helpMails.table.emailNotification.26'), t('helpMails.table.area.26'), t('helpMails.table.category.26') ],
    [ t('helpMails.table.emailNotification.27'), t('helpMails.table.area.27'), t('helpMails.table.category.27') ]
])
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('helpMails.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <div
                        id="c5850"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-5850"
                    >
                        <div
                            id="toc5850"
                            class="toc fixedsticky"
                            data-bind="descendantsComplete: function() {window.bindWillow('Sticky');}"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-5862"
                                        class="toc__"
                                        href="/hilfe/e-mail-benachrichtigungen/#c5862"
                                        @click.prevent="scrollTo('#c5862')"
                                    >
                                        {{ $t('helpMails.title') }}
                                    </a>
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-5858"
                                                href="/hilfe/e-mail-benachrichtigungen/#c5858"
                                                @click.prevent="scrollTo('#c5858')"
                                            >
                                                {{ $t('helpMails.section1.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-5860"
                                                href="/hilfe/e-mail-benachrichtigungen/#c5860"
                                            />
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-5850"
                                        class="toc__"
                                        href="/hilfe/e-mail-benachrichtigungen/#c5850"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-9">
                    <div
                        id="c5862"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-5862"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1>{{ $t('helpMails.title') }}</h1>
                                        <div
                                            id="c5858"
                                            class="ce-box   box"
                                            data-qa="ce-5858"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpMails.section1.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p><b>{{ $t('helpMails.section1.subtitle') }}</b></p>
                                                    <i18n-t
                                                        keypath="helpMails.section1.ps.1"
                                                        tag="p"
                                                    >
                                                        <router-link
                                                            :to="{ name: 'profile' }"
                                                            target="_blank"
                                                        >
                                                            {{ $t('helpMails.section1.link') }}
                                                        </router-link>.
                                                    </i18n-t>
                                                    <p>{{ $t('helpMails.section1.ps.2') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c5860"
                                            class="ce-box  box"
                                            data-qa="ce-5860"
                                        >
                                            <TableComponent
                                                :table-headers="tableHeaders"
                                                :table-rows="tableRows"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--right {
    img {
        max-width: 100%;
        height: auto;
    }
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
}
</style>
