import type { SelectOption } from '@/types/components/select.d.ts'

const countries: Array<SelectOption> = [
    { label: '', code: 'DE', i18nKey: 'enums.countries.DE' },
    { label: '', code: 'AT', i18nKey: 'enums.countries.AT' },
    { label: '', code: 'LU', i18nKey: 'enums.countries.LU' },
    { label: '', code: 'AF', i18nKey: 'enums.countries.AF' },
    { label: '', code: 'EG', i18nKey: 'enums.countries.EG' },
    { label: '', code: 'AX', i18nKey: 'enums.countries.AX' },
    { label: '', code: 'AL', i18nKey: 'enums.countries.AL' },
    { label: '', code: 'DZ', i18nKey: 'enums.countries.DZ' },
    { label: '', code: 'AS', i18nKey: 'enums.countries.AS' },
    { label: '', code: 'VI', i18nKey: 'enums.countries.VI' },
    { label: '', code: 'AD', i18nKey: 'enums.countries.AD' },
    { label: '', code: 'AO', i18nKey: 'enums.countries.AO' },
    { label: '', code: 'AI', i18nKey: 'enums.countries.AI' },
    { label: '', code: 'AQ', i18nKey: 'enums.countries.AQ' },
    { label: '', code: 'AG', i18nKey: 'enums.countries.AG' },
    { label: '', code: 'GQ', i18nKey: 'enums.countries.GQ' },
    { label: '', code: 'AR', i18nKey: 'enums.countries.AR' },
    { label: '', code: 'AM', i18nKey: 'enums.countries.AM' },
    { label: '', code: 'AW', i18nKey: 'enums.countries.AW' },
    { label: '', code: 'AZ', i18nKey: 'enums.countries.AZ' },
    { label: '', code: 'ET', i18nKey: 'enums.countries.ET' },
    { label: '', code: 'AU', i18nKey: 'enums.countries.AU' },
    { label: '', code: 'BS', i18nKey: 'enums.countries.BS' },
    { label: '', code: 'BH', i18nKey: 'enums.countries.BH' },
    { label: '', code: 'BD', i18nKey: 'enums.countries.BD' },
    { label: '', code: 'BB', i18nKey: 'enums.countries.BB' },
    { label: '', code: 'BY', i18nKey: 'enums.countries.BY' },
    { label: '', code: 'BE', i18nKey: 'enums.countries.BE' },
    { label: '', code: 'BZ', i18nKey: 'enums.countries.BZ' },
    { label: '', code: 'BJ', i18nKey: 'enums.countries.BJ' },
    { label: '', code: 'BM', i18nKey: 'enums.countries.BM' },
    { label: '', code: 'BT', i18nKey: 'enums.countries.BT' },
    { label: '', code: 'BO', i18nKey: 'enums.countries.BO' },
    { label: '', code: 'BQ', i18nKey: 'enums.countries.BQ' },
    { label: '', code: 'BA', i18nKey: 'enums.countries.BA' },
    { label: '', code: 'BW', i18nKey: 'enums.countries.BW' },
    { label: '', code: 'BV', i18nKey: 'enums.countries.BV' },
    { label: '', code: 'BR', i18nKey: 'enums.countries.BR' },
    { label: '', code: 'VG', i18nKey: 'enums.countries.VG' },
    { label: '', code: 'IO', i18nKey: 'enums.countries.IO' },
    { label: '', code: 'BN', i18nKey: 'enums.countries.BN' },
    { label: '', code: 'BG', i18nKey: 'enums.countries.BG' },
    { label: '', code: 'BF', i18nKey: 'enums.countries.BF' },
    { label: '', code: 'BI', i18nKey: 'enums.countries.BI' },
    { label: '', code: 'CL', i18nKey: 'enums.countries.CL' },
    { label: '', code: 'CN', i18nKey: 'enums.countries.CN' },
    { label: '', code: 'CK', i18nKey: 'enums.countries.CK' },
    { label: '', code: 'CR', i18nKey: 'enums.countries.CR' },
    { label: '', code: 'CI', i18nKey: 'enums.countries.CI' },
    { label: '', code: 'CW', i18nKey: 'enums.countries.CW' },
    { label: '', code: 'DK', i18nKey: 'enums.countries.DK' },
    { label: '', code: 'DM', i18nKey: 'enums.countries.DM' },
    { label: '', code: 'DO', i18nKey: 'enums.countries.DO' },
    { label: '', code: 'DJ', i18nKey: 'enums.countries.DJ' },
    { label: '', code: 'EC', i18nKey: 'enums.countries.EC' },
    { label: '', code: 'SV', i18nKey: 'enums.countries.SV' },
    { label: '', code: 'ER', i18nKey: 'enums.countries.ER' },
    { label: '', code: 'EE', i18nKey: 'enums.countries.EE' },
    { label: '', code: 'FK', i18nKey: 'enums.countries.FK' },
    { label: '', code: 'FO', i18nKey: 'enums.countries.FO' },
    { label: '', code: 'FJ', i18nKey: 'enums.countries.FJ' },
    { label: '', code: 'FI', i18nKey: 'enums.countries.FI' },
    { label: '', code: 'FR', i18nKey: 'enums.countries.FR' },
    { label: '', code: 'GF', i18nKey: 'enums.countries.GF' },
    { label: '', code: 'PF', i18nKey: 'enums.countries.PF' },
    { label: '', code: 'TF', i18nKey: 'enums.countries.TF' },
    { label: '', code: 'GA', i18nKey: 'enums.countries.GA' },
    { label: '', code: 'GM', i18nKey: 'enums.countries.GM' },
    { label: '', code: 'GE', i18nKey: 'enums.countries.GE' },
    { label: '', code: 'GH', i18nKey: 'enums.countries.GH' },
    { label: '', code: 'GI', i18nKey: 'enums.countries.GI' },
    { label: '', code: 'GD', i18nKey: 'enums.countries.GD' },
    { label: '', code: 'GR', i18nKey: 'enums.countries.GR' },
    { label: '', code: 'GL', i18nKey: 'enums.countries.GL' },
    { label: '', code: 'GP', i18nKey: 'enums.countries.GP' },
    { label: '', code: 'GU', i18nKey: 'enums.countries.GU' },
    { label: '', code: 'GT', i18nKey: 'enums.countries.GT' },
    { label: '', code: 'GG', i18nKey: 'enums.countries.GG' },
    { label: '', code: 'GN', i18nKey: 'enums.countries.GN' },
    { label: '', code: 'GW', i18nKey: 'enums.countries.GW' },
    { label: '', code: 'GY', i18nKey: 'enums.countries.GY' },
    { label: '', code: 'HT', i18nKey: 'enums.countries.HT' },
    { label: '', code: 'HM', i18nKey: 'enums.countries.HM' },
    { label: '', code: 'HN', i18nKey: 'enums.countries.HN' },
    { label: '', code: 'HK', i18nKey: 'enums.countries.HK' },
    { label: '', code: 'IN', i18nKey: 'enums.countries.IN' },
    { label: '', code: 'ID', i18nKey: 'enums.countries.ID' },
    { label: '', code: 'IM', i18nKey: 'enums.countries.IM' },
    { label: '', code: 'IQ', i18nKey: 'enums.countries.IQ' },
    { label: '', code: 'IR', i18nKey: 'enums.countries.IR' },
    { label: '', code: 'IE', i18nKey: 'enums.countries.IE' },
    { label: '', code: 'IS', i18nKey: 'enums.countries.IS' },
    { label: '', code: 'IL', i18nKey: 'enums.countries.IL' },
    { label: '', code: 'IT', i18nKey: 'enums.countries.IT' },
    { label: '', code: 'JM', i18nKey: 'enums.countries.JM' },
    { label: '', code: 'JP', i18nKey: 'enums.countries.JP' },
    { label: '', code: 'YE', i18nKey: 'enums.countries.YE' },
    { label: '', code: 'JE', i18nKey: 'enums.countries.JE' },
    { label: '', code: 'JO', i18nKey: 'enums.countries.JO' },
    { label: '', code: 'KY', i18nKey: 'enums.countries.KY' },
    { label: '', code: 'KH', i18nKey: 'enums.countries.KH' },
    { label: '', code: 'CM', i18nKey: 'enums.countries.CM' },
    { label: '', code: 'CA', i18nKey: 'enums.countries.CA' },
    { label: '', code: 'CV', i18nKey: 'enums.countries.CV' },
    { label: '', code: 'KZ', i18nKey: 'enums.countries.KZ' },
    { label: '', code: 'QA', i18nKey: 'enums.countries.QA' },
    { label: '', code: 'KE', i18nKey: 'enums.countries.KE' },
    { label: '', code: 'KG', i18nKey: 'enums.countries.KG' },
    { label: '', code: 'KI', i18nKey: 'enums.countries.KI' },
    { label: '', code: 'CC', i18nKey: 'enums.countries.CC' },
    { label: '', code: 'CO', i18nKey: 'enums.countries.CO' },
    { label: '', code: 'KM', i18nKey: 'enums.countries.KM' },
    { label: '', code: 'CD', i18nKey: 'enums.countries.CD' },
    { label: '', code: 'KP', i18nKey: 'enums.countries.KP' },
    { label: '', code: 'KR', i18nKey: 'enums.countries.KR' },
    { label: '', code: 'HR', i18nKey: 'enums.countries.HR' },
    { label: '', code: 'CU', i18nKey: 'enums.countries.CU' },
    { label: '', code: 'KW', i18nKey: 'enums.countries.KW' },
    { label: '', code: 'LA', i18nKey: 'enums.countries.LA' },
    { label: '', code: 'LS', i18nKey: 'enums.countries.LS' },
    { label: '', code: 'LV', i18nKey: 'enums.countries.LV' },
    { label: '', code: 'LB', i18nKey: 'enums.countries.LB' },
    { label: '', code: 'LR', i18nKey: 'enums.countries.LR' },
    { label: '', code: 'LY', i18nKey: 'enums.countries.LY' },
    { label: '', code: 'LI', i18nKey: 'enums.countries.LI' },
    { label: '', code: 'LT', i18nKey: 'enums.countries.LT' },
    { label: '', code: 'MO', i18nKey: 'enums.countries.MO' },
    { label: '', code: 'MG', i18nKey: 'enums.countries.MG' },
    { label: '', code: 'MW', i18nKey: 'enums.countries.MW' },
    { label: '', code: 'MY', i18nKey: 'enums.countries.MY' },
    { label: '', code: 'MV', i18nKey: 'enums.countries.MV' },
    { label: '', code: 'ML', i18nKey: 'enums.countries.ML' },
    { label: '', code: 'MT', i18nKey: 'enums.countries.MT' },
    { label: '', code: 'MA', i18nKey: 'enums.countries.MA' },
    { label: '', code: 'MH', i18nKey: 'enums.countries.MH' },
    { label: '', code: 'MQ', i18nKey: 'enums.countries.MQ' },
    { label: '', code: 'MR', i18nKey: 'enums.countries.MR' },
    { label: '', code: 'MU', i18nKey: 'enums.countries.MU' },
    { label: '', code: 'YT', i18nKey: 'enums.countries.YT' },
    { label: '', code: 'MX', i18nKey: 'enums.countries.MX' },
    { label: '', code: 'FM', i18nKey: 'enums.countries.FM' },
    { label: '', code: 'MD', i18nKey: 'enums.countries.MD' },
    { label: '', code: 'MC', i18nKey: 'enums.countries.MC' },
    { label: '', code: 'MN', i18nKey: 'enums.countries.MN' },
    { label: '', code: 'ME', i18nKey: 'enums.countries.ME' },
    { label: '', code: 'MS', i18nKey: 'enums.countries.MS' },
    { label: '', code: 'MZ', i18nKey: 'enums.countries.MZ' },
    { label: '', code: 'MM', i18nKey: 'enums.countries.MM' },
    { label: '', code: 'NA', i18nKey: 'enums.countries.NA' },
    { label: '', code: 'NR', i18nKey: 'enums.countries.NR' },
    { label: '', code: 'NP', i18nKey: 'enums.countries.NP' },
    { label: '', code: 'NC', i18nKey: 'enums.countries.NC' },
    { label: '', code: 'NZ', i18nKey: 'enums.countries.NZ' },
    { label: '', code: 'NI', i18nKey: 'enums.countries.NI' },
    { label: '', code: 'NL', i18nKey: 'enums.countries.NL' },
    { label: '', code: 'AN', i18nKey: 'enums.countries.AN' },
    { label: '', code: 'NE', i18nKey: 'enums.countries.NE' },
    { label: '', code: 'NG', i18nKey: 'enums.countries.NG' },
    { label: '', code: 'NU', i18nKey: 'enums.countries.NU' },
    { label: '', code: 'MK', i18nKey: 'enums.countries.MK' },
    { label: '', code: 'MP', i18nKey: 'enums.countries.MP' },
    { label: '', code: 'NF', i18nKey: 'enums.countries.NF' },
    { label: '', code: 'NO', i18nKey: 'enums.countries.NO' },
    { label: '', code: 'OM', i18nKey: 'enums.countries.OM' },
    { label: '', code: 'TL', i18nKey: 'enums.countries.TL' },
    { label: '', code: 'PK', i18nKey: 'enums.countries.PK' },
    { label: '', code: 'PS', i18nKey: 'enums.countries.PS' },
    { label: '', code: 'PW', i18nKey: 'enums.countries.PW' },
    { label: '', code: 'PA', i18nKey: 'enums.countries.PA' },
    { label: '', code: 'PG', i18nKey: 'enums.countries.PG' },
    { label: '', code: 'PY', i18nKey: 'enums.countries.PY' },
    { label: '', code: 'PE', i18nKey: 'enums.countries.PE' },
    { label: '', code: 'PH', i18nKey: 'enums.countries.PH' },
    { label: '', code: 'PN', i18nKey: 'enums.countries.PN' },
    { label: '', code: 'PL', i18nKey: 'enums.countries.PL' },
    { label: '', code: 'PT', i18nKey: 'enums.countries.PT' },
    { label: '', code: 'PR', i18nKey: 'enums.countries.PR' },
    { label: '', code: 'TW', i18nKey: 'enums.countries.TW' },
    { label: '', code: 'CG', i18nKey: 'enums.countries.CG' },
    { label: '', code: 'RE', i18nKey: 'enums.countries.RE' },
    { label: '', code: 'RW', i18nKey: 'enums.countries.RW' },
    { label: '', code: 'RO', i18nKey: 'enums.countries.RO' },
    { label: '', code: 'RU', i18nKey: 'enums.countries.RU' },
    { label: '', code: 'BL', i18nKey: 'enums.countries.BL' },
    { label: '', code: 'MF', i18nKey: 'enums.countries.MF' },
    { label: '', code: 'SB', i18nKey: 'enums.countries.SB' },
    { label: '', code: 'ZM', i18nKey: 'enums.countries.ZM' },
    { label: '', code: 'WS', i18nKey: 'enums.countries.WS' },
    { label: '', code: 'SM', i18nKey: 'enums.countries.SM' },
    { label: '', code: 'ST', i18nKey: 'enums.countries.ST' },
    { label: '', code: 'SA', i18nKey: 'enums.countries.SA' },
    { label: '', code: 'SE', i18nKey: 'enums.countries.SE' },
    { label: '', code: 'CH', i18nKey: 'enums.countries.CH' },
    { label: '', code: 'SN', i18nKey: 'enums.countries.SN' },
    { label: '', code: 'RS', i18nKey: 'enums.countries.RS' },
    { label: '', code: 'SC', i18nKey: 'enums.countries.SC' },
    { label: '', code: 'SL', i18nKey: 'enums.countries.SL' },
    { label: '', code: 'ZW', i18nKey: 'enums.countries.ZW' },
    { label: '', code: 'SG', i18nKey: 'enums.countries.SG' },
    { label: '', code: 'SX', i18nKey: 'enums.countries.SX' },
    { label: '', code: 'SK', i18nKey: 'enums.countries.SK' },
    { label: '', code: 'SI', i18nKey: 'enums.countries.SI' },
    { label: '', code: 'SO', i18nKey: 'enums.countries.SO' },
    { label: '', code: 'ES', i18nKey: 'enums.countries.ES' },
    { label: '', code: 'LK', i18nKey: 'enums.countries.LK' },
    { label: '', code: 'SH', i18nKey: 'enums.countries.SH' },
    { label: '', code: 'KN', i18nKey: 'enums.countries.KN' },
    { label: '', code: 'LC', i18nKey: 'enums.countries.LC' },
    { label: '', code: 'PM', i18nKey: 'enums.countries.PM' },
    { label: '', code: 'VC', i18nKey: 'enums.countries.VC' },
    { label: '', code: 'ZA', i18nKey: 'enums.countries.ZA' },
    { label: '', code: 'SD', i18nKey: 'enums.countries.SD' },
    { label: '', code: 'GS', i18nKey: 'enums.countries.GS' },
    { label: '', code: 'SS', i18nKey: 'enums.countries.SS' },
    { label: '', code: 'SR', i18nKey: 'enums.countries.SR' },
    { label: '', code: 'SJ', i18nKey: 'enums.countries.SJ' },
    { label: '', code: 'SZ', i18nKey: 'enums.countries.SZ' },
    { label: '', code: 'SY', i18nKey: 'enums.countries.SY' },
    { label: '', code: 'TJ', i18nKey: 'enums.countries.TJ' },
    { label: '', code: 'TZ', i18nKey: 'enums.countries.TZ' },
    { label: '', code: 'TH', i18nKey: 'enums.countries.TH' },
    { label: '', code: 'TG', i18nKey: 'enums.countries.TG' },
    { label: '', code: 'TK', i18nKey: 'enums.countries.TK' },
    { label: '', code: 'TO', i18nKey: 'enums.countries.TO' },
    { label: '', code: 'TT', i18nKey: 'enums.countries.TT' },
    { label: '', code: 'TD', i18nKey: 'enums.countries.TD' },
    { label: '', code: 'CZ', i18nKey: 'enums.countries.CZ' },
    { label: '', code: 'TN', i18nKey: 'enums.countries.TN' },
    { label: '', code: 'TR', i18nKey: 'enums.countries.TR' },
    { label: '', code: 'TM', i18nKey: 'enums.countries.TM' },
    { label: '', code: 'TC', i18nKey: 'enums.countries.TC' },
    { label: '', code: 'TV', i18nKey: 'enums.countries.TV' },
    { label: '', code: 'UG', i18nKey: 'enums.countries.UG' },
    { label: '', code: 'UA', i18nKey: 'enums.countries.UA' },
    { label: '', code: 'HU', i18nKey: 'enums.countries.HU' },
    { label: '', code: 'UM', i18nKey: 'enums.countries.UM' },
    { label: '', code: 'UY', i18nKey: 'enums.countries.UY' },
    { label: '', code: 'UZ', i18nKey: 'enums.countries.UZ' },
    { label: '', code: 'VU', i18nKey: 'enums.countries.VU' },
    { label: '', code: 'VA', i18nKey: 'enums.countries.VA' },
    { label: '', code: 'VE', i18nKey: 'enums.countries.VE' },
    { label: '', code: 'AE', i18nKey: 'enums.countries.AE' },
    { label: '', code: 'US', i18nKey: 'enums.countries.US' },
    { label: '', code: 'GB', i18nKey: 'enums.countries.GB' },
    { label: '', code: 'VN', i18nKey: 'enums.countries.VN' },
    { label: '', code: 'WF', i18nKey: 'enums.countries.WF' },
    { label: '', code: 'CX', i18nKey: 'enums.countries.CX' },
    { label: '', code: 'EH', i18nKey: 'enums.countries.EH' },
    { label: '', code: 'CF', i18nKey: 'enums.countries.CF' },
    { label: '', code: 'CY', i18nKey: 'enums.countries.CY' },
    { label: '', code: 'XK', i18nKey: 'enums.countries.XK' }
]

export default countries
