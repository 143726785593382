<template>
    <div
        v-if="!showWizard && show"
        id="watchlist-info-box"
    >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <Alert
                        :title="message"
                        data-qa-title="watchlist-suggest-box-message"
                        data-qa-close="watchlist-suggest-box-icon-close"
                        @on-close="close"
                    >
                        <div
                            class="watchlist-suggest-box--helpText"
                            data-qa="watchlist-suggest-box-helpText"
                        >
                            {{ $t('watchlist.suggest.help_text') }}
                        </div>
                        <div class="text-align--right mt">
                            <button
                                class="btn btn-secondary btn-small"
                                data-qa="watchlist-suggest-box-button"
                                @click="startWizard"
                            >
                                {{ $t('watchlist.suggest.button') }}
                            </button>
                        </div>
                    </Alert>
                </div>
            </div>
        </div>
    </div>
    <WatchlistWizard
        v-if="showWizard"
        :entries="entries"
    />
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { getCbraSignalSuggestions } from '@/services'
import type { SignalSuggestion } from '@/types/cbra/signalsuggestion'
import Alert from '../Alert.vue'
import WatchlistWizard from './WatchlistWizard.vue'
import { placeNewWizardSuggestionShownAction } from '@/helper/webtrekk/watchlist'

const { t } = useI18n()

const MAX_ENTRIES = 10

const show = ref(false)
const showWizard = ref(false)
const entries = ref<SignalSuggestion[]>([])
const count = ref(0)
const userStore = useUserStore()

const message = computed(() => {
    if (count.value === 0) {
        return ''
    } else if (count.value === 1) {
        return t('watchlist.suggest.found_singular', { count: count.value })
    } else if (count.value > MAX_ENTRIES) {
        return t('watchlist.suggest.found_more', [MAX_ENTRIES], 'watchlist.suggest.found_more')
    } else {
        return t('watchlist.suggest.found_plural',{ count: count.value })
    }
})

onMounted(async()=> {
    await userStore.getCbraUser()
    await handleFetch()
})

async function handleFetch() {
    try {
        const { data } = await getCbraSignalSuggestions(userStore.cbraUser.memberUserId, {
            maxEntries: MAX_ENTRIES + 1
        })
        if (data instanceof Array) {
            initSuccess(data)
        } else {
            initFailure()
        }
    } catch (error) {
        console.error(error)
        initFailure()
    }
}

function initSuccess(data: SignalSuggestion[]) {
    if (data.length > 0) {
        show.value = true
        showWizard.value = false
        if (data.length > MAX_ENTRIES) {
            entries.value = data.slice(0, MAX_ENTRIES)
        } else {
            entries.value = data
        }
        count.value = data.length
        placeNewWizardSuggestionShownAction(entries.value.length)
    } else {
        initFailure()
    }
}

function initFailure() {
    show.value = false
    showWizard.value = false
    entries.value = []
    count.value = 0
}

function close() {
    show.value = false
}

function startWizard() {
    showWizard.value = true
}

</script>