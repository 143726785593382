<!-- eslint-disable vue/no-v-html -->
<template>
    <div 
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'report' }">
                    {{ $t('report.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('reportCompany.breadcrumb') }}</li>
        </Breadcrumb>

        <CompanySearch />

        <div class="content-spacer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>{{ $t('reportCompany.title') }}</h3>
                        <ProductTabs />
                    </div>
                </div>
            </div>
        </div>

        <div class="content-spacer">
            <div
                class="bg-brand-light-grey box-spacing"
            >
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="box-shadow-xy bg-white small-box-spacing mb mt">
                                <h3>{{ $t('reportCompany.boxes.1.title') }}</h3>
                                <div class="d-flex gap mb">
                                    <a href="/hilfe/bonitaetsauskunft/wirtschaftsauskunft/">
                                        <img
                                            src="@/assets/img/bonitaetsauskunft/Wirtschaftsauskunft.png"
                                            width="500"
                                        >
                                    </a>
                                    <p
                                        class="mb-small"
                                        v-html="$t('reportCompany.boxes.1.description')"
                                    />
                                </div>
                                <a href="/hilfe/bonitaetsauskunft/wirtschaftsauskunft/">
                                    {{ $t('reportCompany.boxes.1.linkText') }}
                                </a>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="box-shadow-xy bg-white small-box-spacing mb mt">
                                <h3>{{ $t('reportCompany.boxes.2.title') }}</h3>
                                <div
                                    class="mb-small"
                                    v-html="$t('reportCompany.boxes.2.description')"
                                />
                                <a href="/bonitaetsauskunft/bonitaet-einer-firma-pruefen">
                                    {{ $t('reportCompany.boxes.2.linkText') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-spacer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <ReportFAQAccordions />
                    </div>
                </div>
            </div>
        </div>

        <div class="content-spacer">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-8 offset-md-2">
                        <h3>{{ $t('reportCompany.compare.title') }}</h3>
                        <img src="@/assets/img/bonitaetsauskunft/company/csm_vergleich-auskuenfte-DE.gif">
                        <p>{{ $t('reportCompany.compare.description') }}</p>
                        <a href="/hilfe/bonitaetsauskunft/">
                            {{ $t('reportCompany.compare.linkText') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <ReportFooterBoxes />
    </div>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import ProductTabs from '@/components/Report/ProductTabs.vue'
import CompanySearch from '@/components/CompanySearch.vue'
import ReportFAQAccordions from '@/components/Report/ReportFAQAccordions.vue'
import ReportFooterBoxes from '@/components/Report/ReportFooterBoxes.vue'

const id = 'portal.mc.report-company'

</script>   