<template>
    <div
        v-for="(surrogate, index) in mailSurrogates"
        :key="index"
        class="box-shadow-xy mb"
    >
        <div class="extra-padding">
            <EditSurrogateForm 
                :key="`${componentKey}-${index}`"
                :index="index"
                :surrogate="surrogate" 
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, watch, ref } from 'vue'
import { storeToRefs } from 'pinia'

import EditSurrogateForm from './EditSurrogateForm.vue'

import { useSurrogatesStore } from '@/stores/surrogates'

const cbraSurrogatesStore = useSurrogatesStore()
const { mailSurrogates } = storeToRefs(cbraSurrogatesStore)

const componentKey = ref(0)

const changeDates = () => {
    for (const surrogate of mailSurrogates.value) {
        if (typeof surrogate.validFrom === 'number') {
            surrogate.validFrom = new Date(surrogate.validFrom)
            if (surrogate.validTo !== null) {
                surrogate.validTo = new Date(surrogate.validTo)
            }
        }
    }
}

onMounted(changeDates)

watch(mailSurrogates, () => {
    componentKey.value += 1 // force re-initializing form child components
    changeDates()
}, { immediate: true })
</script>

<style scoped>
.extra-padding {
    padding: 20px;
}
</style>
