<template>
    <div
        :id="id"
        :data-qa="dataQa"
        class="crefo-ui-modal"
    >
        <div class="modal-content">
            <div class="modal-header">
                <div class="small-content-spacer" />
                <FontAwesomeIcon
                    v-if="icon"
                    :icon="icon"
                    size="3x"
                    :color="color"
                    :data-qa="iconDataQa"
                />
                <div class="small-content-spacer" />
                <h2 data-qa="messageBox-header">
                    {{ title }}
                </h2>
                <span 
                    class="modal-close" 
                    data-qa="modal-close-icon"
                    @click.stop="onClose" 
                />
            </div>
            <div class="modal-body">
                <div 
                    class="modal-text text-brand-dark-grey" 
                    data-qa="messageBox-text"
                >
                    <slot />
                </div>
                <template v-if="!hideButtonSection">
                    <div class="small-content-spacer" />
                    <div class="modal-buttons mb-small">
                        <button
                            v-if="cancelText !== ''"
                            class="btn btn-secondary mt ml"
                            :data-qa="cancelDataQaValue"
                            @click.stop="onClose"
                        >
                            {{ cancelText }}
                        </button>               
                        <button
                            v-if="confirmText !== ''"
                            class="btn mt ml btn-default"
                            :data-qa="confirmDataQaValue"
                            :disabled="confirmDisabled"
                            @click.stop="onConfirm"
                        >
                            {{ confirmText }}
                        </button>
                    </div>
                </template>
                <div class="modal-error-div text-brand-dark-grey text-small">
                    <div
                        v-if="errorMessage"
                        class="small-content-spacer"
                    >
                        <span class="modal-memberUserId">{{ errorMessage }}</span>
                    </div>
                    <div
                        v-if="errorContext"
                        class="small-content-spacer"
                    >
                        <div
                            v-if="errorContext.information.correlationId"
                        >
                            Fehler-Id: <span class="correlation-id">{{ errorContext.information.correlationId }}</span>
                        </div>
                        <div
                            v-if="errorContext.information.traceId"
                        >
                            Trace-ID: <span class="trace-id">{{ errorContext.information.traceId }}</span>
                        </div>
                        <div
                            v-if="errorContext.information.user"
                        >
                            Mitgliedsnummer: <span class="member-user-id">{{ errorContext.information.user.memberUserId }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
/**
 * Modal component
 * 
 * Used to display a modal with a title, content.
 * An optional confirm button is displayed, when a confirm text is provided.
 * An optional cancel button is displayed, when a cancel text is provided.
 * 
 * @prop {string} id - The id of the modal
 * @prop {string} title - The title of the modal
 * @prop {string} icon - The icon of the modal. Can be 'info', 'warning' or 'error'.
 * @prop {string} confirmText - The text of the confirm button. Default: None
 * @prop {string} cancelText - The text of the cancel button. Default: None
 * @prop {string} dataQa - The data-qa attribute of the modal
 * @prop {string} confirmDataQa - The data-qa attribute of the modal's confirm button
 * @prop {string} cancelDataQa - The data-qa attribute of the modal's cancel button
 * @prop {string} errorMessage - The error message of the modal
 * @prop {ErrorContext} errorContext - The error context of the modal
 * @prop {boolean} hideButtonSection - Whether tto hide the button section (for custom modals only). Default: false
 * 
 * @emits onClose - When the cancel button is clicked or the modal is dismissed
 * @emits onConfirm - When the confirm button is clicked
 * 
 * Usage:
 * 
    <template>
        <Modal
            v-if="modal" 
            :id="modal.id"
            :title="modal.title"
            :confirm-text="modal.confirmText"
            :cancel-text="modal.cancelText"
            :icon="modal.icon"
            :data-qa="modal.dataQa"
            @on-confirm="onConfirm"
            @on-close="onClose" <!-- always set, so closing by pressing ESC key is possible -->
        />
    </template>

    <script lang="ts" setup>
    import { ref } from 'vue'
    import type { ModalProps } from '@/types/components/modal'
    import Modal from '@/components/Modal.vue'

    const modal = ref<ModalProps>({
        id: 'modal',
        title: 'Modal title',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        icon: 'info',
        dataQa: 'modal'
    })

    const onClose = () => {
        modal.value = undefined
        // .. do something
    }

    const onConfirm = () => {
        modal.value = undefined
        // .. do something
    }
*/
import { computed, onMounted, PropType } from 'vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faCircleExclamation,
    faCircleXmark,
    faCircleInfo
} from '@fortawesome/pro-regular-svg-icons'

const emit = defineEmits(['onConfirm', 'onClose'])

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: false,
        default: ''
    },
    icon: {
        type: String,
        required: false,
        default: undefined,
        validator: (value: string) => {
            return ['info', 'warning', 'error'].includes(value)
        }
    },
    confirmText: {
        type: String,
        required: false,
        default: ''
    },
    confirmDisabled: {
        type: Boolean,
        required: false,
        default: false
    },
    cancelText: {
        type: String,
        required: false,
        default: ''
    },
    errorContext: {
        type: Object as PropType<ErrorContext>,
        required: false,
        default: undefined
    },
    errorMessage: {
        type: String,
        required: false,
        default: undefined
    },
    dataQa: {
        type: String,
        required: false,
        default: 'crefo-ui-modal'
    },
    confirmDataQa: {
        type: String,
        required: false,
        default: ''
    },
    cancelDataQa: {
        type: String,
        required: false,
        default: ''
    },
    hideButtonSection: {
        type: Boolean,
        required: false,
        default: false
    }
})

const cancelDataQaValue = computed(() => {
    return props.cancelDataQa !== '' ? props.cancelDataQa : 'messageBox-cancel'
})

const confirmDataQaValue = computed(() => {
    return props.confirmDataQa !== '' ? props.confirmDataQa : 'messageBox-confirm'
})

const iconDataQa = computed(() => {
    switch (props.icon) {
    case 'warning':
        return 'messageBox-warning-info'
    case 'error':
        return 'messageBox-error-info'
    case 'info':
        return 'messageBox-icon-info'
    default:
        return 'messageBox-icon-info'
    }
})

const icon = computed(() => {
    switch (props.icon) {
    case 'warning':
        return faCircleExclamation
    case 'error':
        return faCircleXmark
    case 'info':
        return faCircleInfo
    default:
        return undefined
    }
})

const color = computed(() => {
    switch (props.icon) {
    case 'warning':
        return 'var(--color-warning)'
    case 'error':
        return 'var(--color-error)'
    case 'info':
        return 'var(--color-c-p1)'
    default:
        return 'var(--color-c-p1)'
    }
})

// function hasOnCloseListener() {
//     /**
//      * In Vue 3, the $listeners object has been removed. 
//      * The listeners are now part of the $attrs object and are prefixed with on.
//      * However, this doesn't work if you are defining custom events in the emits 
//      * property e.g. with `emits: ['click']` present on your component, 
//      * $attrs.onClick will be undefined. 
//      * 
//      * This is a workaround to check if the onClose event is listened to,
//      * based on: https://stackoverflow.com/a/76208995/624466
//      * 
//      * TODO: Replace with a better solution once available
//      */
//     console.log(getCurrentInstance()?.vnode.props)
//     return !!getCurrentInstance()?.vnode.props?.onOnClose
// }

function closeByKey(e: any) {
    if (e.key === 'Escape' || e.key === 'Enter') {
        console.log('close by key')
        e.preventDefault()
        onClose()
    }
}

function onClose() {
    document.removeEventListener('keydown', closeByKey)
    emit('onClose')
}

function onConfirm() {
    document.removeEventListener('keydown', closeByKey)
    emit('onConfirm')
}

onMounted(() => {
    document.addEventListener('keydown', closeByKey)
})
</script>

<style scoped lang="less">
.crefo-ui-modal {
    cursor: default;
}
.modal-text {
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.5px;
}
</style>
