<template>
    <div>
        <MailSurrogatesList v-if="mailSurrogates.length" />
        <AddSurrogateForm v-if="availableSurrogates.length" />
    </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'

import MailSurrogatesList from './MailSurrogatesList.vue'
import AddSurrogateForm from './AddSurrogateForm.vue'

import { useSurrogatesStore } from '@/stores/surrogates'

const cbraSurrogatesStore = useSurrogatesStore()
const { 
    availableSurrogates, 
    mailSurrogates
} = storeToRefs(cbraSurrogatesStore)
</script>
