import { getCbraTransaction, getCbraTransactions, putCbraUpdateReportTracking, saveCbraNote, setFavouriteCbraTransactionsById } from '@/services'
import CONSTANTS from '@/data/constants'
import type { CbraTransaction, CbraUpdateReportTrackingPayload } from '@/types/cbra/transactions'
import { getPortfolioHeader } from './features'
import { isAxiosError } from 'axios'
import { showError } from './error'

export const CONSTS: any = {
    SORT: {
        CREATION_TIME_ASC: 'CREATION_TIME_ASC',
        CREATION_TIME_DESC: 'CREATION_TIME_DESC',
        MODIFICATION_TIME_ASC: 'MODIFICATION_TIME_ASC',
        MODIFICATION_TIME_DESC: 'MODIFICATION_TIME_DESC',
        OBSERVATION_END_TIME_ASC: 'OBSERVATION_END_TIME_ASC',
        OBSERVATION_END_TIME_DESC: 'OBSERVATION_END_TIME_DESC'
    },
    FILTER: {
        ALL: 'ALL',
        FAVOURITES: 'FAVOURITES',
        REPORT: 'REPORT',
        CONSUMER: 'CONSUMER',
        COLLECTION: 'COLLECTION'
    },
    TRANSACTION_TYPE: {
        REPORT: 'REPORT',
        COLLECTION: 'COLLECTION',
        CONSUMER: 'CONSUMER'
    },
    REPORT_OBSERVATION_TYPE: {
        ALL_OBSERVATION: 'ALL_OBSERVATION',
        FREE_OBSERVATION: 'FREE_OBSERVATION',
        NON_FREE_OBSERVATION: 'NON_FREE_OBSERVATION',
        WITHOUT_OBSERVATION: 'WITHOUT_OBSERVATION'
    },
    REPORT_OBSERVATION_EXPIRE_TYPE: {
        EXPIRED_OBSERVATIONS: 'EXPIRED_OBSERVATIONS',
        EXPIRING_OBSERVATIONS: 'EXPIRING_OBSERVATIONS'
    },
    NON_DELETEABLE_STATUS: [
        'MONITORING_UNBEFRISTET',
        'NACHTRAGSFRIST_LAUFEND'
    ],
    TOPIC: 'TransactionService'
}

export function getTransactionsRequestParameters(
    searchText: string,
    pageSize: number,
    pageStartIndex: number,
    sort: any,
    filter: any,
    optionalFilterParameters: any = {}
) {
    return Object.assign({
        pageSize: pageSize || 10,
        pageStartIndex: pageStartIndex || 0,
        sort: sort || CONSTS.SORT.CREATION_TIME_DESC,
        transactionType: getTransactionType(filter),
        searchText: searchText,
        favourites: isFavouritesFilterActive(filter),
    }, optionalFilterParameters)
}

export function getTransactionType(filterMode: string) {
    return CONSTS.TRANSACTION_TYPE[filterMode]
}

export function isFavouritesFilterActive(filterMode: string) {
    return filterMode === CONSTS.FILTER.FAVOURITES
}

export function isProductInProgress(transaction: CbraTransaction) {
    let status = false

    if (transaction && transaction.transactionType) {
        switch (transaction.transactionType) {
        case 'REPORT':
            status = transaction.reportTransaction!.reportTransactionStatus === 'IN_PROGRESS' || false
            break
        case 'COLLECTION':
            status = transaction.collectionTransaction!.collectionTransactionStatus === 'IN_PROGRESS' || false
            break
        default:
            status = false
            break
        }
    }

    return status
}

export function isReportTransactionErasable(transaction: CbraTransaction) {
    let isErasable = false

    switch (transaction.transactionType) {
    case CONSTANTS.TRANSACTION_TYPES.CONSUMER:
        isErasable = !!(transaction.consumerTransaction && transaction.consumerTransaction.erasable)
        break
    case CONSTANTS.TRANSACTION_TYPES.INVOLVEMENTDATA:
        isErasable = !!(transaction.involvementdataTransaction && transaction.involvementdataTransaction.erasable)
        break
    default:
        if (transaction.reportTransaction && transaction.reportTransaction.erasable) {
            isErasable = true
        } else if (
            transaction.reportTransaction &&
            transaction.reportTransaction.reportTransactionTracking &&
            transaction.reportTransaction.reportTransactionTracking.trackingStatus &&
            CONSTS.NON_DELETEABLE_STATUS.includes(transaction.reportTransaction.reportTransactionTracking.trackingStatus)
        ) {
            isErasable = false
        }
    // return false // not in the orginal code, but should be here, right!?
    }

    return isErasable
}

export function toggleFavourite(transaction: Partial<CbraTransaction> & Pick<CbraTransaction, 'crefoTransactionId' | 'favourite'> ) {
    return new Promise((resolve, reject) => {
        const payload = {
            favourite: transaction.favourite
        }
        if (!transaction || !transaction.crefoTransactionId) {
            reject('No transaction or transaction id found')
        }

        let isOwnTransaction = false 
        if ( transaction.cbraUser?.memberUserId === transaction.reportTransaction?.memberUserId 
            || transaction.cbraUser?.memberUserId === transaction.collectionTransaction?.memberUserId 
            || transaction.cbraUser?.memberUserId === transaction.involvementdataTransaction?.memberUserId
            || transaction.cbraUser?.memberUserId === transaction.consumerTransaction?.memberUserId
        ) {
            isOwnTransaction = true
        } 

        let headers = {}
        if (!isOwnTransaction) {
            headers = {
                'viewScope': 'MEMBER'
            }
        }

        setFavouriteCbraTransactionsById(transaction.crefoTransactionId.toString(), payload, headers).then((response: any) => {
            if (response.status === 200 && transaction) {
                resolve(!transaction.favourite)
            }
        }).catch((error: any) => {
            console.log('error', error)
            reject(error)
        })
    })
}

export async function getTransactionsByBusinessId(businessId: string, pageSize: number, pageStartIndex: number, sort: string, filter:string, optionalFilterParameters = {}) {
    const data = Object.assign({
        pageSize: pageSize || 10,
        pageStartIndex: pageStartIndex || 0,
        sort: sort || CONSTS.SORT.CREATION_TIME_DESC,
        transactionType: getTransactionType(filter),
        businessId: businessId,
        favourites: isFavouritesFilterActive(filter)
    }, optionalFilterParameters)

    return await getCbraTransactions(data,getPortfolioHeader() )
}

export async function saveNoteForTransaction(note: string, transactionID: string) {
    const data = { 'text': note }
    try {
        await saveCbraNote(transactionID, data)
    } catch (error) {
        if (isAxiosError( error) && error.response?.status === 403) {
            return showError({ topic: CONSTS.TOPIC, func: 'update_notify_403', xhr: error })
        } else if (isAxiosError( error)) {
            return showError({ topic: CONSTS.TOPIC, xhr: error })
        }
    }
}

export namespace TransactionService {
    export async function updateReportTracking(productOrderId: string, requestData: CbraUpdateReportTrackingPayload, transaction: CbraTransaction) {
        if (!requestData.ueberwachung) {
            return
        }

        const isOwnTransaction = (transaction.reportTransaction && transaction.cbraUser && transaction.cbraUser.memberUserId !== transaction.reportTransaction.memberUserId) ? false : true
        let header = {}
        if (!isOwnTransaction) {
            header = {
                'viewScope': 'MEMBER'
            }
        }
        await putCbraUpdateReportTracking(productOrderId, requestData, header)
    }
    export function getConsumerTransaction(transactionId: string) {
        return getCbraTransaction(transactionId)
    }
    export async function search(query: string) {
        const data = Object.assign({
            searchText: query,
            pageSize: 10,
            pageStartIndex: 0,
            sort: CONSTS.SORT.CREATION_TIME_DESC,
            filter: CONSTS.FILTER.ALL,
        }, {})

        return await getCbraTransactions(data,getPortfolioHeader() )
    }
}
