<template>
    <a
        href="#"
        :data-qa="dataQa"
        :class="classes"
        @click.prevent="_click"
    >
        <slot />
    </a>
    <Modal
        v-if="modal"
        :id="modal.id"
        :title="modal.title"
        :cancel-text="modal.cancelText"
        :confirm-text="modal.confirmText"
        :error-context="modal.errorContext"
        :icon="modal.icon"
        @on-close="modal.onClose"
        @on-confirm="modal.onConfirm"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="modal.content" />
    </Modal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import axios from '@/services/tracing-axios'
import Modal from './Modal.vue'
import type { ModalProps } from '@/types/components/modal'
import { useI18n } from 'vue-i18n'
import { checkUserRight } from '@/helper/services/userRights'
import { useUserStore } from '@/stores/user'
import type { CbraMemberUserId } from '@/types/cbra/shared'

const props = withDefaults(defineProps<{
    classes?: string
    url?: string
    viewScope?: string
    dataQa?: string
    downloadContext?: string
    check?: boolean
    memberUserId?: CbraMemberUserId
}>(), {
    classes: '',
    url: '',
    viewScope: undefined,
    dataQa: 'download-link',
    downloadContext: '',
    check: false,
    memberUserId: ''
})

const { t } = useI18n()

const checkSucceeded = ref(false)
const modal = ref<ModalProps>()
const headers = ref({})

const userStore = useUserStore()

function _downloadFile() {
    const headers = {}
    if (props.viewScope) {
        headers.viewScope = props.viewScope
    }
    axios({
        url: props.url,
        method: 'GET',
        responseType: 'blob',
        headers: headers
    }).then((response) => {
        let filename = ''

        const disposition = response.headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
            }
        }

        const type = response.headers['content-type']
        let blob = new Blob([response.data], { type: type })

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
            window.navigator.msSaveBlob(blob, filename)
        } else {
            const URL = window.URL || window.webkitURL
            const downloadUrl = URL.createObjectURL(blob)

            if (filename) {
                // use HTML5 a[download] attribute to specify filename
                const a = document.createElement('a')
                // safari doesn't support this yet
                if (typeof a.download === 'undefined') {
                    window.location.href = downloadUrl
                } else {
                    a.href = downloadUrl
                    a.download = filename
                    document.body.appendChild(a)
                    a.click()
                    try {
                        a.remove()
                    } catch (e) {
                        // IE 11 may not be able to remove and triggers exception
                    }
                }
            } else {
                window.location.href = downloadUrl
            }

            setTimeout(() => { URL.revokeObjectURL(downloadUrl) }, 100) // cleanup
        }
    })

    return true
}

/**
     * Handle click on link.
     *
     */
function _click() {  
    _handleCheck()
}

/**
     * Handle HEAD request to check download link.
     *
     */
function _handleCheck() {
    console.log('Logger -> FILE: src/components/DownloadLink.vue -> LINE: 135 -> props.viewScope', props.viewScope)
    
    if (checkSucceeded.value || !props.check || !props.url) {
        _handleClick()
    } else {
        const rights = checkUserRight('portfolioReport')
        const user = userStore.cbraUser
        if (rights.grants === true && user.memberUserId !== props.memberUserId || props.viewScope === 'MEMBER') {
            headers.value = {
                viewScope: 'MEMBER'
            }
        }

        axios({
            url: props.url,
            method: 'HEAD',
            headers: headers.value
        }).then((response) => {
            if (response.headers['location']) {
                _handleClickError(response.headers['location'])
            } else {
                checkSucceeded.value = true
                _handleClick()
            }
        }).catch((error) => {
            _handleClickError()
        })
    }
}

/**
     * Handle download.
     */
function _handleClick() {
    if (typeof props.url === 'string' && props.url.length > 0) {
        _downloadFile()
        // if (props.webtrekkCallback && typeof props.webtrekkCallback === 'function') {
        //     this.webtrekkCallback.call(props.context, {
        //         considerPageIndexInSearch: false,
        //         isExport: true,
        //         isError: false
        //     });
        // }
    }
}

/**
     * Handle error case locally.
     *
     * Is called, when there is onError callback, or onError callback returns not false.
     */
function _handleClickError(locationHeader?: string) {
    let location = locationHeader
    if (location && props.downloadContext === 'transaction.export') {
        let match = location.match(/error=true&limit=/g)
        if (match) {
            let exportLimit = _findGetParameter('limit', location)
            modal.value = {
                id: 'export-limit',
                title: t('error.transaction.exportLimit'),
                content: t('error.transaction.exportLimit.description', [parseInt(exportLimit)]),
                icon: 'warning'
            }
        } else {
            modal.value ={
                id: 'export-error',
                title: t('error.transaction.export'),
                content: t('error.transaction.export.description'),
                icon: 'error'
            }
        }
    } else {
        // _showDefaultError(jqXHR)
    }
}

function _findGetParameter(parameterName: string, location: string) {
    let result = ''
    let tmp = []
    location.split('?')[1]
        .split('&')
        .forEach(function(item) {
            tmp = item.split('=')
            if (tmp[0] === parameterName) {
                result = decodeURIComponent(tmp[1])
            }
        })
    return result
}
</script>
