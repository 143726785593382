<template>
    <div
        v-if="loaded"
        class="searchform__wrapper mb"
    >
        <div class="container">
            <div
                v-if="!hideTitle"
                class="row"
            >
                <div class="col-12 mb">
                    <span class="text-bold text-brand-darkest-blue">{{ $t('report.search.title') }}</span>
                </div>
            </div>
            <form
                ref="formElement"
                :action="actionUrl"
                method="GET"
            >
                <div class="row mb-small">
                    <div class="col-12 col-md-8">
                        <div
                            class="autosuggest-container"
                        >
                            <InputText
                                v-model="query"
                                label=""
                                name="query"
                                :placeholder="$t('report.search.placeholder')"
                                data-qa="solvencyCheck-searchInput"
                                error-msg-data-qa="solvencyCheck-searchInput-message"
                                :no-valid-class="true"
                                :onkeyup="onAutosuggest"
                                autocomplete="off"
                                @keydown.prevent.arrow-up="onArrowUp"
                                @keydown.prevent.arrow-down="onArrowDown"
                                @keydown.prevent.enter="onEnter"
                            />
                            <ul
                                v-if="suggestions.length > 0 && showSuggestions"
                                class="suggestion-container"
                            >
                                <li
                                    v-for="(suggestion, index) in suggestions"
                                    :key="index"
                                    :class="selectedSuggestionIndex === index ? 'selected' : ''"
                                    data-qa="suggestionSearchResult"
                                    @click="handleSuggestionClick(index)"
                                >
                                    {{ suggestion.businessName }}, {{ suggestion.postalAddress.city }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-8 col-md-3">
                        <Select
                            id="country"
                            v-model="country"
                            label=""
                            :options="countries"
                            data-qa="solvencyCheck-selectCountry"
                        />
                    </div>
                    <div class="col-4 col-md-1  text-align-right">
                        <button
                            class="btn btn-default btn-min"
                            data-qa="myTransactions-searchButton"
                            :disabled="query.length < 2"
                            type="submit"
                            @click.prevent="onEnter"
                        >
                            <i
                                aria-hidden="true"
                                class="crefo-ui-icon icon-magnifier"
                            />
                        </button>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-12">
                    <router-link
                        :to="{ name: 'advanced-search', query: { query: query, country: country, clearStorage: 'true' }} "
                        class="link-icon-next"
                        data-qa="advancedSearch"
                    >
                        <span>{{ $t('transaction.list.extendedSearch') }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import InputText from '@/components/Input/InputText.vue'
import Select from '@/components/Input/Select.vue'
import { getCbraUserPreferences , getCbraSearchSuggestion , getCbraSearchSuggestConfiguration } from '@/services'
import countries from '@/data/countries'
import { CbraSearchSuggestConfiguration } from '@/types/cbra/searchConfiguration'
import { CbraSearchSuggestion } from '@/types/cbra/searchSuggestion'
import debounce from '@/helper/debounce'
import { useI18n } from 'vue-i18n'
import { udsLinks, advancedSearchLinks, advancedSearchResultsLinks } from '@/data/typo3Links'
import Storage from '@/helper/storage'
import CONSTANTS from '@/data/constants'

const props = defineProps({
    hideTitle: {
        type: Boolean,
        default: false
    },
    isSearchResults: {
        type: Boolean,
        default: false
    }
})

const { locale } = useI18n()
const route = useRoute()
const router = useRouter()

const countriesDAL = ['DE', 'AT', 'LU']
const formElement = ref<HTMLFormElement>()
const searchConfig = ref<CbraSearchSuggestConfiguration>()
const query = ref(route.query.query?.toString() || route.query.businessName?.toString() || '')
const country = ref(route.query.country?.toString() || 'DE')
const loaded = ref(false)
const timestampLastSend = ref(Date.now() - 10000)
const suggestions = ref<CbraSearchSuggestion[]>([])
const selectedSuggestionIndex = ref(-1)
const showSuggestions = ref(false)

onMounted(() => {
    initSearchSuggestConfig()
    if (route.query.country) {
        loaded.value = true
    } else {
        getCbraUserPreferences().then((response: any) => {
            if (
                response.status === 200 &&
                response.data.defaultSearchCountry &&
                response.data.defaultSearchCountry !== '' &&
                response.data.defaultSearchCountry !== country.value
            ) {
                country.value = response.data.defaultSearchCountry
            }
            loaded.value = true
        }).catch(() => {
            loaded.value = true
        })
    }
})

const actionUrl = computed(() => {
    return countriesDAL.includes(country.value) ? advancedSearchResultsLinks[locale.value] : advancedSearchLinks[locale.value]
})

const timeout = computed(() => {
    return searchConfig.value?.typingTimeout || 500
})

function hasMaximumIntervalExpired() {
    if (!searchConfig.value) return false
    if (searchConfig.value.maximumInterval) {
        return Date.now() - timestampLastSend.value >= searchConfig.value.maximumInterval
    } else {
        return true
    }
}

function hasReachedCharacterThreshold() {
    return searchConfig.value ? (query.value.length >= searchConfig.value.characterThreshold) : true
}

function hasWordSeparatorInLastLetter() {
    if (!searchConfig.value) return false
    const lastCharacter = query.value.slice(-1)
    const wordSeparatorsList = searchConfig.value.wordSeparatorsList
    return Array.isArray(wordSeparatorsList) ? wordSeparatorsList.includes(lastCharacter) : true
}

const onAutosuggest = debounce(async() => {
    if (props.isSearchResults) return
    if (!hasReachedCharacterThreshold() && !hasWordSeparatorInLastLetter()) {
        showSuggestions.value = false
        return
    }
    if (!hasMaximumIntervalExpired()) {
        showSuggestions.value = false
        return
    }
    if (typeof query.value !== 'string') {
        showSuggestions.value = false
        return
    }

    timestampLastSend.value = Date.now()

    try {
        const response = await getCbraSearchSuggestion({
            text: query.value,
            country: country.value
        })

        const maxResultItems = searchConfig.value ? searchConfig.value.searchResults : 10
        const limitedSuggestions = response.data.searchHits.slice(0, maxResultItems)
        suggestions.value = limitedSuggestions
        showSuggestions.value = true
    } catch (error) {
        showSuggestions.value = false
        suggestions.value = []
    }
}, timeout.value)

async function initSearchSuggestConfig() {
    try {
        const response = await getCbraSearchSuggestConfiguration()
        searchConfig.value = response.data
    } catch (error) {
        searchConfig.value = undefined
    }
}

function onEnter() {
    if (selectedSuggestionIndex.value > -1) {
        handleSuggestionClick(selectedSuggestionIndex.value)
    } else {
        if (formElement.value && (!props.isSearchResults || !countriesDAL.includes(country.value)) ) {
            formElement.value.submit()
        }
        if (props.isSearchResults && countriesDAL.includes(country.value)) {
            router.push({ name: 'advanced-search-results', query: { query: query.value, country: country.value } })
        }
        showSuggestions.value = false
    }
}

function onArrowUp() {
    if (selectedSuggestionIndex.value > 0) {
        selectedSuggestionIndex.value--
    }
}

function onArrowDown() {
    if (selectedSuggestionIndex.value < suggestions.value.length - 1) {
        selectedSuggestionIndex.value++
    }
}

function handleSuggestionClick(index: number) {
    const url = `${udsLinks[locale.value]}?${new URLSearchParams({
        query: query.value,
        country: country.value,
        businessId: suggestions.value[index].businessId
    }).toString()}`

    Storage.set(CONSTANTS.EVENTS.SAVED_BUSINESS_ITEM, suggestions.value[index])

    location.assign(url)
}

</script>

<style scoped lang="less">
.autosuggest-container {
    position: relative;
}
.suggestion-container {
    list-style-type: none;
    position: absolute;
    background: #fff;
    border: 1px solid var(--color-c-grey);
    z-index: 200;
    top: 56px;
    border-top: none;
    max-height: 405px;
    overflow-y: auto;
    z-index: 2;
    width: 100%;

    li {
        padding: 20px;
        cursor: pointer;
        border-bottom: 1px solid var(--color-c-grey);
        &:hover, &.selected {
            background: var(--color-c-p1);
            color: #fff;
        }
        &:last-of-type {
            border-bottom: none;
        }
    }
}
</style>
