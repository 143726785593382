<template>
    <div class="add-widget">
        <div
            class="add-widget-button"
            @click="addWidgetClick"
        >
            +
        </div>
        {{ $t('dashboard.show.addWidget') }}
    </div>
    <AddWidgetModal
        v-if="showModal"
        @on-close="onClose"
    />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import AddWidgetModal from '../AddWidgetModal.vue'

const showModal = ref(false)

function addWidgetClick() {
    showModal.value = true
}

function onClose() {
    showModal.value = false
}
</script>

<style scoped lang="less">
.add-widget {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.add-widget-button {
    width: 68px;
    height: 68px;
    background-color: var(--color-c-p1);
    color: #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 6px 0 rgba(0,0,0,.3);
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.3);
    font-size: 48px;
    line-height: 68px;
    margin: 0 auto 15px;
    text-align: center;
}
</style>