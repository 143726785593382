<template>
    <div :id="id">
        <Breadcrumb :home="true">
            <li>{{ $t('transactions.breadcrumb') }}</li>
        </Breadcrumb>

        <LoadingOverlay v-if="transactionsStore.loading" />

        <Modal
            v-if="deleteSuccessModal"
            :id="deleteSuccessModal.id"
            :title="deleteSuccessModal.title"
            :confirm-text="deleteSuccessModal.confirmText"
            :data-qa="deleteSuccessModal.dataQa"
            @on-confirm="onSuccessModalClose"
            @on-close="onSuccessModalClose"
        >
            <p>{{ deleteSuccessModal.content }}</p>
        </Modal>

        <div
            v-if="!transactionsStore.loading && transactionsStore.error"
            class="crefo-ui-alert error mb-large"
        >
            {{ $t('globalMisc.error.errorLoadingData.message') }}
        </div>
        <template v-else>
            <NoResults 
                v-if="transactionsStore.showNoResult"
                class="mb"
            />
 
            <CompanySearch v-if="!transactionsStore.loading && transactionsStore.hasNoResults && !transactionsStore.hasActiveFilters" />

            <ListFilter
                :class="{
                    'hidden': transactionsStore.loading || (transactionsStore.hasNoResults && !transactionsStore.hasActiveFilters)
                }"
            />

            <Results />
        </template>

        <ListFooter />
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import Modal from '@/components/Modal.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import CompanySearch from '@/components/CompanySearch.vue'
import NoResults from '@/components/Transactions/NoResults.vue'
import ListFilter from '@/components/Transactions/ListFilter.vue'
import ListFooter from '@/components/Transactions/ListFooter.vue'
import Results from '@/components/Transactions/Results.vue'

import type { ModalProps } from '@/types/components/modal'

import { useTransactionsStore } from '@/stores/transactions'

import { getCbraUserPreferences } from '@/services'

const id = 'portal.mc.transactions'

const transactionsStore = useTransactionsStore()
const { t } = useI18n()

const deleteSuccessModal = ref<ModalProps>()

const onSuccessModalClose = () => {
    transactionsStore.showDeleteSuccessModal = false
}

onMounted(() => {
    getCbraUserPreferences().then((response: any) => {
        if (response.status === 200) {
            transactionsStore.userPreferences = response.data
            transactionsStore.filterPreference = response.data.filterPreference

            if (
                typeof response.data.defaultReportLegitimateInterest !== 'undefined' && 
                response.data.defaultReportLegitimateInterest !== null
            ) {
                transactionsStore.legitimateInterest = response.data.defaultReportLegitimateInterest
            }

            transactionsStore.setCookieIfNeeded()
        }

        transactionsStore.init()
    })
})

watch(() => transactionsStore.showDeleteSuccessModal, (newVal) => {
    if (newVal) {
        deleteSuccessModal.value = {
            id: 'delete-transaction-success',
            title: t('transaction.list.reports.delete.overlay.title'),
            content: t('transaction.list.reports.delete.overlay.success.content'),
            confirmText: t('shared.modals.ok'),
            dataQa: 'delete-transaction-success'
        }
    } else {
        deleteSuccessModal.value = undefined
    }
})
</script>

<style scoped>
.hidden {
    display: none;
}
</style>
