<template>
    <div data-qa="infoChapter-structuredata-crefonumber-container">
        <template v-if="type == 'PERSON'">
            <p data-qa="infoChapter-structuredata-crefonumber">
                {{ helpers.getIdentnumber(data.identnummer) }}
            </p>
            <Bonitaetslink
                v-if="getOrderConsumerUri(data)"
                :text="'transaction.data.collection.solvencyCheck'"
                :href="'/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen/' + getOrderConsumerUri(data)"
                :disabled="isDisabled"
                :title="'transaction.data.collection.solvencyCheckBusinessTooltip'"
                product="consumer"
                data-qa="infoChapter-structuredata-check-solvency-link"
            />
        </template>

        <template v-if="type == 'INTERNATIONAL'">
            <p data-qa="infoChapter-structuredata-crefonumber">
                {{ helpers.getIdentnumber(data.identnummer) }}
            </p>

            <Bonitaetslink 
                v-if="getAdvancedSearchUri(data)"
                text="transaction.data.collection.solvencyCheck"
                :href="advancedSearchLinks[locale] + getAdvancedSearchUri(data)"
                :disabled="isDisabled"
                title="transaction.data.collection.solvencyCheckBusinessTooltip"
                product="search"
                data-qa="infoChapter-structuredata-check-solvency-link"
            />
        </template>

        <template v-if="type === 'BUSINESS'">
            <template v-if="isDalFunction('', data.land) ">
                <template v-if="data.identnummer">
                    <p data-qa="infoChapter-structuredata-crefonumber">
                        {{ helpers.getIdentnumber(data.identnummer) }}
                    </p>
                    <Bonitaetslink 
                        text="transaction.data.collection.solvencyCheck"
                        :href="'/bonitaetsauskunft/bonitaet-einer-firma-pruefen/detailseite-unternehmen/?country='+ dayjs.locale() +'&businessId=' + data.identnummer"
                        :disabled="isDisabled"
                        title="transaction.data.collection.solvencyCheckBusinessTooltip"
                        product="business"
                        data-qa="infoChapter-structuredata-check-solvency-link"
                    />
                </template>

                <template v-if="data.crefonummer">
                    <p data-qa="infoChapter-structuredata-crefonumber">
                        {{ helpers.getIdentnumber(data.crefonummer) }}
                    </p>
                    <Bonitaetslink
                        text="transaction.data.collection.solvencyCheck"
                        :href="'/bonitaetsauskunft/bonitaet-einer-firma-pruefen/detailseite-unternehmen/?country='+ dayjs.locale() +'&businessId=' + data.crefonummer"
                        :disabled="isDisabled"
                        title="transaction.data.collection.solvencyCheckBusinessTooltip"
                        product="businessOrder"
                        data-qa="infoChapter-structuredata-check-solvency-link"
                    />
                </template>
            </template>
            <template v-if="!isDalFunction('', data.land)">
                <p data-qa="infoChapter-structuredata-crefonumber">
                    {{ helpers.getIdentnumber(data.identnummer) }}
                </p>

                <Bonitaetslink 
                    v-if="getAdvancedSearchUri(data)"
                    text="transaction.data.collection.solvencyCheck"
                    :href="advancedSearchLinks[locale] + getAdvancedSearchUri(data)"
                    :disabled="isDisabled"
                    title="transaction.data.collection.solvencyCheckBusinessTooltip"
                    product="search"
                    data-qa="infoChapter-structuredata-check-solvency-link"
                />
            </template>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { CbraReportTransactionStructureData } from '@/types/cbra/reports'
import { PropType } from 'vue'
import helpers from '@/helper/misc'
import { getCountryByName } from '@/helper/countryCodes'
import { useI18n } from 'vue-i18n'
import Bonitaetslink from './Bonitaetslink.vue'
import dayjs from 'dayjs'
import { advancedSearchLinks } from '@/data/typo3Links'

const props = defineProps({
    data: {
        type: Object as PropType<CbraReportTransactionStructureData>,
        required: true,
    },
    countryCode: {
        type: String,
        required: false,
        default: ''
    },
    isDisabled: {
        type: Boolean,
        required: true,
    },
    type: {
        type: String,
        required: true,
    },
})

const { t, locale } = useI18n()

function getAdvancedSearchUri(business:CbraReportTransactionStructureData) {
    let prefix = '?'
    let uri = ''
    const country = getCountryByName(t, business.land)
    let countryCode = ''
    if (country) {
        countryCode = country.code
    }
    const params = [
        { name: 'businessName', value: business.firmierung || '' },
        { name: 'street', value: business.strasse || '' },
        { name: 'housenumber', value: business.hausnummer || '' },
        { name: 'zipCode', value: business.postleitzahl || '' },
        { name: 'city', value: business.ort || '' },
        { name: 'country', value: countryCode || '' },
    ]
    if (business.active && !business.beteiligtBis) {
        uri = prefix
        params.forEach((param, idx) => {
            if (idx > 0) {uri += '&'}
            uri += param.name + '=' + encodeURIComponent(param.value)
        })
        return uri
    }

    return false
}

function isDalFunction(countryCode = '', country = '') {
    if (countryCode === '' && country === '') {
        countryCode = props.countryCode
    }
    if (countryCode === '' && country !== '') {
        const countryByName = getCountryByName(t, country)
        if (countryByName) {
            countryCode = countryByName.code
        }
    }
    return ['DE', 'AT', 'LU'].includes(countryCode)
}

function getOrderConsumerUri(person: CbraReportTransactionStructureData) {
    const prefix = '?'
    let uri = ''
    const wrongStatus = ['minderjährig', 'unbekannt verzogen', 'verstorben']
    const country = getCountryByName(t, person.land)
    let countryCode = ''
    if (country) {
        countryCode = country.code
    }
    const params = [
        { name: 'salutation', value: person.anrede === 'Herr' ? 'MR' : person.anrede === 'Frau' ? 'MS' : 'MR_MS' },
        { name: 'surname', value: person.name || '' },
        { name: 'firstname', value: person.vorname },
        { name: 'birthname', value: person.geburtsname || '' },
        { name: 'birthdate', value: person.geburtstag ? dayjs(person.geburtstag).format('L') : '' },
        { name: 'street', value: person.strasse || '' },
        { name: 'housenumber', value: person.hausnummer || '' },
        { name: 'zipCode', value: person.postleitzahl || '' },
        { name: 'country', value: countryCode || '' },
        { name: 'city', value: person.ort || '' },
    ]
    if (person.land === 'Deutschland' && person.active && !person.beteiligtBis && (person.status === null || wrongStatus.indexOf(person.status) === -1)) {
        uri = prefix
        params.forEach((param, idx) => {
            if (idx > 0) { uri += '&' }
            uri += param.name + '=' + encodeURIComponent(param.value)
        })
        return uri
    }
    return false
}
</script>