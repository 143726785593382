import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import axios from '@/services/tracing-axios'
import { AxiosResponse } from 'axios'
import type { 
    CbraAddMailSurrogate,
    CbraAvailableSurrogates, 
    CbraMailSurrogates,
    CbraUpdateMailSurrogate 
} from '@/types/cbra/surrogates'

export const useSurrogatesStore = defineStore('surrogates', () => {
    // state
    const error = ref('')
    const availableSurrogates = ref<CbraAvailableSurrogates>([])
    const mailSurrogates = ref<CbraMailSurrogates>([])
    // state

    // getters
    const isVisibleInNavigation = computed(() => {
        return availableSurrogates.value.length > 0 || mailSurrogates.value.length > 0
    })
    // getters

    // actions
    async function addMailSurrogate(payload: CbraAddMailSurrogate) {
        try {
            const { status } = await axios.post('/cbra/mailSurrogates', payload)
            if (status === 200) {
                await getSurrogates()
            }
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }
    }

    async function deleteMailSurrogateById(id: number) { 
        try {
            const { status } = await axios.delete('/cbra/mailSurrogates/' + id)
            if (status === 200) {
                await getSurrogates()
            }
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }
    }

    async function updateMailSurrogate(payload: CbraUpdateMailSurrogate) { 
        try {
            const { status } = await axios.put('/cbra/mailSurrogates/' + payload.id, payload)
            if (status === 200) {
                await getSurrogates()
            }
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }
    }
   
    async function getSurrogates() {
        try {
            const { status, data } = await axios.get<CbraAvailableSurrogates, AxiosResponse<CbraAvailableSurrogates>, void>('/cbra/availableSurrogates?_=' + Date.now())

            if (status === 200) {
                availableSurrogates.value = data
            }
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }

        try {
            const { status, data } = await axios.get<CbraMailSurrogates, AxiosResponse<CbraMailSurrogates>, void>('/cbra/mailSurrogates?_=' + Date.now())

            if (status === 200) {
                mailSurrogates.value = data
            }
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }
    }
    // actions

    return { 
        error, availableSurrogates, mailSurrogates, isVisibleInNavigation,
        addMailSurrogate, getSurrogates, deleteMailSurrogateById, updateMailSurrogate
    }
})
