import dayjs from 'dayjs'
import i18n from '@/i18n'

const { t } = i18n.global

export function formatDate(date: Date | number | undefined | null | string, format: string = 'DD.MM.YYYY') {
    if (date) {
        const newDate = dayjs(date).format(format)
        if (newDate !== 'Invalid Date') {
            return newDate
        }
    }
    return ''
}

export function formatDateTime(date: Date | number | undefined | null | string) {
    if (date) {
        const newDate = dayjs(date).format('DD.MM.YYYY HH:mm')
        if (newDate !== 'Invalid Date') {
            return newDate
        }
    }
    return ''
}

export function currency(value: number | string, currency?: string | null, showZero = false) {
    currency = currency || 'EUR'
    let style = 'currency'
    let minimumFractionDigits: number | undefined = undefined
    let maximumFractionDigits: number | undefined = undefined

    if (value === 0 && showZero !== true) return ''

    if (typeof value === 'string') {
        const parsed = parseFloat(value)
        if (Number.isNaN(parsed)) {
            return value
        }
        value = parsed
    }

    if (currency ==='PROZENT') {
        style = 'percent'
        value = value / 100
        currency = undefined
        minimumFractionDigits = 2
        maximumFractionDigits = 2
    }

    // TODO: this should use the current locale
    const formatter = new Intl.NumberFormat('de-DE', {
        style: style as Intl.NumberFormatOptions['style'],
        currency,
        currencyDisplay: 'code',
        minimumFractionDigits,
        maximumFractionDigits

    })
    const formatted = formatter.format(value)
    if (Number.isNaN(parseFloat(formatted))) {
        return value
    }
    return formatted
}

/**
 * Parses a Creditreform member number
 * and returns a clerk identifier as a string
 */
export function sbb(sbbName: string) {
    let result = ''
    const numberTest = new RegExp('^[0-9]*$', 'g')

    if (numberTest.test(sbbName)) {
        const sbbNo = sbbName.substr(sbbName.length - 3, 3)
        result = `${t('shared.sachbearbeiter')} ${parseInt(sbbNo)}`
    } else {
        result = sbbName
    }
    return result
}
