import { getCbraSupplierInquiries } from '@/services'

export enum SupplierInquiryStatus {
    OPEN = 'OPEN',
    IGNORED = 'IGNORED',
    REPLIED = 'REPLIED'
}

export const PAYMENT_MODE = {
    CASH: 'CASH',
    DIRECT_DEBIT: 'DIRECT_DEBIT',
    ADVANCE: 'ADVANCE',
    DISCOUNT: 'DISCOUNT',
    WITHIN_AGREED_TERMS: 'WITHIN_AGREED_TERMS',
    DELAYED_PAYMENT: 'DELAYED_PAYMENT',
    AFTER_DUNNING: 'AFTER_DUNNING',
    AFTER_DEFAULT_SUMMONS: 'AFTER_DEFAULT_SUMMONS'
}

export namespace SupplierInquiryService {
    export function getSupplierInquiryByOrderNo(orderNo: string) {
        return getCbraSupplierInquiries({ status: SupplierInquiryStatus.OPEN, pageSize: 1, orderNo })
    }
}
