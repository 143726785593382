import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import axios from '@/services/tracing-axios'
import type { WatchlistEntryResponse } from '@/types/cbra/watchlist'
import { WatchlistSearchOptions } from '@/types/cbra/watchlist'

export const useWatchlistStore = defineStore('watchlist', () => {
    // state
    const watchlist = ref<WatchlistEntryResponse>({
        listSize: 0,
        watchlistEntries: [],
    })
    const error = ref<string | null>(null)
    // state

    // getters
    const elementCount = computed(() => {
        return watchlist.value.listSize
    })
    // getters

    // actions
    async function fetch(search: WatchlistSearchOptions) {
        error.value = null
        watchlist.value = {
            listSize: 0,
            watchlistEntries: [],
        }
        try {
            const params = new URLSearchParams({
                searchText: search.searchText,
                pageSize: search.pageSize.toString(),
                pageStartIndex: search.pageStartIndex.toString(),
                sort: search.sort,
            })
            let queryString = params.toString()
            search.watchlistFilter.forEach((filter) => {
                queryString += `&${encodeURIComponent('watchlistFilter[]')}=${filter}`
            })
            const { data } = await axios.get(`/cbra/watchlistentries/?${queryString}`)
            watchlist.value = data
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }
    }
    async function removeWatchlistEntry(id: string) {
        error.value = null
        try {
            await axios.delete(`/cbra/watchlistentries/${id}`)
            const index = watchlist.value.watchlistEntries.findIndex((entry) => entry.id === id)
            if (index !== -1) {
                watchlist.value.watchlistEntries.splice(index, 1)
                watchlist.value.listSize -= 1
            }
            const event = new Event('watchlistCountChanged')
            window.dispatchEvent(event)
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }
    }
    // actions

    return {
        watchlist,
        elementCount,
        fetch,
        removeWatchlistEntry
    }
})
