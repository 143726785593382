<template>
    <WidgetLoadingOverlay
        v-if="isLoading"
    />
    <div
        v-if="!isLoading"
        class="chart-badge-wrapper"
    >
        <div
            class="chart-badge"
            data-qa="widget-supplierinquiry-chart"
        />
        <a
            v-if="widget.link"
            :href="widget.link.url"
        >
            <span
                class="chart-badge-counter"
                data-qa="widget-supplierinquiry-chart-linkToOverviewPage"
            >
                {{ totalCount }}
            </span>

        </a>
    </div>
</template>

<script setup lang="ts">
import { onMounted, PropType, ref } from 'vue'
import { Widget } from '@/types/widget'
import { getCbraSupplierInquiries } from '@/services'
import { SupplierInquiryStatus } from '@/helper/services/supplierInquiry'
import WidgetLoadingOverlay from '../WidgetLoadingOverlay.vue'

defineProps({
    widget: {
        type: Object as PropType<Widget>,
        required: true
    },
})

const totalCount = ref(0)
const isLoading = ref(true)

onMounted(async() => {
    try {
        const response = await getCbraSupplierInquiries({ status: SupplierInquiryStatus.OPEN, pageSize: 3 })
        totalCount.value = response.data.totalCount
        isLoading.value = false
    } catch (error) {
        console.error(error)
    }
})

</script>

<style scoped>

.chart-badge-wrapper {
    width: 60%;
    position: relative;
    margin: 7% auto 10%;
}

.chart-badge {
    border-radius: 50%;
    width: 100%;
    padding-bottom: 100%;
    background-color: #008dd9;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0,0,0,.5);
}

.chart-badge-counter {
    color: #fff;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

</style>