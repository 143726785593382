<template>
    <div class="register ">
        <div class="container register__container">
            <div 
                v-if="showTitle"
                class="row mb"
            >
                <div class="col-12 text-center">
                    <h3>{{ $t('Register.title') }}</h3>
                </div>
            </div>
            <div class="row mb">
                <div class="col-12 col-md-4 mb">
                    <h3>
                        {{ $t("Register.steps.register.title") }}
                    </h3>
                    <a
                        :href="`${selfcareBaseUrl}/registrieren/mitglied?prk=MC`"
                        target="_blank"
                        rel="noopener"
                    >
                        <img
                            v-if="$i18n.locale === 'de-DE' || $i18n.locale === 'de-AT'"
                            alt="Registrieren"
                            src="@/assets/img/homepage/registrieren.png"
                            class="mb"
                        >
                        <img
                            v-if="$i18n.locale === 'fr-FR' || $i18n.locale === 'fr-LU'"
                            alt="Registrieren"
                            src="@/assets/img/homepage/registrieren-fr.png"
                            class="mb"
                        >
                        <img
                            v-if="$i18n.locale === 'en-US'"
                            alt="Registrieren"
                            src="@/assets/img/homepage/registrieren-en.png"
                            class="mb"
                        >
                    </a>
                    <!-- eslint-disable vue/no-v-html -->
                    <p 
                        class="mb" 
                        v-html="$t('Register.steps.register.text', { regUrl: `${selfcareBaseUrl}/registrieren/mitglied?prk=MC` })" 
                    />
                </div>
                <div class="col-12 col-md-4 mb">
                    <h3>
                        {{ $t("Register.steps.login.title") }}
                    </h3>
                    <img
                        v-if="$i18n.locale === 'de-DE' || $i18n.locale === 'de-AT'"
                        alt="Anmelden"
                        src="@/assets/img/homepage/anmelden.png"
                        class="mb"
                    >
                    <img
                        v-if="$i18n.locale === 'fr-FR' || $i18n.locale === 'fr-LU'"
                        alt="Anmelden"
                        src="@/assets/img/homepage/anmelden-fr.png"
                        class="mb"
                    >
                    <img
                        v-if="$i18n.locale === 'en-US'"
                        alt="Anmelden"
                        src="@/assets/img/homepage/anmelden-en.png"
                        class="mb"
                    >
                    <p class="mb">
                        {{ $t("Register.steps.login.text") }}
                    </p>
                </div>
                <div class="col-12 col-md-4">
                    <h3>
                        {{ $t("Register.steps.getStarted.title") }}
                    </h3>
                    <img
                        v-if="$i18n.locale === 'de-DE' || $i18n.locale === 'de-AT'"
                        alt="Durchstarten"
                        src="@/assets/img/homepage/durchstarten.png"
                        class="mb"
                    >
                    <img
                        v-if="$i18n.locale === 'fr-FR' || $i18n.locale === 'fr-LU'"
                        alt="Durchstarten"
                        src="@/assets/img/homepage/durchstarten-fr.png"
                        class="mb"
                    >
                    <img
                        v-if="$i18n.locale === 'en-US'"
                        alt="Durchstarten"
                        src="@/assets/img/homepage/durchstarten-en.png"
                        class="mb"
                    >
                    <p class="mb">
                        {{ $t("Register.steps.getStarted.text") }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const selfcareBaseUrl = import.meta.env.VITE_APP_USER_MGMT_BASEURL

const showTitle = computed(() => {
    return route.name === 'index'
})

</script>

<style scoped>
.text-center {
    text-align: center;
}

img {
    width: 80%;
}
</style>
