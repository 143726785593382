import {
    createRouter,
    createWebHistory,
    NavigationGuard,
    RouteLocationNormalized,
    RouteRecordRaw
} from 'vue-router'
import { trackPage } from '@/helper/webtrekk'
import { useUserStore } from '@/stores/user'

// Home
import Homepage from '@/views/Homepage.vue'
import Dashboard from '@/views/Dashboard.vue'

// Profile
import Profile from '@/views/Profile.vue'

// Transactions
import Transactions from '@/views/Transactions.vue'
import DetailTransaction from '@/views/Transaction/DetailTransaction.vue'
import DetailFamiliyTree from '@/views/Transaction/DetailFamiliyTree.vue'

// Watchlist
import Watchlist from '@/views/Watchlist.vue'

// Search
import AdvancedSearch from '@/views/AdvancedSearch/AdvancedSearch.vue'
import SearchResults from '@/views/AdvancedSearch/SearchResults.vue'

// SuperUser
import UserAdministration from '@/views/UserAdministration.vue'

// Reports
import Report from '@/views/Report/Report.vue'
import OrderReport from '@/views/Report/OrderReport.vue'
import ReportCompany from '@/views/Report/ReportCompany.vue'
import ReportPerson from '@/views/Report/ReportPerson.vue'
import DetailCompany from '@/views/Report/DetailCompany.vue'
import DetailPerson from '@/views/Report/ReportPerson/DetailPerson.vue'
import ProductComparison from '@/views/Report/ProductComparison.vue'

// WKV
import OrderWKVVue from '@/views/Report/wkv/OrderWKV.vue'
import ConfirmationWKVVue from '@/views/Report/wkv/ConfirmationWKV.vue'
import FeedbackWKV from '@/views/Report/wkv/FeedbackWKV.vue'

// Contact
import Contact from '@/views/Contact.vue'

// Static landing pages
import ActivateAccess from '@/views/StaticPages/ActivateAccess.vue'
import TransferNow from '@/views/StaticPages/TransferNow.vue'

// Help pages
import Help from '@/views/Help/Help.vue'
import FAQ from '@/views/Help/FAQ.vue'
import HelpPageSearch from '@/views/Help/Search.vue'
import HelpPageCrefoEVA from '@/views/Help/CrefoEVA.vue'
import HelpPageMemberAdmin from '@/views/Help/MemberAdmin.vue'
import HelpPageMemberNavigation from '@/views/Help/MemberNavigation.vue'
import HelpPageCreditInformation from '@/views/Help/CreditInformation.vue'
import HelpPageEffectiveRiskManagement from '@/views/Help/EffectiveRiskManagement.vue'
import HelpPageNewNavigation from '@/views/Help/NewNavigation.vue'
import HelpPageEsgServices from '@/views/Help/EsgServices.vue'
import HelpPageMyMembership from '@/views/Help/MyMembership.vue'
import HelpPageMyProfile from '@/views/Help/MyProfile.vue'
import HelpPageMyProfileCompany from '@/views/Help/MyProfileCompany.vue'
import HelpPageWatchlist from '@/views/Help/Watchlist.vue'
import HelpPageMonitoring from '@/views/Help/Watchlist/Monitoring.vue'
import HelpPageSignalAddress from '@/views/Help/Watchlist/SignalAddress.vue'
import HelpPageSignalSolvency from '@/views/Help/Watchlist/SignalSolvency.vue'
import HelpPageMails from '@/views/Help/Mails.vue'
import HelpPageAuskunftsexport from '@/views/Help/Auskunftsexport/Auskunftsexport.vue'
import HelpPageProcessesMails from '@/views/Help/ProcessesMails.vue'
import HelpPageDebtCollection from '@/views/Help/DebtCollection.vue'
import HelpPageDebtCollectionFormatDescription from '@/views/Help/DebtCollection/FormatDescription.vue'
import HelpPageTrafficLightReport from '@/views/Help/CreditReports/TrafficLightReport.vue'
import HelpPageBriefReport from '@/views/Help/CreditReports/BriefReport.vue'
import HelpPageCompactReport from '@/views/Help/CreditReports/CompactReport.vue'
import HelpPageCommercialReport from '@/views/Help/CreditReports/CommercialReport.vue'
import HelpPagePremiumReport from '@/views/Help/CreditReports/PremiumReport.vue'
import HelpPageUpgradeConcept from '@/views/Help/CreditReports/UpgradeConcept.vue'
import HelpPageFeedback from '@/views/Help/CreditReports/Feedback.vue'
import HelpPageDebtCollectionIkaros from '@/views/Help/DebtCollectionIkaros.vue'
import HelpPageWKV from '@/views/Help/WKV.vue'
import HelpPageInbox from '@/views/Help/Inbox.vue'
import HelpPageConsumer from '@/views/Help/Consumer.vue'

// About Crefo pages
import AboutCreditreform from '@/views/AboutCreditreform/AboutCreditreform.vue'
import AboutPageMyCreditreform from '@/views/AboutCreditreform/MyCreditreform.vue'
import AboutPageMyCrefo from '@/views/AboutCreditreform/MyCrefo.vue'
import AboutPageMarketingAddresses from '@/views/AboutCreditreform/MarketingAddresses.vue'
import AboutPageCrefoReasons from '@/views/AboutCreditreform/CrefoReasons.vue'
import AboutPageOurMemberships from '@/views/AboutCreditreform/OurMemberships.vue'
import AboutPageDataSources from '@/views/AboutCreditreform/DataSources.vue'

// Legal pages
import Impressum from '@/views/Legal/Impressum.vue'
import DataPrivacy from '@/views/Legal/DataPrivacy.vue'
import GDPR from '@/views/Legal/GDPR.vue'
import UsageInstructions from '@/views/Legal/UsageInstructions.vue'

// Misc pages
import PageNotFound from '@/views/PageNotFound.vue'
import LoginError from '@/views/LoginError.vue'

const BASE_PATH_MICROFRONTEND_CONTAINER = `/${import.meta.env.VITE_APP_BASE_PATH}`
const PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP = import.meta.env.VITE_APP_PUBLIC_ENTRY_ROUTE

// Called in "beforeEnter" on protected routes
const innerIsAuthenticated = (to: RouteLocationNormalized, from: RouteLocationNormalized, timeout = 100): ReturnType<NavigationGuard> => {
    const userStore = useUserStore()
    if (
        userStore.authenticated ||
        (timeout >= 2000 && (<any>window)?.keycloak?.token)
    ) {
        return true
    } else if (timeout >= 2000) {
        const event = new Event('redirectToLogin')
        window.dispatchEvent(event)
    } else {
        return new Promise(resolve => setTimeout(resolve, timeout)).then(() => innerIsAuthenticated(to, from, timeout * 2))
    }
}

// Called in "beforeEnter" on protected routes
function isAuthenticated(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    /**
     * Necessary split inner and outer function, because of new NavigationGuard signature of router 4.x:
     *     optional third "next" parameter conflicts with "timeout" parameter.
     **/
    return innerIsAuthenticated(to, from)
}

const routes: RouteRecordRaw[] = [
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/`,
        name: 'index',
        meta: { webtrekkWebsiteArea: 'startseite' },
        component: Homepage
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/dashboard`,
        name: 'dashboard',
        meta: { webtrekkWebsiteArea: 'startseite' },
        component: Dashboard,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/mein-profil/:section?`,
        name: 'profile',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: Profile,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bonitaetsauskunft`,
        name: 'report',
        meta: { webtrekkWebsiteArea: 'bonitaet' },
        component: Report,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bonitaetsauskunft/produktvergleich`,
        name: 'product-comparison',
        meta: { webtrekkWebsiteArea: 'bonitaet' },
        component: ProductComparison,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen`,
        name: 'report-person',
        meta: { webtrekkWebsiteArea: 'bonitaet' },
        component: ReportPerson,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bonitaetsauskunft/watchlist-unternehmen`,
        name: 'watchlist',
        meta: { webtrekkWebsiteArea: 'bonitaet' },
        component: Watchlist,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bonitaetsauskunft/bestellung-einer-wirtschaftsauskunft`,
        name: 'order-report',
        meta: { webtrekkWebsiteArea: 'bonitaet' },
        component: OrderReport,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bonitaetsauskunft/bonitaet-einer-firma-pruefen`,
        name: 'report-company',
        meta: { webtrekkWebsiteArea: 'bonitaet' },
        component: ReportCompany,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bonitaetsauskunft/bonitaet-einer-firma-pruefen/detailseite-unternehmen/`,
        name: 'detail-company',
        meta: { webtrekkWebsiteArea: 'bonitaet' },
        component: DetailCompany,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bonitaetsauskunft/waren-kredit-versicherung`,
        name: 'wkv-order',
        meta: { webtrekkWebsiteArea: 'forderungsausfallversicherung.preisberechnung' },
        component: OrderWKVVue,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bonitaetsauskunft/waren-kredit-versicherung/waren-kredit-versicherung-bestaetigung`,
        name: 'wkv-confirmation',
        meta: { webtrekkWebsiteArea: 'forderungsausfallversicherung.bestaetigung' },
        component: ConfirmationWKVVue,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/wkv/feedback`,
        name: 'wkv-feedback',
        meta: { webtrekkWebsiteArea: 'forderungsausfallversicherung.rueckmeldung' },
        component: FeedbackWKV,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/vorgaenge`,
        name: 'transactions',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: Transactions,
        beforeEnter: isAuthenticated,
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/vorgaenge/detailansicht-auskunft`,
        name: 'detail-transaction',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: DetailTransaction,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/vorgaenge/detailansicht-verflechtung`,
        name: 'detail-famility-tree',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: DetailFamiliyTree,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/vorgaenge/detailansicht-privatauskunft`,
        name: 'detail-person',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: DetailPerson,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/erweiterte-suche`,
        name: 'advanced-search',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: AdvancedSearch,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/erweiterte-suche/suchergebnisliste`,
        name: 'advanced-search-results',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: SearchResults,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/nutzerverwaltung`,
        name: 'user-rights',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: UserAdministration,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/kontakt`,
        name: 'contact',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: Contact,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe`,
        name: 'help',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: Help,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/auskunftsexport`,
        name: 'help-auskunftsexport',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageAuskunftsexport,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/inkasso`,
        name: 'help-debt-collection',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageDebtCollection,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/inkasso/formatbeschreibung-inkassoauftragsverarbeitung`,
        name: 'help-debt-collection-format-description',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageDebtCollectionFormatDescription,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/e-mail-benachrichtigungen`,
        name: 'help-e-mails',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageMails,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/mein-profil`,
        name: 'help-my-profile',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageMyProfile,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/mein-profil/unternehmen`,
        name: 'help-my-profile-company',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageMyProfileCompany,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/suche-in-meine-creditreform`,
        name: 'help-search',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageSearch,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/mein-wkv-einzelschutz`,
        name: 'help-wkv',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageWKV,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/crefoeva`,
        name: 'crefo-eva',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageCrefoEVA,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/mitglieds-admin`,
        name: 'help-member-admin',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageMemberAdmin,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/navigation-fuer-verbundene-unternehmen`,
        name: 'help-member-navigation',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageMemberNavigation,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/bonitaetsauskunft`,
        name: 'credit-information',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageCreditInformation,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/effektives-risikomanagement`,
        name: 'risk-management',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageEffectiveRiskManagement,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/neue-navigation`,
        name: 'new-navigation',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageNewNavigation,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/neu-esg-services`,
        name: 'esg-services',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageEsgServices,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/vorgaenge-nachrichten`,
        name: 'help-processes-mails',
        component: HelpPageProcessesMails,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/bonitaetsauskunft/ampelauskunft`,
        name: 'help-traffic-light-report',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageTrafficLightReport,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/bonitaetsauskunft/kurzauskunft`,
        name: 'help-brief-report',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageBriefReport,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/bonitaetsauskunft/kompaktauskunft`,
        name: 'help-compact-report',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageCompactReport,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/bonitaetsauskunft/wirtschaftsauskunft`,
        name: 'help-commercial-report',
        component: HelpPageCommercialReport,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/bonitaetsauskunft/premiumauskunft`,
        name: 'help-premium-report',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPagePremiumReport,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/bonitaetsauskunft/upgrade`,
        name: 'help-credit-information-upgrade',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageUpgradeConcept,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/bonitaetsauskunft/lieferantenrueckfrage`,
        name: 'help-credit-information-feedback',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageFeedback,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/consumer`,
        name: 'help-consumer',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageConsumer,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/posteingang`,
        name: 'help-inbox',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageInbox,
        beforeEnter: isAuthenticated
    },

    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/watchlist`,
        name: 'help-watchlist',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageWatchlist,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/watchlist/monitoring`,
        name: 'help-watchlist-monitoring',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageMonitoring,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/watchlist/signal-adresse`,
        name: 'help-watchlist-signal-address',
        component: HelpPageSignalAddress,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/watchlist/signal-bonitaet`,
        name: 'help-watchlist-signal-solvency',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageSignalSolvency,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/haeufige-fragen`,
        name: 'faq',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: FAQ,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/meine-mitgliedschaft`,
        name: 'my-membership',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageMyMembership,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/hilfe/neu-mc-inkasso-mit-ikaros`,
        name: 'help-debt-collection-ikaros',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: HelpPageDebtCollectionIkaros,
        beforeEnter: isAuthenticated
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/ueber-creditreform`,
        name: 'about-creditreform',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: AboutCreditreform
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/ueber-creditreform/meine-creditreform`,
        name: 'my-creditreform',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: AboutPageMyCreditreform
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/ueber-creditreform/meine-crefo`,
        name: 'my-crefo',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: AboutPageMyCrefo
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/ueber-creditreform/marketing-adressen`,
        name: 'marketing-addresses',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: AboutPageMarketingAddresses
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/ueber-creditreform/10-gruende-fuer-creditreform`,
        name: 'reasons-for-creditreform',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: AboutPageCrefoReasons
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/ueber-creditreform/unsere-mitgliedschaften`,
        name: 'our-memberships',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: AboutPageOurMemberships
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/ueber-creditreform/datenquellen`,
        name: 'data-sources',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: AboutPageDataSources
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/zugang-aktivieren`,
        name: 'activate-access',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: ActivateAccess
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/jetzt-umsteigen`,
        name: 'transfer-now',
        meta: { webtrekkWebsiteArea: 'funktionen' },
        component: TransferNow
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/datenschutz`,
        name: 'data-privacy',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: DataPrivacy
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/impressum`,
        name: 'impressum',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: Impressum
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/nutzungshinweise`,
        name: 'usage-instructions',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: UsageInstructions
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/eu-dsgvo`,
        name: 'gdpr',
        meta: { webtrekkWebsiteArea: 'redaktion' },
        component: GDPR
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/fehler-login`,
        name: 'login-error',
        meta: { webtrekkWebsiteArea: '' },
        component: LoginError
    },
    {
        path: `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/:pathMatch(.*)*`,
        name: 'page-not-found',
        meta: { webtrekkWebsiteArea: '' },
        component: PageNotFound
    }
]

const router = createRouter({
    history: createWebHistory('/'), // this is crucial in order to rewrite links to the single-spa container!
    routes,
    scrollBehavior: (to) => {
        if (!to.hash) {
            window.scroll(0, 0)
        }
    }
})

router.beforeEach((to, from, next) => {
    window.websiteArea = to.meta.webtrekkWebsiteArea as string
    next()
})
router.afterEach(trackPage)
router.afterEach(changeFooterContactData)

export default router
// for testing reasons exported:
export { innerIsAuthenticated }

function changeFooterContactData(to: RouteLocationNormalized) {
    const bonitaetString = `${BASE_PATH_MICROFRONTEND_CONTAINER}/${PUBLIC_ENTRY_ROUTE_MICROFRONTEND_APP}/bonitaetsauskunft`
    if (to.path.includes(bonitaetString)) {
        window.dispatchEvent(new CustomEvent('setContactByType', {
            detail: { type: 'WIRTSCHAFTSINFORMATION' }
        }))
    }
}
