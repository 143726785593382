<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="crefo-input">
        <div
            class="input-group"
            :class="{ 'has-error': !!errorMessage }"
        >
            <div class="checkbox-group">
                <div class="input-wrapper">
                    <div class="crefo-checkbox">
                        <input 
                            :id="id" 
                            :checked="modelValue" 
                            :class="{ 'is-checked': modelValue }"
                            type="checkbox"
                            :name="name" 
                            :disabled="disabled"
                            :data-qa="dataQa"
                            @input="updateValue"
                        >
                        <label
                            :for="id"
                            class="input-label"
                            :data-qa="`${dataQa}_label`"
                        >
                            <span v-html="label" />
                            <template v-if="required">*</template>
                        </label>
                    </div>
                    <div
                        v-if="helpText"
                        class="help-text"
                    >
                        {{ helpText }}
                    </div>
                </div>
            </div>
            <div 
                v-if="errorMessage"
                :data-qa="errorMessageDataQaAttr"
                class="error-msg"
            >
                <span>{{ errorMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { type PropType, toRef, InputHTMLAttributes } from 'vue'
import { useField } from 'vee-validate'

const emit = defineEmits(['update:modelValue', 'change'])

function updateValue(event: Event) {
    const target = event.target as HTMLInputElement
    emit('update:modelValue', target.checked)
    emit('change', target.checked)
    handleChange(event)
}

const props = defineProps({
    modelValue: {
        type: [Boolean] as PropType<InputHTMLAttributes['checked']>,
        default: false
    },
    id: {
        type: String,
        required: true
    },
    name: {
        type: String,
        required: false,
        default: ''
    },
    label: {
        type: String,
        required: false,
        default: ''
    },
    helpText: {
        type: String,
        required: false,
        default: undefined
    },
    required: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    dataQa: {
        type: [String] as PropType<String | null>,
        default: null
    },
    errorMessageDataQa: {
        type: String,
        required: false,
        default: undefined
    }
})

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
    handleChange,
    errorMessage
} = useField(name, undefined, {
    type: 'checkbox',
    checkedValue: props.modelValue,
    syncVModel: true
})

const errorMessageDataQaAttr = props.errorMessageDataQa || (props.dataQa ? `${props.dataQa}-message` : '')
</script>

<style scoped lang="less">
.has-error {
    .input-label {
        color: var(--color-error);
    }
}

.help-text {
    font-size: 12px;
    color: var(--color-c-grey2);
    margin-top: 5px;
}
</style>
