import { sendClick, sendPage } from '../webtrekk'

export const PAGE_FUNCTIONS = {
    WKV: 'wkv'
}

/*
 * WkvWebTrekkService
 */
export function placeNewWkvView(product: any) {
    const params = {
        product: 'wkv_order',
        productId: 'wkv_order',
        currency: 'EUR',
        productQuantity: '1',
        productCost: product.productCost,
        productStatus: 'view',
        orderValue: product.orderValue,
        productCategory: {
            1: 'reports',
            2: 'wkv'
        },
        customEcommerceParameter: {
            102: window.location.href,
            103: 'Ansichten des WKV-Rechners'
        }
    }

    const customPageParameters = {
        105: 'wkv',
        106: 'view'
    }

    sendPage(params, customPageParameters)
}

export function placeNewWkvOrder(product: any) {
    const params = {
        product: 'wkv_order',
        productId: 'wkv_order',
        currency: 'EUR',
        orderId: product.orderId,
        productQuantity: '1',
        productCost: product.productCost,
        productStatus: 'conf',
        orderValue: product.orderValue,
        productCategory: {
            1: 'reports',
            2: 'wkv'
        },
        customEcommerceParameter: {
            100: product.insuranceSum,
            101: product.insurancePeriod,
            102: window.location.href,
            103: 'Abschlüsse WKV',
            104: String(product.productCost),
            105: product.orderNo
        }
    }
    const customPageParameters = {
        105: 'wkv',
        106: 'order'
    }

    sendPage(params, customPageParameters)
    sendClick('WKV_Abschluss', {
        102: window.location.href,
        104: String(product.productCost),
        105: product.orderNo
    })
}

export function placeNewWkvOrderFailedRequest(xhr: any, errorText: string) {
    sendClick(
        'WKV_Abschluss_Fehler',
        {
            2: xhr.status + ' ' + xhr.statusText,
            3: errorText,
            5: 'ERROR',
            6: PAGE_FUNCTIONS.WKV,
            7: 'WKV Abschluss',
            8: 'Request konnte nicht gesendet werden.'
        }
    )
}
