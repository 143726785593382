<script setup lang="ts">
import { provide, ref } from 'vue'

const props = defineProps({
    activeItemKey: {
        type: Number,
        default: undefined
    }
})

const activeItemKey = ref(props.activeItemKey)
provide('activeItemKey', activeItemKey)
</script>

<template>
    <div
        class="container accordion"
        data-qa="accordion"
    >
        <div style="border-top: 1px solid var(--color-c-linegrey)">
            <slot />
        </div>
    </div>
</template>

<style scoped lang="less">
.container.accordion {
  padding: 0;
  margin: 0;
}
</style>
