<template>
    <div 
        class="crefo-ui-modal"
        data-qa="legitimationOverlay"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h2>{{ $t('report.orderForm.headline.legitimateInterest') }}</h2>
            </div>
            <div class="modal-body">
                <div class="modal-text text-brand-dark-grey text-align--left">
                    <!-- eslint-disable vue/no-v-html -->
                    <p 
                        class="mb"
                        v-html="$t('report.orderForm.text.legitimateInterest')" 
                    />
                    <Select
                        id="legitimateInterest"
                        v-model="legitimateInterest"
                        :label="$t('report.orderForm.label.legitimateInterest')"
                        :searchable="false"
                        :options="CONSTANTS.LEGITIMATE_INTEREST_OPTIONS"
                        data-qa="legitimateInterest"
                    />
                    <div
                        v-if="legitimateInterest"
                        class="crefo-ui-alert"
                    >
                        <b>{{ $t(`enums.legitimateInterestOptions.${legitimateInterest}`) }}</b><br>
                        <span :data-qa="`legitimateInterestExplanationNoteText${showFullText ? 'Long' : 'Short'}`">
                            {{ infoText }}
                        </span>&nbsp;
                        <a 
                            href="#" 
                            :data-qa="`legitimateInterestExplanationToggleButton${showFullText ? 'Less' : 'More'}`"
                            @click.prevent="showFullText = !showFullText"
                        >
                            {{ $t(`report.detail.legitimateInterest.legal_note.buttonText${showFullText ? 'Less' : 'More'}`) }}
                        </a>
                    </div>
                </div>
                <div class="small-content-spacer" />
                <div class="modal-buttons mb-small">
                    <button 
                        class="btn btn-default"
                        :disabled="isSaving || !legitimateInterest"
                        data-qa="continueToReport" 
                        style="width:calc(70% - 0.5rem)"
                        @click.prevent="submit"
                    >
                        {{ $t('report.orderForm.label.legitimateInterest.submit') }}
                    </button>
                    <button 
                        class="btn btn-secondary" 
                        :disabled="isSaving"
                        data-qa="legitimation-overlay-abort-btn"
                        style="width:calc(30% - 0.5rem)"
                        @click.prevent="$emit('abort')"
                    >
                        {{ $t('report.orderForm.label.legitimateInterest.abort') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import Select from '@/components/Input/Select.vue'

import CONSTANTS from '@/data/constants'

import { saveReportOrderLegitimation } from '@/services'
import { useUserStore } from '@/stores/user'

const { t } = useI18n()

const emit = defineEmits(['abort', 'success', 'error'])
const props = defineProps({
    productOrderId: {
        type: String,
        required: true
    },
})

const user = useUserStore()

// const legitimateInterest = ref(user.cbraUser.userPreferences?.defaultReportLegitimateInterest)
const legitimateInterest = ref(user.cbraUser.userPreferences?.defaultReportLegitimateInterest || null)
const showFullText = ref(false)
const isSaving = ref(false)

const infoText = computed(() => {
    return t(`report.detail.legitimateInterest.legal_note${showFullText.value ? '_full' : ''}.${legitimateInterest.value}`)
})

async function submit() {
    isSaving.value = true
    const payload = {
        legitimateInterest: legitimateInterest.value
    }
    try {
        const { status } = await saveReportOrderLegitimation(props.productOrderId, payload)
        isSaving.value = false
        if (status === 200) {
            emit('success')
        }
    } catch (error) {
        emit('error')
    }
}
</script>

<style scoped>
.crefo-ui-alert a {
    color: var(--color-c-p1);
}

.modal-buttons button:not(:last-child) {
    margin-right: .5rem;
}
</style>
