import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from '@/services/tracing-axios'
import { AxiosResponse } from 'axios'
import type { CbraMember, CbraMemberAddress, CbraMemberUpdateData, EnhancedCbraMember } from '@/types/cbra/member'

export const useMembersStore = defineStore('members', () => {
    // state
    const error = ref('')
    const cbraMember = ref<CbraMember>({
        memberId: '',
        businessId: '',
        memberName: '',
        naturalPerson: false,
        memberAddress: {
            addressLine1: '',
            addressLine2: '',
        },
        invoiceAddress: {
            addressLine1: '',
            addressLine2: '',
        },
        iban: '',
        bic: '',
        entryDate: new Date(),
        exitDate: new Date()
    })
    const memberList = ref<Array<EnhancedCbraMember>>([])

    const updatePayload = ref<CbraMemberUpdateData>({
        memberId: '',
        naturalPerson: false,
        companyName: {
            nameLine1: null,
            nameLine2: null,
            nameLine3: null
        },
        memberAddress: null,
        invoiceAddress: null,
        iban: '',
        bic: '',
        validFromDate: null,
        additionalInfos: ''
    })
    const dirtyCompany = ref(false)
    const dirtyAddress = ref(false)
    const dirtyInvoice = ref(false)
    // state

    // getters
    // getters

    // actions
    async function getMember() {
        try {
            const { status, data } = await axios.get<CbraMember, AxiosResponse<CbraMember>, void>('/cbra/members')

            if (status !== 200) {
                return false
            }

            cbraMember.value = data
            cbraMember.value.entryDate = new Date(cbraMember.value.entryDate)
            cbraMember.value.exitDate && (cbraMember.value.exitDate = new Date(cbraMember.value.exitDate))

            // populare updatePayload
            updatePayload.value.memberId = cbraMember.value.memberId
            updatePayload.value.naturalPerson = cbraMember.value.naturalPerson
            updatePayload.value.bic = cbraMember.value.bic || ''
            updatePayload.value.iban = cbraMember.value.iban || ''
            return true
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
            return err
        }
    }
    async function updateMember() {
        const parseHouseNumber = (data: CbraMemberAddress) => {
            const HOUSE_NO_REGEX = /^([1-9][0-9]{0,4})((-| - )[1-9][0-9]{0,4})?( ?[a-zA-Z]{1,8}((-| - )[a-zA-Z]{1,7})?)?/g
            if (data !== null && typeof data.houseNumber === 'string') {
                const matches = data.houseNumber.matchAll(HOUSE_NO_REGEX)
                let number = ''
                let suffix = null
                for (const match of matches) {
                    number = match[1]
                    suffix = match[4]
                }
                data.houseNumber = number
                data.houseNumberSuffix = typeof suffix === 'undefined' ? null : suffix
            }
            return data
        }

        error.value = ''

        try {
            updatePayload.value.memberAddress = parseHouseNumber(updatePayload.value.memberAddress)
            updatePayload.value.invoiceAddress = parseHouseNumber(updatePayload.value.invoiceAddress)
            console.log('updatePayload', updatePayload.value)
            if (
                updatePayload.value.memberAddress !== null &&
                updatePayload.value.memberAddress.type === null
            ) {
                updatePayload.value.memberAddress = null
            }
            
            if (
                updatePayload.value.invoiceAddress !== null &&
                updatePayload.value.invoiceAddress.type === null
            ) {
                updatePayload.value.invoiceAddress = null
            }

            if (updatePayload.value.validFromDate !== null) {
                const ts = (new Date(updatePayload.value.validFromDate)).valueOf()
                if (ts) {
                    updatePayload.value.validFromDate = '' + ts
                }
            }
            
            const payload: CbraMemberUpdateData = updatePayload.value
            await axios.put('/cbra/members/updates', payload)
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }
    }
    // actions

    return { cbraMember, memberList, updatePayload, dirtyCompany, dirtyAddress, dirtyInvoice, error, getMember, updateMember }
})
