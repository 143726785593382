export enum WatchlistSort {
    CREATION_TIMESTAMP_DESC = 'CREATION_TIMESTAMP_DESC',
    CREATION_TIMESTAMP_ASC = 'CREATION_TIMESTAMP_ASC',
    EVENT_TIMESTAMP_DESC = 'EVENT_TIMESTAMP_DESC',
    EVENT_TIMESTAMP_ASC = 'EVENT_TIMESTAMP_ASC',
    OBSERVATION_END_TIME_DESC = 'OBSERVATION_END_TIME_DESC',
    BUSINESS_NAME_ASC = 'BUSINESS_NAME_ASC',
    BUSINESS_NAME_DESC = 'BUSINESS_NAME_DESC',
}

export enum WatchlistFilter {
    ALL= 'ALL',
    SUPPLEMENT= 'SUPPLEMENTS_ONLY',
    MONITORING= 'MONITORING_ONLY',
    SIGNAL= 'SIGNALS_ONLY',
    SINCE_LAST_LOGIN= 'ALL_CHANGED_AFTER_GIVEN_DATE_BY_LAST_EVENT_TIME',
    EXPIRING_OBSERVATIONS= 'EXPIRING_OBSERVATIONS',
    EXPIRED_OBSERVATIONS= 'EXPIRED_OBSERVATIONS'
}

export enum WatchlistHasActiveSignalsState {
    SUPPLEMENT_ACTIVE = 'SUPPLEMENT_ACTIVE',
    MONITORING_ACTIVE = 'MONITORING_ACTIVE',
    SIGNAL_ACTIVE = 'SIGNAL_ACTIVE',
}