<template>
    <SlideComponent>
        <div
            class="productTeaserBox"
            data-qa="productTeaserBox"
            :class="{ 'productTeaserBox--recommended': product.recommended}"
        >
            <!-- FRONT -->
            <div
                class="productTeaserBox__face productTeaserBox--front"
            >
                <div class="productTeaserBox__body">
                    <div class="productTeaserBox__head">
                        <h3
                            class="productTeaserBox__headline"
                            data-qa="productTeaserBox-headline-front"
                        >
                            {{ $t(product.title) }}
                        </h3>
                        <div
                            class="productTeaserBox__subheadline"
                        >
                            {{ $t(product.subtitle) }}
                        </div>

                        <a
                            v-if="product.sampleFile !==''"
                            :href="product.sampleFile"
                            class="productTeaserBox__download vspace--2"
                            data-qa="productTeaserBox-downloadSamplePDF"
                        >
                            <font-awesome-icon icon="fa-solid fa-chevron-right" />
                            {{ $t('report.detail.downloadSampleFile') }}
                        </a>
                        <span
                            v-if="product.recommended"
                            class="productTeaserBox__badge"
                            data-qa="productTeaserBox-recommended-badge"
                        >
                            {{ $t('report.detail.ourRecommendation') }}
                        </span>
                    </div>
                    <div
                        class="small-box-spacing"
                    >
                        {{ $t(product.description) }}
                    </div>
                    <ul

                        class="checkedList fa-ul"
                    >
                        <li
                            v-for="featureId in product.features"
                            :key="featureId"
                            class="checkedList__item"
                        >
                            <font-awesome-icon
                                icon="fa-regular fa-check"
                                color="var(--color-c-p1)"
                                size="xl"
                            />
                            {{ $t('reportProducts.features.' + featureId) }}
                        </li>
                    </ul>
                    <div class="productTeaserBox__cta">
                        <div v-if="product.prohibited && !isDisabled">
                            <Tooltip>
                                <button
                                    :disabled="!isDisabled"
                                    :data-qa="isDisabled ? 'productTeaserBox-prohibitedButton-disabled' : 'productTeaserBox-prohibitedButton'"
                                    class="btn btn-legacy-orange btn-default"
                                >
                                    {{ $t('report.detail.retrieveReport') }}
                                </button>
                                <template #content>
                                    <span>{{ $t('reportProducts.permissionError.message') }}</span>
                                    <br>
                                    <a
                                        class="popover__link"
                                        href="#"
                                        @click="requestPermission"
                                    >
                                        {{ $t('reportProducts.permissionError.linkText') }}
                                    </a>
                                </template>
                            </Tooltip>
                        </div>
                        <button
                            v-else
                            class="btn btn-legacy-orange btn-default"
                            :data-qa="buttonDataQa"
                            :disabled="isDisabled"
                            @click="flip"
                        >
                            {{ buttonText }}
                        </button>
                    </div>
                </div>
            </div>
            <!-- /FRONT -->
        </div>
    </SlideComponent>
</template>

<script lang="ts" setup>
import { computed, } from 'vue'
import SlideComponent from '../Slider/SlideComponent.vue'
import { useI18n } from 'vue-i18n'
import type { TransformedProduct } from '@/data/products'
import { requestCbraPermission } from '@/services'
import { useProduct } from './useProduct'
import { CbraTransaction } from '@/types/cbra/transactions'
import Tooltip from '../Tooltip.vue'
import { storeToRefs } from 'pinia'
import { useProductStore } from '@/stores/product'

const props = defineProps<{
  product: TransformedProduct
  hasBusinessData: boolean
  relatedTransactions: CbraTransaction[]
}>()

const emit = defineEmits(['flip'])
function flip() {
    emit('flip', props.product)
}

const { businessId, isDisabled } = useProduct()

const { t } = useI18n()
const productStore = useProductStore()
const { modal } = storeToRefs(productStore)

const buttonText = computed(() => {
    return props.product.hasToBeOrdered ? t('report.detail.orderReportNow') : t('report.detail.retrieveReport')
})
const buttonDataQa = computed(() => {
    return 'productTeaserBox-' + (!props.product.hasToBeOrdered ? 'buyButton' : 'orderButton') + (isDisabled.value ? '-disabled' : '')
})

async function requestPermission() {
    if (!props.product || !businessId) return
    try {
        await requestCbraPermission({ businessId: businessId, productType: props.product.type })

        modal.value = {
            id: 'requestPermission',
            title: t('report.detail.requestPermission.purchase.info.title'),
            content: t('report.detail.requestPermission.purchase.info.description'),
            confirmText: t('shared.modals.ok'),
            icon: 'info',
            onClose: () => modal.value = undefined,
            onConfirm: () => modal.value = undefined
        }
    } catch (error) {
        modal.value = {
            id: 'requestPermissionError',
            title: t('report.detail.requestPermission.purchase.error.title'),
            content: t('report.detail.requestPermission.purchase.error.description'),
            confirmText: t('shared.modals.ok'),
            icon: 'error',
            onClose: () => modal.value = undefined,
            onConfirm: () => modal.value = undefined
        }
    }
}

</script>

<style lang="less">

// variables
@selectFieldHeight: 50px;
@checkboxHeight: 23px;
@overlayContentPadding--mobile: 5px;
@trans-black: rgba(0, 0, 0, 0.2);
@crefo-product-teaser-badge-bg-color:  #f8e71c;
@crefo-product-teaser-badge-txt-color:        #183f6d;
@perfect-white: #ffffff;
@screen-xs-max: 767px;
@crefo-product-teaser-content-bg-color:   @perfect-white;
@crefo-product-teaser-head-text-color:   @perfect-white;
@pearl:             #eaeaea;
@greyish-brown:     #4a4a4a;
@lipstick:          #e05469; //#e43649;
@crefo-checked-list-even-bg-color:  rgba(0, 141, 217, 0.2);
@crefo-checked-list-odd-bg-color:   rgba(0, 141, 217, 0.11);

// .splide__list .is-active .productTeaserBox__face {
//     height: 725px;
// }

.productTeaserBox {

    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

  .form-group {
      position: relative;
      margin-bottom: 20px;
  }

  // position: absolute;
  // top: 10px;
  // bottom: 25px;
  // width: 100%;

  // .swiper-slide-active & {
  //   top: 0;
  //   bottom: 15px;
  //   z-index: 1000;

  //   &__face {
  //     -webkit-box-shadow: 0 8px 15px 0 @trans-black;
  //     -moz-box-shadow: 0 8px 15px 0 @trans-black;
  //     box-shadow: 0 8px 15px 0 @trans-black;
  //   }
  // }

  &__badge {
    display: none;
    background-color: @crefo-product-teaser-badge-bg-color;
    color: @crefo-product-teaser-badge-txt-color;
    font-size: .8rem;
    text-align: center;
    font-weight: 700;
    transform-origin: top left;
    transform: rotate(-315deg) translateX(57px) translateY(-140px);
    position: absolute;
    top: 0;
    right: 0;
    padding: .8rem 6rem .8rem 6rem;
    line-height: 1.2;
    box-shadow: 0 2px 10px 0 #000, 0 0 2px #333 inset;
    border: 1px solid @perfect-white;

    background-image: -moz-linear-gradient(-315deg, lighten(@crefo-product-teaser-badge-bg-color, 50) 0%, @crefo-product-teaser-badge-bg-color 100%); /* FF3.6-15 */
    background-image: -webkit-linear-gradient(-315deg, lighten(@crefo-product-teaser-badge-bg-color, 50) 0%,@crefo-product-teaser-badge-bg-color 100%); /* Chrome10-25,Safari5.1-6 */
    background-image: linear-gradient(-315deg, lighten(@crefo-product-teaser-badge-bg-color, 50) 0%,@crefo-product-teaser-badge-bg-color 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  &__face {
    height: 700px;
    overflow: visible;
    backface-visibility: hidden;
    z-index: 1;

    transform-style: preserve-3d;
    transition: transform 1s;

    @media only screen and (max-width:@screen-xs-max) {
      transition: none;
    }
  }

  &--back {
    // position: absolute;
    // top: 0;
    background-color: @crefo-product-teaser-content-bg-color;
    transform: rotateY(-180deg);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
  }

  &--front {
    transform: rotateY(0deg);
  }

  &.flipped {
    margin-top: -30px;

    .productTeaserBox--back {
      transform: rotateY(0deg);
      height: auto;
    }

    .productTeaserBox--front {
      transform: rotateY(180deg);
    }
    .productTeaserBox__cta {
        position: relative;
    }
  }

  &--recommended &__badge {
    display: block;
  }

  &__head {
    background: transparent url("@/assets/img/uds/blue_header_arrow_down.png") bottom center no-repeat;
    background-size: cover;

    color: @crefo-product-teaser-head-text-color;
    position: relative;
    padding: 2rem;
    font-size: 1rem;
    overflow: hidden;
    text-align: center;
  }

  &__headline {
    margin: 0 0 1rem;
    font-size: 1.6rem;
    text-align: center;
    display: block;
    color: @perfect-white;
    font-weight: bold;
  }

  &__subheadline {
    font-size: 1rem;
    display: block;
    margin-bottom: 2rem;
    font-weight: bold;
  }

  &__download {
    color: @crefo-product-teaser-head-text-color;
    position: relative;
    font-weight: 600;
  }

  &__bought-message {
    font-weight: 600;
    margin: 0 50px;
  }

  &__info-icon {
    &--position {
      position: absolute;
      top: 0;
      right: -45px;
    }
  }

  &--back {
    .productTeaserBox__head, .productTeaserBox__body {
      background-color: #fff;
    }
  }

  &__contentWrapper {
    padding: 2rem 3rem;
    position: relative;
  }

  &__body {
    background-color: @crefo-product-teaser-content-bg-color;
    font-size: 1rem;
    line-height: 1.4;
    height: 100%;
    text-align: left;
    position: relative;
    // padding-bottom: 110px;

    &--extended {
      padding-bottom: 160px;
    }

    .productTeaserBox--back & {
      height: auto;
      min-height: 100%;
      background: #fff;
    }

  }

  &__cta {
    // padding-bottom: 35px;
    // position: absolute;
    // width: 100%;
    // bottom: 0;
    // text-align: center;
    // left:0;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    padding: 35px 0;

    .btn {
      min-width: 250px;
    }
  }

  .interest-select .info-icon-container {
    position: relative;
    margin-left: 12px;
    margin-top: 12px;
  }

  &__more-info-link {
    position: relative;
    padding: 9px 1.5rem 9px 5.5rem;
    background-color: @perfect-white;
    cursor: pointer;
    font-size: 1rem;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &::after {
      position: absolute;
      left: 1.5rem;
      top: 7px;
      width: 27px;
      height: 27px;
      border-radius: 15px;
      background-color: @greyish-brown;
      color: @perfect-white;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      content: '+';
    }

    &.open::after {
      content: '—';
      font-size: 17px;
    }
  }

  &__more-info {
    position: relative;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
    .open + & {
      max-height: 500px;
      overflow: visible;
    }
  }

  &__productAvailabilityInfo {
    font-size: 14px;
    color: @lipstick;
    text-align: left;
    max-height: 40px;
    overflow: hidden;
  }

}

.overlay__content-wrapper .productTeaserBox {
  top: 0px;
  bottom: 15px;
}

@media only screen and (max-width:@screen-xs-max) {
  .overlay__content-wrapper .productTeaserBox {
    top: @overlayContentPadding--mobile;
    bottom: @overlayContentPadding--mobile;
    left: @overlayContentPadding--mobile;
    right: @overlayContentPadding--mobile;
    width: auto;
  }
}

// checkedList
.checkedList {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &__item {
    display: flex;
    gap: 10px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: left;
    &:nth-child(even) {
      background-color: @crefo-checked-list-even-bg-color;
    }

    &:nth-child(odd) {
      background-color: @crefo-checked-list-odd-bg-color;
    }
  }
}

</style>
