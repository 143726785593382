<template>
    <div
        v-if="userStore.authenticated"
        :id="id"
        :data-page-id="id"
        class="normal-content-spacer"
    >
        <CompanySearch v-if="isMitglied" />

        <template v-if="userStore.ssoChecked">
            <DashboardMessage v-if="isMitglied" />

            <IkarosMessage v-if="isMitglied" />

            <InfoboxList v-if="isMitglied" />

            <Widgets v-if="isMitglied && userStore.ssoChecked" />
        </template>

        <InfoTeaser v-if="!isMitglied" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <hr class="mt">
                    <AdvantagesTeaser />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import CompanySearch from '@/components/CompanySearch.vue'
import AdvantagesTeaser from '@/components/Homepage/AdvantagesTeaser.vue'
import InfoTeaser from '@/components/Dashboard/InfoTeaser.vue'
import Widgets from '@/components/Dashboard/Widgets.vue'
import { useUserStore } from '@/stores/user'
import { isMitgliedsbenutzer } from '@/helper/services/user'
import InfoboxList from '@/components/Dashboard/InfoboxList.vue'
import DashboardMessage from '@/components/Dashboard/DashboardMessage.vue'
import IkarosMessage from '@/components/Dashboard/IkarosMessage.vue'
import { trackMemberNavigationAccess } from '@/helper/webtrekk/superuser'

const userStore = useUserStore()
const id = 'portal.mc.dashboard'

const isMitglied = isMitgliedsbenutzer()

trackMemberNavigationAccess('startseite')
</script>
