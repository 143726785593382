import { ComposerTranslation } from 'vue-i18n'

type rechtsformOption = {
    label: string
    code: string | null
}

export function rechtsformOptions(t: ComposerTranslation, country: string) {
    const rechtsformOptionsAT: rechtsformOption[] = [
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90122'), code: 'LEGALFORM_90122', },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_11022'), code: 'LEGALFORM_11022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_19022'), code: 'LEGALFORM_19022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90222'), code: 'LEGALFORM_90222' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_6022'), code: 'LEGALFORM_6022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_75022'), code: 'LEGALFORM_75022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_2022'), code: 'LEGALFORM_2022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90022'), code: 'LEGALFORM_90022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_5022'), code: 'LEGALFORM_5022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90922'), code: 'LEGALFORM_90922' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10022'), code: 'LEGALFORM_10022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_4022'), code: 'LEGALFORM_4022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10322'), code: 'LEGALFORM_10322' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10222'), code: 'LEGALFORM_10222' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_17022'), code: 'LEGALFORM_17022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90522'), code: 'LEGALFORM_90522' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90822'), code: 'LEGALFORM_90822' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_8122'), code: 'LEGALFORM_8122' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_8022'), code: 'LEGALFORM_8022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_2522'), code: 'LEGALFORM_2522' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10122'), code: 'LEGALFORM_10122' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_3022'), code: 'LEGALFORM_3022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90422'), code: 'LEGALFORM_90422' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_7122'), code: 'LEGALFORM_7122' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_7022'), code: 'LEGALFORM_7022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90722'), code: 'LEGALFORM_90722' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_17522'), code: 'LEGALFORM_17522' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_18122'), code: 'LEGALFORM_18122' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_12022'), code: 'LEGALFORM_12022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_12122'), code: 'LEGALFORM_12122' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_11122'), code: 'LEGALFORM_11122' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_19122'), code: 'LEGALFORM_19122' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_19222'), code: 'LEGALFORM_19222' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_20022'), code: 'LEGALFORM_20022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_18022'), code: 'LEGALFORM_18022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_13022'), code: 'LEGALFORM_13022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90322'), code: 'LEGALFORM_90322' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_15022'), code: 'LEGALFORM_15022' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_4122'), code: 'LEGALFORM_4122' }
    ]
    
    const rechtsformOptionsDE : rechtsformOption[] = [
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90101'), code: 'LEGALFORM_90101' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90601'), code: 'LEGALFORM_90601' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_14301'), code: 'LEGALFORM_14301' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_11001'), code: 'LEGALFORM_11001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_19001'), code: 'LEGALFORM_19001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90201'), code: 'LEGALFORM_90201' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_12001'), code: 'LEGALFORM_12001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_6001'), code: 'LEGALFORM_6001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_75001'), code: 'LEGALFORM_75001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_2001'), code: 'LEGALFORM_2001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_5001'), code: 'LEGALFORM_5001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90401'), code: 'LEGALFORM_90401' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_91101'), code: 'LEGALFORM_91101' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_14101'), code: 'LEGALFORM_14101' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_4001'), code: 'LEGALFORM_4001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10001'), code: 'LEGALFORM_10001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_3001'), code: 'LEGALFORM_3001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90001'), code: 'LEGALFORM_90001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90501'), code: 'LEGALFORM_90501' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_14201'), code: 'LEGALFORM_14201' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_17001'), code: 'LEGALFORM_17001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90801'), code: 'LEGALFORM_90801' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_14401'), code: 'LEGALFORM_14401' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90701'), code: 'LEGALFORM_90701' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_8001'), code: 'LEGALFORM_8001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_14001'), code: 'LEGALFORM_14001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_2501'), code: 'LEGALFORM_2501' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10101'), code: 'LEGALFORM_10101' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_91001'), code: 'LEGALFORM_91001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_14501'), code: 'LEGALFORM_14501' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_7001'), code: 'LEGALFORM_7001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_17501'), code: 'LEGALFORM_17501' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_4501'), code: 'LEGALFORM_4501' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_4601'), code: 'LEGALFORM_4601' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90901'), code: 'LEGALFORM_90901' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_14601'), code: 'LEGALFORM_14601' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_91401'), code: 'LEGALFORM_91401' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_91501'), code: 'LEGALFORM_91501' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10301'), code: 'LEGALFORM_10301' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_12101'), code: 'LEGALFORM_12101' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_11101'), code: 'LEGALFORM_11101' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_19101'), code: 'LEGALFORM_19101' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_19201'), code: 'LEGALFORM_19201' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_18001'), code: 'LEGALFORM_18001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_91601'), code: 'LEGALFORM_91601' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_91701'), code: 'LEGALFORM_91701' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_91201'), code: 'LEGALFORM_91201' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_91301'), code: 'LEGALFORM_91301' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10201'), code: 'LEGALFORM_10201' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_13001'), code: 'LEGALFORM_13001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90301'), code: 'LEGALFORM_90301' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_15001'), code: 'LEGALFORM_15001' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_4101'), code: 'LEGALFORM_4101' }
    ]
    
    const rechtsformOptionsLU: rechtsformOption[] = [
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_13718'), code: 'LEGALFORM_13718' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_2618'), code: 'LEGALFORM_2618' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_5018'), code: 'LEGALFORM_5018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_13018'), code: 'LEGALFORM_13018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_6018'), code: 'LEGALFORM_6018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_17018'), code: 'LEGALFORM_17018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_18018'), code: 'LEGALFORM_18018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_2018'), code: 'LEGALFORM_2018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_75118'), code: 'LEGALFORM_75118' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_75018'), code: 'LEGALFORM_75018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_13618'), code: 'LEGALFORM_13618' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_2518'), code: 'LEGALFORM_2518' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10118'), code: 'LEGALFORM_10118' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_13518'), code: 'LEGALFORM_13518' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_90018'), code: 'LEGALFORM_90018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_14018'), code: 'LEGALFORM_14018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10018'), code: 'LEGALFORM_10018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_11018'), code: 'LEGALFORM_11018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_4518'), code: 'LEGALFORM_4518' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_12018'), code: 'LEGALFORM_12018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_4018'), code: 'LEGALFORM_4018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_8018'), code: 'LEGALFORM_8018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_7018'), code: 'LEGALFORM_7018' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_12118'), code: 'LEGALFORM_12118' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_11118'), code: 'LEGALFORM_11118' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_19118'), code: 'LEGALFORM_19118' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_19218'), code: 'LEGALFORM_19218' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_4118'), code: 'LEGALFORM_4118' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_10218'), code: 'LEGALFORM_10218' },
        { label: t('advancedSearch.options.rechtsform.LEGALFORM_11218'), code: 'LEGALFORM_11218' }
    ]

    switch (country) {
    case 'AT':
        return rechtsformOptionsAT
    case 'DE':
        return rechtsformOptionsDE
    case 'LU':
        return rechtsformOptionsLU
    default:
        return []
    }
}
