import type { SelectOption } from '@/types/components/select.d.ts'

const countryDialingCodes: Array<SelectOption> =[
    { label: '', code: '+49', i18nKey: 'DE (+49)' }, 
    { label: '', code: '+43', i18nKey: 'AT (+43)' }, 
    { label: '', code: '+352', i18nKey: 'LU (+352)' }, 
    { label: '', code: '+376', i18nKey: 'AD (+376)' }, 
    { label: '', code: '+971', i18nKey: 'AE (+971)' }, 
    { label: '', code: '+93', i18nKey: 'AF (+93)' }, 
    { label: '', code: '+1268', i18nKey: 'AG (+1268)' }, 
    { label: '', code: '+1264', i18nKey: 'AI (+1264)' }, 
    { label: '', code: '+355', i18nKey: 'AL (+355)' }, 
    { label: '', code: '+374', i18nKey: 'AM (+374)' }, 
    { label: '', code: '+244', i18nKey: 'AO (+244)' }, 
    { label: '', code: '+672', i18nKey: 'AQ (+672)' }, 
    { label: '', code: '+54', i18nKey: 'AR (+54)' }, 
    { label: '', code: '+1684', i18nKey: 'AS (+1684)' }, 
    { label: '', code: '+61', i18nKey: 'AU (+61)' }, 
    { label: '', code: '+297', i18nKey: 'AW (+297)' }, 
    { label: '', code: '+358', i18nKey: 'AX (+358)' }, 
    { label: '', code: '+994', i18nKey: 'AZ (+994)' }, 
    { label: '', code: '+387', i18nKey: 'BA (+387)' }, 
    { label: '', code: '+1246', i18nKey: 'BB (+1246)' }, 
    { label: '', code: '+880', i18nKey: 'BD (+880)' }, 
    { label: '', code: '+32', i18nKey: 'BE (+32)' }, 
    { label: '', code: '+226', i18nKey: 'BF (+226)' }, 
    { label: '', code: '+359', i18nKey: 'BG (+359)' }, 
    { label: '', code: '+973', i18nKey: 'BH (+973)' }, 
    { label: '', code: '+257', i18nKey: 'BI (+257)' }, 
    { label: '', code: '+229', i18nKey: 'BJ (+229)' }, 
    { label: '', code: '+590', i18nKey: 'BL (+590)' }, 
    { label: '', code: '+1441', i18nKey: 'BM (+1441)' }, 
    { label: '', code: '+673', i18nKey: 'BN (+673)' }, 
    { label: '', code: '+591', i18nKey: 'BO (+591)' }, 
    { label: '', code: '+599', i18nKey: 'BQ (+599)' }, 
    { label: '', code: '+55', i18nKey: 'BR (+55)' }, 
    { label: '', code: '+1242', i18nKey: 'BS (+1242)' }, 
    { label: '', code: '+975', i18nKey: 'BT (+975)' }, 
    { label: '', code: '+55', i18nKey: 'BV (+55)' }, 
    { label: '', code: '+267', i18nKey: 'BW (+267)' }, 
    { label: '', code: '+375', i18nKey: 'BY (+375)' }, 
    { label: '', code: '+501', i18nKey: 'BZ (+501)' }, 
    { label: '', code: '+1', i18nKey: 'CA (+1)' }, 
    { label: '', code: '+61', i18nKey: 'CC (+61)' }, 
    { label: '', code: '+243', i18nKey: 'CD (+243)' }, 
    { label: '', code: '+236', i18nKey: 'CF (+236)' }, 
    { label: '', code: '+242', i18nKey: 'CG (+242)' }, 
    { label: '', code: '+41', i18nKey: 'CH (+41)' }, 
    { label: '', code: '+225', i18nKey: 'CI (+225)' }, 
    { label: '', code: '+682', i18nKey: 'CK (+682)' }, 
    { label: '', code: '+56', i18nKey: 'CL (+56)' }, 
    { label: '', code: '+237', i18nKey: 'CM (+237)' }, 
    { label: '', code: '+86', i18nKey: 'CN (+86)' }, 
    { label: '', code: '+57', i18nKey: 'CO (+57)' }, 
    { label: '', code: '+506', i18nKey: 'CR (+506)' }, 
    { label: '', code: '+53', i18nKey: 'CU (+53)' }, 
    { label: '', code: '+238', i18nKey: 'CV (+238)' }, 
    { label: '', code: '+599', i18nKey: 'CW (+599)' }, 
    { label: '', code: '+61', i18nKey: 'CX (+61)' }, 
    { label: '', code: '+357', i18nKey: 'CY (+357)' }, 
    { label: '', code: '+420', i18nKey: 'CZ (+420)' }, 
    { label: '', code: '+253', i18nKey: 'DJ (+253)' }, 
    { label: '', code: '+45', i18nKey: 'DK (+45)' }, 
    { label: '', code: '+1767', i18nKey: 'DM (+1767)' }, 
    { label: '', code: '+1849', i18nKey: 'DO (+1849)' }, 
    { label: '', code: '+213', i18nKey: 'DZ (+213)' }, 
    { label: '', code: '+593', i18nKey: 'EC (+593)' }, 
    { label: '', code: '+372', i18nKey: 'EE (+372)' }, 
    { label: '', code: '+20', i18nKey: 'EG (+20)' }, 
    { label: '', code: '+212', i18nKey: 'EH (+212)' }, 
    { label: '', code: '+291', i18nKey: 'ER (+291)' }, 
    { label: '', code: '+34', i18nKey: 'ES (+34)' }, 
    { label: '', code: '+251', i18nKey: 'ET (+251)' }, 
    { label: '', code: '+358', i18nKey: 'FI (+358)' }, 
    { label: '', code: '+679', i18nKey: 'FJ (+679)' }, 
    { label: '', code: '+500', i18nKey: 'FK (+500)' }, 
    { label: '', code: '+691', i18nKey: 'FM (+691)' }, 
    { label: '', code: '+298', i18nKey: 'FO (+298)' }, 
    { label: '', code: '+33', i18nKey: 'FR (+33)' }, 
    { label: '', code: '+241', i18nKey: 'GA (+241)' }, 
    { label: '', code: '+44', i18nKey: 'GB (+44)' }, 
    { label: '', code: '+1473', i18nKey: 'GD (+1473)' }, 
    { label: '', code: '+995', i18nKey: 'GE (+995)' }, 
    { label: '', code: '+594', i18nKey: 'GF (+594)' }, 
    { label: '', code: '+44', i18nKey: 'GG (+44)' }, 
    { label: '', code: '+233', i18nKey: 'GH (+233)' }, 
    { label: '', code: '+350', i18nKey: 'GI (+350)' }, 
    { label: '', code: '+299', i18nKey: 'GL (+299)' }, 
    { label: '', code: '+220', i18nKey: 'GM (+220)' }, 
    { label: '', code: '+224', i18nKey: 'GN (+224)' }, 
    { label: '', code: '+590', i18nKey: 'GP (+590)' }, 
    { label: '', code: '+240', i18nKey: 'GQ (+240)' }, 
    { label: '', code: '+30', i18nKey: 'GR (+30)' }, 
    { label: '', code: '+500', i18nKey: 'GS (+500)' }, 
    { label: '', code: '+502', i18nKey: 'GT (+502)' }, 
    { label: '', code: '+1671', i18nKey: 'GU (+1671)' }, 
    { label: '', code: '+245', i18nKey: 'GW (+245)' }, 
    { label: '', code: '+595', i18nKey: 'GY (+595)' }, 
    { label: '', code: '+852', i18nKey: 'HK (+852)' }, 
    { label: '', code: '+379', i18nKey: 'HM (+379)' }, 
    { label: '', code: '+504', i18nKey: 'HN (+504)' }, 
    { label: '', code: '+385', i18nKey: 'HR (+385)' }, 
    { label: '', code: '+509', i18nKey: 'HT (+509)' }, 
    { label: '', code: '+36', i18nKey: 'HU (+36)' }, 
    { label: '', code: '+62', i18nKey: 'ID (+62)' }, 
    { label: '', code: '+353', i18nKey: 'IE (+353)' }, 
    { label: '', code: '+972', i18nKey: 'IL (+972)' }, 
    { label: '', code: '+44', i18nKey: 'IM (+44)' }, 
    { label: '', code: '+91', i18nKey: 'IN (+91)' }, 
    { label: '', code: '+246', i18nKey: 'IO (+246)' }, 
    { label: '', code: '+964', i18nKey: 'IQ (+964)' }, 
    { label: '', code: '+98', i18nKey: 'IR (+98)' }, 
    { label: '', code: '+354', i18nKey: 'IS (+354)' }, 
    { label: '', code: '+39', i18nKey: 'IT (+39)' }, 
    { label: '', code: '+44', i18nKey: 'JE (+44)' }, 
    { label: '', code: '+1876', i18nKey: 'JM (+1876)' }, 
    { label: '', code: '+962', i18nKey: 'JO (+962)' }, 
    { label: '', code: '+81', i18nKey: 'JP (+81)' }, 
    { label: '', code: '+254', i18nKey: 'KE (+254)' }, 
    { label: '', code: '+996', i18nKey: 'KG (+996)' }, 
    { label: '', code: '+855', i18nKey: 'KH (+855)' }, 
    { label: '', code: '+686', i18nKey: 'KI (+686)' }, 
    { label: '', code: '+269', i18nKey: 'KM (+269)' }, 
    { label: '', code: '+1869', i18nKey: 'KN (+1869)' }, 
    { label: '', code: '+850', i18nKey: 'KP (+850)' }, 
    { label: '', code: '+82', i18nKey: 'KR (+82)' }, 
    { label: '', code: '+965', i18nKey: 'KW (+965)' }, 
    { label: '', code: '+ 345', i18nKey: 'KY (+ 345)' }, 
    { label: '', code: '+77', i18nKey: 'KZ (+77)' }, 
    { label: '', code: '+856', i18nKey: 'LA (+856)' }, 
    { label: '', code: '+961', i18nKey: 'LB (+961)' }, 
    { label: '', code: '+1758', i18nKey: 'LC (+1758)' }, 
    { label: '', code: '+423', i18nKey: 'LI (+423)' }, 
    { label: '', code: '+94', i18nKey: 'LK (+94)' }, 
    { label: '', code: '+231', i18nKey: 'LR (+231)' }, 
    { label: '', code: '+266', i18nKey: 'LS (+266)' }, 
    { label: '', code: '+370', i18nKey: 'LT (+370)' }, 
    { label: '', code: '+371', i18nKey: 'LV (+371)' }, 
    { label: '', code: '+218', i18nKey: 'LY (+218)' }, 
    { label: '', code: '+212', i18nKey: 'MA (+212)' }, 
    { label: '', code: '+377', i18nKey: 'MC (+377)' }, 
    { label: '', code: '+373', i18nKey: 'MD (+373)' }, 
    { label: '', code: '+382', i18nKey: 'ME (+382)' }, 
    { label: '', code: '+590', i18nKey: 'MF (+590)' }, 
    { label: '', code: '+261', i18nKey: 'MG (+261)' }, 
    { label: '', code: '+692', i18nKey: 'MH (+692)' }, 
    { label: '', code: '+389', i18nKey: 'MK (+389)' }, 
    { label: '', code: '+223', i18nKey: 'ML (+223)' }, 
    { label: '', code: '+95', i18nKey: 'MM (+95)' }, 
    { label: '', code: '+976', i18nKey: 'MN (+976)' }, 
    { label: '', code: '+853', i18nKey: 'MO (+853)' }, 
    { label: '', code: '+1670', i18nKey: 'MP (+1670)' }, 
    { label: '', code: '+596', i18nKey: 'MQ (+596)' }, 
    { label: '', code: '+222', i18nKey: 'MR (+222)' }, 
    { label: '', code: '+1664', i18nKey: 'MS (+1664)' }, 
    { label: '', code: '+356', i18nKey: 'MT (+356)' }, 
    { label: '', code: '+230', i18nKey: 'MU (+230)' }, 
    { label: '', code: '+960', i18nKey: 'MV (+960)' }, 
    { label: '', code: '+265', i18nKey: 'MW (+265)' }, 
    { label: '', code: '+52', i18nKey: 'MX (+52)' }, 
    { label: '', code: '+60', i18nKey: 'MY (+60)' }, 
    { label: '', code: '+258', i18nKey: 'MZ (+258)' }, 
    { label: '', code: '+264', i18nKey: 'NA (+264)' }, 
    { label: '', code: '+687', i18nKey: 'NC (+687)' }, 
    { label: '', code: '+227', i18nKey: 'NE (+227)' }, 
    { label: '', code: '+672', i18nKey: 'NF (+672)' }, 
    { label: '', code: '+234', i18nKey: 'NG (+234)' }, 
    { label: '', code: '+505', i18nKey: 'NI (+505)' }, 
    { label: '', code: '+31', i18nKey: 'NL (+31)' }, 
    { label: '', code: '+47', i18nKey: 'NO (+47)' }, 
    { label: '', code: '+977', i18nKey: 'NP (+977)' }, 
    { label: '', code: '+674', i18nKey: 'NR (+674)' }, 
    { label: '', code: '+683', i18nKey: 'NU (+683)' }, 
    { label: '', code: '+64', i18nKey: 'NZ (+64)' }, 
    { label: '', code: '+968', i18nKey: 'OM (+968)' }, 
    { label: '', code: '+507', i18nKey: 'PA (+507)' }, 
    { label: '', code: '+51', i18nKey: 'PE (+51)' }, 
    { label: '', code: '+689', i18nKey: 'PF (+689)' }, 
    { label: '', code: '+675', i18nKey: 'PG (+675)' }, 
    { label: '', code: '+63', i18nKey: 'PH (+63)' }, 
    { label: '', code: '+92', i18nKey: 'PK (+92)' }, 
    { label: '', code: '+48', i18nKey: 'PL (+48)' }, 
    { label: '', code: '+508', i18nKey: 'PM (+508)' }, 
    { label: '', code: '+872', i18nKey: 'PN (+872)' }, 
    { label: '', code: '+1939', i18nKey: 'PR (+1939)' }, 
    { label: '', code: '+970', i18nKey: 'PS (+970)' }, 
    { label: '', code: '+351', i18nKey: 'PT (+351)' }, 
    { label: '', code: '+680', i18nKey: 'PW (+680)' }, 
    { label: '', code: '+595', i18nKey: 'PY (+595)' }, 
    { label: '', code: '+974', i18nKey: 'QA (+974)' }, 
    { label: '', code: '+262', i18nKey: 'RE (+262)' }, 
    { label: '', code: '+40', i18nKey: 'RO (+40)' }, 
    { label: '', code: '+381', i18nKey: 'RS (+381)' }, 
    { label: '', code: '+7', i18nKey: 'RU (+7)' }, 
    { label: '', code: '+250', i18nKey: 'RW (+250)' }, 
    { label: '', code: '+966', i18nKey: 'SA (+966)' }, 
    { label: '', code: '+677', i18nKey: 'SB (+677)' }, 
    { label: '', code: '+248', i18nKey: 'SC (+248)' }, 
    { label: '', code: '+249', i18nKey: 'SD (+249)' }, 
    { label: '', code: '+46', i18nKey: 'SE (+46)' }, 
    { label: '', code: '+65', i18nKey: 'SG (+65)' }, 
    { label: '', code: '+290', i18nKey: 'SH (+290)' }, 
    { label: '', code: '+386', i18nKey: 'SI (+386)' }, 
    { label: '', code: '+47', i18nKey: 'SJ (+47)' }, 
    { label: '', code: '+421', i18nKey: 'SK (+421)' }, 
    { label: '', code: '+232', i18nKey: 'SL (+232)' }, 
    { label: '', code: '+378', i18nKey: 'SM (+378)' }, 
    { label: '', code: '+221', i18nKey: 'SN (+221)' }, 
    { label: '', code: '+252', i18nKey: 'SO (+252)' }, 
    { label: '', code: '+597', i18nKey: 'SR (+597)' }, 
    { label: '', code: '+211', i18nKey: 'SS (+211)' }, 
    { label: '', code: '+239', i18nKey: 'ST (+239)' }, 
    { label: '', code: '+503', i18nKey: 'SV (+503)' }, 
    { label: '', code: '+590', i18nKey: 'SX (+590)' }, 
    { label: '', code: '+963', i18nKey: 'SY (+963)' }, 
    { label: '', code: '+268', i18nKey: 'SZ (+268)' }, 
    { label: '', code: '+1649', i18nKey: 'TC (+1649)' }, 
    { label: '', code: '+235', i18nKey: 'TD (+235)' }, 
    { label: '', code: '+262', i18nKey: 'TF (+262)' }, 
    { label: '', code: '+228', i18nKey: 'TG (+228)' }, 
    { label: '', code: '+66', i18nKey: 'TH (+66)' }, 
    { label: '', code: '+992', i18nKey: 'TJ (+992)' }, 
    { label: '', code: '+690', i18nKey: 'TK (+690)' }, 
    { label: '', code: '+670', i18nKey: 'TL (+670)' }, 
    { label: '', code: '+993', i18nKey: 'TM (+993)' }, 
    { label: '', code: '+216', i18nKey: 'TN (+216)' }, 
    { label: '', code: '+676', i18nKey: 'TO (+676)' }, 
    { label: '', code: '+90', i18nKey: 'TR (+90)' }, 
    { label: '', code: '+1868', i18nKey: 'TT (+1868)' }, 
    { label: '', code: '+688', i18nKey: 'TV (+688)' }, 
    { label: '', code: '+886', i18nKey: 'TW (+886)' }, 
    { label: '', code: '+255', i18nKey: 'TZ (+255)' }, 
    { label: '', code: '+380', i18nKey: 'UA (+380)' }, 
    { label: '', code: '+256', i18nKey: 'UG (+256)' }, 
    { label: '', code: '+1', i18nKey: 'UM (+1)' }, 
    { label: '', code: '+1', i18nKey: 'US (+1)' }, 
    { label: '', code: '+598', i18nKey: 'UY (+598)' }, 
    { label: '', code: '+998', i18nKey: 'UZ (+998)' }, 
    { label: '', code: '+379', i18nKey: 'VA (+379)' }, 
    { label: '', code: '+1784', i18nKey: 'VC (+1784)' }, 
    { label: '', code: '+58', i18nKey: 'VE (+58)' }, 
    { label: '', code: '+1284', i18nKey: 'VG (+1284)' }, 
    { label: '', code: '+1340', i18nKey: 'VI (+1340)' }, 
    { label: '', code: '+84', i18nKey: 'VN (+84)' }, 
    { label: '', code: '+678', i18nKey: 'VU (+678)' }, 
    { label: '', code: '+681', i18nKey: 'WF (+681)' }, 
    { label: '', code: '+685', i18nKey: 'WS (+685)' }, 
    { label: '', code: '+383', i18nKey: 'XK (+383)' }, 
    { label: '', code: '+967', i18nKey: 'YE (+967)' }, 
    { label: '', code: '+262', i18nKey: 'YT (+262)' }, 
    { label: '', code: '+27', i18nKey: 'ZA (+27)' }, 
    { label: '', code: '+260', i18nKey: 'ZM (+260)' }, 
    { label: '', code: '+263', i18nKey: 'ZW (+263)' }
]

export default countryDialingCodes
