<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'about-creditreform' }">
                    {{ $t('menu.servicemenu.about.title') }}
                </router-link>
            </li>
            <li>{{ $t('OurMemberships.breadcrumbTitle') }}</li>
        </Breadcrumb>

        <div class="container mt-4">
            <div class="row">
                <div class="col-12">
                    <h3>{{ $t('OurMemberships.title') }}</h3>
                </div>
                <div class="col-12">
                    <Accordion>
                        <AccordionItem
                            v-for="index in itemCount"
                            :key="index"
                            :item-key="index"
                            :title="$t(`OurMemberships.item${index}.title`)"
                        >
                            <p class="mb-small">
                                {{ $t(`OurMemberships.item${index}.text`) }}
                            </p>
                            <a 
                                :href="$t(`OurMemberships.item${index}.linkUrl`)"
                                target="_blank"
                                rel="noopener"
                            >
                                {{ $t(`OurMemberships.item${index}.title`) }}
                            </a>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Accordion from '@/components/Accordion/Accordion.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'

const i18n = useI18n()
const itemCount = computed(() => {
    return i18n.locale.value.toLowerCase() === 'de-at' ? 14 : 25
})

const id = 'portal.mc.about-creditreform.our-memberships'
</script>

<style scoped>

</style>
