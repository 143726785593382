<template>
    <div 
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>{{ $t('loginError.breadcrumb') }}</li>
        </Breadcrumb>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3>
                        <span>{{ $t('loginError.title') }}</span>
                    </h3>
                    <img
                        alt="Sorry"
                        src="@/assets/img/hund_bearbeitet.png"
                        class="mb"
                    >
                    <!-- eslint-disable vue/no-v-html -->
                    <p 
                        class="mb" 
                        v-html="$t('loginError.messageHTML')" 
                    />
                    <a
                        href="/"
                        class="btn btn-default"
                    >
                        {{ $t('loginError.buttonText') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Breadcrumb from '@/components/Breadcrumb.vue'

const id = 'portal.mc.login-error'
</script>
