import { createCbraFamilyTreeUrl } from '@/services'
import { showError } from './error'
import { isAxiosError } from 'axios'

const TOPIC = 'FamilyTreeService'

export namespace FamilyTreeService {
    export async function getFamilyTreeUrl(data: unknown) {
        try {
            return await createCbraFamilyTreeUrl(data)
        } catch (error) {
            if (isAxiosError(error)) {
                return showError({ topic: TOPIC, xhr: error })
            }
        }
    }
}