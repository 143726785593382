<template>
    <div class="hero">
        <div 
            class="hero__image"
            :style="`background-image: url(${imageUrl()})`"
        />
        <div 
            class="hero__image mobile"
            :style="`background-image: url(${mobileImageUrl()})`"
        />

        <div class="container hero__container">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    image: {
        type: String,
        required: true,
        default: ''
    },
    mobileImage: {
        type: String,
        default: ''
    }
})

function imageUrl() {
    const relativePath = props.image.replace(/^@\/assets\//, '../assets/')
    return new URL(relativePath, import.meta.url).href
}

function mobileImageUrl() {
    const image = props.mobileImage === '' ? props.image : props.mobileImage
    const relativePath = image.replace(/^@\/assets\//, '../assets/')
    return new URL(relativePath, import.meta.url).href
}
</script>

<style scoped lang="less">
@break-mobile: ~"screen and (max-width: 767px)";

.hero {
    position: relative;
    background: var(--color-c-lightgrey);

    &__image {
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        width: 100%;
        height: 310px;

        @media @break-mobile {
            height: 200px;
            display: none;
        }

        &.mobile {
            display: none;

            @media @break-mobile {
                display: block;
            }
        }
    }

    &__container {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        @media @break-mobile {
            position: relative;
            display: block;
        }
    }
}
</style>

<style scoped lang="less">
@break-mobile: ~"screen and (max-width: 767px)";
.hero {
    :deep(h1) {
        margin-top: 32px;
        margin-bottom: 48px;

        @media @break-mobile {
            margin-top: 20px;
            margin-bottom: 28px;
        }
    }
}
</style>
