<!-- eslint-disable vue/no-v-html -->
<template>
    <h3 class="m0 text-white mb-small">
        {{ $t('report.detail.quickbuyarea.title') }}
    </h3>
    <div
        class="quickbuy-form"
        :class="{'has-errors': !isValid }"
    >
        <div class="row mb-small">
            <div class="col-12 col-sm-4 d-flex align-items-center">
                <p class="text-white">
                    {{ $t('report.detail.legitimateInterest.label') }} *
                </p>
            </div>
            <div class="col-12 col-sm-8">
                <Select
                    id="legitimateInterest"
                    v-model="legitimateInterest"
                    class="m0"
                    name="legitimateInterest"
                    :options="legitimateInterestOptions"
                    :data-qa="isDisabled ? 'productQuickBox-selectLegitimateInterest-disabled' : 'productQuickBox-selectLegitimateInterest'"
                    :searchable="false"
                    required
                    :disabled="isDisabled"
                />
            </div>
        </div>

        <div class="row mb-small">
            <div class="col-12 col-sm-4 d-flex align-items-center">
                <p class="text-white">
                    {{ $t('shared.product') }} *
                </p>
            </div>
            <div class="col-12 col-sm-8">
                <Select
                    id="productType"
                    v-model="productType"
                    class="m0"
                    name="productType"
                    :options="productOptions"
                    :data-qa="isDisabled ? 'productQuickBox-selectProduct-disabled' : 'productQuickBox-selectProduct'"
                    :searchable="false"
                    required
                    :disabled="isDisabled"
                />
            </div>
        </div>

        <div class="row mb-small">
            <div class="col-12 col-sm-4 d-flex align-items-center">
                <p class="text-white">
                    {{ $t('shared.reference') }}
                </p>
            </div>
            <div class="col-12 col-sm-8">
                <InputText
                    v-model="referenceNumber"
                    name="referenceNumber"
                    :data-qa="isDisabled ? 'productQuickBox-insertReferenceNumber-disabled' : 'productQuickBox-insertReferenceNumber'"
                    :placeholder="$t('report.detail.myReference.placeholder')"
                    :disabled="isDisabled"
                    class="m0"
                />
            </div>
        </div>

        <div class="row mb-small">
            <div class="col-12 col-sm-4 d-flex align-items-center">
                <p class="text-white">
                    {{ $t('report.detail.reportLanguage.quickbuy.label') }}
                </p>
            </div>
            <div class="col-12 col-sm-8">
                <Select
                    id="reportLanguage"
                    v-model="reportLanguage"
                    name="reportLanguage"
                    class="m0"
                    :options="reportLanguageOptions"
                    :data-qa="isDisabled ? 'productQuickBox-selectLanguage-disabled' : 'productQuickBox-selectLanguage'"
                    :searchable="false"
                    :disabled="isDisabled"
                />
            </div>
        </div>

        <div
            v-if="product && !product.isSignalProduct"
            class="row mb-small"
        >
            <div class="col-12 col-sm-4 d-flex align-items-center">
                <p class="text-white">
                    {{ $t('report.detail.monitoring.label') }}
                    {{ product.hasMonitoringRequired ? '*' : '' }}
                    <Tooltip
                        v-if="product.hasMonitoring"
                        position="right"
                        :wide="true"
                    >
                        <font-awesome-icon
                            icon="fa-regular fa-circle-info"
                        />
                        <template #content>
                            <p
                                v-if="isDal"
                                v-html="$t('report.addMonitoringToOrder.tooltip')"
                            />
                            <p
                                v-if="!isDal"
                                v-html="$t('report.addMonitoringToOrderNonDal.tooltip')"
                            />
                        </template>
                    </Tooltip>
                </p>
            </div>
            <div class="col-12 col-sm-8">
                <div class="row">
                    <div class="col-6">
                        <Checkbox
                            id="monitoring"
                            v-model="monitoring"
                            name="monitoring"
                            :label="$t('report.detail.addMonitoringToOrder.QuickBuy')"
                            :data-qa="isDisabled ? 'productQuickBox-checkbox-monitoring-disabled' : 'productQuickBox-checkbox-monitoring'"
                            :disabled="monitoringDisabled"
                            @change="setMonitoring(product)"
                        />
                    </div>
                    <div class="col-6">
                        <Checkbox
                            v-if="product.hasMonitoring"
                            id="monitoringLimited"
                            v-model="monitoringLimited"
                            name="monitoringLimited"
                            :label="$t('report.detail.addMonitoringLimitedToOrder.QuickBuy')"
                            :data-qa="isDisabled ? 'productQuickBox-checkbox-monitoring-limited-disabled' : 'productQuickBox-checkbox-monitoring-limited'"
                            :disabled="monitoringLimitedDisabled"
                            @change="setMonitoringLimited"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="monitoringLimited && product?.hasMonitoring"
            class="row mb-small"
        >
            <div class="col-12 col-sm-4" />
            <div class="col-12 col-sm-8">
                <div class="row">
                    <div class="col-7">
                        <Select
                            id="befristungEndeMonat"
                            v-model="befristungEndeMonat"
                            name="befristungEndeMonat"
                            :label="''"
                            :options="monitoringLimitedMonthOptions"
                            :data-qa="isDisabled ? 'productQuickBox-select-befristungEndeMonat-disabled' : 'productQuickBox-select-befristungEndeMonat'"
                            :searchable="false"
                            :disabled="isDisabled"
                        />
                    </div>
                    <div class="col-5">
                        <Select
                            id="befristungEndeJahr"
                            v-model="befristungEndeJahr"
                            name="befristungEndeJahr"
                            :label="''"
                            :options="monitoringLimitedYearOptions"
                            :data-qa="isDisabled ? 'productQuickBox-select-befristungEndeJahr-disabled' : 'productQuickBox-select-befristungEndeJahr'"
                            :searchable="false"
                            :disabled="isDisabled"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="monitoringPlusVisible"
            class="row mb-small"
        >
            <div class="col-4" />
            <div class="col-8">
                <Checkbox
                    id="monitoringPlus"
                    v-model="monitoringPlus"
                    name="monitoringPlus"
                    :label="$t('report.detail.addMonitoringPlusToOrder.QuickBuy')"
                    :data-qa="isDisabled ? 'productQuickBox-checkbox-monitoring-plus-disabled' : 'productQuickBox-checkbox-monitoring-plus'"
                    :is-disabled="isDisabled"
                />
            </div>
        </div>

        <div
            v-if="product?.isSignalProduct"
            class="row mb-small"
        >
            <div class="col-4 d-flex align-items-center">
                <p class="text-white">
                    {{ $t('report.detail.signal_monitoring.label') }}
                </p>
            </div>
            <div class="col-8">
                <Checkbox
                    id="watchlist"
                    v-model="watchlist"
                    name="watchlist"
                    :label="$t('report.detail.addSignalToOrder')"
                    :data-qa="isDisabled ? 'productQuickBox-checkbox-signal-monitoring-disabled' : 'productQuickBox-checkbox-signal-monitoring'"
                    :disabled="isDisabled || !watchlistSupported"
                />
            </div>
        </div>

        <div class="row mb-small">
            <div class="col-4" />
            <div class="col-8">
                <div v-if="product && !product.prohibited && showAcquireButton">
                    <button
                        class="btn btn-legacy-orange btn-default btn-block"
                        :disabled="buttonDisabled"
                        :data-qa="buttonDataQa"
                        @click="product.hasToBeOrdered ? orderProduct(product, hasBusinessData, relatedTransactions) : acquireProduct(product, hasBusinessData, relatedTransactions)"
                    >
                        {{ buttonText }}
                    </button>
                </div>

                <div
                    v-if="bought && !showAcquireButton"
                    class="business-data__bought-message"
                    data-qa="productQuickBox-bought-message"
                >
                    {{ t("report.detail.productBoughtMessage") }}
                </div>

                <div v-if="product && product.prohibited">
                    <Tooltip>
                        <button
                            :disabled="!isDisabled"
                            data-qa="productQuickBox-prohibitedButton"
                            class="btn btn-default btn-block"
                        >
                            {{ $t('report.detail.buyReportNow.short') }}
                        </button>
                        <template #content>
                            <span>{{ $t('reportProducts.permissionError.message') }}</span>
                            <br>
                            <a
                                class="popover__link"
                                href="#"
                                @click="requestPermission"
                            >
                                {{ $t('reportProducts.permissionError.linkText') }}
                            </a>
                        </template>
                    </Tooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PropType, computed, watch } from 'vue'
import Select from '@/components/Input/Select.vue'
import { requestCbraPermission } from '@/services'
import InputText from '../Input/InputText.vue'
import Checkbox from '../Input/Checkbox.vue'
import { useProductStore } from '@/stores/product'
import { CbraProductAvailablityResponse } from '@/types/cbra/productAvailability'
import { useI18n } from 'vue-i18n'
import Tooltip from '../Tooltip.vue'
import { CbraTransaction } from '@/types/cbra/transactions'
import { storeToRefs } from 'pinia'
import { useProduct } from './useProduct'
import { SelectOption } from '@/types/components/select'
import { object, string } from 'zod'
import { useForm, useIsFormValid } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { REQUIRED_STRING_VALIDATION } from '@/helper/zodSchema'

const props = defineProps({
    businessData: {
        type: Object as PropType<CbraProductAvailablityResponse>,
        required: true,
    },
    hasBusinessData: {
        type: Boolean,
        required: true,
    },
    showDalProducts: {
        type: Boolean,
        required: true,
    },
    relatedTransactions: {
        type: Array as PropType<Array<CbraTransaction>>,
        required: true,
    },
    isDal: {
        type: Boolean,
        required: true,
    }
})

const { t } = useI18n()

const { bought, businessId, referenceNumber, showAcquireButton ,actionInProgress, acquireProduct, orderProduct, watchlist, monitoringLimitedYearOptions, reportLanguageOptions, monitoringLimitedMonthOptions, legitimateInterestOptions, reportLanguage, legitimateInterest, monitoringLimited, monitoring, monitoringPlus, isDisabled, setMonitoringLimited, befristungEndeJahr, befristungEndeMonat , setMonitoring } = useProduct(props.isDal)

const productStore = useProductStore()
const { productType, product, legitimateInterestShared, modal, watchlistSupported } = storeToRefs(productStore)

watch(productType, () => {
    monitoringLimited.value = false
    monitoring.value = false
    monitoringPlus.value = false
    watchlist.value = false
})

watch(legitimateInterest, (newValue) => {
    if (newValue) {
        legitimateInterestShared.value = newValue
    } else {
        legitimateInterestShared.value = ''
    }
})

watch(product, (newValue) => {
    if (newValue?.hasMonitoringRequired) {
        monitoringLimited.value = false
        monitoring.value = true
    }
})

watch(watchlistSupported, (newValue) => {
    if (!newValue) {
        watchlist.value = false
    }
})

const productOptions = computed(() => {
    return productStore.transformedProducts.map((product) => {
        const option: SelectOption = {
            i18nKey: product.title,
            code: product.type,
            label: product.title,
        }
        return option
    })
})

const buttonText = computed(()=> {
    return product.value && !product.value.hasToBeOrdered ? t('report.detail.buyReportNow.short') : t('report.detail.orderReportNow.short')
})

const buttonDisabled = computed(() => {
    return !isValid.value || actionInProgress.value || isDisabled.value
})

const buttonDataQa = computed(() => {
    return 'productQuickBox-' + (!product.value?.hasToBeOrdered ? 'buyButton' : 'orderButton') + (isDisabled.value ? '-disabled' : '')
})

const monitoringDisabled = computed(() => {
    return isDisabled.value || monitoringIsDisabled.value || (product.value?.hasMonitoringRequired && monitoring.value)
})

const monitoringLimitedDisabled = computed(() => {
    return isDisabled.value || monitoringIsDisabled.value || (product.value?.hasMonitoringRequired && monitoringLimited.value)
})

const monitoringIsDisabled = computed(() => {
    if (isDisabled.value) { return false }

    if (product.value?.hasMonitoring) {
        if (monitoring.value && !monitoringPlus.value || !monitoring.value && !monitoringPlus.value) {
            return false
        }
    }
    return true
})

const monitoringPlusVisible = computed(() => {
    return (product.value && !product.value.isSignalProduct && monitoring.value && product.value?.hasMonitoringPlus) || (product.value && !product.value.isSignalProduct && monitoringLimited.value && product.value?.hasMonitoringPlus)
})

async function requestPermission() {
    if (!product.value || !businessId) return
    try {
        await requestCbraPermission({ businessId: businessId, productType: product.value.type })

        modal.value = {
            id: 'requestPermission',
            title: t('report.detail.requestPermission.purchase.info.title'),
            content: t('report.detail.requestPermission.purchase.info.description'),
            confirmText: t('shared.modals.ok'),
            icon: 'info',
            onClose: () => modal.value = undefined,
            onConfirm: () => modal.value = undefined
        }
    } catch (error) {
        modal.value = {
            id: 'requestPermissionError',
            title: t('report.detail.requestPermission.purchase.error.title'),
            content: t('report.detail.requestPermission.purchase.error.description'),
            confirmText: t('shared.modals.ok'),
            icon: 'error',
            onClose: () => modal.value = undefined,
            onConfirm: () => modal.value = undefined
        }
    }
}

const formValidationSchema = toTypedSchema(
    object({
        legitimateInterest: REQUIRED_STRING_VALIDATION(t),
        reportLanguage: REQUIRED_STRING_VALIDATION(t),
        referenceNumber: string().max(32, { message: t('errors.maxLength', { max: 32 }) }),
    }),
)

useForm({ 
    validationSchema: formValidationSchema 
})

const isValid = useIsFormValid()

</script>

<style scoped lang="less">
.quickbuy-form {
    :deep(.input-label) {
        color: var(--color-c-white) !important
    }

    :deep(.input-label span) {
        color: var(--color-c-white) !important
    }
}

.business-data__bought-message {
    color: #f8b820;
}

</style>
