<template>
    <NotAuthorized
        v-if="!isAuthorized"
        data-qa="widget-favouriteCollection-note-not-authorized"
    />
    <ListWidget
        v-else
        :results="results"
        :widget="props.widget"
        :is-loading="isLoading"
        :sorting-type="search.sort"
        :header-name="$t('dashboard.widget.watchlist.list.header.name')"
        :header-changes="$t('dashboard.widget.watchlist.list.header.changes')"
        :page-size="search.pageSize"
        :page-start-index="search.pageStartIndex"
        :list-size="listSize"
        @on-sort="onSort"
        @on-previous-page="onPreviousPage"
        @on-next-page="onNextPage"
    /> 
</template>

<script setup lang="ts">
import { onMounted, PropType, reactive, ref } from 'vue'
import { EnhancedResults, Widget } from '@/types/widget'
import { getCbraTransactions } from '@/services'
import { hasUserRole, ROLES } from '@/helper/services/user'
import { CONSTS } from '@/helper/services/transaction'
import axios from 'axios'
import { CbraCollectionTransaction, CbraTransaction } from '@/types/cbra/transactions'
import { formatDate } from '@/filters'
import { useI18n } from 'vue-i18n'
import ListWidget from './ListWidget.vue'
import { getDebtorName } from '@/helper/widget'
import NotAuthorized from '../NotAuthorized.vue'

const props = defineProps({
    widget: {
        type: Object as PropType<Widget>,
        required: true
    },
})

const results = ref<EnhancedResults[]>([])
const listSize = ref(0)
const isLoading = ref(false)
const isAuthorized = ref(true)

const search = reactive({
    pageSize: 3,
    pageStartIndex: 0,
    sort: CONSTS.SORT.MODIFICATION_TIME_DESC,
    transactionType: 'COLLECTION',
    favourites: true
})

const { t } = useI18n()

onMounted(async() => {
    if (!hasUserRole(ROLES.INKASSO)) {
        isAuthorized.value = false
    } else {
        await handleFetch()
    }
})

async function onSort() {
    if (search.sort === CONSTS.SORT.MODIFICATION_TIME_ASC) {
        search.sort = CONSTS.SORT.MODIFICATION_TIME_DESC
    } else {
        search.sort = CONSTS.SORT.MODIFICATION_TIME_ASC
    }
    await handleFetch()
}

async function handleFetch() {
    isLoading.value = true
    try {
        const response = await getCbraTransactions(search)
        listSize.value = response.data.sizeTransactions
        isLoading.value = false
        results.value = response.data.transactions.map(enhanceItem)
    } catch (error) {
        console.error(error)
        isLoading.value = false
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            isAuthorized.value = false
        }
    }
}

function generateLinkUrl(transactionId: string) {
    return `${props.widget.link?.url}?transactionId=${transactionId}`
}

function enhanceItem(transaction: CbraTransaction) {
    if (!transaction.collectionTransaction) return {} as EnhancedResults
    return {
        name: getDebtorName(transaction.collectionTransaction),
        changeReason: findAndTranslateChangeReason(transaction.collectionTransaction),
        date: formatDate(transaction.collectionTransaction.lastTimelineEntryDate),
        transactionId: transaction.collectionTransaction.id,
        link: generateLinkUrl(transaction.collectionTransaction.id)
    }
}

function findAndTranslateChangeReason(transaction: CbraCollectionTransaction) {
    if (transaction.lastTimelineEntryType) {
        return t(`transaction.collection.timeline.type.${transaction.lastTimelineEntryType}`)
    }
    return ''
}

async function onPreviousPage() {
    if (isLoading.value) return
    search.pageStartIndex--
    await handleFetch()
}

async function onNextPage() {
    if (isLoading.value) return
    search.pageStartIndex++
    await handleFetch()
}

</script>