<template>
    <div>
        <button 
            v-if="!showForm"
            class="btn btn-small btn-secondary"
            :disabled="submitting"
            data-qa="selfcare-userdata-mailSurrogates-add"
            @click.prevent="showForm = true"
        >
            {{ $t('profile.settings.surrogatesForm.btns.addSurrogate') }}
        </button>

        <form 
            v-else 
            @submit="onSubmit"
        >
            <div class="row mb-small">
                <div class="col-12">
                    <Select
                        id="surrogate-select"
                        v-model="selectedSurrogate.surrogateCrid"
                        :label="$t('profile.settings.surrogatesForm.fields.surrogate.label')"
                        :options="availableSurrogatesOptions"
                        :searchable="false"
                        :disabled="submitting"
                        :required="true"
                        :data-qa="`selfcare-userdata-mailSurrogates-availableSurrogates-${mailSurrogates.length+1}`"
                    />
                </div>
                <div class="col-12 col-md-6 mb-small">
                    <Datepicker
                        id="surrogate-valid-from"
                        v-model="selectedSurrogate.validFrom"
                        name="surrogate-valid-from"
                        :label="$t('profile.settings.surrogatesForm.fields.validFrom.label')"
                        :disabled="submitting"
                        :data-qa="`selfcare-userdata-mailSurrogates-validFrom-${mailSurrogates.length+1}`"
                        required
                    />
                </div>
                <div class="col-12 col-md-6">
                    <Datepicker
                        id="surrogate-valid-to"
                        v-model="selectedSurrogate.validTo"
                        name="surrogate-valid-to"
                        :label="$t('profile.settings.surrogatesForm.fields.validTo.label')"
                        :disabled="submitting"
                        :data-qa="`selfcare-userdata-mailSurrogates-validTo-${mailSurrogates.length + 1}`"
                    />
                </div>
            </div>
            <div class="row mb">
                <div class="col-12">
                    {{ $t('profile.settings.surrogatesForm.activeLabel') }}: 
                    <span 
                        :data-qa="`selfcare-userdata-mailSurrogates-active-${mailSurrogates.length}-no`"
                    >{{ $t('globalMisc.no') }}</span>
                </div>
            </div> 
            <div class="row">
                <div class="col-12 col-md-2">
                    <button 
                        class="btn btn-small btn-min btn-secondary"
                        :data-qa="`selfcare-userdata-mailSurrogates-delete-${mailSurrogates.length}`"
                        @click.prevent="reset()"
                    >
                        {{ $t('profile.settings.surrogatesForm.btns.remove') }}
                    </button>
                </div>
                <div class="col-12 col-md-3 offset-md-7 text-align-right-md">
                    <button 
                        class="btn btn-default btn-small btn-min"
                        :disabled="submitting"
                        :data-qa="`selfcare-userdata-mailSurrogates-save-${mailSurrogates.length}`"
                    >
                        {{ $t('profile.settings.surrogatesForm.btns.save') }}
                    </button>
                </div>
            </div> 
        </form>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'

import Datepicker from '@/components/Input/DatePicker.vue'
import Select from '@/components/Input/Select.vue'

import type { CbraAvailableSurrogate } from '@/types/cbra/surrogates'

import { useSurrogatesStore } from '@/stores/surrogates'
import { toTypedSchema } from '@vee-validate/zod'
import { date, object } from 'zod'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const cbraSurrogatesStore = useSurrogatesStore()
const { 
    error,
    mailSurrogates,
    availableSurrogates
} = storeToRefs(cbraSurrogatesStore)

let showForm = ref(false)
let submitting = ref(false)

let selectedSurrogate = ref({
    surrogateCrid: availableSurrogates.value[0].crId,
    validFrom: null,
    validTo: null
})

const formValidationSchema = computed(() => toTypedSchema(
    object({
        ['surrogate-valid-from']: date({ required_error: t('errors.required'), invalid_type_error: t('errors.required') }),
        ['surrogate-valid-to']: date({ required_error: t('errors.required'), invalid_type_error: t('error.form.pattern') }).min(selectedSurrogate.value.validFrom ? dayjs(selectedSurrogate.value.validFrom).toDate() : dayjs().toDate(), t('selfcare.show.section.nutzerdaten.urlaubsvertretung.endDateBeforeStartDate')).nullable(),
    })
))

const {
    handleSubmit, 
    resetForm,
    validate 
} = useForm({ 
    validationSchema: formValidationSchema })

const availableSurrogatesOptions = computed(() => {
    return availableSurrogates.value.map((surrogate: CbraAvailableSurrogate) => {
        return {
            label: `${surrogate.name} (${surrogate.email} | ${surrogate.memberUserId})`,
            code: surrogate.crId
        }
    })
})

const reset = () => {
    showForm.value = false
    selectedSurrogate.value = {
        surrogateCrid: availableSurrogates.value[0].crId,
        validFrom: null,
        validTo: null
    }
    resetForm()
}

const onSubmit = handleSubmit(async() => {
    submitting.value = true

    const valid = await validate()
    if (!valid.valid) {
        return
    }

    await cbraSurrogatesStore.addMailSurrogate(selectedSurrogate.value)    
    submitting.value = false

    if (error.value !== '') {
        console.log('error on save')
    } else {
        reset()
    }
})

watch(selectedSurrogate.value, (value) => {
    if (value) {
        validate()
    }
})
</script>
