<script setup lang="ts">
import type { PropType } from 'vue'
import { computed } from 'vue'
import type { WatchlistEntry } from '@/types/cbra/watchlist'
import { WatchlistStates } from '@/types/cbra/watchlist'
import countries from '@/data/countries'
import { useI18n } from 'vue-i18n'
import MonitorText from '@/components/Watchlist/MonitorText.vue'
import { getStatus } from '@/helper/reportTracking'
import { placeNewWkvOfferOpenAction } from '@/helper/webtrekk/watchlist'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Tooltip from '@/components/Tooltip.vue'
import { accessToFeature } from '@/helper/services/features'
import { useWatchlistStore } from '@/stores/watchlist'
import CONSTANTS from '@/data/constants'
import { udsLinks, adsLinks } from '@/data/typo3Links'
import Storage from '@/helper/storage'

type MonitorProps = {
    element: string
    text: string
    dataQa?: string
    classes?: string
    child?: MonitorProps
    Ret?: MonitorProps
}

const props = defineProps({
    item: {
        type: Object as PropType<WatchlistEntry>,
        required: true
    }
})

const watchlistStore = useWatchlistStore()
const { t, locale } = useI18n()
const wkvRuVActive = accessToFeature('wkvRuVActive')

const country = computed(() => {
    const key = countries.find((c) => c.code === props.item.countryCode)?.i18nKey
    if (key) {
        return t(key)
    }
    return ''
})
const lastReasonType = computed(() => {
    if (props.item.lastSignalReasonIdList && props.item.lastSignalReasonIdList?.length > 0 && (props.item?.lastSupplementReasonIdList?.includes('A900') || props.item.lastSignalReasonIdList && props.item.lastSignalReasonIdList?.length > 0 && !props.item.lastSupplementReasonIdList)) {
        return 'lastSignalReason'
    }
    return 'lastSupplementReason'
})
const lastSupplementReason = computed(() => {
    let reason = ''
    if (props.item.lastSupplementReasonIdList && props.item.lastSupplementReasonIdList.length > 0 &&props.item.lastSupplementReasonIdList.includes('A901')) {
        reason = t('watchlist.list.supplementReason.A901')
    } else {
        if (props.item.lastSignalReasonIdList && props.item.lastSignalReasonIdList.length > 0 && props.item.lastSupplementReasonIdList?.includes('A900') || props.item.lastSignalReasonIdList && props.item.lastSignalReasonIdList.length > 0 && !props.item.lastSupplementReasonIdList) {
            reason = t(`watchlist.list.signalReason.${props.item.lastSignalReasonIdList[0]}`)
        } else {
            if (props.item.lastSupplementReasonIdList && props.item.lastSupplementReasonIdList.length > 0) {
                reason = t(`watchlist.list.supplementReason.${props.item.lastSupplementReasonIdList[0]}`)
            }
        }
    }
    return reason
})
const observationType = computed(() => {
    if (lastReasonType.value === 'lastSignalReason') {
        return t('watchlist.list.purchased_signal')
    }
    return t('watchlist.list.purchased_report')
})
const lastEventType = computed(() => {
    if (lastReasonType.value === 'lastSignalReason') {
        return t('watchlist.list.last_signal')
    }
    return t('watchlist.list.last_supplement')
})
const observationStateText = computed(() => {
    let key = `watchlist.listitem.observationStateEnum.${props.item.observationStateEnum}`
    if (props.item.observationStateEnum === WatchlistStates.MONITORING_ACTIVE || props.item.observationStateEnum === WatchlistStates.SIGNAL_ACTIVE) {
        key += `__WITH${(props.item.monitoringEndDate === null) ? 'OUT' : ''}_END_DATE`
    }
    return t(key)
})
const monitoringTextProps = computed(() => {
    if (!props.item) {
        return null
    }
    let status = ''
    if (props.item.monitoringEndDate) {
        status = getStatus(props.item.monitoringEndDate)
    }
    switch (props.item.observationStateEnum) {
    case WatchlistStates.SIGNAL:
        return <MonitorProps>{
            element: 'span',
            text: observationStateText.value,
            dataQa: 'watchlist-observationStatus-signal-unlimited'
        }
    case WatchlistStates.SIGNAL_INACTIVE:
        if (props.item.monitoringEndDate) {
            return <MonitorProps>{
                element: 'span',
                text: observationStateText.value,
                dataQa: 'watchlist-observationStatus-monitoring-inactive',
                child: <MonitorProps>{
                    element: 'strong',
                    text: parseDate(props.item.monitoringEndDate),
                    classes: 'font-color--normal',
                    dataQa: 'watchlist-supplementOrMonitoringEndDate'
                }
            }
        } else {
            return <MonitorProps>{
                element: 'span',
                text: '',
                dataQa: 'watchlist-observationStatus-monitoring-inactive'
            }
        }
    case WatchlistStates.MONITORING_INACTIVE:
        if (props.item.monitoringEndDate) {
            return <MonitorProps>{
                element: 'span',
                text: observationStateText.value,
                dataQa: 'watchlist-observationStatus-monitoring-inactive',
                child: <MonitorProps>{
                    element: 'strong',
                    text: parseDate(props.item.monitoringEndDate),
                    classes: 'font-color--normal',
                    dataQa: 'watchlist-supplementOrMonitoringEndDate'
                }
            }
        } else {
            return <MonitorProps>{
                element: 'span',
                text: '',
                dataQa: 'watchlist-observationStatus-monitoring-inactive'
            }
        }
    case WatchlistStates.SIGNAL_ACTIVE:
        if (props.item.monitoringEndDate) {
            if (status === 'nearlyExpired') {
                return <MonitorProps>{
                    element: 'span',
                    text: observationStateText.value,
                    dataQa: 'watchlist-observationStatus-signal-active--nearly-expired',
                    child: <MonitorProps>{
                        element: 'strong',
                        text: parseDate(props.item.monitoringEndDate),
                        classes: 'font-color--red',
                        dataQa: 'watchlist-supplementOrMonitoringEndDate'
                    }
                }
            } else {
                return <MonitorProps>{
                    element: 'span',
                    text: observationStateText.value,
                    dataQa: 'watchlist-observationStatus-signal-active',
                    child: <MonitorProps>{
                        element: 'strong',
                        text: parseDate(props.item.monitoringEndDate),
                        classes: 'font-color--blue',
                        dataQa: 'watchlist-supplementOrMonitoringEndDate'
                    }
                }
            }
        } else {
            return <MonitorProps>{
                element: 'span',
                text: observationStateText.value,
                dataQa: 'watchlist-observationStatus-signal-unlimited'
            }
        }
    case WatchlistStates.MONITORING_ACTIVE:
        if (props.item.monitoringEndDate) {
            if (status === 'nearlyExpired') {
                return <MonitorProps>{
                    element: 'span',
                    text: observationStateText.value,
                    dataQa: 'watchlist-observationStatus-monitoring-active--nearly-expired',
                    child: <MonitorProps>{
                        element: 'strong',
                        text: parseDate(props.item.monitoringEndDate),
                        classes: 'font-color--red',
                        dataQa: 'watchlist-supplementOrMonitoringEndDate'
                    }
                }
            } else {
                return <MonitorProps>{
                    element: 'span',
                    text: observationStateText.value,
                    dataQa: 'watchlist-observationStatus-monitoring-active',
                    child: <MonitorProps>{
                        element: 'strong',
                        text: parseDate(props.item.monitoringEndDate),
                        classes: 'font-color--blue',
                        dataQa: 'watchlist-supplementOrMonitoringEndDate'
                    }
                }
            }
        } else {
            return <MonitorProps>{
                element: 'span',
                text: observationStateText.value,
                dataQa: 'watchlist-observationStatus-monitoring-unlimited'
            }
        }
    case WatchlistStates.SUPPLEMENT_INACTIVE:
        return <MonitorProps>{
            element: 'span',
            text: observationStateText.value,
            dataQa: 'watchlist-observationStatus-supplement-inactive',
            child: <MonitorProps>{
                element: 'strong',
                text: parseDate(props.item.supplementEndDate),
                classes: 'font-color--normal',
                dataQa: 'watchlist-supplementOrMonitoringEndDate'
            }
        }
    case WatchlistStates.SUPPLEMENT_ACTIVE:
        if (status === 'nearlyExpired') {
            return <MonitorProps>{
                element: 'span',
                text: observationStateText.value,
                dataQa: 'watchlist-observationStatus-supplement-active--nearly-expired',
                child: <MonitorProps>{
                    element: 'strong',
                    text: parseDate(props.item.supplementEndDate),
                    classes: 'font-color--red',
                    dataQa: 'watchlist-supplementOrMonitoringEndDate'
                }
            }
        } else {
            let outProps = <MonitorProps>{
                element: 'span',
                text: observationStateText.value,
                dataQa: 'watchlist-observationStatus-supplement-active',
                child: <MonitorProps>{
                    element: 'strong',
                    text: parseDate(props.item.supplementEndDate),
                    classes: 'font-color--blue',
                    dataQa: 'watchlist-supplementOrMonitoringEndDate'
                }
            }

            if (props.item.monitoring && props.item.monitoringEndDate === null) {
                outProps.Ret = <MonitorProps>{
                    element: 'span',
                    text: t('watchlist.listitem.observationStateEnum.SUPPLEMENT_ACTIVE.monitoring'),
                    dataQa: 'watchlist-observationStatus-supplement-active-add-monitoring'
                }
            } else if (props.item.monitoring && (props.item.monitoringEndDate && props.item.supplementEndDate) && props.item.monitoringEndDate > props.item.supplementEndDate) {
                outProps.Ret = <MonitorProps>{
                    element: 'span',
                    text: t('watchlist.listitem.observationStateEnum.SUPPLEMENT_ACTIVE.monitoring'),
                    dataQa: 'watchlist-observationStatus-supplement-active-add-monitoring'
                }
            }
            return outProps
        }
    }
    return null
})

function parseDate(timestamp: number | null): string {
    if (!timestamp) {
        return ''
    }
    return new Date(timestamp).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })
}

function followWkvUrl() {
    if (!props.item?.wkvLink) {
        return
    }
    placeNewWkvOfferOpenAction(props.item.observationStateEnum || '')
    let appendToUrl = ''
    if (props.item?.wkvLink.indexOf('#') === -1) {
        if (props.item?.wkvLink.indexOf('?') === -1) {
            appendToUrl += '?'
        } else {
            appendToUrl += '&'
        }
        if (typeof props.item.observationStateEnum === 'string' && props.item.observationStateEnum.length) {
            appendToUrl += 'observation=' + encodeURIComponent(props.item.observationStateEnum)
        } else {
            appendToUrl += 'observation=UNDECIDABLE'
        }
    }
    location.assign(`/portal${props.item?.wkvLink}${appendToUrl}`)
}

function followUrl() {
    if (!props.item?.businessId) {
        return
    }
    // save business item in session store
    const business = {
        businessId: props.item.businessId,
        bunsinessName: props.item.businessName,
        postalAddress: {
            city: props.item.city,
            country: props.item.countryCode,
            zipCode: props.item.zipCode,
            formatted: `${props.item.zipCode} ${props.item.city}`
        }
    }
    Storage.set(CONSTANTS.EVENTS.SAVED_BUSINESS_ITEM, business)

    location.assign(`${udsLinks[locale.value]}?businessId=${props.item?.businessId}&country=${props.item?.countryCode}`)
}

async function removeWatchlist() {
    await watchlistStore.removeWatchlistEntry(props.item?.id)
}

</script>

<template>
    <div
        class="box bg-white small-box-spacing"
        data-qa="watchlist-item"
        @click="followUrl()"
    >
        <span
            class="text-brand-blue is-close"
            data-qa="watchlist-delete"
            role="button"
            @click.stop="removeWatchlist()"
        >
            <Tooltip
                position="left"
            >
                <font-awesome-icon icon="fa-light fa-xmark-large" />
                <template #content>
                    <p>{{ $t('search.removeFromWatchlist') }}</p>
                </template>
            </Tooltip>

        </span>
        <div class="row">
            <div class="col-12 col-lg-10 mb-small">
                <div class="row">
                    <div class="col-12 mb-small">
                        <strong
                            data-qa="watchlist-businessName"
                        >
                            {{ item.businessName }}
                        </strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <small v-if="item.transactionId">
                            <strong data-qa="watchlist-item--observationType">{{ observationType }}</strong>
                        </small>
                        <div>
                            <span data-qa="watchlist-zipCode">{{ item.zipCode }}&nbsp;</span>
                            <span data-qa="watchlist-city">{{ item.city }}</span>
                            <span
                                v-if="country"
                                data-qa="watchlist-country"
                            >, {{ country }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div v-if="item.lastEventTimestamp">
                            <span>{{ lastEventType }}&nbsp;</span>
                            <strong data-qa="watchlist-lastEventTimestamp">{{ parseDate(item.lastEventTimestamp) }}</strong>
                        </div>
                        <div
                            data-qa="watchlist-observationStatus"
                        >
                            <MonitorText
                                v-if="monitoringTextProps"
                                :text="monitoringTextProps.text"
                                :element="monitoringTextProps.element"
                                :data-qa="monitoringTextProps.dataQa"
                                :classes="monitoringTextProps.classes"
                            >
                                <MonitorText
                                    v-if="monitoringTextProps.child"
                                    :text="monitoringTextProps.child.text"
                                    :element="monitoringTextProps.child.element"
                                    :data-qa="monitoringTextProps.child.dataQa"
                                    :classes="monitoringTextProps.child.classes"
                                />
                            </MonitorText>
                            <template v-if="monitoringTextProps && monitoringTextProps.Ret">
                                <br>
                                <MonitorText
                                    v-if="monitoringTextProps.Ret"
                                    :text="monitoringTextProps.Ret.text"
                                    :element="monitoringTextProps.Ret.element"
                                    :data-qa="monitoringTextProps.Ret.dataQa"
                                    :classes="monitoringTextProps.Ret.classes"
                                >
                                    <MonitorText
                                        v-if="monitoringTextProps.Ret.child"
                                        :text="monitoringTextProps.Ret.child.text"
                                        :element="monitoringTextProps.Ret.child.element"
                                        :data-qa="monitoringTextProps.Ret.child.dataQa"
                                        :classes="monitoringTextProps.Ret.child.classes"
                                    />
                                </MonitorText>
                            </template>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <span :data-qa="`watchlist-${lastReasonType}`">{{ lastSupplementReason }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div
                class="col-12 text-align-right"
                style="display: flex; align-items: center; justify-content: flex-end"
            >
                <a
                    v-if="item.transactionId"
                    :href="`${adsLinks[$i18n.locale]}?no_cache=1&transactionId=${item.transactionId}`"
                    type="button"
                    class="btn btn-secondary btn-default btn-min btn-small"
                    data-qa="watchlist-goToReport"
                >
                    {{ $t('watchlist.list.go_to_report') }}
                </a>
                <template v-if="wkvRuVActive">
                    <div
                        v-if="item.wkvRuvStatus === 'WKV_RUV_ABGESCHLOSSEN'"
                        class="text-brand-blue"
                        style="display: inline-block; padding: 0 15px;"
                    >
                        <Tooltip
                            position="top"
                        >
                            <strong>
                                <i
                                    class="crefo-brand-icon icon-shield"
                                    aria-hidden="true"
                                />
                                <span data-qa="watchlist-wkv-status">&nbsp;{{ $t('watchlist.list.wkv_status_abgeschlossen.text') }}</span>
                            </strong>
                            <template #content>
                                <p>{{ $t('watchlist.list.wkv_status_abgeschlossen.tooltip_text') }}</p>
                            </template>
                        </Tooltip>
                    </div>
                    <div
                        v-if="item.wkvRuvStatus === 'WKV_RUV_MOMENTAN_NICHT_ABSCHLIESSBAR'"
                        class="text-brand-blue"
                        style="display: inline-block; padding: 0 15px;"
                    >
                        <Tooltip
                            position="top"
                        >
                            <strong>
                                <i
                                    class="crefo-brand-icon icon-lock"
                                    aria-hidden="true"
                                    data-qa="watchlist-icon-wkv-na"
                                />
                                <span data-qa="watchlist-wkv-status">&nbsp;{{ $t('watchlist.list.wkv_status_abgelehnt.text') }}</span>
                            </strong>
                            <template #content>
                                <p>{{ $t('watchlist.list.wkv_status_abgelehnt.tooltip_text') }}</p>
                            </template>
                        </Tooltip>
                    </div>
                    <div
                        v-if="item.wkvLink"
                        class="text-brand-blue"
                        style="display: inline-block; padding: 0 15px;"
                    >
                        <Tooltip
                            position="top"
                        >
                            <button
                                type="button"
                                class="btn btn-secondary btn-default btn-min btn-small"
                                data-qa="watchlist-goToWkv"
                                @click.stop="followWkvUrl()"
                            >
                                {{ $t('watchlist.list.go_to_wkv') }}
                            </button>
                            <template #content>
                                <p v-html="$t('watchlist.list.go_to_wkv.tooltip_text')" />
                            </template>
                        </Tooltip>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
.box {
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
}

.is-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
</style>
