<template>
    <div class="results-list">
        <div
            v-for="(transaction, index) in transactions"
            :id="`transaction-${transaction.crefoTransactionId}`"
            :key="`transaction-${transaction.crefoTransactionId}`"
            class="row"
            :class="{
                'mb-mini': index !== transactionsStore.transactions.length - 1
            }"
            data-qa="myTransactions-searchResultList"
        >
            <div class="col-12">
                <ListItemConsumer 
                    v-if="transaction.transactionType === CONSTANTS.TRANSACTION_TYPES.CONSUMER"
                    :transaction="transaction" 
                />
                <ListItemCollection 
                    v-if="transaction.transactionType === CONSTANTS.TRANSACTION_TYPES.COLLECTION"
                    :transaction="transaction" 
                />
                <ListItemInvolvementdata 
                    v-if="transaction.transactionType === CONSTANTS.TRANSACTION_TYPES.INVOLVEMENTDATA"
                    :transaction="transaction" 
                />
                <ListItemReport 
                    v-if="transaction.transactionType === CONSTANTS.TRANSACTION_TYPES.REPORT"
                    :transaction="transaction" 
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import ListItemConsumer from './ListItemConsumer.vue'
import ListItemCollection from './ListItemCollection.vue'
import ListItemInvolvementdata from './ListItemInvolvementdata.vue'
import ListItemReport from './ListItemReport.vue'

import type { CbraTransaction } from '@/types/cbra/transactions'

import { useTransactionsStore } from '@/stores/transactions'
const transactionsStore = useTransactionsStore()

import CONSTANTS from '@/data/constants'

const transactions = computed<CbraTransaction[]>(() => transactionsStore.transactions)
</script>

<style scoped lang="less">
.mb-mini {
    margin-bottom: 10px;
}
.results-list {
    padding-top: 10px;
}
</style>