<!-- eslint-disable vue/no-v-html -->
<template>
    <div 
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('faq.breadcrumb') }}</li>
        </Breadcrumb>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ReportFAQAccordions />
                </div>
            </div>

            <div class="row content-spacer">
                <div class="col-12">
                    <h2>
                        {{ $t('faq.inkasso.header') }}
                    </h2>
                    <h3>{{ $t('faq.inkasso.title') }}</h3>
                    <Accordion>
                        <AccordionItem
                            :title="$t('faq.inkasso.accordions.1.title')"
                            :item-key="1"
                        >
                            <p v-html="$t('faq.inkasso.accordions.1.text')" />
                        </AccordionItem>               
                        <AccordionItem
                            :title="$t('faq.inkasso.accordions.2.title')"
                            :item-key="2"
                        >
                            <p v-html="$t('faq.inkasso.accordions.2.text')" />
                        </AccordionItem>
                        <AccordionItem
                            :title="$t('faq.inkasso.accordions.3.title')"
                            :item-key="3"
                        >
                            <p v-html="$t('faq.inkasso.accordions.3.text')" />
                        </AccordionItem>
                        <AccordionItem
                            :title="$t('faq.inkasso.accordions.4.title')"
                            :item-key="4"
                        >
                            <p v-html="$t('faq.inkasso.accordions.4.text')" />
                        </AccordionItem>
                        <AccordionItem
                            :title="$t('faq.inkasso.accordions.5.title')"
                            :item-key="5"
                        >
                            <p v-html="$t('faq.inkasso.accordions.5.text')" />
                        </AccordionItem>
                        <AccordionItem
                            :title="$t('faq.inkasso.accordions.6.title')"
                            :item-key="6"
                        >
                            <p v-html="$t('faq.inkasso.accordions.6.text')" />
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>

            <div class="row content-spacer">
                <div class="col-12">
                    <h2>
                        {{ $t('faq.credit.header') }}
                    </h2>
                    <h3>{{ $t('faq.credit.title') }}</h3>
                    <Accordion>
                        <AccordionItem
                            :title="$t('faq.credit.accordions.1.title')"
                            :item-key="1"
                        >
                            <p v-html="$t('faq.credit.accordions.1.text')" />
                        </AccordionItem>               
                        <AccordionItem
                            :title="$t('faq.credit.accordions.2.title')"
                            :item-key="2"
                        >
                            <p v-html="$t('faq.credit.accordions.2.text')" />
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>

            <div
                v-if="locale !== 'en-US'"
                class="row content-spacer"
            >
                <div class="col-12">
                    <h2>
                        {{ $t('faq.myCrefo.header') }}
                    </h2>
                    <h3>{{ $t('faq.myCrefo.title') }}</h3>
                    <Accordion>
                        <AccordionItem
                            :title="$t('faq.myCrefo.accordions.1.title')"
                            :item-key="1"
                        >
                            <p v-html="$t('faq.myCrefo.accordions.1.text')" />
                        </AccordionItem>               
                        <AccordionItem
                            :title="$t('faq.myCrefo.accordions.2.title')"
                            :item-key="2"
                        >
                            <p v-html="$t('faq.myCrefo.accordions.2.text')" />
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Accordion from '@/components/Accordion/Accordion.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'
import { useI18n } from 'vue-i18n'
import ReportFAQAccordions from '@/components/Report/ReportFAQAccordions.vue'

const id = 'portal.mc.faq'

const { locale } = useI18n()

</script>   