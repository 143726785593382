<template>
    <div 
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'report' }">
                    {{ $t('report.breadcrumb') }}
                </router-link>
            </li>
            <li>
                <a :href="reportsLandingpageB2BLinks[$i18n.locale]">
                    {{ $t('reportCompany.breadcrumb') }}
                </a>
            </li>
            <li>{{ $t('detailCompany.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="container ">
            <div class="row ">
                <div class="col-12">
                    <div
                        v-if="navigatingAsDifferentMember"
                        class="crefo-ui-alert info info-box"
                    >
                        <div
                            class="infoBoxMessage__data-info"
                            data-qa="infoBoxMessage-disabled-businessData"
                        >
                            <div
                                class="infoBoxMessage__data-info-headline h5 text-white"
                                data-qa="infoBoxMessage-disabled-businessData-headline"
                            >
                                {{ $t('infoboxmessages.businessData.headline') }}
                            </div>
                            <div
                                class="infoBoxMessage__data-info-item"
                                data-qa="infoBoxMessage-disabled-businessData-text"
                            >
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <span v-html="$t('infoboxmessages.businessData.text') " />
                                {{ " " }}
                                <a
                                    data-qa="infoBoxMessage-disabled-businessData-link"
                                    class="infoBoxMessage__link"
                                    href="javascript: void(0);"
                                    @click="clickInfoBoxMessageLink"
                                >
                                    {{ $t('infoboxmessages.businessData.link') }}
                                </a>.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 data-qa="businessDetails-title">
                        {{ businessName }}
                    </h1>
                    <div
                        v-if="businessAlias"
                        data-qa="businessDetails-subtitle"
                    >
                        {{ businessAlias }}
                    </div>
                </div>
            </div>
        </div>
        
        <LoadingRing v-if="showLoadingSpinner" />

        <div
            v-if="!showLoadingSpinner && !error"
            class="mt"
        >
            <div class="bg-brand-light-grey box-spacing">
                <div class="container">
                    <div
                        v-if="legitimateInterestShared"
                        class="row"
                    >
                        <div class="col-12">
                            <LegitimateNote :legitimate-interest="legitimateInterestShared" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="box-shadow-xy box-spacing bg-white mb box--business-data h-100">
                                <WatchlistIcon
                                    v-if="businessId"
                                    class="text-align--right watchlist-icon"
                                    :business-id="businessId"
                                    :is-dal="isDAL"
                                    :use-store="true"
                                />
                                <div class="row">
                                    <div class="col-12 col-sm-5">
                                        <div
                                            v-if="postalAddress && (postalAddress.street || postalAddress.houseNumber || postalAddress.houseNumberSuffix || postalAddress.zipCode || postalAddress.city)"
                                            data-qa="address"
                                            class="business-data__contact-detail text-primary d-flex"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-location-dot"
                                                class="location-icon"
                                            />
                                            <div class="ml">
                                                <span>{{ postalAddress.street }}</span> <span>{{ postalAddress.houseNumber }}</span><span>{{ postalAddress.houseNumberSuffix }}</span>
                                                <br v-if="postalAddress.street || postalAddress.houseNumber || postalAddress.houseNumberSuffix">
                                                <span>{{ postalAddress.zipCode }}</span> <span>{{ postalAddress.city }}</span>
                                                <br v-if="country">    
                                                <span
                                                    v-if="country"
                                                >
                                                    {{ country }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-7">                                
                                        <div
                                            v-if="phoneNumber || faxNumber || mobilNumber"
                                        >
                                            <div
                                                v-if="phoneNumber"
                                                class="business-data__contact-detail"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-phone"
                                                />
                                                <span
                                                    class="ml"
                                                    data-qa="phoneNumber"
                                                >
                                                    {{ formatPhoneNumber(phoneNumber) }}
                                                </span>
                                            </div>

                                            <div
                                                v-if="mobilNumber"
                                                class="business-data__contact-detail"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-light fa-mobile-button"
                                                />
                                                <span
                                                    class="ml"
                                                    data-qa="mobilNumber"
                                                >
                                                    {{ formatPhoneNumber(mobilNumber) }}
                                                </span>
                                            </div>

                                            <div
                                                v-if="faxNumber"
                                                class="business-data__contact-detail"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-fax"
                                                />
                                                <span
                                                    class="ml"
                                                    data-qa="faxNumber"
                                                >
                                                    {{ formatPhoneNumber(faxNumber) }}
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            v-if="emailAddress || internetAddress"
                                            class="mt"
                                        >
                                            <div
                                                v-if="emailAddress"
                                                class="business-data__contact-detail"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-light fa-envelope"
                                                />
                                                <span
                                                    class="ml"
                                                    data-qa="emailAddress"
                                                    :title="emailAddress"
                                                >
                                                    {{ emailAddress }}
                                                </span>
                                            </div>

                                            <div
                                                v-if="internetAddress"
                                                class="business-data__contact-detail"
                                            >
                                                <font-awesome-icon icon="fa-light fa-globe" />
                                                <span
                                                    class="ml"
                                                    :title="internetAddress"
                                                    data-qa="internetAddress"
                                                >
                                                    {{ internetAddress }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 mt">
                                        <div
                                            v-if="businessId"
                                            class="row"
                                        >
                                            <div
                                                v-if="isDAL"
                                                class="col-5 business-data__list-field"
                                                :title="$t('shared.businessId')"
                                            >
                                                {{ $t('shared.businessId') }}
                                            </div>
                                            <span
                                                v-if="isDAL"
                                                class="col-7 business-data__list-value"
                                                data-qa="businessId"
                                            >
                                                {{ businessIdDisplay }}
                                            </span>

                                            <div
                                                v-if="!isDAL"
                                                class="col-5 business-data__list-field"
                                                :title="$t('search.extended.identnumber')"
                                            >
                                                {{ $t('search.extended.identnumber') }}
                                            </div>
                                            <span
                                                v-if="!isDAL"
                                                class="col-7 business-data__list-value"
                                                data-qa="identnumber"
                                            >
                                                {{ businessId }}
                                            </span>
                                        </div>

                                        <div
                                            v-if="aggregatedBusinessStatus && isDAL"
                                            class="row"
                                        >
                                            <span
                                                class="col-5 business-data__list-field"
                                                :title="t('shared.status')"
                                            >
                                                {{ t('shared.status') }}
                                            </span>
                                            <span
                                                class="col-7 business-data__list-value"
                                                data-qa="status"
                                            >
                                                {{ t('report.detail.businessStatus.' + aggregatedBusinessStatus.id) }}
                                            </span>
                                        </div>

                                        <div
                                            v-if="tradeName"
                                            class="row mt"
                                        >
                                            <span
                                                class="col-5 business-data__list-field"
                                                :title="t('shared.handelsName')"
                                            >
                                                {{ t('shared.handelsName') }}
                                            </span>
                                            <span
                                                class="col-7 business-data__list-value"
                                                data-qa="tradeName"
                                            >
                                                {{ tradeName }}
                                            </span>
                                        </div>

                                        <div
                                            v-if="commercialActivity"
                                            class="row"
                                            :class="!tradeName ? 'mt' : ''"
                                        >
                                            <span
                                                class="col-5 business-data__list-field"
                                                title="{c:translate.l(key:'shared.commercialActivity')}"
                                            >
                                                {{ t('shared.commercialActivity') }}
                                            </span>
                                            <span
                                                class="col-7 business-data__list-value"
                                                data-qa="commercialActivity"
                                            >
                                                {{ commercialActivity }}
                                            </span>
                                        </div>

                                        <div
                                            v-if="legalForm?.description"
                                            class="row"
                                            :class="!commercialActivity ? 'mt' : ''"
                                        >
                                            <span
                                                class="col-5 business-data__list-field"
                                                :title="t('shared.rechtsform')"
                                            >
                                                {{ t('shared.rechtsform') }}
                                            </span>
                                            <span
                                                class="col-7 business-data__list-value"
                                                data-qa="legalForm"
                                            >
                                                {{ legalForm.description }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 ">
                            <div class="box-shadow-xy box-spacing bg-brand-darkest-blue h-100">
                                <QuickBuyArea
                                    v-if="businessId && productStore.transformedProducts"
                                    :business-data="businessData"
                                    :has-business-data="hasBusinessData"
                                    :related-transactions="transactions"
                                    :show-dal-products="showDalProducts"
                                    :is-dal="businessData ? businessData.dal : isDAL"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row content-spacer">
                        <div class="col-12">
                            <div
                                v-if="featurePortfolioActive && transactionsCount > 0 "
                                id="searchResults"
                            >
                                <h3>{{ $t('transaction.list.transaction_headline') }} (<span>{{ transactionsCount }}</span>)</h3>

                                <LoadingRing v-if="transactionLoadingSpinner" />

                                <div class="searchresult">
                                    <div 
                                        v-if="!transactionLoadingSpinner"
                                        data-qa="myTransactions-searchResultList"
                                    >
                                        <div
                                            v-for="transaction in transactions"
                                            :key="transaction.id"
                                        >
                                            <ListItemReport
                                                v-if="transaction.reportTransaction"
                                                :transaction="transaction"
                                                :hide-actions="true"
                                                :hide-buy-button="true"
                                                class="mb-small"
                                            />

                                            <ListItemInvolvementdata
                                                v-if="transaction.involvementdataTransaction"
                                                :transaction="transaction" 
                                                :hide-actions="true"
                                                class="mb-small"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="transactionsCount > 1"
                                    class="align-center"
                                >
                                    <button
                                        class="btn btn-default mt"
                                        data-qa="transactions-expand-or-reduce"
                                    
                                        @click="toggleTransactionDisplayAction"
                                    >
                                        {{ allTransactionsLoaded ? 'Vorgänge ausblenden ▴' : 'Alle Vorgänge anzeigen ▾' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-brand-blue box-spacing">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <ProductSlider
                                v-if="productStore.transformedProducts"
                                :has-business-data="hasBusinessData"
                                :related-transactions="transactions"
                                :is-dal="businessData ? businessData.dal : isDAL"
                            />
                        </div>
                    </div>
                    <div
                        v-if="hasBusinessData"
                        class="row"
                    >
                        <div class="col-md-3" />
                        <div class="comparison col-md-8 d-flex mt">
                            <font-awesome-icon
                                icon="fa-regular fa-clipboard-check"
                                class="text-white"
                                size="2x"
                            />
                            <div class="comparison__text">
                                <span class="comparison__label h2 text-white m0"> {{ $t('report.detail.compareAllProducts') }} </span>
                                <a
                                    class="link link--white comparison__product text-white"
                                    :href="reportsCompareProductsLinks[$i18n.locale]"
                                > 
                                    <font-awesome-icon icon="fa-solid fa-chevron-right" />
                                    {{ $t('report.detail.showComparisonTable') }} 
                                </a>
                            </div>
                        </div>
                        <div class="col-md-3" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal
        v-if="modal"
        :id="modal.id"
        :title="modal.title"
        :cancel-text="modal.cancelText"
        :confirm-text="modal.confirmText"
        :error-context="modal.errorContext"
        :icon="modal.icon"
        :data-qa="modal.dataQa"
        @on-close="modal.onClose"
        @on-confirm="modal.onConfirm"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="modal.content" />
    </Modal>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import { getCbraPermissions, getCbraProductAvailability } from '@/services'
import { useUserStore } from '@/stores/user'
import { useProductStore } from '@/stores/product'
import { isAxiosError } from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { countryByCode } from '@/helper/countryCodes'
import type { CbraProductAvailablityResponse, CbraProductAvailablityAggregatedBusinessStatus } from '@/types/cbra/productAvailability'
import { CbraPermissions } from '@/types/cbra/permissions'
import LoadingRing from '@/components/LoadingRing.vue'
import QuickBuyArea from '@/components/Report/QuickBuyArea.vue'
import { CbraPhoneNumber, CbraPostalAddress, CbraLegalForm } from '@/types/cbra/shared'
import { formatPhoneNumber } from '@/helper/format'
import LegitimateNote from '@/components/LegitimateNote.vue'
import { getTransactionsByBusinessId } from '@/helper/services/transaction'
import { showError } from '@/helper/services/error'
import { CbraTransaction, CbraTransactionsSearchResult } from '@/types/cbra/transactions'
import { storeToRefs } from 'pinia'
import ListItemReport from '@/components/Transactions/ListItemReport.vue'
import ListItemInvolvementdata from '@/components/Transactions/ListItemInvolvementdata.vue'
import ProductSlider from '@/components/Report/ProductSlider.vue'
import Modal from '@/components/Modal.vue'
import WatchlistIcon from '@/components/Watchlist/WatchlistIcon.vue'
import { differentContextActive, getReportAct4SbNumberSession, removeMemberNavigationSession } from '@/helper/services/memberNavigation'
import Storage from '@/helper/storage'
import CONSTANTS from '@/data/constants'
import { sendClick } from '@/helper/webtrekk'
import { reportsLandingpageB2BLinks, reportsCompareProductsLinks } from '@/data/typo3Links'
import { checkUserRight } from '@/helper/services/userRights'
import { useCbraSettingsStore } from '@/stores/cbraSettings'

export type ProductInfos = {
    orderable: string[]
    receivable: string[]
    orderableHasMonitoring: string[]
    receivableHasMonitoring: string[]
    orderableHasMonitoringPlus: string[]
    receivableHasMonitoringPlus: string[]
    orderableHasMonitoringRequired: string[]
    receivableHasMonitoringRequired: string[]
    permissions: string[]
    hasMonitoring?: string[]
    hasMonitoringPlus?: string[]
}

const CONSTS = {
    INACTIVE: '002',
    SHOW_SAVED_BUSINESS_ITEM: 416,
    COMMERCIAL_REPORT: 'COMMERCIAL_REPORT',
    TOPIC: 'BusinessData',
    INITIAL_SHOWN_TRANSACTIONS: 1
}

const countriesDAL = ['DE', 'AT', 'LU']

const id = 'portal.mc.detail-company'

const cbraSettingsStore = useCbraSettingsStore()
const userStore = useUserStore()
const productStore = useProductStore()
const { modal } = storeToRefs(productStore)
const route = useRoute()
const { t, locale } = useI18n()

const countryParam = route.query.country?.toString() ?? ''
const showDalProducts = countriesDAL.includes(countryParam.toUpperCase())

const navigatingAsDifferentMember = computed(() => {
    const access = checkUserRight('report')
    if (access.grants && differentContextActive() && !getReportAct4SbNumberSession()) {
        return true
    }
    return false
}) 
const error = ref(false)
const hasBusinessData = ref(false)
const businessData = ref()
const showLoadingSpinner = ref(true)
const transactionLoadingSpinner = ref(false)

const aggregatedBusinessStatus = ref<CbraProductAvailablityAggregatedBusinessStatus>()
const businessId = ref(route.query.businessId?.toString())
const businessIdDisplay = ref('')
const businessName = ref('')
const businessAlias = ref('')
const tradeName = ref('') // @TODO check if still exist in API?
const postalAddress = ref<CbraPostalAddress | null>()
const postalAddressTitle =ref('')
const emailAddress = ref('')
const internetAddress =ref('')
const phoneNumber = ref<CbraPhoneNumber | null>()
const mobilNumber = ref<CbraPhoneNumber | null>()
const faxNumber = ref<CbraPhoneNumber | null>()
const commercialActivity = ref('')
const legalForm = ref<CbraLegalForm | null>()
const productInfos = ref<ProductInfos | undefined>()

const { legitimateInterestShared } = storeToRefs(productStore)

const country = computed(() => {
    if (!postalAddress.value) return ''
    const country = countryByCode(postalAddress.value.country)
    return country?.i18nKey ? t(country.i18nKey) : ''
})

const isDAL = ref<boolean>(false)

const transactions = ref<CbraTransaction[]>([])
const transactionsCount = ref(0)
const showNoResult = ref(false)

const featurePortfolioActive = computed(() => {
    return cbraSettingsStore.features.portfolioViewActive
})

const allTransactionsLoaded = ref(false)

onMounted(async() => {
    await cbraSettingsStore.getCbraFeatures()
    await handleFetch()
})

watch(productInfos, async(newValue) => {
    if (!newValue) return
    await productStore.transformProducts(newValue, showDalProducts, isDAL.value)
})

async function handleFetch() {
    try {
        showLoadingSpinner.value = true
        const respBusinessData = await getCbraProductAvailability({ businessId: businessId.value, crId: userStore.user.cr_id, communicationLanguage: locale.value })
        const respPermissions = await getCbraPermissions()

        hasBusinessData.value = true
        businessData.value = respBusinessData.data
        updateBusinessData(respBusinessData.data, respPermissions.data)
        initTransactions()
    } catch (err) {
        if (isAxiosError(err) && err.response?.status === CONSTS.SHOW_SAVED_BUSINESS_ITEM) {
            loadSessionValues()
            await showCommercialReport()
        } else if (isAxiosError(err) && err.response?.status !== CONSTS.SHOW_SAVED_BUSINESS_ITEM) {
            error.value = true
            modal.value = showError({ topic: CONSTS.TOPIC , xhr: err })
            if (modal.value ) {
                modal.value.onClose = () => modal.value = undefined
                modal.value.onConfirm = () => modal.value = undefined
            }
        }
    }
    showLoadingSpinner.value = false
}

function updateBusinessData(data: CbraProductAvailablityResponse, permissionData: CbraPermissions) {
    aggregatedBusinessStatus.value = data.aggregatedBusinessStatus
    businessId.value = data.businessId
    businessIdDisplay.value = businessIdToBusinessIdDisplay(data.businessId)
    businessName.value = data.businessName
    businessAlias.value = data.businessAlias
    tradeName.value = data.tradeName
    postalAddress.value = data.postalAddress
    postalAddressTitle.value = getPostAddressTitle(data.postalAddress)
    emailAddress.value = data.emailAddress
    internetAddress.value = data.internetAddress
    phoneNumber.value = data.phoneNumber
    mobilNumber.value = data.mobilNumber
    faxNumber.value = data.faxNumber
    productInfos.value = transformProductInfos(data, permissionData)
    commercialActivity.value = data.commercialActivity
    legalForm.value = data.legalForm
    isDAL.value = data.dal
}

function getPostAddressTitle(postalAddress: CbraPostalAddress) {
    postalAddress.street = (postalAddress.street) ? postalAddress.street : ''
    postalAddress.houseNumber = postalAddress.houseNumber || ''
    postalAddress.houseNumberSuffix = postalAddress.houseNumberSuffix || ''
    postalAddress.zipCode = (postalAddress.zipCode) ? postalAddress.zipCode + ' ' : ''
    postalAddress.city = postalAddress.city || ''
    const country2 = (country.value) ? '\n' + country.value : ''

    return `${postalAddress.street} ${postalAddress.houseNumber}${postalAddress.houseNumberSuffix}\n${postalAddress.zipCode}${postalAddress.city}${country2}`
}

function transformProductInfos(data: CbraProductAvailablityResponse, permissionData: CbraPermissions) {
    let productInfos:ProductInfos = {
        orderable: [],
        receivable: [],
        orderableHasMonitoring: [],
        receivableHasMonitoring: [],
        orderableHasMonitoringPlus: [],
        receivableHasMonitoringPlus: [],
        orderableHasMonitoringRequired: [],
        receivableHasMonitoringRequired: [],
        permissions: []
    }
    productInfos.permissions = permissionData.permissions

    if (typeof data !== 'object' || data === null) {
        return productInfos
    }
    if (typeof data.productInfos !== 'object' || data.productInfos === null) {
        return productInfos
    }

    if (data.productInfos.orderable instanceof Array) {
        data.productInfos.orderable.forEach((product) => {
            productInfos.orderable.push(product.productType)
            if (product.monitoringUsage === 'SUPPORTED') {
                productInfos.orderableHasMonitoring.push(product.productType)
            }
            if (product.monitoringPlusUsage === 'SUPPORTED') {
                productInfos.orderableHasMonitoringPlus.push(product.productType)
            }
            if (product.monitoringUsage === 'REQUIRED') {
                productInfos.orderableHasMonitoring.push(product.productType)
                productInfos.orderableHasMonitoringRequired.push(product.productType)
            }
        })
    }
    if (data.productInfos.receivable instanceof Array) {
        data.productInfos.receivable.forEach((product) => {
            productInfos.receivable.push(product.productType)
            if (product.monitoringUsage === 'SUPPORTED') {
                productInfos.receivableHasMonitoring.push(product.productType)
            }
            if (product.monitoringPlusUsage === 'SUPPORTED') {
                productInfos.receivableHasMonitoringPlus.push(product.productType)
            }
            if (product.monitoringUsage === 'REQUIRED') {
                productInfos.receivableHasMonitoring.push(product.productType)
                productInfos.receivableHasMonitoringRequired.push(product.productType)
            }
        })
    }

    productInfos.orderable.filter((product, index) => productInfos.orderable.indexOf(product) === index)
    productInfos.receivable.filter((product, index) => productInfos.receivable.indexOf(product) === index)

    productInfos.orderableHasMonitoring.filter((product, index) => productInfos.orderableHasMonitoring.indexOf(product) === index)
    productInfos.receivableHasMonitoring.filter((product, index) => productInfos.receivableHasMonitoring.indexOf(product) === index)

    productInfos.orderableHasMonitoringPlus.filter((product, index) => productInfos.orderableHasMonitoringPlus.indexOf(product) === index)
    productInfos.receivableHasMonitoringPlus.filter((product, index) => productInfos.receivableHasMonitoringPlus.indexOf(product) === index)

    productInfos.orderableHasMonitoringRequired.filter((product, index) => productInfos.orderableHasMonitoringRequired.indexOf(product) === index)
    productInfos.receivableHasMonitoringRequired.filter((product, index) => productInfos.receivableHasMonitoringRequired.indexOf(product) === index)

    return productInfos
}

function businessIdToBusinessIdDisplay(businessId: string) {
    return businessId.substring(4)
}

function initTransactions() {
    if (!businessId.value) return 
    getTransactions(businessId.value, CONSTS.INITIAL_SHOWN_TRANSACTIONS)
}

async function getTransactions(businessId: string, count: number) {
    transactionLoadingSpinner.value = true

    try {
        const { data } = await getTransactionsByBusinessId( businessId,
            count,
            0,
            'CREATION_TIME_DESC',
            'REPORT'
        )
        onTransactionsLoaded(data)
    } catch (error) {
        if (isAxiosError(error)) {
            modal.value = showError({ topic: CONSTS.TOPIC, func: 'getTransactions', xhr: error })
            if (modal.value ) {
                modal.value.onClose = () => modal.value = undefined
                modal.value.onConfirm = () => modal.value = undefined
            }
        }
    }

    transactionLoadingSpinner.value = false
}

function onTransactionsLoaded(data: CbraTransactionsSearchResult) {
    transactions.value = data.transactions
    transactionsCount.value = data.sizeTransactions
    showNoResult.value = data.transactions.length === 0
}

function toggleTransactionDisplayAction() {
    if (!businessId.value) return
    if (allTransactionsLoaded.value === false) {
        sendClick('UDS_Click_Btn_alle_Vorgänge')
    }

    getTransactions(
        businessId.value,
        allTransactionsLoaded.value ? CONSTS.INITIAL_SHOWN_TRANSACTIONS : transactionsCount.value
    )

    allTransactionsLoaded.value = !allTransactionsLoaded.value
}

function clickInfoBoxMessageLink() {
    if (removeMemberNavigationSession()) {
        window.location.reload()
    }
}

// if businessdata is null, try to load session values from search or watchlist
function loadSessionValues() {
    const businessItem = Storage.get(CONSTANTS.EVENTS.SAVED_BUSINESS_ITEM)
    if (!isEmptyObject(businessItem)) {
        businessId.value = businessItem.businessId
        businessIdDisplay.value = businessIdToBusinessIdDisplay(businessItem.businessId)
        businessName.value = businessItem.businessName
        postalAddress.value = businessItem.postalAddress
        postalAddressTitle.value = getPostAddressTitle(businessItem.postalAddress)
        aggregatedBusinessStatus.value = businessItem.aggregatedBusinessStatus
        isDAL.value = ['DE', 'AT', 'LU'].includes(businessItem.postalAddress.country)
        tradeName.value = businessItem.tradeName
        legalForm.value = businessItem.legalForm
        commercialActivity.value = businessItem.commercialActivity
        // ko.postbox.publish(constants.PB.BUSINESS_DATA, businessItem)
    }
}

function isEmptyObject(obj: any) {
    for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false
        }
    }
    return true
}

async function showCommercialReport() {
    let isDALNew = false
    if (postalAddress.value && postalAddress.value.country) {
        isDALNew = ['DE', 'AT', 'LU'].includes(postalAddress.value.country)
    }
    isDAL.value = isDALNew

    const productInfosNew: ProductInfos = {
        orderable: [CONSTS.COMMERCIAL_REPORT],
        receivable: [],
        hasMonitoring: [],
        hasMonitoringPlus: [],
        orderableHasMonitoring: [CONSTS.COMMERCIAL_REPORT],
        orderableHasMonitoringPlus: [isDAL.value ? CONSTS.COMMERCIAL_REPORT : ''],
        orderableHasMonitoringRequired: [],
        receivableHasMonitoring: [],
        receivableHasMonitoringPlus: [],
        receivableHasMonitoringRequired: [],
        permissions: []
    }

    try {
        const response = await getCbraPermissions()
        const permissionsData = response.data
        productInfosNew.permissions = permissionsData.permissions
        productInfos.value = productInfosNew
    } catch (error) {
        productInfos.value = productInfosNew
    }
}

</script>

<style scoped lang="less">
.box--business-data {
    line-height: 30px;

    .fa-mobile-button {
        margin-right: 2px;
        margin-left: 2px;
    }
}

.business-data__contact-detail {
    font-weight: 600;
    color: #183f6d;
    word-break: break-all;
}

.business-data__list-field {
    display: block;
    white-space: nowrap;
    overflow: hidden;
}

.business-data__list-value {
    font-weight: 600;
    color: #183f6d;
    word-wrap: break-word;
}

.comparison {
    display: flex;
    align-items: center;
    gap: 25px;
    font-size: 32px;
    line-height: 1.13;
    color: var(--color-c-white);
    &__text {
      padding-top: 10px;
      display: table-cell;
    }
    &__product {
      font-size: 14px;
      display: block;
      margin-top: 10px;
    }

  }

.location-icon {
    margin-top: 8px;
}

.watchlist-icon {
    line-height: 0;
}

</style>