import { ComposerTranslation } from 'vue-i18n'

type SelectOption = {
    label: string
    code: string | null
}

export function registerArtOptions(t: ComposerTranslation, country: string) {
    const optionsAT: SelectOption[] = [
        { label: t('advancedSearch.options.registerArt.REGISTERART_AT_FIRMENBUCH'), code: 'REGISTERART_AT_FIRMENBUCH' }
    ]
    
    const optionsDE: SelectOption[] = [
        { label: t('advancedSearch.options.registerArt.REGISTERART_DE_GENR'), code: 'REGISTERART_DE_GENR' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_DE_GSR'), code: 'REGISTERART_DE_GSR' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_DE_HRA'), code: 'REGISTERART_DE_HRA' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_DE_HRB'), code: 'REGISTERART_DE_HRB' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_DE_PARTR'), code: 'REGISTERART_DE_PARTR' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_DE_VERR'), code: 'REGISTERART_DE_VERR' }
    ]
    
    const optionsLU: SelectOption[] = [
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_A'), code: 'REGISTERART_LU_A' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_B'), code: 'REGISTERART_LU_B' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_C'), code: 'REGISTERART_LU_C' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_D'), code: 'REGISTERART_LU_D' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_E'), code: 'REGISTERART_LU_E' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_F'), code: 'REGISTERART_LU_F' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_G'), code: 'REGISTERART_LU_G' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_H'), code: 'REGISTERART_LU_H' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_I'), code: 'REGISTERART_LU_I' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_J'), code: 'REGISTERART_LU_J' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_K'), code: 'REGISTERART_LU_K' },
        { label: t('advancedSearch.options.registerArt.REGISTERART_LU_L'), code: 'REGISTERART_LU_L' }
    ]
    
    switch (country) {
    case 'AT':
        return optionsAT
    case 'DE':
        return optionsDE
    case 'LU':
        return optionsLU
    default:
        return []
    }
}
