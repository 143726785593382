<template>
    <!-- https://www.figma.com/file/8rA3ZV9o0QqmGaXo3E6OaJ/Crefo_Design_Library?type=design&node-id=538-8181&mode=design&t=TBiUq70x1m6PKwkz-0 -->
    <span class="crefo-label">
        <slot />
    </span>
</template>

<style lang="less" scoped>
.crefo-label {
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 0.25rem 0.5rem;
    color: white;
    font-size: 13px;
    line-height: 18px;
    white-space: nowrap;
    background: var(--color-c-s3);
    display: inline-block;
    /*font-weight: 700;*/
}
</style>
