<template>
    <div
        :id="id"
        class="custom-select"
        :class="{ 'has-error': !!errorMessage }"
    >
        <slot />
        <label
            v-if="label"
            :id="id + '.label'"
            class="input-label"
        >{{ label }}<template
            v-if="required"
        > *</template></label>
        <v-select
            :id="id + '.select'"
            v-model="input"
            :options="options"
            :reduce="reduceOption"
            :searchable="searchable"
            :clearable="false"
            :get-option-label="getOptionLabel"
            :data-qa="dataQa"
            :disabled="disabled"
            @option:selected="select"
        >
            <template #open-indicator="{ attributes }">
                <span v-bind="attributes" />
            </template>
            <template #no-options>
                <div />
            </template>
        </v-select>
        <div
            v-if="helpText"
            class="help-text"
        >
            {{ helpText }}
        </div>
        <div
            v-if="errorMessage"
            :id="id + '.error'"
            class="error-msg"
            :data-qa="errorMsgDataQaAttr"
        >
            <span>{{ errorMessage }}</span>
        </div>
        <!--
            Die 'hidden' Input-Felder wurden mit migriert, um den Aufbau der Selects im TYPO3 zu replizieren.
            Dies soll verhindern, dass bestehende Selenium-Tests angepasst werden müssen.
        -->
        <input
            v-model="input"
            type="hidden"
            :name="name"
            :data-qa="dataQa + '-input-value'"
        >
    </div>
</template>

<script lang="ts" setup>
import { onMounted, toRef } from 'vue'
import { useField } from 'vee-validate'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

type SelectOption = {
    code: string | boolean | number | null
    label: string
    i18nKey?: string
}

const props = withDefaults(defineProps<{
    id: string
    modelValue: string | boolean | number | undefined | null
    options: SelectOption[]
    dataQa?: string
    errorMsgDataQa?: string
    label?: string
    name?: string
    required?: boolean
    disabled?: boolean
    searchable?: boolean
    helpText?: string
}>(), {
    label: '',
    dataQa: '',
    errorMsgDataQa: '',
    name: '',
    searchable: true,
    required: false,
    disabled: false,
    helpText: ''
})
const name = toRef(props, 'id')
const {
    value: input,
    errorMessage
} = useField(name, undefined, {
    initialValue: toRef(() => props.modelValue).value,
    syncVModel: true,
})

const errorMsgDataQaAttr = props.errorMsgDataQa || (props.dataQa ? `${props.dataQa}-message` : '')

const emit = defineEmits<{
    (e: 'option:label', value: string | boolean | number): void
    (e: 'update:modelValue', value: string | boolean | number): void
}>()

function reduceOption(option: SelectOption) {
    return option.code
}

function getOptionLabel(option: SelectOption) {
    if (option.i18nKey) {
        return t(option.i18nKey)
    }
    return option.label
}

onMounted(() => {
    props.options.forEach((option) => {
        if (option.code === input.value) {
            select(option)
        }
    })
})

function select(option: SelectOption) {
    emit('option:label', option.label)
}

defineExpose({ input, select })
</script>

<style scoped>
label {
    margin-bottom: 12px;
    line-height: 26px;
}
.has-error {
    .input-label {
        color: var(--color-error);
    }
}
</style>

<style>
.custom-select .v-select {
    background: var(--color-c-white);
}
.custom-select .vs__dropdown-toggle {
    border-radius: 0;
    border-color: #b5b5b5;
    padding: 1rem 1.2rem;
    line-height: 24px;
    max-height: 56px;
}

.custom-select .vs__selected {
    margin: 0;
}

.custom-select .vs__selected-options {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    width: fit-content;
    white-space: nowrap;
}

.custom-select .vs__search,
.custom-select .vs__search:focus {
    margin-top: 0;
}

.custom-select .vs__actions {
    padding: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    right: 1.2rem;
}

.custom-select .vs__actions:after {
    content: "\e909";
    font-family: crefo-ui-icons, sans-serif !important;
    font-size: 24px;
    display: block;
    position: absolute;
    color: #b5b5b5;
    transform: rotate(0);
    transition: all .15s ease-in-out;
}

.custom-select .vs__actions:hover:after {
    color: var(--color-c-p1);
}

.custom-select .vs__dropdown-toggle:hover .vs__actions:after {
    color: var(--color-c-p1);
}

.custom-select .vs__dropdown-toggle:hover,
.custom-select .vs--open .vs__dropdown-toggle {
    border-color: var(--color-c-p1);
}

.custom-select .vs--open .vs__actions:after {
    transform: rotate(180deg);
}

.custom-select .vs__dropdown-option {
    white-space: normal;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, .1) transparent;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    padding: 1rem 1.2rem;
}

.custom-select .vs__dropdown-option:last-child {
    border: 0;
}

.custom-select .vs__dropdown-menu {
    padding-bottom: 0;
    padding-top: 0;
    border-radius: 0;
    border: 1px solid var(--color-c-p1);
    border-top: 1px solid #ccc;
    box-shadow: none;
}

.custom-select .vs__dropdown-option--selected {
    background: var(--color-c-p1);
    color: #fff;
}
.custom-select .vs__dropdown-option--highlight {
    background: var(--color-c-p1);
}

.custom-select {
    margin-bottom: 15px;
}

.error-msg {
    color: #e05469;
    line-height: 20px;
    font-size: 14px;
    padding-top: 10px;
    margin-bottom: 0;
}

.has-error .vs__dropdown-toggle {
    border-color: #e05469;
}

.help-text {
    font-size: 12px;
    color: var(--color-c-grey2);
    margin-top: 5px;
}
</style>
