<template>
    <div class="container content-spacer">
        <LoadingOverlay v-if="loading" />

        <Modal
            v-if="errorAlert"
            :id="errorAlert.id"
            :title="errorAlert.title"
            :error-context="errorAlert.errorContext"
            :confirm-text="$t('shared.modals.ok')"
            :icon="errorAlert.icon"
            :data-qa="errorAlert.dataQa"
            @on-confirm="onErrorConfirm"
            @on-close="onErrorConfirm"
        >
            <p>{{ errorAlert.content }}</p>
        </Modal>

        <div
            v-if="!loading && error"
            class="crefo-ui-alert error mb"
        >
            {{ $t('globalMisc.error.errorLoadingData.message') }}
        </div>
        <div 
            v-else
            class="row mb-large"
        >
            <div class="col-12 col-lg-3 mb">
                <div class="box-shadow-xy bg-white chapter-links-box">
                    <div class="box-spacing">
                        <ChapterLinks 
                            section="Unternehmensdaten"
                            :links="chapter" 
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="businessData !== null"
                class="col-12 col-lg-9 mb-large"
            >
                <BusinessDataForm :business-data="businessData" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import ChapterLinks from '@/components/Profile/partials/ChapterLinks.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import BusinessDataForm from '@/components/Profile/partials/Businessdata/Form.vue'
import Modal from '@/components/Modal.vue'

import { getCbraBusinessData } from '@/services'

import type { CbraBusinessData } from '@/types/cbra/businessData'
import type { ModalProps } from '@/types/components/modal'
import router from '@/router'

const { t } = useI18n()

const businessData = ref<CbraBusinessData | null>(null)

const errorAlert = ref<ModalProps>()
let error = ref(false)
let loading = ref(false)

const chapter = [
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.firmierung',
        href: '#businessData-companyName'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.adresse',
        href: '#businessData-address'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.kommunikation',
        href: '#businessData-communication'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.steuerdaten',
        href: '#businessData-taxData'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.funktionsbeteiligte',
        href: '#businessData-management'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.geschaeftsgegenstand',
        href: '#businessData-economicSector'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.importExport',
        href: '#businessData-importExport'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.umsatzUndMitarbeiterangaben',
        href: '#businessData-salesEmployee'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.bankverbindung',
        href: '#businessData-bankData'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.unternehmensstandorte',
        href: '#businessData-locations'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.ansprechpartner',
        href: '#businessData-contactPerson'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.weitereAngaben',
        href: '#businessData-misc'
    }
]

const onErrorConfirm = () => {
    errorAlert.value = undefined
    router.push({ name: 'profile', params: { section: 'einstellungen' } })
}

async function loadBusinessData() {
    loading.value = true

    try {
        const { status, data } = await getCbraBusinessData()
        if (status === 200) {
            businessData.value = data
        }
    } catch (err) {
        errorAlert.value = {
            id: 'userrights-not-granted',
            title: t('error.userrights.saveUnauthorized'),
            content: t('error.userrights.saveUnauthorized.description'),
            icon: 'error',
            dataQa: 'userrights-not-granted'
        }
        error.value = true
    }

    loading.value = false
}

onMounted(loadBusinessData)
</script>

<style scoped>
.error {
    display: inline-block;
}
</style>