
<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('MeineMitgliedschaft.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c7183"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-7183"
                    >
                        <div
                            id="toc7183"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-7185"
                                        class="toc__"
                                        href="/hilfe/crefoeva/#c7185"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7187"
                                                href="/hilfe/crefoeva/#c7187"
                                                @click.prevent="scrollTo('#c7187')"
                                            >
                                                {{ $t('MeineMitgliedschaft.sticky.1') }}                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-7189"
                                                href="/hilfe/crefoeva/#c7189"
                                                @click.prevent="scrollTo('#c7189')"
                                            >
                                                {{ $t('MeineMitgliedschaft.sticky.2') }}                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c7185"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-7185"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />

                                        <div
                                            id="c7187"
                                            class="ce-box   box"
                                            data-qa="ce-7187"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('MeineMitgliedschaft.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic ce-textpic--right ce-textpic--intext row">
                                                        <div class="col-md-6 order-md-2">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/meine-mitgliedschaft/siegel-wir-sind-mitglied-cyan-rgb.png"
                                                                    width="287"
                                                                    height="287"
                                                                    alt="meine-mitgliedschaft siegel wir sind mitglied"
                                                                >
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 order-md-1">
                                                            <div class="ce-textpic__bodytext">
                                                                <p>{{ $t('MeineMitgliedschaft.text1') }}</p>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <ul class="list-ordered">
                                                                    <li><p>{{ $t('MeineMitgliedschaft.point1') }}</p></li>
                                                                    <li><p>{{ $t('MeineMitgliedschaft.point2') }}</p></li>
                                                                    <li><p>{{ $t('MeineMitgliedschaft.point3') }}</p></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="ce-textpic__bodytext mt mb">
                                                        <p>{{ $t('MeineMitgliedschaft.text2') }}</p>
                                                        <a href="www.creditreform.de/mitgliederlogo">{{ $t('MeineMitgliedschaft.link') }}</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                id="c7189"
                                                class="ce-box  box"
                                                data-qa="ce-7189"
                                            >
                                                <div class="ce-header">
                                                    <h3>{{ $t('MeineMitgliedschaft.title2') }}</h3>
                                                </div>

                                                <div class="ce-textpic__bodytext mt mb">
                                                    <span>{{ $t('MeineMitgliedschaft.text3') }}</span>
                                                    <a href="https://sedevcp.meine.creditreform.de/portal/mc/kontakt">
                                                        {{ $t('MeineMitgliedschaft.hyperlink') }}</a>
                                                    <span>{{ $t('MeineMitgliedschaft.text4') }}</span>
                                                    <a href="https://sedevcp.meine.creditreform.de/portal/mc/kontakt">{{ $t('MeineMitgliedschaft.text5') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

import { scrollTo } from '@/helper/scrollto'
import Breadcrumb from '@/components/Breadcrumb.vue'

const id = 'portal.mc.help.my-membership'
</script>

<style scoped lang="less">

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}

.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

</style>

