<template>
    <div
        :id="props.id"
        :class="{ 
            'has-error': !!errorMessage, 
            'is-valid': meta.valid && !!inputValue 
        }"
        :data-qa="props.dataQa"
    >
        <label
            :for="props.id + '.date-picker'"
            :style="label ? '' : 'opacity:0;'"
        >{{ label }}<template
            v-if="required"
        >*</template></label>
        <date-picker
            :id="props.id + '.date-picker'"
            v-model:value="inputValue"
            :data-qa="props.dataQa"
            :format="format"
            class="crefo-datepicker"
            :disabled="props.disabled"
            :name="props.name"
            :default-value="props.defaultValue"
            :disabled-date="disabledDate"
            @input-error="handleInputError"
        />
        <p
            v-if="errorMessage"
            :id="props.id + '.error'"
            class="error-msg"
            :data-qa="`${props.dataQa}-message`"
        >
            {{ errorMessage }}
        </p>
    </div>
</template>

<script lang="ts" setup>
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import { useField } from 'vee-validate'
import { toRef } from 'vue'
import dayjs from 'dayjs'
// import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
// import de from 'vue-datepicker-next/locale/de'
// import fr from 'vue-datepicker-next/locale/fr'
// import en from 'vue-datepicker-next/locale/en'

// const { locale } = useI18n()

const props = withDefaults(defineProps<{
    id: string
    modelValue: Date | undefined | null
    dataQa: string
    validate?: object
    label?: string
    disabled?: boolean
    name?: string
    required?: boolean
    defaultValue?: Date
    disabledDate?: (date: Date) => boolean
}>(), {
    validate: () => {
        return {}
    },
    disabled: false,
    label: '',
    dataQa: '',
    name: '',
    defaultValue: undefined,
    disabledDate: undefined
})

const name = toRef(props, 'name')

const {
    value: inputValue,
    errorMessage,
    meta,
} = useField(name, undefined, {
    initialValue: props.modelValue,
    syncVModel: true
})

const mld = computed(() => dayjs.localeData())

const format = computed(() => {
    return mld.value.longDateFormat('L')
})

// const lang = computed(() => {
//     if (locale.value === 'de-DE' || locale.value === 'de-AT') return de
//     if (locale.value === 'fr-FR' || locale.value === 'fr-LU') return fr
//     return en
// })

function handleInputError(text: any) {   
    const date = dayjs(text, mld.value.longDateFormat('L')).toDate()
    inputValue.value = date
}
</script>

<style scoped>
.error-msg {
    display: block !important;
}
label {
    margin-bottom: 12px;
    line-height: 26px;
}
</style>

<style scoped lang="less">
.has-error .crefo-datepicker :deep(.mx-input-wrapper) {
    border-color: #e05469;
}

.crefo-datepicker {
    width: 100%;

    :deep(.mx-input-wrapper) {
        color: #7f7f7f;
        border-width: 1px;
        border-color: #b5b5b5;
        border-style: solid;
        flex: 1 1 auto;
        position: relative;
        transition: border-color 0.15s ease-in-out;

        input.mx-input {
            flex: 1 1 auto;
            font-size: 15px;
            font-weight: normal;
            line-height: 24px;
            padding: 1rem 3.2rem 1rem 1.2rem;
            outline: none;
            height: auto;
            max-width: 100%;
            min-width: 0;
            width: 100%;
            border-style: none;
            color: #4c4c4c;
            border-color: #b5b5b5;
            background: #ffffff;
            box-shadow: none;
        }

        .mx-icon-calendar,
        .mx-icon-clear {
            right: 1.2rem;
            color: #7f7f7f;
        }

        &:hover {
            border-color: var(--color-c-p1);

            .mx-icon-calendar,
            .mx-icon-clear {
                color: #7f7f7f;
            }
        }
    }

    &.disabled {
        :deep(.mx-input-wrapper) {
            &:hover {
                border-color: #b5b5b5;
            }
        }
    }
}
</style>
<style lang="less">
.mx-datepicker-popup {
    margin-top: -1px;
    box-shadow: none !important;
}

.mx-datepicker-main {
    border-width: 1px;
    border-color: #b5b5b5;
    border-style: solid;
    transition: border-color 0.15s ease-in-out;
    color: #4c4c4c !important;
    font-size: 15px !important;
    line-height: 26px !important;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif !important;

    &:hover {
        border-color: var(--color-c-p1);
    }

    .mx-btn {
        color: #ffffff;
        background-color: var(--color-c-p1);
        line-height: 56px;
        font-size: 15px;
        font-weight: 600;
        padding: 0 13.5px;
        margin: 0;
        border: none;
        border-radius: 0;
        white-space: normal;

        &:hover {
            background-color: #006eb7;
        }
    }

    .mx-time {
        width: 360px;

        .mx-time-header {
            height: 70px;
            padding-top: 6px;
            border-bottom-color: #b5b5b5;

            .mx-btn {
                text-align: center;
            }
        }

        .mx-time-content {
            height: 239px;

            .mx-time-column {
                border-left-color: #b5b5b5;

                li {
                    padding: 8px 0;
                    height: 31px;
                }

                .mx-time-item {
                    font-size: 15px;
                    line-height: 15px;

                    &.active,
                    &:hover {
                        background-color: transparent;
                        color: var(--color-c-p1);
                    }

                    &.active {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .mx-calendar {
        width: 360px;
        padding: 15px 18px;

        &+.mx-calendar {
            border-left-color: #b5b5b5;
        }

        .mx-calendar-header {
            margin-bottom: 15px;

            @media only screen and (max-width: 1199px) {
                margin-bottom: 10px;
            }

            @media only screen and (max-width: 768px) {
                margin-bottom: 7.5px;
            }

            .mx-btn {
                min-width: 32px;
                min-height: 32px;
                color: #4c4c4c;
                background-color: transparent;
                line-height: 32px;
                font-weight: normal;
                white-space: nowrap;

                &:hover {
                    color: var(--color-c-p1);
                }
            }

            .mx-calendar-header-label {
                font-size: 15px;
            }
        }

        .mx-calendar-content {
            height: 230px;

            .mx-table th,
            td {
                font-size: 15px;
                line-height: 15px;
                font-weight: normal;
                text-align: center;
                transition: color 0.1s ease-in-out;
            }

            .mx-table th {
                font-weight: 600;
            }

            .mx-week-number {
                opacity: 0.6;
            }

            .mx-table-date {
                .today {
                    color: var(--color-c-p1);
                }
            }

            .mx-table-date,
            .mx-table-month,
            .mx-table-year {
                .cell {

                    &.active,
                    &:hover {
                        background-color: transparent;
                        color: var(--color-c-p1);
                    }

                    &.active {
                        font-weight: 700;
                    }
                }
            }

            .mx-table-year {
                padding: 0 30px;
            }

            .not-current-month {
                color: #b5b5b5;

                &:hover {
                    color: #b5b5b5;
                }
            }

            .cell {
                &:hover {
                    background-color: transparent;
                    color: var(--color-c-p1);
                }

                &.disabled {
                    color: #ffffff;
                    background-color: #b5b5b5;

                    &:hover {
                        color: #ffffff;
                        background-color: #b5b5b5;
                    }
                }
            }
        }

        &.mx-calendar-week-mode {
            .mx-date-row {
                transition: background-color 0.1s ease-in-out;

                &:hover {
                    background-color: rgba(0, 157, 224, 0.05);

                    td {
                        color: var(--color-c-p1);
                    }
                }
            }
        }
    }

    .mx-datepicker-footer {
        text-align: center;
        border-top-color: #b5b5b5;
    }
}
</style>
