<!-- eslint-disable vue/no-v-html -->
<template>
    <CarouselSlider
        :per-page="3"
        gap="2rem"
        :pagination="false"
    >
        <ProductSlide
            v-for="product in productStore.transformedProducts"
            :key="product.type"
            :product="product"
            :has-business-data="props.hasBusinessData"
            :related-transactions="props.relatedTransactions"
            @flip="flip"
        />
    </CarouselSlider>
    <Modal
        v-if="flippedProduct"
        :id="slideModal.id"
        :hide-button-section="true"
        @on-close="flippedProduct = undefined"
        @on-confirm="flippedProduct = undefined"
    >
        <div
            class="productTeaserBox"
            data-qa="productTeaserBox"
            :class="{'flipped': flippedProduct , 'productTeaserBox--recommended': flippedProduct.recommended}"
        >
            <div
                v-if="flippedProduct"
                class="productTeaserBox__face productTeaserBox--back"
                data-qa="productTeaserBox-back"
            >
                <div
                    class="productTeaserBox__body"
                    :class="{'productTeaserBox__body--extended': flippedProduct.hasToBeOrdered}"
                >
                    <div class="productTeaserBox__head">
                        <h3
                            class="productTeaserBox__headline"
                            data-qa="productTeaserBox-headline-back"
                        >
                            {{ $t(flippedProduct.title) }}
                        </h3>
                        <div
                            class="productTeaserBox__subheadline"
                        >
                            {{ $t(flippedProduct.subtitle) }}
                        </div>
                        <a
                            v-if="flippedProduct.sampleFile !==''"
                            :href="flippedProduct.sampleFile"
                            class="productTeaserBox__download vspace--2"
                            data-qa="productTeaserBox-backside-downloadSamplePDF"
                        >
                            <font-awesome-icon icon="fa-solid fa-chevron-right" />
                            {{ $t('report.detail.downloadSampleFile') }}
                        </a>
                    </div>
                    <div class="small-box-spacing">
                        <div class="form-group">
                            <label class="d-flex justify-content-between">
                                <span>{{ $t('report.detail.legitimateInterest') }} *</span>
                                <Tooltip
                                    data-qa="productTeaserBox-infoIcon-legitimateInterest"
                                    position="left"
                                >
                                    <font-awesome-icon
                                        icon="fa-regular fa-circle-info"
                                    />
                                    <template #content>
                                        <p>{{ $t('reportProducts.infoText.legitimateInterest') }}</p>
                                    </template>
                                </Tooltip>
                            </label>
                            <Select
                                id="legitimateInterest"
                                v-model="legitimateInterest"
                                class="m0"
                                name="legitimateInterest"
                                :options="legitimateInterestOptions"
                                data-qa="productTeaserBox-selectLegitimateInterest"
                                :searchable="false"
                                required
                                :disabled="isDisabled"
                            />
                        </div>
                        <div
                            v-if="legitimateInterest && legitimateInterestChanged"
                            class="legitimateInterests-wrapper--product-teaser"
                        >
                            <LegitimateNote :legitimate-interest="legitimateInterest" />
                        </div>
                        <template v-if="flippedProduct.hasMonitoring">
                            <label class="d-flex justify-content-between">
                                <span>{{ $t('report.detail.monitoring.label') }} <template v-if="flippedProduct.hasMonitoringRequired">*</template>  </span>
                                <Tooltip
                                    data-qa="productTeaserBox-infoIcon-automaticUpdates"
                                    position="left"
                                    :wide="true"
                                >
                                    <font-awesome-icon
                                        icon="fa-regular fa-circle-info"
                                    />
                                    <template #content>
                                        <p
                                            v-if="isDal"
                                            v-html="$t('report.addMonitoringToOrder.tooltip')"
                                        />
                                        <p
                                            v-if="!isDal"
                                            v-html="$t('report.addMonitoringToOrderNonDal.tooltip')"
                                        />
                                    </template>
                                </Tooltip>
                             
                            </label>
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <Checkbox
                                        :id="'automaticUpdatesCheckbox' + flippedProduct.type"
                                        v-model="monitoring"
                                        :name="'automaticUpdatesCheckbox' + flippedProduct.type"
                                        :label="$t('report.detail.addMonitoringToOrder.QuickBuy')"
                                        :data-qa="isDisabled ? 'productTeaserBox-checkbox-automaticUpdates-disabled' : 'productTeaserBox-checkbox-automaticUpdates'"
                                        :disabled="monitoringDisabled"
                                        @change="setMonitoring"
                                    />
                                </div>
                                <div class="col-12 col-sm-6">
                                    <Checkbox
                                        :id="'automaticUpdatesCheckboxAdditional' + flippedProduct.type"
                                        v-model="monitoringLimited"
                                        :name="'automaticUpdatesCheckboxAdditional' + flippedProduct.type"
                                        :label="$t('report.detail.addMonitoringLimitedToOrder.QuickBuy')"
                                        :data-qa="isDisabled ? 'productTeaserBox-checkbox-automaticUpdates-additional-disabled' : 'productTeaserBox-checkbox-automaticUpdates-additional'"
                                        :disabled="monitoringLimitedDisabled"
                                        @change="setMonitoringLimited"
                                    />
                                </div>
                            </div>
                        </template>

                        <div
                            v-if="monitoringLimited && flippedProduct.hasMonitoring"
                            class="row"
                        >
                            <div class="col-7">
                                <Select
                                    id="befristungEndeMonat"
                                    v-model="befristungEndeMonat"
                                    name="befristungEndeMonat"
                                    :label="''"
                                    :options="monitoringLimitedMonthOptions"
                                    :data-qa="isDisabled ? 'productTeaserBox-select-befristungEndeMonat-disabled' : 'productTeaserBox-select-befristungEndeMonat'"
                                    :searchable="false"
                                    :disabled="isDisabled"
                                />
                            </div>
                            <div class="col-5">
                                <Select
                                    id="befristungEndeJahr"
                                    v-model="befristungEndeJahr"
                                    name="befristungEndeJahr"
                                    :label="''"
                                    :options="monitoringLimitedYearOptions"
                                    :data-qa="isDisabled ? 'productTeaserBox-select-befristungEndeJahr-disabled' : 'productTeaserBox-select-befristungEndeJahr'"
                                    :searchable="false"
                                    :disabled="isDisabled"
                                />
                            </div>
                        </div>

                        <div
                            v-if="monitoring && flippedProduct.hasMonitoringPlus || monitoringLimited && flippedProduct.hasMonitoringPlus"
                            class="form-group"
                        >
                            <Checkbox
                                :id="'automaticUpdatesCheckboxMonitoringPlus' + flippedProduct.type"
                                v-model="monitoringPlus"
                                :name="'automaticUpdatesCheckboxMonitoringPlus' + flippedProduct.type"
                                :label="$t('report.detail.addMonitoringPlusToOrder.QuickBuy')"
                                :data-qa="isDisabled ? 'productTeaserBox-checkbox-automaticUpdates-monitoringPlus-disabled' : 'productTeaserBox-checkbox-automaticUpdates-monitoringPlus'"
                                :is-disabled="isDisabled"
                            />
                        </div>

                        <div class="form-group">
                            <InputText
                                v-model="referenceNumber"
                                :label="$t('shared.reference')"
                                name="referenceNumber"
                                :data-qa="isDisabled ? 'productTeaserBox-insertReferenceNumber-disabled' : 'productTeaserBox-insertReferenceNumber'"
                                :placeholder="$t('report.detail.myReference.placeholder')"
                                :disabled="isDisabled"
                                class="m0"
                                :help-text="$t('report.detail.cannotBeChangedLater')"
                            />
                        </div>
                    </div>

                    <!-- Optional Data -->
                    <div
                        class="productTeaserBox__more-info-link"
                        :data-qa="isDisabled ? 'productTeaserBox-optionalInformation-disabled' : 'productTeaserBox-optionalInformation'"
                        :class="{'open': isMoreOpen, 'disabled': isDisabled}"
                        @click="toggleShowMore"
                    >
                        {{ $t('report.detail.optionalInformation') }}
                    </div>
                    <div class="productTeaserBox__more-info">
                        <div class="bg-white box--product-teaser small-box-spacing">
                            <div class="form-group">
                                <label class="label label--crefo pl-0">
                                    {{ $t('report.detail.reportLanguage.label') }}
                                </label>
                                <div class="form-control__selectWrapper">
                                    <Select
                                        id="reportLanguage"
                                        v-model="reportLanguage"
                                        name="reportLanguage"
                                        class="m0"
                                        :options="reportLanguageOptions"
                                        :data-qa="isDisabled ? 'productTeaserBox-selectLanguage-disabled' : 'productTeaserBox-selectLanguage'"
                                        :searchable="false"
                                        :disabled="isDisabled"
                                    />
                                </div>
                            </div>

                            <div
                                v-if="watchlistSupported && flippedProduct.isSignalProduct"
                                class="form-group"
                            >
                                <Checkbox
                                    :id="'addToWatchlist' + flippedProduct.type"
                                    v-model="watchlist"
                                    :name="'addToWatchlist' + flippedProduct.type"
                                    :label="$t('report.detail.addSignalToOrder')"
                                    :data-qa="'productTeaserBox-checkbox-watchlist'"
                                    :is-disabled="isDisabled"
                                />
                            </div>

                            <Textarea
                                v-if="flippedProduct.hasToBeOrdered"
                                id="myNoteInput"
                                v-model="additionalInfos"
                                :label="$t('shared.myNote')"
                                name="additionalInfos"
                                data-qa="productTeaserBox-insertMyNote'"
                                :placeholder="$t('report.detail.myNote.placeholder')"
                            />
                        </div>
                    </div>
                    <!-- /Optional Data -->

                    <div class="productTeaserBox__cta">
                        <div
                            v-if="flippedProduct.hasToBeOrdered && !productOrderId"
                            class="box box--product-teaser small-box-spacing"
                        >
                            <div class="form-group form-group--no-margin d-flex">
                                <div class="productTeaserBox__productAvailabilityInfo">
                                    {{ $t('report.detail.productAvailabilityMessage') }}
                                </div>
                                <Tooltip
                                    data-qa="productTeaserBox-infoIcon-productAvailability"
                                    position="left"
                                    class="ml"
                                >
                                    <font-awesome-icon
                                        icon="fa-regular fa-circle-info"
                                    />
                                    <template #content>
                                        <p>{{ $t('reportProducts.infoText.productAvailability') }}</p>
                                    </template>
                                </Tooltip>
                            </div>
                        </div>

                        <button
                            v-if="showAcquireButton"
                            class="btn btn-legacy-orange btn-default btn-block"
                            :disabled="!isValid || actionInProgress"
                            :data-qa="'productTeaserBox-' + (!flippedProduct.hasToBeOrdered ? 'buyButton' : 'orderButton') + (isDisabled ? '-disabled' : '')"
                            @click="flippedProduct.hasToBeOrdered ? orderProduct(flippedProduct, hasBusinessData, relatedTransactions) : acquireProduct(flippedProduct, hasBusinessData, relatedTransactions)"
                        >
                            {{ $t(!flippedProduct.hasToBeOrdered ? 'report.detail.buyReportNow' : "report.detail.orderReportNow") }}
                        </button>
                        <div
                            v-if="!showAcquireButton"
                            class="productTeaserBox__bought-message"
                        >
                            {{ $t('report.detail.productBoughtMessage') }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- /BACK -->
        </div>
    </Modal>
</template>

<script lang="ts" setup>
import { CbraTransaction } from '@/types/cbra/transactions'
import CarouselSlider from '../Slider/CarouselSlider.vue'
import ProductSlide from './ProductSlide.vue'
import { useProductStore } from '@/stores/product'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { ModalProps } from '@/types/components/modal'
import { TransformedProduct } from '@/data/products'
import { useProduct } from './useProduct'
import Modal from '../Modal.vue'
import Select from '../Input/Select.vue'
import Tooltip from '../Tooltip.vue'
import Checkbox from '../Input/Checkbox.vue'
import Textarea from '../Input/Textarea.vue'
import LegitimateNote from '../LegitimateNote.vue'
import InputText from '../Input/InputText.vue'
import { REQUIRED_STRING_VALIDATION } from '@/helper/zodSchema'
import { toTypedSchema } from '@vee-validate/zod'
import { useForm, useIsFormValid } from 'vee-validate'
import { object, string } from 'zod'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  hasBusinessData: boolean
  relatedTransactions: CbraTransaction[]
  isDal: boolean
}>()

const productStore = useProductStore()
const { flippedProduct , watchlistSupported } = storeToRefs(productStore)
const { resetLegitimateInterest, actionInProgress, showAcquireButton, referenceNumber , acquireProduct, orderProduct, monitoringLimitedYearOptions, reportLanguageOptions, monitoringLimitedMonthOptions, legitimateInterestOptions, reportLanguage, legitimateInterest, monitoringLimited, monitoring, monitoringPlus, isDisabled, setMonitoringLimited, befristungEndeJahr, befristungEndeMonat , setMonitoring, watchlist, legitimateInterestChanged, productOrderId, additionalInfos } = useProduct(props.isDal)

const slideModal = ref<ModalProps>({
    id: '',
})

const isMoreOpen = ref(false)
const { t } = useI18n()

const monitoringIsDisabled = computed(() => {
    if (isDisabled.value) { return false }

    if (flippedProduct.value?.hasMonitoring) {
        if (monitoring.value && !monitoringPlus.value || !monitoring.value && !monitoringPlus.value) {
            return false
        }
    }
    return true
})

const monitoringDisabled = computed(() => {
    return isDisabled.value || monitoringIsDisabled.value || (flippedProduct.value?.hasMonitoringRequired && monitoring.value)
})

const monitoringLimitedDisabled = computed(() => {
    return isDisabled.value || monitoringIsDisabled.value || (flippedProduct.value?.hasMonitoringRequired && monitoringLimited.value)
})

function flip(product: TransformedProduct) {
    flippedProduct.value = product
    if (flippedProduct.value.hasMonitoringRequired) {
        monitoring.value = true
        monitoringLimited.value = false
    } else {
        monitoring.value = false
        monitoringLimited.value = false
    }
    resetLegitimateInterest()
}

function toggleShowMore() {
    isMoreOpen.value = !isMoreOpen.value
}

const formValidationSchema = toTypedSchema(
    object({
        legitimateInterest: REQUIRED_STRING_VALIDATION(t),
        reportLanguage: REQUIRED_STRING_VALIDATION(t),
        referenceNumber: string().max(32, { message: t('errors.maxLength', { max: 32 }) }),
    }),
)

useForm({ 
    validationSchema: formValidationSchema 
})

const isValid = useIsFormValid()
</script>
