<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue'
import InputText from '@/components/Input/InputText.vue'
import Select from '@/components/Input/Select.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useCbraSettingsStore } from '@/stores/cbraSettings'
import debounce from '@/helper/debounce'
import { WatchListCookie, WatchlistSearchOptions } from '@/types/cbra/watchlist'
import { useWatchlistStore } from '@/stores/watchlist'
import { WatchlistFilter, WatchlistSort } from '@/enums/watchlist'
import { useI18n } from 'vue-i18n'
import Checkbox from '@/components/Input/Checkbox.vue'
import LoadingRing from '@/components/LoadingRing.vue'
import CompanySearch from '@/components/CompanySearch.vue'
import { scrollTo } from '@/helper/scrollto'
import { getCbraUserPreferences, updateCbraUserPreferences } from '@/services'
import type { CbraUserPreferences } from '@/types/cbra/userPreferences'
import Cookies from 'js-cookie'
import SimpleDropdown from '../Input/SimpleDropdown.vue'
import { VuePaginate as Paginate } from '@svifty7/vue-paginate'
import { setCookie } from '@/helper/storage'

const COOKIE_NAME = 'WATCHLIST_LIST_COOKIE'
const userPreferences = ref<CbraUserPreferences | null>(null)
const filterPreference = ref(false)
const cbraSettingsStore = useCbraSettingsStore()
const watchlistStore = useWatchlistStore()
const { t } = useI18n()
const filterType = ref('ALL')
const filterEndSoon = ref(false)
const filterExpired = ref(false)
const loading = ref(false)
const search = reactive<WatchlistSearchOptions>({
    pageSize: 10,
    pageStartIndex: 0,
    searchText: '',
    sort: WatchlistSort.EVENT_TIMESTAMP_DESC,
    watchlistFilter: []
})
const lastSortMode: any = ref(WatchlistSort.EVENT_TIMESTAMP_DESC)
const totalPages = computed(() => {
    return Math.ceil(watchlistStore.elementCount / search.pageSize)
})
const currentPage = ref(1)

watch(search, (newVal) => {
    if (newVal.sort !== lastSortMode.value) {
        console.log('sorting changed')
        lastSortMode.value = newVal.sort
        search.pageStartIndex = 0
    } else {
        console.log('sorting not changed')
    }
})

const sortOptions = [
    {
        code: WatchlistSort.CREATION_TIMESTAMP_DESC as string,
        label: t('shared.sort.creationTimeDesc'),
    },
    {
        code: WatchlistSort.CREATION_TIMESTAMP_ASC as string,
        label: t('shared.sort.creationTimeAsc'),
    },
    {
        code: WatchlistSort.EVENT_TIMESTAMP_DESC as string,
        label: t('shared.sort.lastModificationTimeDesc'),
    },
    {
        code: WatchlistSort.EVENT_TIMESTAMP_ASC as string,
        label: t('shared.sort.lastModificationTimeAsc'),
    },
    {
        code: WatchlistSort.BUSINESS_NAME_ASC as string,
        label: t('shared.sort.alphabeticAsc'),
    },
    {
        code: WatchlistSort.BUSINESS_NAME_DESC as string,
        label: t('shared.sort.alphabeticDesc'),
    },
]

const filterOptions = [
    {
        code: WatchlistFilter.ALL as string,
        label: t('watchlist.listfilter.observationType.ALL'),
    },
    {
        code: WatchlistFilter.SUPPLEMENT as string,
        label: t('watchlist.listfilter.observationType.SUPPLEMENT'),
    },
    {
        code: WatchlistFilter.MONITORING as string,
        label: t('watchlist.listfilter.observationType.MONITORING'),
    },
    {
        code: WatchlistFilter.SIGNAL as string,
        label: t('watchlist.listfilter.observationType.SIGNAL'),
    },
    {
        code: WatchlistFilter.SINCE_LAST_LOGIN as string,
        label: t('watchlist.listfilter.observationType.SINCE_LAST_LOGIN'),
    }
]

// TODO: Labels are not correct
const selectedFilterOption = computed(() => {
    return filterOptions.find((option) => {
        return option.code === filterType.value
    })
})

const activeFilter = computed(() => {
    return filterType.value !== 'ALL' || filterEndSoon.value || filterExpired.value || search.searchText.length >= cbraSettingsStore.searchConfiguration.characterThreshold
})

const emptyWatchlist = computed(() => {
    return watchlistStore.elementCount === 0 && !activeFilter.value && !search.searchText.length
})
 
const autoSubmit = debounce(async() => {
    if (search.searchText.length === 0 || search.searchText.length >= cbraSettingsStore.searchConfiguration.characterThreshold) {
        handleSubmit()
    }
}, cbraSettingsStore.searchConfiguration.typingTimeout)

function filterChange() {
    search.watchlistFilter = []
    if (filterType.value !== 'ALL') {
        search.watchlistFilter.push(filterType.value)
    }
    if (filterEndSoon.value) {
        search.watchlistFilter.push(WatchlistFilter.EXPIRING_OBSERVATIONS)
    }
    if (filterExpired.value) {
        search.watchlistFilter.push(WatchlistFilter.EXPIRED_OBSERVATIONS)
    }
    handleSubmit()
}

function resetFilter() {
    filterType.value = 'ALL'
    filterEndSoon.value = false
    filterExpired.value = false
    filterChange()
}

async function handleSubmit() {
    if (hasWordSeperator() || !hasReachedCharacterThreshold()) {
        console.log('hasWordSeperator() || hasReachedCharacterThreshold()', hasWordSeperator(), !hasReachedCharacterThreshold())
        return
    }
    loading.value = true
    await watchlistStore.fetch(search)
    loading.value = false
    saveSettings()
}

function changePage(newPage: number) {
    search.pageStartIndex = newPage-1
    handleSubmit()
    scrollTo('#watchlist-search-wrapper')
}

function selectRange(range: number) {
    search.pageSize = range
    search.pageStartIndex = 0
    currentPage.value = 1
    handleSubmit()
    scrollTo('#watchlist-search-wrapper')
}

function hasWordSeperator() {
    return cbraSettingsStore.searchConfiguration.wordSeparatorsList.includes(search.searchText.slice(-1))
}

function hasReachedCharacterThreshold() {
    return search.searchText.length === 0 || search.searchText.length >= cbraSettingsStore.searchConfiguration.characterThreshold
}

function saveSettings() {
    try {
        let searchCookie = <WatchListCookie>{
            sortMode: search.sort,
            pagerPageSize: search.pageSize,
            filterPreference: false,
            filterModes: {
                observationType: filterType.value !== 'ALL' ? filterType.value : '',
                expiringObservations: filterEndSoon.value ? filterEndSoon.value : null,
                expiredObservations: filterExpired.value ? filterExpired.value : null,
            }
        }

        if (filterPreference.value === true && userPreferences.value) {
            let userPrefs = userPreferences.value
            userPrefs.watchlistFilter = JSON.stringify(searchCookie)
            updateCbraUserPreferences(userPrefs)
        }

        const cookieValue = JSON.stringify(searchCookie)
        setCookie(COOKIE_NAME, cookieValue)
    } catch (error) {
        console.error(error)
    }
}

function loadSearchCookie() {
    try {
        const cookie = document.cookie.split(';').find((cookie) => {
            return cookie.trim().startsWith(`${COOKIE_NAME}=`)
        })
        if (cookie) {
            const cookieValue = cookie.split('=')[1]
            const cookieSearch = JSON.parse(cookieValue) as WatchListCookie
            search.pageSize = cookieSearch.pagerPageSize
            search.sort = cookieSearch.sortMode
            if (cookieSearch.filterModes) {
                filterType.value = cookieSearch.filterModes.observationType ? cookieSearch.filterModes.observationType : 'ALL'
                filterEndSoon.value = !!cookieSearch.filterModes.expiringObservations
                filterExpired.value = !!cookieSearch.filterModes.expiredObservations
            }
            if (cookieSearch.filterPreference) {
                filterPreference.value = cookieSearch.filterPreference
            }
        }
    } catch (error) {
        console.error(error)
    }
    filterChange()
}

if (cbraSettingsStore.searchConfiguration.id === 0) {
    cbraSettingsStore.getCbraSearchConfiguration()
}

onMounted(async() => {
    const { data } = await getCbraUserPreferences()
    userPreferences.value = data
    setCookieIfNeeded()
    if (userPreferences.value?.filterPreference) {
        filterPreference.value = userPreferences.value.filterPreference
    }
    loadSearchCookie()
})

function setCookieIfNeeded() {
    let userPrefs = userPreferences.value
    let _cookie = Cookies.get(COOKIE_NAME)

    if (!_cookie && userPrefs?.filterPreference === true && !!userPrefs.watchlistFilter) {
        let _cookieData = userPrefs.watchlistFilter
        setCookie(COOKIE_NAME, _cookieData)
    }
}

</script>

<template>
    <div
        id="watchlist-search-wrapper"
        class="container mb"
    >
        <div
            v-if="watchlistStore.elementCount === 0 && !loading"
            class="row"
        >
            <div
                class="col-12 col-md-3"
                style="padding-bottom: 2rem;"
            >
                <img
                    alt="Watchlist"
                    src="@/assets/img/empty-watchlist.png"
                    width="121"
                    height="121"
                >
            </div>
            <div
                v-if="emptyWatchlist && !search.searchText.length"
                class="col-12 col-md-9"
            >
                <h2>{{ t('watchlist.list.empty.header') }}</h2>
                <p>{{ t('watchlist.list.empty.text') }}</p>
            </div>
            <div
                v-else
                class="col-12 col-md-9"
            >
                <h2>{{ t('watchlist.list.not.found.header') }}</h2>
                <p>{{ t('watchlist.list.not.found.text') }}</p>
            </div>
        </div>
        <div
            class="row"
        >
            <div
                v-if="!emptyWatchlist"
                class="col-12"
            >
                <form
                    id="watchlist-search-form"
                    @submit.prevent="handleSubmit"
                >
                    <div class="row">
                        <div class="col-12 col-md-9 search-input-group">
                            <InputText
                                v-model="search.searchText"
                                label=""
                                :placeholder="$t('watchlist.list.search.placeholder')"
                                name="query"
                                data-qa="watchlist-searchText"
                                :no-valid-class="true"
                                @input="autoSubmit"
                            />
                            <button
                                type="submit"
                                data-qa="watchlist-searchButton"
                                class="btn btn-default btn-min"
                            >
                                <i
                                    aria-hidden="true"
                                    class="crefo-ui-icon icon-magnifier"
                                />
                            </button>
                        </div>
                        <div class="col-12 col-md-3">
                            <Select
                                id="watchlist-sort-select"
                                v-model="search.sort"
                                data-qa="watchlist-sortMode"
                                :options="sortOptions"
                                @update:model-value="handleSubmit"
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div
                v-else-if="!loading"
                class="col-12"
            >
                <CompanySearch />
            </div>
        </div>
    </div>
    <div
        v-if="!emptyWatchlist"
        class="anchor-container"
    >
        <div class="container">
            <nav>
                <a
                    href="#"
                    data-qa="watchlist-tab-header"
                    @click.prevent="void(0)"
                >
                    <span>{{ t('watchlist.list.entries_count') }}</span>&nbsp;
                    <span
                        v-show="watchlistStore.elementCount > 0"
                        data-qa="watchlist-tab-count"
                    >({{ watchlistStore.elementCount }})</span>
                </a>
            </nav>
        </div>
    </div>
    <div
        v-if="!emptyWatchlist"
        class="bg-brand-light-grey pt-small"
    >
        <div class="container">
            <div class="box bg-white is-filter-box">
                <div class="row">
                    <div class="col-12">
                        <h4>{{ t('watchlist.listfilter.headline') }}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <Select
                            id="watchlist-sort-select"
                            v-model="filterType"
                            :label="$t('watchlist.listfilter.label.observationType')"
                            :options="filterOptions"
                            data-qa="listfilter_selectedObservationType"
                            @update:model-value="filterChange"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <div
                            class="row"
                            style="height:100%"
                        >
                            <div class="col-12 col-md-6 align-flex-end">
                                <Checkbox
                                    id="listfilter_selectedObservationEndsSoon"
                                    v-model="filterEndSoon"
                                    name="filterEndSoon"
                                    :label="$t('watchlist.listfilter.label.selectedObservationEndsSoon')"
                                    data-qa="listfilter_selectedObservationEndsSoon"
                                    @change="filterExpired = false; filterChange()"
                                />
                            </div>
                            <div class="col-12 col-md-6 align-flex-end">
                                <Checkbox
                                    id="listfilter_selectedObservationExpired"
                                    v-model="filterExpired"
                                    name="filterExpired"
                                    :label="$t('watchlist.listfilter.label.selectedObservationExpired')"
                                    data-qa="listfilter_selectedObservationExpired"
                                    @change="filterEndSoon = false; filterChange()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button
                            v-if="selectedFilterOption?.code !== 'ALL'"
                            type="button"
                            class="btn btn-secondary btn-default btn-min btn-small mr"
                            :data-qa="`listfilter_reset_observationType`"
                            @click="filterType = 'ALL'; filterChange();"
                        >
                            {{ selectedFilterOption?.label }}
                            <font-awesome-icon icon="fa-light fa-xmark" />
                        </button>
                        <button
                            v-if="filterEndSoon"
                            type="button"
                            class="btn btn-secondary btn-default btn-min btn-small"
                            data-qa="listfilter_reset_expiringObservations"
                            @click="filterEndSoon = false; filterChange();"
                        >
                            {{ $t('watchlist.listfilter.observationEndsSoon.reset') }}
                            <font-awesome-icon icon="fa-light fa-xmark" />
                        </button>
                        <button
                            v-if="filterExpired"
                            type="button"
                            class="btn btn-secondary btn-default btn-min btn-small"
                            data-qa="listfilter_reset_expiredObservations"
                            @click="filterExpired = false; filterChange();"
                        >
                            {{ $t('watchlist.listfilter.observationExpired.reset') }}
                            <font-awesome-icon icon="fa-light fa-xmark" />
                        </button>
                        <button
                            v-if="selectedFilterOption?.code !== 'ALL' || filterEndSoon || filterExpired"
                            type="button"
                            class="btn btn-default btn-min btn-small fa-pull-right"
                            data-qa="listfilter_reset_button"
                            @click="resetFilter"
                        >
                            {{ $t('watchlist.listfilter.label.reset.button') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        v-if="loading"
        class="bg-brand-light-grey box-spacing"
    >
        <div class="container ">
            <div class="row">
                <div class="col-12">
                    <LoadingRing />
                </div>
            </div>
        </div>
    </div>
    <slot />
    <div
        v-if="!emptyWatchlist && watchlistStore.elementCount === 0 && !loading"
        class="bg-brand-light-grey box-spacing"
    >
        <div class="container ">
            <div class="row">
                <div
                    class="col-12 text-align-center"
                    data-qa="noSearchResult"
                >
                    <strong>{{ t('watchlist.list.search.noresults') }}</strong>
                    <br>
                    <span>{{ t('watchlist.list.search.noresults.description') }}</span>
                </div>
            </div>
        </div>
    </div>
    <div
        v-if="!emptyWatchlist && watchlistStore.elementCount > 0"
        class="container"
    >
        <div class="row">
            <div class="col-12 mb">
                <div
                    class="pagination-wrapper"
                >
                    <Paginate
                        :id="'overview-paginate'"
                        v-model="currentPage"
                        :click-handler="changePage"
                        :page-count="totalPages"
                        class="pagination"
                    >
                        <template #prevBtnText>
                            <i
                                :id="'prev-page'"
                                class="crefo-ui-icon icon-arrow-prev"
                                data-qa="pager-pagingPrev-desktop"
                            />
                        </template>
                        <template #nextBtnText>
                            <i
                                :id="'next-page'"
                                class="crefo-ui-icon icon-arrow-next"
                                data-qa="pager-pagingNext-desktop"
                            />
                        </template>
                    </Paginate>
                </div>
                <SimpleDropdown
                    v-model="search.pageSize"
                    class="range-dropdown"
                    data-qa="pager-selectPageSize"
                    @select-option="selectRange"
                />
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.is-filter-box {
    margin: 10px 0 0;
    padding: 15px;

    h4 {
        margin-bottom: 15px;
        font-size: 1.1rem;
    }
}
.search-input-group {
    .crefo-input {
        width: calc(100% - 56px);
        float: left;
    }
}

.align-flex-end {
    align-self: flex-end;
}

.anchor-container {
    border-bottom: 1px solid var(--color-c-linegrey);
    margin-bottom: 0;

    nav {
        display: flex;
        flex-wrap: nowrap;

        a {
            padding: 0 7px 5px 7px;
            font-weight: bold;
            border-bottom: 5px solid var(--color-c-p1);
            cursor: default;
        }
    }
}
</style>
