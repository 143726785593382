import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getDasboardConfig, saveDashboardConfig } from '@/services'
import { CbraDashboardResponse, CbraDashboardSavePayload } from '@/types/cbra/dashboard'
import { isAxiosError } from 'axios'

export const useDashboardStore = defineStore('dashboard', () => {
    const dashboardConfig = ref<CbraDashboardResponse>()
    const isLoading = ref(false)
    const error = ref<string | null>(null)

    async function fetch() {
        error.value = null
        isLoading.value = true
        try {
            const { data } = await getDasboardConfig()
            dashboardConfig.value = data
            isLoading.value = false
        } catch (err) {
            isLoading.value = false
            let message = ''
            if (isAxiosError(err) && err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }
    }

    async function save(payload?: CbraDashboardSavePayload) {
        error.value = null
        isLoading.value = true
        await saveDashboardConfig(payload)
        isLoading.value = false
    }

    return {
        dashboardConfig,
        isLoading,
        fetch,
        save,
    }
})
