<template>
    <div
        v-if="userStore.authenticated"
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>{{ $t("wkv.breadcrumb") }}</li>
        </Breadcrumb>

        <div
            class="wkv"
        >
            <Hero
                :image="heroImage"
                :mobile-image="heroImageMobile"
            >
                <div class="row mb-large">
                    <div class="col-12 col-md-12 col-xl-12">
                        <div class="new-badge ">
                            NEU
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-xl-12">
                        <img
                            src="@/assets/img/wkv/wkv_order_crefoeva_logo.svg"
                            width="370"
                        >
                    </div>
                    <div class="col-12 col-md-12 col-xl-12">
                        <img
                            src="@/assets/img/wkv/wkv_order_claim.svg"
                            width="460"
                        >
                    </div>
                </div>
            </Hero>

            <div
                class="bg-brand-light-grey big-box-spacing"
            >
                <LoadingRing v-if="showLoadingSpinner" />

                <template v-if="showAnswersSection">
                    <div 
                        v-if="!showLoadingSpinner && showAnswersSection"
                    >
                        <div class="row">
                            <div class="col-12">
                                <h2 class="text-align--center">
                                    {{ $t('wkv.reminder.headline') }}
                                </h2>
                                <h4 class="text-align--center">
                                    {{ $t('wkv.reminder.subheadline') }}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 offset-sm-3 no-padding-sm">
                                <div class="box-spacing bg-white">
                                    <div class="row mb-small">
                                        <div class="col-12 text-left text-sm-left text-xs-left">
                                            <span>{{ $t("wkv.order.customer") }}</span>
                                        </div>
                                        <div class="col-12">
                                            <span
                                                class="text-brand-darkest-blue text-bold text-big"
                                                data-qa="wkv-response-debtor-name"
                                            >
                                                {{ givenName }}
                                            </span> 
                                            <span
                                                class="text-brand-darkest-blue text-bold text-big"
                                                data-qa="wkv-response-debtor-city"
                                            >
                                                {{ givenCity }}
                                            </span>
                                        </div>	
                                    </div>
                                    <div class="row mb-small">
                                        <div class="col-12 text-left text-sm-left text-xs-left">
                                            <span>{{ $t('wkv.insurance.complete.ordernumber') }}</span>
                                        </div>
                                        <div class="col-12">
                                            <span
                                                class="text-brand-darkest-blue text-bold text-big"
                                                data-qa="wkv-response-order-orderNo"
                                            >
                                                {{ orderNr }}
                                            </span> 
                                        </div>			
                                    </div>
                                    <div class="row mb-small">
                                        <div class="col-12">
                                            <span>
                                                {{ $t("wkv.order.insurance.coverage.choose_short") }}
                                            </span>
                                        </div>
                                        <div class="col-12">
                                            <span
                                                class="text-brand-darkest-blue text-bold text-big"
                                                data-qa="wkv-response-order-insuranceSum"
                                            >

                                                {{ coverageAmountText }}
                                            </span> 
                                        </div>			
                                    </div>
                                    <div class="row vspace--1">
                                        <div class="col-12 text-left text-sm-left text-xs-left">
                                            <span>{{ $t('wkv.order.insurance.timeslot') }}</span>
                                        </div>
                                        <div class="col-12">
                                            <span
                                                class="text-brand-darkest-blue text-bold text-big"
                                                data-qa="wkv-response-order-insuranceTime"
                                            >

                                                {{ coverageTimeText }}
                                            </span> 
                                        </div>			
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 offset-sm-3 mt">
                                <div class="form-group">
                                    <Radio
                                        id="wkvReminderAnswerChecke"
                                        v-model="wkvReminderAnswerCheck"
                                        radio-group-name="wkvReminderAnswerCheck"
                                        :radio-options="wkvReminderAnswerOptions()"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-align--center mt">
                                <button
                                    class="btn btn-default"
                                    data-qa="wkv-response-button"
                                    name="wkv_reminder_button"
                                    :disabled="isSaving"
                                    @click="onSave"
                                >
                                    {{ $t('wkv.reminder.submit') }}
                                </button>
                            </div>	
                        </div>
                    </div>
                </template>

                <template v-if="showNoteSection">
                    <div v-if="!showLoadingSpinner && showNoteSection">
                        <div class="row pb-5">
                            <div class="col-12 col-sm-6 offset-sm-3">
                                <h2
                                    class="text-align--center"
                                    data-qa="wkv-response-already-answered-title"
                                >
                                    {{ $t('wkv.reminder.showNoteSection.headline') }}
                                </h2>
                                <h4
                                    class="text-align--center "
                                    data-qa="wkv-response-already-answered-text"
                                >
                                    {{ $t('wkv.reminder.showNoteSection.subheadline') }}
                                </h4>
                            </div>
                        </div>
                        <div class="row pb-5">
                            <div class="col-12 text-align--center">
                                <a
                                    class="btn btn-default"
                                    data-qa="wkv-response-already-answered-button"
                                    href="/"
                                > {{ $t('wkv.reminder.showAnswer1Confirmation.button') }} </a>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="showAnswer1ConfirmationSection">
                    <div v-if="!showLoadingSpinner && showAnswer1ConfirmationSection">
                        <div class="row mb">
                            <div class="col-12 col-sm-8 offset-sm-2">
                                <h2
                                    class="text-align--center"
                                    data-qa="wkv-response-feedback-title"
                                >
                                    {{ $t("wkv.reminder.showAnswer1Confirmation.headline") }}
                                </h2>
                                <h4
                                    class="text-align--center"
                                    data-qa="wkv-response-feedback-text"
                                >
                                    {{ $t("wkv.reminder.showAnswer1Confirmation.subheadline") }}
                                </h4>
                            </div>
                        </div>
                        <div class="row mb">
                            <div class="col-12 text-align--center">
                                <a
                                    class="btn btn-default"
                                    data-qa="wkv-response-feedback-button"
                                    href="/"
                                >
                                    {{ $t("wkv.reminder.showAnswer1Confirmation.button") }}
                                </a>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="showAnswer2ConfirmationSection">
                    <div v-if="!showLoadingSpinner && showAnswer2ConfirmationSection">
                        <div class="row mb">
                            <div class="col-12 col-sm-6 offset-sm-3">
                                <h2
                                    class="text-align--center"
                                    data-qa="wkv-response-feedback-inkasso-title"
                                >
                                    {{ $t("wkv.reminder.showAnswer2Confirmation.headline") }}
                                </h2>
                                <h4
                                    class="text-align--center"
                                    data-qa="wkv-response-feedback-inkasso-text"
                                >
                                    {{ $t("wkv.reminder.showAnswer2Confirmation.subheadline") }}
                                </h4>
                            </div>
                        </div>
                        <div class="row mb">
                            <div class="col-12 text-align--center">
                                <a
                                    class="btn btn-default wkv-reminder__button"
                                    data-qa="wkv-response-feedback-inkasso-button"
                                    :href="inkassoUrl"
                                >
                                    {{ $t('wkv.reminder.showAnswer2Confirmation.button') }}</a>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <Modal
        v-if="modal"
        :id="modal.id"
        :title="modal.title"
        :confirm-text="modal.confirmText"
        :icon="modal.icon"
        :data-qa="modal.dataQa"
        @on-confirm="modal = undefined"
        @on-close="modal = undefined"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="modal.content" />
    </Modal>
</template>

<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useUserStore } from '@/stores/user'
import heroImage from '@/assets/img/wkv/landingpage-desktop.jpg'
import heroImageMobile from '@/assets/img/wkv/landingpage-mobil.jpg'
import Hero from '@/components/Hero.vue'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import LoadingRing from '@/components/LoadingRing.vue'
import { useI18n } from 'vue-i18n'
import { CbraWKVOrder } from '@/types/cbra/WKV'
import { CbraTransaction } from '@/types/cbra/transactions'
import { getLatestWkvFromCbra } from '@/services'
import { getCbraTransaction, getAnswerFromCbra } from '@/services'
import { formatDate } from '@/filters'
import Radio from '@/components/Input/Radio.vue'
import { scrollTo } from '@/helper/scrollto'
import { sendAnswerToCbra } from '@/services'
import { ModalProps } from '@/types/components/modal'
import { getErrorMessages } from '@/helper/services/error'
import Modal from '@/components/Modal.vue'

const id = 'portal.mc.wkv-feedback'
const userStore = useUserStore()
const route = useRoute()
const { t } = useI18n()

onMounted(async() => {
    await checkAnswers()
})

const showLoadingSpinner = ref(true)
const isSaving = ref(false)
const wkvOrderId = route.query?.orderNo?.toString()
const transactionId = route.query?.transactionId?.toString()
        
const modal = ref<ModalProps>()
const showAnswersSection = ref(false)
const showAnswer1ConfirmationSection = ref(false)
const showAnswer2ConfirmationSection =ref(false)
const showNoteSection = ref(false)
const givenName = ref()
const givenCity = ref()
const orderNr = ref('')

const insuranceBegin: any = ref(null)
const insuranceEnd: any = ref(null)

const wkvReminderAnswerCheck = ref('ANSWER_1')
const wkvOrder = ref<CbraWKVOrder>()

const coverageAmountText = computed(() => {
    return wkvOrder.value?.orderRequest.insuranceSum !== '' ? t('wkv.order.insurance.coverage.' + wkvOrder.value?.orderRequest.insuranceSum) : ''
})

const coverageTimeText = computed(() => {
    return (insuranceBegin.value && insuranceEnd) ? formatDate(insuranceBegin.value) + ' - ' + formatDate(insuranceEnd.value) : ''
})

const inkassoUrl = `/inkasso/inkassoauftrag-per-upload/?wkvOrderNo=${wkvOrderId}`

function wkvReminderAnswerOptions() {
    return [
        { value: 'ANSWER_1', label: t('wkv.reminder.answer.1'), id: 'answer_1', dataQa: 'wkv-response-positive' },
        { value: 'ANSWER_2', label: t('wkv.reminder.answer.2'), id: 'answer_2', dataQa: 'wkv-response-negative' },
    ]
}

function showAnswers(wkvOrder: CbraWKVOrder, transaction: CbraTransaction) {
    givenName.value = transaction?.reportTransaction?.businessName.trim() + ', '
    givenCity.value = transaction?.reportTransaction?.postalAddress?.city
    orderNr.value = wkvOrder.orderNo
    insuranceBegin.value = wkvOrder.insuranceBegin
    insuranceEnd.value = wkvOrder.insuranceEnd
        
    showLoadingSpinner.value = false
    showAnswersSection.value = true
}

async function checkAnswers() {
    if (!transactionId || !wkvOrderId) return
    try {
        const transactionResponse = await getCbraTransaction(transactionId)
        const transaction = transactionResponse.data
        if (!transaction?.reportTransaction?.productOrderId) return

        const wkvOrdersResponse = await getLatestWkvFromCbra(transaction.reportTransaction.productOrderId)
        const latestWkvOrder = wkvOrdersResponse.data[0]
        wkvOrder.value = latestWkvOrder
        
        const cbraAnswerResponse = await getAnswerFromCbra(wkvOrderId)
        const cbraAnswer = cbraAnswerResponse.data

        if (cbraAnswer !== 'OPEN') {
            showNote()
        } else {
            showAnswers(latestWkvOrder, transaction)
        }
    } catch (err) {
        console.error('checkAnswers: err', err)
        showNote()
    } 
}

function showNote() {
    showLoadingSpinner.value = false
    showNoteSection.value = true
}

async function onSave() {
    if (isSaving.value || !wkvOrderId) {
        return
    }
    isSaving.value = true

    try {
        const answer = (wkvReminderAnswerCheck.value === 'ANSWER_1') ? 'SUCCESSFULL' : 'INKASSO_REQUIRED'
        const cbraAnswer = await sendAnswerToCbra(wkvOrderId, answer)
        clearScreen()
        if (cbraAnswer) {
            if (wkvReminderAnswerCheck.value === 'ANSWER_1') {
                showAnswer1Confirmation() 
            } else {
                showAnswer2Confirmation()
            }                    
            scrollToReminder()
        } else {
            showNote()
        }
    } catch (error) {
        const errorConf = getErrorMessages('wkv.order.notreachable')
        modal.value = {
            id: 'wkv-order-error',
            title: errorConf.headline,
            content: errorConf.description,
            icon: 'warning'
        }
    }
}

function scrollToReminder() {
    scrollTo('wkv__reminder')
}

function showAnswer1Confirmation() {
    showLoadingSpinner.value = false
    showAnswersSection.value = false
    showAnswer1ConfirmationSection.value = true
    showAnswer2ConfirmationSection.value = false
}

function showAnswer2Confirmation() {
    showLoadingSpinner.value = false
    showAnswersSection.value = false
    showAnswer1ConfirmationSection.value = false
    showAnswer2ConfirmationSection.value = true
}

function clearScreen() {
    showLoadingSpinner.value = false
    showAnswersSection.value = false
    showAnswer1ConfirmationSection.value = false
    showAnswer2ConfirmationSection.value = false
}

</script>

<style lang="less" scoped>

.text-big {
    font-size: 1.3rem;
}

</style>
```