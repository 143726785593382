<template>
    <div
        class="col-12 col-md-7 userrights__content-col"
        data-qa="user-rights-form template-userRights"
    >
        <div
            v-if="isLoadingUser"
            class="loading-wrapper"
        >
            <loading-ring />
        </div>
        <div v-else-if="!isLoadingUser && user">
            <div>
                <h3>{{ user.name }}</h3>
            </div>
            <div>
                <div class="row header-row">
                    <p
                        class="col-md-5 text"
                    >
                        {{ $t('UserRights.form.email') }}
                    </p>
                    <p
                        class="col-md left-border text email"
                        data-qa="email selfcare-userdata-email"
                    >
                        {{ user.email }}
                    </p>
                </div>
                <div class="row header-row">
                    <p class="col-md-5 text">
                        {{ $t('UserRights.form.membershipNumber') }}
                    </p>
                    <p
                        class="col-md left-border text"
                    >
                        {{ user.memberUserId }}
                    </p>
                </div>
                <div class="row header-row">
                    <p class="col-md-5 text">
                        {{ $t('UserRights.status.label') }}
                    </p>
                    <div
                        class="col-md left-border"
                    >
                        <span
                            class="status"
                            :class="statusClass"
                            data-qa="status-text"
                        >{{ statusText }}</span>
                    </div>
                </div>
                <div class="row mb-small">
                    <div class="col-md-5" />
                    <div class="col">
                        <button
                            class="btn-small btn-default"
                            data-qa="toggle-person-lock-status-btn"
                            @click="toggleLock()"
                        >
                            {{ blockButtonText }}
                        </button>
                    </div>
                </div>
                <hr class="horizontal-line">
            </div>
            <template v-if="isUserAdministrable">
                <form
                    id="user-rights-form"
                    class="form"
                    @submit.prevent="saveUserRights"
                >
                    <div
                        v-for="(_, userRightLabel) in user.grants"
                        :key="userRightLabel"
                    >
                        <div
                            v-if="userRightLabel !== 'memberstructureNavigation' || isMemberNavigationCheckboxVisible"
                            class="row user-right-row"
                        >
                            <p class="col-6 col-md-5 text">
                                {{ $t(`UserRights.${userRightLabel}`) }}
                            </p>
                            <div class="input-container left-border">
                                <Checkbox
                                    :id="`checkbox-${userRightLabel}`"
                                    v-model="formUserRights[userRightLabel]"
                                    :name="`checkbox-${userRightLabel}`"
                                    class="user-rights-checkbox"
                                    :data-qa="`userrights-checkbox-${checkboxDataQas[userRightLabel]}`"
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-5" />
                    <div class="col">
                        <button
                            type="submit"
                            data-qa="userrights-save-button"
                            form="user-rights-form"
                            class="btn-default"
                            :class="{ disabled: isButtonDisabled }"
                            :disabled="isButtonDisabled"
                        >
                            {{ $t('UserRights.form.button') }}
                        </button>
                    </div>
                </div>
            </template>
            <Modal
                v-if="toggleLockModal"
                :id="toggleLockModal.id"
                :title="toggleLockModal.title"
                :confirm-text="toggleLockModal.confirmText"
                :cancel-text="toggleLockModal.cancelText"
                :icon="toggleLockModal.icon"
                :data-qa="toggleLockModal.dataQa"
                @on-confirm="onToggleLockModalConfirm"
                @on-close="toggleLockModal = undefined"
            >
                <p>{{ toggleLockModal.content }}</p>
            </Modal>
            <Modal
                v-if="toggleLockDeniedForOwnPersonModal"
                :id="toggleLockDeniedForOwnPersonModal.id"
                :title="toggleLockDeniedForOwnPersonModal.title"
                :confirm-text="toggleLockDeniedForOwnPersonModal.confirmText"
                :data-qa="toggleLockDeniedForOwnPersonModal.dataQa"
                @on-confirm="toggleLockDeniedForOwnPersonModal = undefined"
                @on-close="toggleLockDeniedForOwnPersonModal = undefined"
            >
                <p>{{ toggleLockDeniedForOwnPersonModal.content }}</p>
            </Modal>
            <Modal
                v-if="toggleLockDeniedForSuperUserModal"
                :id="toggleLockDeniedForSuperUserModal.id"
                :title="toggleLockDeniedForSuperUserModal.title"
                :confirm-text="toggleLockDeniedForSuperUserModal.confirmText"
                :data-qa="toggleLockDeniedForSuperUserModal.dataQa"
                @on-confirm="toggleLockDeniedForSuperUserModal = undefined"
                @on-close="toggleLockDeniedForSuperUserModal = undefined"
            >
                <p>{{ toggleLockDeniedForSuperUserModal.content }}</p>
            </Modal>

            <Modal
                v-if="errorAlert"
                :id="errorAlert.id"
                :title="errorAlert.title"
                :confirm-text="$t('shared.modals.ok')"
                :error-context="errorAlert.errorContext"
                :icon="errorAlert.icon"
                @on-close="errorAlert = undefined"
                @on-confirm="errorAlert = undefined"
            >
                <p>{{ errorAlert.content }}</p>
            </Modal>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref, watch } from 'vue'
import type { CbraUser } from '@/types/cbra/user'
import { setRoleAssignmentsInUserManagement } from '@/helper/services/userRights'
import { setPersonLockStatusByCrid } from '@/helper/services/user'
import { useI18n } from 'vue-i18n'
import { UserRights } from '@/types/cbra/userRights'
import Checkbox from '@/components/Input/Checkbox.vue'
import Modal from '@/components/Modal.vue'
import type { ModalProps } from '@/types/components/modal'
import LoadingRing from '@/components/LoadingRing.vue'
const { t } = useI18n()

const emit = defineEmits(['showModal'])

const blockButtonText = computed(() => {
    let key = isUserLocked.value ? 'unlockPerson' : 'lockPerson'
    return t('UserRights.form.' + key)
})

let props = defineProps({
    user: {
        type: Object as PropType<CbraUser | null>,
        required: true
    },
    isUserAdministrable: {
        type: Boolean,
        required: true
    },
    isMemberNavigationCheckboxVisible: {
        type: Boolean,
        required: true
    },
    isLoadingUser: {
        type: Boolean,
        default: false
    },
    loadUsers: {
        type: Function,
        required: true
    },
    setIsLoadingUser: {
        type: Function,
        required: true
    }
})

const toggleLockModal = ref<ModalProps>()
const toggleLockDeniedForOwnPersonModal = ref<ModalProps>()
const toggleLockDeniedForSuperUserModal = ref<ModalProps>()
const errorAlert = ref<ModalProps>()

const submitting = ref(false)

/* CHECKBOXES */
let initialFormUserRights = ref(setFormUserRights(props.user))
let formUserRights = ref(setFormUserRights(props.user))
watch(() => props.user, (newUser) => {
    initialFormUserRights.value = setFormUserRights(newUser)
    formUserRights.value = setFormUserRights(newUser)
})
function setFormUserRights(user: CbraUser | null) {
    const formUserRights: UserRights = {
        'report': user?.grants?.report || false,
        'portfolioReport': user?.grants?.portfolioReport || false,
        'debtCollection': user?.grants?.debtCollection || false,
        'businessData': user?.grants?.businessData || false,
        'membershipData': user?.grants?.membershipData || false,
        'memberstructureNavigation': user?.grants?.memberstructureNavigation || false,
    }
    return formUserRights
}
const checkboxDataQas = {
    'report': 'report',
    'portfolioReport': 'portfolioReport',
    'debtCollection': 'inkasso',
    'businessData': 'businessData',
    'membershipData': 'membershipData',
    'memberstructureNavigation': 'memberstructureNavigation',
}

async function onToggleLockModalConfirm() {
    toggleLockModal.value = undefined
    let crid = props.user?.crId || ''
    try {
        props.setIsLoadingUser(true)
        await setPersonLockStatusByCrid(crid, !isUserLocked.value)
        await props.loadUsers(props.user?.crMembernumber)
        props.setIsLoadingUser(false)
    } catch (error) {
        errorAlert.value = {
            // TODO Fehlerhandling optimieren
            id: 'toggleLockError',
            title: 'Fehler',
            content: 'Es ist ein Fehler aufgetreten.',
            icon: 'error',
        }
    }
}

function toggleLock() {
    if (props.user?.crId === window.keycloak.tokenParsed.cr_id) {
        toggleLockDeniedForOwnPersonModal.value = {
            id: 'test',
            title: t('userrights.lockDeniedForOwnPerson.title'),
            content: t('userrights.lockDeniedForOwnPerson.description'),
            confirmText: t('shared.modals.ok'),
            dataQa: 'modal-toggleLock-lockDeniedForOwnPerson',
        }
    } else if (props.user?.roles.includes('CP.BENUTZERVERWALTUNG-MITGLIED')) {
        toggleLockDeniedForSuperUserModal.value = {
            id: 'test',
            title: t('userrights.lockDeniedForSuperUser.title'),
            content: t('userrights.lockDeniedForSuperUser.description'),
            confirmText: t('shared.modals.ok'),
            dataQa: 'modal-toggleLock-lockDeniedForSuperUser',
        }
    } else {
        let key = isUserLocked.value ? 'unlockPerson' : 'lockPerson'
        toggleLockModal.value = {
            id: 'test',
            title: t('userrights.' + key + '.title'),
            content: t('userrights.' + key + '.description'),
            confirmText: t('shared.modals.yes'),
            cancelText: t('shared.cancel'),
            dataQa: 'modal-toggleLock'
        }
    }
}

/* SAVE USERRIGHTS */
async function saveUserRights() {
    let crid = props.user?.crId || ''

    if (!formUserRights.value) {
        emit('showModal', {
            title: t('error.userrights.saveUnauthorized'),
            content: t('error.userrights.saveUnauthorized.description'),
            icon: 'error',
            dataQa: 'userrights-not-granted',
            confirmText: t('shared.modals.ok'),
            cancelText: t('shared.cancel')
        })
        return
    }

    submitting.value = true

    try {
        await setRoleAssignmentsInUserManagement(crid, formUserRights.value)
        submitting.value = false
        initialFormUserRights.value = JSON.parse(JSON.stringify(formUserRights.value))
        emit('showModal', {
            title: t('userrights.save.success.title'),
            content: t('userrights.save.success.description'),
            icon: 'info',
            confirmText: t('shared.modals.ok')
        })

        // this.grants.setAllRights(transformResponse(response))
    } catch (errorModal) {
        emit('showModal', errorModal)
    }
}

let isButtonDisabled = computed(() => {
    return submitting.value || JSON.stringify(formUserRights.value) === JSON.stringify(initialFormUserRights.value)
})

//region STATUS
const isUserLocked = computed(() => {
    return props.user?.locked
})
const statusClass = computed(() => {
    return isUserLocked.value ? 'status-inactive' : 'status-active'
})
const statusText = computed(() => {
    return isUserLocked.value ? t('UserRights.status.locked') : t('UserRights.status.active')
})
//endregion
</script>

<style scoped lang="less">
.header-row {
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }

    .left-border {
        @media screen and (max-width: 767px) {
            border-left: none;
        }
    }
}

.userrights__content-col {
    border-left: 2px solid #eaeaea;
    padding: 1.5rem 2rem 2rem;
}

.left-border {
    border-left: 1px solid #eaeaea;
}

.status {
    max-width: fit-content;
    color: white;
    border-radius: 3px;
    padding: 2px 5px 3px;
    display: inline-block;
    font-weight: bold;
    font-size: 95%;
    &.status-active {
        background: var(--color-c-t4);
    }
    &.status-inactive {
        background: var(--color-c-t2);
    }
}

.user-right-row {
    margin-bottom: 20px;
    overflow: auto;
}

.form {
    margin-top: 35px;
}

.text {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input-container {
    padding-left: 15px;
    align-items: center;
}

.checkbox {
    appearance: none;
    display: grid;
    place-content: center;
    width: 23px !important;
    height: 23px !important;
    -webkit-appearance: none;
    background-color: #fff !important;
    margin: 0 !important;

    border-radius: 5px !important;
    border: 1px solid #d1d1d1 !important;

    &:hover {
        cursor: pointer;
    }

    &::after {
        display: none;
    }

    &:checked {
        &::after {
            display: block;
            content: "\2713";
            /*width: 0.65em;
            height: 0.65em;*/
            width: 100%;
            height: 100%;
            /*background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" viewBox="0 0 24 24"><path d="M9 22l-10-10.598 2.798-2.859 7.149 7.473 13.144-14.016 2.909 2.806z"/></svg>');*/
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            color: var(--color-c-p1);
            /*display: block;
            content:'\2713';
            position: absolute;
            top: -5px;
            width: 5px;
            height: 10px;
            left: 2px;
            color: var(--color-c-p1);*/
        }
    }
}

.horizontal-line {
    border: 0;
    border-top: 1px solid #eee;
}

.user-rights-checkbox :deep(.input-label) {
    &::before, &::after {
        margin-top: -15px;
    }
}

.loading-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
}
</style>
