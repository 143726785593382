<template>
    <div
        v-if="userStore.authenticated"
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>{{ $t('UserRights.breadcrumb') }}</li>
        </Breadcrumb>

        <LoadingOverlay
            v-if="isLoading"
            id="user-administration-loading-overlay"
            class="loading-div"
        />

        <div v-else>
            <div
                class="container mb"
            >
                <div
                    v-if="isMemberNavigationVisible"
                    class="row"
                >
                    <div class="col-12 col-md-6">
                        <MemberNavigation
                            id="user-rights-member-navigation"
                            v-model="selectedMember"
                            :options="membersStore.memberList"
                            @change="selectMember"
                        />
                    </div>
                </div>
                <UserRightsNavigation
                    v-else-if="isUserRightsNavigationVisible"
                    @go-to-mobile-overview="goToMobileOverview"
                    @select-previous-user="selectPreviousUser"
                    @select-next-user="selectNextUser"
                />
            </div>
            <div class="container userrights-container">
                <div class="col-12">
                    <div class="row userrights__content-row">
                        <VerticalTabsList
                            v-if="isVerticalTabsListVisible"
                            :is-loading="isLoading"
                            :show-no-result="showNoResult"
                            :users="shownUsers"
                            @select-user="selectUser"
                        />
                        <UserRightsForm
                            v-if="isUserRightsFormVisible"
                            :user="selectedUser"
                            :is-user-administrable="canUserBeAdministered"
                            :is-member-navigation-checkbox-visible="isMemberNavigationCheckboxVisible"
                            :load-users="loadUsersOfMember"
                            :is-loading-user="isLoadingUser"
                            :set-is-loading-user="setIsLoadingUser"
                            @show-modal="showModal"
                        />
                        <Modal
                            v-if="isModalVisible"
                            :id="modal.dataQa || 'user-administration-modal'"
                            :title="modal.title"
                            :data-qa="modal.dataQa"
                            :confirm-text="modal.confirmText"
                            :icon="modal.icon"
                            :error-message="modal.errorMessage"
                            @on-close="closeModal"
                            @on-confirm="closeModal"
                        >
                            {{ modal.content }}
                        </Modal>
                    </div>
                    <div
                        v-if="isVerticalTabsListVisible"
                        class="row"
                    >
                        <div class="col-12 mb">
                            <div
                                class="pagination-wrapper"
                            >
                                <Paginate
                                    :id="'overview-paginate'"
                                    v-model="pageData.page"
                                    :click-handler="changePagination"
                                    :page-count="totalPages"
                                    class="pagination"
                                >
                                    <template #prevBtnText>
                                        <i
                                            :id="'prev-page'"
                                            class="crefo-ui-icon icon-arrow-prev"
                                            data-qa="pager-pagingPrev-desktop"
                                        />
                                    </template>
                                    <template #nextBtnText>
                                        <i
                                            :id="'next-page'"
                                            class="crefo-ui-icon icon-arrow-next"
                                            data-qa="pager-pagingNext-desktop"
                                        />
                                    </template>
                                </Paginate>
                            </div>
                            <SimpleDropdown
                                v-model="pageData.numberOfShownUsers"
                                class="range-dropdown"
                                data-qa="pager-selectPageSize"
                                @select-option="selectRange"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { useMembersStore } from '@/stores/members'
import Breadcrumb from '@/components/Breadcrumb.vue'
import VerticalTabsList from '@/components/UserAdministration/VerticalTabsList.vue'
import UserRightsForm from '@/components/UserAdministration/UserRightsForm.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import Modal from '@/components/Modal.vue'
import { CbraUser } from '@/types/cbra/user'
import { ROLES, hasUserRole, getUserInfo } from '@/helper/services/user'
import {
    differentContextActive, prepareMemberListForMemberNavigation
} from '@/helper/services/memberNavigation'
import {
    checkSuperUserRole,
    getRoleAssignmentsFromUserManagement,
    getUsersOfMember
} from '@/helper/services/userRights'
import { amIInMemberStructureInSuperUserStructure, isRoleSettable, getOriginalMemberId, getMembersInfoOfCurrentUser, getMemberNavigationSession } from '@/helper/services/memberNavigation'
import { useI18n } from 'vue-i18n'
import MemberNavigation from '@/components/UserAdministration/MemberNavigation.vue'
import { EnhancedCbraMember } from '@/types/cbra/member'
import UserRightsNavigation from '@/components/UserAdministration/UserAdministrationNavigation.vue'
import { ModalProps } from '@/types/components/modal'
import standardAxios from 'axios'
import { showError } from '@/helper/services/error'
import { superUserOverlayNotAuthorized } from '@/helper/webtrekk/superuser'
import { VuePaginate as Paginate } from '@svifty7/vue-paginate'
import SimpleDropdown from '@/components/Input/SimpleDropdown.vue'

//region STORES, I18N, ID
const { t } = useI18n()
const userStore = useUserStore()
const membersStore = useMembersStore()
const id = 'portal.mc.userAdministration'
//endregion

const canUserBeAdministered = ref(true)
const isLoadingUser = ref(false)

//region GET USERS
const users = ref<Array<CbraUser> | undefined>([])
let hasUsers = computed(() => {
    const usersArrLength = users.value?.length || 0
    return usersArrLength > 0
})
function sortUsersByGivenName() {
    let sortedUsers = users.value?.sort((x, y) => {
        return ((x.givenName == y.givenName) ? 0 : ((x.givenName.toLowerCase()> y.givenName.toLowerCase()) ? 1 : -1 ))
    })
    users.value = sortedUsers || []
}
async function getUsers() {
    const userInfo: UserData = await getUserInfo()
    try {
        const isRoleSettableBool = await isRoleSettable()
        await loadContent(isRoleSettableBool || false, userInfo)
    } catch (error) {
        if (standardAxios.isAxiosError(error)) {
            if (error.response?.status !== 403) {
                showError({ topic: 'UserRights', func: 'isRoleSetable', xhr: error })
            } else {
                try {
                    const response: boolean = await amIInMemberStructureInSuperUserStructure(userInfo) || false
                    await loadContent(response, userInfo)
                } catch (error) {
                    if (standardAxios.isAxiosError(error)) {
                        showError({ topic: 'UserRights', func: 'amIInMemberStructureInSuperUserStructure', xhr: error })
                        await loadContent(false, userInfo)
                    }
                }
            }
        }
    }
}

async function loadUsersOfMember(memberId: string|null) {
    const response = await getUsersOfMember(memberId || '')
    if (Array.isArray(response)) {
        users.value = response
        sortUsersByGivenName()
        const updatedSelectedUser: CbraUser | undefined = response.find((user: CbraUser) => user.crId === selectedUser.value?.crId)
        await selectUser(updatedSelectedUser??null)
    } else {
        modal.value = response
    }
}

async function loadContent(isRoleSettable: boolean, userInfo: UserData) {
    isMemberNavigationCheckboxVisible.value = isRoleSettable || !isRoleSettable && callFromSUMainMember.value

    isLoading.value = true

    let memberId = selectedMember.value?.memberId || userInfo.cr_membernumber || null
    if (differentContextActive()) {
        memberId = getMemberNavigationSession()
    }

    await loadUsersOfMember(memberId)
    onUsersLoaded()
}

function onUsersLoaded() {
    if (hasUsers.value) {
        sortUsersByGivenName()
        changePage({ ...defaultChangePageData })
        isLoading.value = false
    // superUsersCount.value = users.value?.length || 0
    } else {
        users.value = []
        selectUser(null)
        isLoading.value = false
    }

    showNoResult.value = !hasUsers.value
}
//endregion

//region SELECT(ED) USER
let selectedUser = ref<CbraUser | null>(null)
function isModalProps(modal: any): modal is ModalProps {
    return (typeof modal.id === 'string' && typeof modal.title === 'string' && typeof modal.content === 'string')
}
async function selectUser(user: CbraUser | null) {
    isLoadingUser.value = true
    if (!user) {
        hasSelectedMobileUser.value = false
        selectedUser.value = null
        isLoading.value = false
        return
    }

    try {
        await getRoleAssignmentsFromUserManagement(user)
        onGrantsLoaded(user)
        isLoadingUser.value = false
    } catch (error) {
        isLoading.value = false
        isLoadingUser.value = false
        if (isModalProps(error)) {
            isModalVisible.value = true
            modal.value = error
            modal.value.errorMessage = `${t('wkv.order.memberId')}: ${userStore.user.cr_membernumber}`
        }
        onGrantsLoaded(user, true)
    }
}
function selectPreviousUser() {
    if (!users.value) return
    users.value.find((user, index) => {
        if (user.memberUserId === selectedUser.value?.memberUserId && index > 0) {
            const previousUser = users.value ? users.value[index - 1] : null
            selectUser(previousUser)
            return true
        }
    })
}
function selectNextUser() {
    if (!users.value) return
    users.value.find((user, index) => {
        const selectedUserIsNotLastUser = users.value ? index < users.value.length - 1 : false
        if (user.memberUserId === selectedUser.value?.memberUserId && selectedUserIsNotLastUser) {
            const nextUser = users.value ? users.value[index + 1] : null
            selectUser(nextUser)
            return true
        }
    })
}
function onGrantsLoaded(user: CbraUser, loadWithoutSettings: boolean = false) {
    selectedUser.value = user
    hasSelectedMobileUser.value = true
    isLoading.value = false
    canUserBeAdministered.value = !loadWithoutSettings
}
//endregion

//region SHOWN USERS
type PageData = {
    page: number
    indexOfFirstShownUser: number
    numberOfShownUsers: number
}
type ChangePageData = {
    newPage: number
    indexOfFirstShownEntry: number
    numberOfShownEntries: number
}
const defaultPageData: PageData = {
    page: 1,
    indexOfFirstShownUser: 0,
    numberOfShownUsers: 10,
}
const defaultChangePageData: ChangePageData = {
    newPage: defaultPageData.page,
    indexOfFirstShownEntry: defaultPageData.indexOfFirstShownUser,
    numberOfShownEntries: defaultPageData.numberOfShownUsers,
}
const pageData = ref<PageData>({ ...defaultPageData })
function changePage({ newPage, indexOfFirstShownEntry, numberOfShownEntries }: ChangePageData) {
    pageData.value.page = newPage
    pageData.value.indexOfFirstShownUser = indexOfFirstShownEntry
    pageData.value.numberOfShownUsers = numberOfShownEntries
}
function changePagination(newPage: number) {
    const indexOfFirstShownEntry = newPage * pageData.value.numberOfShownUsers - pageData.value.numberOfShownUsers
    const numberOfShownEntries = pageData.value.numberOfShownUsers
    changePage({ indexOfFirstShownEntry, numberOfShownEntries, newPage })
}
function selectRange(range: number) {
    changePage({ ...defaultChangePageData, numberOfShownEntries: range })
}
let shownUsers = computed(() => {
    return users.value?.slice(pageData.value.indexOfFirstShownUser, pageData.value.indexOfFirstShownUser + pageData.value.numberOfShownUsers) || []
})

const totalPages = computed(() => {
    return users.value ? Math.ceil(users.value?.length / pageData.value.numberOfShownUsers) : 0
})
//endregion

//region SELECTED MEMBER & MEMBER NAVIGATION
const initialMember = ref(getOriginalMemberId())
let selectedMember = ref<EnhancedCbraMember | null>(null)
let isMemberListLoaded = ref(false)
let showNoResult = ref(false)
let isMemberNavigationCheckboxVisible = ref(false)
let callFromSUMainMember = ref(false)

function selectMember(member: EnhancedCbraMember | null) {
    const hasSelectedMember = (selectedMember.value !== null)
    const isNewMemberSelected = (member?.memberId !== selectedMember.value?.memberId)
    callFromSUMainMember.value = member?.callFromSUMainMember || false
    if (!isLoading.value && hasSelectedMember && isNewMemberSelected) {
        getUsers()
    }
}
//endregion

const hasSuperUserRole = ref(false)

//region <CHECK PERMISSIONS>
async function checkPermissionForUserAdministration() {
    try {
        hasSuperUserRole.value = await checkSuperUserRole()
        if (hasSuperUserRole.value) {
            await getUsers()
        } else {
            isLoading.value = false

            isModalVisible.value = true
            modal.value = {
                id: 'user-administration-not-authorized',
                title: t('error.userrights.saveUnauthorized'),
                content: t('error.userrights.saveUnauthorized.description'),
                icon: 'error',
                dataQa: 'userrights-not-granted',
                confirmText: t('shared.modals.ok')
            }
        }
    } catch (error) {
        modal.value = {
            id: 'user-administration-not-authorized',
            title: t('error.userrights.saveUnauthorized'),
            content: t('error.userrights.saveUnauthorized.description'),
            icon: 'error',
            dataQa: 'userrights-not-granted',
            confirmText: t('shared.modals.ok')
        }
    }
}
async function checkPermissionForMemberNavigation() {
    const permission = hasUserRole(ROLES.UNTERNEHMENSNAVIGATION)
    const hasUserPermissionForMemberNavigationAndIsActingAsOriginalMember = permission && !differentContextActive()
    if (hasUserPermissionForMemberNavigationAndIsActingAsOriginalMember) {
        const response = await getMembersInfoOfCurrentUser()
        membersStore.cbraMember.memberId = response?.memberId || ''
        if (!initialMember.value) initialMember.value = response?.memberId || ''

        const hasCurrentUserMultipleMembers = response && response.members
        if (hasCurrentUserMultipleMembers) {
            let preparedMembers = prepareMemberListForMemberNavigation(response.members, true)
            if (preparedMembers.length > 1) {
                membersStore.memberList.push(...preparedMembers)
                selectedMember.value = membersStore.memberList.find(member => member.memberId === initialMember.value) || null
                isMemberListLoaded.value = true
            }
        }
    }
}
//endregion

//region MODAL
let isModalVisible = ref(false)
let modal = ref<ModalProps>({
    id: '',
    title: '',
    content: '',
    icon: 'info',
    dataQa: '',
    confirmText: '',
    cancelText: ''
})
function showModal(modalInfo: ModalProps) {
    modal.value = modalInfo
    isModalVisible.value = true
}
function closeModal() {
    superUserOverlayNotAuthorized('saveSettings')
    isModalVisible.value = false
    if (!hasSuperUserRole.value) {
        history.back()
    }
}
//endregion

//region MOBILE
const isMobile = ref(window.innerWidth < 768)
const hasSelectedMobileUser = ref(false)
const isMemberNavigationVisible = computed(() => {
    // TODO: What is userStore.isMember? (comes from old project)
    // userStore.authenticated && userStore.user?.isMember && isMemberListLoaded.value
    return (!isMobile.value || !hasSelectedMobileUser.value) && userStore.authenticated && isMemberListLoaded.value
})
const isUserRightsNavigationVisible = computed(() => {
    return isMobile.value && hasSelectedMobileUser.value
})
const isVerticalTabsListVisible = computed(() => {
    return (!isMobile.value || !hasSelectedMobileUser.value) && hasUsers.value
})
const isUserRightsFormVisible = computed(() => {
    return (!isMobile.value || hasSelectedMobileUser.value) && selectedUser.value !== null
})
function handleResize() {
    isMobile.value = window.innerWidth < 768
}
function goToMobileOverview() {
    hasSelectedMobileUser.value = false
    selectedUser.value = null
}

function setIsLoadingUser(loadingStatus: boolean): void
{
    isLoadingUser.value = loadingStatus
}
//endregion

//region LOAD / ON MOUNTED
let isLoading = ref(true)
window.addEventListener('resize', handleResize)
onMounted(async() => {
    await checkPermissionForUserAdministration()
    await checkPermissionForMemberNavigation()
    // users.value = await membersStore.getUsersOfMember(window.sessionStorage.originalMemberId)
    isLoading.value = false
})
//endregion
</script>

<style scoped lang="less">
.userrights__content-row {
  background-color: white;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.10);
}

.pagination-col {
  padding: 0;
}
</style>
