<template>
    <!-- gesellschafterContentTemplate -->
    <tr>
        <th
            scope="col"
            :colspan="(first ? 5 : 4)"
        >
            <span>{{ eigenschaften.beteiligtenEigenschaft }}</span>
        </th>
    </tr>

    <template 
        v-for="(beteiligter, index) in eigenschaften.beteiligter"
        :key="index"
    >
        <tr v-if="beteiligter.personendaten">
            <StructureTable
                :data="beteiligter.personendaten"
                :colspan="first ? 2 : 1"
                :rowspan="getRowspanForGesellschafterKapitalanlagen(beteiligter.beteiligtenEigenschaftenVerflechtung)"
                :postal-address="postalAddress"
                type="PERSON"
                :is-disabled="isDisabled"
            />
        </tr>
    
        <tr v-if="beteiligter.firmendaten">
            <StructureTable
                :data="beteiligter.firmendaten"
                :colspan="first ? 2 : 1"
                :rowspan="getRowspanForGesellschafterKapitalanlagen(beteiligter.beteiligtenEigenschaftenVerflechtung)"
                :postal-address="postalAddress"
                type="BUSINESS"
                :is-disabled="isDisabled"
            />
        </tr>
  
        <tr v-if="beteiligter.gemeinschaftdaten">
            <StructureTable
                :data="beteiligter.gemeinschaftdaten"
                :colspan="first ? 2 : 1"
                :rowspan="getRowspanForGesellschafterKapitalanlagen(beteiligter.beteiligtenEigenschaftenVerflechtung)"
                :postal-address="postalAddress"
                type="GEMEINSCHAFT"
                :is-disabled="isDisabled"
            />
        </tr>

        <tr v-if="beteiligter.auslandDaten">
            <StructureTable
                :data="beteiligter.auslandDaten"
                :colspan="first ? 2 : 1"
                :rowspan="getRowspanForGesellschafterKapitalanlagen(beteiligter.beteiligtenEigenschaftenVerflechtung)"
                :postal-address="postalAddress"
                type="INTERNATIONAL"
                :is-disabled="isDisabled"
            />
        </tr>

        <template
            v-for="(eigenschaft, eigenschaftIndex) of beteiligter.beteiligtenEigenschaftenVerflechtung?.beteiligtenEigenschaften"
            :key="eigenschaftIndex"
        >
            <GesellschafterContentTemplate
                :eigenschaften="eigenschaft"
                :first="false"
                :postal-address="postalAddress"
                :is-disabled="isDisabled"
            />
        </template>
    </template>

    <tr v-if="eigenschaften.kapitalArt">
        <th
            scope="col"
            :colspan="(first ? 4 : 3)"
            class="text--ellipsis-multiline"
        >
            <span>{{ eigenschaften.kapitalArt }}</span>
        </th>
        <th class="text-align-right">
            <span v-if="eigenschaften.kapitalAnteil">
                {{ currency(Number(eigenschaften.kapitalAnteil)) }}
            </span>
            <span v-if="eigenschaften.kapitalAnteil && eigenschaften.kapitalAnteil === 'unbekannt'">{{ $t('transaction.chapter.shared.UNBEKANNT') }}</span>
        </th>
    </tr>
</template>

<script lang="ts" setup>
import { CbraReportTransactionBeteiligtenEigenschaften, CbraReportTransactionBeteiligtenEigenschaftenVerflechtung } from '@/types/cbra/reports'
import { PropType } from 'vue'
import { CbraPostalAddress } from '@/types/cbra/shared'
import StructureTable from './StructureTable.vue'
import { currency } from '@/filters'

defineProps({
    eigenschaften: {
        type: Object as PropType<CbraReportTransactionBeteiligtenEigenschaften>,
        required: true,
    },
    first: {
        type: Boolean,
        required: true,
    },
    postalAddress: {
        type: Object as PropType<CbraPostalAddress>,
        required: true,
    },
    isDisabled: {
        type: Boolean,
        required: true,
    },
})

function getRowspanForGesellschafterKapitalanlagen(beteiligtenEigenschaftenVerflechtung: CbraReportTransactionBeteiligtenEigenschaftenVerflechtung) {
    let rows = 1
    let mainData: CbraReportTransactionBeteiligtenEigenschaften[] = []
    if (beteiligtenEigenschaftenVerflechtung && beteiligtenEigenschaftenVerflechtung.beteiligtenEigenschaften) {
        if (typeof mainData === 'object') {
            mainData.push(beteiligtenEigenschaftenVerflechtung.beteiligtenEigenschaften)
        } else {
            mainData = beteiligtenEigenschaftenVerflechtung.beteiligtenEigenschaften
        }

        mainData.forEach(function(data) {
            data.forEach(function(data2) {
                rows += data2.beteiligter ? data2.beteiligter.length : 0
                if (data2.beteiligtenEigenschaft) {
                    rows++
                }
                if (data2.kapitalAnteil) {
                    rows++
                }
            })
        })
    }
    return rows
}

</script>