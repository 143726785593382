<template>
    <NotAuthorized
        v-if="!isAuthorized"
        data-qa="widget-inkassoActivity-note-not-authorized"
    />
    <ListWidget
        v-else
        :results="results"
        :widget="props.widget"
        :is-loading="isLoading"
        :sorting-type="search.sort"
        :header-name="$t('dashboard.widget.watchlist.list.header.name')"
        :header-changes="$t('dashboard.widget.watchlist.list.header.changes')"
        :sort-name="$t('dashboard.widget.watchlist.list.header.date')"
        :page-size="search.pageSize"
        :page-start-index="search.pageStartIndex"
        :list-size="listSize"
        @on-sort="onSort"
        @on-previous-page="onPreviousPage"
        @on-next-page="onNextPage"
    />
</template>

<script setup lang="ts">
import { onMounted, PropType, reactive, ref } from 'vue'
import { EnhancedResults, Widget } from '@/types/widget'
import { getCbraActivities } from '@/services'
import { hasUserRole, ROLES } from '@/helper/services/user'
import { CONSTS } from '@/helper/services/transaction'
import axios from 'axios'
import { formatDate } from '@/filters'
import { useI18n } from 'vue-i18n'
import ListWidget from './ListWidget.vue'
import { getDebtorName } from '@/helper/widget'
import { CbraActivity, CbraActivityWrapper } from '@/types/cbra/activity'
import NotAuthorized from '../NotAuthorized.vue'

const props = defineProps({
    widget: {
        type: Object as PropType<Widget>,
        required: true
    },
})

const isAuthorized = ref(true)
const results = ref<EnhancedResults[]>([])
const listSize = ref(0)
const isLoading = ref(true)
const search = reactive({
    pageSize: 3,
    pageStartIndex: 0,
    sort: CONSTS.SORT.CREATION_TIME_DESC,
    activityType: 'COLLECTION',
})

const { t } = useI18n()

onMounted(async() => {
    await handleFetch()
})

async function onSort() {
    if (search.sort === CONSTS.SORT.CREATION_TIME_ASC) {
        search.sort = CONSTS.SORT.CREATION_TIME_DESC
    } else {
        search.sort = CONSTS.SORT.CREATION_TIME_ASC
    }
    await handleFetch()
}

async function handleFetch() {
    if (!hasUserRole(ROLES.INKASSO)) {
        isAuthorized.value = false
        return
    }
    isLoading.value = true
    try {
        const { data } = await getCbraActivities(search)
        isLoading.value = false
        const filtered = data.activities.filter((wrapper) => wrapper.activity && !wrapper.hidden)
        listSize.value = data.sizeActivities
        results.value = filtered.map(enhanceItem)
    } catch (error) {
        console.error(error)
        isLoading.value = false
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            isAuthorized.value = false
        }
    }
}

function generateLinkUrl(transactionId: string) {
    return `${props.widget.link?.url}?transactionId=${transactionId}`
}

function enhanceItem(wrapper: CbraActivityWrapper) {
    const enhanedResult : EnhancedResults = {
        name: getDebtorName(wrapper.activity),
        changeReason: findAndTranslateChangeReason(wrapper.activity),
        date: formatDate(wrapper.activity.creationTime),
        transactionId: wrapper.activity.transactionId,
        link: generateLinkUrl(wrapper.activity.transactionId)
    }
    return enhanedResult
}

function findAndTranslateChangeReason(activity: CbraActivity) {
    if (activity.timelineEntryType) {
        return t(`transaction.collection.timeline.type.${activity.timelineEntryType}`)
    }
    return ''
}

async function onPreviousPage() {
    if (isLoading.value) return
    search.pageStartIndex--
    await handleFetch()
}

async function onNextPage() {
    if (isLoading.value) return
    search.pageStartIndex++
    await handleFetch()
}

</script>