export enum WidgetType {
    data = 'data',
    marketing = 'marketing',
    marketingAlt = 'marketingAlt',
    dynamicData = 'dynamicData',
    // magazine = 'magazine', // remove?
}

export enum DynamicDataType {
    inkasso = 'inkasso',
    favouriteReport = 'favouriteReport',
    favouriteCollection = 'favouriteCollection',
    inkassoActivity = 'inkassoActivity',
    inkassoRealisation = 'inkassoRealisation',
    watchlist = 'watchlist',
    statusMonitoring = 'statusMonitoring',
    expiringMonitoring = 'expiringMonitoring',
    events = 'events',
    supplierinquiry = 'supplierinquiry',
}

export interface Widget {
    id: number
    disabled?: boolean
    title: string
    description: string
    previewImage?: string
    type: WidgetType
    link?: {
        url: string
        target?: string
        text?: string
    }
    listUrl?: string
    
    teaser?: string // marketingAlt

    marketingText?: string
    marketingImage?: string // Marketing (Bilder bitte im 3:2 Format / 16:10 für Marketing2)
    marketingVideo?: string
    // autoplay?: boolean // YouTube Video
    // loop?: boolean // YouTube Video

    dynamicDataType?: DynamicDataType
    emptyText?: string
    emptyLink?: {
        url: string
        target?: string
        text?: string
    }
}

export interface MarketingWidget extends Widget {
    marketingText: string
    marketingMedia: string
}

export type EnhancedResults = {
    name: string
    changeReason?: string
    date?: string
    transactionId?: string
    businessId?: string
    link?: string
}