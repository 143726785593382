<template>
    <span 
        class="crefo-ui-tooltip" 
        :class="positionClass" 
        data-crefo-ui="true"
    >
        <span class="inline-wrapper">
            <slot />
            <span 
                v-if="isVisible" 
                class="crefo-tooltip text-brand-dark-grey"
                :class="wideclass"
            >
                <slot name="content" />
            </span>
        </span>
    </span>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
    isVisible: {
        type: Boolean,
        required: false,
        default: true
    },
    position: {
        type: String,
        required: false,
        default: 'top',
        validator: (value: string) => {
            return ['top', 'right', 'bottom', 'left'].includes(value)
        }
    },
    wide: {
        type: Boolean,
        required: false,
        default: false
    }
})

const positionClass = computed(() => {
    return `tooltip-${props.position}`
})

const wideclass = computed(() => {
    return props.wide ? 'tooltip-wide' : ''
})

</script>

<style lang="less">
/* Don't inherit button styles */
a.btn span.crefo-tooltip {
    line-height: initial;
    text-transform: initial;
    font-weight: initial;
}
</style>

<style scoped lang="less">
.crefo-tooltip,
.crefo-tooltip :deep(p) {
    font-size: 12px !important;
    line-height: 19.2px !important;
    letter-spacing: 0.5px;
    color: var(--color-c-grey2);
}

.tooltip-wide {
    width: 360px !important;
    max-width: 75vw;
}

p:last-of-type {
    margin-bottom: 0;
}
</style>
