<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        v-if="navigatingAsDifferentMember"
        class="container"
    >
        <div
            class="crefo-ui-alert info info-box"
        >
            <div class="row row--flex row--align-items-end">
                <div class="col-12">
                    <div
                        class="infoBoxMessage__data-info"
                        :data-qa="'infoBoxMessage-disabled-' + dataQaName"
                    >
                        <div
                            class="infoBoxMessage__data-info-headline mb-small"
                            :data-qa="'infoBoxMessage-disabled-' + dataQaName + '-headline'"
                        >
                            <p><strong>{{ $t('infoboxmessages.familytreetransaction.headline') }}</strong></p>
                        </div>
                        <div
                            class="infoBoxMessage__data-info-item"
                            :data-qa="'infoBoxMessage-disabled-' + dataQaName + '-text'"
                            v-html="$t('infoboxmessages.familytreetransaction.text')"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { differentContextActive, getReportAct4SbNumberSession } from '@/helper/services/memberNavigation'
import { checkUserRight } from '@/helper/services/userRights'
import { computed } from 'vue'

withDefaults(defineProps<{
    dataQaName?: string
}>(), {
    dataQaName: 'report'
})

const navigatingAsDifferentMember = computed(() => {
    if (differentContextActive() && getReportAct4SbNumberSession()) {
        const report = checkUserRight('report')
        const portfolioReport = checkUserRight('portfolioReport')
        return !(report.grants === true && portfolioReport.grants === true)
    } else if (differentContextActive() && !getReportAct4SbNumberSession()) {
        return true
    } else {
        return false
    }
}) 

</script>