<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        v-if="userStore.authenticated"
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>{{ $t("wkv.breadcrumb") }}</li>
        </Breadcrumb>

        <div class="wkv">
            <Hero
                :image="heroImage"
                :mobile-image="heroImageMobile"
            >
                <div class="row mb-large">
                    <div class="col-12 col-md-12 col-xl-12">
                        <div class="new-badge mb-small">
                            {{ $t('wkv.order.new') }}
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-xl-12">
                        <img
                            src="@/assets/img/wkv/wkv_order_crefoeva_logo.svg"
                            width="370"
                            class="mt mb-small"
                        >
                    </div>
                    <div class="col-12 col-md-12 col-xl-12">
                        <img
                            src="@/assets/img/wkv/wkv_order_claim.svg"
                            width="460"
                        >
                    </div>
                    <div class="col-12 col-md-12 col-xl-12 mb-large">
                        <button
                            data-qa="ce-header-"
                            class="btn btn-default mt"
                            @click.prevent="scrollToCalculator"
                        >
                            <font-awesome-icon
                                icon="fa-regular fa-calculator-simple"
                                size="lg"
                            />
                            {{ $t('wkv.order.btn_text') }}
                        </button>
                    </div>
                    <div class="col-12 col-md-12 col-xl-12 mb-large">
                        <div class="advantages-list">
                            <h3 class="m0">
                                {{ $t('wkv.order.advantageList.headline') }}
                            </h3>
                            <ul class="fa-ul">
                                <li>
                                    <span class="fa-li"><font-awesome-icon
                                        icon="fa-regular fa-check"
                                        color="var(--color-c-p1)"
                                        size="xl"
                                    /></span>
                                    {{ $t('wkv.order.advantageList.1') }}
                                </li>
                                <li>
                                    <span class="fa-li"><font-awesome-icon
                                        icon="fa-regular fa-check"
                                        color="var(--color-c-p1)"
                                        size="xl"
                                    /></span>{{ $t('wkv.order.advantageList.2') }}
                                </li>
                                <li>
                                    <span class="fa-li"><font-awesome-icon
                                        icon="fa-regular fa-check"
                                        color="var(--color-c-p1)"
                                        size="xl"
                                    /></span>{{ $t('wkv.order.advantageList.3') }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Hero>
            <div class="container content-spacer">
                <div class="row">
                    <div class="col-xs-12 col-md-7">
                        <h3>{{ $t('wkv.order.intro.headline') }}</h3>
                        <p>
                            {{ $t('wkv.order.intro.text') }}
                        </p>
                    </div>
                </div>
            </div>
            <Calculator />
            <div class="container content-spacer">
                <div class="row mb">
                    <div class="col-12 text-align--center">
                        <h3>{{ $t('wkv.order.advantages.headline') }}</h3>
                    </div>
                </div>
                <div class="row mb">
                    <div class="col-12 col-md-4">
                        <ul class="fa-ul">
                            <li class="h4">
                                <span class="fa-li"><font-awesome-icon
                                    icon="fa-regular fa-check"
                                    size="lg"
                                /></span>
                                {{ $t('wkv.order.advantages.text.0') }}
                            </li>
                            <li class="h4">
                                <span class="fa-li"><font-awesome-icon
                                    icon="fa-regular fa-check"
                                    size="lg"
                                /></span>
                                {{ $t('wkv.order.advantages.text.1') }}
                            </li>
                            <li class="h4">
                                <span class="fa-li"><font-awesome-icon
                                    icon="fa-regular fa-check"
                                    size="lg"
                                /></span>
                                {{ $t('wkv.order.advantages.text.2') }}
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-4">
                        <ul class="fa-ul">
                            <li class="h4">
                                <span class="fa-li"><font-awesome-icon
                                    icon="fa-regular fa-check"
                                    size="lg"
                                /></span>
                                {{ $t('wkv.order.advantages.text.3') }}
                            </li>
                            <li class="h4">
                                <span class="fa-li"><font-awesome-icon
                                    icon="fa-regular fa-check"
                                    size="lg"
                                /></span>
                                {{ $t('wkv.order.advantages.text.4') }}
                            </li>
                            <li class="h4">
                                <span class="fa-li"><font-awesome-icon
                                    icon="fa-regular fa-check"
                                    size="lg"
                                /></span>
                                {{ $t('wkv.order.advantages.text.5') }}
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-4">
                        <ul class="fa-ul">
                            <li class="h4">
                                <span class="fa-li"><font-awesome-icon
                                    icon="fa-regular fa-check"
                                    size="lg"
                                /></span>
                                {{ $t('wkv.order.advantages.text.6') }}
                            </li>
                            <li class="h4">
                                <span class="fa-li"><font-awesome-icon
                                    icon="fa-regular fa-check"
                                    size="lg"
                                /></span>
                                {{ $t('wkv.order.advantages.text.7') }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <small>{{ $t('wkv.order.advantages.text.notice') }}</small>
                    </div>
                </div>
            </div>
            <div class="content-spacer">
                <div
                    class="bg-brand-light-grey big-box-spacing"
                >
                    <div class="container">
                        <div class="row">
                            <div class="col-12 text-align--center">
                                <h3>{{ $t('wkv.order.security.headline') }}</h3>
                                <h4>{{ $t('wkv.order.security.subheadline') }}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-4 mb">
                                <div class="box-shadow-xy small-box-spacing bg-white d-flex align-items-center gap h-100">
                                    <div>
                                        <h2 class="m0">
                                            01
                                        </h2>
                                        <p>{{ $t('wkv.order.security.text.0') }}</p>
                                    </div>
                                    <i
                                        class="crefo-brand-icon icon-shield icon-5x"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-md-4 mb">
                                <div class="box-shadow-xy small-box-spacing bg-white d-flex align-items-center gap h-100">
                                    <div>
                                        <h1 class="m0">
                                            02
                                        </h1>
                                        <p>{{ $t('wkv.order.security.text.1') }}</p>
                                    </div>
                                    <i
                                        class="crefo-brand-icon icon-crefo-file icon-5x"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-md-4 mb">
                                <div class="box-shadow-xy small-box-spacing bg-white d-flex align-items-center gap h-100">
                                    <div>
                                        <h2 class="m0">
                                            03
                                        </h2>
                                        <p>{{ $t('wkv.order.security.text.2') }}</p>
                                    </div>
                                    <i
                                        class="crefo-brand-icon icon-file-euro icon-5x"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container content-spacer">
                <div class="row mb">
                    <div class="col-12 text-align--center">
                        <h3>{{ $t('wkv.order.detail.headline') }}</h3>
                        <h4>{{ $t('wkv.order.detail.subheadline') }}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <a
                            :href="getFileUrlWKV('Zeitstrahl.jpg')"
                            target="_blank"
                            rel="noopener"
                        >
                            <img
                                src="@/assets/img/wkv/Zeitstrahl.jpg"
                                class="mt mb-small"
                            >
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-align--center">
                        <button
                            data-qa="button-wkv-orderpage-oder-now-bottom"
                            class="btn btn-default mt"
                            @click.prevent="scrollToCalculator"
                        >
                            <font-awesome-icon
                                icon="fa-regular fa-calculator-simple"
                                size="lg"
                            />
                            {{ $t('wkv.order.btn_text') }}
                        </button>
                    </div>
                </div>

                <div class="row content-spacer">
                    <div class="col-12">
                        <Accordion>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.1.title')"
                                :item-key="1"
                            >
                                {{ $t('wkv.order.accordions.1.text') }}
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.2.title')"
                                :item-key="2"
                            >
                                <span v-html="$t('wkv.order.accordions.2.text')" />
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.3.title')"
                                :item-key="3"
                            >
                                {{ $t('wkv.order.accordions.3.text') }}
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.4.title')"
                                :item-key="4"
                            >
                                {{ $t('wkv.order.accordions.4.text') }}
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.5.title')"
                                :item-key="5"
                            >
                                <p v-html="$t('wkv.order.accordions.5.text')" />
                                <br>
                                <strong>{{ $t('wkv.order.accordions.5.list.title') }}</strong>
                                <ul class="list-unordered mb">
                                    <li>{{ $t('wkv.order.accordions.5.list.1') }}</li>
                                    <li>{{ $t('wkv.order.accordions.5.list.2') }}</li>
                                </ul>
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.6.title')"
                                :item-key="6"
                            >
                                <p v-html="$t('wkv.order.accordions.6.text')" />
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.7.title')"
                                :item-key="7"
                            >
                                <p v-html="$t('wkv.order.accordions.7.text')" />
                                <p v-html="$t('wkv.order.accordions.7.text.2')" />
                                <p v-html="$t('wkv.order.accordions.7.text.3')" />
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.8.title')"
                                :item-key="8"
                            >
                                <p v-html="$t('wkv.order.accordions.8.text')" />
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.9.title')"
                                :item-key="9"
                            >
                                <p v-html="$t('wkv.order.accordions.9.text')" />
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.10.title')"
                                :item-key="10"
                            >
                                <p v-html="$t('wkv.order.accordions.10.text')" />
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.11.title')"
                                :item-key="11"
                            >
                                <p v-html="$t('wkv.order.accordions.11.text')" />
                            </AccordionItem>
                            <AccordionItem
                                :title="$t('wkv.order.accordions.12.title')"
                                :item-key="12"
                            >
                                <p v-html="$t('wkv.order.accordions.12.text')" />
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>

                <div class="row content-spacer">
                    <div class="col-xs-12 col-md-4">
                        <h3>{{ $t('wkv.order.partner.headline') }}</h3>
                        <p>{{ $t('wkv.order.partner.text') }}</p>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <a
                            href="https://www.creditreform.de"
                            target="_blank"
                            rel="noopener"
                            title="Creditreform"
                        >
                            <img
                                width="466"
                                src="@/assets/img/wkv/creditreform_logo.svg"
                                class="mt mb"
                            >
                        </a>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <a
                            href="https://www.atradius.com"
                            target="_blank"
                            rel="noopener"
                            title="Atradius"
                        >
                            <img
                                src="@/assets/img/wkv/logo_atradius@2x.png"
                                class="mt mb"
                            >
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang='ts'>
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useUserStore } from '@/stores/user'
import Hero from '@/components/Hero.vue'
import heroImage from '@/assets/img/wkv/landingpage-desktop.jpg'
import heroImageMobile from '@/assets/img/wkv/landingpage-mobil.jpg'
import { scrollTo } from '@/helper/scrollto'
import Calculator from '@/components/WKV/Calculator.vue'
import { getFileUrlWKV } from '@/helper/image'
import Accordion from '@/components/Accordion/Accordion.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'

const userStore = useUserStore()
const id = 'portal.mc.wkv-order'

function scrollToCalculator() {
    scrollTo('#wkv-calculator')
}

</script>

<style scoped lang="less">

:deep(.wkv) {
    .advantages-list {
        background: #e5f3fb;
        padding: 2rem 3.5rem;
        font-size: 1.2rem;    
        line-height: 2;
    }
    .new-badge {
        width: fit-content;
        padding: 5px 10px;
        background-color: #006eb7;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
        font-size: 1.4rem;
    }
    .so-gehts {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        position: absolute;
        top: -95px;
        left: calc(50% - 50px);
        padding: 15px 10px;
        background-color: var(--color-c-p1);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: var(--color-c-white);
        gap: 10px;
    }
}

@media (max-width: 991px) {
    .wkv .so-gehts {
        width: 54px;
        height: 50px;
        top: -50px;
        left: calc(50% - 25px);
    }
}

@media (min-width: 992px) {
    .wkv .advantages-list {
        position: absolute;
        right: 5rem;
        top: -7rem;
        z-index: 2;
    }
  }

  .icon-5x {
    font-size: 5rem;
  }

</style>