<template>
    <div
        v-if="hasMemberView"
        class="container"
        data-qa="dashboard"
    >
        <div class="row">
            <div class="col-12">
                <draggable 
                    v-model="widgets" 
                    group="people" 
                    item-key="id" 
                    ghost-class="ghost"
                    class="widget-wrapper"
                    @start="drag=true"
                    @end="onDragEnd"
                >
                    <template #item="{element: widget}">
                        <div
                            class="widget box-shadow-xy small-box-spacing"
                            data-qa="widgetContainer"
                        >
                            <div
                                class="widget-title"
                                data-qa="widgetTitle"
                            >
                                {{ widgetTitle(widget) }}
                                <div class="col-4 text-align-right">
                                    <Tooltip
                                        position="bottom"
                                        data-qa="widget-contextMenu"
                                    >
                                        <font-awesome-icon
                                            icon="fa-regular fa-ellipsis-vertical"
                                            size="xl"
                                        />
                                        <template #content>
                                            <button
                                                class="widget-button"
                                                @click="handleDeleteWidget(widget)"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-light fa-trash-alt"
                                                    size="xl"
                                                    color="var(--color-c-p1)"
                                                />
                                            </button>
                                        </template>
                                    </Tooltip>
                                </div>
                            </div>
                            <div
                                class="widget-content"
                                data-qa="widget__content"
                            >
                                <Marketing
                                    v-if="widget.type === WidgetType.marketing"
                                    :widget="widget"
                                    data-qa="marketing-widget"
                                />
                                <MarketingAlt
                                    v-if="widget.type === WidgetType.marketingAlt"
                                    :widget="widget"
                                    data-qa="marketing2-widget"
                                />
                                <SupplierInquiry
                                    v-if="widget.dynamicDataType === DynamicDataType.supplierinquiry"
                                    :widget="widget"
                                />
                                <FavouriteCollection
                                    v-if="widget.dynamicDataType === DynamicDataType.favouriteCollection"
                                    :widget="widget"
                                />
                                <InkassoActivity
                                    v-if="widget.dynamicDataType === DynamicDataType.inkassoActivity"
                                    :widget="widget"
                                />
                                <ExpiringMonitoring
                                    v-if="widget.dynamicDataType === DynamicDataType.expiringMonitoring"
                                    :widget="widget"
                                />                  
                                <FavouriteReport
                                    v-if="widget.dynamicDataType === DynamicDataType.favouriteReport"
                                    :widget="widget"
                                />     
                                <Watchlist
                                    v-if="widget.dynamicDataType === DynamicDataType.watchlist"
                                    :widget="widget"
                                />
                                <Inkasso
                                    v-if="widget.dynamicDataType === DynamicDataType.inkasso"
                                    :widget="widget"
                                />
                                <StatusMonitoring
                                    v-if="widget.dynamicDataType === DynamicDataType.statusMonitoring"
                                    :widget="widget"
                                />
                                <InkassoRealisation
                                    v-if="widget.dynamicDataType === DynamicDataType.inkassoRealisation"
                                    :widget="widget"
                                />
                                <EventWidget
                                    v-if="widget.dynamicDataType === DynamicDataType.events"
                                    :widget="widget"
                                />
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <div
                            class="widget box-shadow-xy box-spacing"
                            data-qa="addWidgetButton"
                        >
                            <Add />
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </div>
    <Modal
        v-if="errorAlert"
        :id="errorAlert.id"
        :title="errorAlert.title"
        :content="errorAlert.content"
        :confirm-text="$t('shared.modals.ok')"
        :error-context="errorAlert.errorContext"
        :icon="errorAlert.icon"
        @on-close="errorAlert = undefined"
        @on-confirm="errorAlert = undefined"
    >
        <p>{{ errorAlert.content }}</p>
    </Modal>
</template>

<script setup lang="ts">
import draggable from 'vuedraggable'
import { onMounted, ref, watch } from 'vue'
import { getAllWidgets } from '@/data/widgets'
import { DynamicDataType, Widget, WidgetType } from '@/types/widget'
import Marketing from '@/components/Dashboard/Widgets/Marketing.vue'
import MarketingAlt from '@/components/Dashboard/Widgets/MarketingAlt.vue'
import Add from '@/components/Dashboard/Widgets/Add.vue'
import SupplierInquiry from '@/components/Dashboard/Widgets/SupplierInquiry.vue'
import FavouriteCollection from '@/components/Dashboard/Widgets/List/FavouriteCollection.vue'
import InkassoActivity from '@/components/Dashboard/Widgets/List/InkassoActivity.vue'
import ExpiringMonitoring from '@/components/Dashboard/Widgets/List/ExpiringMonitoring.vue'
import FavouriteReport from '@/components/Dashboard/Widgets/List/FavouriteReport.vue'
import Watchlist from '@/components/Dashboard/Widgets/List/Watchlist.vue'
import EventWidget from '@/components/Dashboard/Widgets/List/EventWidget.vue'
import Inkasso from '@/components/Dashboard/Widgets/Inkasso.vue'
import StatusMonitoring from '@/components/Dashboard/Widgets/Chart/StatusMonitoring.vue'
import InkassoRealisation from '@/components/Dashboard/Widgets/Chart/InkassoRealisation.vue'
import { useDashboardStore } from '@/stores/dashboard'
import { getCbraUserVC } from '@/services'
import { ModalProps } from '@/types/components/modal'
import Modal from '../Modal.vue'
import { useI18n } from 'vue-i18n'
import Tooltip from '../Tooltip.vue'
import { checkMemberView } from '@/helper/services/memberNavigation'
import { useUserStore } from '@/stores/user'

const drag = ref(false)
const { t, locale } = useI18n()
const store = useDashboardStore()
const widgets = ref()
const isLoading = ref(false)
const vc = ref()
const errorAlert = ref<ModalProps>()
const userStore = useUserStore()
const hasMemberView = checkMemberView(userStore.cbraUser.crMembernumber)

onMounted(async() => {
    if (hasMemberView) {
        await handleFetchVC()
        await store.fetch()
        widgets.value = initWidgets()
    }
})

function widgetTitle(widget: Widget) {
    if (widget.dynamicDataType === DynamicDataType.events) {
        return vc.value.name
    } else {
        return t(widget.title)
    }
}

watch(() => locale.value, () => {
    widgets.value = initWidgets()
})

watch(() => store.dashboardConfig, () => {
    widgets.value = initWidgets()
})

function initWidgets() {
    if (!store.dashboardConfig) return []
    const savedWidgets = []
    for (const item of store.dashboardConfig) {
        const savedWidget = getAllWidgets(locale.value).find((widget) => item.externalId === widget.id)
        if (!savedWidget) continue
        savedWidgets.push(savedWidget)
    }
    return savedWidgets
}

async function onDragEnd() {
    if (!widgets.value || !store.dashboardConfig) return
    const payload = []
    for (const widget of widgets.value) {
        const config = store.dashboardConfig.find((item) => item.externalId === widget.id)
        if (!config) continue
        payload.push({
            id: config.id,
            externalId: config.externalId,
            width: 1
        })
    }

    try {
        await store.save(payload)
        await store.fetch()
    } catch (error) {
        errorAlert.value = {
            id: 'error',
            title: t('error.DashboardService.saveDashboardConfig'),
            content: t('error.DashboardService.saveDashboardConfig.description'),
            icon: 'error'
        }
    }
    drag.value = false
}

async function handleFetchVC() {
    isLoading.value = true
    try {
        const { data } = await getCbraUserVC()
        isLoading.value = false
        vc.value = data
    } catch (error) {
        console.error(error)
        isLoading.value = false
    }
}

async function handleDeleteWidget(widget: Widget) {
    if (!widgets.value || !store.dashboardConfig) return
    const payload = []
    const filteredWidgets = widgets.value.filter((w: Widget) => w.id !== widget.id)
    for (const widget of filteredWidgets) {
        const config = store.dashboardConfig.find((item) => item.externalId === widget.id)
        if (!config) continue
        payload.push({
            id: config.id,
            externalId: config.externalId,
            width: 1
        })
    }

    try {
        await store.save(payload)
        await store.fetch()
    } catch (error) {
        errorAlert.value = {
            id: 'error',
            title: t('error.DashboardService.saveDashboardConfig'),
            content: t('error.DashboardService.saveDashboardConfig.description'),
            icon: 'error'
        }
    }
    drag.value = false
}

</script>

<style scoped lang="less">

.widget-wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 25px;
  align-items: stretch;
  grid-auto-rows: 1fr;
}

@media (min-width: 768px) {
    .widget-wrapper  {
        grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1200px) {
    .widget-wrapper  {
        grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.widget {
    position: relative;
    height: 100%;
    background-color: #fff;
    height: 340px;
}

.widget-title {
  font-size: 16px;
  cursor: move;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 40px;
}

.widget-content {
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    div {
          flex-grow: 1;
    }
}

.widget-button {
  all: unset;
  cursor: pointer;
  padding: 0 5px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>