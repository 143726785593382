<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        v-if="navigatingAsDifferentMember"
        class="container"
    >
        <div
            class="crefo-ui-alert info info-box"
        >
            <div class="row row--flex row--align-items-end">
                <div class="col-12">
                    <div
                        class="infoBoxMessage__data-info"
                        :data-qa="'infoBoxMessage-disabled-' + dataQaName "
                    >
                        <div
                            class="infoBoxMessage__data-info-item"
                            :data-qa="'infoBoxMessage-disabled-' + dataQaName + '-text'"
                            v-html="$t('infoboxmessages.report.text')"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { differentContextActive, getReportAct4SbNumberSession } from '@/helper/services/memberNavigation'
import { checkUserRight } from '@/helper/services/userRights'
import { computed } from 'vue'

withDefaults(defineProps<{
    dataQaName?: string
}>(), {
    dataQaName: 'report'
})

const navigatingAsDifferentMember = computed(() => {
    const access = checkUserRight('report')
    if (access.grants && differentContextActive() && !getReportAct4SbNumberSession()) {
        return true
    }
    return false
}) 

</script>