<template>
    <div
        class="dropdown"
    >
        <a
            class="dropdown-toggle"
            href="#"
            :data-qa="dataQa"
            @click.prevent="toggleDropdown"
        >
            <div
                v-if="isDropdownOpen"
                class="dropdown-content"
            >
                <a
                    v-for="(option, index) in options"
                    :key="index"
                    href="#"
                    class="dropdown-option"
                    :data-qa="`${dataQa}-option-${index}`"
                    @click.stop.prevent="selectOption(index)"
                >
                    {{ option.label }}
                </a>
            </div>
            {{ selectedOption?.label }}
            <font-awesome-icon
                :icon="dropdownIcon"
                size="sm"
                class="dropdown-icon"
            />
        </a>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, PropType } from 'vue'

type dropdownOption = {
    label: string
    value: number
}
let props = defineProps({
    modelValue: {
        type: Number,
        default: 10
    },
    dataQa: {
        type: String,
        default: undefined
    },
    initialSelectedOptionIndex: {
        type: Number,
        default: 0
    },
    options: {
        type: Array as PropType<Array<dropdownOption>>,
        default: () => {
            const { t } = useI18n()
            return [
                {
                    label: `10 ${t('SimpleDropdown.entries')}`,
                    value: 10
                },
                {
                    label: `25 ${t('SimpleDropdown.entries')}`,
                    value: 25
                },
                {
                    label: `50 ${t('SimpleDropdown.entries')}`,
                    value: 50
                },
                {
                    label: `100 ${t('SimpleDropdown.entries')}`,
                    value: 100
                },
                {
                    label: `200 ${t('SimpleDropdown.entries')}`,
                    value: 200
                }
            ]
        }
    }
})

/* DROPDOWN TOGGLE */
let isDropdownOpen = ref(false)
function toggleDropdown() {
    isDropdownOpen.value = !isDropdownOpen.value
}
let dropdownIcon = computed(() => {
    return isDropdownOpen.value
        ? 'fa-sharp fa-solid fa-chevron-up'
        : 'fa-sharp fa-solid fa-chevron-down'
})

/* SELECT OPTION */
let selectedOption = ref(props.options.find(option => option.value === props.modelValue))
const emit = defineEmits(['selectOption', 'update:modelValue'])
function selectOption(optionIndex: number) {
    selectedOption.value = props.options[optionIndex]
    toggleDropdown()
    emit('selectOption', props.options[optionIndex].value)
    emit('update:modelValue', props.options[optionIndex].value)
}
</script>

<style scoped lang="less">

.dropdown {
    // position: relative;
    // display: inline-block;
    // margin-right: 0;
    // vertical-align: middle;

    .dropdown-toggle {
        position: relative;
        color: var(--color-c-grey2);
        transition: color .15s ease-in-out;
        text-decoration: none;

        &:hover {
            color: var(--color-c-p1);
        }
    }

    .dropdown-content {
        display: block;
        position: absolute;
        min-width: 150px;
        z-index: 10;
        background: #fff;
        box-shadow: 0 0 10px #00000026;
        font-size: 14px;
        top: 30px;
        left: 0;

        .dropdown-option {
            color: #4c4c4c;
            padding: 10px 15px;
            line-height: 18px;
            font-weight: 400;
            text-decoration: none;
            display: block;
            border-bottom: 1px solid #edece5;
            transition: color .15s ease-in-out;

            &:hover {
                color: #fff;
                background: var(--color-c-p1);
            }
        }
    }
}

</style>
