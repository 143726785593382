<template>
    <div
        v-if="iconIsAvailable"
        class="tooltip-icon pull-right"
    >
        <LoadingOverlay v-if="showLoadingSpinner" />

        <!-- Button Watchlist - add elem -->
        <Tooltip
            v-if="!onWatchlist && watchlistSupported"
            position="top"
            @click.stop.prevent="addWatchlistEntry"
        >
            <div
                class="icon--watchlist-add"
                :data-qa="dataQaAdd"
            >
                <img
                    src="@/assets/img/icons/watchlist-inactive.svg"
                    width="29"
                    height="13"
                    alt=""
                >
            </div>
            <template #content>
                {{ addOnWatchlistText }}
            </template>
        </Tooltip>

        <!-- Button Watchlist - remove elem -->
        <Tooltip
            v-if="onWatchlist"
            position="top"
            @click.stop.prevent="deleteWatchlistEntry"
        >
            <div
                class="icon--watchlist-delete"
                :data-qa="dataQaRemove"
            >
                <img
                    src="@/assets/img/icons/watchlist-active.svg"
                    width="29"
                    height="13"
                    alt=""
                >
            </div>
            <template #content>
                {{ $t('search.removeFromWatchlist') }}
            </template>
        </Tooltip>

        <!-- Button Watchlist - deactivated -->
        <span
            v-if="watchlistSupported && !onWatchlist"
            class="icon--watchlist-unsupported icon icon--watchlist-active watchlist-mousepointer--inactive"
            :data-qa="dataQaDisabled"
        />
        <Modal
            v-if="modal"
            :id="modal.id"
            :title="modal.title"
            :cancel-text="modal.cancelText"
            :confirm-text="modal.confirmText"
            :error-context="modal.errorContext"
            :icon="modal.icon"
            @on-close="modal = undefined"
            @on-confirm="modal = undefined"
        >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="modal.content" />
        </Modal>
    </div>
</template>

<script lang="ts" setup>
/**
 * Shows watchlist icon.
 *
 * Required parameters:
 *      businessId - Either a string, or an observable
 *
 * Optional parameters (when given, both parameters are required):
 *      dataQaAdd - [string] if given, overrides the default dataqa for adding
 *      dataQaRemove - [string] if given, overrides the default dataqa for removing
 *      dataQaDisabled - [string] if given, overrides the default dataqa for disabled state
 *
 * Example:
 * <ko-watchlisticon params="businessId: businessId"></ko-watchlisticon>
 * <ko-watchlisticon params="businessId: '1234567'"></ko-watchlisticon>
 *
 * onWatchlist - is true, if company is on watchlist
 * watchlistSupported -
 *      is true, if company is already on watchlist
 *      is true, when watchlist is supported and company is not inactive
 *
 * Normally there are two ajax requests required:
 *      - /cbra/watchlistentries/validate (checks if on watchlist and if watchlist is supported)
 *      - /cbra/productavailbility (checks if company is not inactive)
 *
 *      If watchlistentries says no watchlist supported, then we skip the productavailbility check.
 *      If company is already on watchlist, we also skip the check initially, and set supported = true to be
 *      able to remove the company from the watchlist. In this case we set watchlistSupportedBecauseOnWatchlist as a
 *      reminder.
 *      To prevent the problem, that the company is already on the watchlist - but is not allowed anymore -
 *      we check the productavailbility as soon as the company is removed from the watchlist. That prevents, that the
 *      company can be readded again.
 */

import { computed, onMounted, ref, watch } from 'vue'
import Tooltip from '../Tooltip.vue'
import { useI18n } from 'vue-i18n'
import { putCbraEntryOnWatchlist, deleteEntryFromWatchlist } from '@/services'
import { differentContextActive } from '@/helper/services/memberNavigation'
import { WatchlistService } from '@/helper/services/watchlist'
import LoadingOverlay from '../LoadingOverlay.vue'
import { storeToRefs } from 'pinia'
import { useProductStore } from '@/stores/product'
import { showError } from '@/helper/services/error'
import { isAxiosError } from 'axios'
import Modal from '../Modal.vue'
import { ModalProps } from '@/types/components/modal'

interface Props {
    businessId: string
    dataQaAdd?: string
    dataQaRemove?: string
    dataQaDisabled?: string
    isDal?: boolean
    show?: boolean
    useStore?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    dataQaAdd: 'addToWatchlist', 
    dataQaRemove: 'deleteFromWatchlist',
    dataQaDisabled: 'watchlistActive',
    show: true,
})

const { t } = useI18n()
const productStore = useProductStore()
const watchlistSupported = props.useStore ? storeToRefs(productStore).watchlistSupported : ref(false)

const modal = ref<ModalProps>()
const isDisabled = ref(false)

const iconIsAvailable = ref(false)
const showLoadingSpinner = ref(false)

const onWatchlist = ref(false)

const watchlistSignalsAvailable = ref(false)
const hasActiveSignalsOnWatchlist = ref(false)

watch([watchlistSignalsAvailable, hasActiveSignalsOnWatchlist], () => {
    let supported = false

    if (watchlistSignalsAvailable.value && !hasActiveSignalsOnWatchlist.value) {
        supported = true
    }
    watchlistSupported.value = supported
})

const isDAL = ref(props.isDal)

const addOnWatchlistText = computed(() => {
    return isDAL.value ? t('search.addOnWatchlist') : t('search.addOnWatchlistNonDAL')
})

onMounted(_init)

/**
 * Initialize watchlist status if needed.
 *
 */
async function _init() {
    if (differentContextActive()) {
        isDisabled.value = true
    }

    if (!props.businessId || isDisabled.value || !props.show) {
        iconIsAvailable.value = false
        onWatchlist.value = false
        watchlistSupported.value = false
        watchlistSignalsAvailable.value = false
        hasActiveSignalsOnWatchlist.value = false
        return Promise.reject()
    }

    try {
        const watchlistStatus = await _initOnWatchlist()
        const watchlistSupportedNew = await _initWatchListSupported()

        if (watchlistSupportedNew && watchlistStatus) {
            watchlistSupported.value = watchlistSupportedNew.watchlist && watchlistStatus.watchlistAddingSupported
            watchlistSignalsAvailable.value = watchlistSupportedNew.signals && watchlistStatus.watchlistSignalsSupported
        }
        iconIsAvailable.value = watchlistSupported.value || onWatchlist.value

        return Promise.resolve()
    } catch (error) {
        iconIsAvailable.value = onWatchlist.value
        watchlistSupported.value = false
        watchlistSignalsAvailable.value = false
        return Promise.reject(error)
    }
}

/**
 * Initialize if watchlist is supported.
 *
 * Please call only, when businessId is set.
 *
 */
async function _initWatchListSupported() {
    try {
        const status = await WatchlistService.checkWatchlistAvailability(props.businessId)

        if (!status.watchlist) {
            watchlistSupported.value = false
        }
        if (!status.signals) {
            watchlistSignalsAvailable.value = false
        }
        return Promise.resolve(status)
    } catch (error) {
        watchlistSupported.value = false
        watchlistSignalsAvailable.value = false
        return Promise.reject(error)
    }
}

/**
     * Initialize if company is already on watchlist.
     *
     * Please call only, when businessId is set.
     *
     */
async function _initOnWatchlist() {
    try {
        const result = await WatchlistService.hasWatchlistActiveSignalEntries(props.businessId)
        onWatchlist.value = result.isOnWatchlist
        hasActiveSignalsOnWatchlist.value = result.hasActiveSignals
        return Promise.resolve(result)
    } catch (error) {
        onWatchlist.value = false
        hasActiveSignalsOnWatchlist.value = false
        return Promise.reject(error)
    }
}

async function addWatchlistEntry() {
    if (isDisabled.value) { return }

    if (!showLoadingSpinner.value && props.businessId) {
        showLoadingSpinner.value = true

        onWatchlist.value = true

        try {
            await putCbraEntryOnWatchlist({
                id: props.businessId
            })

            await _initOnWatchlist()
            // notifier.notifySubscribers(null, constants.EVENTS.UPDATE_WATCHLIST_ENTRIES_EVENT);
            const event = new Event('watchlistCountChanged')
            window.dispatchEvent(event)
            showLoadingSpinner.value = false
        } catch (error) {
            if (isAxiosError(error)) {
                modal.value = showError({ topic: WatchlistService.CONSTS.TOPIC, func: 'putEntryOnWatchlist', xhr: error })
            }
            onWatchlist.value = false
            watchlistSupported.value = false
            watchlistSignalsAvailable.value = false
            hasActiveSignalsOnWatchlist.value = false
            showLoadingSpinner.value = false
        }
    }

    return Promise.resolve()
}

async function deleteWatchlistEntry() {
    if (isDisabled.value) { return }

    if (!showLoadingSpinner.value && props.businessId) {
        showLoadingSpinner.value = true

        try {
            const watchlistStatus = await _initOnWatchlist()
            if (onWatchlist.value && watchlistStatus?.id) {
                await deleteEntryFromWatchlist(watchlistStatus.id)
                // notifier.notifySubscribers(null, constants.EVENTS.UPDATE_WATCHLIST_ENTRIES_EVENT)
                const event = new Event('watchlistCountChanged')
                window.dispatchEvent(event)
                onWatchlist.value = false

                await _init()
            }
            showLoadingSpinner.value = false
        } catch (error) {
            showLoadingSpinner.value = false
        }

        showLoadingSpinner.value = false
    }

    return false
}

</script>