<template>
    <div
        class="crefo-ui-modal"
        data-qa="dashboard-add-widget-modal"
    >
        <div class="modal-content">
            <div class="modal-header">
                <div class="small-content-spacer" />
                <div class="small-content-spacer" />
                <h2>{{ $t('dashboard.show.manageDashboard') }}</h2>
                <span 
                    class="modal-close" 
                    data-qa="modal-close-icon"
                    @click="onClose" 
                />
            </div>
            <div class="modal-body">
                <div 
                    class="modal-text text-brand-dark-grey text-align--left" 
                >
                    <div class="row">
                        <div class="col-6 widget-checkboxes">
                            <h3>
                                {{ $t('dashboard.show.inkasso') }}
                            </h3>
                            <div
                                v-for="widget in getInkassoWidgets($i18n.locale)" 
                                :key="widget.id"
                            >
                                <WidgetModalCheckbox
                                    :widget="widget"
                                    @add-widget="addWidget"
                                    @remove-widget="removeWidget"
                                />
                            </div>
                            <h3>
                                {{ $t('dashboard.show.report') }}
                            </h3>
                            <div
                                v-for="widget in getAuskunftWidgets($i18n.locale)" 
                                :key="widget.id"
                            >
                                <WidgetModalCheckbox
                                    :widget="widget"
                                    @add-widget="addWidget"
                                    @remove-widget="removeWidget"
                                />
                            </div>
                            <h3>
                                {{ $t('dashboard.show.regional') }}
                            </h3>
                            <div
                                v-for="widget in getRegionalWidgets($i18n.locale)" 
                                :key="widget.id"
                            >
                                <WidgetModalCheckbox
                                    :widget="widget"
                                    @add-widget="addWidget"
                                    @remove-widget="removeWidget"
                                />
                            </div>
                            <h3>
                                {{ $t('dashboard.show.recommended') }}
                            </h3>
                            <div
                                v-for="widget in getRecommendedWidgets($i18n.locale)" 
                                :key="widget.id"
                            >
                                <WidgetModalCheckbox
                                    :widget="widget"
                                    @add-widget="addWidget"
                                    @remove-widget="removeWidget"
                                />
                            </div>
                            <h3>
                                {{ $t('dashboard.show.mycreditreform') }}
                            </h3>
                            <div
                                v-for="widget in getMyCreditreformWidgets($i18n.locale)" 
                                :key="widget.id"
                            >
                                <WidgetModalCheckbox
                                    :widget="widget"
                                    @add-widget="addWidget"
                                    @remove-widget="removeWidget"
                                />
                            </div>
                        </div>
                        <div class="col-6">
                            <img
                                :src="previewWidget.previewImage"
                                class="mb-small"
                                data-qa="widgetCatalog-previewImg"
                            >
                            {{ $t(previewWidget.description) }}
                        </div> 
                    </div>
                </div>
                <div class="small-content-spacer" />
                <div class="modal-buttons mb-small">
                    <button
                        class="btn mt ml btn-default"
                        :disabled="store.isLoading"
                        data-qa="widgetCatalog-saveButton"
                        @click="onConfirm"
                    >
                        {{ $t('dashboard.show.saveSelection') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <Modal
        v-if="errorAlert"
        :id="errorAlert.id"
        :title="errorAlert.title"
        :confirm-text="$t('shared.modals.ok')"
        :error-context="errorAlert.errorContext"
        :icon="errorAlert.icon"
        @on-close="errorAlert = undefined"
        @on-confirm="errorAlert = undefined"
    >
        <p>{{ errorAlert.content }}</p>
    </Modal>
</template>

<script lang="ts" setup>
import { getAllWidgets, getAuskunftWidgets, getInkassoWidgets, getRegionalWidgets , getRecommendedWidgets, getMyCreditreformWidgets } from '@/data/widgets'
import { ref, onMounted } from 'vue'
import { Widget } from '@/types/widget'
import { useDashboardStore } from '@/stores/dashboard'
import WidgetModalCheckbox from './WidgetModalCheckbox.vue'
import { ModalProps } from '@/types/components/modal'
import Modal from '../Modal.vue'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['onClose'])

const { t, locale } = useI18n()
const store = useDashboardStore()

const selectedWidgets = ref(store.dashboardConfig)
const previewWidget = ref(getAllWidgets(locale.value)[0]) // Always set the next not selected widget as preview Widget
const errorAlert = ref<ModalProps>()

function closeByKey(e: any) {
    if (e.key === 'Escape' || e.key === 'Enter') {
        console.log('close by key')
        e.preventDefault()
        onClose()
    }
}

function onClose() {
    document.removeEventListener('keydown', closeByKey)
    emit('onClose')
}

async function onConfirm() {
    // if (!selectedWidgets.value) return
    try {
        await store.save(selectedWidgets.value)
        await store.fetch()
        onClose()
    } catch (error) {
        store.isLoading = false
        errorAlert.value = {
            id: 'error',
            title: t('error.DashboardService.saveDashboardConfig'),
            content: t('error.DashboardService.saveDashboardConfig.description'),
            icon: 'error'
        }
    }
}

function addWidget(widget: Widget) {
    const alreadySelectedWidget = store.dashboardConfig?.find((config) => config.externalId === widget.id)

    if (alreadySelectedWidget) {
        selectedWidgets.value = selectedWidgets.value ? [...selectedWidgets.value, alreadySelectedWidget] : [alreadySelectedWidget]
        return 
    }

    const addedConfig = {
        externalId: widget.id,
        width: 1 
    }
    selectedWidgets.value = selectedWidgets.value ? [...selectedWidgets.value, addedConfig] : [addedConfig]
    previewWidget.value = widget
}

function removeWidget(widget: Widget) {
    if (!selectedWidgets.value) return
    const removedConfig = selectedWidgets.value.filter((config) => config.externalId !== widget.id)
    selectedWidgets.value = removedConfig
    previewWidget.value = widget
}

onMounted(() => {
    document.addEventListener('keydown', closeByKey)
})
</script>

<style scoped>
.widget-checkboxes {
    overflow-y: scroll;
    max-height: 600px;;
}
</style>