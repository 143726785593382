export default function debounce<T extends(...args: any[]) => void>(
    func: T,
    delay: number
): (...args: Parameters<T>) => void {
    let timeoutId: ReturnType<typeof setTimeout> | null
  
    return function(...args: Parameters<T>) {
        clearTimeout(timeoutId!)
  
        timeoutId = setTimeout(() => {
            func(...args)
        }, delay)
    }
}