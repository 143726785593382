<template>
    <div 
        class="container searchresult"
        data-qa="myTransactions-no-search-result"
    >
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-3">
                    <img
                        alt="Keine Ergebnisse"
                        src="@/assets/img/empty-watchlist.png"
                        width="121"
                        height="121"
                    >
                </div>
                <div 
                    v-if="!transactionsStore.hasActiveFilters"
                    class="col-12 col-md-9"
                >
                    <h1>
                        Wir haben keine Vorgänge gefunden!
                    </h1>
                    <p>
                        Hier finden Sie alle Details zu abgerufenen Auskünften und Inkasso-Aufträgen. Wir halten Ihre Vorgänge zentral und dauerhaft für Sie bereit.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useTransactionsStore } from '@/stores/transactions'
const transactionsStore = useTransactionsStore()
</script>
