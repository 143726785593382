<template>
    <ListWidget
        :results="results"
        :widget="props.widget"
        :is-loading="isLoading"
        :sorting-type="search.sort"
        :list-link="generateListLinkUrl()"
        :header-name="$t('dashboard.widget.expiringMonitoring.list.header.name')"
        :sort-name="$t('dashboard.widget.expiringMonitoring.list.header.date')"
        :page-size="search.pageSize"
        :page-start-index="search.pageStartIndex"
        :list-size="listSize"
        @on-sort="onSort"
        @on-previous-page="onPreviousPage"
        @on-next-page="onNextPage"
    />
</template>

<script setup lang="ts">
import { onMounted, PropType, reactive, ref } from 'vue'
import { EnhancedResults, Widget } from '@/types/widget'
import { getCbraTransactions } from '@/services'
import { CONSTS } from '@/helper/services/transaction'
import { CbraTransaction,CbraReportTransaction } from '@/types/cbra/transactions'
import { formatDate } from '@/filters'
import ListWidget from './ListWidget.vue'

const props = defineProps({
    widget: {
        type: Object as PropType<Widget>,
        required: true
    },
})

const observationType = 'ALL_OBSERVATION'
const observationExpirationType = 'EXPIRING_OBSERVATIONS'

const results = ref<EnhancedResults[]>([])
const listSize = ref(0)
const isLoading = ref(true)

const search = reactive({
    pageSize: 3,
    pageStartIndex: 0,
    sort: CONSTS.SORT.OBSERVATION_END_TIME_DESC,
    transactionType: 'REPORT',
    favourites: false,
    observationType: observationType,
    observationExpirationType: observationExpirationType
})

onMounted(async() => {
    await handleFetch()
})

async function onSort() {
    if (search.sort === CONSTS.SORT.OBSERVATION_END_TIME_ASC) {
        search.sort = CONSTS.SORT.OBSERVATION_END_TIME_DESC
    } else {
        search.sort= CONSTS.SORT.OBSERVATION_END_TIME_ASC
    }
    await handleFetch()
}

async function handleFetch() {
    isLoading.value = true
    try {
        const response = await getCbraTransactions(search)
        listSize.value = response.data.sizeTransactions
        isLoading.value = false
        const enhancedResults = response.data.transactions.map(enhanceItem)
        results.value = enhancedResults
    } catch (error) {
        console.error(error)
        isLoading.value = false
    }
}

function generateLinkUrl(transactionId: string) {
    return `${props.widget.link?.url}?transactionId=${transactionId}`
}

function generateListLinkUrl() {
    if (props.widget.listUrl) {
        const filterParameters = {
            observationType: observationType,
            observationExpirationType: observationExpirationType
        }

        const urlParams = new URLSearchParams({
            sortMode: search.sort,
            filterMode: 'REPORT', 
            filterParameters: JSON.stringify(filterParameters)
        })

        return `${props.widget.listUrl}?${urlParams.toString()}`
    }
}

function enhanceItem(transaction: CbraTransaction) {
    if (!transaction.reportTransaction) {
        return {} as EnhancedResults
    }

    return {
        name: genBusinessName(transaction.reportTransaction),
        date: getEndDate(transaction.reportTransaction),
        transactionId: transaction.reportTransaction.id,
        businessId: transaction.reportTransaction.businessId,
        link: generateLinkUrl(transaction.reportTransaction.id)
    }
}

function getEndDate(item: CbraReportTransaction) {
    if (item.monitoringEndTime && item.supplementEndDate) {
        let endDate = item.monitoringEndTime > item.supplementEndDate ? item.monitoringEndTime : item.supplementEndDate
        return formatDate(endDate)
    } else if (item.supplementEndDate) {
        return formatDate(item.supplementEndDate)
    } else if (item.monitoringEndTime) {
        return formatDate(item.monitoringEndTime)
    } else {
        return ''
    }
}

function genBusinessName(item: CbraReportTransaction) {
    return item.postalAddress && item.postalAddress.city ? `${item.businessName}, ${item.postalAddress.city}`: item.businessName
}

async function onPreviousPage() {
    if (isLoading.value) return
    search.pageStartIndex--
    await handleFetch()
}

async function onNextPage() {
    if (isLoading.value) return
    search.pageStartIndex++
    await handleFetch()
}

</script>