<script setup lang="ts">
import { ref } from 'vue'
import { useMembersStore } from '@/stores/members'

import ReadOnlyElement from '@/components/Profile/partials/ReadOnlyElement.vue'
import Card from '@/components/Card.vue'
import InputText from '@/components/Input/InputText.vue'
import Select from '@/components/Input/Select.vue'

const membersStore = useMembersStore()
const editCompanyData = ref(false)
const memberTypeOptions = ref([
    {
        code: true,
        label: '',
        i18nKey: 'selfcare.memberType.person'
    },
    {
        code: false,
        label: '',
        i18nKey: 'selfcare.memberType.firm'
    }
])
const props = defineProps({
    isAllowedToSave: {
        type: Boolean,
        default: false
    }
})

function handleTypeChange() {
    if (membersStore.updatePayload.naturalPerson) {
        delete membersStore.updatePayload.companyName
        if (!membersStore.updatePayload.naturalPersonName) {
            membersStore.updatePayload.naturalPersonName = {
                givenName: '',
                surname: '',
                birthName: null
            }
        }
    } else {
        delete membersStore.updatePayload.naturalPersonName
        if (!membersStore.updatePayload.companyName) {
            membersStore.updatePayload.companyName = {
                nameLine1: '',
                nameLine2: null,
                nameLine3: null
            }
        }
    }
}

async function toggleEditCompanyData() {
    if (!props.isAllowedToSave) {
        return
    }
    handleTypeChange()
    editCompanyData.value = !editCompanyData.value
    membersStore.dirtyCompany = editCompanyData.value
}
</script>

<template>
    <Card
        id="memberdata-companyName"
        data-qa="selfcare-member-membership"
    >
        <h3>{{ $t('selfcare.show.section.unternehmensdaten.firmierung') }}</h3>
        <ReadOnlyElement
            :label="$t('selfcare.show.section.mitgliedsdaten.mitgliedsnummer')"
            :value="membersStore.cbraMember.memberId"
            data-qa="selfcare-member-memberId"
        />
        <ReadOnlyElement
            :label="$t('shared.businessId')"
            :value="membersStore.cbraMember.businessId"
            data-qa="selfcare-member-businessId"
        />
        <ReadOnlyElement
            :label="$t('selfcare.show.section.mitgliedsdaten.mitgliedsname')"
            :value="membersStore.cbraMember.memberName"
            data-qa="selfcare-member-memberName"
        />
        <ReadOnlyElement
            :label="$t('selfcare.show.section.mitgliedsdaten.eintrittsDatum')"
            :value="membersStore.cbraMember.entryDate.toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })"
            data-qa="selfcare-member-entryDate"
        />
        <ReadOnlyElement
            :label="$t('selfcare.show.section.mitgliedsdaten.austrittsDatum')"
            :value="!membersStore.cbraMember.exitDate ? '' : membersStore.cbraMember.exitDate.toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })"
            data-qa="selfcare-member-exitDate"
        />
        <div class="row">
            <div class="col-7 offset-5">
                <a
                    role="button"
                    href="#"
                    class="showmore-link"
                    :class="{ 'showmore-link-disabled': !isAllowedToSave}"
                    :data-qa="`selfcare-member-toggle-data-link${!isAllowedToSave ? '-disabled' : ''}`"
                    @click.prevent.stop="toggleEditCompanyData"
                >
                    <span v-if="!editCompanyData">
                        <font-awesome-icon icon="fa-light fa-circle-plus" />
                        {{ $t('selfcare.change') }}
                    </span>
                    <span v-else>
                        <font-awesome-icon icon="fa-light fa-circle-minus" />
                        {{ $t('selfcare.hide') }}
                    </span>
                </a>
            </div>
        </div>
        <Transition>
            <div
                v-if="editCompanyData"
                class="row"
            >
                <div class="col-12">
                    <Select
                        id="selfcare-member-type"
                        v-model="membersStore.updatePayload.naturalPerson"
                        data-qa="selfcare-member-type"
                        :options="memberTypeOptions"
                        :label="$t('selfcare.show.section.mitgliedsdaten.mitgliedstyp')"
                        :searchable="false"
                        @update:model-value="handleTypeChange"
                    />
                    <template v-if="!membersStore.updatePayload.naturalPerson && membersStore.updatePayload.companyName">
                        <InputText
                            v-model="membersStore.updatePayload.companyName.nameLine1"
                            name="companyName"
                            :label="$t('selfcare.show.section.mitgliedsdaten.mitgliedsname')"
                            data-qa="selfcare-member-memberNameField"
                            error-msg-data-qa="register-companyName-message"
                        />
                    </template>
                    <template v-else-if="membersStore.updatePayload.naturalPersonName">
                        <InputText
                            v-model="membersStore.updatePayload.naturalPersonName.givenName"
                            name="givenName"
                            :label="$t('shared.givenName')"
                            data-qa="register-vorname"
                            error-msg-data-qa="register-vorname-message"
                            :required="true"
                        />
                        <InputText
                            v-model="membersStore.updatePayload.naturalPersonName.surname"
                            name="nachname"
                            :label="$t('shared.surname')"
                            data-qa="register-nachname"
                            error-msg-data-qa="register-nachname-message"
                            :required="true"
                        />
                        <InputText
                            v-model="membersStore.updatePayload.naturalPersonName.birthName"
                            name="birthNameInput"
                            :label="$t('shared.birthName')"
                            data-qa="member-birthname"
                            error-msg-data-qa="member-birthname-message"
                        />
                    </template>
                </div>
            </div>
        </Transition>
    </Card>
</template>

<style scoped>
/* we will explain what these classes do next! */
.v-enter-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
