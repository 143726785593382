import { useUserStore } from '@/stores/user'
import { requestConfig } from '@/helper/requestConfig'
import axios from '@/services/tracing-axios'
import standardAxios from 'axios'

export const ROLES = {
    MG_BENUTZER: 'CR-CP.MG-BENUTZER',
    LOGIN: 'CR-CP.LOGIN',
    MG_BERECHTIGTER: 'CR-CP.MG-BERECHTIGTER',
    SELBSTAUSKUNFT: 'CR-CP.SELBSTAUSKUNFT',
    SYSTEM: 'CR-CP.SYSTEM',
    BENUTZERVERWALTUNG_MITGLIED: 'CR-CP.BENUTZERVERWALTUNG-MITGLIED',
    AUSKUNFT: 'CR-CP.AUSKUNFT',
    INKASSO: 'CR-CP.INKASSO',
    INKASSO_SCAN: 'CR-CP.INKASSO-SCAN',
    MITGLIEDSDATEN: 'CR-CP.MITGLIEDSDATEN',
    UNTERNEHMENSDATEN: 'CR-CP.UNTERNEHMENSDATEN',
    PORTFOLIO_AUSKUNFT: 'CR-CP.PORTFOLIO_AUSKUNFT',
    UNTERNEHMENSNAVIGATION: 'CR-CP.UNTERNEHMENSNAVIGATION'
}

export function isMitgliedsbenutzer() {
    const userStore = useUserStore()
    return userStore.user.cr_mapping && userStore.user.cr_mapping.length !== 0
}

export function isSuperUser() {
    if (!isMitgliedsbenutzer()) {
        return false
    }
    const userStore = useUserStore()
    const roleName = ROLES.BENUTZERVERWALTUNG_MITGLIED
    const memberId = userStore.user.cr_membernumber && userStore.user.cr_membernumber.length ? userStore.user.cr_membernumber : ''
    const bvw = userStore.user.user_roles.filter((role) => role.indexOf(roleName) > -1)
    console.log('isSuperUser', userStore.user.user_roles, memberId)
    return (bvw && bvw.length) ? bvw[0].split(';').includes(memberId) : false
}

export function getUserRoles() {
    const userStore = useUserStore()
    return userStore.user.user_roles ? userStore.user.user_roles : []
}

export async function getUserInfo() {
    const userStore = useUserStore()
    return userStore.user
}

export async function getCurrentUserCbra() {
    try {
        return await axios.get('/cbra/user', requestConfig)
    } catch (error) {
        if (standardAxios.isAxiosError(error)) return error
    }
}

export function hasUserRole(role: string) {
    return getUserRoles().includes(role)
}

/**
 *
 * hasSuperUserRole
 *
 * @param {String} memberId
 * @return {Boolean}
 */
export function hasSuperUserRole(memberId: string) {
    const roleName = ROLES.BENUTZERVERWALTUNG_MITGLIED
    const bvw = getUserRoles().filter((role) => {
        return role.indexOf(roleName) > -1
    })
    return (bvw && bvw.length) ? bvw[0].split(';').includes(memberId) : false
}

/**
 * Set the locked status for the given person
 *
 * @param {String} crId - A persons unique Crid
 * @param {Boolean} locked - The lock status to be set for the given Crid
 * @returns {Promise<all>} - Axios promise object
 */
export async function setPersonLockStatusByCrid(crId: string, locked: boolean) {
    return await axios.post(`${import.meta.env.VITE_APP_USER_MGMT_API_BASEURL}/persons/${crId}/lock`,
        { locked: locked }, requestConfig)
}