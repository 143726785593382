import Cookies from 'js-cookie'
import CONSTANTS from '@/data/constants'

interface StorageInterface {
  usedNonPersistentKeys: { [key: string]: boolean }

  set(key: string, val: any, isPersistent?: boolean): void

  get(key: string, isPersistent?: boolean): any

  remove(key: string, isPersistent?: boolean): void

  cleanUp(): void
}

const LStorage: StorageInterface = {
    usedNonPersistentKeys: {},

    set(key, val, isPersistent) {
        try {
            getStorage(isPersistent).setItem(
                key,
                typeof val === 'string' ? val : JSON.stringify(val),
            )
            if (!isPersistent) {
                this.usedNonPersistentKeys[key] = true
            }
        } catch (e) {
            console.error('Error: Local/SessionStorage: %o', e)
        }
    },

    get(key, isPersistent) {
        const value = getStorage(isPersistent).getItem(key)
        if (!value) {
            return value
        }
        try {
            return JSON.parse(value)
        } catch (e) {
            return value
        }
    },

    remove(key, isPersistent) {
        try {
            getStorage(isPersistent).removeItem(key)
            if (!isPersistent) {
                this.usedNonPersistentKeys[key] = false
            }
        } catch (e) {
            console.error('Error: Local/SessionStorage: %o', e)
        }
    },

    cleanUp() {
        Object.keys(this.usedNonPersistentKeys).forEach((key) => {
            if (this.usedNonPersistentKeys[key] === true) {
                this.remove(key, false)
            }
        })
    },
}

const CStorage: StorageInterface = {
    usedNonPersistentKeys: {},

    set(key, val, isPersistent) {
    /*
     * max expires: Tue Jan 19 2038 04:14:07 GMT+0100
     * https://en.wikipedia.org/wiki/Year_2038_problem
     */
        Cookies.set(
            key,
            val,
            isPersistent
                ? {
                    expires: new Date(2147483647000),
                    path: '/',
                    secure: true,
                    sameSite: 'Lax',
                }
                : undefined,
        )
        if (!isPersistent) {
            this.usedNonPersistentKeys[key] = true
        }
    },

    get(key) {
        const value = Cookies.get(key)
        if (value) {
            try {
                return JSON.parse(value)
            } catch (e) {
                return value
            }
        }
        return value
    },

    remove(key) {
        Cookies.remove(key, { path: '/', secure: true, sameSite: 'Lax' })
        this.usedNonPersistentKeys[key] = false
    },

    cleanUp() {
        Object.keys(this.usedNonPersistentKeys).forEach((key) => {
            if (this.usedNonPersistentKeys[key] === true) {
                this.remove(key)
            }
        })
    },
}

function init() {
    window.addEventListener(CONSTANTS.USER_SSO_LOGOUT, () => {
        LStorage.cleanUp()
        CStorage.cleanUp()
    })
}

function getStorage(isPersistent?: boolean) {
    return isPersistent ? window.localStorage : window.sessionStorage
}

export const Storage: StorageInterface = LStorage

export function deleteCookie(
    name: string,
    path: string = '/',
    secure: boolean = true,
    sameSite: string = 'Lax',
) {
    let cookie = `${name}=; Max-Age=-99999999;path=${path};sameSite=${sameSite}`
    if (secure) {
        cookie += ';secure'
    }
    console.log('deleteCookie', name)
    document.cookie = cookie
}

export function getCookie(name: string) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    if (match) {
        return match[2]
    }
    return null
}

export function setCookie(
    name: string,
    value: string,
    days: number = 0,
    path: string = '/',
    secure: boolean = true,
    sameSite: string = 'Lax',
) {
    const cookie = []
    cookie.push(`${name}=${value}`)
        
    if (days) {
        const expires = new Date()     
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
        cookie.push(`expires=${expires.toUTCString()}`)
    }
    
    cookie.push(`path=${path}`)
    cookie.push(`sameSite=${sameSite}`)
    if (secure) {
        cookie.push('secure')
    }
    document.cookie = cookie.join(';')
}

export default Storage
init()
