import { useUserStore } from '@/stores/user'
import { getUserInfo, ROLES } from '@/helper/services/user'
import { requestConfig } from '@/helper/requestConfig'
import axios from '@/services/tracing-axios'
import standardAxios from 'axios'
import i18n from '@/i18n'
import { CbraMemberStructure, CbraMemberStructureMember } from '@/types/cbra/memberStructure'
import { EnhancedCbraMember } from '@/types/cbra/member'
import { showError } from '@/helper/services/error'

export function isSuperUserAdministrationAllowed(memberStructure: any) {
    const userStore = useUserStore()
    // 1.) Check if we have a CTV member structure
    if (!memberStructure || !memberStructure.mainMember || !memberStructure.subMembers || !memberStructure.subMembers.length) {
        return false
    }

    // 2.) Check if the current user's memberId exists in the given CTV member structure
    // @TODO: Currently, the logged-in user must be a user of the member structure's
    // main member. Originally, the feature was planned to allow all SuperUsers of the
    // member structure's main member. If this logic will be added (again), just uncomment
    // the else-block.
    let currentMemberExistsInStructure = false
    if (userStore.user.cr_membernumber === memberStructure.mainMember.memberId) {
        currentMemberExistsInStructure = true
    } /*else {
        for (let i in memberStructure.subMembers) {
            if (user.cr_membernumber === memberStructure.subMembers[i].memberId) {
                currentMemberExistsInStructure = true;
            }
        }
    }*/

    // 3.) Check if the current user is superuser for the given CTV member structure's main member
    let currentUserisSuperUserForMainMember = false
    if (currentMemberExistsInStructure) {
        const roles = userStore.user.user_roles
        for (const i in roles) {
            if (roles[i].match(ROLES.BENUTZERVERWALTUNG_MITGLIED) && roles[i].match(memberStructure.mainMember.memberId)) {
                currentUserisSuperUserForMainMember = true
            }
        }
    }

    let inOwnContext = false
    const sessionActForMemberId = sessionStorage.getItem('actForMemberId')
    if (!sessionActForMemberId || sessionActForMemberId === userStore.user.cr_membernumber) {
        inOwnContext = true
    }

    const isAllowed = (currentMemberExistsInStructure && currentUserisSuperUserForMainMember && inOwnContext)
    return isAllowed
}

export async function amIInMemberStructureInSuperUserStructure(user: UserData) {
    /* return userService.getUserInfo().then((user) => { */
    try {
        const modifiedRequestConfig = {
            ...requestConfig,
            useRequestCache: true,
            useRequestCacheKey: `MemberStructure_${user.cr_id}_${user.cr_membernumber}_${i18n.locale}`,
            useRequestCacheDuration: 1000
        }
        const response = await axios.get('/cbra/memberstructure', modifiedRequestConfig)
        if (response && response.data.mainMember !== null && response.data.subMembers) {
            return checkIfAllowed(user.cr_membernumber, response.data)
        } else {
            return false
        }
    } catch (error) {
        if (standardAxios.isAxiosError(error)) {
            if (error.status === 200) {
                return false
            } else {
                showError({ topic: 'MemberNavigationService', xhr: error })
            }
        }
    }
    /* }); */
}

export function isSameMember(memberId: string) {
    return getMemberNavigationSession() === memberId
}

export async function isRoleSettable() {
    try {
        await axios.get('/cbra/memberstructure/rolesetable', requestConfig)
        return true
    } catch (error) {
        if (standardAxios.isAxiosError(error)) {
            throw error
        }
    }
}

export function getOriginalMemberId() {
    return sessionStorage.getItem('originalMemberId')
}

export function getMemberNavigationSession() {
    return sessionStorage.getItem('actForMemberId')
}

export function removeMemberNavigationSession() {
    sessionStorage.removeItem('actForMemberId')
    sessionStorage.removeItem('reportAct4SbNumber')
    return true
}

export async function getMembersInfoOfCurrentUser() {
    const user = await getUserInfo()

    const emptyObject = { members: {}, memberId: user.cr_membernumber }

    try {
        const response = await axios.get('/cbra/memberstructure?administrable=true', requestConfig)
        if (response) {
            return {
                members: response.data || {},
                memberId: user.cr_membernumber,
            }
        } else {
            return emptyObject
        }
    }
    catch (err: unknown) {
        if (standardAxios.isAxiosError(err)) {
            if (err.response?.status === 200 || err.response?.status === 204) {
                removeMemberNavigationSession()
                return emptyObject
            } else if (err.response?.status === 403) {
                return emptyObject
            } else {
                showError({ topic: 'MemberNavigationService', xhr: err })
            }
        }
    }
}

/**
 * getMembersForMe - get all members to switch in
 *
 * @return {Promise}
 */
export async function getMembersForMe(useCache = false) {
    const userInfo = await getUserInfo()
    if (userInfo) sessionStorage.setItem('originalMemberId', userInfo.cr_membernumber || '')

    type MyMembers = {
        members: CbraMemberStructure | null
        memberId: string
    }

    let myMembers: MyMembers = {
        members: null,
        memberId: ''
    }

    const requestConfigWithCacheOption = {
        ...requestConfig,
        useRequestCache: useCache,
        useRequestCacheKey: `MemberStructure_${userInfo.cr_id}_${userInfo.cr_membernumber}_${i18n.locale}`,
        useRequestCacheDuration: 1000
    }
    try {
        const response = await axios.get('/cbra/memberstructure?navigationSelected=true', requestConfigWithCacheOption)
        const mId = getMemberNavigationSession() || userInfo.cr_membernumber || ''
        sessionStorage.setItem('actForMemberName', getActForMemberName(mId, response.data))

        if (response) {
            if (!checkIfAllowed(userInfo.cr_membernumber, response.data)) {
                if (getMemberNavigationSession() && userInfo.cr_membernumber !== getMemberNavigationSession()) {
                    removeMemberNavigationSession()
                    window.location.href = '/?revoked=true'
                }

                removeMemberNavigationSession()
                myMembers = {
                    members: null,
                    memberId: userInfo.cr_membernumber || ''
                }
                return myMembers
            }

            myMembers = {
                members: response.data || null,
                memberId: mId || ''
            }
            return myMembers
        } else {
            myMembers = {
                members: null,
                memberId: userInfo.cr_membernumber || ''
            }
        }
    } catch (error) {
        if (standardAxios.isAxiosError(error)) {
            if (error.status === 200) {
                removeMemberNavigationSession()
                myMembers = {
                    members: null,
                    memberId: userInfo.cr_membernumber || ''
                }
                return myMembers
            } else {
                showError({ topic: 'MemberNavigationService', xhr: error })
            }
        }
    }
}

function checkIfAllowed(memberId = '', memberStructure: CbraMemberStructure) {
    const actForMemberId = getMemberNavigationSession()
    let check1 = false,
        check2 = false

    if (memberId && checkIfIamAllowedToNavigate(memberId, memberStructure)) {
        check1 = true
    }

    if (actForMemberId && checkIfIamAllowedToNavigate(actForMemberId, memberStructure) && check1) {
        check2 = true
    }

    return check1 && check2 || check1 && !actForMemberId
}

function checkIfIamAllowedToNavigate(memberId = '', memberStructure: CbraMemberStructure) {
    if (!memberId || memberStructure.mainMember && memberStructure.mainMember.memberId === memberId) {
        return true
    }

    if (memberStructure.subMembers) {
        for (const i in memberStructure.subMembers) {
            if (memberStructure.subMembers[i].memberId === memberId) {
                return true
            }
        }
    }

    return false
}

function getActForMemberName(memberId: string, memberStructure: CbraMemberStructure) {
    let name = ''

    const callFromSUMainMember = !!memberId
    const flatMemberStructure = prepareMemberListForMemberNavigation(memberStructure, callFromSUMainMember)

    for (const i in flatMemberStructure) {
        if (flatMemberStructure[i].memberId === memberId) {
            name = flatMemberStructure[i].displayName
        }
    }

    return name
}

export function differentContextActive() {
    const origMemberId = getOriginalMemberId()
    const actForMemberId = getMemberNavigationSession()
    return (origMemberId && actForMemberId && origMemberId !== actForMemberId) || false
}

export function checkMemberView(memberId: string) {
    return isSameMember(memberId) || !getMemberNavigationSession()
}

/**
 * prepareMemberList - prepare memberlist for selectbox
 *
 * @param {Object} memberStructure
 * @param {Boolean} callFromSUMainMember
 * @return {Array} allMembers - Array of member objects
 */
export function prepareMemberListForMemberNavigation(memberStructure: CbraMemberStructure, callFromSUMainMember = false) {
    const allMembers = []

    if (memberStructure && memberStructure.mainMember) {
        allMembers.push(enhanceMember(memberStructure.mainMember, true, callFromSUMainMember))
    }

    if (memberStructure && memberStructure.subMembers && memberStructure.subMembers.length > 0) {
        memberStructure.subMembers.map((member: CbraMemberStructureMember) => {
            allMembers.push(enhanceMember(member, false, callFromSUMainMember))
        })
    }

    return allMembers
}

/**
 * enhanceMember - Anreichern der Informationen
 *
 * @param {Object} member - member object
 * @param {Boolean} mainMember - is main member
 * @param {Boolean} callFromSUMainMember - call from SU main member
 * @return {Object} outputObject - enhanced member object
 */
export function enhanceMember(member: CbraMemberStructureMember, mainMember = false, callFromSUMainMember = false) {
    let fullAddress: string
    if ((member.zipCode || member.city) && !member.address) {
        fullAddress = `${member.zipCode} ${member.city}`
    } else {
        fullAddress = member.address
    }

    const outputObject: EnhancedCbraMember = {
        'address': member.address,
        'addressText': '',
        'callFromSUMainMember': callFromSUMainMember,
        'collectionSelected': false,
        'displayName': member.displayName,
        'lastUpdate': member.lastUpdate,
        'mainMember': mainMember,
        'memberId': member.memberId,
        'navigationSelected': member.navigationSelected,
        'outputFirstLine': `${member.displayName} (${member.memberId})`,
        'outputSecondLine': '',
        'reportSelected': member.reportSelected
    }

    if (member.reportAct4SbNumber) outputObject.reportAct4SbNumber = member.reportAct4SbNumber
    outputObject.outputSecondLine = outputObject.addressText = fullAddress

    return outputObject
}

/**
 * isSameMemberId - check if given memberId is the same like in session storage
 */
export function isSameMemberId(memberId: string) {
    return getMemberNavigationSession() === memberId
}

export function getReportAct4SbNumberSession() {
    return sessionStorage.getItem('reportAct4SbNumber')
}

export function setReportAct4SbNumberSession(reportAct4SbNumber: number) {
    if (reportAct4SbNumber === null) {
        removeReportAct4SbNumberSession()
    } else {
        sessionStorage.setItem('reportAct4SbNumber', reportAct4SbNumber.toString())
    }
    return reportAct4SbNumber
}

export function removeReportAct4SbNumberSession() {
    sessionStorage.removeItem('reportAct4SbNumber')
    return true
}
