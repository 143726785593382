/**
 * Keys are based on the java enum KapitelType.
 * @see de.creditreform.crefoportal.cbra.api.auskunft.service.generated.KapitelType
 */
export default {
    FIRMENIDENTIFIKATION: {},
    ANFRAGEDATEN: { template: 'failReportQueryData' },
    STATUSANTWORT: {},
    NACHTRAGSDATEN: { template: 'nachtragsdatenBoxTemplate' },
    AKTUALISIERUNGSHINWEIS: {},
    BONITAET: { template: 'liquidityBoxTemplate' },
    BONITAETSENTWICKLUNG: { template: 'solvencyTrendBoxTemplate' },
    BONITAETUNTERNEHMENSINFO: { template: 'liquidityInfoBoxTemplate' },
    ZAHLUNGSWEISE_KREDITURTEIL: { template: 'creditBoxTemplate' },

    HINWEISE_ZUM_FEHLBERICHT: { template: 'notesFailReport' },
    FEHLBERICHT: { template: 'failReport' },
    FEHLBERICHT_DETAILS: { template: 'failReportDetails' },

    STRUKTURDATEN: { template: 'structureBoxTemplate' },
    RECHTSFORM: { template: 'rechtsformBoxTemplate' },
    GRUENDUNG: { template: 'gruendungBoxTemplate' },
    GRUENDUNG_INT: { template: 'gruendungIntBoxTemplate' },
    REGISTER: { template: 'registerBoxTemplate' },
    REGISTERHISTORIE: {},
    KAPITALANGABEN: { template: 'kapitalangabenBoxTemplate' },
    ZUSATZINFOS_ZUR_RECHTSFORM: { template: 'zusatzinfosZurRechtsformBoxTemplate' },
    ZUSATZINFOS_ZUR_RECHTSFORM_AG: { template: 'zusatzinfosZurRechtsformAGBoxTemplate' },
    ZUSATZINFOS_ZUR_RECHTSFORM_GENOSSE: { template: 'zusatzinfosZurRechtsformGenosseBoxTemplate' },
    ZUSATZINFOS_ZUR_RECHTSFORM_STIFTUNG: { template: 'zusatzinfosZurRechtsformStiftungBoxTemplate' },
    ZUSATZINFOS_ZUR_RECHTSFORM_VEREIN: { template: 'zusatzinfosZurRechtsformVereinBoxTemplate' },
    GESELLSCHAFTER_UND_KAPITALANGABEN: { template: 'gesellschafterBoxTemplate' },
    GESCHAEFTSFUEHRUNG_UND_VERTRETUNGSBEFUGNISSE: { template: 'managingDirectorsBoxTemplate' },
    STANDORTE: {},

    BETEILIGUNGSVERHAELTNISSE: { template: 'beteiligungsverhaeltnisseBoxTemplate' },
    BETEILIGUNGEN_DES_UNTERNEHMENS: { template: 'beteiligungUnternehmenBoxTemplate' },
    KONZERNZUGEHOERIGKEIT: { template: 'konzernzugehoerigkeitBoxTemplate' },
    BETEILIGUNGEN_UND_FUNKTIONEN: { template: 'beteiligungFunktionenBoxTemplate' },

    GESCHAEFTSTAETIGKEIT: { template: 'businessActivityBoxTemplate' },
    EINGETRAGENER_GEGENSTAND: { template: 'eingetragenBoxTemplate' },
    TATSAECHLICHER_GEGENSTAND: { template: 'tatsaechlicherGegenstandBoxTemplate' },
    ANMERKUNGEN_GESCHAEFTSTAETIGKEIT: { template: 'anmerkungenGeschaeftstaetigkeitBoxTemplate' },
    KLASSIFIKATION_DER_WIRTSCHAFTSZWEIGE: { template: 'wirtschaftszweigeBoxTemplate' },
    PRODUKTE: {},
    MARKEN: {},
    VERTRIEBSWEGE: {},
    ZERTIFIZIERUNGEN: {},

    GESCHAEFTSZAHLEN: { template: 'geschaeftszahlenBoxTemplate' },
    MITARBEITER_UND_UMSATZ_UNTERNEHMEN: { template: 'mitarbeiterUmsatzUnternehmenBoxTemplate' },
    UMSATZ_UNTERNEHMEN: { template: 'umsatzTemplate' },
    MITARBEITER_UNTERNEHMEN: { template: 'mitarbeiterTemplate' },
    MITARBEITER_UND_UMSATZ_KONZERN: { template: 'mitarbeiterUmsatzKonzernBoxTemplate' },
    UMSATZ_KONZERN: { template: 'umsatzTemplate' },
    MITARBEITER_KONZERN: { template: 'mitarbeiterTemplate' },
    MITARBEITER_UND_UMSATZ_KLASSE_UNTERNEHMEN: { template: 'mitarbeiterUmsatzUnternehmenKlassenBoxTemplate' },
    IMPORT_EXPORT: {},
    IMMOBILIEN: {},

    UNTERNEHMENSBILANZ: { template: 'unternehmensBilanzTemplate' },
    KONZERNBILANZ: { template: 'konzernBilanzTemplate' },
    BILANZEN_UNTERNEHMEN: {},
    GUV_BERICHTE_UNTERNEHMEN: {},
    JAHRESABSCHLUSSKENNZAHLEN_UNTERNEHMEN: {},
    BILANZEN_KONZERN: {},
    GUV_BERICHTE_KONZERN: {},
    JAHRESABSCHLUSSKENNZAHLEN_KONZERN: {},

    ZUSATZINFORMATIONEN: { template: 'zusatzInformationenBoxTemplate' },
    ANMERKUNGEN_ZUSATZINFORMATIONEN: { template: 'zusatzInformationenAnmerkungenBoxTemplate' },
    BANKEN: { template: 'bankenBoxTemplate' },

    ZAHLUNGSINFORMATIONEN_UND_BEURTEILUNG: { template: 'paymentInfoBoxTemplate' },
    ZAHLUNGSWEISE_UND_KREDITURTEIL: {},
    DEBITORENREGISTER_DEUTSCHLAND: {},
    ANFRAGEZAEHLER: {},
    WIRTSCHAFTLICHE_ENTWICKLUNG: { template: 'wirtschaftlicheEntwicklungBoxTemplate' },
    NEGATIVMERKMALE: { template: 'negativeFeaturesBoxTemplate' },
    NEGATIVMERKMALE_INTERNATIONAL: { template: 'negativeInternFeaturesBoxTemplate' },
    MERKMALE_FUER_DIE_BONITAETSBEWERTUNG: {},
    ZUORDNUNG_BONI_IN_KLASSEN_ANDERER_DIENSTLEISTER: {},

    AUSKUNFTSFUSS: {},

    STEUERDATEN: {},
    ZAHLUNGSWEISE: {},
    KREDITURTEIL: {},

    HINWEISE_ZUR_AUSKUNFT: { template: 'hinweisZurAuskunftBoxTemplate' },

    // "CREDIT_INFORMATION":           { template: "creditInformationBoxTemplate" },
    // "STEUERDATEN":                  { template: "steuerdatenBoxTemplate"},
}
