<template>
    <span class="crefo-filter-label text-brand-darkest-blue">
        <slot />
        <font-awesome-icon icon="fa-light fa-xmark" />
    </span>
</template>

<style lang="less" scoped>
.crefo-filter-label {
    margin: 10px 10px 0 0;
    padding: 7px 8px;
    border-radius: 5px;
    background-color: var(--color-c-t-lightblue);
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    font-size: 1rem;
    cursor: pointer;

    svg {
        margin-left: 10px;
    }
}
</style>
