// /dm/meine-creditreform/portal-frontend/portal-frontend-releases/build/ux-prototype/webapp/public/js/viewmodels/transactions/reportTracking/ReportTracking.js
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { ComposerTranslation } from 'vue-i18n'
import { formatDate } from '@/filters'
import type { CbraTransaction } from '@/types/cbra/transactions'

dayjs.extend(isBetween)

const CONSTS = {
    REPORT_TRANSACTION_TRACKING: [
        'MONITORINGPLUS_UNBEFRISTET',
        'MONITORINGPLUS_LAUFEND',
        'MONITORING_UNBEFRISTET',
        'MONITORING_LAUFEND',
        'MONITORING_BEENDET',
        'NACHTRAGSFRIST_LAUFEND',
        'NACHTRAGSFRIST_BEENDET',
        'KEIN_TRACKINGSTATUS'
    ]
}

/**
 *
 * getMonitoringStatus
 *
 * @param {string|number} date - date in timestamp or string
 * @return {string}
 */
export function getStatus(date: string | number | null) {
    let dateWOTime = ''

    if (date !== null && typeof date === 'string' && date.indexOf('.')) {
        dateWOTime = dayjs(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    } else {
        dateWOTime = dayjs(date).format('YYYY-MM-DD')
    }

    const todayString = dayjs().format('YYYY-MM-DD')
    const todayObj = dayjs(todayString)
    const dateWOTimeObj = dayjs(dateWOTime)

    const today = todayObj.format('YYYY-MM-DD')
    const today30 = todayObj.add(29, 'days').format('YYYY-MM-DD')
    const abgelaufen = dateWOTimeObj.isBefore(today)
    const fastabgelaufen = dateWOTimeObj.isBetween(today, today30, null, '[]')

    if (abgelaufen) {
        return 'expired'
    } else if (fastabgelaufen) {
        return 'nearlyExpired'
    }
    return ''
}

function trackingLaufend(
    t: ComposerTranslation,
    // { _enum, endTime, status, monitoringEndTime, monitoringStatus, nameOfArea }
    { _enum, endTime, status, nameOfArea }
) {
    const className = status === 'nearlyExpired' ? 'text-brand-red' : 'text-brand-darkest-blue'
    // const monitoringClassName = monitoringStatus === 'nearlyExpired' ? 'text-brand-red' : 'text-brand-darkest-blue'

    const getReportDetailPageTemplate = () => {

    }

    const getTransactionListTemplate = () => {
        let desc = ''
        let dataQa = ''

        if (_enum === 'MONITORINGPLUS_LAUFEND') {
            desc = t('transaction.data.monitoringPlus.until')
            dataQa = status === 'nearlyExpired' ? 'myTransactions-MonitoringPlus-nearlyExpired' : 'myTransactions-MonitoringPlus-value'
        } else if (_enum === 'MONITORING_LAUFEND') {
            desc = t('watchlist.list.monitoring_until')
            dataQa = status === 'nearlyExpired' ? 'myTransactions-Monitoring-nearlyExpired' : 'myTransactions-Monitoring-value'
        } else if (_enum === 'NACHTRAGSFRIST_LAUFEND') {
            desc = t('watchlist.list.supplements_until')
            dataQa = status === 'nearlyExpired' ? 'myTransactions-Supplement-nearlyExpired' : 'myTransactions-Supplement-value'
        }

        return `
            <span data-qa="myTransactions-searchResultList-monitoringEndTime">
                <span>${desc}</span> <span class="text-bold ${className}" data-qa="${dataQa}">${formatDate(endTime, 'L')}</span>
            </span>`
    }

    return nameOfArea === 'ReportDetailPage' ? getReportDetailPageTemplate() : getTransactionListTemplate()
}

function trackingBeendet(
    t: ComposerTranslation,
    // { _enum, endTime, nameOfArea }
    { _enum, nameOfArea }
) {
    const getReportDetailPageTemplate = () => {

    }

    const getTransactionListTemplate = () => {
        let desc = ''
        let dataQa = ''
        let statusText = ''

        if (_enum === 'MONITORING_BEENDET') {
            desc = t('transaction.data.monitoring')
            statusText = t('transaction.data.monitoring.expired')
            dataQa = 'myTransactions-Monitoring-expired'
        } else if (_enum === 'NACHTRAGSFRIST_BEENDET') {
            desc = t('watchlist.list.supplement')
            statusText = t('transaction.data.monitoring.expired')
            dataQa = 'myTransactions-Supplement-expired'
        }

        return `
            <span data-qa="myTransactions-searchResultList-monitoringEndTime">
                <span>${desc}</span> <span class="text-brand-red text-bold" data-qa="${dataQa}">${statusText}</span>
            </span>`
    }

    return nameOfArea === 'ReportDetailPage' ? getReportDetailPageTemplate() : getTransactionListTemplate()
}

function trackingUnbefristet(
    t: ComposerTranslation,
    { _enum, nameOfArea }
) {
    const getTemplateForMonitoring = (desc: string) => {
        return `
            <span>
                <span class="icon icon--inline-withlabel icon--auskunft-monitoring"></span>
                <span>${desc}</span>
            </span>
            <span data-qa="process-Monitoring-unlimited">${t('transaction.show.unlimited')}</span>`
    }

    const getTemplateForMonitoringPlus = (desc: string) => {
        return `
            <div>
                <span>
                    <span class="icon icon--inline-withlabel icon--auskunft-monitoring"></span>
                    <span>${t('transaction.data.monitoring')}</span>
                </span>
                <span data-qa="process-Monitoring-unlimited">${t('transaction.show.unlimited')}</span>
            </div>
            <div>
                <span>
                    <span style="padding-left: 28px;">${desc} ${t('transaction.data.monitoringPlus')}</span>
                 </span>
                 <span data-qa="process-MonitoringPlus-unlimited">${t('transaction.show.unlimited')}</span>
            </div>`
    }

    const getReportDetailPageTemplate = () => {
        if (_enum === 'MONITORINGPLUS_UNBEFRISTET') {
            return getTemplateForMonitoringPlus(t('transaction.data.monitoring'))
        } else if (_enum === 'MONITORING_UNBEFRISTET') {
            return getTemplateForMonitoring(t('transaction.data.monitoring'))
        }
    }

    const getTransactionListTemplate = () => {
        let desc = ''
        let dataQa = ''

        if (_enum === 'MONITORINGPLUS_UNBEFRISTET') {
            desc = t('transaction.data.monitoringPlus.unlimited')
            dataQa = 'myTransactions-MonitoringPlus-unlimited'
        } else if (_enum === 'MONITORING_UNBEFRISTET') {
            desc = t('transaction.data.monitoring.unlimited')
            dataQa = 'myTransactions-Monitoring-unlimited'
        }

        return `
            <span data-qa="myTransactions-searchResultList-monitoringEndTime">
                <span data-qa="${dataQa}">${desc}</span>
            </span>`
    }

    return nameOfArea === 'ReportDetailPage' ? getReportDetailPageTemplate() : getTransactionListTemplate()
}

export function getReportTrackingHTML(
    t: ComposerTranslation,
    transaction: CbraTransaction,
    area: string
) {
    if (
        !transaction ||
        !transaction.reportTransaction ||
        !transaction.reportTransaction.reportTransactionTracking
    ) {
        return null
    }

    let reportTracking = null
    const trackingMonitoringEndTime = transaction.reportTransaction.monitoringEndTime,
        trackingStatus = transaction.reportTransaction.reportTransactionTracking.trackingStatus,
        trackingStatusEnd = transaction.reportTransaction.reportTransactionTracking.trackingStatusEnd,
        trackingStatusExpire = trackingStatusEnd ? getStatus(trackingStatusEnd) : null,
        trackingMonitoringStatusExpire = trackingMonitoringEndTime ? getStatus(trackingMonitoringEndTime) : null

    if (!CONSTS.REPORT_TRANSACTION_TRACKING.includes(trackingStatus)) {
        return ''
    }

    switch (trackingStatus) {
    case 'MONITORING_UNBEFRISTET':
    case 'MONITORINGPLUS_UNBEFRISTET':
        reportTracking = trackingUnbefristet(t, {
            _enum: trackingStatus,
            nameOfArea: area
        })
        break
    case 'MONITORING_LAUFEND':
    case 'MONITORINGPLUS_LAUFEND':
    case 'NACHTRAGSFRIST_LAUFEND':
        reportTracking = trackingLaufend(t, {
            _enum: trackingStatus,
            endTime: trackingStatusEnd,
            status: trackingStatusExpire,
            monitoringEndTime: trackingMonitoringEndTime,
            monitoringStatus: trackingMonitoringStatusExpire,
            nameOfArea: area
        })
        break
    case 'MONITORING_BEENDET':
    case 'NACHTRAGSFRIST_BEENDET':
        reportTracking = trackingBeendet(t, {
            _enum: trackingStatus,
            endTime: trackingStatusEnd,
            nameOfArea: area
        })
        break
    }

    return reportTracking
}
