<template>
    <form 
        :class="{'has-errors': !isValid && meta.dirty }"
        @submit.prevent="onSubmit"
    >
        <!-- Firmierung -->
        <Card id="businessData-companyName">
            <h3>{{ $t('selfcare.show.section.unternehmensdaten.firmierung') }}</h3>
            <Textarea
                v-model="formData.identificationData.businessName"
                label="Name"
                name="companyName"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-name"
                error-msg-data-qa="selfcare-business-name-message"
            />
            <p>{{ $t('selfcare.show.section.unternehmensdaten.firmierungHint') }}</p>
        </Card>

        <!-- Adresse -->
        <Card id="businessData-address">
            <h3>{{ $t('selfcare.show.section.unternehmensdaten.adresse') }}</h3>
            <div class="multi-address-input-wrapper">
                <!-- Adressart -->
                <div class="row">
                    <div class="col-12">
                        <Select
                            id="addresstype"
                            v-model="formData.identificationData.postalAddress.type"
                            :label="$t('postalAddress.addressType.label')"
                            :searchable="false"
                            :options="CONSTANTS.POSTAL_ADDRESS_TYPE_OPTIONS"
                            :disabled="isNotAllowedToSave"
                            data-qa="addresstype"
                        />
                    </div>
                    <div 
                        v-if="formData.identificationData.postalAddress.type !== null"
                        class="col-12"
                    >
                        <p class="mb-small">
                            {{ $t('selfcare.fullAddress') }}
                        </p>
                    </div>
                </div>
                <div v-if="formData.identificationData.postalAddress.type !== null">
                    <!-- Strasse / Hausnummer -->
                    <div 
                        v-if="formData.identificationData.postalAddress.type === 'DELIVERY_ADDRESS'"
                        class="row"
                    >
                        <div class="col-12 col-md-9">
                            <InputText
                                v-model="formData.identificationData.postalAddress.street"
                                :label="$t('postalAddress.street.label')"
                                name="postalAddress_street"
                                :readonly="isNotAllowedToSave"
                                :required="true"
                                data-qa="street"
                                error-msg-data-qa="street-message"
                            />
                        </div>
                        <div class="col-12 col-md-3">
                            <InputText
                                v-model="formData.identificationData.postalAddress.houseNumberPlusSuffix"
                                :label="$t('postalAddress.number.label')"
                                name="postalAddress_houseNumber"
                                :readonly="isNotAllowedToSave"
                                :required="true"
                                data-qa="houseNumber"
                                error-msg-data-qa="houseNumber-message"
                            />
                        </div>
                    </div>
                    <!-- Postfach -->
                    <div 
                        v-if="formData.identificationData.postalAddress.type === 'POST_OFFICE_BOX'"
                        class="row"
                    >
                        <div class="col-12">
                            <InputText
                                v-model="formData.identificationData.postalAddress.postOfficeBoxNumber"
                                :label="$t('selfcare.show.section.unternehmensdaten.adresse.postfach')"
                                name="postalAddress_postOfficeBoxNumber"
                                :placeholder="$t('postalAddress.postbox.placeholder')"
                                :readonly="isNotAllowedToSave"
                                data-qa="postOfficeBoxNumber"
                                error-msg-data-qa="postOfficeBoxNumber-message"
                            />
                        </div>
                    </div>
                    <!-- PLZ / Ort -->
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <InputText
                                v-model="formData.identificationData.postalAddress.zipCode"
                                :label="$t('postalAddress.zipCode.label')"
                                name="postalAddress_zipCode"
                                :placeholder="$t('postalAddress.zipCode.placeholder')"
                                :readonly="isNotAllowedToSave"
                                :required="true"
                                data-qa="zipCode"
                                error-msg-data-qa="zipCode-message"
                            />
                        </div>
                        <div class="col-12 col-md-9">
                            <InputText
                                v-model="formData.identificationData.postalAddress.city"
                                :label="$t('postalAddress.city.label')"
                                name="postalAddress_city"
                                :placeholder="$t('postalAddress.city.placeholder')"
                                :readonly="isNotAllowedToSave"
                                :required="true"
                                data-qa="city"
                                error-msg-data-qa="city-message"
                            />
                        </div>
                    </div>
                    <!-- Land -->
                    <div class="row">
                        <div class="col-12">
                            <Select
                                id="identificationData_country"
                                v-model="formData.identificationData.postalAddress.country"
                                :label="$t('postalAddress.country.label')"
                                :options="countries"
                                :required="true"
                                :disabled="isNotAllowedToSave"
                                data-qa="country"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Card>
        
        <!-- Kommunikation -->
        <Card id="businessData-communication">
            <h3>{{ $t('selfcare.show.section.unternehmensdaten.kommunikation') }}</h3>
            <!-- Telefon -->
            <div class="row">
                <div class="col-12 col-md-3">
                    <Select
                        id="phoneCountryDialingCode"
                        v-model="formData.identificationData.phone.countryDialingCode"
                        :label="$t('selfcare.show.section.unternehmensdaten.kommunikation.telefon')"
                        :searchable="true"
                        :options="countryDialingCodes"
                        :disabled="isNotAllowedToSave"
                        data-qa="selfcare-business-tel1"
                    />
                </div>
                <div class="col-12 col-md-3">
                    <InputText
                        v-model="formData.identificationData.phone.areaDialingCode"
                        label=""
                        :margin-on-empty-label="true"
                        name="phone_areaDialingCode"
                        :readonly="isNotAllowedToSave"
                        :required="true"
                        :placeholder="$t('shared.phone.areaCode')"
                        data-qa="selfcare-business-tel2"
                        error-msg-data-qa="selfcare-business-tel2-message"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <InputText
                        v-model="formData.identificationData.phone.subscriberNumberWithExtension"
                        label=""
                        :margin-on-empty-label="true"
                        name="phone_subscriberNumber"
                        :readonly="isNotAllowedToSave"
                        :required="true"
                        :placeholder="$t('shared.phone.number')"
                        data-qa="selfcare-business-tel3"
                        error-msg-data-qa="selfcare-business-tel3-message"
                    />
                </div>
            </div>
            <!-- Fax -->
            <div class="row">
                <div class="col-12 col-md-3">
                    <Select
                        id="faxCountryDialingCode"
                        v-model="formData.identificationData.fax.countryDialingCode"
                        :label="$t('selfcare.show.section.unternehmensdaten.kommunikation.telefax')"
                        :searchable="true"
                        :options="countryDialingCodes"
                        :disabled="isNotAllowedToSave"
                        data-qa="selfcare-business-fax1"
                    />
                </div>
                <div class="col-12 col-md-3">
                    <InputText
                        v-model="formData.identificationData.fax.areaDialingCode"
                        label=""
                        :margin-on-empty-label="true"
                        name="fax_areaDialingCode"
                        :readonly="isNotAllowedToSave"
                        :required="true"
                        :placeholder="$t('shared.phone.areaCode')"
                        data-qa="selfcare-business-fax2"
                        error-msg-data-qa="selfcare-business-fax2-message"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <InputText
                        v-model="formData.identificationData.fax.subscriberNumberWithExtension"
                        label=""
                        :margin-on-empty-label="true"
                        name="fax_subscriberNumber"
                        :readonly="isNotAllowedToSave"
                        :required="true"
                        :placeholder="$t('shared.phone.number')"
                        data-qa="selfcare-business-fax3"
                        error-msg-data-qa="selfcare-business-fax3-message"
                    />
                </div>
            </div>
            <!-- Mobil -->
            <div class="row">
                <div class="col-12 col-md-3">
                    <Select
                        id="mobilCountryDialingCode"
                        v-model="formData.identificationData.mobile.countryDialingCode"
                        :label="$t('selfcare.show.section.unternehmensdaten.kommunikation.mobilNummer')"
                        :searchable="true"
                        :options="countryDialingCodes"
                        :disabled="isNotAllowedToSave"
                        data-qa="selfcare-business-mobil1"
                    />
                </div>
                <div class="col-12 col-md-3">
                    <InputText
                        v-model="formData.identificationData.mobile.areaDialingCode"
                        label=""
                        :margin-on-empty-label="true"
                        name="mobil_areaDialingCode"
                        :readonly="isNotAllowedToSave"
                        :required="true"
                        :placeholder="$t('shared.phone.areaCode')"
                        data-qa="selfcare-business-mobil2"
                        error-msg-data-qa="selfcare-business-mobil2-message"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <InputText
                        v-model="formData.identificationData.mobile.subscriberNumberWithExtension"
                        label=""
                        :margin-on-empty-label="true"
                        name="mobil_subscriberNumber"
                        :readonly="isNotAllowedToSave"
                        :required="true"
                        :placeholder="$t('shared.phone.number')"
                        data-qa="selfcare-business-mobil3"
                        error-msg-data-qa="selfcare-business-mobil3-message"
                    />
                </div>
            </div>
            <!-- Email -->
            <InputText
                v-model="formData.identificationData.email"
                :label="$t('selfcare.show.section.unternehmensdaten.kommunikation.emailAdresse')"
                name="mail"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-email"
                error-msg-data-qa="selfcare-business-email-message"
            />
            <!-- Webseite -->
            <InputText
                v-model="formData.identificationData.website"
                :label="$t('selfcare.show.section.unternehmensdaten.kommunikation.webAdresse')"
                name="website"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-web"
                error-msg-data-qa="selfcare-business-web-message"
            />
        </Card>
        
        <!-- Steuerdaten -->
        <Card id="businessData-taxData">
            <h3>{{ $t('selfcare.show.section.unternehmensdaten.steuerdaten') }}</h3>
            <InputText
                v-model="formData.taxData.vatNumber"
                :label="$t('selfcare.show.section.unternehmensdaten.steuerdaten.umsatzsteuerId')"
                name="vatNumber"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-vatno"
                error-msg-data-qa="selfcare-business-vatno-message"
            />
            <InputText
                v-model="formData.taxData.taxNumber"
                :label="$t('selfcare.show.section.unternehmensdaten.steuerdaten.steuernummer')"
                name="taxNumber"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-taxNumber"
                error-msg-data-qa="selfcare-business-taxNumber-message"
            />
        </Card>
        
        <!-- Funktionsbeteiligte -->
        <Card id="businessData-management">
            <h3>
                {{ $t('selfcare.show.section.unternehmensdaten.funktionsbeteiligte') }}
                <span v-if="formData.managementData.managers.length > 1">
                    ({{ formData.managementData.managers.length }})
                </span>
            </h3>
            <div 
                v-for="(manager, index) in formData.managementData.managers"
                :key="index"
                class="manager box-shadow-xy mb"
            >
                <div class="box-spacing">
                    <div 
                        v-if="manager.canBeDeleted"
                        class="delete-manager"
                    >
                        <a 
                            class="text-brand-blue"
                            :data-qa="`selfcare-business-manager-delete-${index}`"
                            @click.prevent="deleteManager(index)"
                        >
                            {{ $t('shared.delete') }}
                            <font-awesome-icon
                                icon="fa-light fa-trash-can"
                            />
                        </a>
                    </div>
                    <InputText
                        v-model="manager.capacity"
                        :label="$t('selfcare.show.section.unternehmensdaten.geschaftsfuehrung.capacity')"
                        :name="`managers.manager${index}_Capacity`"
                        :readonly="isNotAllowedToSave"
                        :placeholder="$t('selfcare.show.section.unternehmensdaten.geschaftsfuehrung.capacity.placeholder')"
                        :data-qa="`selfcare-business-manager-capacity-${index}`"
                        :error-msg-data-qa="`selfcare-business-manager-capacity-${index}-message`"
                    />
                    <InputText
                        v-model="manager.givenName"
                        :label="$t('selfcare.show.section.unternehmensdaten.geschaftsfuehrung.vorname')"
                        :name="`managers.manager${index}_Givenname`"
                        :readonly="isNotAllowedToSave"
                        :data-qa="`selfcare-business-manager-givenname-${index}`"
                        :error-msg-data-qa="`selfcare-business-manager-givenname-${index}-message`"
                    />
                    <InputText
                        v-model="manager.surname"
                        :label="$t('selfcare.show.section.unternehmensdaten.geschaftsfuehrung.nachname')"
                        :name="`managers.manager${index}_Surname`"
                        :readonly="isNotAllowedToSave"
                        :data-qa="`selfcare-business-manager-surname-${index}`"
                        :error-msg-data-qa="`selfcare-business-manager-surname-${index}-message`"
                    />
                    <div :data-qa="`selfcare-business-manager-address-${index}`">
                        <!-- Strasse / Hausnummer -->
                        <div class="row">
                            <div class="col-12 col-md-9">
                                <InputText
                                    v-model="manager.postalAddress.street"
                                    :label="$t('postalAddress.street.label')"
                                    :name="`managers.manager${index}_postalAddress_street`"
                                    :readonly="isNotAllowedToSave"
                                    data-qa="street"
                                    error-msg-data-qa="street-message"
                                />
                            </div>
                            <div class="col-12 col-md-3">
                                <InputText
                                    v-model="manager.postalAddress.houseNumberPlusSuffix"
                                    :label="$t('postalAddress.number.label')"
                                    :name="`managers.manager${index}_postalAddress_houseNumber`"
                                    :readonly="isNotAllowedToSave"
                                    data-qa="houseNumber"
                                    error-msg-data-qa="houseNumber-message"
                                />
                            </div>
                        </div>
                        <!-- PLZ / Ort -->
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <InputText
                                    v-model="manager.postalAddress.zipCode"
                                    :label="$t('postalAddress.zipCode.label')"
                                    :name="`managers.manager${index}_postalAddress_zipCode`"
                                    :placeholder="$t('postalAddress.zipCode.placeholder')"
                                    :readonly="isNotAllowedToSave"
                                    data-qa="zipCode"
                                    error-msg-data-qa="zipCode-message"
                                />
                            </div>
                            <div class="col-12 col-md-9">
                                <InputText
                                    v-model="manager.postalAddress.city"
                                    :label="$t('postalAddress.city.label')"
                                    :name="`managers.manager${index}_postalAddress_city`"
                                    :readonly="isNotAllowedToSave"
                                    :placeholder="$t('postalAddress.city.placeholder')"
                                    data-qa="city"
                                    error-msg-data-qa="city-message"
                                />
                            </div>
                        </div>
                        <!-- Land -->
                        <Select
                            :id="`manager${index}_country`"
                            v-model="manager.postalAddress.country"
                            data-qa="country"
                            :label="$t('postalAddress.country.label')"
                            :options="countries"
                            :disabled="isNotAllowedToSave"
                        />
                    </div>
                    <!-- Geburtsdatum  -->
                    <DatePicker
                        :id="`manager${index}_dateOfBirth`"
                        v-model="manager.calculatedBirthdate"
                        :name="`manager${index}_dateOfBirth`"
                        :data-qa="`selfcare-business-manager-birthdate-${index}`"
                        :label="$t('collectionorder.debtor.birthdate')"
                        :disabled="isNotAllowedToSave"
                    />
                </div>
            </div>
            <div class="add-manager">
                <button
                    class="btn btn-secondary"
                    type="button"
                    :data-qa="`selfcare-business-manager-add${isNotAllowedToSave ? '-disabled' : ''}`"
                    :disabled="isNotAllowedToSave"
                    @click="addManager"
                >
                    {{ $t('selfcare.show.section.unternehmensdaten.newPerson') }}
                </button>
            </div>
        </Card>
        
        <!-- Geschäftsgegenstand -->
        <Card id="businessData-economicSector">
            <h3>{{ $t('selfcare.show.section.unternehmensdaten.geschaeftsgegenstand') }}</h3>
            <InputText
                v-model="formData.economicSectorData.sectorDescription"
                :label="$t('selfcare.show.section.unternehmensdaten.geschaeftsgegenstand.branchenbezeichnungGeschaeftsgegenstand')"
                name="sectorDescription"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-sectorDescription"
                error-msg-data-qa="selfcare-business-sectorDescription-message"
            />
        </Card>
        
        <!-- Import -->
        <Card id="businessData-importExport">
            <h3>{{ $t('selfcare.show.section.unternehmensdaten.import') }}</h3>
            <Radio
                id="importRadio"
                v-model="importRadio"
                radio-group-name="importRadio"
                :radio-options="[
                    { value: true, label: $t('selfcare.show.section.unternehmensdaten.import.ja'), id: 'importRadioTrue', dataQa: 'selfcare-business-hasImport-yes' },
                    { value: false, label: $t('selfcare.show.section.unternehmensdaten.import.nein'), id: 'importRadioFalse', dataQa: 'selfcare-business-hasImport-no' }
                ]"
                :label="$t('selfcare.show.section.unternehmensdaten.import.hinweis')"
                :disabled="isNotAllowedToSave"
                data-qa="importRadioGroup"
            />
            <div v-if="importRadio === true">
                <div class="row">
                    <div class="col-12">
                        <InputText
                            v-model="formData.foreignTradeData.importPercentage"
                            :label="$t('selfcare.show.section.unternehmensdaten.import.importInProzent')"
                            name="importGoodsValue"
                            :readonly="isNotAllowedToSave"
                            data-qa="selfcare-business-import"
                            error-msg-data-qa="selfcare-business-import-message"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <Textarea
                            v-model="formData.foreignTradeData.importCountryNames"
                            :label="$t('selfcare.show.section.unternehmensdaten.import.wichtigeImportlaender')"
                            name="importGoodsCountries"
                            :readonly="isNotAllowedToSave"
                            data-qa="selfcare-business-importCountries"
                            error-msg-data-qa="selfcare-business-importCountries-message"
                        />
                    </div>
                </div>
            </div>
        </Card>
        
        <!-- Export -->
        <Card id="businessData-export">
            <h3>{{ $t('selfcare.show.section.unternehmensdaten.export') }}</h3>
            <Radio
                id="exportRadio"
                v-model="exportRadio"
                radio-group-name="exportRadio"
                :radio-options="[
                    { value: true, label: $t('selfcare.show.section.unternehmensdaten.export.ja'), id: 'exportRadioTrue', dataQa: 'selfcare-business-hasExport-yes' },
                    { value: false, label: $t('selfcare.show.section.unternehmensdaten.export.nein'), id: 'exportRadioFalse', dataQa: 'selfcare-business-hasExport-no' }
                ]"
                :label="$t('selfcare.show.section.unternehmensdaten.export.hinweis')"
                :disabled="isNotAllowedToSave"
                data-qa="exportRadioGroup"
            />
            <div v-if="exportRadio === true">
                <div class="row">
                    <div class="col-12">
                        <InputText
                            v-model="formData.foreignTradeData.exportPercentage"
                            :label="$t('selfcare.show.section.unternehmensdaten.export.exportInProzent')"
                            name="exportGoodsValue"
                            :readonly="isNotAllowedToSave"
                            data-qa="selfcare-business-export"
                            error-msg-data-qa="selfcare-business-export-message"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <Textarea
                            v-model="formData.foreignTradeData.exportCountryNames"
                            :label="$t('selfcare.show.section.unternehmensdaten.export.wichtigeExportlaender')"
                            name="exportGoodsCountries"
                            :readonly="isNotAllowedToSave"
                            data-qa="selfcare-business-exportCountries"
                            error-msg-data-qa="selfcare-business-exportCountries-message"
                        />
                    </div>
                </div>
            </div>
        </Card>

        <!-- Umsatz- und Mitarbeiterangaben -->
        <Card id="businessData-salesEmployee">
            <h3>{{ $t('selfcare.show.section.unternehmensdaten.umsatzUndMitarbeiterangaben') }}</h3>
            <div class="row">
                <div class="col-6">
                    <InputText
                        v-model="formData.employeesData.employeesCurrent"
                        :label="`${$t('selfcare.show.section.unternehmensdaten.umsatzUndMitarbeiterangaben.mitarbeitergesamtzahl')} ${$t('selfcare.show.section.unternehmensdaten.umsatzUndMitarbeiterangaben.aktuellesjahr')}`"
                        name="employeesCurrent"
                        :readonly="isNotAllowedToSave"
                        data-qa="selfcare-business-employees-currentyear"
                        error-msg-data-qa="selfcare-business-employees-currentyear-message"
                    />
                </div>
                <div class="col-6">
                    <InputText
                        v-model="formData.employeesData.employeesPreviousYear"
                        :label="`${$t('selfcare.show.section.unternehmensdaten.umsatzUndMitarbeiterangaben.mitarbeitergesamtzahl')} ${$t('selfcare.show.section.unternehmensdaten.umsatzUndMitarbeiterangaben.vorjahr')}`"
                        name="employeesPreviousYear"
                        :readonly="isNotAllowedToSave"
                        data-qa="selfcare-business-employees-lastyear"
                        error-msg-data-qa="selfcare-business-employees-lastyear-message"
                    /> 
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <InputText
                        v-model="formData.turnoverData.turnoverExpected.value"
                        :label="`${$t('selfcare.show.section.unternehmensdaten.umsatzUndMitarbeiterangaben.umsatzerwartung')} ${$t('selfcare.show.section.unternehmensdaten.umsatzUndMitarbeiterangaben.aktuellesjahr')} (${!!formData.turnoverData.turnoverExpected.currency ? formData.turnoverData.turnoverExpected.currency : 'EUR'})`"
                        name="turnoverCurrentYear"
                        :readonly="isNotAllowedToSave"
                        maxlength="22"
                        data-qa="selfcare-business-sales-currentyear"
                        error-msg-data-qa="selfcare-business-sales-currentyear-message"
                    />
                </div>
                <div class="col-6">
                    <InputText
                        v-model="formData.turnoverData.turnoverPreviousYear.value"
                        :label="`${$t('selfcare.show.section.unternehmensdaten.umsatzUndMitarbeiterangaben.umsatzerwartung')} ${$t('selfcare.show.section.unternehmensdaten.umsatzUndMitarbeiterangaben.vorjahr')} (${!!formData.turnoverData.turnoverPreviousYear.currency ? formData.turnoverData.turnoverPreviousYear.currency : 'EUR'})`"
                        name="turnoverPreviousYear"
                        :readonly="isNotAllowedToSave"
                        data-qa="selfcare-business-sales-lastyear"
                        error-msg-data-qa="selfcare-business-sales-lastyear-message"
                    /> 
                </div>
            </div>
        </Card>
        
        <!-- Geschäftsbank -->
        <Card id="businessData-bankData">
            <h3>
                {{ $t('selfcare.show.section.unternehmensdaten.bankverbindung') }}
                <span v-if="formData.bankingDetailsData.bankingConnections.length > 1">
                    ({{ formData.bankingDetailsData.bankingConnections.length }})
                </span>
            </h3>
            <div 
                v-for="(bankingConnection, index) in formData.bankingDetailsData.bankingConnections"
                :key="index"
                class="banking-connection box-shadow-xy mb"
            >
                <div class="box-spacing">
                    <div 
                        v-if="bankingConnection.canBeDeleted"
                        class="delete-bank"
                    >
                        <a 
                            class="text-brand-blue"
                            :data-qa="`selfcare-business-bank-remove-${index}`"
                            @click.prevent="deleteBank(index)"
                        >
                            {{ $t('shared.delete') }}
                            <font-awesome-icon
                                icon="fa-light fa-trash-can"
                            />
                        </a>
                    </div>
                    <InputText
                        v-model="bankingConnection.bankName"
                        :label="$t('selfcare.show.section.unternehmensdaten.bankverbindung.nameDerBank')"
                        :name="`bankingConnections.bankingConnection${index}_bankName`"
                        :readonly="isNotAllowedToSave"
                        :data-qa="`selfcare-business-bank-name-${index}`"
                        :error-msg-data-qa="`selfcare-business-bank-name-${index}-message`"
                    />
                    <InputText
                        v-model="bankingConnection.city"
                        :label="$t('selfcare.show.section.unternehmensdaten.bankverbindung.ortDerBank')"
                        :name="`bankingConnections.bankingConnection${index}_city`"
                        :readonly="isNotAllowedToSave"
                        :data-qa="`selfcare-business-bank-city-${index}`"
                        :error-msg-data-qa="`selfcare-business-bank-city-${index}-message`"
                    />
                </div>  
            </div>
            <div class="add-bank">
                <button
                    class="btn btn-secondary"
                    type="button"
                    :data-qa="`selfcare-business-bank-add${isNotAllowedToSave ? '-disabled' : ''}`"
                    :disabled="isNotAllowedToSave"
                    @click="addBank"
                >
                    {{ $t('selfcare.show.section.unternehmensdaten.furtherBankverbindung') }}
                </button>
            </div>
        </Card>
        
        <!-- Unternehmensstandorte -->
        <Card id="businessData-locations">
            <h3>
                {{ $t('selfcare.show.section.unternehmensdaten.unternehmensstandorte') }}
                <span v-if="formData.locationsData.businessLocations.length > 1">
                    ({{ formData.locationsData.businessLocations.length }})
                </span>
            </h3>
            <p class="mb-small">
                {{ $t('selfcare.show.section.unternehmensdaten.unternehmensstandorte.hinweis') }}
            </p>
            <div 
                v-for="(location, index) in formData.locationsData.businessLocations"
                :key="index"
                class="location box-shadow-xy mb"
            >
                <div class="box-spacing">
                    <div 
                        v-if="location.canBeDeleted"
                        class="delete-location"
                    >
                        <a 
                            class="text-brand-blue"
                            :data-qa="`selfcare-business-location-remove-${index}`"
                            @click.prevent="deleteLocation(index)"
                        >
                            {{ $t('shared.delete') }}
                            <font-awesome-icon
                                icon="fa-light fa-trash-can"
                            />
                        </a>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <Select
                                :id="`businessLocations.location${index}_type`"
                                v-model="location.locationType"
                                :label="$t('selfcare.show.section.unternehmensdaten.unternehmensstandorte.artDesStandorts')"
                                :searchable="false"
                                :options="CONSTANTS.BUSINESS_LOCATION_TYPE_OPTIONS"
                                :disabled="isNotAllowedToSave"
                                :data-qa="`selfcare-business-location-type-${index}`"
                            />
                        </div>
                    </div>
                    <div :data-qa="`selfcare-business-location-address-${index}`">
                        <!-- Strasse / Hausnummer -->
                        <div class="row">
                            <div class="col-12 col-md-9">
                                <InputText
                                    v-model="location.postalAddress.street"
                                    :label="$t('postalAddress.street.label')"
                                    :name="`businessLocations.location${index}_postalAddress_street`"
                                    :readonly="isNotAllowedToSave"
                                    data-qa="street"
                                    error-msg-data-qa="street-message"
                                />
                            </div>
                            <div class="col-12 col-md-3">
                                <InputText
                                    v-model="location.postalAddress.houseNumberPlusSuffix"
                                    :label="$t('postalAddress.number.label')"
                                    :name="`businessLocations.location${index}_postalAddress_houseNumber`"
                                    :readonly="isNotAllowedToSave"
                                    data-qa="houseNumber"
                                    error-msg-data-qa="houseNumber-message"
                                />
                            </div>
                        </div>
                        <!-- PLZ / Ort -->
                        <div class="row">
                            <div class="col-12 col-md-3">
                                <InputText
                                    v-model="location.postalAddress.zipCode"
                                    :label="$t('postalAddress.zipCode.label')"
                                    :name="`businessLocations.location${index}_postalAddress_zipCode`"
                                    :placeholder="$t('postalAddress.zipCode.placeholder')"
                                    :readonly="isNotAllowedToSave"
                                    data-qa="zipCode"
                                    error-msg-data-qa="zipCode-message"
                                />
                            </div>
                            <div class="col-12 col-md-9">
                                <InputText
                                    v-model="location.postalAddress.city"
                                    :label="$t('postalAddress.city.label')"
                                    :name="`businessLocations.location${index}_postalAddress_city`"
                                    :placeholder="$t('postalAddress.city.placeholder')"
                                    :readonly="isNotAllowedToSave"
                                    data-qa="city"
                                    error-msg-data-qa="city-message"
                                />
                            </div>
                        </div>
                        <!-- Land -->
                        <Select
                            :id="`location${index}_country`"
                            v-model="location.postalAddress.country"
                            data-qa="country"
                            :label="$t('postalAddress.country.label')"
                            :options="countries"
                            :disabled="isNotAllowedToSave"
                        />
                    </div>
                </div>
            </div>
            <div class="add-bank">
                <button
                    class="btn btn-secondary"
                    type="button"
                    :data-qa="`selfcare-business-location-add${isNotAllowedToSave ? '-disabled' : ''}`"
                    :disabled="isNotAllowedToSave"
                    @click="addLocation(true)"
                >
                    {{ $t('selfcare.show.section.unternehmensdaten.unternehmensstandorte.weiterenStandortHinzufuegen') }}
                </button>
            </div>
        </Card>
        
        <!-- Ansprechpartner -->
        <Card id="businessData-contactPerson">
            <h3>{{ $t('selfcare.show.section.unternehmensdaten.ansprechpartner') }}</h3>
            <p class="mb-small">
                {{ $t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.hinweis') }}
            </p>
            <InputText
                v-model="formData.contactPerson!.givenName"
                :label="$t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.vorname')"
                name="contactPerson_Givenname"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-contact-givenname"
                error-msg-data-qa="selfcare-business-contact-givenname-message"
            />
            <InputText
                v-model="formData.contactPerson!.surname"
                :label="$t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.nachname')"
                name="contactPerson_Surname"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-contact-surname"
                error-msg-data-qa="selfcare-business-contact-surname-message"
            />
            <!-- Telefon -->
            <div class="row">
                <div class="col-12 col-md-3">
                    <Select
                        id="phoneCountryDialingCode"
                        v-model="formData.contactPerson.phone.countryDialingCode"
                        :label="$t('selfcare.show.section.unternehmensdaten.kommunikation.telefon')"
                        :searchable="true"
                        :options="countryDialingCodes"
                        :disabled="isNotAllowedToSave"
                        data-qa="selfcare-business-contact-tel1"
                    />
                </div>
                <div class="col-12 col-md-3">
                    <InputText
                        v-model="formData.contactPerson.phone.areaDialingCode"
                        label=""
                        :margin-on-empty-label="true"
                        name="contactPerson_phone_areaDialingCode"
                        :readonly="isNotAllowedToSave"
                        :required="true"
                        :placeholder="$t('shared.phone.areaCode')"
                        data-qa="selfcare-business-contact-tel2"
                        error-msg-data-qa="selfcare-business-contact-tel2-message"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <InputText
                        v-model="formData.contactPerson.phone.subscriberNumberWithExtension"
                        label=""
                        :margin-on-empty-label="true"
                        name="contactPerson_phone_subscriberNumber"
                        :readonly="isNotAllowedToSave"
                        :required="true"
                        :placeholder="$t('shared.phone.number')"
                        data-qa="selfcare-business-contact-tel3"
                        error-msg-data-qa="selfcare-business-contact-tel3-message"
                    />
                </div>
            </div>
            <InputText
                v-model="formData.contactPerson!.email"
                :label="$t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.emailAdresse')"
                name="contactPerson_Email"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-contact-email"
                error-msg-data-qa="selfcare-business-contact-email-message"
            />
            <InputText
                v-model="formData.contactPerson!.function"
                :label="$t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.funktionImUnternehmen')"
                name="contactPerson_Function"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-contact-function"
                error-msg-data-qa="selfcare-business-contact-function-message"
            />
        </Card>
        
        <!-- Weitere Angaben -->
        <Card id="businessData-misc">
            <h3>{{ $t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.weitereAngaben') }}</h3>
            <InputText
                v-model="formData.customerBase.customerCount"
                :label="$t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.anzahlDerKunden')"
                name="customerCount"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-details-customer-count"
                error-msg-data-qa="selfcare-business-details-customer-count-message"
            />
            <InputText
                v-model="formData.customerBase.percentageOfPrivatePersons"
                :label="$t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.davonPrivatpersonen')"
                name="customerPrivateValue"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-details-customer-private"
                error-msg-data-qa="selfcare-business-details-customer-private-message"
            />
            <InputText
                v-model="formData.customerBase.percentageOfCompanies"
                :label="$t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.davonUnternehmen')"
                name="customerBusinessValue"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-details-customer-business"
                error-msg-data-qa="selfcare-business-details-customer-business-message"
            />
            <InputText
                v-model="formData.customerBase.percentageOfPublicSector"
                :label="$t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.davonOeffentlich')"
                name="customerPublicValue"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-details-customer-public"
                error-msg-data-qa="selfcare-business-details-customer-public-message"
            />
            <InputText
                v-model="formData.commercialCreditInsurance"
                :label="$t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.forderungsabsicherungDurchWKV')"
                name="commercialCreditInsurance"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-details-securing-receivables"
                error-msg-data-qa="selfcare-business-details-securing-receivables-message"
            />
            <InputText
                v-model="formData.remarks"
                :label="$t('selfcare.show.section.unternehmensdaten.branchenangabenUndAnsprechpartner.other')"
                name="remarks"
                :readonly="isNotAllowedToSave"
                data-qa="selfcare-business-details-other"
                error-msg-data-qa="selfcare-business-details-other-message"
            />
        </Card>
        
        <!-- SUBMIT -->
        <Card
            id="businessData-submit"
            style="padding: 20px; padding-left: 250px;"
        >
            <div class="mb-small">
                {{ $t('selfcare.checkHint') }}
            </div>
            <button
                class="btn btn-default"
                type="submit"
                :data-qa="`selfcare-business-submitButton${submitting || isNotAllowedToSave ? '-disabled' : ''}`"
                :disabled="submitting || isNotAllowedToSave"
            >
                {{ $t('selfcare.show.section.mitgliedsdaten.speichern') }}
            </button>
        </Card>
    </form>
    <Modal
        v-if="modal"
        :id="modal.id"
        :title="modal.title"
        :confirm-text="modal.confirmText"
        :icon="modal.icon"
        :data-qa="modal.dataQa"
        @on-confirm="onModalConfirm"
        @on-close="onModalConfirm"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="modal.content" />
    </Modal>
</template>

<script setup lang="ts">
const props = defineProps<{
    businessData: CbraBusinessData
}>()

import { computed, ref, toRef, onMounted, watch, nextTick } from 'vue'
import { useForm, useIsFormValid } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { object, string, array, coerce, literal } from 'zod'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import { useUserStore } from '@/stores/user'

import DatePicker from '@/components/Input/DatePicker.vue'
import Card from '@/components/Card.vue'
import InputText from '@/components/Input/InputText.vue'
import Radio from '@/components/Input/Radio.vue'
import Select from '@/components/Input/Select.vue'
import Textarea from '@/components/Input/Textarea.vue'
import Modal from '@/components/Modal.vue'

import {
    REGEXES,
    REQUIRED_STRING_ONLY_NUMBERS,
    STRING_ONLY_NUMBERS,
    EMAIL_VALIDATION,
    PERCENT_STRING_VALIDATION,
    VAT_VALIDATION,
    TAXNO_VALIDATION,
    REQUIRED_STRING_VALIDATION
} from '@/helper/zodSchema'
import { useScrollToFormError } from '@/helper/forms'
import { scrollTo } from '@/helper/scrollto'

import { 
    getMemberNavigationSession, 
    isSameMember 
} from '@/helper/services/memberNavigation'

import type { 
    CbraBusinessData,
    CbraBusinessDataFormLayout,
    CbraBusinessUpdateData
} from '@/types/cbra/businessData'
import type { ModalProps } from '@/types/components/modal'

import { updateCbraBusinessData } from '@/services'

import CONSTANTS from '@/data/constants'
import countries from '@/data/countries'
import countryDialingCodes from '@/data/countryDialingCodes'

const userStore = useUserStore()
const { cbraUser } = storeToRefs(userStore)

const importRadio = ref(false)
const exportRadio = ref(false)

const modal = ref<ModalProps>()

const { t } = useI18n()
const i18n = useI18n() 

const businessData = toRef(props.businessData)

const isNotAllowedToSave = computed(() => {
    // this.isNotAllowedToSave((!memberNavigationService.isSameMemberId(user.crMembernumber) && memberNavigationService.getMemberNavigationSession()) ? true : false);
    return getMemberNavigationSession() && 
        !isSameMember(cbraUser.value.crMembernumber) ? true : false
})

const formData: CbraBusinessDataFormLayout = ref({
    identificationData: {
        businessName: null,
        email: null,
        website: null,
        postalAddress: {
            type: null,
            street: '',
            houseNumberPlusSuffix: '',
            zipCode: '',
            city: '',
            country: 'DE',
            postOfficeBoxNumber: ''
        },
        phone: {
            countryDialingCode: '+49',
            areaDialingCode: '',
            subscriberNumber: '',
            extensionNumber: '',
            subscriberNumberWithExtension: ''
        },
        fax: {
            countryDialingCode: '+49',
            areaDialingCode: '',
            subscriberNumber: '',
            extensionNumber: '',
            subscriberNumberWithExtension: ''
        },
        mobile: {
            countryDialingCode: '+49',
            areaDialingCode: '',
            subscriberNumber: '',
            extensionNumber: '',
            subscriberNumberWithExtension: ''
        }
    },
    managementData: {
        managers: []
    },
    taxData: {
        vatNumber: null,
        taxNumber: null
    },
    turnoverData: {
        turnoverExpected: {
            value: '',
            currency: null
        },
        turnoverPreviousYear: {
            value: '',
            currency: null
        }
    },
    employeesData: {
        employeesCurrentYear: null,
        employeesPreviousYear: null
    },
    foreignTradeData: {
        import: {
            value: null,
            countries: ''
        },
        export: {
            value: null,
            countries: ''
        }
    },
    bankingDetailsData: {
        bankingConnections: []
    },
    locationsData: {
        businessLocations: []
    },
    economicSectorData: {
        sectorDescription: null
    },
    // always empty on GET, only used to update
    contactPerson: {
        email: null,
        function: null,
        name: '',
        givenName: '',
        surname: '',
        phone: {
            countryDialingCode: '+49',
            areaDialingCode: '',
            subscriberNumber: '',
            extensionNumber: '',
            subscriberNumberWithExtension: ''
        }
    },
    // always empty on GET, only used to update
    customerBase: {
        customerCount: null,
        percentageOfCompanies: null,
        percentageOfPrivatePersons: null,
        percentageOfPublicSector: null
    },
    // always empty on GET, only used to update
    commercialCreditInsurance: null,
    // always empty on GET, only used to update
    remarks: null
})

const addManager = (canBeDeleted: boolean = false) => {
    const newItem = {
        givenName: '',
        surname: '',
        birthday: null,
        capacity: null,
        postalAddress: {
            type: 'DELIVERY_ADDRESS',
            street: '',
            houseNumber: null,
            houseNumberSuffix: null,
            houseNumberPlusSuffix: '',
            zipCode: '',
            city: '',
            country: 'DE',
            postOfficeBoxNumber: ''
        },
        calculatedBirthdate: null
    }
    if (canBeDeleted) {
        newItem.canBeDeleted = true
    }
    formData.value.managementData.managers.push(newItem)
    addDynamicManagementValidationConfig(formData.value.managementData.managers.length - 1)
}

const deleteManager = (index: number) => {
    formData.value.managementData.managers.splice(index, 1)
    dynamicValidationConfig.value.splice(index, 1)
}

const addBank = (canBeDeleted: boolean = false) => {
    const newItem = {
        bankName: '',
        city: ''
    }
    if (canBeDeleted) {
        newItem.canBeDeleted = true
    }
    formData.value.bankingDetailsData.bankingConnections.push(newItem)
}

const deleteBank = (index: number) => {
    formData.value.bankingDetailsData.bankingConnections.splice(index, 1)
}

const addLocation = (canBeDeleted: boolean = false) => {
    const newItem = {
        locationType: null,
        postalAddress: {
            type: 'DELIVERY_ADDRESS',
            street: '',
            houseNumber: null,
            houseNumberSuffix: null,
            houseNumberPlusSuffix: '',
            zipCode: '',
            city: '',
            country: 'DE',
            postOfficeBoxNumber: null
        }
    }
    if (canBeDeleted) {
        newItem.canBeDeleted = true
    }
    formData.value.locationsData.businessLocations.push(newItem)
}

const deleteLocation = (index: number) => {
    formData.value.locationsData.businessLocations.splice(index, 1)
}

type ValidationConfig = { 
    fieldName: string
    type?: string
    minLength?: number
    maxLength?: number
    mandatory?: boolean
    nullish?: boolean 
}
const dynamicValidationConfig = ref<ValidationConfig[]>([])

const currencyValidationRule = computed(() => {
    return i18n.locale.value === 'en-US' ? REGEXES.currencyEN 
        : i18n.locale.value.startsWith('fr-') ? REGEXES.currencyFR : REGEXES.currencyDE
})

const formValidationSchema = computed(() => toTypedSchema(object({
    // Firmierung
    companyName: string().max(300, { message: t('errors.maxLength', { max: 300 }) }).nullish(),
    // Adresse
    postalAddress_postOfficeBoxNumber: dynamicPostalAddressRule('postalAddress_postOfficeBoxNumber'),
    postalAddress_street: dynamicPostalAddressRule('postalAddress_street'),
    postalAddress_houseNumber: dynamicPostalAddressRule('postalAddress_houseNumber'),
    postalAddress_city: dynamicPostalAddressRule('postalAddress_city'),
    postalAddress_zipCode: dynamicPostalAddressRule('postalAddress_zipCode'),
    // Kommunikation
    phone_areaDialingCode: dynamicPhoneRule('phone_areaDialingCode'),
    phone_subscriberNumber: dynamicPhoneRule('phone_subscriberNumber'),
    fax_areaDialingCode: dynamicPhoneRule('fax_areaDialingCode'),
    fax_subscriberNumber: dynamicPhoneRule('fax_subscriberNumber'),
    mobil_areaDialingCode: dynamicPhoneRule('mobil_areaDialingCode'),
    mobil_subscriberNumber: dynamicPhoneRule('mobil_subscriberNumber'),
    mail: EMAIL_VALIDATION(t).or(literal('')).nullish(),
    website: string().nullish(),
    // Steuerdaten
    vatNumber: VAT_VALIDATION(t).or(literal('')).nullish(),
    taxNumber: TAXNO_VALIDATION(t).or(literal('')).nullish(),
    // Funktionsbeteiligte
    managers: dynamicManagementDataRules(),
    // Geschäftsgegenstand
    sectorDescription: string().nullish(),
    // Import
    importGoodsValue: PERCENT_STRING_VALIDATION(t).or(literal('')).nullish(),
    importGoodsCountries: string().nullish(),
    // Export
    exportGoodsValue: PERCENT_STRING_VALIDATION(t).or(literal('')).nullish(),
    exportGoodsCountries: string().nullish(),
    // Umsatz- und Mitarbeiterangaben
    employeesCurrent: coerce.string().regex(REGEXES.numOnly, { message: t('error.form.mustNumber') }).regex(REGEXES.smallerThanAMillion, { message: t('errors.maxNumberAlt', { max: 999999 }) }).or(literal('')).nullish(),
    employeesPreviousYear: coerce.string().regex(REGEXES.numOnly, { message: t('error.form.mustNumber') }).regex(REGEXES.smallerThanAMillion, { message: t('errors.maxNumberAlt', { max: 999999 }) }).or(literal('')).nullish(),
    turnoverCurrentYear: coerce.string().regex(currencyValidationRule.value, { message: t('error.form.invalid.currency.' + i18n.locale.value.substr(0, 2)) }).max(22, { message: t('errors.maxLength', { max: 22 }) }).or(literal('')).nullish(),
    turnoverPreviousYear: coerce.string().regex(currencyValidationRule.value, { message: t('error.form.invalid.currency.' + i18n.locale.value.substr(0, 2)) }).max(22, { message: t('errors.maxLength', { max: 22 }) }).or(literal('')).nullish(),
    // Geschäftsbank
    bankingConnections: dynamicBankingConnectionsRules(),
    // Unternehmensstandorte
    businessLocations: dynamicBusinessLocationsRules(),
    // Ansprechpartner
    contactPerson_Givenname: dynamicContactPersonRules(),
    contactPerson_Surname: dynamicContactPersonRules(),
    contactPerson_Email: EMAIL_VALIDATION(t).or(literal('')).nullish(),
    contactPerson_phone_areaDialingCode: dynamicPhoneRule('contactPerson_phone_areaDialingCode'),
    contactPerson_phone_subscriberNumber: dynamicPhoneRule('contactPerson_phone_subscriberNumber'),
    // Weitere Angaben
    customerCount: STRING_ONLY_NUMBERS(t).or(literal('')).nullish(),
    customerPrivateValue: PERCENT_STRING_VALIDATION(t).or(literal('')).nullish(),
    customerBusinessValue: PERCENT_STRING_VALIDATION(t).or(literal('')).nullish(),
    customerPublicValue: PERCENT_STRING_VALIDATION(t).or(literal('')).nullish()
})))

if (businessData.value.identificationData !== null) {
    if (businessData.value.identificationData.businessName !== null) {
        formData.value.identificationData.businessName = businessData.value.identificationData.businessName
    }
    if (businessData.value.identificationData.email !== null) {
        formData.value.identificationData.email = businessData.value.identificationData.email
    }
    if (businessData.value.identificationData.website !== null) {
        formData.value.identificationData.website = businessData.value.identificationData.website
    }
    if (businessData.value.identificationData.phone !== null) {
        formData.value.identificationData.phone = businessData.value.identificationData.phone

        let combinedNumber = ''
        if (formData.value.identificationData.phone.subscriberNumber === '' || formData.value.identificationData.phone.subscriberNumber === null) combinedNumber = ''
        if (formData.value.identificationData.phone.extensionNumber === '' || formData.value.identificationData.phone.extensionNumber === null) combinedNumber = '' + formData.value.identificationData.phone.subscriberNumber
        else combinedNumber = `${formData.value.identificationData.phone.subscriberNumber}-${formData.value.identificationData.phone.extensionNumber}`

        formData.value.identificationData.phone.subscriberNumberWithExtension = combinedNumber
    }
    if (businessData.value.identificationData.fax !== null) {
        formData.value.identificationData.fax = businessData.value.identificationData.fax
        
        let combinedNumber = ''
        if (formData.value.identificationData.fax.subscriberNumber === '' || formData.value.identificationData.fax.subscriberNumber === null) combinedNumber = ''
        if (formData.value.identificationData.fax.extensionNumber === '' || formData.value.identificationData.fax.extensionNumber === null) combinedNumber = '' + formData.value.identificationData.fax.subscriberNumber
        else combinedNumber = `${formData.value.identificationData.fax.subscriberNumber}-${formData.value.identificationData.fax.extensionNumber}`

        formData.value.identificationData.fax.subscriberNumberWithExtension = combinedNumber
    }
    if (businessData.value.identificationData.mobile !== null) {
        formData.value.identificationData.mobile = businessData.value.identificationData.mobile

        let combinedNumber = ''
        if (formData.value.identificationData.mobile.subscriberNumber === '' || formData.value.identificationData.mobile.subscriberNumber === null) combinedNumber = ''
        if (formData.value.identificationData.mobile.extensionNumber === '' || formData.value.identificationData.mobile.extensionNumber === null) combinedNumber = '' + formData.value.identificationData.mobile.subscriberNumber
        else combinedNumber = `${formData.value.identificationData.mobile.subscriberNumber}-${formData.value.identificationData.mobile.extensionNumber}`

        formData.value.identificationData.mobile.subscriberNumberWithExtension = combinedNumber
    }
    if (businessData.value.identificationData.postalAddress !== null) {
        formData.value.identificationData.postalAddress = businessData.value.identificationData.postalAddress
        let combinedHousenumber = ''
        if (formData.value.identificationData.postalAddress.houseNumber === '' || formData.value.identificationData.postalAddress.houseNumber === null) combinedHousenumber = ''
        if (formData.value.identificationData.postalAddress.houseNumberSuffix === '' || formData.value.identificationData.postalAddress.houseNumberSuffix === null) combinedHousenumber = '' + formData.value.identificationData.postalAddress.houseNumber
        else combinedHousenumber = `${formData.value.identificationData.postalAddress.houseNumber}${formData.value.identificationData.postalAddress.houseNumberSuffix}`

        formData.value.identificationData.postalAddress.houseNumberPlusSuffix = combinedHousenumber
    }
}

if (businessData.value.taxData !== null) {
    if (businessData.value.taxData.vatNumber !== null) {
        formData.value.taxData.vatNumber = businessData.value.taxData.vatNumber
    }
    if (businessData.value.taxData.taxNumber !== null) {
        formData.value.taxData.taxNumber = businessData.value.taxData.taxNumber
    }
}

if (businessData.value.managementData !== null) {
    if (businessData.value.managementData.managers.length) {
        formData.value.managementData.managers = businessData.value.managementData.managers
        businessData.value.managementData.managers.forEach((manager, index) => {
            const _manager = formData.value.managementData.managers[index]

            if (_manager.capacity !== null && _manager.capacity !== '' && /^\d+$/.test(_manager.capacity)) {
                _manager.capacity = t(`selfcare.show.section.unternehmensdaten.geschaftsfuehrung.capacity.${_manager.capacity}`)
            }

            let combinedHousenumber = ''
            if (_manager.postalAddress.houseNumber === '' || _manager.postalAddress.houseNumber === null) combinedHousenumber = ''
            if (_manager.postalAddress.houseNumberSuffix === '' || _manager.postalAddress.houseNumberSuffix === null) combinedHousenumber = '' + _manager.postalAddress.houseNumber
            else combinedHousenumber = `${_manager.postalAddress.houseNumber}${_manager.postalAddress.houseNumberSuffix}`
            formData.value.managementData.managers[index].postalAddress.houseNumberPlusSuffix = combinedHousenumber

            if (_manager.postalAddress.zipCode == null) {
                _manager.postalAddress.zipCode = ''
            }

            if (_manager.postalAddress.houseNumberPlusSuffix == 'null') {
                _manager.postalAddress.houseNumberPlusSuffix = ''
            }

            if (_manager.birthday !== null) {
                formData.value.managementData.managers[index].calculatedBirthdate = new Date(_manager.birthday.stringRepresentation.split('+')[0])
            }
            addDynamicManagementValidationConfig(index)
        })
    } else {
        // always initialize form with at least one manager
        addManager()
    }
} else {
    // always initialize form with at least one manager
    addManager()
}

if (businessData.value.economicSectorData !== null) {
    formData.value.economicSectorData.sectorDescription = businessData.value.economicSectorData.sectorDescription
}

if (businessData.value.foreignTradeData !== null) {
    formData.value.foreignTradeData = businessData.value.foreignTradeData
    if (
        businessData.value.foreignTradeData.importPercentage !== null ||
        businessData.value.foreignTradeData.importCountryNames !== null
    ) {
        importRadio.value = true
    }
    if (
        businessData.value.foreignTradeData.exportPercentage !== null ||
        businessData.value.foreignTradeData.exportCountryNames !== null
    ) {
        exportRadio.value = true
    }
}

if (businessData.value.turnoverData !== null) {
    if (businessData.value.turnoverData.turnoverExpected !== null) {
        if (businessData.value.turnoverData.turnoverExpected.value !== null) {
            formData.value.turnoverData.turnoverExpected.value = businessData.value.turnoverData.turnoverExpected.value.toLocaleString(i18n.locale.value)
        }
        if (businessData.value.turnoverData.turnoverExpected.currency !== null) {
            formData.value.turnoverData.turnoverExpected.currency = businessData.value.turnoverData.turnoverExpected.currency
        }
    }
    if (businessData.value.turnoverData.turnoverPreviousYear !== null) {
        if (businessData.value.turnoverData.turnoverPreviousYear.value !== null) {
            formData.value.turnoverData.turnoverPreviousYear.value = businessData.value.turnoverData.turnoverPreviousYear.value.toLocaleString(i18n.locale.value)
        }
        if (businessData.value.turnoverData.turnoverPreviousYear.currency !== null) {
            formData.value.turnoverData.turnoverPreviousYear.currency = businessData.value.turnoverData.turnoverPreviousYear.currency
        }
    }
}

if (businessData.value.employeesData !== null) {
    if (businessData.value.employeesData.employeesCurrent !== null) {
        formData.value.employeesData.employeesCurrent = businessData.value.employeesData.employeesCurrent
    }
    if (businessData.value.employeesData.employeesPreviousYear !== null) {
        formData.value.employeesData.employeesPreviousYear = businessData.value.employeesData.employeesPreviousYear
    }
}

if (businessData.value.bankingDetailsData !== null) {
    if (businessData.value.bankingDetailsData.bankingConnections.length) {
        formData.value.bankingDetailsData.bankingConnections = businessData.value.bankingDetailsData.bankingConnections
    } else {
        // always initialize form with at least one bank
        addBank()
    }
} else {
    // always initialize form with at least one bank
    addBank()
}

if (businessData.value.locationsData !== null) {
    if (businessData.value.locationsData.businessLocations.length) {
        formData.value.locationsData.businessLocations = businessData.value.locationsData.businessLocations
        businessData.value.locationsData.businessLocations.forEach((location, index) => {
            const _location = formData.value.locationsData.businessLocations[index]

            let combinedHousenumber = ''
            if (_location.postalAddress.houseNumber === '' || _location.postalAddress.houseNumber === null) combinedHousenumber = ''
            if (_location.postalAddress.houseNumberSuffix === '' || _location.postalAddress.houseNumberSuffix === null) combinedHousenumber = '' + _location.postalAddress.houseNumber
            else combinedHousenumber = `${_location.postalAddress.houseNumber}${_location.postalAddress.houseNumberSuffix}`
            formData.value.locationsData.businessLocations[index].postalAddress.houseNumberPlusSuffix = combinedHousenumber

            if (_location.postalAddress.zipCode == null) {
                _location.postalAddress.zipCode = ''
            }

            if (_location.postalAddress.houseNumberPlusSuffix == 'null') {
                _location.postalAddress.houseNumberPlusSuffix = ''
            }
            // addDynamicManagementValidationConfig(index)
        })
    } else {
        addLocation()
    }
} else {
    // always initialize form with at least one location
    addLocation()
}

const { 
    meta, 
    validate 
} = useForm({ 
    validationSchema: formValidationSchema
})
const isValid = useIsFormValid()

const revalidate = async() => {
    await nextTick()
    const validationResult = await validate()
    console.log('revalidate result', validationResult)
    return validationResult
}

const submitting = ref(false)

const onSubmit = async() => {
    submitting.value = true
    
    const result = await revalidate()

    if (!result.valid) {
        submitting.value = false
        console.log('form is invalid', result.errors)
        useScrollToFormError(result.errors)
    } else {
        let payload: CbraBusinessUpdateData = JSON.parse(JSON.stringify({
            memberUserId: cbraUser.value.memberUserId,
            businessData: {
                businessId: businessData.value.businessId,
                bankingDetailsData: formData.value.bankingDetailsData,
                economicSectorData: formData.value.economicSectorData,
                employeesData: formData.value.employeesData,
                foreignTradeData: formData.value.foreignTradeData,
                identificationData: formData.value.identificationData,
                locationsData: formData.value.locationsData,
                managementData: formData.value.managementData,
                taxData: formData.value.taxData,
                turnoverData: formData.value.turnoverData
            },
            commercialCreditInsurance: formData.value.commercialCreditInsurance,
            contactPerson: formData.value.contactPerson,
            customerBase: formData.value.customerBase,
            remarks: formData.value.remarks
        }))

        payload = parseSpecialFields(payload)
        console.log('payload on submit', payload)

        const _modal = {
            id: 'modal-selfcare-onSaveBusinessData',
            title: t('selfcare.save.success.title'),
            content: `<p>${t('selfcare.save.success.description')}</p>`,
            confirmText: 'Ok',
            icon: 'info',
            dataQa: 'modal-selfcare-onSaveBusinessData'
        }

        try {
            const { status } = await updateCbraBusinessData(payload)
            if (status !== 200) {
                _modal.content = `<p>${t('selfcare.save.error.description')}</p>`
                _modal.icon = 'error'
            }
        } catch (e) {
            _modal.content = `<p>${t('selfcare.save.error.description')}</p>`
            _modal.icon = 'error'
        }

        submitting.value = false

        modal.value = _modal
    }
}

function dynamicPhoneRule(fieldName: string) {
    if (formData.value.identificationData === null) return string().nullish()
    if (formData.value.identificationData.phone === null) return string().nullish()

    switch (fieldName) {
    case 'contactPerson_phone_areaDialingCode':
        if (formData.value.contactPerson.phone.subscriberNumberWithExtension === null || formData.value.contactPerson.phone.subscriberNumberWithExtension === '') {
            if (formData.value.contactPerson.phone.areaDialingCode === '') return string().max(6).nullish()
            return STRING_ONLY_NUMBERS(t).regex(/^0?[1-9]/g, { message: t('error.form.pattern') }).max(6, { message: t('errors.maxLength', { max: 6 }) }).nullish()
        }
        return REQUIRED_STRING_ONLY_NUMBERS(t).regex(/^0?[1-9]/g, { message: t('error.form.pattern') }).max(6, { message: t('errors.maxLength', { max: 6 }) })
    case 'contactPerson_phone_subscriberNumber':
        if (formData.value.contactPerson.phone.areaDialingCode === null || formData.value.contactPerson.phone.areaDialingCode === '') return string().nullish()
        return REQUIRED_STRING_VALIDATION(t).regex(REGEXES.phoneSubscriberNumberWithExtension, { message: t('error.form.pattern') })     
    case 'phone_areaDialingCode':
        if (formData.value.identificationData.phone.subscriberNumberWithExtension === null || formData.value.identificationData.phone.subscriberNumberWithExtension === '') {
            if (formData.value.identificationData.phone.areaDialingCode === '') return string().max(6, { message: t('errors.maxLength', { max: 6 }) }).nullish()
            return STRING_ONLY_NUMBERS(t).regex(/^0?[1-9]/g, { message: t('error.form.pattern') }).max(6, { message: t('errors.maxLength', { max: 6 }) }).nullish()
        }
        return REQUIRED_STRING_ONLY_NUMBERS(t).regex(/^0?[1-9]/g, { message: t('error.form.pattern') } ).max(6, { message: t('errors.maxLength', { max: 6 }) })
    case 'phone_subscriberNumber':
        if (formData.value.identificationData.phone.areaDialingCode === null || formData.value.identificationData.phone.areaDialingCode === '') return string().nullish()
        return REQUIRED_STRING_VALIDATION(t).regex(REGEXES.phoneSubscriberNumberWithExtension, { message: t('error.form.pattern') })
    case 'fax_areaDialingCode':
        if (formData.value.identificationData.fax.subscriberNumberWithExtension === null || formData.value.identificationData.fax.subscriberNumberWithExtension === '') {
            if (formData.value.identificationData.fax.areaDialingCode === '') return string().max(6, { message: t('errors.maxLength', { max: 6 }) }).nullish()
            return STRING_ONLY_NUMBERS(t).regex(/^0?[1-9]/g, { message: t('error.form.pattern') } ).max(6, { message: t('errors.maxLength', { max: 6 }) }).nullish()
        }
        return REQUIRED_STRING_ONLY_NUMBERS(t).regex(/^0?[1-9]/g, { message: t('error.form.pattern') }).max(6, { message: t('errors.maxLength', { max: 6 }) })
    case 'fax_subscriberNumber':
        if (formData.value.identificationData.fax.areaDialingCode === null || formData.value.identificationData.fax.areaDialingCode === '') return string().nullish()
        return REQUIRED_STRING_VALIDATION(t).regex(REGEXES.phoneSubscriberNumberWithExtension, { message: t('error.form.pattern') })
    case 'mobil_areaDialingCode':
        if (formData.value.identificationData.mobile.subscriberNumberWithExtension === null || formData.value.identificationData.mobile.subscriberNumberWithExtension === '') {
            if (formData.value.identificationData.mobile.areaDialingCode === '') return string().max(6, { message: t('errors.maxLength', { max: 6 }) }).nullish()
            return STRING_ONLY_NUMBERS(t).regex(/^0?[1-9]/g, { message: t('error.form.pattern') }).max(6, { message: t('errors.maxLength', { max: 6 }) }).nullish()
        }
        return REQUIRED_STRING_ONLY_NUMBERS(t).regex(/^0?[1-9]/g, { message: t('error.form.pattern') }).max(6, { message: t('errors.maxLength', { max: 6 }) })
    case 'mobil_subscriberNumber':
        if (formData.value.identificationData.mobile.areaDialingCode === null || formData.value.identificationData.mobile.areaDialingCode === '') return string().nullish()
        return REQUIRED_STRING_VALIDATION(t).regex(REGEXES.phoneSubscriberNumberWithExtension, { message: t('error.form.pattern') })
    default:
        return string().nullish()
    }
}

function dynamicPostalAddressRule(fieldName: string) {
    if (formData.value.identificationData === null) return string().nullish()
    if (formData.value.identificationData.postalAddress === null) return string().nullish()
    if (formData.value.identificationData.postalAddress.type === null) return string().nullish()

    switch (fieldName) {
    case 'postalAddress_postOfficeBoxNumber':
        return STRING_ONLY_NUMBERS(t).max(10, { message: t('errors.maxLength', { max: 10 }) }).or(literal('')).nullish()
    case 'postalAddress_street':
        if (formData.value.identificationData.postalAddress.type !== 'DELIVERY_ADDRESS') return string().nullish()
        return REQUIRED_STRING_VALIDATION(t).max(46, { message: t('errors.maxLength', { max: 46 }) })
    case 'postalAddress_houseNumber':
        if (formData.value.identificationData.postalAddress.type !== 'DELIVERY_ADDRESS') return string().nullish()
        return REQUIRED_STRING_VALIDATION(t).max(13, { message: t('errors.maxLength', { max: 13 }) }).regex(REGEXES.houseNumberPlusSuffix, { message: t('error.form.pattern') }).nullish()
    case 'postalAddress_city':
        return REQUIRED_STRING_VALIDATION(t).max(40, { message: t('errors.maxLength', { max: 40 }) })
    case 'postalAddress_zipCode': {
        if (
            formData.value.identificationData.postalAddress.country !== 'DE' && 
            formData.value.identificationData.postalAddress.country !== 'AT' &&
            formData.value.identificationData.postalAddress.country !== 'LU'
        ) {
            return REQUIRED_STRING_ONLY_NUMBERS(t)
        }

        let len = 5
        if (
            formData.value.identificationData.postalAddress.country === 'AT' ||
            formData.value.identificationData.postalAddress.country === 'LU'
        ) {
            len = 4
        }

        return REQUIRED_STRING_ONLY_NUMBERS(t).min(len, {
            message: t('error.form.zipCode')
                .replace('%1$s', t(`enums.countries.${formData.value.identificationData.postalAddress.country}`))
                .replace('%2$s', len.toString())
        }).max(len, {
            message: t('error.form.zipCode')
                .replace('%1$s', t(`enums.countries.${formData.value.identificationData.postalAddress.country}`))
                .replace('%2$s', len.toString())
        })
    }
    default:
        return string().nullish()
    }
}

function dynamicBankingConnectionsRules() {
    const bankingConnectionsSchema: any = {}
    if (!formData.value.bankingDetailsData) return array(string()).nullish()

    formData.value.bankingDetailsData.bankingConnections.forEach((bank, index) => {
        if (bank.bankName !== null && bank.bankName !== '') {
            bankingConnectionsSchema[`bankingConnection${index}_city`] = REQUIRED_STRING_VALIDATION(t)
        }
        if (bank.city !== null && bank.city !== '') {
            bankingConnectionsSchema[`bankingConnection${index}_bankName`] = REQUIRED_STRING_VALIDATION(t)
        }
    })
    
    return object(bankingConnectionsSchema)
}

function dynamicBusinessLocationsRules() {
    const locationsSchema: any = {}
    if (!formData.value.locationsData) return array(string()).nullish()

    formData.value.locationsData.businessLocations.forEach((location, index) => {
        locationsSchema[`location${index}_type`] = generateFieldSchema(`location${index}_type`)
        locationsSchema[`location${index}_postalAddress_street`] = generateFieldSchema(`location${index}_postalAddress_street`)
        locationsSchema[`location${index}_postalAddress_houseNumber`] = generateFieldSchema(`location${index}_postalAddress_houseNumber`)
        locationsSchema[`location${index}_postalAddress_zipCode`] = generateFieldSchema(`location${index}_postalAddress_zipCode`)
        locationsSchema[`location${index}_postalAddress_city`] = generateFieldSchema(`location${index}_postalAddress_city`)
    })

    return object(locationsSchema)
}

function dynamicManagementDataRules() {
    const managersSchema: any = {}
    if (!formData.value.managementData) return array(string()).nullish()

    formData.value.managementData.managers.forEach((manager, index) => {
        managersSchema[`manager${index}_Capacity`] = generateFieldSchema(`manager${index}_Capacity`)
        managersSchema[`manager${index}_Givenname`] = generateFieldSchema(`manager${index}_Givenname`)
        managersSchema[`manager${index}_Surname`] = generateFieldSchema(`manager${index}_Surname`)
        managersSchema[`manager${index}_postalAddress_street`] = generateFieldSchema(`manager${index}_postalAddress_street`)
        managersSchema[`manager${index}_postalAddress_houseNumber`] = generateFieldSchema(`manager${index}_postalAddress_houseNumber`)
        managersSchema[`manager${index}_postalAddress_zipCode`] = generateFieldSchema(`manager${index}_postalAddress_zipCode`)
        managersSchema[`manager${index}_postalAddress_city`] = generateFieldSchema(`manager${index}_postalAddress_city`)
    })

    return object(managersSchema)
}

function dynamicContactPersonRules() {
    if (
        (formData.value.contactPerson.phone.areaDialingCode !== null && formData.value.contactPerson.phone.areaDialingCode !== '') ||
        (formData.value.contactPerson.phone.subscriberNumberWithExtension !== null && formData.value.contactPerson.phone.subscriberNumberWithExtension !== '') ||
        (formData.value.contactPerson.email !== null && formData.value.contactPerson.email !== '') ||
        (formData.value.contactPerson.function !== null && formData.value.contactPerson.function !== '')
    ) {
        return REQUIRED_STRING_VALIDATION(t).min(2, { message: t('errors.minLength', { min: 2 }) })
    } else {
        return string().min(2, { message: t('errors.minLength', { min: 2 }) }).or(literal('')).nullish()
    }
}

function addDynamicManagementValidationConfig(index: number) {
    dynamicValidationConfig.value.push({ fieldName: `manager${index}_Capacity`, nullish: true })
    dynamicValidationConfig.value.push({ fieldName: `manager${index}_Givenname`, nullish: true })
    dynamicValidationConfig.value.push({ fieldName: `manager${index}_Surname`, nullish: true })
    dynamicValidationConfig.value.push({ fieldName: `manager${index}_postalAddress_street` })
    dynamicValidationConfig.value.push({ fieldName: `manager${index}_postalAddress_houseNumber` })
    dynamicValidationConfig.value.push({ fieldName: `manager${index}_postalAddress_zipCode` })
    dynamicValidationConfig.value.push({ fieldName: `manager${index}_postalAddress_city` })
}

function generateFieldSchema(fieldName: string) {
    const validationConfig = dynamicValidationConfig.value.find((config) => config.fieldName === fieldName)
    console.log('validationConfig', validationConfig)
    let schema = string()

    if (fieldName.startsWith('manager') && fieldName.endsWith('_Givenname')) {
        const index = parseInt(fieldName.split('_Givenname')[0].replace('manager', ''))
        if (
            (formData.value.managementData.managers[index].capacity !== null && formData.value.managementData.managers[index].capacity !== '') ||
            (formData.value.managementData.managers[index].surname !== null && formData.value.managementData.managers[index].surname !== '')
        ) {
            return REQUIRED_STRING_VALIDATION(t)
        }
    } else if (fieldName.startsWith('manager') && fieldName.endsWith('_Surname')) {
        const index = parseInt(fieldName.split('_Surname')[0].replace('manager', ''))
        if (
            (formData.value.managementData.managers[index].capacity !== null && formData.value.managementData.managers[index].capacity !== '') ||
            (formData.value.managementData.managers[index].givenName !== null && formData.value.managementData.managers[index].givenName !== '')
        ) {
            return REQUIRED_STRING_VALIDATION(t)
        }
    } else if (fieldName.startsWith('manager') && fieldName.endsWith('_Capacity')) {
        const index = parseInt(fieldName.split('_Capacity')[0].replace('manager', ''))
        if (
            (formData.value.managementData.managers[index].surname !== null && formData.value.managementData.managers[index].surname !== '') ||
            (formData.value.managementData.managers[index].givenName !== null && formData.value.managementData.managers[index].givenName !== '')
        ) {
            return REQUIRED_STRING_VALIDATION(t)
        }
    }

    if (fieldName.endsWith('_type')) {
        let index = parseInt(fieldName.split('_type')[0].replace('location', ''))
        const item = formData.value.locationsData.businessLocations[index]
        if (
            (item.postalAddress.city !== null && item.postalAddress.city !== '') ||
            (item.postalAddress.zipCode !== null && item.postalAddress.zipCode !== '') ||
            (item.postalAddress.street !== null && item.postalAddress.street !== '') ||
            (item.postalAddress.houseNumberPlusSuffix !== null && item.postalAddress.houseNumberPlusSuffix !== '')
        ) {
            return REQUIRED_STRING_VALIDATION(t)
        }
        return schema.nullish()
    }

    if (
        (fieldName.startsWith('manager') || fieldName.startsWith('location')) && 
        fieldName.includes('_postalAddress_')
    ) {
        let type = fieldName.split('_postalAddress_')[1]
        let index = parseInt(fieldName.split('_postalAddress_')[0].replace('manager', '').replace('location', ''))
        const item = fieldName.startsWith('manager')
            ? formData.value.managementData.managers[index] 
            : formData.value.locationsData.businessLocations[index]

        switch (type) {
        case 'street':
            if (
                fieldName.startsWith('location') && 
                (
                    item.locationType !== null ||
                    (
                        (item.postalAddress.zipCode !== null && item.postalAddress.zipCode !== '') ||
                        (item.postalAddress.city !== null && item.postalAddress.city !== '') ||
                        (item.postalAddress.houseNumberPlusSuffix !== null && item.postalAddress.houseNumberPlusSuffix !== '')
                    )
                )
            ) {
                return REQUIRED_STRING_VALIDATION(t).max(46, { message: t('errors.maxLength', { max: 46 }) })
            }
            return string().max(46, { message: t('errors.maxLength', { max: 46 }) }).nullish()
        case 'houseNumber':
            if (
                fieldName.startsWith('location') && 
                (
                    item.locationType !== null ||
                    (
                        (item.postalAddress.zipCode !== null && item.postalAddress.zipCode !== '') ||
                        (item.postalAddress.street !== null && item.postalAddress.street !== '') ||
                        (item.postalAddress.city !== null && item.postalAddress.city !== '')
                    )
                )
            ) {
                return REQUIRED_STRING_VALIDATION(t).max(13, { message: t('errors.maxLength', { max: 13 }) }).regex(REGEXES.houseNumberPlusSuffix, { message: t('error.form.pattern') })
            }
            return string().max(13, { message: t('errors.maxLength', { max: 13 }) }).regex(REGEXES.houseNumberPlusSuffix, { message: t('error.form.pattern') }).or(literal('')).nullish()
        case 'city':
            if (
                (item.postalAddress.zipCode !== null && item.postalAddress.zipCode !== '') ||
                (
                    fieldName.startsWith('location') &&
                    (
                        item.locationType !== null ||
                        (
                            (item.postalAddress.zipCode !== null && item.postalAddress.zipCode !== '') ||
                            (item.postalAddress.street !== null && item.postalAddress.street !== '') ||
                            (item.postalAddress.houseNumberPlusSuffix !== null && item.postalAddress.houseNumberPlusSuffix !== '')
                        )
                    )
                )
            ) {
                return REQUIRED_STRING_VALIDATION(t).max(40, { message: t('errors.maxLength', { max: 40 }) })
            }
            return string().max(40, { message: t('errors.maxLength', { max: 40 }) }).nullish()
        case 'zipCode': {
            let len = 5
            if (
                item.postalAddress.country === 'AT' ||
                item.postalAddress.country === 'LU'
            ) {
                len = 4
            }

            let isRequired = false

            if (
                (fieldName.startsWith('location') && (
                    item.locationType !== null ||
                    (
                        (item.postalAddress.street !== null && item.postalAddress.street !== '') ||
                        (item.postalAddress.city !== null && item.postalAddress.city !== '') ||
                        (item.postalAddress.houseNumberPlusSuffix !== null && item.postalAddress.houseNumberPlusSuffix !== '')
                    )
                )) ||
                (item.postalAddress.street !== null && item.postalAddress.street !== '')
            ) {
                isRequired = true
            }

            if (
                item.postalAddress.country !== 'DE' &&
                item.postalAddress.country !== 'AT' &&
                item.postalAddress.country !== 'LU'
            ) {
                return STRING_ONLY_NUMBERS(t).nullish()
            }

            let schema: any = !isRequired ? STRING_ONLY_NUMBERS(t) : REQUIRED_STRING_ONLY_NUMBERS(t)
            schema = schema.min(len, {
                message: t('error.form.zipCode')
                    .replace('%1$s', t(`enums.countries.${item.postalAddress.country}`))
                    .replace('%2$s', len.toString())
            }).max(len, {
                message: t('error.form.zipCode')
                    .replace('%1$s', t(`enums.countries.${item.postalAddress.country}`))
                    .replace('%2$s', len.toString())
            })
            if (!isRequired) {
                schema = schema.or(literal(''))
            }

            return schema
        }
        default:
            return string().nullish()
        }
    }

    if (validationConfig?.nullish) {
        // nullish option needs to go first, because you can not join
        // string.fn with string.nullish
        return schema.nullish()
    }

    if (validationConfig?.minLength) {
        schema = schema.min(validationConfig.minLength, { message: t('errors.minLength', { min: validationConfig.minLength }) })
    }
    if (validationConfig?.maxLength) {
        schema = schema.max(validationConfig.maxLength, { message: t('errors.maxLength', { max: validationConfig.maxLength }) })
    }

    if (validationConfig?.mandatory) {
        schema = schema.min(1, t('errors.required'))
    }

    console.log('schema', schema)
    return schema
}

function parseSpecialFields(payload: any) {
    const HOUSE_NO_REGEX = /^([1-9][0-9]{0,4})((-| - )[1-9][0-9]{0,4})?( ?[a-zA-Z]{1,8}((-| - )[a-zA-Z]{1,7})?)?/g
    const PHONE_REGEX = /^([0-9]{1,15})(-[0-9]{1,15})?$/g

    const parsePostalAddress = (item: any) => {
        if (item.postalAddress === null) return item
        if (item.postalAddress.street === '') {
            item.postalAddress.street = null
        }
        if (item.postalAddress.city === '') {
            item.postalAddress.street = null
        }
        if (item.postalAddress.zipCode === '') {
            item.postalAddress.zipCode = null
        }
        if (item.postalAddress.postOfficeBoxNumber === '') {
            item.postalAddress.postOfficeBoxNumber = null
        }

        if (item.postalAddress.type === null) {
            item.postalAddress = null
        } else if (item.postalAddress.type === CONSTANTS.POSTAL_ADDRESS_TYPES.POST_OFFICE_BOX) {
            item.postalAddress.street = null
            item.postalAddress.houseNumber = null
            item.postalAddress.houseNumberSuffix = null
        } else if (item.postalAddress.type === CONSTANTS.POSTAL_ADDRESS_TYPES.POSTCODE) {
            item.postalAddress.street = null
            item.postalAddress.houseNumber = null
            item.postalAddress.houseNumberSuffix = null
            item.postalAddress.postOfficeBoxNumber = null
        } else if (item.postalAddress.type === CONSTANTS.POSTAL_ADDRESS_TYPES.DELIVERY_ADDRESS) {
            item.postalAddress.postOfficeBoxNumber = null
        }

        return item
    }
   
    const parseHouseNumber = (item: any) => {
        if (item.postalAddress === null) return item
        if (item.postalAddress.houseNumberPlusSuffix !== null) {
            const houseNumber = item.postalAddress.houseNumberPlusSuffix
            const matches = houseNumber.matchAll(HOUSE_NO_REGEX)
            let number = null
            let suffix = null
            for (const match of matches) {
                number = match[1]
                suffix = match[4]
            }

            item.postalAddress.houseNumber = number
            item.postalAddress.houseNumberSuffix = typeof suffix === 'undefined' ? null : suffix
            delete item.postalAddress.houseNumberPlusSuffix
        }
        return item
    }
    
    const parsePhoneNumber = (item: any, key: string = 'phone') => {
        if (item[key] === null) return item
        if (item[key].subscriberNumberWithExtension !== null) {
            const phoneNumber = item[key].subscriberNumberWithExtension
            const matches = phoneNumber.matchAll(PHONE_REGEX)
            let number = null
            let extension = null
            for (const match of matches) {
                number = match[1]
                extension = match[2]
            }

            if (extension && extension !== null && extension.startsWith('-')) extension = extension.replace('-', '')
            item[key].subscriberNumber = number
            item[key].extensionNumber = typeof extension === 'undefined' ? null : extension
            delete item[key].subscriberNumberWithExtension
        }

        if (item[key].subscriberNumber === null && item[key].extensionNumber === null) {
            item[key] = null
        }
        return item
    }

    const parseBirthday = (item: any) => {
        if (item.calculatedBirthdate === null) return item
        const normalizedString = (new Date(item.calculatedBirthdate)).toLocaleDateString('en')
        const parts = normalizedString.split('/')
        const birthdayString = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`
        const timezone = item.birthday !== null && item.birthday.timezone !== null ? item.birthday.timezone : 0
        const birthday = {
            stringRepresentation: birthdayString,
            timezone: timezone,
            precision: 'DAY',
            day: parseInt(birthdayString.split('-')[2]),
            month: parseInt(birthdayString.split('-')[1]),
            year: parseInt(birthdayString.split('-')[0])
        }
        item.birthday = birthday
        delete item.calculatedBirthdate
        return item
    }

    const parseCurrency = (value: string) => {
        if (value === null || value === '') return null
        const decimalSeparator = 1.1.toLocaleString(i18n.locale.value).substring(1, 2)
        console.log('value in currency formatter', value)
        if (value.slice(-3, -2) === decimalSeparator) {
            const decimalValue = value.slice(-2)
            const integerValue = value.slice(0, -3).replace(/[^0-9]/g, '')
            value = `${integerValue}.${decimalValue}`
        } else {
            value = value.replace(/[^0-9]/g, '')
        }
        return parseFloat(value)
    }

    const parseEmpty = (item: any, keys: Array<string>) => {
        for (const key of keys) {
            if (item[key] === '') item[key] = null
        }
        return item
    }

    if (
        payload.businessData.identificationData !== null &&
        payload.businessData.identificationData.postalAddress !== null
    ) {
        payload.businessData.identificationData = parsePostalAddress(payload.businessData.identificationData)
        payload.businessData.identificationData = parseHouseNumber(payload.businessData.identificationData)
    }

    if (
        payload.businessData.identificationData !== null &&
        payload.businessData.identificationData.phone !== null
    ) {
        payload.businessData.identificationData = parsePhoneNumber(payload.businessData.identificationData)
    }

    if (
        payload.businessData.identificationData !== null &&
        payload.businessData.identificationData.fax !== null
    ) {
        payload.businessData.identificationData = parsePhoneNumber(payload.businessData.identificationData, 'fax')
    }

    if (
        payload.businessData.identificationData !== null &&
        payload.businessData.identificationData.mobile !== null
    ) {
        payload.businessData.identificationData = parsePhoneNumber(payload.businessData.identificationData, 'mobile')
    }

    if (
        payload.businessData.turnoverData !== null &&
        payload.businessData.turnoverData.turnoverExpected !== null && 
        payload.businessData.turnoverData.turnoverExpected.value !== ''
    ) {
        payload.businessData.turnoverData.turnoverExpected.value = parseCurrency(payload.businessData.turnoverData.turnoverExpected.value)
    }
    if (
        payload.businessData.turnoverData !== null &&
        payload.businessData.turnoverData.turnoverExpected !== null && 
        payload.businessData.turnoverData.turnoverExpected.value !== '' &&
        payload.businessData.turnoverData.turnoverExpected.currency === null
    ) {
        payload.businessData.turnoverData.turnoverExpected.currency = 'EUR'
    }

    if (
        payload.businessData.turnoverData !== null &&
        payload.businessData.turnoverData.turnoverPreviousYear !== null &&
        payload.businessData.turnoverData.turnoverPreviousYear.value !== ''
    ) {
        payload.businessData.turnoverData.turnoverPreviousYear.value = parseCurrency(payload.businessData.turnoverData.turnoverPreviousYear.value)
    }
    if (
        payload.businessData.turnoverData !== null &&
        payload.businessData.turnoverData.turnoverPreviousYear !== null &&
        payload.businessData.turnoverData.turnoverPreviousYear.value !== '' &&
        payload.businessData.turnoverData.turnoverPreviousYear.currency === null
    ) {
        payload.businessData.turnoverData.turnoverPreviousYear.currency = 'EUR'
    }

    if (
        payload.businessData.managementData !== null &&
        payload.businessData.managementData.managers !== null &&
        payload.businessData.managementData.managers.length
    ) {
        let i = 0
        for (const manager of payload.businessData.managementData.managers) {
            if (manager.givenName === '' && manager.surname === '' && manager.birthday === null && manager.capacity === null) {
                payload.businessData.managementData.managers.splice(i, 1)
            } else {
                payload.businessData.managementData.managers[i] = parseHouseNumber(manager)
                payload.businessData.managementData.managers[i] = parseBirthday(manager)
                payload.businessData.managementData.managers[i] = parsePostalAddress(manager)
                delete payload.businessData.managementData.managers[i].canBeDeleted
            }
            i++
        }
    }

    if (
        payload.businessData.bankingDetailsData !== null &&
        payload.businessData.bankingDetailsData.bankingConnections !== null &&
        payload.businessData.bankingDetailsData.bankingConnections.length
    ) {
        let i = 0
        for (const bank of payload.businessData.bankingDetailsData.bankingConnections) {
            if (bank.city === '' && bank.bankName === '') {
                payload.businessData.bankingDetailsData.bankingConnections.splice(i, 1)
            } else {
                delete payload.businessData.bankingDetailsData.bankingConnections[i].canBeDeleted
            }
            i++
        }
    }

    if (
        payload.businessData.locationsData !== null &&
        payload.businessData.locationsData.businessLocations !== null &&
        payload.businessData.locationsData.businessLocations.length
    ) {
        let i = 0
        for (const location of payload.businessData.locationsData.businessLocations) {
            if (location.locationType === null) {
                payload.businessData.locationsData.businessLocations.splice(i, 1)
            } else {
                payload.businessData.locationsData.businessLocations[i] = parsePostalAddress(location)
                payload.businessData.locationsData.businessLocations[i] = parseHouseNumber(location)
                delete payload.businessData.locationsData.businessLocations[i].canBeDeleted
            }
            i++
        }
    }

    if (payload.contactPerson.givenName !== '' || payload.contactPerson.surname !== '') {
        payload.contactPerson.name = `${payload.contactPerson.givenName} ${payload.contactPerson.surname}`
    }
    delete payload.contactPerson.givenName
    delete payload.contactPerson.surname

    if (payload.contactPerson.phone.subscriberNumberWithExtension !== '') {
        payload.contactPerson = parsePhoneNumber(payload.contactPerson)
    } else {
        payload.contactPerson.phone = null
    }

    payload.contactPerson = parseEmpty(payload.contactPerson, [
        'name'
    ])

    console.log('Businessdata update payload', payload)
    return payload
}

function onModalConfirm() {
    modal.value = undefined
    scrollTo('.scs-mc')
}

onMounted(() => {
    if (!isNotAllowedToSave.value) {
        validate()
    }
})

watch(
    () => formData,
    () => { revalidate() },
    { deep: true }
)
</script>

<style lang="less" scoped>
.delete-bank,
.delete-location,
.delete-manager {
    text-align: right;
    cursor: pointer;
}
</style>
