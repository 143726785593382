<template>
    <div class="box-shadow-xy box-spacing bg-white mb">
        <strong
            class="h4 m0"
            data-qa="legitimateInterestExplanationHeadlineMirror"
        >
            {{ headline }}
        </strong>
        <span
            class="legitimateInterest-container--short"
            data-qa="legitimateInterestExplanationNoteTextShortMirror"
        >
      
            {{ text }}&nbsp; 
        </span>
        <a
            v-if="expandable"
            href="#"
            class="legitimateInterest-container--button"
            data-qa="legitimateInterestExplanationToggleButton"
            @click.prevent="toggleExpanded"
        >
            {{ buttonText }}
        </a>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const DEFAULT_TRANSLATION_PREFIX = 'report.detail.legitimateInterest.'

const props = defineProps({
    translationPrefix: {
        type: String,
        required: false,
        default: undefined
    },
    legitimateInterest: {
        type: String,
        required: true
    },
    expandable: {
        type: Boolean,
        required: false,
        default: true
    }
})

const { t } = useI18n()

const translationPrefix = props.translationPrefix ? props.translationPrefix : DEFAULT_TRANSLATION_PREFIX

const expanded = ref(props.expandable ? false : true)

const buttonText = computed(() => {
    return expanded.value ? t(translationPrefix + 'legal_note.buttonTextLess') : t(translationPrefix + 'legal_note.buttonTextMore')
})

const text = computed(() => {
    return expanded.value ? t(translationPrefix + 'legal_note_full.' + props.legitimateInterest) : t(translationPrefix + 'legal_note.' + props.legitimateInterest)
})

const headline = computed(() => {
    return t(translationPrefix + props.legitimateInterest)
})

function toggleExpanded() {
    expanded.value = !expanded.value
}

</script>

