<script setup lang="ts">
import { ref } from 'vue'

import Breadcrumb from '@/components/Breadcrumb.vue'
import Hero from '@/components/Hero.vue'
import Contact from '@/components/Teaser/Contact.vue'

import heroImage from '@/assets/img/about-creditreform/about-creditreform-crefo-header-desktop.png'
import heroImageMobile from '@/assets/img/about-creditreform/about-creditreform-crefo-header-mobile.png'

const id = 'portal.mc.about-creditreform'

let isMobile = ref(window.innerWidth < 768)

window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 768
})
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>{{ $t('menu.servicemenu.about.title') }}</li>
        </Breadcrumb>

        <Hero
            :image="heroImage"
            :mobile-image="heroImageMobile"
        />

        <div
            id="c471"
            class=" clearfix grid-box grid-box--stageLeft box"
            data-qa="grid-box-471"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div
                            id="c395"
                            class="ce-box box"
                            data-qa="ce-395"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('AboutCreditreform.whoWeAre.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <i18n-t
                                        keypath="AboutCreditreform.whoWeAre.text1"
                                        tag="p"
                                    >
                                        <b>{{ $t('AboutCreditreform.whoWeAre.bold1') }}</b>
                                    </i18n-t>
                                    <i18n-t
                                        keypath="AboutCreditreform.whoWeAre.text2"
                                        tag="p"
                                    >
                                        <b>{{ $t('AboutCreditreform.whoWeAre.bold2') }}</b>
                                    </i18n-t>
                                    <i18n-t
                                        keypath="AboutCreditreform.whoWeAre.text3"
                                        tag="p"
                                    >
                                        <b>{{ $t('AboutCreditreform.whoWeAre.bold3') }}</b>
                                    </i18n-t>
                                    <i18n-t
                                        keypath="AboutCreditreform.whoWeAre.text4"
                                        tag="p"
                                    >
                                        <b>{{ $t('AboutCreditreform.whoWeAre.bold4') }}</b>
                                    </i18n-t>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="c6894"
            class=" clearfix grid-box grid-box--2cols box box--pearl"
            data-qa="grid-box-6894"
        >
            <div class="container container--crefo">
                <div class="row white-boxes-row">
                    <div class="col-12 col-lg-6 white-box-col">
                        <div
                            id="c6896"
                            class="ce-box box box--white white-box"
                            data-qa="ce-6896"
                        >
                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                <div class="ce-textpic__bodytext text-only">
                                    <i18n-t
                                        keypath="AboutCreditreform.whyCreditreform.text1"
                                        tag="p"
                                    >
                                        <router-link
                                            to="/portal/mc/ueber-creditreform/10-gruende-fuer-creditreform"
                                            class="internal-link"
                                        >
                                            {{ $t('AboutCreditreform.whyCreditreform.link1') }}
                                        </router-link>
                                    </i18n-t>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 white-box-col">
                        <div
                            id="c6898"
                            class="ce-box  box box--white white-box"
                            data-qa="ce-6898"
                        >
                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                <div class="ce-textpic__bodytext text-only">
                                    <i18n-t
                                        keypath="AboutCreditreform.whyCreditreform.text2"
                                        tag="p"
                                    >
                                        <router-link
                                            to="/portal/mc/ueber-creditreform/meine-creditreform"
                                            class="internal-link"
                                        >
                                            {{ $t('AboutCreditreform.whyCreditreform.link2') }}
                                        </router-link>
                                        <i>
                                            {{ $t('AboutCreditreform.whyCreditreform.italic') }}
                                        </i>
                                    </i18n-t>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="c6888"
            class=" clearfix grid-box grid-box--stageLeft box"
            data-qa="grid-box-6888"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12">
                        <div
                            id="c6890"
                            class="ce-box box"
                            data-qa="ce-6890"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('AboutCreditreform.international.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <i18n-t
                                        keypath="AboutCreditreform.international.text1"
                                        tag="p"
                                    >
                                        <b>
                                            {{ $t('AboutCreditreform.international.bold1') }}
                                        </b>
                                    </i18n-t>
                                    <i18n-t
                                        keypath="AboutCreditreform.international.text2"
                                        tag="p"
                                    >
                                        <b>
                                            {{ $t('AboutCreditreform.international.bold2') }}
                                        </b>
                                    </i18n-t>
                                    <ul class="list-unordered">
                                        <i18n-t
                                            keypath="AboutCreditreform.international.li1"
                                            tag="li"
                                        >
                                            <b>
                                                <a
                                                    href="/bonitaetsauskunft/bonitaet-einer-firma-pruefen/"
                                                    title="Opens internal link in current window"
                                                    class="internal-link"
                                                >
                                                    {{ $t('AboutCreditreform.international.li1-link') }}
                                                </a>
                                            </b>
                                        </i18n-t>
                                        <i18n-t
                                            keypath="AboutCreditreform.international.li2"
                                            tag="li"
                                        >
                                            <b>
                                                <router-link
                                                    to="/portal/mc/bonitaetsauskunft/watchlist-unternehmen"
                                                    class="internal-link"
                                                >
                                                    {{ $t('AboutCreditreform.international.li2-link') }}
                                                </router-link>
                                            </b>
                                        </i18n-t>
                                        <i18n-t
                                            keypath="AboutCreditreform.international.li3"
                                            tag="li"
                                        >
                                            <b>
                                                <a
                                                    href="/inkasso/inkassoauftrag-per-upload/"
                                                    title="Opens internal link in current window"
                                                    class="internal-link"
                                                >
                                                    {{ $t('AboutCreditreform.international.li3-link') }}
                                                </a>
                                            </b>
                                        </i18n-t>
                                    </ul>
                                    <p>{{ $t('AboutCreditreform.international.text3') }}</p>
                                    <i18n-t
                                        keypath="AboutCreditreform.international.text4"
                                        tag="p"
                                    >
                                        <b>
                                            {{ $t('AboutCreditreform.international.bold4') }}
                                        </b>
                                    </i18n-t>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="$i18n.locale !== 'de-AT'"
            id="c6922"
            class=" clearfix grid-box grid-box--stageLeft box box--pearl"
            data-qa="grid-box-6922"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c6924"
                            class="ce-box   box"
                            data-qa="ce-6924"
                        >
                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                <div class="ce-header">
                                    <h3>{{ $t('AboutCreditreform.strongGroup.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('AboutCreditreform.strongGroup.text') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="$i18n.locale !== 'de-AT'"
            id="c6918"
            class=" clearfix grid-box grid-box--3cols box box--pearl"
            data-qa="grid-box-6918"
        >
            <div class="container container--crefo">
                <div class="row white-boxes-row">
                    <div class="col-12 col-lg-4 white-box-col">
                        <div
                            id="c6920"
                            class="ce-box box box--white white-box"
                            data-qa="ce-6920"
                        >
                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                <div class="ce-textpic__bodytext">
                                    <div>
                                        <p>
                                            <b>{{ $t('AboutCreditreform.services.service1.title') }}</b>
                                        </p>
                                        <p>
                                            {{ $t('AboutCreditreform.services.service1.text') }}
                                        </p>
                                    </div>
                                    <p class="white-box-link">
                                        <router-link
                                            to="/portal/mc/ueber-creditreform/marketing-adressen"
                                        >
                                            {{ $t('AboutCreditreform.services.service1.link') }}
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 white-box-col">
                        <div
                            id="c6926"
                            class="ce-box box box--white white-box"
                            data-qa="ce-6926"
                        >
                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                <div class="ce-textpic__bodytext">
                                    <div>
                                        <p><b>{{ $t('AboutCreditreform.services.service2.title') }}</b></p>
                                        <p>
                                            {{ $t('AboutCreditreform.services.service2.text') }}
                                        </p>
                                    </div>
                                    <p class="white-box-link">
                                        <a
                                            href="https://consumer.boniversum.com/sso"
                                            title="Opens external link in new window"
                                            target="_blank"
                                            rel="noopener"
                                            class="external-link-new-window"
                                        >
                                            {{ $t('AboutCreditreform.services.service2.link') }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 white-box-col">
                        <div
                            id="c6928"
                            class="ce-box box box--white white-box"
                            data-qa="ce-6928"
                        >
                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                <div class="ce-textpic__bodytext">
                                    <div>
                                        <p><b>{{ $t('AboutCreditreform.services.service3.title') }}</b></p>
                                        <p>{{ $t('AboutCreditreform.services.service3.text') }}</p>
                                    </div>
                                    <p class="white-box-link">
                                        <a
                                            href="https://www.crefo-factoring.de/"
                                            title="Opens external link in new window"
                                            target="_blank"
                                            rel="noopener"
                                            class="external-link-new-window"
                                        >
                                            {{ $t('AboutCreditreform.services.service3.link') }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="$i18n.locale !== 'de-AT'"
            id="c6504"
            class=" clearfix grid-box grid-box--stageLeft box"
            data-qa="grid-box-6504"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12">
                        <div
                            id="c6508"
                            class="ce-box box"
                            data-qa="ce-6508"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('AboutCreditreform.partnerOffers.title') }}</h3>
                                </div>

                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/about-creditreform/about-creditreform-atradius-logo.png"
                                                    width="1280"
                                                    height="512"
                                                    alt=""
                                                    class="ce-textpic__img"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p><b>{{ $t('AboutCreditreform.partnerOffers.atradius.title') }}</b></p>
                                    <p>{{ $t('AboutCreditreform.partnerOffers.atradius.text') }}</p>
                                    <p>
                                        <a
                                            href="https://www.creditreform.de/crefoeva"
                                            title="Opens internal link in current window"
                                            target="_blank"
                                            rel="noopener"
                                            class="external-link-new-window"
                                        >
                                            {{ $t('AboutCreditreform.partnerOffers.atradius.link') }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            id="c6510"
                            class="ce-box box"
                            data-qa="ce-6510"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext ce-textpic--nowrap">
                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/about-creditreform/about-creditreform-rv-logo.png"
                                                    width="827"
                                                    height="296"
                                                    alt=""
                                                    class="ce-textpic__img"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p><b>{{ $t('AboutCreditreform.partnerOffers.ruv.title') }}</b> </p>
                                    <p>{{ $t('AboutCreditreform.partnerOffers.ruv.text') }}</p>
                                    <p>
                                        <a
                                            href="https://www.creditreform.de/loesungen/inkasso-aussenstaende/kreditversicherung-wkv"
                                            title="Opens internal link in current window"
                                            target="_blank"
                                            rel="noopener"
                                            class="external-link-new-window"
                                        >
                                            {{ $t('AboutCreditreform.partnerOffers.ruv.link') }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="c1431"
            class=" clearfix grid-box grid-box--3cols box box--pearl"
            data-qa="grid-box-1431"
        >
            <div class="container container--crefo">
                <div class="row white-boxes-row">
                    <div class="col-12 col-lg-4 white-box-col">
                        <div
                            id="c1432"
                            class="ce-box box box--white white-box"
                            data-qa="ce-1432"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('AboutCreditreform.infoBoxes.box1.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <ul class="list-unordered">
                                        <li>{{ $t('AboutCreditreform.infoBoxes.box1.li1') }}</li>
                                        <li>{{ $t('AboutCreditreform.infoBoxes.box1.li2') }}</li>
                                        <li>{{ $t('AboutCreditreform.infoBoxes.box1.li3') }}</li>
                                        <li v-if="$i18n.locale === 'en-US'">
                                            {{ $t('AboutCreditreform.infoBoxes.box1.li4') }}
                                        </li>
                                        <li v-if="$i18n.locale === 'en-US'">
                                            {{ $t('AboutCreditreform.infoBoxes.box1.li5') }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 white-box-col">
                        <div
                            id="c1434"
                            class="ce-box box box--white white-box"
                            data-qa="ce-1434"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('AboutCreditreform.infoBoxes.box2.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <p>{{ $t('AboutCreditreform.infoBoxes.box2.text') }}</p>
                                    <p
                                        v-if="$i18n.locale === 'en-US'"
                                        class="white-box-link"
                                    >
                                        <router-link
                                            to="/portal/mc/ueber-creditreform/unsere-mitgliedschaften"
                                            class="internal-link"
                                        >
                                            {{ $t('AboutCreditreform.infoBoxes.box2.link') }}
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 white-box-col">
                        <div
                            id="c1433"
                            class="ce-box box box--white white-box"
                            data-qa="ce-1433"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-header">
                                    <h3>{{ $t('AboutCreditreform.infoBoxes.box3.title') }}</h3>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <i18n-t
                                        v-if="$i18n.locale !== 'en-US'"
                                        keypath="AboutCreditreform.infoBoxes.box3.text"
                                        tag="p"
                                    >
                                        <router-link
                                            to="/portal/mc/ueber-creditreform/unsere-mitgliedschaften"
                                            class="internal-link"
                                        >
                                            {{ $t('AboutCreditreform.infoBoxes.box3.link') }}
                                        </router-link>
                                    </i18n-t>
                                    <div v-else>
                                        <i18n-t
                                            keypath="AboutCreditreform.infoBoxes.box3.text1"
                                            tag="p"
                                        >
                                            <em class="blue-italic-text">
                                                {{ $t('AboutCreditreform.infoBoxes.box3.link1-1') }}
                                            </em>
                                            <em class="blue-italic-text">
                                                {{ $t('AboutCreditreform.infoBoxes.box3.link1-2') }}
                                            </em>
                                        </i18n-t>
                                        <i18n-t
                                            keypath="AboutCreditreform.infoBoxes.box3.text2"
                                            tag="p"
                                        >
                                            <router-link
                                                to="/portal/mc/ueber-creditreform/unsere-mitgliedschaften"
                                                class="internal-link"
                                            >
                                                {{ $t('AboutCreditreform.infoBoxes.box3.link2') }}
                                            </router-link>
                                        </i18n-t>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <a id="c5938" />

        <Contact />
    </div>
</template>

<style scoped lang="less">
.grid-box {
    margin: 3rem 0 2rem;
}

.box {
    padding: 1.5rem;
    position: relative;
}

.ce-box {
    .ce-textpic {
        display: block;
        overflow: hidden;
        .ce-header {
            margin-bottom: 2rem;
        }
    }
}

.ce-textpic--right {
    &.ce-textpic--intext {
        .ce-textpic__gallery {
            float: right;
            max-width: 40%;
            @media (max-width: 1199px) {
                float: none;
                max-width: 100%;
            }
            .ce-textpic__media {
                max-width: 100%;
                float: right;
                padding-left: 20px;
                padding-top: 20px;
                @media (max-width: 1199px) {
                    padding: 0 0 2rem;
                    float: none;
                }
                .ce-textpic__img {
                    max-width: 100%;
                    width: 827px;
                    height: auto;
                    vertical-align: middle;
                    border: 0;
                }
            }
        }
    }
}

.box--pearl {
    background-color: #edece5;
}

.box--white {
    background-color: #fff;
}

.white-boxes-row {
    .white-box-col {
        .white-box {
            height: 100%;
            .ce-textpic {
                height: inherit;
                .ce-textpic__bodytext {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: inherit;
                    white-space: pre-wrap;
                    &.text-only {
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                    }
                    .white-box-link {
                        @media (max-width: 1199px) {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }
}

.container--crefo {
    padding-left: 15px;
    @media (min-width: 1290px) {
        padding-left: 15px;
    }
    @media (min-width: 768px) {
        padding-left: 75px;
    }
}

.blue-italic-text {
    color: #61ace3;
}
</style>