<template>
    <div 
        v-show="transactionsStore.deleteAreaIsActive"
        class="row"
    >
        <div class="col-12">
            <div class="filter_box bg-white">
                <p>
                    <a
                        role="button"
                        href="#"
                        class="showmore-link"
                        :data-qa="`activities-deleting-headline-toggle-data-link`"
                        @click.prevent.stop="toggleMassDelete"
                    >
                        <span>
                            <font-awesome-icon :icon="`fa-light fa-circle-${!deleteOptionsAreShown ? 'plus' : 'minus'}`" />
                            {{ $t('transaction.list.deleting.show.link') }}
                        </span>
                    </a>
                </p>
                <div v-if="deleteOptionsAreShown">
                    <div class="row">
                        <div class="col-12">
                            <p class="mb-small">
                                {{ $t('transaction.list.deleting.text') }}
                            </p>
                        </div>
                        <div class="col-6">
                            <Checkbox
                                id="selectAll"
                                v-model="selectAll"
                                name="select-all"
                                :class="{ 'all-checked': transactionsStore.hasAllChecked }"
                                :label="$t('transaction.list.deleting.selectall.checkbox.text')"
                                data-qa="activities-deleting-toggle"
                                @change="toggleSelectAll"
                            />
                        </div>
                        <div class="col-6 align-text-right">
                            <button 
                                class="btn btn-default btn-small btn-min" 
                                :disabled="disabled"
                                :data-qa="`transactions-deleting-all-selected${disabled ? '-disabled' : ''}`"
                                @click="deleteSelectedTransactions"
                            >
                                {{ $t('transaction.list.deleting.btn.submit') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal
        v-if="deleteModal"
        :id="deleteModal.id"
        :title="deleteModal.title"
        :confirm-text="deleteModal.confirmText"
        :cancel-text="deleteModal.cancelText"
        :icon="deleteModal.icon"
        :data-qa="deleteModal.dataQa"
        @on-confirm="onDeleteModalConfirm"
        @on-close="deleteModal = undefined"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="deleteModal.content" />
    </Modal> 
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import Checkbox from '@/components/Input/Checkbox.vue'
import Modal from '@/components/Modal.vue'

import { useTransactionsStore } from '@/stores/transactions'

import type { ModalProps } from '@/types/components/modal'
import { isReportTransactionErasable } from '@/helper/services/transaction'
import { storeToRefs } from 'pinia'

const transactionsStore = useTransactionsStore()
const selectAll = ref(false)
const deleteModal = ref<ModalProps>()
const { t } = useI18n()

const { deleteOptionsAreShown } = storeToRefs(transactionsStore)

const disabled = computed(() => {
    return !transactionsStore.deleteButtonActive
})

function toggleMassDelete() {
    transactionsStore.deleteOptionsAreShown = !deleteOptionsAreShown.value
}

function toggleSelectAll() {
    transactionsStore.selectAll = selectAll.value
}

const onDeleteModalConfirm = () => { 
    deleteModal.value = undefined
    const idsToDelete = transactionsStore.transactions
        .filter((transaction) => transaction.checkForDeleting && isReportTransactionErasable(transaction))
        .map((transaction) => transaction.crefoTransactionId.toString())
    if (!idsToDelete.length) return
    transactionsStore.deleteTransactions(idsToDelete)
}

function deleteSelectedTransactions() {
    deleteModal.value = {
        id: 'transaction-deleting-modal',
        title: t('transaction.list.deleting.modal.title'),
        content: t('transaction.list.deleting.modal.content'),
        icon: 'warning',
        confirmText: t('transaction.list.deleting.modal.confirm'),
        cancelText: t('transaction.list.deleting.modal.cancel'),
        dataQa: 'transaction-deleting-modal'
    }
}

watch(() => transactionsStore.hasAllChecked, (value) => {
    if (value !== selectAll.value) {
        selectAll.value = value
    }
})
</script>

<style scoped lang="less">
.filter_box {
    margin: 10px 0 0;
    padding: 15px;

    p {
        font-size: 15px;
    }

    #selectAll {
        float: left;
    }

    .align-text-right {
        text-align: right;
    }
}
</style>