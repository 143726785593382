<script setup lang="ts">
import { ref, computed, PropType } from 'vue'

let isMobile = ref(window.innerWidth < 768)
window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 768
})

const props = defineProps({
    tableHeaders: {
        type: Array as PropType<string[]>,
        required: true
    },
    tableRows: {
        type: Array as PropType<string[][]>,
        required: true
    },
    tableForm: {
        type: String as PropType<'default' | 'vertical'>,
        default: 'default'
    },
    stripes: {
        type: String as PropType<'none' | 'horizontal' | 'vertical'>,
        default: 'none'
    },
    highlightedRows: {
        type: Array as PropType<number[]>,
        default: () => []
    },
    highlightedColumns: {
        type: Array as PropType<number[]>,
        default: () => []
    },
    caption: {
        type: String,
        default: ''
    }
})

const verticalRows = computed(() => {
    const vertRows = []
    for (let i = 0; i < props.tableHeaders.length; i++) {
        const row = []
        for (const tableRow of props.tableRows) {
            row.push(tableRow[i])
        }
        vertRows.push(row)
    }
    return vertRows
})

// onMounted(() => {
//     highlightCells()
// })

// function highlightCells() {
//     for (const cell of tableCells) {
//         const cellIndex = cell.cellIndex
//         const rowIndex = cell.parentElement.rowIndex
//         if (props.highlightedRows.includes(rowIndex) || props.highlightedColumns.includes(cellIndex)) {
//             cell.classList.add('highlighted')
//         }
//     }
// }
</script>

<template>
    <div
        v-if="props.tableForm === 'default'"
        class="table-responsive"
    >
        <table
            class="table"
            :class="{
                'horizontal-stripes': props.stripes === 'horizontal',
                'vertical-stripes': props.stripes === 'vertical',
                'table-highlighted': props.highlightedRows.length > 0 || props.highlightedColumns.length > 0
            }"
        >
            <caption>{{ props.caption }}</caption>
            <thead>
                <tr>
                    <th
                        v-for="(tableHeader, index) in props.tableHeaders"
                        :key="index"
                    >
                        {{ tableHeader }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(tableRow, rowIndex) in props.tableRows"
                    :key="rowIndex"
                >
                    <td
                        v-for="(tableData, dataIndex) in tableRow"
                        :key="dataIndex"
                    >
                        {{ tableData }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div
        v-if="props.tableForm === 'vertical'"
        class="table-responsive"
    >
        <table
            class="table table-vertical"
            :class="{
                'horizontal-stripes': props.stripes === 'horizontal',
                'vertical-stripes': props.stripes === 'vertical',
                'table-highlighted': props.highlightedRows.length > 0 || props.highlightedColumns.length > 0
            }"
        >
            <caption>{{ props.caption }}</caption>
            <tr
                v-for="(tableHeader, headerIndex) in props.tableHeaders"
                :key="headerIndex"
            >
                <th>{{ tableHeader }}</th>
                <td
                    v-for="(rowData, rowDataIndex) in verticalRows[headerIndex]"
                    :key="rowDataIndex"
                >
                    {{ rowData }}
                </td>
            </tr>
        </table>
    </div>
</template>

<style scoped lang="less">
.px2rem(@px: 15) {
    //@result: unit((@px / @font-size-base), rem);
    @result: unit((@px / 1), px);
}

@box-shadow-default: 0 0 14px rgba(0, 0, 0, 0.15);
@normal-spacing: .px2rem(15)[@result];
@white:                         rgba(255, 255, 255, 1); // FFFFFF
@clr-brand-darkest-blue:        rgba(0, 72, 132, 1);    // 004884
@clr-brand-grey:                rgba(181, 181, 181, 1); // B5B5B5
@clr-brand-light-grey:          rgba(237, 236, 229, 1); // EDECE5
@clr-brand-blue:                rgba(0, 158, 226, 1);   // 009EE2
@des-mobile:                    ~"only screen and (max-width: 767px)";

.table-responsive {
    overflow-x: auto;
}

table.table {
    width: 100%;
    border: none;
    box-shadow: @box-shadow-default;
    margin-bottom: @normal-spacing;

    td, th {
        border: none;
        height: .px2rem(60)[@result];
        line-height: .px2rem(26)[@result];
        padding: .px2rem(15)[@result];
    }

    td {
        font-size: .px2rem(15)[@result];
    }
    th {
        color: var(--color-c-s3);
        background-color: @white;
        border-bottom: solid 2px var(--color-c-grey);
        font-size: .px2rem(16)[@result];
    }

    &.table-small {
        td, th {
            height: .px2rem(40)[@result];
            line-height: .px2rem(22)[@result];
            padding: .px2rem(10)[@result];
        }

        td {
            font-size: .px2rem(14)[@result];
        }
        th {
            font-size: .px2rem(15)[@result];
        }
    }

    /*tr > td:not(:last-child) {
        border-right: 1px solid @clr-brand-grey;
    }*/

    tr > td:not(:last-child) {
        border-right: 0px !important;
    }

    &.horizontal-stripes {
        tr:nth-child(even) {
            background-color: @white;
        }
        tr:nth-child(odd) {
            background-color: @clr-brand-light-grey;
        }
    }

    &.vertical-stripes {
        th:nth-child(even), td:nth-child(even) {
            background-color: @white;
        }
        th:nth-child(odd), td:nth-child(odd) {
            background-color: @clr-brand-light-grey;
        }
    }

    &.is-mobile.vertical-stripes {
        &:nth-child(odd) {
            th, td {
                background-color: @clr-brand-light-grey;
            }
        }
        &:nth-child(even) {
            th, td {
                background-color: @white;
            }
        }
    }

    tr {
        background-color: @white;
    }

    tr:not(:last-child) {
        border-bottom: solid 1px var(--color-c-t-grey);
    }

    &.table-vertical {
        tr > th {
            border-right: solid 2px var(--color-c-grey);
        }

        &.horizontal-stripes {
            tr:nth-child(odd), th:nth-child(odd) {
                background-color: @clr-brand-light-grey;
            }
            tr:nth-child(even), th:nth-child(even) {
                background-color: @white;
            }
        }

        tr {
            background-color: @white;
        }

        th {
            border-bottom: 0px;
        }
    }

    &.table-plain {
        tr {
            background-color: @white !important;
        }

        tr > th:not(:last-child) {
            border-right: 1px solid @clr-brand-grey;
        }

        tr:not(:last-child) {
            border-bottom: 1px solid @clr-brand-grey;
        }

        &.table-vertical {
            tr {
                border-bottom: 0;
            }

            tr:not(:last-child) > td {
                border-bottom: 1px solid @clr-brand-grey;
            }
        }
    }

    &.table-highlighted {
        /*&:not(.table-vertical) {
            tr {
                background-color: @white !important;

                &:not(:last-child) > td {
                    border-bottom: 1px solid @clr-brand-grey;
                }
            }

            th, td {
                border-right: 0 !important;
            }

            td:nth-child(even) {
                background-color: @white;
            }
            td:nth-child(odd) {
                background-color: @clr-brand-light-grey;
            }
        }*/

        th.highlighted, td.highlighted {
            color: @white;
            background-color: @clr-brand-blue !important;
            font-weight: bold;
        }
    }

    &.table-full-width {
        width: 100%;
    }

    &.table-no-borders {
        tr, th, td {
            border: 0 !important;
        }
    }

    &.table-no-column-borders {
        tr, th, td {
            border-right: 0 !important;
        }
    }

    &.table-no-row-borders {
        tr, th, td {
            border-bottom: 0 !important;
        }
    }
}

@media @des-mobile {
    table.table.table-vertical.table-break-on-mobile {
        box-shadow: none;

        tr, td, th {
            display: block !important;
        }

        tr {
            margin-bottom: @normal-spacing;
            box-shadow: @box-shadow-default;

            > td {
                border-right: 0 !important
            }

            > td:not(:last-child) {
                border-bottom: 1px solid @clr-brand-grey;
            }
        }
    }
}
</style>