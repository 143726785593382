<template>
    <NotAuthorized 
        v-if="!isAuthorized"
        data-qa="widget-inkassoRealisation-note-not-authorized"
    />
    <Doughnut
        v-else
        id="inkasso-realisation-chart"
        class="chart"
        :options="chartOptions"
        :data="chartData"
        :plugins="chartPlugins"
        data-qa="widgetCanvas"
    />
</template>

<script setup lang="ts">

import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement , Chart, Plugin, ChartData, ChartOptions , DoughnutController } from 'chart.js'
import { computed, onMounted, ref } from 'vue'
import { getCbraLatestCollectionFileStatistics } from '@/services'
import { useI18n } from 'vue-i18n'
import { ROLES, hasUserRole } from '@/helper/services/user'
import axios from 'axios'
import type { CbraLatestCollectionFileStatisticsResponse } from '@/types/cbra/latestCollectionFileStatistics'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, DoughnutController)

const { t } = useI18n()

let currencyFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code'
})

const chartOptions = ref<ChartOptions<'doughnut'>>({
    responsive: true,
    cutout: 75,
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 10,
                color: '#4a4a4a',
                font: {
                    weight: '600',
                    family: "'Open Sans', sans-serif",
                }
            },
        },
        tooltip: {
            callbacks: {
                title: () => (''),
                label: (tooltipItem) => chartData.value.labels ? chartData.value.labels[tooltipItem.dataIndex] as string : ''
            }
        }
    }
})

const isLoading = ref(true)
const showNoInquiries = ref(false)
const isAuthorized = ref(true)

const fileStats = ref<CbraLatestCollectionFileStatisticsResponse>()

async function handleFetch() {
    if (!hasUserRole(ROLES.INKASSO)) {
        isAuthorized.value = false
        return
    }
    
    try {
        isLoading.value = true
        const { data } =await getCbraLatestCollectionFileStatistics()
        fileStats.value = data
        let hasNoInquiries = (!data || !data.openFilesCount && !data.closedFilesCount)
        showNoInquiries.value = hasNoInquiries
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            isAuthorized.value = false
            return
        } else {
            showNoInquiries.value = true
        }
        console.error(error)
    }
    isLoading.value = false
}

onMounted(async()=> {
    await handleFetch()
})

const chartData = computed(() => {
    let chartData: ChartData<'doughnut'> = {
        labels: [],
        datasets: []
    }
    
    if (!fileStats.value) {
        return chartData
    }

    let totalOutstandingAmount = fileStats.value.totalOutstandingAmount ?fileStats.value.totalOutstandingAmount.value : 0
    let totalRedeemedAmount = fileStats.value.totalRedeemedAmount ? fileStats.value.totalRedeemedAmount.value : 0

    chartData = {
        labels: [
            t('dashboard.widget.monitoring.label.totalOutstandingAmount', { amount: currencyFormatter.format(totalOutstandingAmount) }),
            t('dashboard.widget.monitoring.label.totalRedeemedAmount', { amount: currencyFormatter.format(totalRedeemedAmount) })
        ],
        datasets: [{
            data: [
                totalOutstandingAmount,
                totalRedeemedAmount
            ],
            backgroundColor: [
                'rgba(0, 141, 217, 0.1)',
                'rgba(0, 141, 217, 0.7)'
            ],
            borderWidth: [
                0,
                0
            ]
        }],
    }
    return chartData
})

const chartPlugins = computed(() => {
    const plugins: Plugin<'doughnut'>[] = [ {
        id: 'label-plugin',
        beforeDraw: (chartInstance: Chart) => {
            if (!fileStats.value) return
            const datasetCount = chartInstance.getVisibleDatasetCount()
            const chartMeta = chartInstance.getDatasetMeta(datasetCount-1).data.at(0)
            if (!chartMeta) return
            const { innerRadius } = chartMeta.getProps(['innerRadius'])
            let canvas = chartInstance
            let ctx = canvas.ctx
            let label = currencyFormatter.format(fileStats.value.totalAmount.value)
            let x = (chartInstance.chartArea.right - chartInstance.chartArea.left ) / 2
            let y = (chartInstance.chartArea.bottom - chartInstance.chartArea.top ) / 2
            let fontSize = Math.floor(x /4) 
            const font = 'Open Sans'

            // horizontal padding (left + right) for centered label
            let textBoxPadding = 20

            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = '#4a4a4a'

            do {
                fontSize--
                ctx.font = `${fontSize}px ${font}`
            } while (ctx.measureText(label).width + textBoxPadding > (2 * innerRadius) )

            ctx.fillText(label, x, y)
        },
    },
    ] 
    return plugins
})

</script>

<style>
    .chart {
        margin: 0 auto;
    }
</style>