<template>
    <div>
        <div 
            v-if="_transaction && _transaction.consumerTransaction"
            :id="`transaction-${_transaction.id}`"
            :key="_transaction.id"
            class="results-list--item"
            :class="{ 'inactive': false }"
        >
            <div class="row">
                <div class="col-12">
                    <div class="bg-white">
                        <div class="row">
                            <div class="col-md-5 col-12">
                                <div class="row">
                                    <div class="col-1">
                                        <div 
                                            class="icon-favourite" 
                                            data-qa="myTransactions-iconStar"
                                            @click="_toggleFavourite"
                                        >
                                            <font-awesome-icon
                                                :icon="`fa-light fa-star ${_transaction.favourite ? 'fa-solid' : ''}`"
                                                color="#009ee2"
                                            />
                                        </div>
                                        <div 
                                            v-if="_transaction.reportId !== null && !hideDeleteAction"
                                            class="icon-delete"
                                            data-qa="myTransactions-iconTrash"
                                            :class="{ 'disabled': !erasable }"
                                            @click="deleteTransaction"
                                        >
                                            <template v-if="erasable">
                                                <Tooltip position="bottom">
                                                    <font-awesome-icon
                                                        icon="fa-light fa-trash-can"
                                                        color="#009ee2"
                                                    />
                                                    <template #content>
                                                        <p>{{ $t('transaction.list.reports.delete.tooltop.erasable') }}</p>
                                                    </template>
                                                </Tooltip>
                                            </template>
                                            <template v-else>
                                                <Tooltip position="bottom">
                                                    <font-awesome-icon
                                                        icon="fa-light fa-trash-can"
                                                        color="#009ee2"
                                                    />
                                                    <template #content>
                                                        <p>{{ $t('transaction.list.reports.delete.tooltop.noterasable') }}</p>
                                                    </template>
                                                </Tooltip>
                                            </template>
                                        </div>
                                    </div>
                                    <div 
                                        class="col-11"
                                        @click.prevent="openDetailsPage"
                                    >
                                        <span class="h4 transaction-title">
                                            <span data-qa="myTransactions-searchResultList-name">
                                                {{ _transaction.consumerTransaction.givenName }} {{ _transaction.consumerTransaction.surname }}
                                            </span>
                                            <span 
                                                v-if="_transaction.consumerTransaction.postalAddress && 
                                                    _transaction.consumerTransaction.postalAddress.city"
                                                data-qa="myTransactions-searchResultList-postalAddress-city"
                                            >, {{ _transaction.consumerTransaction.postalAddress.city }}</span>
                                            <span 
                                                v-if="_transaction.consumerTransaction.postalAddress && 
                                                    _transaction.consumerTransaction.postalAddress.country &&
                                                    _transaction.consumerTransaction.postalAddress.country !== 'DE'"
                                                data-qa="myTransactions-searchResultList-postalAddress-country"
                                            >, {{ $t(`enums.countries.${_transaction.consumerTransaction.postalAddress.country}`) }}</span>
                                        </span>
                                        <div 
                                            v-if="_transaction.consumerTransaction?.referenceNumber"
                                            class="text-small"
                                        >
                                            <span 
                                                :class="{
                                                    'text-brand-grey': _transaction.reportId !== null,
                                                    'text-brand-dark-grey': _transaction.reportId === null
                                                }"
                                            >{{ $t('shared.reference') }}:</span>&nbsp;
                                            <span 
                                                class="text-brand-darkest-blue text-bold"
                                                data-qa="myTransactions-searchResultList-referenceNumber"
                                            >{{ _transaction.consumerTransaction.referenceNumber }}</span>
                                        </div>
                                        <div 
                                            v-if="featurePortfolioActive"
                                            class="text-small transaction_query_by"
                                        >
                                            <div class="icon baseline">
                                                <font-awesome-icon
                                                    icon="fa-solid fa-user"
                                                    color="#b5b5b5"
                                                />
                                            </div>
                                            <span 
                                                :class="{
                                                    'text-brand-grey': _transaction.reportId !== null,
                                                    'text-brand-dark-grey': _transaction.reportId === null
                                                }"
                                            >{{ $t('transaction.list.transaction_getby') }}</span>&nbsp;
                                            <span 
                                                class="text-brand-darkest-blue text-bold"
                                                data-qa="transaction_query_by"
                                            >{{ sbb(_transaction.owner) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                class="col-md-4 col-12"
                                @click.prevent="openDetailsPage"
                            >
                                <span 
                                    class="h4 transaction-title"
                                    data-qa="myTransactions-searchResultList-consumerReportTransactionType"
                                >{{ $t(`reportperson.form.product.${_transaction.consumerTransaction.reportProductType}`) }}</span>
                            </div>
                            <div 
                                class="col-md-3 col-12 data p0"
                                @click.prevent="openDetailsPage"
                            >
                                <div class="text-small">
                                    <span class="text-brand-grey">{{ $t('transaction.show.orderNumber') }}</span>&nbsp;
                                    <span 
                                        class="text-brand-darkest-blue text-bold"
                                        data-qa="myTransactions-searchResultList-productOrderId"
                                    >
                                        {{ _transaction.consumerTransaction.productOrderId }}
                                    </span>
                                </div>
                                <div 
                                    v-if="_transaction.consumerTransaction.creationTime"
                                    class="text-small"
                                >
                                    <span class="text-brand-grey">{{ $t('transaction.list.creationTime') }}</span>&nbsp;
                                    <span 
                                        class="text-brand-darkest-blue text-bold"
                                        data-qa="myTransactions-searchResultList-creationTime"
                                    >
                                        {{ formatDate(_transaction.consumerTransaction.creationTime, 'L') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            v-if="deleteModal"
            :id="deleteModal.id"
            :title="deleteModal.title"
            :confirm-text="deleteModal.confirmText"
            :cancel-text="deleteModal.cancelText"
            :icon="deleteModal.icon"
            :data-qa="deleteModal.dataQa"
            @on-confirm="onDeleteModalConfirm"
            @on-close="deleteModal = undefined"
        >
            <p>{{ deleteModal.content }}</p>
        </Modal>
        <Modal
            v-if="notReadyModal"
            :id="notReadyModal.id"
            :title="notReadyModal.title"
            :confirm-text="notReadyModal.confirmText"
            :cancel-text="notReadyModal.cancelText"
            :icon="notReadyModal.icon"
            :data-qa="notReadyModal.dataQa"
            @on-confirm="notReadyModal = undefined"
            @on-close="notReadyModal = undefined"
        >
            <p>{{ notReadyModal.content }}</p>
        </Modal>

        <Modal
            v-if="modal"
            :id="modal.id"
            :title="modal.title"
            :confirm-text="modal.confirmText"
            :cancel-text="modal.cancelText"
            :icon="modal.icon"
            :data-qa="modal.dataQa"
            @on-confirm="modal.onConfirm"
            @on-close="modal.onConfirm"
        >
            <p>{{ modal.content }}</p>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import Modal from '@/components/Modal.vue'
import Tooltip from '@/components/Tooltip.vue'

import { useTransactionsStore } from '@/stores/transactions'

import { accessToFeature } from '@/helper/services/features'
import { 
    isReportTransactionErasable,
    toggleFavourite 
} from '@/helper/services/transaction'

import { formatDate, sbb } from '@/filters'

import { adsConsumerLinks } from '@/data/typo3Links'

import type { CbraTransaction } from '@/types/cbra/transactions'
import type { ModalProps } from '@/types/components/modal'
import { ReportPersonService } from '@/helper/services/reportPerson'
import { showError } from '@/helper/services/error'
import { isAxiosError } from 'axios'

const transactionsStore = useTransactionsStore()
const { t, locale } = useI18n()

const props = defineProps<{
    transaction: CbraTransaction | undefined
    hideDeleteAction?: boolean
}>()

const _transaction = ref<CbraTransaction | undefined>(props.transaction)

const deleteModal = ref<ModalProps>()
const notReadyModal = ref<ModalProps>()
const modal = ref<ModalProps>()

const featurePortfolioActive = computed(() => {
    return accessToFeature('portfolioViewActive')
})

const erasable = computed(() => {
    if (!_transaction.value) {
        return false
    }

    return isReportTransactionErasable(_transaction.value)
})

function _toggleFavourite() {
    if (!_transaction.value) return
    _transaction.value.favourite = !_transaction.value.favourite
    return toggleFavourite(_transaction.value)
}

const onDeleteModalConfirm = async() => {
    if (!_transaction.value) return
    deleteModal.value = undefined
    try {
        await ReportPersonService.deleteReportByTransaction(_transaction.value)

        modal.value = {
            id: 'test',
            title: t('transaction.list.consumer_reports.delete.overlay.title'),
            content: t('transaction.list.consumer_reports.delete.overlay.success.content'),
            icon: 'info',
            dataQa: 'delete-transaction-success',
            confirmText: t('shared.modals.ok'),
            onConfirm: () => {
                modal.value = undefined
                transactionsStore.executeNewSearch()
            }
            
        }
    } catch (error) {
        if (isAxiosError(error)) {
            modal.value = showError({ topic: ReportPersonService.TOPIC, func: 'deleteTransaction', xhr: error })
        }
    }
}

function deleteTransaction() {
    if (!erasable.value) {
        return
    }
    deleteModal.value = {
        id: 'test',
        title: t('transaction.list.consumer_reports.delete.overlay.title'),
        content: t('transaction.list.consumer_reports.delete.overlay.question'),
        icon: 'warning',
        confirmText: t('transaction.list.consumer_reports.delete.overlay.confirm'),
        cancelText: t('transaction.list.consumer_reports.delete.overlay.cancel'),
        dataQa: 'delete-transaction'
    }
}

function openDetailsPage() {
    if (!_transaction.value) return
    // if (true === false) {
    //     notReadyModal.value = {
    //         id: 'test',
    //         title: t('error.transaction.notReady'),
    //         content: t('error.transaction.notReady.description'),
    //         icon: 'warning',
    //         confirmText: t('shared.modals.ok')
    //     }
    //     return
    // }
    // 
    location.assign(`${adsConsumerLinks[locale.value]}?no_cache=1&transactionId=${_transaction.value.crefoTransactionId}`)
}
</script>
