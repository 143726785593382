import CONSTANTS from '@/data/constants'
import { getCbraInsuranceMatrix } from '@/services'

export async function getInsuranceMatrix(reportId: string) {
    const { data } = await getCbraInsuranceMatrix(reportId)

    const obj: any = {}

    CONSTANTS.WKV_INSURANCES_AMOUNT.map(elem => {
        const foo1 = data.premiumDetailsList.filter(element => {
            return element.insuranceSum === elem
        })

        // generating object for elem
        obj[elem] = {}

        foo1.map(item => {
            obj[elem][item.insurancePeriod] = {
                netInsurancePremium: {
                    price: item.netInsurancePremium.value,
                    currency: item.netInsurancePremium.currency
                },
                grossInsurancePremium: {
                    price: item.grossInsurancePremium.value,
                    currency: item.grossInsurancePremium.currency
                },
                insuranceTax: {
                    price: item.insuranceTax.value,
                    currency: item.insuranceTax.currency
                }
            }
        })
    })
    console.log('obj', obj)
    return obj
}