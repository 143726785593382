import { App } from 'vue'
import { createPinia } from 'pinia'
import VueScrollTo from 'vue-scrollto'
import vSelect from 'vue-select'
import VueNumeric from '@handcrafted-market/vue3-numeric'
import VuePaginatePlugin from '@svifty7/vue-paginate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from '@/router'
import i18n from '@/i18n'
import DataReady from '@/plugins/data-ready'

import '@/font-awesome'
import '@/validator'

export default function(app: App, noRouter = false) {
    if (!noRouter) {
        app.use(router)
    }
    app.use(i18n)

    const pinia = createPinia()
    app.use(pinia)
    app.use(DataReady)

    app.component('FontAwesomeIcon', FontAwesomeIcon)

    app.use(VueScrollTo, {
        container: 'body',
        duration: 500,
        easing: 'ease',
        offset: -60, // fixed header
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true,
    })
    app.component('VSelect', vSelect)
    app.component(VueNumeric)
    app.use(VuePaginatePlugin)
}
