<template>
    <a  
        :href="computedHref"
        :title="translatedTitle"
        :data-qa="computedDataQa"
        :class="computedLinkClass" 
        @click="_click"
    >
        {{ translatedText }}
    </a>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
    text: {
        type: String, 
        required: true,
        default: ''
    },
    href: {
        type: String,
        required: true,
        default: ''
    },
    disabled: {
        type: Boolean,
        required: true,
        default: false
    },
    title: {
        type: String,
        required: true
    },   
    product: {
        type: String,
        required: true
    }, 
    dataQa: {
        type: String,
        required: true,
        default: 'infoChapter-structuredata-check-solvency-link'
    },
    class: {
        type: String,
        required: false,
        default: ''
    },
})

const { t } = useI18n()

const computedHref = computed(() => {
    return props.disabled ? 'javascript: void(0);' : props.href
})
        
const translatedText = computed(() => {
    const translateKey = String(props.text)
    if (translateKey.length > 0) {
        return t(translateKey)
    } else {
        return ''
    }
})

const computedLinkClass = computed(() => {
    return props.disabled ? `${props.class} link--disabled` : props.class
})

const computedDataQa = computed(() => {
    return props.disabled ? `${props.dataQa}-disabled`: props.dataQa
})

const translatedTitle = computed(() => {
    const translateKey = String(props.title)
    if (translateKey.length > 0) {
        return t(translateKey)
    } else {
        return ''
    }
})

function _click() {    
    if (!props.disabled) {
        if (props.product) { 
            // crossSellingWebTrekkService.crossSellingClick(props.product) @TODO: implement crossSellingWebTrekkService
        }
        return true            
    } else {
        return false
    }        
}

</script>