import { ref, watch, type Ref } from 'vue'
import dayjs, { ManipulateType, OpUnitType } from 'dayjs'
import i18n from '@/i18n'
const { t } = i18n.global

import { CONSTS } from '@/helper/services/transaction'
import helpers from './misc'

type FilterParameters = {
    collectionProcedureStage?: string
    collectionFileClosed?: boolean
    observationType?: string
    observationExpirationType?: string
    collectionModificationDateTo?: string
    collectionModificationDateFrom?: string
    collectionCreationDateTo?: string
    collectionCreationDateFrom?: string
    collectionInvoiceDateTo?: string
    collectionInvoiceDateFrom?: string
    reportModificationDateTo?: string
    reportModificationDateFrom?: string
    reportCreationDateTo?: string
    reportCreationDateFrom?: string
}

// Implementation of portal-frontend-releases/build/ux-prototype/webapp/public/js/viewmodels/transactions/TransactionsListFilter.js
export default class TransactionsListFilter {
    filterMode: Ref<string>
    mld: dayjs.GlobalLocaleDataReturn

    activeReportFilters: Ref<Array<{ filter: string; label: any }>>
    activeCollectionFilters: Ref<Array<{ filter: string; label: any }>>

    selectedReportObservationType: Ref<string>
    // Open and Close (REPORT)
    selectedReportObservationExpireType: Ref<string | null>

    // Modification Date (REPORT)
    selectedReportModificationDateFrom: Ref<string | null>
    selectedReportModificationDateFromStart: Ref<string | null>
    selectedReportModificationDateFromEnd: Ref<string | null>
    selectedReportModificationDateFromStartDate: Ref<Date | null>
    selectedReportModificationDateFromEndDate: Ref<Date | null>

    // Creation Date (REPORT)
    selectedReportCreationDateFrom: Ref<string | null>
    selectedReportCreationDateFromStart: Ref<string | null>
    selectedReportCreationDateFromEnd: Ref<string | null>

    selectedReportCreationDateFromStartDate: Ref<Date | null>
    selectedReportCreationDateFromEndDate: Ref<Date | null>

    // ProcedureStages (COLLECTION)
    selectedCollectionProcedureStage: Ref<string>
    // Open and Close (COLLECTION)
    selectedCollectionFileClosed: Ref<boolean | null>

    // Invoice Date (COLLECTION)
    selectedCollectionInvoiceDateFrom: Ref<string | null>
    selectedCollectionInvoiceDateFromStart: Ref<string | null>
    selectedCollectionInvoiceDateFromEnd: Ref<string | null>
    selectedCollectionInvoiceDateFromStartDate: Ref<Date | null>
    selectedCollectionInvoiceDateFromEndDate: Ref<Date | null>

    // Modification Date (COLLECTION)
    selectedCollectionModificationDateFrom: Ref<string | null>
    selectedCollectionModificationDateFromStart: Ref<string | null>
    selectedCollectionModificationDateFromEnd: Ref<string | null>
    selectedCollectionModificationDateFromStartDate: Ref<Date | null>
    selectedCollectionModificationDateFromEndDate: Ref<Date | null>

    // Creation Date (COLLECTION)
    selectedCollectionCreationDateFrom: Ref<string | null>
    selectedCollectionCreationDateFromStart: Ref<string | null>
    selectedCollectionCreationDateFromEnd: Ref<string | null>
    selectedCollectionCreationDateFromStartDate: Ref<Date | null>
    selectedCollectionCreationDateFromEndDate: Ref<Date | null>

    static get CONSTANTS() {
        return {
            FILTER_DEFAULT_VALUES: {
                SELECTED_COLLECTION_PROCEDURE_STAGE: 'ALL',
                SELECTED_COLLECTION_FILE_CLOSED: null,
                SELECTED_COLLECTION_INVOICE_DATE_FROM: 'ALL',
                SELECTED_MODIFICATION_DATE_FROM: 'ALL',
                SELECTED_CREATION_DATE_FROM: 'ALL',
                SELECTED_REPORT_OBSERVATION_TYPE: 'ALL',
                SELECTED_REPORT_OBSERVATION_EXPIRE_TYPE: null
            },
            SELECTED_REPORT_OBSERVATION_TYPE: {
                ALL_VALUE: '',
                ALL_TEXT: t('transaction.listfilter.observationType.ALL')
            },
            SELECTED_COLLECTION_PROCEDURE_STAGE: {
                ALL_VALUE: '',
                ALL_TEXT: t('transaction.listfilter.procedureStage.ALL')
            },
            SELECTED_MODIFICATION_DATE_FROM_OPTIONS: {
                ALL: '',
                LAST_30_DAYS: [30, 'days'],
                LAST_60_DAYS: [60, 'days'],
                LAST_90_DAYS: [90, 'days'],
                CURRENT_YEAR: [0, 'years', 'year'],
                PREVIOUS_YEAR: [1, 'years', 'year'], // StartOf Year Subtract 1 year
                CUSTOM: 'CUSTOM'
            },
            SELECTED_CREATION_DATE_FROM_OPTIONS: {
                ALL: '',
                LAST_30_DAYS: [30, 'days'],
                LAST_60_DAYS: [60, 'days'],
                LAST_90_DAYS: [90, 'days'],
                CURRENT_YEAR: [0, 'years', 'year'],
                PREVIOUS_YEAR: [1, 'years', 'year'], // StartOf Year Subtract 1 year
                CUSTOM: 'CUSTOM'
            },
            SELECTED_COLLECTION_INVOICE_DATE_FROM_OPTIONS: {
                ALL: '',
                LAST_30_DAYS: [30, 'days'],
                LAST_60_DAYS: [60, 'days'],
                LAST_90_DAYS: [90, 'days'],
                CURRENT_YEAR: [0, 'years', 'year'],
                PREVIOUS_YEAR: [1, 'years', 'year'], // StartOf Year Subtract 1 year
                CUSTOM: 'CUSTOM'
            },
            FILTER_TYPES_MAP: {
                COLLECTION: [
                    'collectionProcedureStage',
                    'collectionFileClosed',
                    'collectionModificationDateFrom',
                    'collectionModificationDateTo',
                    'collectionCreationDateFrom',
                    'collectionCreationDateTo',
                    'collectionInvoiceDateFrom',
                    'collectionInvoiceDateTo'
                ],
                REPORT: [
                    'observationType',
                    'observationExpirationType',
                    'reportModificationDateFrom',
                    'reportModificationDateTo',
                    'reportCreationDateFrom',
                    'reportCreationDateTo'
                ]
            }
        }
    }

    constructor(
        filterMode: string,
        onChangeCallback: (resetPageIndex: boolean) => void
    ) {
        this.filterMode = ref(filterMode)

        this.mld = dayjs.localeData()

        this.activeReportFilters = ref([])
        this.activeCollectionFilters = ref([])
        this.selectedReportObservationType = ref(TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_REPORT_OBSERVATION_TYPE)
        this.selectedReportObservationExpireType = ref(TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_REPORT_OBSERVATION_EXPIRE_TYPE)
        this.selectedReportModificationDateFrom = ref(TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_MODIFICATION_DATE_FROM)
        this.selectedReportModificationDateFromStart = ref(null)
        this.selectedReportModificationDateFromEnd = ref(null)
        this.selectedReportModificationDateFromStartDate = ref(null)
        this.selectedReportModificationDateFromEndDate = ref(null)

        this.selectedReportCreationDateFrom = ref(TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_CREATION_DATE_FROM)
        this.selectedReportCreationDateFromStart = ref(null)
        this.selectedReportCreationDateFromStartDate = ref(null)
        this.selectedReportCreationDateFromEnd = ref(null)
        this.selectedReportCreationDateFromEndDate = ref(null)

        this.selectedCollectionProcedureStage = ref(TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_COLLECTION_PROCEDURE_STAGE)
        this.selectedCollectionFileClosed = ref(TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_COLLECTION_FILE_CLOSED)
        this.selectedCollectionInvoiceDateFrom = ref(TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_COLLECTION_INVOICE_DATE_FROM)
        this.selectedCollectionInvoiceDateFromStart = ref(null)
        this.selectedCollectionInvoiceDateFromEnd = ref(null)
        this.selectedCollectionInvoiceDateFromStartDate = ref(null)
        this.selectedCollectionInvoiceDateFromEndDate = ref(null)

        this.selectedCollectionModificationDateFrom = ref(TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_MODIFICATION_DATE_FROM)
        this.selectedCollectionModificationDateFromStart = ref(null)
        this.selectedCollectionModificationDateFromEnd = ref(null)
        this.selectedCollectionModificationDateFromStartDate = ref(null)
        this.selectedCollectionModificationDateFromEndDate = ref(null)

        this.selectedCollectionCreationDateFrom = ref(TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_CREATION_DATE_FROM)
        this.selectedCollectionCreationDateFromStart = ref(null)
        this.selectedCollectionCreationDateFromEnd = ref(null)
        this.selectedCollectionCreationDateFromStartDate = ref(null)
        this.selectedCollectionCreationDateFromEndDate = ref(null)

        watch(this.filterMode, () => onChangeCallback(true))
        watch(this.selectedReportObservationType, () => onChangeCallback(true))
        watch(this.selectedReportObservationExpireType, () => onChangeCallback(true))
        watch(this.selectedReportModificationDateFrom, () => onChangeCallback(true))
        watch(this.selectedReportModificationDateFromStart, () => onChangeCallback(true))
        watch(this.selectedReportModificationDateFromEnd, () => onChangeCallback(true))
        watch(this.selectedReportCreationDateFrom, () => onChangeCallback(true))
        watch(this.selectedReportCreationDateFromStart, () => onChangeCallback(true))
        watch(this.selectedReportCreationDateFromEnd, () => onChangeCallback(true))
        watch(this.selectedCollectionModificationDateFrom, () => onChangeCallback(true))
        watch(this.selectedCollectionModificationDateFromStart, () => onChangeCallback(true))
        watch(this.selectedCollectionModificationDateFromEnd, () => onChangeCallback(true))
        watch(this.selectedCollectionCreationDateFrom, () => onChangeCallback(true))
        watch(this.selectedCollectionCreationDateFromStart, () => onChangeCallback(true))
        watch(this.selectedCollectionCreationDateFromEnd, () => onChangeCallback(true))
        watch(this.selectedCollectionInvoiceDateFrom, () => onChangeCallback(true))
        watch(this.selectedCollectionInvoiceDateFromStart, () => onChangeCallback(true))
        watch(this.selectedCollectionInvoiceDateFromEnd, () => onChangeCallback(true))
        watch(this.selectedCollectionFileClosed, () => onChangeCallback(true))
        watch(this.selectedCollectionProcedureStage, () => onChangeCallback(true))
    }

    /**
     * Gets the default value for the given filter.
     *
     * Undefined is returned, for unknown filterParameters.
     *
     * @param {string} filterParameter
     * @return {*}
     */
    getFilterParameterDefaultValue(filterParameter: string) {
        if (typeof filterParameter !== 'string' || filterParameter.length === 0) {
            return
        }

        switch (this.filterMode.value) {
        case CONSTS.TRANSACTION_TYPE.COLLECTION:
            switch (filterParameter) {
            case 'collectionProcedureStage':
                return TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_COLLECTION_PROCEDURE_STAGE
            case 'collectionFileClosed':
                return TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_COLLECTION_FILE_CLOSED
            case 'collectionModificationDateFrom':
            case 'collectionModificationDateTo':
                return TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_MODIFICATION_DATE_FROM
            case 'collectionCreationDateFrom':
            case 'collectionCreationDateTo':
                return TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_CREATION_DATE_FROM
            case 'collectionInvoiceDateFrom':
            case 'collectionInvoiceDateTo':
                return TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_COLLECTION_INVOICE_DATE_FROM
            }
            break
        case CONSTS.TRANSACTION_TYPE.REPORT:
            switch (filterParameter) {
            case 'observationType':
                return TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_REPORT_OBSERVATION_TYPE
            case 'observationExpirationType':
                return TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_REPORT_OBSERVATION_EXPIRE_TYPE
            case 'reportModificationDateFrom':
            case 'reportModificationDateTo':
                return TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_MODIFICATION_DATE_FROM
            case 'reportCreationDateFrom':
            case 'reportCreationDateTo':
                return TransactionsListFilter.CONSTANTS.FILTER_DEFAULT_VALUES.SELECTED_CREATION_DATE_FROM
            }
            break
        }
    }

    /**
     * Returns filter parameters for active/selected filters.
     *
     * Filters with default values will be omitted.
     *
     * @param {boolean} [withTransformedValues]
     * @return {Object}
     */
    getSelectedFilterParameters(withTransformedValues: boolean = false): FilterParameters { 
        const filterParameters: FilterParameters = {}
        switch (this.filterMode.value) {
        case CONSTS.TRANSACTION_TYPE.COLLECTION:
            if (this.selectedCollectionProcedureStage.value !== this.getFilterParameterDefaultValue('collectionProcedureStage')) {
                filterParameters['collectionProcedureStage'] = this.selectedCollectionProcedureStage.value
            }
            if (
                this.selectedCollectionFileClosed.value !== null && 
                this.selectedCollectionFileClosed.value !== this.getFilterParameterDefaultValue('collectionFileClosed')
            ) {
                filterParameters['collectionFileClosed'] = this.selectedCollectionFileClosed.value
            }
            if (this.selectedCollectionCreationDateFrom.value !== this.getFilterParameterDefaultValue('collectionCreationDateFrom')) {
                if (this.selectedCollectionCreationDateFrom.value === TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS.CUSTOM) {
                    if (this.selectedCollectionCreationDateFromStart.value || this.selectedCollectionCreationDateFromEnd.value) {
                        if (this.selectedCollectionCreationDateFromStart.value) {
                            const startDate = this._getStartDate(this.selectedCollectionCreationDateFromStart.value)
                            if (startDate) {
                                filterParameters['collectionCreationDateFrom'] = startDate
                            }
                        }
                        if (this.selectedCollectionCreationDateFromEnd.value) {
                            const endDate = this._getEndDate(this.selectedCollectionCreationDateFromEnd)
                            if (endDate) {
                                filterParameters['collectionCreationDateTo'] = endDate
                            }
                        }
                    }
                } else { 
                    if (this.selectedCollectionCreationDateFrom.value !== null) {
                        if (withTransformedValues) {
                            const timeFromDayjs = this.getDayjsForTransformValues(this.selectedCollectionCreationDateFrom)
                            filterParameters['collectionCreationDateFrom'] = timeFromDayjs.format('YYYY-MM-DD')
                            if (this.selectedCollectionCreationDateFrom.value.endsWith('_YEAR')) {
                                const startOf = TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS[this.selectedCollectionCreationDateFrom.value][2]
                                filterParameters['collectionCreationDateTo'] = timeFromDayjs.endOf(startOf).format('YYYY-MM-DD')
                            }
                        } else {
                            filterParameters['collectionCreationDateFrom'] = this.selectedCollectionCreationDateFrom.value
                        }
                    }
                }
            }
            if (this.selectedCollectionModificationDateFrom.value !== this.getFilterParameterDefaultValue('collectionModificationDateFrom')) {
                if (this.selectedCollectionModificationDateFrom.value === TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS.CUSTOM) {
                    if (this.selectedCollectionModificationDateFromStart.value || this.selectedCollectionModificationDateFromEnd.value) {
                        if (this.selectedCollectionModificationDateFromStart.value) {
                            const startDate = this._getStartDate(this.selectedCollectionModificationDateFromStart.value)
                            if (startDate) {
                                filterParameters['collectionModificationDateFrom'] = startDate
                            }
                        }
                        if (this.selectedCollectionModificationDateFromEnd.value) {
                            const endDate = this._getEndDate(this.selectedCollectionModificationDateFromEnd.value)
                            if (endDate) {
                                filterParameters['collectionModificationDateTo'] = endDate
                            }
                        }
                    }
                } else {
                    if (this.selectedCollectionModificationDateFrom.value !== null) {
                        if (withTransformedValues) {
                            const timeFromDayjs = this.getDayjsForTransformValues(this.selectedCollectionModificationDateFrom)
                            filterParameters['collectionModificationDateFrom'] = timeFromDayjs.format('YYYY-MM-DD')
                            if (this.selectedCollectionModificationDateFrom.value.endsWith('_YEAR')) {
                                const startOf = TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS[this.selectedCollectionModificationDateFrom.value][2]
                                filterParameters['collectionModificationDateTo'] = timeFromDayjs.endOf(startOf).format('YYYY-MM-DD')
                            }
                        } else {
                            filterParameters['collectionModificationDateFrom'] = this.selectedCollectionModificationDateFrom.value
                        }
                    }
                }
            }
            if (this.selectedCollectionInvoiceDateFrom.value !== this.getFilterParameterDefaultValue('collectionInvoiceDateFrom')) {
                if (this.selectedCollectionInvoiceDateFrom.value === TransactionsListFilter.CONSTANTS.SELECTED_COLLECTION_INVOICE_DATE_FROM_OPTIONS.CUSTOM) {
                    if (this.selectedCollectionInvoiceDateFromStart.value || this.selectedCollectionInvoiceDateFromEnd.value) {
                        if (this.selectedCollectionInvoiceDateFromStart.value) {
                            const startDate = this._getStartDate(this.selectedCollectionInvoiceDateFromStart)
                            if (startDate) {
                                filterParameters['collectionInvoiceDateFrom'] = startDate
                            }
                        }
                        if (this.selectedCollectionInvoiceDateFromEnd.value) {
                            const endDate = this._getEndDate(this.selectedCollectionInvoiceDateFromEnd.value)
                            if (endDate) {
                                filterParameters['collectionInvoiceDateTo'] = endDate
                            }
                        }
                    }
                } else {
                    if (this.selectedCollectionInvoiceDateFrom.value !== null) {
                        if (withTransformedValues) {
                            const timeFromDayjs = this.getDayjsForTransformValues(this.selectedCollectionInvoiceDateFrom)
                            filterParameters['collectionInvoiceDateFrom'] = timeFromDayjs.format('YYYY-MM-DD')
                            if (this.selectedCollectionInvoiceDateFrom.value.endsWith('_YEAR')) {
                                const startOf = TransactionsListFilter.CONSTANTS.SELECTED_COLLECTION_INVOICE_DATE_FROM_OPTIONS[this.selectedCollectionInvoiceDateFrom.value][2]
                                filterParameters['collectionInvoiceDateTo'] = timeFromDayjs.endOf(startOf).format('YYYY-MM-DD')
                            }
                        } else {
                            filterParameters['collectionInvoiceDateFrom'] = this.selectedCollectionInvoiceDateFrom.value
                        }
                    }
                }
            }
            break
        case CONSTS.TRANSACTION_TYPE.REPORT:
            if (this.selectedReportObservationType.value !== this.getFilterParameterDefaultValue('observationType')) {
                filterParameters['observationType'] = this.selectedReportObservationType.value
            }
            if (
                this.selectedReportObservationExpireType.value !== null && 
                this.selectedReportObservationExpireType.value !== this.getFilterParameterDefaultValue('observationExpirationType')
            ) {
                filterParameters['observationExpirationType'] = this.selectedReportObservationExpireType.value
            }
            if (this.selectedReportCreationDateFrom.value !== this.getFilterParameterDefaultValue('reportCreationDateFrom')) {
                if (this.selectedReportCreationDateFrom.value === TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS.CUSTOM) {
                    const startDate = this._getStartDate(this.selectedReportCreationDateFromStart.value)
                    if (startDate) {
                        filterParameters['reportCreationDateFrom'] = startDate
                    }
                    const endDate = this._getEndDate(this.selectedReportCreationDateFromEnd.value)
                    if (endDate) {
                        filterParameters['reportCreationDateTo'] = endDate
                    }
                } else { 
                    if (this.selectedReportCreationDateFrom.value !== null) {
                        if (withTransformedValues) {
                            const timeFromDayjs = this.getDayjsForTransformValues(this.selectedReportCreationDateFrom)
                            filterParameters['reportCreationDateFrom'] = timeFromDayjs.format('YYYY-MM-DD')
                            if (this.selectedReportCreationDateFrom.value.endsWith('_YEAR')) {
                                const startOf = TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS[this.selectedReportCreationDateFrom.value][2]
                                filterParameters['reportCreationDateTo'] = timeFromDayjs.endOf(startOf).format('YYYY-MM-DD')
                            }
                        } else {
                            filterParameters['reportCreationDateFrom'] = this.selectedReportCreationDateFrom.value
                        }
                    }
                }
            }
            if (this.selectedReportModificationDateFrom.value !== this.getFilterParameterDefaultValue('reportModificationDateFrom')) {
                if (this.selectedReportModificationDateFrom.value === TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS.CUSTOM) {
                    const startDate = this._getStartDate(this.selectedReportModificationDateFromStart.value)
                    if (startDate) {
                        filterParameters['reportModificationDateFrom'] = startDate
                    }
                    const endDate = this._getEndDate(this.selectedReportModificationDateFromEnd.value)
                    if (endDate) {
                        filterParameters['reportModificationDateTo'] = endDate
                    }
                } else {
                    if (this.selectedReportModificationDateFrom.value !== null) {
                        if (withTransformedValues) {
                            const timeFromDayjs = this.getDayjsForTransformValues(this.selectedReportModificationDateFrom)
                            filterParameters['reportModificationDateFrom'] = timeFromDayjs.format('YYYY-MM-DD')
                            if (this.selectedReportModificationDateFrom.value.endsWith('_YEAR')) {
                                const startOf = TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS[this.selectedReportModificationDateFrom.value][2]
                                filterParameters['reportModificationDateTo'] = timeFromDayjs.endOf(startOf).format('YYYY-MM-DD')
                            }
                        } else {
                            filterParameters['reportModificationDateFrom'] = this.selectedReportModificationDateFrom.value
                        }
                    }
                }
            }
            break
        }

        return filterParameters
    }

    getDayjsForTransformValues(ref: Ref): dayjs.Dayjs {
        const count: number = TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS[ref.value][0]
        const period: ManipulateType = TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS[ref.value][1]
        const startOf: OpUnitType = TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS[ref.value][2]

        let timeFromDayjs = dayjs()
        if (typeof count === 'number' && count !== 0) {
            timeFromDayjs = timeFromDayjs.subtract(count, period)
        }
        if (typeof startOf === 'string' && startOf.length) {
            timeFromDayjs = timeFromDayjs.startOf(startOf)
        }
        return timeFromDayjs
    }

    /**
     * Set filter parameters to given values.
     *
     * @param {Object} [filterParameters]
     * @param {boolean} [resetToDefaultValue]
     * @param {boolean} [updateOnChange]
     * @return {boolean} true when values were changed
     */
    setSelectedFilterParameters(
        filterParameters: { [key: string]: string | undefined } = {}, 
        resetToDefaultValue = false, 
        updateOnChange = false
    ): boolean {
        if (filterParameters === null || typeof filterParameters !== 'object') {
            return false
        }

        let changedValuesDetected = false

        switch (this.filterMode.value) {
        case CONSTS.TRANSACTION_TYPE.COLLECTION:
            [
                'collectionProcedureStage', 
                'collectionFileClosed', 
                'collectionModificationDateFrom', 
                'collectionModificationDateTo', 
                'collectionCreationDateFrom', 
                'collectionCreationDateTo', 
                'collectionInvoiceDateFrom', 
                'collectionInvoiceDateTo'
            ].forEach((filterParameter: string) => {
                if (typeof filterParameters[filterParameter] === 'undefined' && resetToDefaultValue) {
                    if (this.setSelectedFilterParameter(filterParameter, this.getFilterParameterDefaultValue(filterParameter))) {
                        changedValuesDetected = true
                    }
                } else if (typeof filterParameters[filterParameter] !== 'undefined') {
                    if (this.setSelectedFilterParameter(filterParameter, filterParameters[filterParameter])) {
                        changedValuesDetected = true
                    }
                }
            })
            break
        case CONSTS.TRANSACTION_TYPE.REPORT:
            [
                'observationType', 
                'observationExpirationType', 
                'reportModificationDateFrom', 
                'reportModificationDateTo', 
                'reportCreationDateFrom', 
                'reportCreationDateTo'
            ].forEach((filterParameter: string) => {
                if (typeof filterParameters[filterParameter] === 'undefined' && resetToDefaultValue) {
                    if (this.setSelectedFilterParameter(filterParameter, this.getFilterParameterDefaultValue(filterParameter))) {
                        changedValuesDetected = true
                    }
                } else if (typeof filterParameters[filterParameter] !== 'undefined') {
                    if (this.setSelectedFilterParameter(filterParameter, filterParameters[filterParameter])) {
                        changedValuesDetected = true
                    }
                }
            })
            break
        }

        if (updateOnChange && changedValuesDetected) {
            // this.update()
        }

        return changedValuesDetected
    }

    setFilterMode(filterMode: string): boolean {
        if (typeof filterMode !== 'string') {
            return false
        }

        this.filterMode.value = filterMode

        return true
    }

    /**
     * Changes a single filter parameter.
     *
     * If updateOnChange = true, an update request will be send, if the value has changed.
     *
     * @param {string} filterParameter
     * @param {*} filterValue
     * @param {boolean} [updateOnChange]
     * @return {boolean} true when values were changed
     */
    setSelectedFilterParameter(
        filterParameter: string, 
        filterValue: any, 
        updateOnChange: boolean = false
    ): boolean {
        if (typeof filterParameter !== 'string' || filterParameter.length === 0) {
            return false
        }
        let changedValueDetected = false

        switch (this.filterMode.value) {
        case CONSTS.TRANSACTION_TYPE.COLLECTION:
            switch (filterParameter) {
            case 'collectionProcedureStage':
                if (typeof filterValue === 'string') {
                    if (this.selectedCollectionProcedureStage.value !== filterValue) {
                        changedValueDetected = true
                    }
                    this.selectedCollectionProcedureStage.value = filterValue
                }
                break
            case 'collectionFileClosed':
                if (typeof filterValue === 'boolean') {
                    if (this.selectedCollectionFileClosed.value !== filterValue) {
                        changedValueDetected = true
                    }
                    this.selectedCollectionFileClosed.value = filterValue
                } else if (filterValue === null) {
                    if (this.selectedCollectionFileClosed !== null) {
                        changedValueDetected = true
                    }
                    this.selectedCollectionFileClosed.value = null
                }
                break
            case 'collectionModificationDateFrom':
                if (filterValue) {
                    const isCustomValue = Object.entries(TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS)
                        .filter(option => option[0] !== 'CUSTOM')
                        .findIndex(option => option[0] === filterValue)

                    if (isCustomValue === -1) {
                        if (
                            this.selectedCollectionModificationDateFrom.value !== 'CUSTOM' || 
                            this.selectedCollectionModificationDateFromStart.value !== filterValue
                        ) {
                            changedValueDetected = true
                        }
                        this.selectedCollectionModificationDateFrom.value = TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS.CUSTOM
                        this.selectedCollectionModificationDateFromStart.value = helpers.isDate(filterValue) ? dayjs(filterValue).format('YYYY-MM-DD') : null
                        this.selectedCollectionModificationDateFromStartDate.value = filterValue
                    } else {
                        if (this.selectedCollectionModificationDateFrom.value !== filterValue) {
                            changedValueDetected = true
                        }
                        this.selectedCollectionModificationDateFrom.value = filterValue
                        this.selectedCollectionModificationDateFromStartDate.value = null
                        this.selectedCollectionModificationDateFromEndDate.value = null
                    }
                }
                break
            case 'collectionModificationDateTo':
                if ( filterValue) {
                    const isCustomValue = Object.entries(TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS)
                        .filter(option => option[0] !== 'CUSTOM')
                        .findIndex(option => option[0] === filterValue)

                    if (isCustomValue === -1) {
                        if (
                            this.selectedCollectionModificationDateFrom.value !== 'CUSTOM' || 
                            this.selectedCollectionModificationDateFromEnd.value !== filterValue
                        ) {
                            changedValueDetected = true
                        }
                        this.selectedCollectionModificationDateFrom.value = TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS.CUSTOM
                        this.selectedCollectionModificationDateFromEnd.value = helpers.isDate(filterValue) ? dayjs(filterValue).format('YYYY-MM-DD') : null
                        this.selectedCollectionModificationDateFromEndDate.value = filterValue
                    }
                }
                break
            case 'collectionCreationDateFrom':
                if (filterValue) {
                    const isCustomValue = Object.entries(TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS)
                        .filter(option => option[0] !== 'CUSTOM')
                        .findIndex(option => option[0] === filterValue)
                    if (isCustomValue === -1) {
                        if (
                            this.selectedCollectionCreationDateFrom.value !== 'CUSTOM' || 
                            this.selectedCollectionCreationDateFromStart.value !== filterValue
                        ) {
                            changedValueDetected = true
                        }
                        this.selectedCollectionCreationDateFrom.value = TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS.CUSTOM
                        this.selectedCollectionCreationDateFromStart.value = helpers.isDate(filterValue) ? dayjs(filterValue).format('YYYY-MM-DD') : null
                        this.selectedCollectionCreationDateFromStartDate.value = filterValue
                    } else {
                        if (this.selectedCollectionCreationDateFrom.value !== filterValue) {
                            changedValueDetected = true
                        }
                        this.selectedCollectionCreationDateFrom.value = filterValue
                        this.selectedCollectionCreationDateFromStartDate.value = null
                        this.selectedCollectionCreationDateFromEndDate.value = null
                    }
                }
                break
            case 'collectionCreationDateTo':
                if (filterValue) {
                    const isCustomValue = Object.entries(TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS)
                        .filter(option => option[0] !== 'CUSTOM')
                        .findIndex(option => option[0] === filterValue)
                    if (isCustomValue === -1) {
                        if (
                            this.selectedCollectionCreationDateFrom.value !== 'CUSTOM' || 
                            this.selectedCollectionCreationDateFromEnd.value !== filterValue
                        ) {
                            changedValueDetected = true
                        }
                        this.selectedCollectionCreationDateFrom.value = TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS.CUSTOM
                        this.selectedCollectionCreationDateFromEnd.value = helpers.isDate(filterValue) ? dayjs(filterValue).format('YYYY-MM-DD') : null
                        this.selectedCollectionCreationDateFromEndDate.value = filterValue 
                    }
                }
                break
            case 'collectionInvoiceDateFrom':
                if (filterValue) {
                    const isCustomValue = Object.entries(TransactionsListFilter.CONSTANTS.SELECTED_COLLECTION_INVOICE_DATE_FROM_OPTIONS)
                        .filter(option => option[0] !== 'CUSTOM')
                        .findIndex(option => option[0] === filterValue)
                    if (isCustomValue === -1) {
                        if (
                            this.selectedCollectionInvoiceDateFrom.value !== 'CUSTOM' || 
                            this.selectedCollectionInvoiceDateFromStart.value !== filterValue
                        ) {
                            changedValueDetected = true
                        }
                        this.selectedCollectionInvoiceDateFrom.value = TransactionsListFilter.CONSTANTS.SELECTED_COLLECTION_INVOICE_DATE_FROM_OPTIONS.CUSTOM
                        this.selectedCollectionInvoiceDateFromStart.value = helpers.isDate(filterValue) ? dayjs(filterValue).format('YYYY-MM-DD') : null
                        this.selectedCollectionInvoiceDateFromStartDate.value = filterValue 
                    } else {
                        if (this.selectedCollectionInvoiceDateFrom.value !== filterValue) {
                            changedValueDetected = true
                        }
                        this.selectedCollectionInvoiceDateFrom.value = filterValue
                        this.selectedCollectionInvoiceDateFromStartDate.value = null
                        this.selectedCollectionInvoiceDateFromEndDate.value = null
                    }
                }
                break
            case 'collectionInvoiceDateTo':
                if ( filterValue) {
                    const isCustomValue = Object.entries(TransactionsListFilter.CONSTANTS.SELECTED_COLLECTION_INVOICE_DATE_FROM_OPTIONS)
                        .filter(option => option[0] !== 'CUSTOM')
                        .findIndex(option => option[0] === filterValue)
                    if (isCustomValue === -1) {
                        if (
                            this.selectedCollectionInvoiceDateFrom.value !== 'CUSTOM' || 
                            this.selectedCollectionInvoiceDateFromEnd.value !== filterValue
                        ) {
                            changedValueDetected = true
                        }
                        this.selectedCollectionInvoiceDateFrom.value = TransactionsListFilter.CONSTANTS.SELECTED_COLLECTION_INVOICE_DATE_FROM_OPTIONS.CUSTOM
                        this.selectedCollectionInvoiceDateFromEnd.value = helpers.isDate(filterValue) ? dayjs(filterValue).format('YYYY-MM-DD') : null
                        this.selectedCollectionInvoiceDateFromEndDate.value = filterValue 
                    }
                }
                break
            }
            break
        case CONSTS.TRANSACTION_TYPE.REPORT:
            switch (filterParameter) {
            case 'observationType':
                if (typeof filterValue === 'string') {
                    if (this.selectedReportObservationType.value !== filterValue) {
                        changedValueDetected = true
                    }
                    this.selectedReportObservationType.value = filterValue
                }
                break
            case 'observationExpirationType':
                if (typeof filterValue === 'string') {
                    if (
                        this.selectedReportObservationExpireType.value === null ||
                        (
                            this.selectedReportObservationExpireType.value && 
                            this.selectedReportObservationExpireType.value !== filterValue
                        )
                    ) {
                        changedValueDetected = true
                    }
                    this.selectedReportObservationExpireType.value = filterValue
                } else if (filterValue === null) {
                    if (
                        !this.selectedReportObservationExpireType.value ||
                        (
                            this.selectedReportObservationExpireType.value &&
                            this.selectedReportObservationExpireType.value !== null
                        )
                    ) {
                        changedValueDetected = true
                    }
                    this.selectedReportObservationExpireType.value = null
                }
                break
            case 'reportModificationDateFrom':
                if ( filterValue) {
                    const isCustomValue = Object.entries(TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS)
                        .filter(option => option[0] !== 'CUSTOM')
                        .findIndex(option => option[0] === filterValue)
                    if (isCustomValue === -1) {
                        if (
                            this.selectedReportModificationDateFrom.value !== 'CUSTOM' || 
                            this.selectedReportModificationDateFromStart.value !== filterValue
                        ) {
                            changedValueDetected = true
                        }
                        this.selectedReportModificationDateFrom.value = TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS.CUSTOM
                        this.selectedReportModificationDateFromStart.value = helpers.isDate(filterValue) ? dayjs(filterValue).format('YYYY-MM-DD') : null
                        this.selectedReportModificationDateFromStartDate.value = filterValue
                    } else {
                        if (this.selectedReportModificationDateFrom.value !== filterValue) {
                            changedValueDetected = true
                        }
                        this.selectedReportModificationDateFrom.value = filterValue
                        this.selectedReportModificationDateFromStartDate.value = null
                        this.selectedReportModificationDateFromEndDate.value = null
                    }
                }
                break
            case 'reportModificationDateTo':
                if (filterValue) {
                    const isCustomValue = Object.entries(TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS)
                        .filter(option => option[0] !== 'CUSTOM')
                        .findIndex(option => option[0] === filterValue)
                    if (isCustomValue === -1) {
                        if (
                            this.selectedReportModificationDateFrom.value !== 'CUSTOM' || 
                            this.selectedReportModificationDateFromEnd.value !== filterValue
                        ) {
                            changedValueDetected = true
                        }
                        this.selectedReportModificationDateFrom.value = TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS.CUSTOM
                        this.selectedReportModificationDateFromEnd.value = helpers.isDate(filterValue) ? dayjs(filterValue).format('YYYY-MM-DD') : null
                        this.selectedReportModificationDateFromEndDate.value = filterValue
                    }
                }
                break
            case 'reportCreationDateFrom':
                if (filterValue) {
                    const isCustomValue = Object.entries(TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS)
                        .filter(option => option[0] !== 'CUSTOM')
                        .findIndex(option => option[0] === filterValue)
                    if (isCustomValue === -1) {
                        if (
                            this.selectedReportCreationDateFrom.value !== 'CUSTOM' || 
                            this.selectedReportCreationDateFromStart.value !== filterValue
                        ) {
                            changedValueDetected = true
                        }
                        this.selectedReportCreationDateFrom.value = TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS.CUSTOM
                        this.selectedReportCreationDateFromStart.value = helpers.isDate(filterValue) ? dayjs(filterValue).format('YYYY-MM-DD') : null
                        this.selectedReportCreationDateFromStartDate.value = filterValue
                    } else {
                        if (this.selectedReportCreationDateFrom.value !== filterValue) {
                            changedValueDetected = true
                        }
                        this.selectedReportCreationDateFrom.value = filterValue
                        this.selectedReportCreationDateFromStartDate.value = null
                        this.selectedReportCreationDateFromEndDate.value = null
                    }
                }
                break
            case 'reportCreationDateTo':   
                if (filterValue) {
                    const isCustomValue = Object.entries(TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS)
                        .filter(option => option[0] !== 'CUSTOM')
                        .findIndex(option => option[0] === filterValue)
                    if (isCustomValue === -1) {
                        if (
                            this.selectedReportCreationDateFrom.value !== 'CUSTOM' || 
                            this.selectedReportCreationDateFromEnd.value !== filterValue
                        ) {
                            changedValueDetected = true
                        }
                        this.selectedReportCreationDateFrom.value = TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS.CUSTOM
                        this.selectedReportCreationDateFromEnd.value = helpers.isDate(filterValue) ? dayjs(filterValue).format('YYYY-MM-DD') : null
                        this.selectedReportCreationDateFromEndDate.value = filterValue 
                    }
                }
                break
            }
            break
        }

        if (
            filterValue === 'ALL' && 
            (
                filterParameter.endsWith('DateFrom') || 
                filterParameter.endsWith('DateTo')
            )
        ) {
            let baseFilterParameterName = filterParameter.replace('DateTo', 'DateFrom')
            baseFilterParameterName = 'selected' + baseFilterParameterName.charAt(0).toUpperCase() + baseFilterParameterName.substring(1)
            this[baseFilterParameterName + 'Start'].value = null
            this[baseFilterParameterName + 'End'].value = null
        }
    
        if (changedValueDetected) {
            this.updateActiveFilterList()
            if (updateOnChange) {
                // this.update()
            }
        }

        return changedValueDetected
    }

    getNormalizedFilterParameters(optionalFilterParameters: any) {
        if (optionalFilterParameters) {
            for (const i in optionalFilterParameters) {
                if (i === 'collectionModificationDateFrom' || i === 'reportModificationDateFrom') {
                    optionalFilterParameters['modificationDateFrom'] = optionalFilterParameters[i]
                    delete optionalFilterParameters.collectionModificationDateFrom
                    delete optionalFilterParameters.reportModificationDateFrom
                } else if (i === 'collectionModificationDateTo' || i === 'reportModificationDateTo') {
                    optionalFilterParameters['modificationDateTo'] = optionalFilterParameters[i]
                    delete optionalFilterParameters.collectionModificationDateTo
                    delete optionalFilterParameters.reportModificationDateTo
                } else if (i === 'collectionCreationDateFrom' || i === 'reportCreationDateFrom') {
                    optionalFilterParameters['creationDateFrom'] = optionalFilterParameters[i]
                    delete optionalFilterParameters.collectionCreationDateFrom
                    delete optionalFilterParameters.reportCreationDateFrom
                } else if (i === 'collectionCreationDateTo' || i === 'reportCreationDateTo') {
                    optionalFilterParameters['creationDateTo'] = optionalFilterParameters[i]
                    delete optionalFilterParameters.collectionCreationDateTo
                    delete optionalFilterParameters.reportCreationDateTo
                } else if (i === 'collectionInvoiceDateFrom') {
                    optionalFilterParameters['invoiceDateFrom'] = optionalFilterParameters[i]
                    delete optionalFilterParameters.collectionInvoiceDateFrom
                } else if (i === 'collectionInvoiceDateTo') {
                    optionalFilterParameters['invoiceDateTo'] = optionalFilterParameters[i]
                    delete optionalFilterParameters.collectionInvoiceDateTo
                }
            }
            return optionalFilterParameters
        } else {
            return {}
        }
    }

    /**
     * Returns the label for the individual filter reset button.
     *
     * @param {string} filterParameter
     * @return {string}
     */
    getFilterParameterResetLabel(filterParameter: string): string {
        if (typeof filterParameter !== 'string' || filterParameter.length === 0) {
            return ''
        }

        switch (this.filterMode.value) {
        case CONSTS.TRANSACTION_TYPE.COLLECTION:
            switch (filterParameter) {
            case 'collectionProcedureStage':
                if (this.selectedCollectionProcedureStage.value !== this.getFilterParameterDefaultValue(filterParameter)) {
                    return t('transaction.listfilter.procedureStage.' + this.selectedCollectionProcedureStage.value)
                }
                break
            case 'collectionFileClosed':
                if (this.selectedCollectionFileClosed.value !== this.getFilterParameterDefaultValue(filterParameter)) {
                    return t(`transaction.listfilter.label.collectionFileClosed${this.selectedCollectionFileClosed.value ? 'Yes' : 'No'}`)
                }
                break
            case 'collectionCreationDateFrom':
            case 'collectionCreationDateTo':
                if (this.selectedCollectionCreationDateFrom.value === TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS.CUSTOM) {
                    if (this.selectedCollectionCreationDateFromStart.value && this.selectedCollectionCreationDateFromEnd.value) {
                        const startDate = dayjs(this._getStartDate(this.selectedCollectionCreationDateFromStart.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        const endDate = dayjs(this._getEndDate(this.selectedCollectionCreationDateFromEnd.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.creationDateFrom.CUSTOM.label') + ' ' + startDate + ' - ' + endDate
                    } else if (this.selectedCollectionCreationDateFromStart.value) {
                        const startDate = dayjs(this._getStartDate(this.selectedCollectionCreationDateFromStart.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.label.creationDateFrom.start.label') + ' ' + startDate
                    } else if (this.selectedCollectionCreationDateFromEnd.value) {
                        const endDate = dayjs(this._getEndDate(this.selectedCollectionCreationDateFromEnd.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.label.creationDateFrom.end.label') + ' ' + endDate
                    }
                } else if (this.selectedCollectionCreationDateFrom.value !== this.getFilterParameterDefaultValue(filterParameter)) {
                    return t('transaction.listfilter.creationDateFrom.label.' + this.selectedCollectionCreationDateFrom.value)
                }
                break
            case 'collectionModificationDateFrom':
            case 'collectionModificationDateTo':
                if (this.selectedCollectionModificationDateFrom.value === TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS.CUSTOM) {
                    if (this.selectedCollectionModificationDateFromStart.value && this.selectedCollectionModificationDateFromEnd.value) {
                        const startDate = dayjs(this._getStartDate(this.selectedCollectionModificationDateFromStart.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        const endDate = dayjs(this._getEndDate(this.selectedCollectionModificationDateFromEnd.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.modificationDateFrom.CUSTOM.label') + ' ' + startDate + ' - ' + endDate
                    } else if (this.selectedCollectionModificationDateFromStart.value) {
                        const startDate = dayjs(this._getStartDate(this.selectedCollectionModificationDateFromStart.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.label.modificationDateFrom.start.label') + ' ' + startDate
                    } else if (this.selectedCollectionModificationDateFromEnd.value) {
                        const endDate = dayjs(this._getEndDate(this.selectedCollectionModificationDateFromEnd.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.label.modificationDateFrom.end.label') + ' ' + endDate
                    }
                } else if (this.selectedCollectionModificationDateFrom.value !== this.getFilterParameterDefaultValue(filterParameter)) {
                    return t('transaction.listfilter.modificationDateFrom.label.' + this.selectedCollectionModificationDateFrom.value)
                }
                break
            case 'collectionInvoiceDateFrom':
            case 'collectionInvoiceDateTo':
                if (this.selectedCollectionInvoiceDateFrom.value === TransactionsListFilter.CONSTANTS.SELECTED_COLLECTION_INVOICE_DATE_FROM_OPTIONS.CUSTOM) {
                    if (this.selectedCollectionInvoiceDateFromStart.value && this.selectedCollectionInvoiceDateFromEnd.value) {
                        const startDate = dayjs(this._getStartDate(this.selectedCollectionInvoiceDateFromStart.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        const endDate = dayjs(this._getEndDate(this.selectedCollectionInvoiceDateFromEnd.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.invoiceDateFrom.CUSTOM.label') + ' ' + startDate + ' - ' + endDate
                    } else if (this.selectedCollectionInvoiceDateFromStart.value) {
                        const startDate = dayjs(this._getStartDate(this.selectedCollectionInvoiceDateFromStart.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.label.invoiceDateFrom.start.label') + ' ' + startDate
                    } else if (this.selectedCollectionInvoiceDateFromEnd.value) {
                        const endDate = dayjs(this._getEndDate(this.selectedCollectionInvoiceDateFromEnd.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.label.invoiceDateFrom.end.label') + ' ' + endDate
                    }
                } else if (this.selectedCollectionInvoiceDateFrom.value !== this.getFilterParameterDefaultValue(filterParameter)) {
                    return t('transaction.listfilter.invoiceDateFrom.label.' + this.selectedCollectionInvoiceDateFrom.value)
                }
                break
            }
            break
        case CONSTS.TRANSACTION_TYPE.REPORT:
            switch (filterParameter) {
            case 'observationType':
                if (this.selectedReportObservationType.value !== this.getFilterParameterDefaultValue(filterParameter)) {
                    return t('transaction.listfilter.observationType.' + this.selectedReportObservationType.value)
                }
                break
            case 'observationExpirationType':
                if (this.selectedReportObservationExpireType.value === 'EXPIRED_OBSERVATIONS') {
                    return t('transaction.listfilter.expireType.label.expired')
                } else if (this.selectedReportObservationExpireType.value === 'EXPIRING_OBSERVATIONS') {
                    return t('transaction.listfilter.expireType.label.expiring')
                }
                break
            case 'reportCreationDateFrom':
            case 'reportCreationDateTo':
                if (this.selectedReportCreationDateFrom.value === TransactionsListFilter.CONSTANTS.SELECTED_CREATION_DATE_FROM_OPTIONS.CUSTOM) {
                    if (this.selectedReportCreationDateFromStart.value && this.selectedReportCreationDateFromEnd.value) {
                        const startDate = dayjs(this._getStartDate(this.selectedReportCreationDateFromStart.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        const endDate = dayjs(this._getEndDate(this.selectedReportCreationDateFromEnd.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.creationDateFrom.CUSTOM.label') + ' ' + startDate + ' - ' + endDate
                    } else if (this.selectedReportCreationDateFromStart.value) {
                        const startDate = dayjs(this._getStartDate(this.selectedReportCreationDateFromStart.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.label.creationDateFrom.start.label') + ' ' + startDate
                    } else if (this.selectedReportCreationDateFromEnd.value) {
                        const endDate = dayjs(this._getEndDate(this.selectedReportCreationDateFromEnd.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.label.creationDateFrom.end.label') + ' ' + endDate
                    }
                } else if (this.selectedReportCreationDateFrom.value !== this.getFilterParameterDefaultValue(filterParameter)) {
                    return t('transaction.listfilter.creationDateFrom.label.' + this.selectedReportCreationDateFrom.value)
                }
                break
            case 'reportModificationDateFrom':
            case 'reportModificationDateTo':
                if (this.selectedReportModificationDateFrom.value === TransactionsListFilter.CONSTANTS.SELECTED_MODIFICATION_DATE_FROM_OPTIONS.CUSTOM) {
                    if (this.selectedReportModificationDateFromStart.value && this.selectedReportModificationDateFromEnd.value) {
                        const startDate = dayjs(this._getStartDate(this.selectedReportModificationDateFromStart.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        const endDate = dayjs(this._getEndDate(this.selectedReportModificationDateFromEnd.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.modificationDateFrom.CUSTOM.label') + ' ' + startDate + ' - ' + endDate
                    } else if (this.selectedReportModificationDateFromStart.value) {
                        const startDate = dayjs(this._getStartDate(this.selectedReportModificationDateFromStart.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.label.modificationDateFrom.start.label') + ' ' + startDate
                    } else if (this.selectedReportModificationDateFromEnd.value) {
                        const endDate = dayjs(this._getEndDate(this.selectedReportModificationDateFromEnd.value), 'YYYY-MM-DD').format(this.mld.longDateFormat('L'))
                        return t('transaction.listfilter.label.modificationDateFrom.end.label') + ' ' + endDate
                    }
                } else if (this.selectedReportModificationDateFrom.value !== this.getFilterParameterDefaultValue(filterParameter)) {
                    return t('transaction.listfilter.modificationDateFrom.label.' + this.selectedReportModificationDateFrom.value)
                }
                break
            }
            break
        }

        return ''
    }

    /**
     * Updates the activeCollectionFilters and activeReportFilters reactive arrays.
     */
    updateActiveFilterList() {
        const activeCollectionFilters = []
        const activeReportFilters = []
        const filterParameters = this.getSelectedFilterParameters()

        // Special case if date from AND to is set
        if (filterParameters['collectionModificationDateFrom'] && filterParameters['collectionModificationDateTo']) {
            delete filterParameters['collectionModificationDateTo']
        }
        if (filterParameters['collectionCreationDateFrom'] && filterParameters['collectionCreationDateTo']) {
            delete filterParameters['collectionCreationDateTo']
        }
        if (filterParameters['collectionInvoiceDateFrom'] && filterParameters['collectionInvoiceDateTo']) {
            delete filterParameters['collectionInvoiceDateTo']
        }
        if (filterParameters['reportModificationDateFrom'] && filterParameters['reportModificationDateTo']) {
            delete filterParameters['reportModificationDateTo']
        }
        if (filterParameters['reportCreationDateFrom'] && filterParameters['reportCreationDateTo']) {
            delete filterParameters['reportCreationDateTo']
        }

        for (const property in filterParameters) {
            if (TransactionsListFilter.CONSTANTS.FILTER_TYPES_MAP.COLLECTION.indexOf(property) !== -1) {
                activeCollectionFilters.push({
                    filter: property,
                    label: this.getFilterParameterResetLabel(property)
                })
            } else if (TransactionsListFilter.CONSTANTS.FILTER_TYPES_MAP.REPORT.indexOf(property) !== -1) {
                activeReportFilters.push({
                    filter: property,
                    label: this.getFilterParameterResetLabel(property)
                })
            }
        }

        this.activeCollectionFilters.value = activeCollectionFilters.filter(activeFilter => activeFilter.label?.length !== 0)
        this.activeReportFilters.value = activeReportFilters.filter(activeFilter => activeFilter.label?.length !== 0)
    }

    resetAction = (filterParameter: string | null = null) => {
        if (typeof filterParameter === 'string' && filterParameter.length) {
            if (filterParameter.endsWith('DateTo')) {
                filterParameter = filterParameter.replace('DateTo', 'DateFrom')
            }
            this.setSelectedFilterParameter(
                filterParameter,
                this.getFilterParameterDefaultValue(filterParameter),
                true
            )
        } else {
            this.reset()
            this.selectedCollectionCreationDateFromStart.value = null
            this.selectedCollectionCreationDateFromEnd.value = null
            this.selectedCollectionCreationDateFromStart.value = null
            this.selectedCollectionModificationDateFromStart.value = null
            this.selectedCollectionModificationDateFromEnd.value = null
            this.selectedCollectionInvoiceDateFromEnd.value = null
            this.selectedReportCreationDateFromStart.value = null
            this.selectedReportCreationDateFromStartDate.value = null
            this.selectedReportCreationDateFromEnd.value = null
            this.selectedReportCreationDateFromEndDate.value = null
            this.selectedReportModificationDateFromStart.value = null
            this.selectedReportModificationDateFromStartDate.value = null
            this.selectedReportModificationDateFromEndDate.value = null
        }
    }

    /**
     * Resets all filters to default values.
     *
     * Pauses update function as long as all filters are reset to default.
     * After that update function is unpaused and called once.
     *
     * @param {boolean} [updateOnChange]
     */
    reset(updateOnChange = true) {
        this.setSelectedFilterParameters({}, true, updateOnChange)
    }

    _getStartDate(dateString: any) {
        if (!dateString || typeof dateString !== 'string') return ''

        const matchYear = new RegExp('^\\d{4}$')
        const matchMonthYear = new RegExp('^(\\d{1,2})\\D(\\d{4})$')

        if (matchYear.test(dateString)) {
            return dateString + '-01-01'
        } else if (matchMonthYear.test(dateString)) {
            const matches = dateString.match(matchMonthYear)
            return dayjs(matches![2] + '-' + matches![1], 'YYYY-MM').startOf('month').format('YYYY-MM-DD')
        }

        return dayjs(dateString).format('YYYY-MM-DD')
    }

    _getEndDate(dateString: any) {
        if (!dateString || typeof dateString !== 'string') return ''

        const matchYear = new RegExp('^\\d{4}$')
        const matchMonthYear = new RegExp('^(\\d{1,2})\\D(\\d{4})$')

        if (matchYear.test(dateString)) {
            return dateString + '-12-31'
        } else if (matchMonthYear.test(dateString)) {
            const matches = dateString.match(matchMonthYear)
            return dayjs(matches![2] + '-' + matches![1], 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
        }

        return dayjs(dateString).format('YYYY-MM-DD')
    }
}
