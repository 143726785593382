<template>
    <div
        :id="id"
        class="tabs-list-container mb-large box-shadow-xy"
    >
        <div class="tabs-container mb">
            <nav
                v-if="tabProps"
                ref="scrollContainer"
                class="justify-content-left"
                @scroll="setFadeOutEffect"
            >
                <a
                    v-for="(tab, index) of tabProps"
                    :id="id + '.anchor-' + index"
                    :key="id + '.anchor-' + index"
                    class="tab-anchor"
                    :class="{ active: activeTab === tab?.id }"
                    :data-qa="tab?.dataQa"
                    href="#"
                    @click.prevent="changeSelected(tab?.id)"
                >
                    <span>
                        {{ tab?.title }}
                    </span>
                </a>
            </nav>
        </div>
        <slot />
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, provide, ref, watch, useSlots } from 'vue'
import { useI18n } from 'vue-i18n'
const { locale } = useI18n()

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    selectedTab: {
        type: String,
        default: '',
        required: false
    },
    show: {
        type: Boolean,
        default: true,
        required: false
    }
})

/* MAP TAB PROPS INITIALLY AND ON CHANGE OF LOCALE */
const slots = useSlots()
function mapTabProps() {
    return slots.default ? slots.default().map(tab => tab.props) : []
}
let tabProps = mapTabProps()
watch(
    () => locale.value,
    () => {
        tabProps = mapTabProps()
    }
)

/* NAVIGATION TABS */
let activeTab = ref(props.selectedTab)

function changeSelected(id: string) {
    activeTab.value = id
}

provide('activeTab', activeTab)

/* HORIZONTAL FADE-OUT EFFECT FOR SCROLLABLE ELEMENT */
let scrollContainer = ref<HTMLElement>()
function setFadeOutEffect() {
    const element: HTMLElement | undefined = scrollContainer.value
    if (!element) {
        return
    }

    const scrollableWidth = element.scrollWidth
    const visibleWidth = element.clientWidth
    const scrollPosition = element.scrollLeft

    const hasOverflow = scrollableWidth > visibleWidth
    const isScrolledToLeft = (scrollPosition === 0)
    const isScrolledToRight = (scrollableWidth - visibleWidth === scrollPosition)

    if (hasOverflow) {
        element.classList.add('masked-overflow')
        if (isScrolledToLeft) {
            element.classList.add('scrolled-left')
            element.classList.remove('scrolled-right')
        } else if (isScrolledToRight) {
            element.classList.add('scrolled-right')
            element.classList.remove('scrolled-left')
        } else {
            element.classList.remove('scrolled-left', 'scrolled-right')
        }
    } else {
        element.classList.remove('masked-overflow')
    }
}
onMounted(() => {
    window.addEventListener('resize', setFadeOutEffect)
    setFadeOutEffect()
})
onUnmounted(() => {
    window.removeEventListener('resize', setFadeOutEffect)
})
</script>

<style scoped lang="less">
@break-mobile: ~"screen and (max-width: 767px)";
.tabs-list-container {
    padding: 0 30px 30px;
    @media screen and (max-width: 590px) {
        margin-right: max(20px, auto);
        margin-left: max(20px, auto);
        width: unset;
    }
}

.tabs-container {
    border-bottom: 1px solid var(--color-c-linegrey);
    margin-bottom: 0;
}

nav {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;

    &.justify-content-left {
        justify-content: flex-start;
        gap: 50px;
        @media @break-mobile {
            gap: 15px;
        }
    }

    // &::-webkit-scrollbar {
    //    display: none;
    //}
   // -ms-overflow-style: none;
    //scrollbar-width: none;

    &.masked-overflow {
        --mask-width: 48px;
        --mask-image-content: linear-gradient(
            to right,
            transparent,
            black var(--mask-width),
            black calc(100% - var(--mask-width)),
            transparent
        );
        --mask-size-content: 100% 100%;

        mask-image: var(--mask-image-content);
        mask-size: var(--mask-size-content);
        mask-position: 0 0, 100% 0;
        mask-repeat: no-repeat, no-repeat;

        -webkit-mask-image: var(--mask-image-content);
        -webkit-mask-size: var(--mask-size-content);
        -webkit-mask-position: 0 0, 100% 0;
        -webkit-mask-repeat: no-repeat, no-repeat;
    }

    &.scrolled-left {
        --mask-image-content: linear-gradient(
            to right,
            black,
            black var(--mask-width),
            black calc(100% - var(--mask-width)),
            transparent
        );
    }

    &.scrolled-right {
        --mask-image-content: linear-gradient(
            to right,
            transparent,
            black var(--mask-width),
            black calc(100% - var(--mask-width)),
            black
        );
    }

    a {
        &.active {
            color: #004884;
            border-bottom: 5px solid #009ee2;
        }

        padding: 18px 7px 8px 7px;
        font-weight: bold;
    }
}

.content-container {
    .content-title {
        margin-bottom: 10px;
        @media @break-mobile {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            letter-spacing: 0.5px;
            margin-bottom: 7px;
        }
    }
    .content-text {
        white-space: pre-wrap;
    }
}

.tab-anchor {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 150% */
    letter-spacing: 0.5px;
    @media @break-mobile {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        letter-spacing: 0.5px;
    }
}
</style>
