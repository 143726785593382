import { getDynamicWidgetFileUrl } from '@/helper/image'
import { DynamicDataType, Widget, WidgetType } from '@/types/widget'
import { adsLinks, idsLinks, inkassoUploadLinks, inkassoLandingpageLinks, transactionsLinks, watchlistLinks } from './typo3Links'

const allInkassoWidgets = (locale: string) => {
    const inkassoWidgetsAll: Widget[] = [
        {
            id: 25,
            title: 'widgets.25.title',
            description: 'widgets.25.description',
            previewImage: getDynamicWidgetFileUrl('bewertung-qualitaet.jpg'),
            type: WidgetType.dynamicData,
            dynamicDataType: DynamicDataType.inkasso,
            emptyText: 'widgets.25.emptyText',
            emptyLink: {
                url: inkassoUploadLinks[locale],
                text: 'widgets.25.emptyLinkText'
            },
        },
        {
            id: 38,
            title: 'widgets.38.title',
            description: 'widgets.38.description',
            previewImage: getDynamicWidgetFileUrl('update-inkasso.png'),
            type: WidgetType.dynamicData,
            dynamicDataType: DynamicDataType.inkassoActivity,
            emptyText: 'widgets.38.emptyText',
            emptyLink: {
                url: inkassoLandingpageLinks[locale],
                text: 'widgets.38.emptyLinkText'
            },
            link: {
                url: idsLinks[locale]
            },
        },
        {
            id: 44,
            disabled: true,
            title: 'widgets.44.title',
            description: 'widgets.44.description',
            previewImage: getDynamicWidgetFileUrl(''),
            type: WidgetType.dynamicData,
            dynamicDataType: DynamicDataType.inkassoRealisation,
            emptyText: 'widgets.44.emptyText',

        },
        {
            id: 49,
            title: 'widgets.49.title',
            description: 'widgets.49.description',
            previewImage: getDynamicWidgetFileUrl('favoriten.png'),
            type: WidgetType.dynamicData,
            dynamicDataType: DynamicDataType.favouriteCollection,
            emptyText: 'widgets.49.emptyText',
            link: {
                url: idsLinks[locale]
            }
        },
    ]

    return inkassoWidgetsAll
}

const allAuskunftWidgets = (locale: string) => {
    const auskunftWidgetsAll: Widget[] = [
        {
            id: 27,
            title: 'widgets.27.title',
            description: 'widgets.27.description',
            previewImage: getDynamicWidgetFileUrl('watchlist-fernglas.jpg'),
            type: WidgetType.dynamicData,
            dynamicDataType: DynamicDataType.watchlist,
            emptyText: 'widgets.27.emptyText',
            emptyLink: {
                url: watchlistLinks[locale],
                text: 'widgets.27.emptyLinkText'
            },
            link: {
                url: adsLinks[locale]
            }
        },
        {
            id: 36,
            title: 'widgets.36.title',
            description: 'widgets.36.description',
            previewImage: getDynamicWidgetFileUrl('favoriten.png'),
            type: WidgetType.dynamicData,
            dynamicDataType: DynamicDataType.favouriteReport,
            emptyText: 'widgets.36.emptyText',
            link: {
                url: adsLinks[locale]
            }
        },
        {
            id: 39,
            title: 'widgets.39.title',
            description: 'widgets.39.description',
            previewImage: getDynamicWidgetFileUrl('bewertung-qualitaet.jpg'),
            type: WidgetType.dynamicData,
            dynamicDataType: DynamicDataType.statusMonitoring,
            emptyText: 'widgets.39.emptyText',
        },
        {
            id: 43,
            title: 'widgets.43.title',
            description: 'widgets.43.description',
            previewImage: getDynamicWidgetFileUrl('creditreform-community.jpg'),
            type: WidgetType.dynamicData,
            dynamicDataType: DynamicDataType.supplierinquiry,
            link: {
                url: '/portal/lieferantenrueckfrage/lieferantenrueckfrage-uebersicht'
            }
        },
        {
            id: 75,
            title: 'widgets.75.title',
            description: 'widgets.75.description',
            previewImage: getDynamicWidgetFileUrl('widget-ueberwachung.png'),
            type: WidgetType.dynamicData,
            dynamicDataType: DynamicDataType.expiringMonitoring,
            emptyText: 'widgets.75.emptyText',
            link: {
                url: adsLinks[locale],
            },
            listUrl: transactionsLinks[locale]
        }
    ]

    return auskunftWidgetsAll
}

const allRegionalWidgets = (locale: string) => {
    const regionalWidgetsAll: Widget[] = [
        {
            id: 40,
            title: 'widgets.40.title',
            description: 'widgets.40.description',
            previewImage: getDynamicWidgetFileUrl('termine-kalender.jpg'),
            type: WidgetType.dynamicData,
            dynamicDataType: DynamicDataType.events,
            emptyText: 'widgets.40.emptyText',
        }
    ]

    return regionalWidgetsAll
}

const allRecommendedWidgets = (locale: string) => {
    const recommendedWidgetsAll: Widget[] = [
        {
            id: 23,
            title: 'widgets.23.title',
            description: 'widgets.23.description',
            previewImage: getDynamicWidgetFileUrl('Fotolia_114184953_S.jpg'),
            type: WidgetType.marketing,
            marketingText: 'widgets.23.marketingText',
            marketingImage: getDynamicWidgetFileUrl('qualitaetsverbesserer.jpg'),
            link: {
                url: '/hilfe/bonitaetsauskunft/lieferantenrueckfrage/'
            },
        },
        {
            id: 34,
            title: 'widgets.34.title',
            description: 'widgets.34.description',
            previewImage: getDynamicWidgetFileUrl('inkasso-titelbild.png'),
            type: WidgetType.marketing,
            marketingText: 'widgets.34.marketingText',
            marketingImage: getDynamicWidgetFileUrl('dateiupload.png'),
            link: {
                url: inkassoUploadLinks[locale]
            }
        },
        {
            id: 78,
            title: 'widgets.78.title',
            description: 'widgets.78.description',
            previewImage: getDynamicWidgetFileUrl('verjaehrung-2023_widget-verwaltung_800x600.jpg'),
            type: WidgetType.marketing,
            marketingText: 'widgets.78.marketingText',
            marketingImage: getDynamicWidgetFileUrl('verjaehrung-2023_widget-teaser_600x400.jpg'),
            link: {
                url: inkassoLandingpageLinks[locale]
            }
        },
        {
            id: 80,
            title: 'widgets.80.title',
            description: 'widgets.80.description',
            previewImage: getDynamicWidgetFileUrl('CrefoFactoring_Widget_600x400.png'),
            type: WidgetType.marketing,
            marketingText: 'widgets.80.marketingText',
            marketingImage: getDynamicWidgetFileUrl('CrefoFactoring_Widget_600x400.png'),
            link: {
                target: '_blank',
                url: 'https://www.crefo-factoring.de'
            }
        },
        {
            id: 96,
            title: 'widgets.96.title',
            description: 'widgets.96.description',
            previewImage: getDynamicWidgetFileUrl('Glaeubiger-Kompakt-600x400.png'),
            type: WidgetType.marketing,
            marketingText: 'widgets.96.marketingText',
            marketingImage: getDynamicWidgetFileUrl('Glaeubiger-Kompakt-600x400.png'),
            link: {
                url: 'https://www.creditreform.de/aktuelles-wissen/veranstaltungen',
                target: '_blank'
            }
        },
        {
            id: 100,
            title: 'widgets.100.title',
            description: 'widgets.100.description',
            previewImage: getDynamicWidgetFileUrl('mc-releasenotes-800x600.png'),
            type: WidgetType.marketing,
            marketingText: 'widgets.100.marketingText',
            marketingImage: getDynamicWidgetFileUrl('mc-releasenotes-600x400.png'),
            link: {
                url: '/hilfe/release-notes/'
            }
        },
        {
            id: 129,
            title: 'widgets.129.title',
            description: 'widgets.129.description',
            previewImage: getDynamicWidgetFileUrl('drd-800x600.png'),
            type: WidgetType.marketing,
            marketingText: 'widgets.129.marketingText',
            marketingImage: getDynamicWidgetFileUrl('drd-600x400.png'),
            link: {
                url: 'https://www.creditreform.de/loesungen/bonitaet-risikobewertung/finanzkommunikation/debitorenregister-deutschland-drd',
                target: '_blank'
            }
        },
        {
            id: 141,
            title: 'widgets.141.title',
            description: 'widgets.141.description',
            previewImage: getDynamicWidgetFileUrl('standortreport-800x600.png'),
            type: WidgetType.marketing,
            marketingText: 'widgets.141.marketingText',
            marketingImage: getDynamicWidgetFileUrl('standortreport-600x400.png'),
            link: {
                url: 'https://standortreport.de',
                target: '_blank'
            }
        },
        {
            id: 153,
            title: 'widgets.153.title',
            description: 'widgets.153.description',
            previewImage: getDynamicWidgetFileUrl('CONSUMER-Adresse_800x600pxr.png'),
            type: WidgetType.marketing,
            marketingText: 'widgets.153.marketingText',
            marketingImage: getDynamicWidgetFileUrl('CONSUMER-Adresse_600x400pxr.png'),
            link: {
                url: getDynamicWidgetFileUrl('Flyer_Creditreform_CONSUMER_Adresse.pdf'),
                target: '_blank'
            }
        },
        {
            id: 199,
            title: 'widgets.199.title',
            description: 'widgets.199.description',
            previewImage: getDynamicWidgetFileUrl('Widget_mC_600x800.jpg'),
            type: WidgetType.marketing,
            marketingText: 'widgets.199.marketingText',
            marketingImage: getDynamicWidgetFileUrl('Widget_mC_400x600.jpg'),
            link: {
                url: 'https://www.creditreform.at/wien/loesungen/insolvenzvertretung-glaeubigerschutz/aktuelle-insolvenzen',
                target: '_blank'
            }
        },
        {
            id: 231,
            title: 'widgets.231.title',
            description: 'widgets.231.description',
            previewImage: getDynamicWidgetFileUrl('mycrefoapp_800x600.jpg'),
            type: WidgetType.marketing,
            marketingText: 'widgets.231.marketingText',
            marketingImage: getDynamicWidgetFileUrl('mycrefoapp_600x400.jpg'),
            link: {
                url: 'https://www.creditreform.de/mycrefo-app',
                target: '_blank'
            }
        },
        {
            id: 233,
            title: 'widgets.233.title',
            description: 'widgets.233.description',
            previewImage: getDynamicWidgetFileUrl('mein_WKV_Einzelschutz_800x600.jpg'),
            type: WidgetType.marketing,
            marketingText: 'widgets.233.marketingText',
            marketingImage: getDynamicWidgetFileUrl('mein_WKV_Einzelschutz_600x400.jpg'),
            link: {
                url: watchlistLinks[locale],
            }
        }
    ]

    return recommendedWidgetsAll
}

const allMyCreditreformWidgets = (locale: string) => {
    const myCreditreformWidgetsAll: Widget[] = [
        {
            id: 20,
            title: 'widgets.20.title',
            description: 'widgets.20.description',
            previewImage: getDynamicWidgetFileUrl('handwerker-rechner.jpg'),
            type: WidgetType.marketing,
            marketingText: 'widgets.20.marketingText',
            marketingImage: getDynamicWidgetFileUrl('handwerker-rechner.jpg'),
            link: {
                url: '/portal/mc/mein-profil/'
            },
        },
        {
            id: 24,
            title: 'widgets.24.title',
            description: 'widgets.24.description',
            previewImage: getDynamicWidgetFileUrl('Fotolia_89356209_S.jpg'),
            type: WidgetType.marketing,
            marketingText: 'widgets.24.marketingText',
            marketingImage: getDynamicWidgetFileUrl('Frau_Buero_Fenster.png'),
            link: {
                url: '/hilfe/effektives-risikomanagement/'
            },
        },
        {
            id: 29,
            title: 'widgets.29.title',
            description: 'widgets.29.description',
            previewImage: getDynamicWidgetFileUrl('Leistungskreis_bedirect_800x600.png'),
            type: WidgetType.marketing,
            marketingText: 'widgets.29.marketingText',
            marketingImage: getDynamicWidgetFileUrl('CrefoAddress.png'),
            link: {
                url: '/portal/mc/ueber-creditreform/marketing-adressen'
            },
        },
        {
            id: 31,
            title: 'widgets.31.title',
            description: 'widgets.31.description',
            previewImage: getDynamicWidgetFileUrl('creditreform-kanal.png'),
            type: WidgetType.marketing,
            marketingText: 'widgets.31.marketingText',
            marketingVideo: 'https://www.youtube.com/embed/nmt4mZ7M4GM?si=5puhf2jVpgEkxU5O'
        },
        {
            id: 115,
            title: 'widgets.115.title',
            description: 'widgets.115.description',
            previewImage: getDynamicWidgetFileUrl('Magazin_Widget-800x600.png'),
            type: WidgetType.marketingAlt,
            teaser: 'widgets.115.teaser',
            marketingImage: getDynamicWidgetFileUrl('Magazin_Widget-Vorschau-600x400.png'),
            link: {
                url: '/portal/creditreform-magazin',
                target: '_blank',
                text: 'widgets.115.linkText'
            },
        },
        {
            id: 125,
            title: 'widgets.125.title',
            description: 'widgets.125.description',
            previewImage: getDynamicWidgetFileUrl('marketing2_default.png'),
            type: WidgetType.marketingAlt,
            teaser: 'widgets.125.teaser',
            marketingText: 'widgets.125.marketingText',
            marketingImage: getDynamicWidgetFileUrl('marketing2_default.png'),
            link: {
                url: '/portal/meine-bonitaet',
                text: 'widgets.125.linkText'
            },
        },
        {
            id: 131,
            title: 'widgets.131.title',
            description: 'widgets.131.description',
            previewImage: getDynamicWidgetFileUrl('CrefoTrust_800x600.jpg'),
            type: WidgetType.marketing,
            marketingText: 'widgets.131.marketingText',
            marketingImage: getDynamicWidgetFileUrl('CrefoTrust_600x400.jpg'),
            link: {
                url: '/portal/digitale-identitaet/vb-verifikation',
            },
        },
        {
            id: 157,
            title: 'widgets.157.title',
            description: 'widgets.157.description',
            previewImage: getDynamicWidgetFileUrl('mahnwesen-800x600.jpg'),
            type: WidgetType.marketing,
            marketingText: 'widgets.157.marketingText',
            marketingImage: getDynamicWidgetFileUrl('mahnwesen-600x400.jpg'),
            link: {
                url: 'https://www.creditreform-mahnwesen.de/de/home',
                target: '_blank'
            },
        },
    ]

    return myCreditreformWidgetsAll
}

export function getInkassoWidgets(locale: string) {
    return allInkassoWidgets(locale).filter(w => !w.disabled)
}

export function getAuskunftWidgets(locale: string) {
    return allAuskunftWidgets(locale).filter(w => !w.disabled)
}

export function getRegionalWidgets(locale: string) {
    return allRegionalWidgets(locale).filter(w => !w.disabled)
}

export function getRecommendedWidgets(locale: string) {
    return allRecommendedWidgets(locale).filter(w => !w.disabled)
}

export function getMyCreditreformWidgets(locale: string) {
    return allMyCreditreformWidgets(locale).filter(w => !w.disabled)
}

export function getAllWidgets(locale: string) {
    const allWidgets: Widget[] = [
        ...getInkassoWidgets(locale),
        ...getAuskunftWidgets(locale),
        ...getRegionalWidgets(locale),
        ...getRecommendedWidgets(locale),
        ...getMyCreditreformWidgets(locale)
    ]

    return allWidgets
}
