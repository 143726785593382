<!-- eslint-disable vue/no-v-html -->
<template>
    <Modal
        id="reportTrackingSettings"
        :title="$t('transaction.reportTransactionSettings.dialog.headline')"
        cancel-data-qa="monitoring-terminate-cancel"
        confirm-data-qa="monitoring-terminate-ok"
        :cancel-text="$t('transaction.reportTransactionSettings.dialog.cancel')"
        :confirm-text="$t('transaction.reportTransactionSettings.dialog.save')"
        :confirm-disabled="!isValid || isLoading"
        @on-close="onClose"
        @on-confirm="onConfirm"
    >
        <div
            id="display_7"
            class="reportTrackingSettings--display_7 text-align-left" 
        >
            <div class="row">
                <div class="col-12">
                    <h4
                        data-qa="display_7--cancel-supplement-headline-text"
                        style="margin-bottom: 0;"
                    >
                        {{ $t('transaction.reportTransactionSettings.supplement.inactive') }}

                        <Tooltip
                            id="display_7--supplement-inactive--info-text"
                            position="right"
                            data-qa="display_7--supplement-inactive--info-text"
                            class="ml"
                            wide
                        >
                            <font-awesome-icon
                                icon="fa-regular fa-circle-info" 
                                size="2x"
                                color="var(--color-c-p1)"
                            />
                            <template #content>
                                <span v-html="$t('transaction.reportTransactionSettings.display_7.infotext.1')" />
                            </template>
                        </Tooltip>
                    </h4>
                </div>
            </div>
            <div class="row small-content-spacer">
                <div class="col-12 borderTop small-content-spacer">
                    <h4>
                        <span
                            data-qa="display_7--cancel-monitoring-headline-text"
                        >{{ monitoringHeadline }}</span>

                        <Tooltip
                            id="display_7--cancel-supplement---info-text"
                            position="right"
                            data-qa="display_7--cancel-supplement---info-text"
                            class="ml"
                            wide
                        >
                            <font-awesome-icon
                                icon="fa-regular fa-circle-info" 
                                size="2x"
                                color="var(--color-c-p1)"
                            />
                            <template #content>
                                <span v-html="$t('transaction.reportTransactionSettings.display_7.infotext.2')" />
                            </template>
                        </Tooltip>
                    </h4>
                    <div class="row">
                        <div class="col-md-10 col-12 offset-md-1">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('transaction.reportTransactionSettings.monitoring.cancel.options.headline') }}</h5>
                                </div>
                            </div>

                            <Checkbox
                                id="cancelMonitoringCheckbox"
                                v-model="cancelMonitoringCheckbox"
                                name="cancelMonitoringCheckbox"
                                :label="$t('transaction.reportTransactionSettings.monitoring.cancel.text')"
                                data-qa="display_7--remove-un-monitoring--checkbox"
                                :disabled="cancelMonitoringOptionsDisabled"
                            />
                        </div>
                    </div>
                    <div class="row vspace--3">
                        <div class="col-md-10 col-12 offset-md-1">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('transaction.reportTransactionSettings.monitoring.change.options.headline') }}</h5>
                                </div>
                                <div class="col-12 col-md-3">
                                    <Checkbox
                                        id="orderMonitoringUnCheckbox"
                                        v-model="orderMonitoringUnCheckbox"
                                        name="orderMonitoringUnCheckbox"
                                        :label="$t('transaction.reportTransactionSettings.add.monitoring.unlimited')"
                                        data-qa="display_7--order-un-monitoring--checkbox"
                                        :disabled="changeMonitoringOptionsDisabled"
                                        @change="toggleOrderMonitoringUnCheckbox"
                                    />
                                </div>
                                <div class="col-12 col-md-3">
                                    <Checkbox
                                        id="orderMonitoringLimitedCheckbox"
                                        v-model="orderMonitoringLimitedCheckbox"
                                        name="orderMonitoringLimitedCheckbox"
                                        :label="$t('transaction.reportTransactionSettings.add.monitoring.limited.text')"
                                        data-qa="display_7--order-mon-limited-checkbox"
                                        :disabled="changeMonitoringOptionsDisabled"
                                        @change="toggleOrderMonitoringLimitedCheckbox"
                                    />
                                </div>

                                <div class="col-12 col-md-3">
                                    <Select
                                        id="befristungEndeMonat"
                                        v-model="befristungEndeMonat"
                                        :options="monitoringLimitedMonthOptions"
                                        data-qa="display_7-befristungEndeMonat"
                                        label=""
                                        :disabled="changeMonitoringOptionsDisabled"
                                        :searchable="false"
                                    />
                                </div>

                                <div class="col-12 col-md-3">
                                    <Select
                                        id="befristungEndeJahr"
                                        v-model="befristungEndeJahr"
                                        :options="monitoringLimitedYearOptions"
                                        data-qa="display_7-befristungEndeJahr"
                                        label=""
                                        :disabled="changeMonitoringOptionsDisabled"
                                        :searchable="false"
                                    />
                                </div>
                                
                                <div class="col-12 pt-4">
                                    <Checkbox
                                        id="orderMonitoringPlusCheckbox"
                                        v-model="orderMonitoringPlusCheckbox"
                                        name="orderMonitoringPlusCheckbox"
                                        :label="$t('transaction.reportTransactionSettings.add.monitoringPlus')"
                                        data-qa="display_7--plus-option--checkbox"
                                        :disabled="changeMonitoringOptionsDisabled"
                                        @change="toggleOrderMonitoringPlusCheckbox"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
    <Modal
        v-if="modal"
        :id="modal.id"
        :title="modal.title"
        :cancel-text="modal.cancelText"
        :confirm-text="modal.confirmText"
        :error-context="modal.errorContext"
        :icon="modal.icon"
        @on-close="modal.onClose"
        @on-confirm="modal.onConfirm"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="modal.content" />
    </Modal>
</template>

<script lang="ts" setup>
import Checkbox from '@/components/Input/Checkbox.vue'
import Select from '@/components/Input/Select.vue'
import Modal from '@/components/Modal.vue'
import Tooltip from '@/components/Tooltip.vue'
import CONSTANTS from '@/data/constants'
import { formatDate } from '@/filters'
import helpers from '@/helper/misc'
import { CbraReportTransaction, CbraTransaction, CbraUpdateReportTrackingPayload } from '@/types/cbra/transactions'
import dayjs from 'dayjs'
import { PropType, computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useReportTracking } from './UseReportTracking'

const props = defineProps({
    reportTransaction: {
        type: Object as PropType<CbraReportTransaction>,
        required: true
    },
    transactionData: {
        type: Object as PropType<CbraTransaction>,
        required: true
    },
})

const emit = defineEmits(['onClose'])

function onClose() {
    reset()
    emit('onClose')
}

const { t } = useI18n()
const { modal, isLoading, updateReportTracking } = useReportTracking()

const productOrderId = ref(props.reportTransaction.productOrderId)
const trackingStatus = ref(props.reportTransaction.reportTransactionTracking?.trackingStatus)

const monitoringStateEndTime = formatDate(props.reportTransaction.monitoringEndTime, 'L')
const monitoringHeadline = getMonitoringText(monitoringStateEndTime)

const cancelMonitoringOptionsDisabled = computed(() => {
    return orderMonitoringUnCheckbox.value ||
                orderMonitoringPlusCheckbox.value ||
                orderMonitoringLimitedCheckbox.value
})

const changeMonitoringOptionsDisabled = computed(() => {
    return cancelMonitoringCheckbox.value
})

const isValid = computed(() => {
    return cancelMonitoringCheckbox.value ||
                orderMonitoringUnCheckbox.value ||
                orderMonitoringLimitedCheckbox.value ||
                orderMonitoringPlusCheckbox.value
})

const monitoringLimitedMonthOptions = CONSTANTS.LIMITED_MONITORING_MONTH_OPTIONS
const monitoringLimitedYearOptions = helpers.getMonitoringLimitedYearOptions()

const defaultMonth = dayjs().locale('en').format('MMMM').toUpperCase()
const defaultYear = dayjs().add(3, 'year').format('YYYY')

const cancelMonitoringCheckbox = ref(false)
const orderMonitoringUnCheckbox = ref(false)
const orderMonitoringLimitedCheckbox = ref(false)
const befristungEndeMonat = ref(defaultMonth)
const befristungEndeJahr = ref(defaultYear)
const orderMonitoringPlusCheckbox = ref(false)

function onConfirm() {
    sendRequest()
}

function sendRequest() {
    if (!isValid.value || !productOrderId.value) {
        return
    }

    let params: CbraUpdateReportTrackingPayload = { ueberwachung: null }
    if (cancelMonitoringCheckbox.value) {
        params.ueberwachung = 'MONITORING_KUENDIGEN'
    } else if (orderMonitoringUnCheckbox.value && !orderMonitoringPlusCheckbox.value) {
        params.ueberwachung = 'MONITORING_UNBEFRISTEN'
    } else if (orderMonitoringUnCheckbox.value && orderMonitoringPlusCheckbox.value) {
        params.ueberwachung = 'MONITORING_PLUS_UNBEFRISTEN'
    } else if (orderMonitoringLimitedCheckbox.value && !orderMonitoringPlusCheckbox.value) {
        params.ueberwachung = 'MONITORING_BEFRISTEN'
        params.befristungEndeMonat = befristungEndeMonat.value
        params.befristungEndeJahr = parseInt(befristungEndeJahr.value)
    } else if (orderMonitoringLimitedCheckbox.value && orderMonitoringPlusCheckbox.value) {
        params.ueberwachung = 'MONITORING_PLUS_BEFRISTEN'
        params.befristungEndeMonat = befristungEndeMonat.value
        params.befristungEndeJahr = parseInt(befristungEndeJahr.value)
    }
    return updateReportTracking(productOrderId.value, params, props.transactionData)
}

function reset() {
    cancelMonitoringCheckbox.value = false
    orderMonitoringUnCheckbox.value = false
    orderMonitoringLimitedCheckbox.value = false
    orderMonitoringPlusCheckbox.value = false
    befristungEndeMonat.value = defaultMonth
    befristungEndeJahr.value = defaultYear
}

function getMonitoringText(date: string) {
    if (trackingStatus.value === 'MONITORINGPLUS_LAUFEND') {
        return t('transaction.reportTransactionSettings.monitoringPlus.active.until', { date })
    } else {
        return t('transaction.reportTransactionSettings.monitoring.active.until', { date })
    }
}

async function toggleOrderMonitoringUnCheckbox() {
    if (orderMonitoringPlusCheckbox.value) {
        await nextTick()
        orderMonitoringUnCheckbox.value = true
    }
    orderMonitoringLimitedCheckbox.value = false
}

async function toggleOrderMonitoringLimitedCheckbox() {
    if (orderMonitoringPlusCheckbox.value) {
        await nextTick()
        orderMonitoringLimitedCheckbox.value = true
    }
    orderMonitoringUnCheckbox.value = false
}

function toggleOrderMonitoringPlusCheckbox() {
    if (!orderMonitoringLimitedCheckbox.value) {
        orderMonitoringUnCheckbox.value = true
    }
}

</script>