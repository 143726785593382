<script setup lang="ts">
import { ref, computed } from 'vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'
import AnchorLinks, { AnchorLink } from '@/components/AnchorLinks.vue'
import { useI18n } from 'vue-i18n'
import TableComponent from '@/components/TableComponent.vue'

const id = 'portal.mc.help.debt-collection'

const { t, locale } = useI18n()

const isEnglish = computed(() => locale.value === 'en-US')
const isGerman = computed(() => locale.value === 'de-DE')
const isAustrian = computed(() => locale.value === 'de-AT')

const anchorLinks: AnchorLink[] = [
    {
        id: 'anchor-link-1',
        dataQa: '',
        i18nKey: 'DebtCollection.tabs.1.anchor'
    },
    {
        id: 'anchor-link-2',
        dataQa: '',
        i18nKey: 'DebtCollection.tabs.2.anchor'
    },
    {
        id: 'anchor-link-3',
        dataQa: '',
        i18nKey: 'DebtCollection.tabs.3.anchor'
    }
]
const selectedAnchorLinkId = ref<string>('anchor-link-1')
function changeSelectedAnchorLink(anchorLink: AnchorLink) {
    selectedAnchorLinkId.value = anchorLink.id
}

const tableHeaders = computed(() => [
    t('DebtCollection.sections.5.table.headers.1'),
    t('DebtCollection.sections.5.table.headers.2')
])

const tableRows = computed(() => [
    [ t('DebtCollection.sections.5.table.row1.1'), t('DebtCollection.sections.5.table.row1.2') ],
    [ t('DebtCollection.sections.5.table.row2.1'), t('DebtCollection.sections.5.table.row2.2') ],
    [ t('DebtCollection.sections.5.table.row3.1'), t('DebtCollection.sections.5.table.row3.2') ],
    [ t('DebtCollection.sections.5.table.row4.1'), t('DebtCollection.sections.5.table.row4.2') ],
    [ t('DebtCollection.sections.5.table.row5.1'), t('DebtCollection.sections.5.table.row5.2') ]
])

</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('DebtCollection.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c1247"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-1247"
                    >
                        <div
                            id="toc1247"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-5842"
                                        class="toc__"
                                        href="/hilfe/inkasso/#c5842"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-5840"
                                                href="/hilfe/inkasso/#c5840"
                                                @click.prevent="scrollTo('#c5840')"
                                            >
                                                {{ $t('DebtCollection.sections.1.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-1274"
                                        class="toc__"
                                        href="/hilfe/inkasso/#c1274"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-1275"
                                                href="/hilfe/inkasso/#c1275"
                                                @click.prevent="scrollTo('#c1275')"
                                            >
                                                {{ $t('DebtCollection.sections.2.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-1463"
                                        class="toc__"
                                        href="/hilfe/inkasso/#c1463"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-1464"
                                                href="/hilfe/inkasso/#c1464"
                                                @click.prevent="scrollTo('#c1464')"
                                            >
                                                {{ $t('DebtCollection.sections.3.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li
                                    v-if="!isEnglish"
                                    class="toc__listItem"
                                >
                                    <a
                                        data-qa="toc-listItem-1257"
                                        class="toc__"
                                        href="/hilfe/inkasso/#c1257"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-1346"
                                                href="/hilfe/inkasso/#c1346"
                                                @click.prevent="scrollTo('#c1346')"
                                            >
                                                {{ $t('DebtCollection.sections.4.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-1281"
                                        class="toc__"
                                        href="/hilfe/inkasso/#c1281"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-1282"
                                                href="/hilfe/inkasso/#c1282"
                                                @click.prevent="scrollTo('#c1282')"
                                            >
                                                {{ $t('DebtCollection.sections.5.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-1259"
                                        class="toc__"
                                        href="/hilfe/inkasso/#c1259"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-1284"
                                                href="/hilfe/inkasso/#c1284"
                                                @click.prevent="scrollTo('#c1284')"
                                            >
                                                {{ $t('DebtCollection.sections.6.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-1247"
                                        class="toc__"
                                        href="/hilfe/inkasso/#c1247"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        v-if="isGerman || isAustrian"
                        id="c5842"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-5842"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />
                                        <div
                                            id="c5840"
                                            class="ce-box   box"
                                            data-qa="ce-5840"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('DebtCollection.sections.1.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('DebtCollection.sections.1.text') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="c1274"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-1274"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />
                                        <div
                                            id="c1275"
                                            class="ce-box   box"
                                            data-qa="ce-1275"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('DebtCollection.sections.2.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('DebtCollection.sections.2.ps.1') }}</p>
                                                    <ul class="list-unordered">
                                                        <li>{{ $t('DebtCollection.sections.2.ul.lis.1') }}</li>
                                                        <li>{{ $t('DebtCollection.sections.2.ul.lis.2') }}</li>
                                                        <li>{{ $t('DebtCollection.sections.2.ul.lis.3') }}</li>
                                                        <li>{{ $t('DebtCollection.sections.2.ul.lis.4') }}</li>
                                                        <li>{{ $t('DebtCollection.sections.2.ul.lis.5') }}</li>
                                                        <li>{{ $t('DebtCollection.sections.2.ul.lis.6') }}</li>
                                                        <li>{{ $t('DebtCollection.sections.2.ul.lis.7') }}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="c1463"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-1463"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />
                                        <div
                                            id="c1464"
                                            class="ce-box   box"
                                            data-qa="ce-1464"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--below">
                                                <div class="ce-header">
                                                    <h3>{{ $t('DebtCollection.sections.3.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>
                                                        {{ $t('DebtCollection.sections.3.ps.1') }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!isEnglish"
                        id="c1257"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-1257"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />
                                        <div
                                            id="c1346"
                                            class="ce-box   box"
                                            data-qa="ce-1346"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('DebtCollection.sections.4.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('DebtCollection.sections.4.text') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="c1281"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-1281"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />
                                        <div
                                            id="c1282"
                                            class="ce-box   box"
                                            data-qa="ce-1282"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('DebtCollection.sections.5.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('DebtCollection.sections.5.text') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Switch this with Table component -->
                                        
                                        <div
                                            id="c1283"
                                            class="ce-box  box"
                                            data-qa="ce-1283"
                                        >
                                            <TableComponent
                                                :table-headers="tableHeaders"
                                                :table-rows="tableRows"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="c1259"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-1259"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />
                                        <div
                                            id="c1261"
                                            class=" clearfix grid-box grid-box--tabContainer box"
                                            data-qa="grid-box-1261"
                                        >
                                            <div
                                                class="ce-tabs"
                                                data-ready="true"
                                                data-willow-name="DropDownDisplayState"
                                            >
                                                <AnchorLinks
                                                    id="help-debt-collection-anchor-links"
                                                    :links="anchorLinks"
                                                    :selected-link="selectedAnchorLinkId"
                                                    justify-content=""
                                                    @change-selected="changeSelectedAnchorLink"
                                                />
                                                <div class="ce-tabs__content tab-content box--pearl">
                                                    <div
                                                        v-if="selectedAnchorLinkId === anchorLinks[0].id"
                                                        id="tab1262"
                                                        class="ce-tabs__content__pane"
                                                        style=""
                                                    >
                                                        <div
                                                            id="c1285"
                                                            class="ce-box   box"
                                                            data-qa="ce-1285"
                                                        >
                                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                                <div class="ce-header">
                                                                    <h3>{{ $t('DebtCollection.tabs.1.title') }}</h3>
                                                                </div>
                                                                <div class="ce-textpic__bodytext">
                                                                    <p>{{ $t('DebtCollection.tabs.1.text') }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else-if="selectedAnchorLinkId === anchorLinks[1].id"
                                                        id="tab1263"
                                                        class="ce-tabs__content__pane"
                                                    >
                                                        <div
                                                            id="c1294"
                                                            class="ce-box   box"
                                                            data-qa="ce-1294"
                                                        >
                                                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                                                <div class="ce-header">
                                                                    <h3>{{ $t('DebtCollection.tabs.2.title') }}</h3>
                                                                </div>
                                                                <div
                                                                    class="ce-textpic__gallery"
                                                                    data-ce-columns="1"
                                                                    data-ce-images="1"
                                                                >
                                                                    <div class="ce-textpic__row">
                                                                        <div class="ce-textpic__column">
                                                                            <div class="ce-textpic__media icon">
                                                                                <img
                                                                                    src="@/assets/img/icons/envelope.svg"
                                                                                    width="29"
                                                                                    height="21"
                                                                                    alt=""
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="ce-textpic__bodytext">
                                                                    <p>{{ $t('DebtCollection.tabs.2.text') }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else-if="selectedAnchorLinkId === anchorLinks[2].id"
                                                        id="tab1264"
                                                        class="ce-tabs__content__pane"
                                                    >
                                                        <div
                                                            id="c1295"
                                                            class="ce-box   box"
                                                            data-qa="ce-1295"
                                                        >
                                                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                                                <div class="ce-header">
                                                                    <h3>{{ $t('DebtCollection.tabs.3.title') }}</h3>
                                                                </div>
                                                                <div
                                                                    class="ce-textpic__gallery"
                                                                    data-ce-columns="1"
                                                                    data-ce-images="1"
                                                                >
                                                                    <div class="ce-textpic__row">
                                                                        <div class="ce-textpic__column">
                                                                            <div class="ce-textpic__media icon">
                                                                                <img
                                                                                    src="@/assets/img/icons/telephone.svg"
                                                                                    width="27"
                                                                                    height="27"
                                                                                    alt=""
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="ce-textpic__bodytext">
                                                                    <p>{{ $t('DebtCollection.tabs.3.text') }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c1284"
                                            class="ce-box   box"
                                            data-qa="ce-1284"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('DebtCollection.sections.6.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('DebtCollection.sections.6.text') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding-bottom: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}
.tab-content {
    padding: 1.5rem;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.box--pearl {
    background-color: #edece5;
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--left {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--right {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--left.ce-textpic--left {
    .ce-textpic__gallery {
        float: left;
    }
    .ce-textpic__media {
        float: left;
        padding-right: 20px;
        padding-left: 0;
    }
}
.ce-textpic--right.ce-textpic--left {
    .ce-textpic__gallery {
        float: left;
    }
    .ce-textpic__media {
        float: left;
        padding-right: 20px;
        padding-left: 0;
    }
}
.ce-textpic--left.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
.ce-textpic--right.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
.ce-textpic--left.ce-textpic--below {
    .ce-textpic__gallery {
        float: none;
        max-width: 100%;
    }
    .ce-textpic__media {
        float: none;
        display: block;
        padding: 20px 0;
        max-width: 100%;
    }
}
.ce-textpic--right.ce-textpic--below {
    .ce-textpic__gallery {
        float: none;
        max-width: 100%;
    }
    .ce-textpic__media {
        float: none;
        display: block;
        padding: 20px 0;
        max-width: 100%;
    }
}
@media (max-width: 1199px) {
    .ce-textpic--left {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
    .ce-textpic--right {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
}

.ce-textpic__media.icon {
    padding-top: 8px;
}
</style>
