import { showError } from '@/helper/services/error'
import { CONSTS, TransactionService } from '@/helper/services/transaction'
import { CbraTransaction, CbraUpdateReportTrackingPayload } from '@/types/cbra/transactions'
import { ModalProps } from '@/types/components/modal'
import { isAxiosError } from 'axios'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useReportTracking() {
    const modal = ref<ModalProps>()
    const isLoading = ref(false)

    const { t } = useI18n()

    async function sendRawRequest(productOrderId: string, params: CbraUpdateReportTrackingPayload, transactionData: CbraTransaction) {
        isLoading.value = true

        try {
            await TransactionService.updateReportTracking(productOrderId, params, transactionData)
            window.location.reload()
        } catch (error) {
            if (isAxiosError( error) && error.response?.status === 403) {
                modal.value = showError({ topic: CONSTS.TOPIC, func: 'updateReportTracking_403', xhr: error })
            } else if (isAxiosError( error) && error.response?.status === 422) {
                modal.value = showError({ xhr: error })
            } else if (isAxiosError(error)) {
                modal.value = showError({ topic: CONSTS.TOPIC, func: 'updateReportTracking', xhr: error })
            }
            if (modal.value) {
                modal.value.onClose = () => modal.value = undefined
                modal.value.onConfirm = () => modal.value = undefined
            }
        }
    
        isLoading.value = false
    }

    function updateReportTracking(productOrderId: string, data: CbraUpdateReportTrackingPayload, transactionData: CbraTransaction) {
        if (data && data.ueberwachung === 'NACHTRAG_STOP_SOFORT') {
            modal.value = {
                id: 'cancelSupplementWarning',
                title: t('transaction.reportTransactionSettings.cancel.supplement.immediately'),
                content: t('transaction.reportTransactionSettings.cancel.supplements.warning.desc'),
                confirmText: t('transaction.reportTransactionSettings.cancel.supplements.warning.btn.yes'),
                icon: 'warning',
                cancelText: t('transaction.reportTransactionSettings.cancel.supplements.warning.btn.no'),
                onConfirm: async() => await sendRawRequest(productOrderId, data, transactionData),
                onClose: () => modal.value = undefined
            }
        } else {
            sendRawRequest(productOrderId, data, transactionData)
        }
    }

    return { modal, isLoading, updateReportTracking }
}