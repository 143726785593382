<template>
    <div class="row">
        <div class="col-12">
            <div class="filter_box bg-white">
                <div class="row">
                    <div class="col-12">
                        <h4 data-qa="listfilter_title">
                            {{ $t('transaction.listfilter.headline.collection') }}
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <Select
                            id="creationDate"
                            v-model="filter.PROCEDURE_STAGE"
                            :label="$t('transaction.listfilter.label.collectionProcedureStage')"
                            :options="procedureStageOptions"
                            :searchable="false"
                            data-qa="listfilter_selectedCollectionProcedureStage"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <div 
                            class="row" 
                            style="height:100%"
                        >
                            <div class="col-md-6 col-12 align-flex-end">
                                <Checkbox
                                    id="test"
                                    v-model="filter.COLLECTION_FILE_CLOSED"
                                    name="input-name"
                                    :label="$t('transaction.listfilter.fileClosed.label.yes')"
                                    data-qa="listfilter_selectedCollectionFileClosedYes"
                                />
                            </div>
                            <div class="col-md-6 col-12 align-flex-end">
                                <Checkbox
                                    id="test2"
                                    v-model="filter.COLLECTION_FILE_OPEN"
                                    name="input-name2"
                                    :label="$t('transaction.listfilter.fileClosed.label.no')"
                                    data-qa="listfilter_selectedCollectionFileClosedNo"
                                />
                            </div>
                        </div>
                    </div> 
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <Select
                            id="creationDate"
                            v-model="filter.CREATION_DATE"
                            :label="$t('transaction.listfilter.label.creationDateFrom')"
                            :options="creationDateOptions"
                            :searchable="false"
                            data-qa="listfilter_selectedCreationDateFrom"
                        />
                    
                        <div
                            v-if="filter.CREATION_DATE === 'CUSTOM'" 
                            class="row mb-small"
                        >
                            <div class="col-12 col-md-6">
                                <DatePicker
                                    id="dp1716895729027"
                                    v-model="filter.CREATION_DATE_START"
                                    :label="$t('transaction.listfilter.label.creationDateFrom.start')"
                                    data-qa="listfilter_selectedCreationDateFrom_start"
                                />
                            </div>
                            <div class="col-12 col-md-6">
                                <DatePicker
                                    id="dp1716895729028"
                                    v-model="filter.CREATION_DATE_END"
                                    :label="$t('transaction.listfilter.label.creationDateFrom.end')"
                                    data-qa="listfilter_selectedCreationDateFrom_end"
                                    :disabled-date="(date) => filter.CREATION_DATE_START ? date < filter.CREATION_DATE_START || date > new Date() : date < new Date()"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <Select
                            id="modificationDate"
                            v-model="filter.MODIFICATION_DATE"
                            :label="$t('transaction.listfilter.label.modificationDateFrom')"
                            :options="modificationDateOptions"
                            :searchable="false"
                            data-qa="listfilter_selectedModificationDateFrom"
                        />
                            
                        <div
                            v-if="filter.MODIFICATION_DATE === 'CUSTOM'"
                            class="row mb-small"
                        >
                            <div class="col-12 col-md-6">
                                <DatePicker
                                    id="dp1716888138330"
                                    v-model="filter.MODIFICATION_DATE_START"
                                    :label="$t('transaction.listfilter.label.modificationDateFrom.start')"
                                    data-qa="listfilter_selectedReportModificationDateFrom_start"
                                />
                            </div>
                            <div class="col-12 col-md-6">
                                <DatePicker
                                    id="dp1716888138331"
                                    v-model="filter.MODIFICATION_DATE_END"
                                    :label="$t('transaction.listfilter.label.modificationDateFrom.end')"
                                    data-qa="listfilter_selectedReportModificationDateFrom_end"
                                    :disabled-date="(date) => filter.MODIFICATION_DATE_START ? date < filter.MODIFICATION_DATE_START || date > new Date() : date < new Date()"
                                />
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <Select
                            id="invoiceDate"
                            v-model="filter.INVOICE_DATE"
                            :label="$t('transaction.listfilter.label.invoiceDateFrom')"
                            :options="invoiceDateOptions"
                            :searchable="false"
                            data-qa="listfilter_selectedCollectionInvoiceDateFrom"
                        />
                 
                        <div
                            v-if="filter.INVOICE_DATE === 'CUSTOM'"
                            class="row mb-small"
                        >
                            <div class="col-12 col-md-6">
                                <DatePicker
                                    id="dp1716888509518"
                                    v-model="filter.INVOICE_DATE_START"
                                    :label="$t('transaction.listfilter.label.creationDateFrom.start')"
                                    data-qa="listfilter_selectedCollectionInvoiceDateFrom_start"
                                />
                            </div>
                            <div class="col-12 col-md-6">
                                <DatePicker
                                    id="dp1716888509519"
                                    v-model="filter.INVOICE_DATE_END"
                                    :label="$t('transaction.listfilter.label.creationDateFrom.end')"
                                    data-qa="listfilter_selectedCollectionInvoiceDateFrom_end"
                                    :disabled-date="(date) => filter.INVOICE_DATE_START ? date < filter.INVOICE_DATE_START || date > new Date() : date < new Date()"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div 
                    v-if="transactionsStore.listFilter.activeCollectionFilters.value.length > 0"
                    class="row active-collection-filters"
                >
                    <div class="col-12 col-md-9">
                        <FilterLabel
                            v-for="(activeFilter, index) in transactionsStore.listFilter.activeCollectionFilters.value"
                            :key="`activeCollectionFilters-${index}`"
                            :data-qa="`listfilter_reset_${activeFilter.filter}`"
                            @click="transactionsStore.listFilter.resetAction(activeFilter.filter)"
                        >
                            {{ activeFilter.label }}
                        </FilterLabel>
                    </div>
                    <div class="col-12 col-md-3 text-align-right">
                        <button 
                            class="btn btn-default btn-small btn-min" 
                            data-qa="listfilter_reset_button"
                            @click="transactionsStore.listFilter.reset"
                        >
                            {{ $t('transaction.listfilter.label.reset.button') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import Checkbox from '@/components/Input/Checkbox.vue'
import Select from '@/components/Input/Select.vue'
import FilterLabel from '@/components/FilterLabel.vue'

import { useTransactionsStore } from '@/stores/transactions'
import DatePicker from '../Input/DatePicker.vue'
const transactionsStore = useTransactionsStore()

const procedureStageOptions = [
    { code: 'ALL', i18nKey: 'transaction.listfilter.procedureStage.ALL', label: '' },
    { code: 'DUNNING_SERVICE', i18nKey: 'transaction.listfilter.procedureStage.DUNNING_SERVICE', label: '' },
    { code: 'PRE_LITIGATION', i18nKey: 'transaction.listfilter.procedureStage.PRE_LITIGATION', label: '' },
    { code: 'LITIGATION', i18nKey: 'transaction.listfilter.procedureStage.LITIGATION', label: '' },
    { code: 'POST_LITIGATION', i18nKey: 'transaction.listfilter.procedureStage.POST_LITIGATION', label: '' },
    { code: 'MONITORING', i18nKey: 'transaction.listfilter.procedureStage.MONITORING', label: '' },
    // { code: 'CLOSED', i18nKey: 'transaction.listfilter.procedureStage.CLOSED', label: '' }
]

const creationDateOptions = [
    { code: 'ALL', i18nKey: 'transaction.listfilter.creationDateFrom.ALL', label: '' },
    { code: 'LAST_30_DAYS', i18nKey: 'transaction.listfilter.creationDateFrom.LAST_30_DAYS', label: '' },
    { code: 'LAST_60_DAYS', i18nKey: 'transaction.listfilter.creationDateFrom.LAST_60_DAYS', label: '' },
    { code: 'LAST_90_DAYS', i18nKey: 'transaction.listfilter.creationDateFrom.LAST_90_DAYS', label: '' },
    { code: 'CURRENT_YEAR', i18nKey: 'transaction.listfilter.creationDateFrom.CURRENT_YEAR', label: '' },
    { code: 'PREVIOUS_YEAR', i18nKey: 'transaction.listfilter.creationDateFrom.PREVIOUS_YEAR', label: '' },
    { code: 'CUSTOM', i18nKey: 'transaction.listfilter.creationDateFrom.CUSTOM', label: '' }
]

const modificationDateOptions = [
    { code: 'ALL', i18nKey: 'transaction.listfilter.modificationDateFrom.ALL', label: '' },
    { code: 'LAST_30_DAYS', i18nKey: 'transaction.listfilter.modificationDateFrom.LAST_30_DAYS', label: '' },
    { code: 'LAST_60_DAYS', i18nKey: 'transaction.listfilter.modificationDateFrom.LAST_60_DAYS', label: '' },
    { code: 'LAST_90_DAYS', i18nKey: 'transaction.listfilter.modificationDateFrom.LAST_90_DAYS', label: '' },
    { code: 'CURRENT_YEAR', i18nKey: 'transaction.listfilter.modificationDateFrom.CURRENT_YEAR', label: '' },
    { code: 'PREVIOUS_YEAR', i18nKey: 'transaction.listfilter.modificationDateFrom.PREVIOUS_YEAR', label: '' },
    { code: 'CUSTOM', i18nKey: 'transaction.listfilter.modificationDateFrom.CUSTOM', label: '' }
]

const invoiceDateOptions = [
    { code: 'ALL', i18nKey: 'transaction.listfilter.invoiceDateFrom.ALL', label: '' },
    { code: 'LAST_30_DAYS', i18nKey: 'transaction.listfilter.invoiceDateFrom.LAST_30_DAYS', label: '' },
    { code: 'LAST_60_DAYS', i18nKey: 'transaction.listfilter.invoiceDateFrom.LAST_60_DAYS', label: '' },
    { code: 'LAST_90_DAYS', i18nKey: 'transaction.listfilter.invoiceDateFrom.LAST_90_DAYS', label: '' },
    { code: 'CURRENT_YEAR', i18nKey: 'transaction.listfilter.invoiceDateFrom.CURRENT_YEAR', label: '' },
    { code: 'PREVIOUS_YEAR', i18nKey: 'transaction.listfilter.invoiceDateFrom.PREVIOUS_YEAR', label: '' },
    { code: 'CUSTOM', i18nKey: 'transaction.listfilter.invoiceDateFrom.CUSTOM', label: '' }
]

const filter = ref({
    PROCEDURE_STAGE: transactionsStore.listFilter.selectedCollectionProcedureStage.value,
    COLLECTION_FILE_CLOSED: transactionsStore.listFilter.selectedCollectionFileClosed.value === true,
    COLLECTION_FILE_OPEN: transactionsStore.listFilter.selectedCollectionFileClosed.value === false,
    CREATION_DATE: transactionsStore.listFilter.selectedCollectionCreationDateFrom.value,
    CREATION_DATE_START: transactionsStore.listFilter.selectedCollectionCreationDateFromStartDate.value,
    CREATION_DATE_END: transactionsStore.listFilter.selectedCollectionCreationDateFromEndDate.value,
    MODIFICATION_DATE: transactionsStore.listFilter.selectedCollectionModificationDateFrom.value,
    MODIFICATION_DATE_START: transactionsStore.listFilter.selectedCollectionModificationDateFromStartDate.value,
    MODIFICATION_DATE_END: transactionsStore.listFilter.selectedCollectionModificationDateFromEndDate.value,
    INVOICE_DATE: transactionsStore.listFilter.selectedCollectionInvoiceDateFrom.value,
    INVOICE_DATE_START: transactionsStore.listFilter.selectedCollectionInvoiceDateFromStartDate.value,
    INVOICE_DATE_END: transactionsStore.listFilter.selectedCollectionInvoiceDateFromEndDate.value
})

watch(() => filter.value, (newValue) => {
    if (newValue.COLLECTION_FILE_CLOSED === true && newValue.COLLECTION_FILE_OPEN === true) {
        transactionsStore.listFilter.setSelectedFilterParameter('collectionFileClosed', !transactionsStore.listFilter.selectedCollectionFileClosed.value, true)
    } else {
        if (newValue.COLLECTION_FILE_CLOSED === true) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionFileClosed', true, true)
        } else if (newValue.COLLECTION_FILE_OPEN === true) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionFileClosed', false, true)
        } else {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionFileClosed', null, true)
        }

        if (newValue.PROCEDURE_STAGE) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionProcedureStage', newValue.PROCEDURE_STAGE, true)
        }

        if (newValue.CREATION_DATE) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionCreationDateFrom', newValue.CREATION_DATE, true)
        }
        if (newValue.CREATION_DATE === 'CUSTOM' && newValue.CREATION_DATE_START) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionCreationDateFrom', newValue.CREATION_DATE_START, true)
        }
        if (newValue.CREATION_DATE === 'CUSTOM' && newValue.CREATION_DATE_END) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionCreationDateTo', newValue.CREATION_DATE_END, true)
        }

        if (newValue.MODIFICATION_DATE) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionModificationDateFrom', newValue.MODIFICATION_DATE, true)
        }
        if (newValue.MODIFICATION_DATE === 'CUSTOM' && newValue.MODIFICATION_DATE_START) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionModificationDateFrom', newValue.MODIFICATION_DATE_START, true)
        }
        if (newValue.MODIFICATION_DATE === 'CUSTOM' && newValue.MODIFICATION_DATE_END) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionModificationDateTo', newValue.MODIFICATION_DATE_END, true)
        }

        if (newValue.INVOICE_DATE) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionInvoiceDateFrom', newValue.INVOICE_DATE, true)
        }
        if (newValue.INVOICE_DATE === 'CUSTOM' && newValue.INVOICE_DATE_START) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionInvoiceDateFrom', newValue.INVOICE_DATE_START, true)
        }
        if (newValue.INVOICE_DATE === 'CUSTOM' && newValue.INVOICE_DATE_END) {
            transactionsStore.listFilter.setSelectedFilterParameter('collectionInvoiceDateTo', newValue.INVOICE_DATE_END, true)
        }
    }
}, { deep: true })
</script>

<style scoped lang="less">
.filter_box {
    margin: 10px 0 0;
    padding: 15px;

    h4 {
        margin-bottom: 15px;
        font-size: 1.1rem;
    }

    .active-collection-filters {
        margin-top: 1rem;
    }

    .align-flex-end {
        align-self: flex-end;
    }
}
</style>