import { computed, onMounted, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { reportProductsDal, reportProductsInt ,TransformedProduct } from '@/data/products'
import { useUserStore } from './user'
import { getMemberNavigationSession, getReportAct4SbNumberSession, isSameMemberId } from '@/helper/services/memberNavigation'
import { getCbraUserPreferences } from '@/services'
import { ProductInfos } from '@/views/Report/DetailCompany.vue'
import { ModalProps } from '@/types/components/modal'
import { useRoute } from 'vue-router'

const SIGNAL_AVAILABLE_PRODUCT_TYPES = [
    'TRAFFIC_LIGHT_REPORT',
    'BRIEF_REPORT',
    'COMPACT_REPORT',
    'COMPANY_REPORT_COMPACT',
    'FAMILYTREE_PLUS',
    'ESG_BASIS_REPORT',
    'RISIKO_CHECK',
    'REPORT_WITHOUT_SUPPLEMENT'
]

export const useProductStore = defineStore('product', () => {
    const isNotMyContext = ref(false)
    const transformedProducts = ref<TransformedProduct[]>([])
    const legitimateInterestShared = ref('')
    const userPreferences = ref()
    const productType = ref(reportProductsDal[0].type)
    const modal = ref<ModalProps>()
    const recommendedProductType = ref()
    const watchlistSupported = ref(false)
    const flippedProduct = ref<TransformedProduct | undefined>()
    const isDAL = ref(true)

    const userStore = useUserStore()
    const route = useRoute()

    onMounted(async() => {
        await userStore.getCbraUser()
        if (!isSameMemberId(userStore.cbraUser.crMembernumber) && getMemberNavigationSession() && !getReportAct4SbNumberSession()) {
            isNotMyContext.value = true
        } else {
            isNotMyContext.value = false
        }
        recommendedProductType.value = userStore.cbraUser.recommendedProduct
    })

    const product = computed(() => {
        return transformedProducts.value.find((product) => product.type === productType.value)
    })

    watch(transformedProducts, (newValue) => {
        let defaultProductType = userPreferences.value.defaultReport
        if (!isDAL.value) { defaultProductType = userPreferences.value.defaultReportInternational }

        const preselectedProductType = route.query.preselectProduct?.toString()
        let product = preselectedProductType ? getPreselectedProduct(newValue, preselectedProductType) : undefined
        if (!product) {
            product = getDefaultProduct(newValue, defaultProductType)
        }
        if (!product) {
            product = getRecommendedProduct(newValue, recommendedProductType.value)
        }

        if (!product) {
            product = reportProductsDal[0]
        }

        productType.value = product.type
    })

    async function transformProducts(productInfos: ProductInfos, showDALProducts: boolean, isDALNew: boolean) {
        const { data } = await getCbraUserPreferences()
        if (!data || !productInfos) return null

        userPreferences.value = data
        isDAL.value = isDALNew

        const products = showDALProducts ? reportProductsDal : reportProductsInt
        const receivableProducts = productInfos.receivable
        const orderableProducts = productInfos.orderable
        const allowedProducts = productInfos.permissions
 
        const orderableHasMonitoring = productInfos.orderableHasMonitoring
        const receivableHasMonitoring = productInfos.receivableHasMonitoring
        const orderableHasMonitoringPlus = productInfos.orderableHasMonitoringPlus
        const receivableHasMonitoringPlus = productInfos.receivableHasMonitoringPlus

        const orderableHasMonitoringRequired = productInfos.orderableHasMonitoringRequired
        const receivableHasMonitoringRequired = productInfos.receivableHasMonitoringRequired

        const filteredProducts = products.filter(prod => {
            return receivableProducts.includes(prod.type) || orderableProducts.includes(prod.type)
        })

        const newProducts = filteredProducts.map(prod => {
            const transformed: TransformedProduct = { 
                ...prod,
            }

            if (orderableHasMonitoring || receivableHasMonitoring) {
                if (orderableHasMonitoring.includes(prod.type)) {
                    transformed.ordeableHasMonitoring = true
                } else {
                    transformed.ordeableHasMonitoring = false
                }

                if (receivableHasMonitoring.includes(prod.type)) {
                    transformed.receivableHasMonitoring = true
                } else {
                    transformed.receivableHasMonitoring = false
                }

                if (orderableHasMonitoringPlus.includes(prod.type)) {
                    transformed.ordeableHasMonitoringPlus = true
                } else {
                    transformed.ordeableHasMonitoringPlus = false
                }

                if (receivableHasMonitoringPlus.includes(prod.type)) {
                    transformed.receivableHasMonitoringPlus = true
                } else {
                    transformed.receivableHasMonitoringPlus = false
                }

                if (orderableHasMonitoringRequired.includes(prod.type)) {
                    transformed.orderableHasMonitoringRequired = true
                } else {
                    transformed.orderableHasMonitoringRequired = false
                }

                if (receivableHasMonitoringRequired.includes(prod.type)) {
                    transformed.receivableHasMonitoringRequired = true
                } else {
                    transformed.receivableHasMonitoringRequired = false
                }

                if (isDALNew) {
                    transformed.isInDAL = isDALNew
                }
            }

            if (!allowedProducts.includes(prod.type)) {
                transformed.prohibited = true
            }

            if (!receivableProducts.includes(prod.type)) {
                transformed.hasToBeOrdered = true
            }

            if (transformed.hasToBeOrdered) {
                transformed.hasMonitoring = transformed.ordeableHasMonitoring
                transformed.hasMonitoringPlus = transformed.ordeableHasMonitoringPlus
                transformed.hasMonitoringRequired = transformed.orderableHasMonitoringRequired
            } else {
                transformed.hasMonitoring = transformed.receivableHasMonitoring
                transformed.hasMonitoringPlus = transformed.receivableHasMonitoringPlus
                transformed.hasMonitoringRequired = transformed.receivableHasMonitoringRequired
            }

            if (!transformed.hasMonitoring && SIGNAL_AVAILABLE_PRODUCT_TYPES.includes(prod.type)) {
                transformed.isSignalProduct = true
            }

            if (prod.type === recommendedProductType.value) {
                transformed.recommended = true
            }

            return transformed
        })

        transformedProducts.value = newProducts
    }

    function getPreselectedProduct(transformedProducts: TransformedProduct[], preselectedProductType: string) {
        return transformedProducts.find(prod => prod.type === preselectedProductType)
    }

    function getDefaultProduct(transformedProducts: TransformedProduct[], defaultProductType: string) {
        return transformedProducts.find(prod => prod.type === defaultProductType)
    }

    function getRecommendedProduct(transformedProducts: TransformedProduct[], recommendedProductType?: string) {
        return transformedProducts.find(prod => prod.type === recommendedProductType)
    }

    return {
        modal,
        isNotMyContext,
        productType,
        product,
        transformProducts,
        transformedProducts,
        legitimateInterestShared,
        watchlistSupported,
        flippedProduct
    }
})

