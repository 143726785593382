<template>
    <div class="crefo-input">
        <div 
            class="input-group"
            :class="{ 'has-error': !!errorMessage }"
        >
            <label class="input-label">
                {{ label }}<span v-if="required">*</span>
            </label>
            <div 
                class="radio-group"
                :class="{ 'disabled': disabled }"
                :data-qa="dataQa"
            >
                <div class="input-wrapper">
                    <div
                        v-for="(radio, index) in radioOptions"
                        :key="index"
                        class="crefo-radio"
                    >
                        <div
                            class="radio-btn"
                            :data-qa="radio.dataQa"
                        >
                            <input
                                :id="`${radio.id}-${index}`"
                                type="radio"
                                :name="radioGroupName"
                                :checked="isChecked(radio.value)"
                                :value="radio.value"
                                :disabled="disabled"
                                @click="updateValue"
                            >
                            <label :for="`${radio.id}-${index}`">
                                <span class="radio-box"><span
                                    class="radio-box-circle"
                                /></span>
                                <span class="radio-label">{{ radio.label }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div
                    v-if="helpText"
                    class="help-text"
                >
                    {{ helpText }}
                </div>
            </div>
            <p 
                v-if="errorMessage"
                class="error-msg" 
                :data-qa="errorMsgDataQaAttr"
            >
                <span>{{ errorMessage }}</span>
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { toRef, PropType } from 'vue'
import { useField } from 'vee-validate'

const emit = defineEmits(['update:modelValue', 'change'])

type RadioOption = {
    id: string
    value: boolean | string | number
    label: string
    dataQa?: string
}

const props = defineProps({
    modelValue: {
        type: [String, Number, Boolean] as PropType<string | number | boolean | null | undefined>,
        default: undefined
    },
    radioOptions: {
        type: Array as PropType<RadioOption[]>,
        default: () => ([])
    },
    radioGroupName: {
        type: String,
        required: true
    },
    label: {
        type: String,
        default: ''
    },
    helpText: {
        type: String,
        required: false,
        default: undefined
    },
    required: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    dataQa: {
        type: String,
        default: ''
    },
    errorMsgDataQa: {
        type: String,
        required: false,
        default: undefined
    }
})

const isChecked = (value: boolean | string | number) => {
    if (propsModelValue.value === undefined) return false
    if (propsModelValue.value === null) return false
    return value === propsModelValue.value
}

function updateValue(event: Event) {
    const target = event.target as HTMLInputElement
    let value: any = target.value
    if (value === 'true') value = true
    else if (value === 'false') value = false
    // else if (value === '') value = undefined
    // else if (Number(value)) value = Number(value)

    emit('update:modelValue', value)
    emit('change', value)
    handleChange(event)
}

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'radioGroupName')
const propsModelValue = toRef(props, 'modelValue')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
    handleChange,
    errorMessage
} = useField(name, undefined, {
    type: 'radio',
    initialValue: props.modelValue,
    syncVModel: true
})

const errorMsgDataQaAttr = props.errorMsgDataQa || (props.dataQa ? `${props.dataQa}-message` : '')
</script>

<style scoped>
.has-error {
    .input-label {
        color: var(--color-error);
    }
}

.disabled .crefo-radio input,
.disabled .crefo-radio label {
    cursor: not-allowed !important;
}
.help-text {
    font-size: 12px;
    color: var(--color-c-grey2);
    margin-top: 5px;
}
</style>
