import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from '@/services/tracing-axios'
import { AxiosResponse } from 'axios'
import type { CbraFeatures } from '@/types/cbra/features'
import { CbraSearchSuggestConfiguration } from '@/types/cbra/searchConfiguration'
import { getCbraSearchSuggestConfiguration } from '@/services'

export const useCbraSettingsStore = defineStore('cbraSettings', () => {
    // state
    const error = ref('')
    const features = ref<CbraFeatures>({
        wkvActive: false,
        askNotificationActive: false,
        portfolioViewActive: false,
        submitCollectionFileInfoActive: false,
        wkvRuVActive: false
    })
    const featuresReadyToRead = ref(false)
    const searchConfiguration = ref<CbraSearchSuggestConfiguration>({
        id: 0,
        characterThreshold: 3,
        maximumInterval: 100,
        searchResults: 10,
        typingTimeout: 500,
        wordSeparators: '',
        wordSeparatorsList: []
    })
    // state

    // getters
    // getters

    // actions
    async function getCbraFeatures() {
        try {
            const { status, data } = await axios.get<CbraFeatures, AxiosResponse<CbraFeatures>, void>('/cbra/features')

            if (status === 200) {
                features.value = data
                featuresReadyToRead.value = true
            }
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }
    }
    async function getCbraSearchConfiguration() {
        try {
            const { data } = await getCbraSearchSuggestConfiguration()
            searchConfiguration.value = data
        } catch (err: any) {
            let message = err.message
            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }
            error.value = message
        }
    }
    // actions

    return { features, featuresReadyToRead, searchConfiguration, getCbraFeatures, getCbraSearchConfiguration }
})
