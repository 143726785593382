<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'
import Accordion from '@/components/Accordion/Accordion.vue'

const id = 'portal.mc.help.traffic-light-report'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'credit-information' }">
                    {{ $t('help.creditReports.breadcrumbs.creditReport') }}
                </router-link>
            </li>
            <li>{{ $t('help.creditReports.breadcrumbs.trafficLight') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3" />
                <div class="col-md-9">
                    <div
                        id="c475"
                        class=" clearfix grid-box grid-box--accordionContainer box"
                        data-qa="grid-box-475"
                    >
                        <div class="ce-header">
                            <h3>{{ $t('TrafficLightReport.title') }}</h3>
                        </div>

                        <div
                            id="c486"
                            class="ce-box   box"
                            data-qa="ce-486"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/help/bonitaetsauskunft/Ampelauskunft_star.png"
                                                    width="665"
                                                    height="349"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext" />
                            </div>
                        </div>

                        <Accordion>
                            <AccordionItem
                                :item-key="1"
                                :title="$t('TrafficLightReport.accordion.item1.panel')"
                            >
                                <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                    <div class="ce-textpic__bodytext">
                                        <h4>{{ $t('TrafficLightReport.accordion.item1.body.title') }}</h4>
                                        <p>
                                            {{ $t('TrafficLightReport.accordion.item1.body.ps.1') }}
                                        </p>
                                        <p>{{ $t('TrafficLightReport.accordion.item1.body.ps.2') }}</p>
                                    </div>
                                </div>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="2"
                                :title="$t('TrafficLightReport.accordion.item2.panel')"
                            >
                                <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                    <div class="ce-textpic__bodytext">
                                        <h4>{{ $t('TrafficLightReport.accordion.item2.body.title') }}</h4>
                                        <p>{{ $t('TrafficLightReport.accordion.item2.body.p1') }}</p>
                                    </div>
                                </div>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="3"
                                :title="$t('TrafficLightReport.accordion.item3.panel')"
                            >
                                <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                    <div
                                        class="ce-textpic__gallery"
                                        data-ce-columns="1"
                                        data-ce-images="1"
                                    >
                                        <div class="ce-textpic__row">
                                            <div class="ce-textpic__column">
                                                <figure class="ce-textpic__media ce-textpic__media--with-iframe">
                                                    <img
                                                        title="Ampelauskunft"
                                                        src="@/assets/img/help/bonitaetsauskunft/ampelauskunft/ampelauskunft.png"
                                                        width="800"
                                                        height="384"
                                                        alt=""
                                                    >

                                                    <figcaption>
                                                        {{ $t('TrafficLightReport.accordion.item3.body.imageDescription') }}
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="ce-textpic__bodytext">
                                        <h4>{{ $t('TrafficLightReport.accordion.item3.body.title') }}</h4>
                                        <p>
                                            {{ $t('TrafficLightReport.accordion.item3.body.ps.1') }}
                                        </p>
                                        <p>{{ $t('TrafficLightReport.accordion.item3.body.ps.2') }}</p>
                                    </div>
                                </div>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="4"
                                :title="$t('TrafficLightReport.accordion.item4.panel')"
                            >
                                <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                    <div class="ce-header">
                                        <h3>{{ $t('TrafficLightReport.accordion.item4.body.titles.1') }}</h3>
                                    </div>

                                    <div class="ce-textpic__bodytext">
                                        <p>
                                            {{ $t('TrafficLightReport.accordion.item4.body.ps.1') }}
                                        </p>
                                        <h4>{{ $t('TrafficLightReport.accordion.item4.body.titles.2') }}</h4>
                                        <p>{{ $t('TrafficLightReport.accordion.item4.body.ps.2') }}</p>
                                    </div>
                                </div>
                            </AccordionItem>
                            <AccordionItem
                                :item-key="5"
                                :title="$t('TrafficLightReport.accordion.item5.panel')"
                            >
                                <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                    <div class="ce-textpic__bodytext">
                                        <h4>{{ $t('TrafficLightReport.accordion.item5.body.titles.1') }}</h4>
                                        <p>
                                            {{ $t('TrafficLightReport.accordion.item5.body.ps.1') }}
                                        </p>
                                        <h4>{{ $t('TrafficLightReport.accordion.item5.body.titles.2') }}</h4>
                                        <ul class="list-unordered">
                                            <li>{{ $t('TrafficLightReport.accordion.item5.body.ul.lis.1') }}</li>
                                            <li>{{ $t('TrafficLightReport.accordion.item5.body.ul.lis.2') }}</li>
                                            <li>{{ $t('TrafficLightReport.accordion.item5.body.ul.lis.3') }}</li>
                                            <li>{{ $t('TrafficLightReport.accordion.item5.body.ul.lis.4') }}</li>
                                        </ul>
                                        <h4>{{ $t('TrafficLightReport.accordion.item5.body.titles.3') }}</h4>
                                        <p>
                                            {{ $t('TrafficLightReport.accordion.item5.body.ps.2') }}
                                        </p>
                                        <h4>{{ $t('TrafficLightReport.accordion.item5.body.titles.4') }}</h4>
                                        <i18n-t
                                            keypath="TrafficLightReport.accordion.item5.body.ps.3"
                                            tag="p"
                                        >
                                            <strong>{{ $t('TrafficLightReport.accordion.item5.body.ps.strongs.1') }}</strong>
                                            <strong>{{ $t('TrafficLightReport.accordion.item5.body.ps.strongs.1') }}</strong>
                                            <strong>{{ $t('TrafficLightReport.accordion.item5.body.ps.strongs.1') }}</strong>
                                        </i18n-t>
                                    </div>
                                </div>
                            </AccordionItem>
                        </Accordion>

                        <div
                            id="c713"
                            class="ce-box   box"
                            data-qa="ce-713"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                <div class="ce-textpic__bodytext">
                                    <i18n-t
                                        keypath="TrafficLightReport.backLink.text"
                                        tag="p"
                                    >
                                        <router-link
                                            :to="{ name: 'credit-information' }"
                                            title="Opens internal link in current window"
                                            class="internal-link"
                                        >
                                            {{ $t('TrafficLightReport.backLink.linkText') }}
                                        </router-link>&nbsp;
                                    </i18n-t>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
}
.grid-box {
    display: block;
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--right {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--right.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
@media (max-width: 1199px) {
    .ce-textpic--right {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
}

strong {
    color: #183f6d;
}
</style>
