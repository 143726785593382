import { ref, computed, watch, markRaw, type Ref } from 'vue'
import { defineStore } from 'pinia'

import CONSTANTS from '@/data/constants'

import {
    CONSTS,
    getTransactionsRequestParameters
} from '@/helper/services/transaction'
import { differentContextActive } from '@/helper/services/memberNavigation'
import { checkAllUserRightsOfCurrentUser } from '@/helper/services/userRights'
import { getCookie, setCookie } from '@/helper/storage'
import TransactionsListFilter from '@/helper/transactionListFilter'
import { placeNewTransactionsListRequest } from '@/helper/webtrekk/transactions'

import router from '@/router'

import {
    getCbraSearchSuggestConfiguration,
    getCbraTransactions,
    deleteCbraTransactionsByIds,
    updateCbraUserPreferences
} from '@/services'

import { useUserStore } from './user'
import { scrollTo } from '@/helper/scrollto'
import { CbraTransaction } from '@/types/cbra/transactions'
import { CbraUserPreferences } from '@/types/cbra/userPreferences'

const COOKIE_NAME = 'TRANSACTION_LIST_COOKIE'

export const useTransactionsStore = defineStore('transactions', () => {
    const cbraUserStore = useUserStore()

    // state
    const showExportError = ref(false)
    const showExportLimit = ref(0)

    const error = ref(false)
    const showDeleteSuccessModal = ref(false)
    const loading = ref(true)
    const initialLoad = ref(true)

    const transactions = ref<CbraTransaction[]>([])
    const userTransactions = ref([])
    const memberTransactions = ref([])
    const searchQuery = ref('')

    const userPreferences = ref<CbraUserPreferences | null>(null)

    const transactionsCount = ref(0)
    const sortMode = ref(CONSTS.SORT.CREATION_TIME_DESC)
    const filterMode = ref(CONSTS.FILTER.ALL)

    // WTF dm/meine-creditreform/portal-frontend/portal-frontend-releases/build/ux-prototype/webapp/public/js/viewmodels/Pager.js
    const pager = ref({
        currentPage: 1,
        pageSize: 10,
        pageIndex: 0,
        totalCount: 0,
        totalPages: 0,
        pages: [],
        getOffset: function() { return 0 },
        getLimit: function() { return 0 },
        getPages: function() { return [] }
    })
    // const searchButtonEnabled = ref()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const searchButtonEnabled = computed(() => {
        return isSearchActive.value
    })
    const isMember = ref(false)
    const savedMember = ref(null)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isSavedMember = ref(false)
    const hasRights: any = ref({})
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const currentExportUrl = ref('#')

    const memberUserId: Ref<string | null> = ref(null)

    const countMember = ref(0)
    const countUser = ref(0)
    const currentTabId = ref('2')
    const showTabs = ref(false)

    const currentCount = computed(() => {
        return isMember.value === true ? countMember.value : countUser.value
    })

    const isDifferentContext = computed(() => {
        return differentContextActive()
    })

    const actionInProgress = ref(false)

    const filterPreference = ref(false)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const urlToFollow = ref(null)

    // options for legitimate interest dropdown in order form
    const legitimateInterest = ref(null)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const legitimateInterestOptions = CONSTANTS.LEGITIMATE_INTEREST_OPTIONS

    const errors = ref([])
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const checkFailed = computed(() => {
        return errors.value.length > 0
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isLegitimationSaving = ref(false)

    // WTF dm/meine-creditreform/portal-frontend/portal-frontend-releases/build/ux-prototype/webapp/public/js/viewmodels/transactions/TransactionsListFilter.js
    // Make sure to markRaw the object to avoid reactivity
    const listFilter = markRaw(new TransactionsListFilter(
        filterMode.value,
        executeNewSearch
    ))

    const showNoResult = computed(() => {
        return !loading.value && !error.value && hasNoTransactions.value
    })

    const showResult = computed(() => {
        return !loading.value && !error.value && !hasNoTransactions.value
    })

    const hasActiveFilters = computed(() => {
        return !!(searchQuery.value.length || filterMode.value !== CONSTS.FILTER.ALL || listFilter.activeCollectionFilters.length || listFilter.activeReportFilters.length)
    })

    const hasNoResults = computed(() => {
        return hasNoTransactions.value
    })

    const hasNoTransactions = computed(() => {
        let hasNoTransactions = false

        if (hasRights.value.portfolioReport === true) {
            hasNoTransactions = transactions.value.length === 0 && countMember.value === 0 && countUser.value === 0
        } else {
            hasNoTransactions = transactions.value.length === 0 && countUser.value === 0
        }

        return hasNoTransactions
    })

    const deleteAreaIsActive = ref(false)
    const deleteOptionsAreShown = ref(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const deleteButtonActive = computed(() => {
        const filter = transactions.value.filter((item: any) => {
            return item.checkForDeleting === true
        })
        return filter.length > 0
    })

    const selectAll = ref(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const hasAllChecked = computed(() => {
        const filter = transactions.value.filter((item: any) => {
            return item.checkForDeleting !== true
        })
        return filter.length === 0
    })

    const timestampLastSend: any = ref(null)
    const timestampLastKeystroke: any = ref(null)
    const searchConfig: any = ref(null)

    const isSearchActive = computed(() => {
        return searchConfig.value !== null && typeof searchQuery.value === 'string'
    })
    // state

    // getters
    // getters

    // actions
    function saveSettings() {
        if (initialLoad.value === true) {
            return
        }

        const mode = filterMode.value
        const cookie = getCookie(COOKIE_NAME)
        let _cookie: any = cookie ? JSON.parse(cookie) : null
        if (!_cookie) {
            _cookie = {
                filterParameters: {},
                filterParameters2: {}
            }
        }
        _cookie.savedMember = savedMember.value
        _cookie.sortMode = sortMode.value
        _cookie.filterMode = mode
        _cookie.filterParameters[mode] = listFilter.getSelectedFilterParameters()
        _cookie.pagerPageSize = pager.value.pageSize
        _cookie.filterPreference = filterPreference.value
        console.log('saving filter settings', JSON.stringify(_cookie))
        setCookie(COOKIE_NAME, JSON.stringify(_cookie))

        if (filterPreference.value === true && initialLoad.value === false) {
            const prefs: any = userPreferences.value || {}
            prefs.transactionFilter = JSON.stringify(_cookie)
            updateCbraUserPreferences(prefs)
        }
    }

    function loadSettings(ignoreFilterMode: boolean = false) {
        return new Promise((resolve) => {
            const queryParams = router.currentRoute.value.query
            let settings = null

            const _cookie = getCookie(COOKIE_NAME)
            if (_cookie) {
                try {
                    settings = JSON.parse(decodeURIComponent(_cookie))
                } catch (e) {
                    console.log(e)
                }
            }

            if (settings || (queryParams.sortMode || queryParams.filterMode)) {
                if (settings && settings.filterPreference) {
                    filterPreference.value = settings.filterPreference
                }

                // set filter mode
                if (!ignoreFilterMode) {
                    if (settings && settings.filterMode) {
                        filterMode.value = settings.filterMode
                    }
                    if (queryParams.filterMode) {
                        console.log('set filterMode via query parameter', queryParams.filterMode)
                        filterMode.value = queryParams.filterMode
                    }
                }
                // set sort mode
                if (settings && settings.sortMode) {
                    sortMode.value = settings.sortMode
                }
                if (queryParams.sortMode) {
                    // sortModes OBSERVATION_END_TIME_* is only valid for filterMode REPORT
                    if (filterMode.value === CONSTS.FILTER.REPORT) {
                        console.log('set sortMode via query parameter', queryParams.sortMode)
                        sortMode.value = queryParams.sortMode
                    } else {
                        if (
                            queryParams.sortMode !== CONSTS.SORT.OBSERVATION_END_TIME_ASC &&
                            queryParams.sortMode !== CONSTS.SORT.OBSERVATION_END_TIME_DESC
                        ) {
                            console.log('set sortMode via query parameter', queryParams.sortMode)
                            sortMode.value = queryParams.sortMode
                        } else {
                            sortMode.value = CONSTS.SORT.CREATION_TIME_DESC
                        }
                    }
                }

                if (settings && settings.pagerPageSize) {
                    pager.value.pageSize = settings.pagerPageSize
                }

                if (settings && settings.savedMember) {
                    savedMember.value = settings.savedMember
                }

                // set filter parameters
                if (settings && settings.filterParameters) {
                    console.log('loadSettings, setting filterParameters from cookie', settings.filterParameters)
                    listFilter.setSelectedFilterParameters(
                        settings.filterParameters[filterMode.value], true, true
                    )
                }
                if (
                    queryParams.filterParameters &&
                    queryParams.filterMode &&
                    queryParams.filterMode === filterMode.value
                ) {
                    // https://sedevcp.meine.creditreform.de/portal/mc/vorgaenge?filterMode=REPORT&filterParameters=%7B%22observationExpirationType%22%3A%22EXPIRED_OBSERVATIONS%22%7D
                    try {
                        const filterParams = JSON.parse(queryParams.filterParameters) || {}
                        console.log('loadSettings, setting filterParameters via query parameter', queryParams.filterParameters)
                        listFilter.setSelectedFilterParameters(filterParams, true, true)
                    } catch (e) {
                        console.log(e)
                    }
                }
            } else {
                sortMode.value = CONSTS.SORT.CREATION_TIME_DESC
                filterMode.value = CONSTS.FILTER.ALL
            }

            if (!searchConfig.value) {
                getCbraSearchSuggestConfiguration().then((conf) => {
                    if (conf.status === 200 && conf.data) {
                        searchConfig.value = conf.data
                        console.log('getCbraSearchSuggestConfiguration', searchConfig.value)
                    }
                    resolve(true)
                })
            } else {
                resolve(true)
            }
        })
    }

    function setCookieIfNeeded() {
        const userPrefs = userPreferences.value
        const cookie = getCookie(COOKIE_NAME)
        if (cookie === null && userPrefs !== null && userPrefs.filterPreference === true && userPrefs.transactionFilter) {
            const newFilter = JSON.parse(JSON.stringify(userPrefs.transactionFilter))
            setCookie(COOKIE_NAME, newFilter)
        }
    }

    function onSearchSubmit() {
        isSearchRequestInputRateOK().then((isInputRateOK) => {
            if (isInputRateOK) {
                pager.value.pageIndex = 0
                sendQuery(false)
            }
        })
    }

    function isIntervalRequestRateOK() {
        const intervalTimeout = (searchConfig.value && searchConfig.value.maximumInterval && typeof searchConfig.value.maximumInterval === 'number') ? searchConfig.value.maximumInterval : 500
        const isRequestRateOK = !timestampLastSend.value || (Date.now() - timestampLastSend.value >= intervalTimeout)
        console.log('Transactions: isIntervalRequestRateOK', isRequestRateOK)
        return isRequestRateOK
    }

    function isSearchRequestInputRateOK() {
        return new Promise((resolve) => {
            const typingTimeout = (searchConfig.value && searchConfig.value.typingTimeout) ? searchConfig.value.typingTimeout : 500
            const lastKeyStroke = timestampLastKeystroke.value
            setTimeout(() => {
                const isInputRateOK = (lastKeyStroke === timestampLastKeystroke.value)
                console.log('Transactions: isSearchRequestInputRateOK', isInputRateOK)
                resolve(isInputRateOK)
            }, typingTimeout)
        })
    }

    /**
    * Check last character of text if it's contained in list of word separators
    * @param str {String}
    * @return {boolean}
    */
    function hasWordSeparator(str: string) {
        let isOK = false
        if (
            typeof str === 'string' &&
            searchConfig.value &&
            searchConfig.value.wordSeparatorsList &&
            searchConfig.value.wordSeparatorsList.includes(str.slice(-1))
        ) {
            isOK = true
        }
        console.log('Transactions: hasWordSeparator', isOK)
        return isOK
    }

    /**
    * check if threshold is reached and returns true
    * @param str {String}
    * @return {boolean}
    */
    function hasReachedCharacterThreshold(str: string) {
        let isOK = true
        if (
            searchConfig.value &&
            searchConfig.value.characterThreshold &&
            typeof searchConfig.value.characterThreshold === 'number'
        ) {
            // is str.length === 0 really correct here!?
            isOK = str.length === 0 || str.length >= searchConfig.value.characterThreshold
        }
        console.log('Transactions: hasReachedCharacterThreshold', isOK)
        return isOK
    }

    function hasErasableItems() {
        const erasableItems = transactions.value.filter((item: any) => {
            return item.reportTransaction?.erasable === true || item.involvementdataTransaction?.erasable === true
        })
        return erasableItems.length > 0
    }

    function onTransactionsLoaded(response: any) {
        countMember.value = response.memberResults && response.memberResults.sizeTransactions ? response.memberResults.sizeTransactions : 0
        countUser.value = response.userResults && response.userResults.sizeTransactions ? response.userResults.sizeTransactions : 0

        if (countMember.value > 0) {
            memberTransactions.value = response.memberResults.transactions
        }
        if (countUser.value > 0) {
            userTransactions.value = response.userResults.transactions
        }

        // if (countUser.value === 0 && countMember.value > 0 && !isDifferentContext.value) {
        if (countUser.value === 0 && countMember.value > 0) {
            if (initialLoad.value) currentTabId.value = '1'
            isMember.value = true
        } else if (countUser.value === countMember.value && isDifferentContext.value) {
            if (initialLoad.value) currentTabId.value = '2'
            isMember.value = true
        }

        if (isMember.value === true || currentTabId.value === '1') {
            transactions.value = response.memberResults?.transactions || []
            transactionsCount.value = response.memberResults?.sizeTransactions
        } else if (countMember.value > 0 && isDifferentContext.value) {
            transactions.value = response.memberResults?.transactions || []
            transactionsCount.value = response.memberResults?.sizeTransactions
        } else {
            transactions.value = response.userResults?.transactions || []
            transactionsCount.value = response.userResults?.sizeTransactions 
        }

        checkReportMassDelete()

        actionInProgress.value = false
        loading.value = false
        showTabs.value = true
        scrollTo('.transactions__search_results')
    }

    function checkReportMassDelete() {
        const filter = listFilter.getSelectedFilterParameters()
        if (
            (
                filter?.observationType === CONSTS.REPORT_OBSERVATION_TYPE.WITHOUT_OBSERVATION ||
            filter?.observationExpirationType === CONSTS.REPORT_OBSERVATION_EXPIRE_TYPE.EXPIRED_OBSERVATIONS
            ) && hasErasableItems()
        ) {
            deleteAreaIsActive.value = true
            selectAll.value = false
        } else {
            deleteAreaIsActive.value = false
            deleteOptionsAreShown.value = false
            selectAll.value = false
        }
    }

    function getTransactionsRequest(
        considerPageIndexInSearch: boolean = true,
        optionalFilterParameters: any = {},
        headers: any = {}
    ) {
        const payload = getTransactionsRequestParameters(
            searchQuery.value,
            pager.value.pageSize,
            considerPageIndexInSearch ? pager.value.pageIndex : 0,
            sortMode.value,
            filterMode.value,
            listFilter.getNormalizedFilterParameters(optionalFilterParameters)
        )
        return new Promise((resolve) => {
            getCbraTransactions(payload, headers).then((response) => {
                if (response.status === 200) {
                    resolve(response)
                } else {
                    error.value = true
                    loading.value = false
                    resolve(false)
                }
            })
        })
    }

    async function getTransactions(
        considerPageIndexInSearch: boolean = true,
        optionalFilterParameters: any = {}
    ) {
        const query = searchQuery.value
        console.log('getTransactions', query)
        loading.value = true

        let memberResults = null
        let userResults = null

        if (hasRights.value.portfolioReport === true) {
            //considerPageIndexInSearch = currentTabId.value === '2' ? false : considerPageIndexInSearch
            const { status, data } = await getTransactionsRequest(
                considerPageIndexInSearch,
                optionalFilterParameters,
                {
                    'viewScope': 'MEMBER'
                }
            )

            if (status === 200) {
                memberResults = data
            }
        }
        
        updateCurrentExportUrl(query, optionalFilterParameters, {})
        if (memberResults && memberResults.sizeTransactions && isDifferentContext.value) {
            userResults = memberResults
        }

        if (!(memberResults && memberResults.sizeTransactions && isDifferentContext.value)) {
            //considerPageIndexInSearch = currentTabId.value === '1' ? false : considerPageIndexInSearch
            const { status, data } = await getTransactionsRequest(
                considerPageIndexInSearch,
                optionalFilterParameters,
                {
                    'viewScope': ''
                }
            )
    
            if (status === 200) {
                userResults = data
            }
        }

        onTransactionsLoaded({
            memberResults: memberResults,
            userResults: userResults
        })
    }

    function sendQuery(
        considerPageIndexInSearch: boolean = true,
        filterParameters: any = null
    ) {
        const query = searchQuery.value
        console.log('Transactions: sendQuery', query)

        if (filterParameters === null) {
            filterParameters = listFilter.getSelectedFilterParameters(true)
        }
        if (filterParameters.reportCreationDateFrom === 'CUSTOM') {
            delete filterParameters.reportCreationDateFrom
        }
        if (filterParameters.modificationDateFrom === 'CUSTOM') {
            delete filterParameters.modificationDateFrom
        }
        console.log('Transactions: sendQuery', filterParameters)

        if (isSearchActive.value && isIntervalRequestRateOK()) {
            timestampLastSend.value = Date.now()

            if (hasWordSeparator(query) || hasReachedCharacterThreshold(query)) {
                sendWebtrekkRequest(query, considerPageIndexInSearch)
                getTransactions(considerPageIndexInSearch, filterParameters)
            }
        }
    }

    function executeNewSearch(resetPageIndex: boolean = false) {
        if (
            filterMode.value !== CONSTS.FILTER.ALL ||
            filterMode.value !== CONSTS.FILTER.REPORT
        ) {
            isMember.value = false
            if (initialLoad.value)
                currentTabId.value = '2'
            console.log('setting currentTab id to 2 in executeNewSearch')
        }

        if (resetPageIndex) {
            pager.value.pageIndex = 0
        }
        saveSettings()
        sendQuery()
    }

    function deleteTransactions(ids: string[]) {
        const payload = {
            listToDelete: ids
        }

        let headers = {}
        if (isMember.value && hasRights.value.portfolioReport) {
            headers = {
                'viewScope': 'MEMBER'
            }
        }

        deleteCbraTransactionsByIds(payload, headers).then((response) => {
            if (response.status === 200) {
                if (ids.length === 1) {
                    showDeleteSuccessModal.value = true
                }
                executeNewSearch(true)
            }
        })
    }

    function sendWebtrekkRequest(
        query: string, 
        considerPageIndexInSearch: boolean, 
        isExport: boolean = false, 
        isError: boolean = false
    ) {
        const params = listFilter.getSelectedFilterParameters(false)
        const normalizedParams = listFilter.getNormalizedFilterParameters(params)
        placeNewTransactionsListRequest(
            query,
            filterMode.value,
            sortMode.value,
            normalizedParams,
            considerPageIndexInSearch ? pager.value.pageIndex : 0,
            isExport,
            isError
        )
    }

    function trackExportClick() {
        const params = listFilter.getSelectedFilterParameters(false)
        const normalizedParams = listFilter.getNormalizedFilterParameters(params)
        placeNewTransactionsListRequest(
            searchQuery.value,
            filterMode.value,
            sortMode.value,
            normalizedParams,
            pager.value.pageIndex,
            true
        )
    }

    /**
     * updateCurrentExportUrl
     *
     * @param query
     * @param optionalFilterParameters
     * @param headers
     */
    function updateCurrentExportUrl(
        query: string,
        optionalFilterParameters = {},
        headers = {}
    ) {
        const parameters = getTransactionsRequestParameters(
            query,
            pager.value.pageSize,
            0,
            sortMode.value,
            filterMode.value,
            listFilter.getNormalizedFilterParameters(optionalFilterParameters)
        )
        const exportParameters = Object.assign(
            parameters,
            headers,
            { lang: window.cp.language.toUpperCase() },
            { redirect: window.location.href.replace(window.location.search, '') }
        )
        currentExportUrl.value = '/cbra/transactions/export?' + new URLSearchParams(exportParameters).toString()
    }

    function init() {
        if (showExportError.value) {
            if (showExportLimit.value > 0) {
                // uimodal.show({
                //     title: translationService.translate('error.transaction.exportLimit'),
                //     content: translationService.translate('error.transaction.exportLimit.description', [parseInt(this.showExportLimit)]),
                //     icon: "warning"
                // })
                // this.sendWebtrekkRequest(this.searchQuery(), false, true, parseInt(this.showExportLimit));
            } else {
                // uimodal.show({
                //     title: translationService.translate('error.transaction.export'),
                //     content: translationService.translate('error.transaction.export.description'),
                //     icon: "error"
                // })
                // this.sendWebtrekkRequest(this.searchQuery(), false, true, true);
            }
        }

        checkAllUserRightsOfCurrentUser().then((response: any) => {
            hasRights.value = response
            loadSettings().then(() => {
                sendQuery()
                initialLoad.value = false
            })
        })

        if (
            cbraUserStore.cbraUser.memberUserId !== null &&
            cbraUserStore.cbraUser.memberUserId !== ''
        ) {
            memberUserId.value = cbraUserStore.cbraUser.memberUserId
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function showDeleteOptions() {
        deleteOptionsAreShown.value = !deleteOptionsAreShown.value
    }
    // actions

    watch(() => filterMode.value, (newVal) => {
        // sortModes OBSERVATION_END_TIME_* is only valid for filterMode REPORT
        if (
            newVal !== CONSTS.FILTER.REPORT &&
            (
                sortMode.value === CONSTS.SORT.OBSERVATION_END_TIME_ASC ||
                sortMode.value === CONSTS.SORT.OBSERVATION_END_TIME_DESC
            )
        ) {
            sortMode.value = CONSTS.SORT.CREATION_TIME_DESC
        }
        listFilter.setFilterMode(newVal)
        saveSettings() 
        loadSettings(true).then(() => {
            executeNewSearch(true)
        })
    })

    watch(() => currentTabId.value, (newVal) => {
        transactions.value = []
        if (newVal === '1' && countMember.value > 0) {
            pager.value.totalCount = countMember.value
            pager.value.pageIndex = 0
            transactions.value = memberTransactions.value
        } else {
            pager.value.totalCount = countUser.value
            pager.value.pageIndex = 0
            transactions.value = userTransactions.value
        }
        checkReportMassDelete()
    })

    watch(() => sortMode.value, () => { executeNewSearch() })

    watch(() => pager.value.pageIndex, () => {
        saveSettings()
        loadSettings(true).then(() => {
            executeNewSearch()
        })
    })

    watch(() => pager.value.pageSize, () => {
        saveSettings()
        loadSettings(true).then(() => {
            executeNewSearch()
        })
    })

    watch(() => selectAll.value, (newVal) => {
        transactions.value.forEach((item) => {
            item.checkForDeleting = newVal
        })
    })

    watch(() => transactionsCount.value, (newVal) => {
        pager.value.totalCount = newVal
    })

    watch(() => searchQuery.value, () => {
        timestampLastKeystroke.value = Date.now()
        onSearchSubmit()
    })

    return {
        error,
        showDeleteSuccessModal,
        loading,
        initialLoad,
        transactions,
        searchQuery,
        userPreferences,
        transactionsCount,
        sortMode,
        filterMode,
        pager,
        searchButtonEnabled,
        isMember,
        savedMember,
        isSavedMember,
        hasRights,
        currentExportUrl,
        countMember,
        countUser,
        currentTabId,
        showTabs,
        currentCount,
        isDifferentContext,
        actionInProgress,
        filterPreference,
        urlToFollow,
        legitimateInterest,
        legitimateInterestOptions,
        errors,
        checkFailed,
        isLegitimationSaving,
        listFilter,
        showNoResult,
        showResult,
        hasActiveFilters,
        hasNoResults,
        hasNoTransactions,
        deleteAreaIsActive,
        deleteOptionsAreShown,
        deleteButtonActive,
        selectAll,
        hasAllChecked,
        timestampLastSend,
        timestampLastKeystroke,
        searchConfig,
        isSearchActive,
        saveSettings,
        loadSettings,
        setCookieIfNeeded,
        onSearchSubmit,
        isIntervalRequestRateOK,
        isSearchRequestInputRateOK,
        hasWordSeparator,
        hasReachedCharacterThreshold,
        hasErasableItems,
        onTransactionsLoaded,
        getTransactionsRequest,
        getTransactions,
        sendQuery,
        executeNewSearch,
        init,
        showDeleteOptions,
        deleteTransactions,
        trackExportClick
    }
})
