<template>
    <div class="container content-spacer">
        <div class="row mb-large">
            <div class="col-12 col-lg-3 mb">
                <div class="box-shadow-xy bg-white chapter-links-box">
                    <div class="box-spacing">
                        <ChapterLinks 
                            section="Administration"
                            :links="chapter" 
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-9 mb-large">
                <div
                    id="superuseradmindata-navigation"
                    data-qa="selfcare-superuseradmin-navigation"
                    class="box-shadow-xy bg-white"
                >
                    <div class="box-spacing">
                        <h3>{{ $t('profile.administration.navigation.title') }}</h3>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <p v-html="$t('profile.administration.navigation.description')" />

                        <LoadingOverlay
                            v-if="loading"
                            id="superuseradmin-settings"
                        />

                        <div
                            v-if="!loading && error"
                            class="crefo-ui-alert error mb"
                        >
                            {{ $t('globalMisc.error.errorLoadingData.message') }}
                        </div>

                        <form
                            v-if="!loading && !error && hasMembers"
                            @submit="onSubmit"
                        >
                            <div class="row mb not-responsive">
                                <div class="col-12 col-md-6">
                                    <b>{{ $t('profile.administration.navigation.fields.identifier') }}</b>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="row">
                                        <div class="col-12 col-md-4">
                                            <b>{{ $t('profile.administration.navigation.fields.navigation') }}</b>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <b>{{ $t('profile.administration.navigation.fields.report') }}</b>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <b>{{ $t('profile.administration.navigation.fields.collection') }}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="memberStructure.mainMember"
                                class="row mb-small"
                                data-qa="member-entry"
                            >
                                <div class="col-12 col-md-6">
                                    <b>{{ memberStructure.mainMember?.displayName || '' }} ({{ memberStructure.mainMember?.memberId || '' }})</b><br>
                                    <span class="text-small">{{ memberStructure.mainMember?.address || '' }}</span>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="row mb-small">
                                        <div class="col-12 col-md-4">
                                            <Checkbox
                                                id="navigationSelected-mainMember"
                                                v-model="memberStructure.mainMember.navigationSelected"
                                                name="navigationSelected-mainMember"
                                                label=""
                                                data-qa="selfcare-superuseradmindata-checkbox-navigation"
                                                :disabled="submitting"
                                            />
                                            <span class="only-mobile">{{ $t('profile.administration.navigation.fields.navigation') }}</span>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <Checkbox
                                                id="reportSelected-mainMember"
                                                v-model="memberStructure.mainMember.reportSelected"
                                                name="reportSelected-mainMember"
                                                label=""
                                                data-qa="selfcare-superuseradmindata-checkbox-inquiry"
                                                :disabled="submitting"
                                            />
                                            <span class="only-mobile">{{ $t('profile.administration.navigation.fields.report') }}</span>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <Checkbox
                                                id="collectionSelected-mainMember"
                                                v-model="memberStructure.mainMember.collectionSelected"
                                                name="collectionSelected-mainMember"
                                                label=""
                                                data-qa="selfcare-superuseradmindata-checkbox-debt"
                                                :disabled="submitting"
                                            />
                                            <span class="only-mobile">{{ $t('profile.administration.navigation.fields.collection') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-if="memberStructure.subMembers">
                                <div
                                    v-for="(subMember, index) in memberStructure.subMembers"
                                    :key="index"
                                    class="row mb-small"
                                    data-qa="member-entry"
                                >
                                    <div class="col-12 col-md-6">
                                        <b>{{ subMember.displayName }} ({{ subMember.memberId }})</b><br>
                                        <span class="text-small">{{ subMember.address }}</span>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="row mb-small">
                                            <div class="col-12 col-md-4">
                                                <Checkbox
                                                    :id="`navigationSelected-subMember-${index}`"
                                                    v-model="subMember.navigationSelected"
                                                    :name="`navigationSelected-subMember-${index}`"
                                                    label=""
                                                    data-qa="selfcare-superuseradmindata-checkbox-navigation"
                                                    :disabled="submitting"
                                                />
                                                <span class="only-mobile">{{ $t('profile.administration.navigation.fields.navigation') }}</span>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <Checkbox
                                                    :id="`reportSelected-subMember-${index}`"
                                                    v-model="subMember.reportSelected"
                                                    :name="`reportSelected-subMember-${index}`"
                                                    label=""
                                                    data-qa="selfcare-superuseradmindata-checkbox-inquiry"
                                                    :disabled="submitting"
                                                />
                                                <span class="only-mobile">{{ $t('profile.administration.navigation.fields.report') }}</span>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <Checkbox
                                                    :id="`collectionSelected-subMember-${index}`"
                                                    v-model="subMember.collectionSelected"
                                                    :name="`collectionSelected-subMember-${index}`"
                                                    label=""
                                                    data-qa="selfcare-superuseradmindata-checkbox-debt"
                                                    :disabled="submitting"
                                                />
                                                <span class="only-mobile">{{ $t('profile.administration.navigation.fields.collection') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="row">
                                <div class="col-12 col-md-6 offset-md-6">
                                    <button
                                        type="submit"
                                        class="btn btn-default"
                                        :disabled="!initialDataWasTouched || submitting"
                                        data-qa="selfcare-superuseradmindata-save-button"
                                    >
                                        {{ $t('profile.administration.navigation.submit') }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            v-if="modal"
            :id="modal.id"
            :title="modal.title"
            :confirm-text="modal.confirmText"
            :icon="modal.icon"
            :data-qa="modal.dataQa"
            @on-confirm="modal = undefined"
            @on-close="modal = undefined"
        >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="modal.content" />
        </Modal>
        <Modal
            v-if="invalidModal"
            :id="invalidModal.id"
            :title="invalidModal.title"
            :confirm-text="invalidModal.confirmText"
            :cancel-text="invalidModal.cancelText"
            :icon="invalidModal.icon"
            :data-qa="invalidModal.dataQa"
            @on-confirm="submitAnyway()"
            @on-close="invalidModal = undefined"
        >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="invalidModal.content" />
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import ChapterLinks from '@/components/Profile/partials/ChapterLinks.vue'
import Checkbox from '@/components/Input/Checkbox.vue'
import LoadingOverlay from '@/components/LoadingRing.vue'
import Modal from '@/components/Modal.vue'

import {
    getCbraMemberStructure,
    updateCbraMemberStructure
} from '@/services'

import type {
    CbraMemberStructure,
    CbraMemberUpdateStructure
} from '@/types/cbra/memberStructure'

import type { ModalProps } from '@/types/components/modal'

const { t } = useI18n()

const memberStructure = ref<CbraMemberStructure>({})
const initialData = ref<CbraMemberStructure>({})

const chapter = [
    {
        i18nKey: 'profile.administration.navigation.title',
        href: '#superuseradmindata-navigation'
    }
]

let error = ref(false)
let loading = ref(true)
let submitting = ref(false)

const modal = ref<ModalProps>()
const invalidModal = ref<ModalProps>()
const ignoreInvalidConfig = ref(false)

const hasMembers = computed(() => {
    return memberStructure.value.mainMember || memberStructure.value.subMembers
})

const initialDataWasTouched = computed(() => {
    return JSON.stringify(initialData.value) !== JSON.stringify(memberStructure.value)
})

async function loadMemberStructure() {
    loading.value = true
    ignoreInvalidConfig.value = false

    try {
        const { status, data } = await getCbraMemberStructure()
        if (status === 200) {
            memberStructure.value = data
            initialData.value = JSON.parse(JSON.stringify(data))
        }
    } catch (err) {
        error.value = true
        console.log(err)
    }

    loading.value = false
}

const showInvalidConfigModal = () => {
    invalidModal.value = {
        id: 'invalidModal',
        title: t('selfcare.show.section.administration.infomessage.title'),
        content: t('selfcare.show.section.administration.infomessage.description'),
        confirmText: t('selfcare.show.section.administration.infomessage.continue'),
        cancelText: t('selfcare.show.section.administration.infomessage.abort')
    }
}

const submitAnyway = () => {
    invalidModal.value = undefined
    ignoreInvalidConfig.value = true
    onSubmit(null)
}

const onSubmit = async(event: Event) => {
    event !== null && event.preventDefault()
    submitting.value = true
    let countSelectedMembers = 0

    let payload: CbraMemberUpdateStructure = {}
    if (memberStructure.value.mainMember) {
        if (memberStructure.value.mainMember.navigationSelected) {
            countSelectedMembers++
        }
        payload.mainMember = {
            memberId: memberStructure.value.mainMember.memberId,
            navigationSelected: memberStructure.value.mainMember.navigationSelected,
            reportSelected: memberStructure.value.mainMember.reportSelected,
            collectionSelected: memberStructure.value.mainMember.collectionSelected
        }
    }
    if (memberStructure.value.subMembers) {
        payload.subMembers = []
        for (const subMember of memberStructure.value.subMembers) {
            if (subMember.navigationSelected) {
                countSelectedMembers++
            }
            payload.subMembers.push({
                memberId: subMember.memberId,
                navigationSelected: subMember.navigationSelected,
                reportSelected: subMember.reportSelected,
                collectionSelected: subMember.collectionSelected
            })
        }
    }

    if (countSelectedMembers < 2 && ignoreInvalidConfig.value === false) {
        showInvalidConfigModal()
        submitting.value = false
        return
    }

    const _modal = {
        id: 'modal',
        title: t('profile.administration.navigation.modal.success.title'),
        content: t('profile.administration.navigation.modal.success.content'),
        confirmText: t('profile.administration.navigation.modal.success.btn'),
        icon: 'info'
    }

    try {
        const { status } = await updateCbraMemberStructure(payload)
        if (status !== 200) {
            _modal.title = t('profile.administration.navigation.modal.error.title')
            _modal.content = t('profile.administration.navigation.modal.error.content')
            _modal.icon = 'error'
        }
        await loadMemberStructure()
    } catch (err) {
        console.log(err)
        _modal.title = t('profile.administration.navigation.modal.error.title')
        _modal.content = t('profile.administration.navigation.modal.error.content')
        _modal.icon = 'error'
    }

    submitting.value = false

    modal.value = _modal
}

onMounted(loadMemberStructure)
</script>

<style scoped>
@media screen and (max-width: 767px) {
    .crefo-input {
        display: contents;
    }
    .only-mobile {
        margin-left: 45px;
    }
}

@media only screen and (min-width: 768px) {
    .crefo-input {
        margin-top: -15px;
    }

    .only-mobile {
        display: none;
    }
}
</style>
