<template>
    <div
        :id="id"
        class="anchor-links-container"
    >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <nav
                        ref="scrollContainer"
                        :class="{
                            'justify-content-between': justifyContent === 'space-between',
                            'justify-content-left': justifyContent === '',
                        }"
                        @scroll="setFadeOutEffect"
                    >
                        <a
                            v-for="(link, index) of displayLinks"
                            :id="id + '.anchor-' + index"
                            :key="id + '.anchor-' + index"
                            :class="{ active: activeLink === link.id }"
                            :data-qa="link.dataQa"
                            href="#"
                            @click.prevent="changeSelected(link)"
                        >
                            <!-- eslint-disable vue/no-v-html -->
                            <span 
                                v-if="link.html" 
                                v-html="link.html" 
                            />
                            <span v-else>
                                {{ link.text ? link.text : $t(link.i18nKey) }}
                            </span>
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, PropType, ref, watch } from 'vue'

export type AnchorLink = {
    id: string
    i18nKey: string
    text?: string
    html?: string
    url?: string
    dataQa?: string
    show?: boolean
    component?: Object
}

const props = defineProps({
    id: {
        type: String,
        required: true
    },
    links: {
        type: Array as PropType<Array<AnchorLink>>,
        required: true
    },
    selectedLink: {
        type: String,
        default: '',
        required: false
    },
    show: {
        type: Boolean,
        default: true,
        required: false
    },
    justifyContent: {
        type: String,
        default: 'space-between',
        required: false
    }
})

/* NAVIGATION LINKS */
let activeLink = ref(props.selectedLink)
let displayLinks = computed(() => {
    return props.links.filter(link => {
        return link.show !== false
    })
})
const emit = defineEmits(['changeSelected'])
function changeSelected(link: AnchorLink) {
    activeLink.value = link.id
    emit('changeSelected', link)
}

watch(() => props.selectedLink, (newValue) => {
    activeLink.value = newValue
})

/* HORIZONTAL FADE-OUT EFFECT FOR SCROLLABLE ELEMENT */
let scrollContainer = ref<HTMLElement>()
function setFadeOutEffect() {
    const element: HTMLElement | undefined = scrollContainer.value
    if (!element) {
        return
    }

    const scrollableWidth = element.scrollWidth
    const visibleWidth = element.clientWidth
    const scrollPosition = element.scrollLeft

    const hasOverflow = scrollableWidth > visibleWidth
    const isScrolledToLeft = (scrollPosition === 0)
    const isScrolledToRight = (scrollableWidth - visibleWidth === scrollPosition)

    if (hasOverflow) {
        element.classList.add('masked-overflow')
        if (isScrolledToLeft) {
            element.classList.add('scrolled-left')
            element.classList.remove('scrolled-right')
        } else if (isScrolledToRight) {
            element.classList.add('scrolled-right')
            element.classList.remove('scrolled-left')
        } else {
            element.classList.remove('scrolled-left', 'scrolled-right')
        }
    } else {
        element.classList.remove('masked-overflow')
    }
}
onMounted(() => {
    window.addEventListener('resize', setFadeOutEffect)
    setFadeOutEffect()
})
onUnmounted(() => {
    window.removeEventListener('resize', setFadeOutEffect)
})
</script>

<style scoped lang="less">
.anchor-links-container {
    border-bottom: 1px solid var(--color-c-linegrey);
    margin-bottom: 0;
}

nav {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;

    &.justify-content-left {
        justify-content: flex-start;

        a:not(:last-child) {
            margin-right: 20px;
        }
    }

    &.justify-content-between {
        justify-content: space-between;
    }

    // &::-webkit-scrollbar {
    //     display: none;
    // }
    // -ms-overflow-style: none;
    // scrollbar-width: none;

    &.masked-overflow {
        --mask-width: 48px;
        --mask-image-content: linear-gradient(
            to right,
            transparent,
            black var(--mask-width),
            black calc(100% - var(--mask-width)),
            transparent
        );
        --mask-size-content: 100% 100%;

        mask-image: var(--mask-image-content);
        mask-size: var(--mask-size-content);
        mask-position: 0 0, 100% 0;
        mask-repeat: no-repeat, no-repeat;

        -webkit-mask-image: var(--mask-image-content);
        -webkit-mask-size: var(--mask-size-content);
        -webkit-mask-position: 0 0, 100% 0;
        -webkit-mask-repeat: no-repeat, no-repeat;
    }

    &.scrolled-left {
        --mask-image-content: linear-gradient(
            to right,
            black,
            black var(--mask-width),
            black calc(100% - var(--mask-width)),
            transparent
        );
    }

    &.scrolled-right {
        --mask-image-content: linear-gradient(
            to right,
            transparent,
            black var(--mask-width),
            black calc(100% - var(--mask-width)),
            black
        );
    }

    a {
        &.active {
            color: var(--color-c-s3);
            border-bottom: 5px solid var(--color-c-p1);
        }

        padding: 0 7px 5px 7px;
        font-weight: bold;
    }
}
</style>
