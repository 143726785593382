import { ComposerTranslation } from 'vue-i18n'
import { string, coerce, date } from 'zod'

export const REGEXES = {
    numOnly: /^\d+$/,
    vatNo: /^[\w\s-]{2,40}$/,
    taxNo: /^[\w\s+-//]{4,40}$/,
    houseNumberPlusSuffix: /(?=^[1-9][0-9]{0,4}((-| - )[1-9][0-9]{0,4})?( ?[a-zA-Z]{1,8}((-| - )[a-zA-Z]{1,7})?)?$)(?=^[0-9]{0,5}.{0,8}$)/,
    phoneSubscriberNumberWithExtension: /^[0-9]{1,15}(-[0-9]{1,15})?$/,
    percentString: /^((100)|(\d{1,2}))$/,
    internetAddress: /[-a-zA-Z0-9]{1,255}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9]*)?/gi,
    areaNummer: /^[0-9+]{0,}$/,
    houseNumber: /^[1-9][0-9]{0,4}(\s|,|-|\.|\/|[0-9]|[a-zA-Z]){0,10}$/,
    zipCode: /^[a-zA-Z0-9 ]*$/g,
    currencyDE: /^(\d{1,3}(\.\d{3})*|(\d+))(,\d{2})?$/,
    currencyFR: /^(\d{1,3}(\s{1}\d{3})*|(\d+))(,\d{2})?$/,
    currencyEN: /^(\d{1,3}(,\d{3})*|(\d+))(\.\d{2})?$/,
    currencyEN2: /^\d+(,\d{3})*(\.\d{1,2})?$/,
    smallerThanAMillion: /^(\d{1,6})$/,
    nonEmptyString: /^(?!\s*$).+/
}

export function REQUIRED_STRING_VALIDATION(t: ComposerTranslation) {
    return string({ required_error: t('error.form.required'), invalid_type_error: t('error.form.pattern') }).min(1, { message: t('error.form.required') }).regex(REGEXES.nonEmptyString, { message: t('error.form.required'), })
}
export function REQUIRED_NUMBER_VALIDATION(t: ComposerTranslation) {
    return coerce.number().min(1, { message: t('error.form.required') })
}
export function STRING_ONLY_NUMBERS(t: ComposerTranslation) {
    return string({ required_error: t('error.form.required'), invalid_type_error: t('error.form.pattern') }).regex(REGEXES.numOnly, { message: t('error.form.mustNumber') })
}
export function REQUIRED_STRING_ONLY_NUMBERS(t: ComposerTranslation) {
    return REQUIRED_STRING_VALIDATION(t).regex(REGEXES.numOnly, { message: t('error.form.mustNumber') })
}

export function EMAIL_VALIDATION(t: ComposerTranslation) {
    return string({ required_error: t('error.form.required'), invalid_type_error: t('error.form.pattern') }).email({ message: t('errors.noValidEmail') })
}

export function PERCENT_VALIDATION(t: ComposerTranslation) {
    return coerce.number().min(0, { message: t('errors.min0') }).max(100, { message: t('errors.max100') })
}
export function PERCENT_STRING_VALIDATION(t: ComposerTranslation) {
    return coerce.string({ required_error: t('error.form.required'), invalid_type_error: t('error.form.pattern') })
        .regex(REGEXES.numOnly, { message: t('error.form.mustNumber') })
        .regex(REGEXES.percentString, { message: t('errors.percent') })
}

export function VAT_VALIDATION(t: ComposerTranslation) {
    return string({ required_error: t('error.form.required'), invalid_type_error: t('error.form.pattern') }).regex(REGEXES.vatNo, { message: t('selfcare.show.section.unternehmensdaten.steuerdaten.umsatzsteuerId.error') })
}
export function TAXNO_VALIDATION(t: ComposerTranslation) {
    return string({ required_error: t('error.form.required'), invalid_type_error: t('error.form.pattern') }).regex(REGEXES.taxNo, { message: t('selfcare.show.section.unternehmensdaten.steuerdaten.steuernummer.error') })
}
export function HOUSENUMBER_VALIDATION(t: ComposerTranslation) {
    return string({ required_error: t('errors.required') }).regex(/(?=^[1-9][0-9]{0,4}((-| - )[1-9][0-9]{0,4})?( ?[a-zA-Z]{1,8}((-| - )[a-zA-Z]{1,7})?)?$)(?=^[0-9]{0,5}.{0,8}$)/, { message: t('error.form.pattern') })
}
export function IBAN_VALIDATION(t: ComposerTranslation) {
    return string().max(34, { message: t('errors.maxLength', { max: 34 }) }).regex(/^[A-Z0-9]*$/, { message: t('error.form.iban.syntax') })
}
export function BIC_VALIDATION(t: ComposerTranslation) {
    return string().max(11, { message: t('errors.maxLength', { max: 11 }) }).regex(/^[A-Z0-9]*$/, { message: t('error.form.bic.syntax') })
}

export function DATE(t: ComposerTranslation) {
    return date({
        errorMap: (issue, { defaultError }) => ({
            message: issue.code === 'invalid_date' ? t('errors.invalidDate') : defaultError,
        }),
    })
}
