<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'
import Accordion from '@/components/Accordion/Accordion.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'

const id = 'portal.mc.help.search'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>Suche in Meine Creditreform</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c2751"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-2751"
                    >
                        <div
                            id="toc2751"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-2752"
                                        class="toc__"
                                        href="/hilfe/suche-in-meine-creditreform/#c2752"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2753"
                                                href="/hilfe/suche-in-meine-creditreform/#c2753"
                                                @click.prevent="scrollTo('#c2753')"
                                            >
                                                {{ $t('helpSearch.sections.1.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2765"
                                                href="/hilfe/suche-in-meine-creditreform/#c2765"
                                                @click.prevent="scrollTo('#c2765')"
                                            >
                                                {{ $t('helpSearch.sections.2.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2766"
                                                href="/hilfe/suche-in-meine-creditreform/#c2766"
                                                @click.prevent="scrollTo('#c2766')"
                                            >
                                                {{ $t('helpSearch.sections.3.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-3549"
                                                href="/hilfe/suche-in-meine-creditreform/#c3549"
                                                @click.prevent="scrollTo('#c3549')"
                                            >
                                                {{ $t('helpSearch.sections.4.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2769"
                                                href="/hilfe/suche-in-meine-creditreform/#c2769"
                                                @click.prevent="scrollTo('#c2769')"
                                            >
                                                {{ $t('helpSearch.sections.5.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2770"
                                                href="/hilfe/suche-in-meine-creditreform/#c2770"
                                                @click.prevent="scrollTo('#c2770')"
                                            >
                                                {{ $t('helpSearch.sections.6.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2768"
                                                href="/hilfe/suche-in-meine-creditreform/#c2768"
                                                @click.prevent="scrollTo('#c2768')"
                                            >
                                                {{ $t('helpSearch.sections.7.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-2751"
                                        class="toc__"
                                        href="/hilfe/suche-in-meine-creditreform/#c2751"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c2752"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-2752"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />

                                        <div
                                            id="c2753"
                                            class="ce-box   box"
                                            data-qa="ce-2753"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpSearch.sections.1.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/search/firmenwissen-suche.jpeg"
                                                                    width="488"
                                                                    height="325"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-html="$t('helpSearch.sections.1.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c2765"
                                            class="ce-box   box"
                                            data-qa="ce-2765"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpSearch.sections.2.title') }}</h3>
                                                </div>

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/search/einfeldsuche.png"
                                                                    width="400"
                                                                    height="300"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-html="$t('helpSearch.sections.2.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c2766"
                                            class="ce-box   box"
                                            data-qa="ce-2766"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpSearch.sections.3.title') }}</h3>
                                                </div>
                                            
                                                <div v-html="$t('helpSearch.sections.3.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c3549"
                                            class="ce-box   box"
                                            data-qa="ce-3549"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpSearch.sections.4.title') }}</h3>
                                                </div>

                                                <div v-html="$t('helpSearch.sections.4.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c2769"
                                            class="ce-box   box"
                                            data-qa="ce-2769"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpSearch.sections.5.title') }}</h3>
                                                </div>

                                                <div v-html="$t('helpSearch.sections.5.text')" />
                                            </div>
                                        </div>

                                        <div
                                            id="c2770"
                                            class=" clearfix grid-box grid-box--accordionContainer box"
                                            data-qa="grid-box-2770"
                                        >
                                            <div class="ce-header">
                                                <h3>{{ $t('helpSearch.sections.6.title') }}</h3>
                                            </div>

                                            <Accordion id="accordion2770">
                                                <AccordionItem
                                                    :item-key="1"
                                                    :title="$t('helpSearch.sections.6.accordion.1.title')"
                                                >
                                                    <div
                                                        id="c2773"
                                                        data-qa="ce-2773"
                                                    >
                                                        <div v-html="$t('helpSearch.sections.6.accordion.1.text')" />
                                                    </div>
                                                </AccordionItem>
                                                <AccordionItem
                                                    :item-key="2"
                                                    :title="$t('helpSearch.sections.6.accordion.2.title')"
                                                >
                                                    <div
                                                        id="c2774"
                                                        data-qa="ce-2774"
                                                    >
                                                        <div v-html="$t('helpSearch.sections.6.accordion.2.text')" />
                                                    </div>
                                                </AccordionItem>
                                            </Accordion>
                                        </div>

                                        <div
                                            id="c2768"
                                            class="ce-box   box"
                                            data-qa="ce-2768"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('helpSearch.sections.7.title') }}</h3>
                                                </div>

                                                <div v-html="$t('helpSearch.sections.7.text')" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding-bottom: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic {
    display: block;
    overflow: hidden;
    &.ce-textpic--right {
        &.ce-textpic--intext .ce-textpic__media {
            max-width: 100%;
        }
        .ce-textpic__gallery {
            float: right;
            max-width: 40%;
            @media (max-width: 1199px) {
                float: none;
                max-width: 100%;
            }
            .ce-textpic__media {
                float: right;
                padding-left: 20px;
                padding-bottom: 20px;
                @media (max-width: 1199px) {
                    padding: 0 0 2rem;
                    float: none;
                }
            }
        }
    }
}

.blue-italic {
    color: var(--color-c-s1);
}
</style>
