<script lang="ts" setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useForm, useIsFormValid } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { object, string, literal, ZodNullable, ZodString } from 'zod'

import Textarea from '@/components/Input/Textarea.vue'
import ChapterLinks from '@/components/Profile/partials/ChapterLinks.vue'
import DatePicker from '@/components/Input/DatePicker.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import CompanyDataBox from '@/components/Profile/partials/Memberdata/CompanyDataBox.vue'
import AddressDataBox from '@/components/Profile/partials/Memberdata/AddressDataBox.vue'
import InvoiceDataBox from '@/components/Profile/partials/Memberdata/InvoiceDataBox.vue'
import BankDataBox from '@/components/Profile/partials/Memberdata/BankDataBox.vue'
import Card from '@/components/Card.vue'
import Modal from '@/components/Modal.vue'

import { scrollTo } from '@/helper/scrollto'
import { PAGE_FUNCTIONS, trackClick } from '@/helper/webtrekk'
import { useScrollToFormError } from '@/helper/forms'
import {
    BIC_VALIDATION, IBAN_VALIDATION, 
    REQUIRED_STRING_ONLY_NUMBERS, REQUIRED_STRING_VALIDATION,
    REGEXES
} from '@/helper/zodSchema'
import {
    isSameMember,
    getMemberNavigationSession
} from '@/helper/services/memberNavigation'

import { useMembersStore } from '@/stores/members'
import { useUserStore } from '@/stores/user'

import router from '@/router'

import type { ModalProps } from '@/types/components/modal'
import { CbraMemberAddress } from '@/types/cbra/member'

const { t } = useI18n()
const membersStore = useMembersStore()
const userStore = useUserStore()

const chapter = [
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.firmierung',
        href: '#memberdata-companyName'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.adresse',
        href: '#memberdata-address'
    },
    {
        i18nKey: 'selfcare.show.section.mitgliedsdaten.rechnungsadresse',
        href: '#memberdata-invoiceaddress'
    },
    {
        i18nKey: 'selfcare.show.section.unternehmensdaten.bankverbindung',
        href: '#memberdata-bankData'
    }
]

const loading = ref(true)
const submitting = ref(false)
const error = ref(false)
const errorAlert = ref<ModalProps>()
const modal = ref<ModalProps>()
const isAllowedToSave = ref(false)
userStore.getCbraUser().then(() => {
    isAllowedToSave.value = !(!isSameMember(userStore.cbraUser.crMembernumber) && getMemberNavigationSession())
})

const onErrorConfirm = () => {
    errorAlert.value = undefined
    router.push({ name: 'profile', params: { section: 'einstellungen' } })
}

const formValidationSchema = computed(() => {
    type Ruleset = {
        companyName: ZodString | ZodNullable<ZodString>
        givenName: ZodString | ZodNullable<ZodString>
        nachname: ZodString | ZodNullable<ZodString>
        birthNameInput: ZodString | ZodNullable<ZodString>
        street: ZodString | ZodNullable<ZodString>
        houseNumber: ZodString | ZodNullable<ZodString>
        zipCode: ZodString | ZodNullable<ZodString>
        city: ZodString | ZodNullable<ZodString>
        country: ZodString | ZodNullable<ZodString>
        postOfficeBoxNumber: ZodString | ZodNullable<ZodString>
        invoiceStreet: ZodString | ZodNullable<ZodString>
        invoiceHouseNumber: ZodString | ZodNullable<ZodString>
        invoiceZipCode: ZodString | ZodNullable<ZodString>
        invoiceCity: ZodString | ZodNullable<ZodString>
        invoiceCountry: ZodString | ZodNullable<ZodString>
        invoicePostOfficeBoxNumber: ZodString | ZodNullable<ZodString>
        iban: ZodString | ZodNullable<ZodString>
        bic: ZodString | ZodNullable<ZodString>
        addresstype: ZodString
        invoiceAddresstype: ZodString
    }
    let zodObject = <Ruleset>{}
    if (membersStore.dirtyCompany) {
        if (membersStore.updatePayload.naturalPerson) {
            zodObject.givenName = REQUIRED_STRING_VALIDATION(t).min(2, { message: t('errors.minLength', { min: 2 }) }).max(30, { message: t('errors.maxLength', { max: 30 }) }).refine(s => s.replace(/\s/g, '') !== '', t('error.form.required'))
            zodObject.nachname = REQUIRED_STRING_VALIDATION(t).min(2, { message: t('errors.minLength', { min: 2 }) }).max(30, { message: t('errors.maxLength', { max: 30 }) }).refine(s => s.replace(/\s/g, '') !== '', t('error.form.required'))
            zodObject.birthNameInput = string().min(2, { message: t('errors.minLength', { min: 2 }) }).max(30, { message: t('errors.maxLength', { max: 30 }) }).or(literal('')).nullish()
        } else {
            zodObject.companyName = REQUIRED_STRING_VALIDATION(t).min(2, { message: t('errors.minLength', { min: 2 }) }).max(30, { message: t('errors.maxLength', { max: 30 }) })
        }
    }
    if (membersStore.dirtyAddress && membersStore.updatePayload.memberAddress) {
        if (membersStore.updatePayload.memberAddress.type === null) {
            zodObject.addresstype = string({ required_error: t('error.form.required'), invalid_type_error: t('error.form.required') }).min(1, { message: t('error.form.required') })
        }
        if (membersStore.updatePayload.memberAddress.type === 'POST_OFFICE_BOX') {
            zodObject.postOfficeBoxNumber = REQUIRED_STRING_ONLY_NUMBERS(t).max(10, { message: t('errors.maxLength', { max: 10 }) })
        }
        if (membersStore.updatePayload.memberAddress.type === 'DELIVERY_ADDRESS') {
            zodObject.street = REQUIRED_STRING_VALIDATION(t).max(46, { message: t('errors.maxLength', { max: 46 }) }).refine(s => s.replace(/\s/g, '') !== '', t('error.form.required'))
            zodObject.houseNumber = REQUIRED_STRING_VALIDATION(t).max(13, { message: t('errors.maxLength', { max: 13 }) }).regex(REGEXES.houseNumberPlusSuffix, { message: t('error.form.pattern') })
        }
        zodObject.zipCode = generateZipCodeValidation(membersStore.updatePayload.memberAddress)
        zodObject.city = REQUIRED_STRING_VALIDATION(t).max(40, { message: t('errors.maxLength', { max: 40 }) }).refine(s => s.replace(/\s/g, '') !== '', t('error.form.required'))
        zodObject.country = REQUIRED_STRING_VALIDATION(t)
    }
    if (membersStore.dirtyInvoice && membersStore.updatePayload.invoiceAddress) {
        if (membersStore.updatePayload.invoiceAddress.type === null) {
            zodObject.invoiceAddresstype = string({ required_error: t('error.form.required'), invalid_type_error: t('error.form.required') }).min(1, { message: t('error.form.required') })
        }
        if (membersStore.updatePayload.invoiceAddress.type === 'POST_OFFICE_BOX') {
            zodObject.invoicePostOfficeBoxNumber = REQUIRED_STRING_ONLY_NUMBERS(t).max(10, { message: t('errors.maxLength', { max: 10 }) })
        }
        if (membersStore.updatePayload.invoiceAddress.type === 'DELIVERY_ADDRESS') {
            zodObject.invoiceStreet = REQUIRED_STRING_VALIDATION(t).max(46, { message: t('errors.maxLength', { max: 46 }) }).refine(s => s.replace(/\s/g, '') !== '', t('error.form.required'))
            zodObject.invoiceHouseNumber = REQUIRED_STRING_VALIDATION(t).max(13, { message: t('errors.maxLength', { max: 13 }) }).regex(REGEXES.houseNumberPlusSuffix, { message: t('error.form.pattern') })
        }
        zodObject.invoiceZipCode = generateZipCodeValidation(membersStore.updatePayload.invoiceAddress)
        zodObject.invoiceCity = REQUIRED_STRING_VALIDATION(t).max(40, { message: t('errors.maxLength', { max: 40 }) }).refine(s => s.replace(/\s/g, '') !== '', t('error.form.required'))
        zodObject.invoiceCountry = REQUIRED_STRING_VALIDATION(t)
    }
    zodObject.iban = IBAN_VALIDATION(t)
    zodObject.bic = BIC_VALIDATION(t)
    let memberValidationSchema = object(zodObject)
    return toTypedSchema(memberValidationSchema)
})

const { meta, validate } = useForm({
    validationSchema: formValidationSchema
})
const isValid = useIsFormValid()

watch(() => formValidationSchema, () => {
    validate()
})

function generateZipCodeValidation(address: CbraMemberAddress) {
    if (
        address.country !== 'DE' &&
        address.country !== 'AT' &&
        address.country !== 'LU'
    ) {
        return REQUIRED_STRING_ONLY_NUMBERS(t)
    }

    let len = 5
    if (
        address.country === 'AT' ||
        address.country === 'LU'
    ) {
        len = 4
    }

    return REQUIRED_STRING_ONLY_NUMBERS(t).min(len, {
        message: t('error.form.zipCode')
            .replace('%1$s', t(`enums.countries.${address.country}`))
            .replace('%2$s', len.toString())
    }).max(len, {
        message: t('error.form.zipCode')
            .replace('%1$s', t(`enums.countries.${address.country}`))
            .replace('%2$s', len.toString())
    })
}

async function onSubmit() {
    const result = await validate()
    console.log('validation result', result)
    if (!result.valid) {
        useScrollToFormError(result.errors)
        return
    }

    let _modal = {
        title: t('selfcare.save.success.title'),
        content: `<p data-qa="selfcare-save-success-description">${t('selfcare.save.success.description')}</p>`,
        confirmText: t('memberData.modal.confirm'),
        icon: 'info',
        dataQa: 'selfcare-memberData-save-success-message'
    }
    submitting.value = true
    await membersStore.updateMember()
    submitting.value = false
    if (membersStore.error != '') {
        _modal = {
            title: t('selfcare.save.error.validation.title'),
            content: `<p>${t('selfcare.save.error.validation.description')}</p>`,
            confirmText: t('memberData.modal.confirm'),
            icon: 'error',
            dataQa: 'selfcare-memberData-save-error-message'
        }
    }

    modal.value = _modal

    trackClick(PAGE_FUNCTIONS.SELFCARE + '.memberData.save', PAGE_FUNCTIONS.SELFCARE, 'save.memberData.click')
}

function onModalConfirm() {
    modal.value = undefined
    scrollTo('.scs-mc')
}

onMounted(async() => {
    const member = await membersStore.getMember()
    if (member !== true && (member.name && member.name === 'AxiosError' || member === false)) {
        error.value = true
        errorAlert.value = {
            id: 'userrights-not-granted',
            title: t('error.userrights.saveUnauthorized'),
            content: t('error.userrights.saveUnauthorized.description'),
            icon: 'error',
            dataQa: 'userrights-not-granted'
        }
    }
    loading.value = false
})
</script>

<template>
    <div
        class="container content-spacer"
        data-component="memberdata"
    >
        <LoadingOverlay v-if="loading" />

        <Modal
            v-if="errorAlert"
            :id="errorAlert.id"
            :title="errorAlert.title"
            :confirm-text="$t('shared.modals.ok')"
            :error-context="errorAlert.errorContext"
            :icon="errorAlert.icon"
            :data-qa="errorAlert.dataQa"
            @on-confirm="onErrorConfirm"
            @on-close="onErrorConfirm"
        >
            <p>{{ errorAlert.content }}</p>
        </Modal>

        <Modal
            v-if="modal"
            :id="modal.id"
            :title="modal.title"
            :confirm-text="modal.confirmText"
            :icon="modal.icon"
            :data-qa="modal.dataQa"
            @on-confirm="onModalConfirm"
            @on-close="onModalConfirm"
        >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="modal.content" />
        </Modal>

        <div
            v-if="!loading && error"
            class="crefo-ui-alert error mb"
        >
            {{ $t('globalMisc.error.errorLoadingData.message') }}
        </div>
        <div
            v-else
            class="row mb-large"
        >
            <div class="col-12 col-lg-3 mb">
                <div class="box-shadow-xy bg-white chapter-links-box">
                    <div class="box-spacing">
                        <ChapterLinks 
                            section="Mitgliedsdaten"
                            :links="chapter" 
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-9 mb-large">
                <form
                    id="selfcare-member-form"
                    :class="{ 'has-errors': !isValid && meta.dirty }"
                    @submit.prevent="onSubmit"
                >
                    <CompanyDataBox
                        :is-allowed-to-save="isAllowedToSave"
                    />
                    <AddressDataBox
                        :is-allowed-to-save="isAllowedToSave"
                    />
                    <InvoiceDataBox
                        :is-allowed-to-save="isAllowedToSave"
                    />
                    <BankDataBox
                        :is-allowed-to-save="isAllowedToSave"
                    />
                    <Card id="">
                        <p>{{ $t('selfcare.checkHint') }}</p>
                        <DatePicker
                            id="selfcare-member-validFromDate"
                            v-model="membersStore.updatePayload.validFromDate" 
                            name="selfcare-member-validFromDate"
                            data-qa="selfcare-member-validFromDate"
                            :label="$t('selfcare.show.section.mitgliedsdaten.rechnungsadresse.datum')"
                            :readonly="!isAllowedToSave"
                            :disabled="!isAllowedToSave"
                            style="margin-bottom: 15px;"
                        />
                        <Textarea
                            v-model="membersStore.updatePayload.additionalInfos"
                            :label="$t('selfcare.show.section.mitgliedsdaten.notiz')"
                            data-qa="selfcare-member-additionalInfos"
                            name="additionalInfos"
                            :placeholder="$t('selfcare.show.section.mitgliedsdaten.notiz.placeholder')"
                            :readonly="!isAllowedToSave"
                            :disabled="!isAllowedToSave"
                        />
                        <button
                            type="submit"
                            form="selfcare-member-form"
                            class="btn btn-default btn-submit loader"
                            :data-qa="`selfcare-member-submit${submitting || !isAllowedToSave ? '-disabled' : ''}`"
                            :disabled="submitting || !isAllowedToSave"
                        >
                            {{ $t('selfcare.show.section.mitgliedsdaten.speichern') }}
                        </button>
                    </Card>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>
.error {
    display: inline-block;
}
</style>
