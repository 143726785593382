import { sendClick, sendPage } from '../webtrekk'

export namespace ConsumerReportWebTrekkService {
    export function placeNewConsumerReportView(productName: string) {
        if (typeof productName !== 'string' || productName.length === 0) {
            productName = 'none'
        }

        const params = {
            product: productName,
            productId: productName,
            productQuantity: '1',
            productCost: 0,
            productStatus: 'view',
            productCategory: {
                1: 'reports',
                2: 'consumer_reports'
            },
            orderValue: 0,
            currency: 'EUR'
        }

        const customPageParameters = {
            105: 'consumer_report',
            106: 'view'
        }

        sendPage(params, customPageParameters)
    }

    export function placeNewConsumerReportOrder(productName: string, orderNumber: string) {
        const params = {
            product: productName,
            productId: productName,
            productQuantity: '1',
            productCost: 0,
            productStatus: 'conf',
            productCategory: {
                1: 'reports',
                2: 'consumer_reports'
            },
            orderId: orderNumber,
            orderValue: 0,
            currency: 'EUR',
            customEcommerceParameter: {
                102: window.location.href,
                13: productName
            }
        }

        const customPageParameters = {
            105: 'consumer_report',
            106: 'order'
        }

        sendPage(params, customPageParameters)
    }

    export function placeNewConsumerReportOrderFailed(productName: string, errorMessage: string) {
        const params = {
            product: productName,
            productId: productName,
            productQuantity: '1',
            productCost: 0,
            productStatus: 'add',
            productCategory: {
                1: 'reports',
                2: 'consumer_reports'
            },
            errorMessages: errorMessage,
            orderValue: 0,
            currency: 'EUR'
        }

        const customPageParameters = {
            105: 'consumer_report',
            106: 'error'
        }

        sendPage(params, customPageParameters)
    }

    export function placeNewConsumerReportDownloadFailed(productType: string, errorMessage: string) {
        const params = {
            product: productType,
            productId: productType,
            productQuantity: '1',
            productCost: 0,
            productStatus: 'view',
            productCategory: {
                1: 'reports',
                2: 'consumer_reports'
            },
            errorMessages: errorMessage,
            orderValue: 0,
            currency: 'EUR'
        }

        const customPageParameters = {
            105: 'consumer_report',
            106: 'downloadError'
        }

        sendPage(params, customPageParameters)
    }

    export function placeNewConsumerReportPermissionRequest(productName: string) {
        sendClick(
            'consumerReport_permissionRequest',
            { product: productName },
        )
    }

   export function placeNewConsumerReportPermissionFailure(productName: string) {
       sendClick(
           'consumerReport_permissionFailure',
           { product: productName },
       )
   }
}