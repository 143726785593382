import { sendClick } from '../webtrekk'
import { useUserStore } from '@/stores/user'

export const PAGE_FUNCTIONS = {
    SELFCARE: 'selfcare'
}

/*
 * SelfcareWebTrekkService
 */
export function placeSelfcareNavigationClick(
    area: string,
    initialLoad: boolean = false
) {
    const userStore = useUserStore()
    sendClick(
        `${PAGE_FUNCTIONS.SELFCARE}_Mainnavigation_Click`,
        {
            2: '200 OK',
            5: 'OK',
            6: PAGE_FUNCTIONS.SELFCARE,
            7: 'navigationClick',
            8: `selfcare.navigation.main.${initialLoad ? 'pageload' : 'click'}.${area.toLowerCase()}`,
            13: userStore.cbraUser.crMembernumber
        }
    )
}

export function placeSelfcareSubnavigationClick(
    mainNav: string,
    subNav: string
) {
    const subNavFormatted = transformTextForNav(subNav.toLowerCase())
    const userStore = useUserStore()
    sendClick(
        `${PAGE_FUNCTIONS.SELFCARE}_Subnavigation_Click`,
        {
            2: '200 OK',
            5: 'OK',
            6: PAGE_FUNCTIONS.SELFCARE,
            7: 'subNavigationClick',
            8: `selfcare.subnavigation.click.${mainNav.toLowerCase()}.${subNavFormatted}`,
            13: userStore.cbraUser.crMembernumber
        }
    )
}

function transformTextForNav(str: string) {
    return str.replace(/ä/ig, 'ae')
        .replace(/ö/ig, 'oe')
        .replace(/ü/ig, 'ue')
        .replace(/[^A-Z0-9]/ig, '_')
        .replace(/___/ig, '_')
        .replace(/__/ig, '_')
}
