<script setup lang="ts">
const emit = defineEmits(['goToMobileOverview', 'selectPreviousUser', 'selectNextUser'])

function goToMobileOverview() {
    emit('goToMobileOverview')
}
function selectPreviousUser() {
    emit('selectPreviousUser')
}
function selectNextUser() {
    emit('selectNextUser')
}
</script>

<template>
    <div class="row userrights__content-row-mobile">
        <div class="col-7 back-link-container">
            <a
                href="#"
                class="back-link"
                @click="goToMobileOverview"
            >
                Übersicht
            </a>
        </div>
        <div class="col-5">
            <ul class="nav-buttons">
                <li
                    class="prev"
                    @click="selectPreviousUser"
                >
                    <a
                        href="#"
                        class="link link-prev"
                    />
                </li>
                <li
                    class="next"
                    @click="selectNextUser"
                >
                    <a
                        href="#"
                        class="link link-next"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped lang="less">
.userrights__content-row-mobile {
    padding: 20px 0;

    .back-link-container {
        display: flex;
        align-items: center;
        .back-link {
            display: inline-block;
            position: relative;
            margin-left: 5px;
            padding-left: 2rem;
            color: #9b9b9b;
            &::before {
                display: block;
                position: absolute;
                content: "<";
                width: 1rem;
                height: 1rem;
                left: 0;
                top: 0;
            }
        }
    }

    .nav-buttons {
        list-style-type: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        color: #4a4a4a;
        border-radius: 4px;
        margin: 0;
        .link {
            margin-left: 4px;
            margin-right: 4px;
            border-radius: 0!important;
            color: #4a4a4a;
            font-size: 14px;
            padding: 9px 15px;
            &:hover {
                z-index: 2;
                color: #23527c;
                background-color: #eee;
                border-color: #ddd;
            }
        }

        .link-prev {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            position: relative;
            float: left;
            line-height: 1.42857143;
            text-decoration: none;
            background-color: #fff;
            border: 1px solid #ddd;
            &::after {
                content: "\2329";
                color: #008dd9;
                font-weight: 700;
            }
        }

        .link-next {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            position: relative;
            margin-right: 4px;
            font-size: 14px;
            padding: 9px 15px;
            line-height: 1.42857143;
            text-decoration: none;
            background-color: #fff;
            border: 1px solid #ddd;
            &::after {
                content: "\232A";
                color: #008dd9;
                font-weight: 700;
            }
        }
    }
}
</style>