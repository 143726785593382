<template>
    <div
        v-if="transactionsStore.filterMode === 'REPORT' || transactionsStore.filterMode === 'COLLECTION'"
        class="container transactions-export"
    >
        <div
            class="row"
            style="position:relative"
        >
            <div class="col-12 dl-link">
                <DownloadLink
                    :url="transactionsStore.currentExportUrl"
                    :data-qa="`myTransactions-${transactionsStore.filterMode === 'REPORT' ? 'report' : 'collection'}-export`"
                    :view-scope="transactionsStore.isMember ? 'MEMBER' : 'USER'"
                    @click="transactionsStore.trackExportClick"
                >
                    <font-awesome-icon
                        icon="fa-light fa-file-export"
                    />
                    {{ $t(`transaction.list.${transactionsStore.filterMode === 'REPORT' ? 'reports' : 'collections'}.export`) }}
                </DownloadLink>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import DownloadLink from '@/components/DownloadLink.vue'

import { useTransactionsStore } from '@/stores/transactions'
const transactionsStore = useTransactionsStore()
</script>

<style scoped lang="less">
.dl-link {
    position: absolute;
        top: -60px;
        right: 0;
        max-width: 400px;
        text-align: right;
    a {
        svg {
            margin-right: 5px;
        }
    }
}

@media (min-width: 992px) {
        .dl-link {
            top: -30px;

    }
  }

</style>
