/**
 * Icon search: https://fontawesome.com/icons/
 */
import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faAngleDown as falAngleDown,
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faAngleUp as falAngleUp,
    faArrowUpFromBracket as falArrowUpFromBracket,
    faBuilding as falBuilding,
    faCalculator as falCalculator,
    faCalendar as falCalendar,
    faCheckCircle as falCheckCircle,
    faClipboard as falClipboard,
    faClipboardList as falClipboardList,
    faCog as falCog,
    faCommentAltEdit as falCommentAltEdit,
    faEnvelope as falEnvelope,
    faFileChartPie as falFileChartPie,
    faFileContract as falFileContract,
    faFileDownload as falFileDownload,
    faFileExport as falFileExport,
    faFileInvoice as falFileInvoice,
    faFilePlus as falFilePlus,
    faFileUpload as falFileUpload,
    faLocationDot as falLocationDot,
    faPaperPlane as falPaperPlane,
    faPencil as falPencil,
    faPhone as falPhone,
    faPlus as falPlus,
    faPrint as falPrint,
    faQuestionCircle as falQuestionCircle,
    faSlidersH as falSlidersH,
    faStream as falStream,
    faTrashAlt as falTrashAlt,
    faTrashCan as falTrashCan,
    faUser as falUser,
    faUsers as falUsers,
    faStar as falStar,
    faXmark as falXmark,
    faXmarkLarge as falXmarkLarge,
    faCircleMinus as falCircleMinus,
    faCirclePlus as falCirclePlus,
    faCalendarCircleExclamation,
    faGlobe as falGlobe,
    faTriangleExclamation as falTriangleExclamation,
    faMobileButton as faMobileButton
} from '@fortawesome/pro-light-svg-icons'

import {
    faArrowRight as farArrowRight,
    faArrowLeft as farArrowLeft,
    faArrowRightLong as farArrowRightLong,
    faCircleInfo as farCircleInfo,
    faCommentAltEdit as farCommentAltEdit,
    faEllipsisV as farEllipsisV,
    faFileDownload as farFileDownload,
    faFileUpload as farFileUpload,
    faMagnifyingGlass as farMagnifyingGlass,
    faPen as farPen,
    faSearchPlus as farSearchPlus,
    faLockKeyhole as farLockKeyhole,
    faExpand as farExpand,
    faCheck as farCheck,
    faEllipsisVertical,
    faCalculatorSimple as farCalculatorSimple,
    faShield as farShield,
    faClipboardCheck as farClipboardCheck,
    faFloppyDisk as farFloppyDisk,
} from '@fortawesome/pro-regular-svg-icons'

import {
    faStar as fasStar,
    faUser as fasUser,
    faTrashCan as fasTrashCan,
    faCaretDown,
    faCaretUp,
    faArrowDownToLine as fasArrowDownToLine,
    faLocationDot as fasLocationDot,
    faPhone as fasPhone,
    faFax as fasFax,
    faChevronRight as fasChevronRight,
} from '@fortawesome/pro-solid-svg-icons'

import {
    faChevronDown as fassChevronDown,
    faChevronLeft as fassChevronLeft,
    faChevronRight as fassChevronRight,
    faChevronUp as fassChevronUp,
    faQuoteRight as fassQuoteRight,
    faMagnifyingGlass as fassMagnifyingGlass,
} from '@fortawesome/sharp-solid-svg-icons'

import {
    faArrowDown as fasrArrowDown,
    faDownload as fasrDownload,
} from '@fortawesome/sharp-regular-svg-icons'

library.add(
    falAngleDown,
    falAngleLeft,
    falAngleRight,
    falAngleUp,
    falArrowUpFromBracket,
    falBuilding,
    falFileChartPie,
    falFileDownload,
    falFileUpload,
    falFilePlus,
    falCalculator,
    falCalendar,
    falCheckCircle,
    farCircleInfo,
    falClipboard,
    falClipboardList,
    falCog,
    falCommentAltEdit,
    falEnvelope,
    falFileContract,
    falFileExport,
    falFileInvoice,
    falLocationDot,
    falPaperPlane,
    falPencil,
    falPhone,
    falPlus,
    falPrint,
    falQuestionCircle,
    falSlidersH,
    falStream,
    falTrashAlt,
    falTrashCan,
    falUser,
    falUsers,
    falStar,
    falXmark,
    falXmarkLarge,

    farArrowRight,
    farArrowLeft,
    farArrowRightLong,
    farCommentAltEdit,
    farEllipsisV,
    farFileDownload,
    farFileUpload,
    farMagnifyingGlass,
    farPen,
    farSearchPlus,
    farLockKeyhole,
    farExpand,
    farCheck,
    faEllipsisVertical,
    farCalculatorSimple,
    farShield,
    farClipboardCheck,
    farFloppyDisk,

    fasStar,
    fasTrashCan,
    fasUser,
    fasArrowDownToLine,
    fasLocationDot,
    fasPhone,
    fasFax,
    fasChevronRight,

    faCaretDown,
    faCaretUp,
    faMobileButton,

    fassChevronDown,
    fassChevronLeft,
    fassChevronRight,
    fassChevronUp,
    fassQuoteRight,
    fassMagnifyingGlass,

    falCircleMinus,
    falCirclePlus,
    falGlobe,
    faCalendarCircleExclamation,
    falTriangleExclamation,

    fasrArrowDown,
    fasrDownload
)
