import axios from './tracing-axios'
import { AxiosHeaderValue, AxiosResponse, isAxiosError } from 'axios'
import qs from 'qs'

import type {
    CbraBusinessData,
    CbraBusinessUpdateData,
} from '@/types/cbra/businessData'
import type {
    CbraMemberStructure,
    CbraMemberUpdateStructure,
} from '@/types/cbra/memberStructure'
import type { CbraNotificationUserConfig } from '@/types/cbra/notificationUserConfig'
import type {
    CbraUserPreferences,
    CbraUserPreferencesResponse,
} from '@/types/cbra/userPreferences'
import type { CbraPermissions } from '@/types/cbra/permissions'
import type { CbraUser } from '@/types/cbra/user'
import type { CbraSearchSuggestConfiguration } from '@/types/cbra/searchConfiguration'
import type { CbraSearchSuggestionResponse } from '@/types/cbra/searchSuggestion'
import type {
    CbraSupplierInquiryParams,
    CbraSupplierInquiryResponse,
} from '@/types/cbra/supplierInquiry'
import type { CbraActivityResponse } from '@/types/cbra/activity'
import type {
    WatchlistEntryResponse,
    WatchlistValidateResponse,
} from '@/types/cbra/watchlist'
import type {
    CbraTransaction,
    CbraTransactionCountPayload,
    CbraTransactionCountResponse,
    CbraTransactionFamilyTreeResponse,
    CbraTransactionsSearchResult,
} from '@/types/cbra/transactions'
import type { CbraQualityStatsResponse } from '@/types/cbra/qualityStats'
import type { CbraMonitoringStatsResponse } from '@/types/cbra/monitoringStats'
import type { CbraLatestCollectionFileStatisticsResponse } from '@/types/cbra/latestCollectionFileStatistics'
import type { CbraEventResponse } from '@/types/cbra/event'
import type {
    CbraDashboardResponse,
    CbraDashboardSavePayload,
    CbraDashboardSaveResponse,
} from '@/types/cbra/dashboard'
import type { CbraUserVCResponse } from '@/types/cbra/vc'
import type { CbraUnreadInfoboxMessagesResponse } from '@/types/cbra/infoboxMessage'
import type { InkassoMemberInfo } from '@/types/inkasso/inkassoMemberInfo'
import type { SignalSuggestionsResponse } from '@/types/cbra/signalsuggestion'
import type { ReportOrderLegitimations } from '@/types/cbra/auskunft'
import { Storage } from '@/helper/storage'
import { getPortfolioHeader } from '@/helper/services/features'
import type {
    CbraInsuranceMatrixResponse,
    CbraWKVAvailabilityCheckResponse,
    CbraWKVOrder,
} from '@/types/cbra/WKV'
import { CustomerFeedbackPayload } from '@/types/cbra/customerFeedback'
import type { CbraProductAvailablityResponse } from '@/types/cbra/productAvailability'
import {
    BuyProductPayload,
    BuyProductResponse,
    RequestPermissionPayload,
} from '@/types/cbra/product'
import type {
    CbraAdvancedSearchCreateData,
    CbraOrderReportCreateData,
    CbraOrderReportResponse,
} from '@/types/cbra/advancedSearch'
import { differentContextActive } from '@/helper/services/memberNavigation'
import {
    CbraReportConsumerResponse,
    CbraReportHistoryResponse,
    CbraReportRequestConsumerPermissionPayload,
    CbraReportTransactionData,
} from '@/types/cbra/reports'
import {
    SimpleSearchPayload,
    SimpleSearchResults,
} from '@/types/cbra/simplesearch'

export const getCbraUser = () =>
    axios.get<CbraUser, AxiosResponse<CbraUser>, void>('/cbra/user')

export const getCbraBusinessData = () =>
    axios.get<CbraBusinessData, AxiosResponse<CbraBusinessData>, void>(
        '/cbra/businessdata',
    )
export const updateCbraBusinessData = (data: CbraBusinessUpdateData) =>
    axios.put('/cbra/businessdata/updates', data)

export const getCbraMemberStructure = (navigationSelected: boolean = false) =>
    axios.get<CbraMemberStructure, AxiosResponse<CbraMemberStructure>, void>(
        '/cbra/memberstructure' +
      (navigationSelected ? '?navigationSelected=true' : ''),
    )
export const updateCbraMemberStructure = (data: CbraMemberUpdateStructure) =>
    axios.put('/cbra/memberstructure', data)

export const getCbraPermissions = () =>
    axios.get<CbraPermissions, AxiosResponse<CbraPermissions>, void>(
        '/cbra/permissions',
    )

/**
 * Re-implements SelfcareService.getUserPreferences()
 * with cookie caching. This was done because User Preferences get
 * requested pretty often and the response is always the same.
 */
export const getCbraUserPreferences = () => {
    return new Promise<CbraUserPreferencesResponse>((resolve, reject) => {
        const url = '/cbra/userPreferences'
        const res = {
            status: 200,
            data: null,
        }
        let userPrefs: any = Storage.get(url, false)
        if (userPrefs !== null) {
            if (userPrefs.ttl && new Date(userPrefs.ttl) <= new Date()) {
                Storage.remove(url, true)
                userPrefs = null
            } else {
                res.data = userPrefs
                resolve(res)
            }
        }
        if (userPrefs === null) {
            axios
                .get<CbraUserPreferences, AxiosResponse<CbraUserPreferences>, void>(
                    '/cbra/userPreferences',
                )
                .then((response) => {
                    userPrefs = response.data
                    const ttl = new Date()
                    ttl.setMinutes(ttl.getMinutes() + 2)
                    userPrefs.ttl = ttl
                    Storage.set(url, JSON.stringify(userPrefs), false)
                    res.data = userPrefs
                    resolve(res)
                })
                .catch((error) => {
                    Storage.remove(url, false)
                    if (isAxiosError(error)) {
                        reject({
                            status: error.response ? error.response.status : error.status,
                            error: error.response ? error.response.data : error.message,
                        })
                    }
                })
        }
    })
}
export const updateCbraUserPreferences = (data: CbraUserPreferences) => {
    Storage.remove('/cbra/userPreferences', false)
    return axios.put('/cbra/userPreferences', data)
}

export const getCbraNotificationUserConfig = () =>
    axios.get<
    CbraNotificationUserConfig,
    AxiosResponse<CbraNotificationUserConfig>,
    void
  >('/cbra/notificationUserConfig')
export const updateCbraNotificationUserConfig = (
    data: CbraNotificationUserConfig,
) => axios.put('/cbra/notificationUserConfig', data)

export const getCbraSearchPreferences = () =>
    axios.get<CbraSearchPreferences, AxiosResponse<CbraSearchPreferences>, void>(
        '/cbra/searchpreferences',
    )

export const getCbraSearchSuggestConfiguration = () =>
    axios.get<
    CbraSearchSuggestConfiguration,
    AxiosResponse<CbraSearchSuggestConfiguration>,
    void
  >('/cbra/searchConfiguration')

export const getCbraTransactions = (
    params: any,
    headers?: { [key: string]: AxiosHeaderValue },
) =>
    axios.get<
    CbraTransactionsSearchResult,
    AxiosResponse<CbraTransactionsSearchResult>
  >('/cbra/transactions', {
      headers: headers,
      params: params,
  })
export const deleteCbraTransactionsByIds = (
    data: any,
    headers: { [key: string]: AxiosHeaderValue } = {},
) => axios.put('/cbra/transactions/deleteByIds', data, { headers })
export const setFavouriteCbraTransactionsById = (
    id: string,
    data: any,
    headers: { [key: string]: AxiosHeaderValue } = {},
) => axios.post(`/cbra/transactions/favourite/${id}`, data, { headers })

export const createCbraOrderReport = (
    data: CbraOrderReportCreateData,
    headers?: { [key: string]: AxiosHeaderValue },
) => axios.post('/cbra/orderreportForm', data, { headers })

export const postCbraOrderReport = (
    data: CbraOrderReportCreateData,
    headers?: { [key: string]: AxiosHeaderValue },
) =>
    axios.post<CbraOrderReportResponse, AxiosResponse<CbraOrderReportResponse>>(
        '/cbra/orderreport',
        data,
        { headers },
    )

export const sendAdvancedSearch = (data: CbraAdvancedSearchCreateData) =>
    axios.post<SimpleSearchResults, AxiosResponse<SimpleSearchResults>>(
        '/cbra/advancedsearch',
        data,
        { headers: { 'Content-Type': 'application/json' } },
    )

export const getCbraProductAvailability = (params: any) =>
    axios.get<
    CbraProductAvailablityResponse,
    AxiosResponse<CbraProductAvailablityResponse>
  >('/cbra/productavailability', { params })

export const getCbraSearchSuggestion = (params: any) =>
    axios.get<
    CbraSearchSuggestionResponse,
    AxiosResponse<CbraSearchSuggestionResponse>
  >('/cbra/suggestionssearch', { params })

export const getCbraSupplierInquiries = (params: CbraSupplierInquiryParams) =>
    axios.get<
    CbraSupplierInquiryResponse,
    AxiosResponse<CbraSupplierInquiryResponse>
  >('/cbra/supplierinquiries', {
      params: {
          status: params.status,
          pageSize: params.pageSize ?? 20,
          pageIndex: params.pageIndex ?? 1,
          orderNo: params.orderNo,
      },
  })

export const getCbraActivities = (
    params: any,
    headers?: { [key: string]: AxiosHeaderValue },
) =>
    axios.get<CbraActivityResponse, AxiosResponse<CbraActivityResponse>>(
        '/cbra/activities',
        {
            headers: headers,
            params: params,
        },
    )

export const getCbraWatchlistentries = (
    params: any,
    headers?: { [key: string]: AxiosHeaderValue },
) =>
    axios.get<WatchlistEntryResponse, AxiosResponse<WatchlistEntryResponse>>(
        '/cbra/watchlistentries/',
        {
            headers: headers,
            params: params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'brackets' })
            },
        },
    )

export const getCbraQualityStats = () =>
    axios.get<CbraQualityStatsResponse, AxiosResponse<CbraQualityStatsResponse>>(
        '/cbra/stats/quality',
    )

export const getCbraMonitoringStats = () =>
    axios.get<
    CbraMonitoringStatsResponse,
    AxiosResponse<CbraMonitoringStatsResponse>
  >('/cbra/monitoring/statistic')

export const getCbraLatestCollectionFileStatistics = () =>
    axios.get<
    CbraLatestCollectionFileStatisticsResponse,
    AxiosResponse<CbraLatestCollectionFileStatisticsResponse>
  >('/cbra/stats/latestCollectionFileStatistics')

export const getCbraEvents = (
    params: any,
    headers?: { [key: string]: AxiosHeaderValue },
) =>
    axios.get<CbraEventResponse, AxiosResponse<CbraEventResponse>>(
        '/cbra/vc/events',
        {
            headers: headers,
            params: params,
        },
    )

export const getDasboardConfig = () =>
    axios.get<CbraDashboardResponse, AxiosResponse<CbraDashboardResponse>>(
        '/cbra/widgetConfiguration/list',
    )

export const saveDashboardConfig = (data?: CbraDashboardSavePayload) =>
    axios.post<
    CbraDashboardSaveResponse,
    AxiosResponse<CbraDashboardSaveResponse>
  >('/cbra/dashboardConfiguration/update', data)

export const getCbraUserVC = () =>
    axios.get<CbraUserVCResponse, AxiosResponse<CbraUserVCResponse>>(
        '/cbra/vc/forUser',
    )

export const readCbraInfoboxMessages = (
    data: unknown,
    headers: { [key: string]: AxiosHeaderValue } = {},
) => axios.put('/cbra/user/readInfoboxMessages', data, { headers })

export const getCbraUnreadInfoboxMessages = (params: unknown) =>
    axios.get<
    CbraUnreadInfoboxMessagesResponse,
    AxiosResponse<CbraUnreadInfoboxMessagesResponse>
  >('/cbra/user/unreadInfoboxMessages', {
      params,
      paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
      },
  })

export const getInfoboxMessages = () =>
    axios.get(
        `${import.meta.env.VITE_APP_TYPO3_WEB_API_BASEURL}/infobox/messages`,
    )

export const hideCbraOnvbInfoBox = () =>
    axios.post('/cbra/userPreferences/confirmUserAgreement')

export const isIkarosAvailableForMember = () =>
    axios.get<InkassoMemberInfo, AxiosResponse<InkassoMemberInfo>>(
        '/api/inkasso/inkassoMemberInfo',
    )

export const getUnreadMessagesCount = (memberNumber: string) =>
    axios.get<number, AxiosResponse<number>>(
        `/api/posteingang/mitgliedsstruktur/unreadCount/${memberNumber}`,
    )

export const getCbraSignalSuggestions = (
    memberUserId: string,
    params: unknown,
) => {
    return axios.get<
    SignalSuggestionsResponse,
    AxiosResponse<SignalSuggestionsResponse>
  >(`/cbra/signalsuggestions/${encodeURIComponent(memberUserId)}`, {
      params,
  })
}

export const putCbraEntryOnWatchlist = (
    data: unknown,
    headers: { [key: string]: AxiosHeaderValue } = {},
) => axios.put('/cbra/watchlistentries/', data, { headers })

export const getReportOrderLegitimation = (productOrderId: string) =>
    axios.get<ReportOrderLegitimations, AxiosResponse<ReportOrderLegitimations>>(
        `/cbra/auskunft/orders/${productOrderId}/legitimations`,
        {
            headers: {
                viewScope: 'MEMBER',
            },
        },
    )

export const saveReportOrderLegitimation = (
    productOrderId: string,
    data: any,
) =>
    axios.post(`/cbra/auskunft/orders/${productOrderId}/legitimations`, data, {
        headers: {
            viewScope: 'MEMBER',
        },
    })

export const getCbraTransaction = (transactionId: string) => {
    return axios.get<CbraTransaction, AxiosResponse<CbraTransaction>>(
        `/cbra/transactions/${encodeURIComponent(transactionId)}`,
        { headers: { viewScope: 'MEMBER' } },
    )
}

export const getCbraInsuranceMatrix = (reportId: string) => {
    const headers = getPortfolioHeader()
    return axios.get<
    CbraInsuranceMatrixResponse,
    AxiosResponse<CbraInsuranceMatrixResponse>
  >(`/cbra/wkv/premiumcalcs/${encodeURIComponent(reportId)}`, {
      headers,
  })
}

// @TODO: add response type
export const createCbraWKVOrder = (data: unknown) => {
    const headers = getPortfolioHeader()
    return axios.post<any, AxiosResponse<any>>(
        '/cbra/wkv/wkvorderrequests',
        data,
        { headers },
    )
}
export const getCbraWKVOrder = (reportOrderNumber: string) => {
    const headers = getPortfolioHeader()
    return axios.get<CbraWKVOrder, AxiosResponse<CbraWKVOrder>>(
        `/cbra/wkv/wkvorders/${encodeURIComponent(reportOrderNumber)}`,
        {
            headers,
        },
    )
}

export const getLatestWkvFromCbra = (reportOrderNumber: string) => {
    const headers = getPortfolioHeader()
    return axios.get<CbraWKVOrder[], AxiosResponse<CbraWKVOrder[]>>(
        `/cbra/wkv/wkvorders?reportOrderNo=${encodeURIComponent(reportOrderNumber)}&excludeCancelled=true&maxResults=1`,
        {
            headers,
        },
    )
}

export const getAnswerFromCbra = (wkvOrderId: string) => {
    const headers = getPortfolioHeader()
    return axios.get<string, AxiosResponse<string>>(
        `/cbra/wkv/wkvorders/${encodeURIComponent(wkvOrderId)}/paymentfeedbackstatus`,
        {
            headers,
        },
    )
}

export const sendCbraCustomerFeedback = (data: CustomerFeedbackPayload) => {
    const headers = getPortfolioHeader()
    return axios.post<unknown, AxiosResponse<unknown>>(
        '/cbra/customerfeedbacks',
        data,
        { headers },
    )
}

export const sendCbraMail = (data: unknown) => {
    return axios.post<boolean, AxiosResponse<boolean>>('/cbra/vc/email/', data)
}

export const requestCbraPermission = (data: RequestPermissionPayload) => {
    return axios.post<unknown, AxiosResponse<unknown>>(
        '/cbra/vc/requestPermission',
        data,
    )
}
export const buyCbraReport = (data: BuyProductPayload) => {
    let headers: { [key: string]: AxiosHeaderValue } | undefined = undefined
    if (differentContextActive()) {
        headers = getPortfolioHeader()
    }

    return axios.post<BuyProductResponse, AxiosResponse<BuyProductResponse>>(
        '/cbra/receivereport/',
        data,
        {
            headers: headers,
        },
    )
}

export const sendAnswerToCbra = (wkvOrderId: string, answer: string) => {
    const headers = getPortfolioHeader()
    const allHeaders = { ...headers, 'Content-Type': 'application/json' }
    return axios.put<boolean, AxiosResponse<boolean>>(
        `/cbra/wkv/wkvorders/${wkvOrderId}/paymentfeedbackstatus`,
        answer,
        { headers: allHeaders },
    )
}

export const validateWatchlistEntries = (businessId: string | number) => {
    return axios.get<
    WatchlistValidateResponse,
    AxiosResponse<WatchlistValidateResponse>
  >('/cbra/watchlistentries/validate', { params: { businessId } })
}

export const deleteEntryFromWatchlist = (watchlistEntryId: string) => {
    return axios.delete<unknown, AxiosResponse<unknown>>(
        '/cbra/watchlistentries/' + watchlistEntryId,
    )
}

export const createCbraFamilyTreeUrl = (data: unknown) => {
    return axios.post<unknown, AxiosResponse<unknown>>(
        '/cbra/vfa5/createUrl',
        data,
    )
}

export const getMonitoringStateFromCbra = (transactionId: string) => {
    const headers = getPortfolioHeader()
    return axios.get<string, AxiosResponse<string>>(
        `/cbra/transactions/${encodeURIComponent(transactionId)}/monitoringstatus`,
        {
            headers,
        },
    )
}

export const saveCbraNote = (transactionID: string, data: unknown) => {
    const headers = getPortfolioHeader()
    return axios.post<unknown, AxiosResponse<unknown>>(
        `/cbra/transactions/myNote/${encodeURIComponent(transactionID)}`,
        data,
        { headers },
    )
}

export const getCbraAvailabilityCheck = (data: unknown) => {
    const headers = getPortfolioHeader()
    return axios.post<
    CbraWKVAvailabilityCheckResponse,
    AxiosResponse<CbraWKVAvailabilityCheckResponse>
  >('/cbra/wkv/availchecks', data, { headers })
}

export const getCbraReportTransactionData = (
    reportId: string,
    headers: { [key: string]: AxiosHeaderValue } = {},
    params: string,
) => {
    return axios.get<
    CbraReportTransactionData,
    AxiosResponse<CbraReportTransactionData>
  >(`/cbra/reports/${encodeURIComponent(reportId)}/json` + params, {
      headers,
  })
}

export const getCbraSupplementHistory = (transactionId: string) => {
    return axios.get<unknown, AxiosResponse<unknown>>(
        `/cbra/transactions/${transactionId}/lastTenSupplements/`,
    )
}

export const getCbraReportHistory = (transactionId: string) => {
    const headers = getPortfolioHeader()
    return axios.get<
    CbraReportHistoryResponse,
    AxiosResponse<CbraReportHistoryResponse>
  >(`/cbra/transactions/${transactionId}/reportHistory/`, {
      headers,
  })
}

export const putCbraUpdateReportTracking = (
    productOrderId: string,
    data: unknown,
    headers: { [key: string]: AxiosHeaderValue } = {},
) =>
    axios.put(`/cbra/updatemonitoring?productOrderId=${productOrderId}`, data, {
        headers,
    })

export const getCbraProductpermissions = () => {
    return axios.get<unknown, AxiosResponse<unknown>>(
        '/cbra/consumer/productpermissions',
    )
}

export const postConsumerProduct = (data: unknown) => {
    return axios.post<
    CbraReportConsumerResponse,
    AxiosResponse<CbraReportConsumerResponse>
  >('/cbra/consumer/producttransactions', data)
}

export const postCbraConsumerPermissions = (
    data: CbraReportRequestConsumerPermissionPayload,
) => {
    return axios.post<unknown, AxiosResponse<unknown>>(
        '/cbra/vc/requestConsumerPermission',
        data,
    )
}

export const getCbraConsumerJSON = (reportId: string) => {
    return axios.get<unknown, AxiosResponse<unknown>>(
        `/cbra/consumer/products/${encodeURIComponent(reportId)}/json`,
    )
}

export const postCbraSimpleSearch = (data: SimpleSearchPayload) => {
    return axios.post<SimpleSearchResults, AxiosResponse<SimpleSearchResults>>(
        '/cbra/simplesearch',
        data,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    )
}

export const postCbraCountTransactions = (
    transactionCounts: CbraTransactionCountPayload,
) => {
    const headers = getPortfolioHeader()
    return axios.post<
    CbraTransactionCountResponse,
    AxiosResponse<CbraTransactionCountResponse>
  >(
      '/cbra/transactions/countbybusinessids',
      { transactionCounts: transactionCounts },
      { headers },
  )
}
export const getCbraFamilyTreeReportJSON = (reportId: string) => {
    const headers = getPortfolioHeader()
    return axios.get<
    CbraTransactionFamilyTreeResponse,
    AxiosResponse<CbraTransactionFamilyTreeResponse>
  >(
      '/cbra/involvementdata/products/' + encodeURIComponent(reportId) + '/json',
      { headers },
  )
}

export const postCbraCheckLogin = (
    tokenParsed: unknown,
    preferredLanguage: string,
) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        preferredLanguage: preferredLanguage,
    }
    return axios.post<
    CbraTransactionCountResponse,
    AxiosResponse<CbraTransactionCountResponse>
  >('/cbra/checkSsoLogin', tokenParsed, { headers })
}

export const deleteCbraConsumerTransaction = (
    reportId: string,
    transactionId: string,
    headers: { [key: string]: AxiosHeaderValue } = {},
) => {
    return axios.delete<unknown, AxiosResponse<unknown>>(
        `/cbra/consumer/products/${reportId}?transactionId=${transactionId}`,
        { headers },
    )
}
