import { getCbraAvailabilityCheck } from '@/services'
import { getErrorMessages } from './services/error'
import { isAxiosError } from 'axios'

export namespace WKVHelper {
    export const currencyFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'code'
    })
    export function getWkvPrices(amount:string, time:string, section: string, calcMatrix) {
        if (!amount || !time || !section) { return '0.00'}
    
        if (typeof calcMatrix[amount] === 'object' && calcMatrix[amount] !== undefined) {
            return currencyFormatter.format(parseFloat(calcMatrix[amount][time][section]['price']))
        } else {
            return currencyFormatter.format(parseFloat('0.00'))
        }
    }
    
    export function getWkvPdf(orderId: string, type: 'INVOICE' | 'CONFIRMATION' | 'POLICY') {
        return `/cbra/wkv/wkvorders/${orderId}/documents/${type}`
    }

       export async function checkForWkvButton(transactionData) {
           const response = await getAvailabilityCheck(transactionData.reportId)

           if (typeof response === 'object' && response !== null && typeof response.insuranceAvailable === 'boolean') {
               return response.insuranceAvailable
           } else {
               return false
           }
       }
     
}

async function getAvailabilityCheck(reportId: string ) {
    const reqData = { reportId: reportId }

    try {
        const { data } = await getCbraAvailabilityCheck(reqData)
        return data
    } catch (error) {
        if (isAxiosError(error) && error.response?.status && error.response.status >= 400) {
            return {
                'reportId': reportId,
                'insuranceAvailable': false,
                'reportOrderNo': null,
                'crefoNr': null
            }
        } else {
            const errorConf = getErrorMessages('wkv.order.notreachable')
            uimodal.show({
                title: errorConf.headline,
                content: errorConf.description,
                icon: 'warning'
            })
        }
    }
}
