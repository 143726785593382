import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/services/tracing-axios'
import { AxiosResponse } from 'axios'
import type { CbraUser } from '@/types/cbra/user'
import { useCbraSettingsStore } from './cbraSettings'
import dayjs from 'dayjs'
import { CbraUserPreferences } from '@/types/cbra/userPreferences'
import { getCbraUserPreferences } from '@/services'

export const useUserStore = defineStore('user', () => {
    const ssoChecked = ref(false)
    const cbraSettingsStore = useCbraSettingsStore()
    const authenticated = ref(false)
    const token = ref<string | undefined>(undefined)
    const isMemberUser = ref(false)
    const dateLocale = ref('de')
    const user = ref<UserData>({
        actForMemberId: undefined,
        actForMemberName: '',
        cr_id: undefined,
        cr_mapping: undefined,
        cr_membernumber: undefined,
        email: undefined,
        givenName: '',
        surname: '',
        user_roles: [],
    })

    const cbraUser = ref<CbraUser>({
        clz: undefined,
        crId: '',
        crMembernumber: '',
        currentLoginDate: 0,
        effectiveClz: undefined,
        email: undefined,
        familyName: '',
        givenName: '',
        lastLoginDate: undefined,
        memberUserId: '',
        name: '',
        overrideClz: undefined,
        roles: []
    })

    const userPreferences = ref<CbraUserPreferences | undefined | null>(null)

    const ikarosState = ref(false)

    /* istanbul ignore next */
    async function getCbraUser() {
        try {
            const { status, data } = await axios.get<CbraUser, AxiosResponse<CbraUser>, void>('/cbra/user')

            if (status === 200) {
                cbraUser.value = data
                if (user.value.cr_mapping && user.value.cr_mapping.length) {
                    cbraSettingsStore.getCbraFeatures()
                }
            }
        } catch (err: any) {
            // todo: handle error
        }
    }

    /* async function getUserPreferences() {
        const { data } = await getCbraUserPreferences()
        userPreferences.value = data
    } */

    async function getUserPreferences() {
        try {
            const response = await getCbraUserPreferences()
            
            if (response && response.data) {                
                userPreferences.value = response.data
            } else {
                console.error('Data not found in response')
            }
        } catch (error) {
            console.log('Error fetching user preferences:', error)
        }
    }

    function saveTokenValues(data: NewTokenValues | RefreshedTokenValues) {
        isMemberUser.value = !!data.idTokenParsed.cr_mapping
        user.value.email = data.idTokenParsed.email
        user.value.givenName = data.idTokenParsed.given_name
        user.value.surname = data.idTokenParsed.family_name
        user.value.user_roles = typeof data.idTokenParsed.cr_userrole === 'string' ? [data.idTokenParsed.cr_userrole] : data.idTokenParsed.cr_userrole
        user.value.cr_membernumber = data.idTokenParsed.cr_membernumber
        user.value.cr_mapping = data.idTokenParsed.cr_mapping
    }

    function setCbraUser(data: CbraUser) {
        cbraUser.value = data
    }

    /**
     * Should be called, when the keycloak token was initially set.
     */
    function onValidTokenExists(data: NewTokenValues) {
        authenticated.value = true
        token.value = data.token
        user.value.cr_id = data.idTokenParsed.cr_id
        saveTokenValues(data)
    }

    /**
     * Should be called, when the keycloak token was updated.
     *
     * Currently, there is no real difference to ON_VALID_TOKEN_EXISTS.
     * Its only there, if it is needed to differentiate between token updates and the initial login.
     */
    function onKeycloakTokenUpdateSuccess(data: RefreshedTokenValues) {
        token.value = data.token || null
        saveTokenValues(data)
    }

    /**
     * Should be called, when the user navigation to a different company using Unternehmensnavigation.
     */
    function setActForMember(data: Member) {
        user.value.actForMemberId = data.actForMemberId
        user.value.actForMemberName = data.actForMemberName
    }

    /**
     * Should be called with the dayjs locale that should be used for date formatting.
     */
    function onLanguageChange(locale: string) {
        const localeClean = locale.toLowerCase().split('_')
        let newDateLocale: string

        // dayjs supports e.g 'de' 'de-at' 'de-ch' 'en' 'en-gb' 'en-au' 'es' 'es-us'...
        if (localeClean[0] === localeClean[1]) {
            newDateLocale = localeClean[0]
        } else if (localeClean[0] === 'en' && localeClean[1] === 'us') {
            newDateLocale = localeClean[0]
        } else {
            newDateLocale = localeClean.join('-')
        }

        dateLocale.value = newDateLocale
        dayjs.locale(newDateLocale)
    }

    return {
        ssoChecked, authenticated, token, isMemberUser, dateLocale, user, cbraUser, ikarosState, userPreferences,
        onValidTokenExists, onKeycloakTokenUpdateSuccess, setActForMember, setCbraUser, onLanguageChange, getCbraUser, getUserPreferences
    }
})
