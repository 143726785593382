<template>
    <div class="big-image-teaser bg-brand-light-grey">
        <div class="container big-image-teaser__container pt">
            <div class="row mb-large">
                <div class="col-12 col-md-6 mb">
                    <h3>
                        {{ $t("BigImageTeaser.myCompany.title") }}
                    </h3>
                    <i18n-t
                        keypath="BigImageTeaser.myCompany.text"
                        tag="p"
                        class="mb"
                    >
                        <template #companyInDB>
                            <a
                                :href="$t('BigImageTeaser.myCompany.companyInDBLink')"
                                title="Wirtschauftsauskünfte zu Unternehmen"
                            >
                                {{ $t("BigImageTeaser.myCompany.companyInDB") }}
                            </a>
                        </template>
                    </i18n-t>
                </div>
                <div class="col-12 col-md-6 mb">
                    <h3>
                        {{ $t("BigImageTeaser.qualityPromise.title") }}
                    </h3>
                    <i18n-t
                        keypath="BigImageTeaser.qualityPromise.text"
                        tag="p"
                        class="mb"
                    >
                        <template #uniqueInformationSources>
                            <a
                                :href="$t('BigImageTeaser.qualityPromise.uniqueInformationSourcesLink')"
                                title="Unsere Datenquellen"
                            >
                                {{ $t("BigImageTeaser.qualityPromise.uniqueInformationSources") }}
                            </a>
                        </template>
                    </i18n-t>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
img {
    width: 100%;
}
</style>
