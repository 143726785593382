<script setup lang="ts">
import { ref } from 'vue'
import { useMembersStore } from '@/stores/members'

import ReadOnlyElement from '@/components/Profile/partials/ReadOnlyElement.vue'
import Card from '@/components/Card.vue'
import Select from '@/components/Input/Select.vue'
import InputText from '@/components/Input/InputText.vue'

import CONSTANTS from '@/data/constants'
import countries from '@/data/countries'

const membersStore = useMembersStore()
const editData = ref(false)

const props = defineProps({
    isAllowedToSave: {
        type: Boolean,
        default: false
    }
})

function toggleEditData() {
    if (!props.isAllowedToSave) {
        return
    }
    editData.value = !editData.value
    initMemberAddress()
    membersStore.dirtyInvoice = editData.value
}

function initMemberAddress() {
    if (editData.value && !membersStore.updatePayload.invoiceAddress) {
        membersStore.updatePayload.invoiceAddress = {
            type: null,
            city: '',
            country: 'DE',
            zipCode: ''
        }
    }
}
</script>

<template>
    <Card
        id="memberdata-invoiceaddress"
        data-qa="selfcare-member-invoice-addressFields"
    >
        <h3>{{ $t('selfcare.show.section.mitgliedsdaten.rechnungsadresse') }}</h3>
        <ReadOnlyElement
            :label="$t('selfcare.show.section.unternehmensdaten.geschaftsfuehrung.adresse.strasseHausnummer')"
            :value="membersStore.cbraMember.invoiceAddress.addressLine1"
            data-qa="addressLine1"
        />
        <ReadOnlyElement
            :label="$t('selfcare.show.section.mitgliedsdaten.rechnungsadresse.plzOrt')"
            :value="membersStore.cbraMember.invoiceAddress.addressLine2"
            data-qa="addressLine2"
        />
        <div class="row">
            <div class="col-7 offset-5">
                <a
                    role="button"
                    href="#"
                    class="showmore-link"
                    :data-qa="`selfcare-member-invoice-addressToggle${!isAllowedToSave ? '-disabled' : ''}`"
                    :class="{ 'showmore-link-disabled': !isAllowedToSave}"
                    @click.prevent.stop="toggleEditData"
                >
                    <span v-if="!editData">
                        <font-awesome-icon icon="fa-light fa-circle-plus" />
                        {{ $t('selfcare.change') }}
                    </span>
                    <span v-else>
                        <font-awesome-icon icon="fa-light fa-circle-minus" />
                        {{ $t('selfcare.hide') }}
                    </span>
                </a>
            </div>
        </div>
        <Transition>
            <div
                v-if="editData && membersStore.updatePayload.invoiceAddress"
                class="row"
            >
                <div class="col-12">
                    <Select
                        id="invoiceAddresstype"
                        v-model="membersStore.updatePayload.invoiceAddress.type"
                        :label="$t('postalAddress.addressType.label')"
                        :searchable="false"
                        :options="CONSTANTS.POSTAL_ADDRESS_TYPE_OPTIONS"
                        data-qa="addresstype"
                        :required="true"
                    />
                    <template v-if="membersStore.updatePayload.invoiceAddress.type !== null">
                        <div
                            v-if="membersStore.updatePayload.invoiceAddress.type === CONSTANTS.POSTAL_ADDRESS_TYPES.DELIVERY_ADDRESS"
                            class="row"
                        >
                            <div class="col-8">
                                <InputText
                                    v-model="membersStore.updatePayload.invoiceAddress.street"
                                    name="invoiceStreet"
                                    :label="$t('selfcare.show.section.unternehmensdaten.adresse.strasse')"
                                    data-qa="street"
                                    error-msg-data-qa="street-message"
                                    :required="true"
                                />
                            </div>
                            <div class="col-4">
                                <InputText
                                    v-model="membersStore.updatePayload.invoiceAddress.houseNumber"
                                    name="invoiceHouseNumber"
                                    :label="$t('selfcare.show.section.unternehmensdaten.adresse.hausnr')"
                                    data-qa="houseNumber"
                                    error-msg-data-qa="houseNumber-message"
                                    :required="true"
                                />
                            </div>
                        </div>
                        <div
                            v-if="membersStore.updatePayload.invoiceAddress.type === CONSTANTS.POSTAL_ADDRESS_TYPES.POST_OFFICE_BOX"
                            class="row"
                        >
                            <div class="col-12">
                                <InputText
                                    v-model="membersStore.updatePayload.invoiceAddress.postOfficeBoxNumber"
                                    name="invoicePostOfficeBoxNumber"
                                    :label="$t('selfcare.show.section.unternehmensdaten.adresse.postfach')"
                                    data-qa="postOfficeBoxNumber"
                                    error-msg-data-qa="postOfficeBoxNumber-message"
                                    :required="true"
                                />
                            </div>
                        </div>
                        <div
                            class="row"
                        >
                            <div class="col-4">
                                <InputText
                                    v-model="membersStore.updatePayload.invoiceAddress.zipCode"
                                    name="invoiceZipCode"
                                    :label="$t('selfcare.show.section.unternehmensdaten.adresse.plz')"
                                    data-qa="zipCode"
                                    error-msg-data-qa="zipCode-message"
                                    :required="true"
                                />
                            </div>
                            <div class="col-8">
                                <InputText
                                    v-model="membersStore.updatePayload.invoiceAddress.city"
                                    name="invoiceCity"
                                    :label="$t('selfcare.show.section.unternehmensdaten.adresse.ort')"
                                    data-qa="city"
                                    error-msg-data-qa="city-message"
                                    :required="true"
                                />
                            </div>
                        </div>
                        <div
                            class="row"
                        >
                            <div class="col-12">
                                <Select
                                    id="invoiceCountry"
                                    v-model="membersStore.updatePayload.invoiceAddress.country"
                                    :label="$t('selfcare.show.section.unternehmensdaten.adresse.land')"
                                    :options="countries"
                                    data-qa="country"
                                    error-msg-data-qa="selectedCountry-message"
                                    :required="true"
                                />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </Transition>
    </Card>
</template>

<style scoped>
/* we will explain what these classes do next! */
.v-enter-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
