<template>
    <div class="container mb">
        <div class="row">
            <div 
                v-if="transactionsStore.filterMode !== CONSTS.FILTER.FAVOURITES"
                class="col-12 col-md-6 search-input-group"
            >
                <InputText
                    v-model="transactionsStore.searchQuery"
                    name="query"
                    label=""
                    :placeholder="$t('transaction.list.search.placeholder')"
                    :no-valid-class="true"
                    data-qa="myTransactions-searchInput"
                    error-msg-data-qa="myTransactions-searchInput-message"
                />
                <button 
                    class="btn btn-default btn-min"
                    :disabled="!transactionsStore.isSearchActive"
                    data-qa="myTransactions-searchButton"
                    @click.prevent="transactionsStore.onSearchSubmit"
                >
                    <!-- <font-awesome-icon
                        icon="fa-sharp fa-solid fa-magnifying-glass"
                    /> -->
                    <i
                        aria-hidden="true"
                        class="crefo-ui-icon icon-magnifier"
                    />
                </button>
            </div>
            <div 
                class="col-12 col-md-3"
                :class="{ 'offset-md-6': transactionsStore.filterMode === CONSTS.FILTER.FAVOURITES }"
            >
                <Select
                    id="myTransactions-selectReports"
                    v-model="transactionsStore.filterMode"
                    label=""
                    :searchable="false"
                    :options="filterOptions"
                    data-qa="myTransactions-selectReports"
                />
            </div>
            <div class="col-12 col-md-3">
                <Select
                    id="myTransactions-selectSortMode"
                    v-model="transactionsStore.sortMode"
                    label=""
                    :searchable="false"
                    :options="availableSortOptions"
                    data-qa="myTransactions-selectSortMode"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import InputText from '@/components/Input/InputText.vue'
import Select from '@/components/Input/Select.vue'

import { CONSTS } from '@/helper/services/transaction'

import { useTransactionsStore } from '@/stores/transactions'
const transactionsStore = useTransactionsStore()

import type { SelectOption } from '@/types/components/select.d.ts'

const filterOptions: Array<SelectOption> = [
    { label: '', code: CONSTS.FILTER.ALL, i18nKey: 'transaction.list.transactionFilter.all' },
    { label: '', code: CONSTS.FILTER.FAVOURITES, i18nKey: 'shared.filter.favourites' },
    { label: '', code: CONSTS.FILTER.REPORT, i18nKey: 'shared.filter.reports' },
    { label: '', code: CONSTS.FILTER.CONSUMER, i18nKey: 'shared.filter.consumerReports' },
    { label: '', code: CONSTS.FILTER.COLLECTION, i18nKey: 'shared.filter.collections' }
]

const sortOptions: Array<SelectOption> = [
    { label: '', code: CONSTS.SORT.CREATION_TIME_DESC, i18nKey: 'shared.sort.creationTimeDesc' },
    { label: '', code: CONSTS.SORT.CREATION_TIME_ASC, i18nKey: 'shared.sort.creationTimeAsc' },
    { label: '', code: CONSTS.SORT.MODIFICATION_TIME_DESC, i18nKey: 'shared.sort.lastModificationTimeDesc' },
    { label: '', code: CONSTS.SORT.MODIFICATION_TIME_ASC, i18nKey: 'shared.sort.lastModificationTimeAsc' },
    { label: '', code: CONSTS.SORT.OBSERVATION_END_TIME_DESC, i18nKey: 'shared.sort.observationEndTimeDesc' },
    { label: '', code: CONSTS.SORT.OBSERVATION_END_TIME_ASC, i18nKey: 'shared.sort.observationEndTimeAsc' }
]

const availableSortOptions = computed(() => {
    // OBSERVATION_END_TIME_DESC & OBSERVATION_END_TIME_ASC are only available for reports
    return sortOptions.filter((option) => {
        if (transactionsStore.filterMode === CONSTS.TRANSACTION_TYPE.REPORT) {
            return true
        } else {
            return option.code !== CONSTS.SORT.OBSERVATION_END_TIME_DESC && 
                option.code !== CONSTS.SORT.OBSERVATION_END_TIME_ASC
        }
    })
})
</script>

<style lang="less" scoped>
.search-input-group {
    .crefo-input {
        width: calc(100% - 56px);
        float: left;
    }
}
</style>
