import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    locale: 'de-DE',
    fallbackLocale: 'de-DE',
    messages: loadLocaleMessages(),
    silentTranslationWarn: true,
    legacy: false
})

function loadLocaleMessages() {
    const locales = import.meta.glob('./i18n/*.json', { eager: true })

    const messages = {}
    for (const locale in locales) {
        const localeModule = locales[locale]
        // @ts-ignore
        messages[localeModule.locale] = JSON.parse(JSON.stringify(localeModule.default))
    }

    return messages
}

export default i18n

export function changeLocale(locale: string) {
    i18n.global.locale.value = locale
}
