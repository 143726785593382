<!-- eslint-disable vue/no-v-html -->
<template>
    <!-- MITARBEITER_UNTERNEHMEN -->
    <!-- mitarbeiterTemplate -->
    
    <template v-if="productTypeEnum === 'COMPACT_REPORT'">
        <div
            v-if="unternehmen.mitarbeiter && unternehmen.mitarbeiter.length > 0"
            class="row table-responsive"
        >
            <table class="table table--fix product-content-box__table col-12 mt mb">
                <tbody>
                    <tr>
                        <td style="width: 20%;">
                            <span>{{ $t('transaction.chapter.GESCHAEFTSZAHLEN.mitarbeiter') }}</span>
                        </td>
                        <template
                            v-for="(data, index) of unternehmen.mitarbeiter"
                            :key="index"
                        >
                            <td style="width: 80%;">
                                <GeschaeftszahlenDekorationsTemplate :data="data" />
                                <span
                                    v-if="data.gesamt && data.dekoration !== 'UNBEKANNT'"
                                    data-qa="report-financialFigures-employees"
                                >{{ data.gesamt }}</span>
                                <span v-if="data.bereich && data.dekoration !== 'UNBEKANNT'">{{ data.bereich }}</span>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>
    </template>
    <template v-if="productTypeEnum !== 'COMPACT_REPORT'">
        <template v-if="unternehmen.mitarbeiter && unternehmen.mitarbeiter.length > 0">
            <div class="row table-responsive hidden-xs hidden-sm">
                <table class="table table--fix product-content-box__table col-12 mt mb">
                    <thead>
                        <tr>
                            <th class="col-2">
                                <p class="mt">
                                    {{ $t('transaction.chapter.GESCHAEFTSZAHLEN.mitarbeiter') }}
                                </p>
                            </th>

                            <th :colspan="unternehmen.mitarbeiter.length">
                                <p class="text-align-center">
                                    {{ $t('transaction.chapter.GESCHAEFTSZAHLEN.geschäftsjahr') }}
                                </p>
                                <ul class="table-responsive__label-list no-padding">
                                    <template
                                        v-for="(data, index) of unternehmen.mitarbeiter"
                                        :key="index"
                                    >
                                        <li
                                            class="table-responsive__label text-align-right"
                                            :style="{ width: (97 / unternehmen.mitarbeiter .length) + '%', display: 'inline-block' }"
                                        >
                                            <GeschaeftszahlenDatumsTemplate
                                                :data="data"
                                                :index="index"
                                                :is-dal="isDal"
                                            />
                                        </li>
                                    </template>
                                </ul>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template
                            v-for="(employeeType, employeeTypeIndex) of getUsedEmployeeTypes(unternehmen.mitarbeiter)"
                            :key="employeeTypeIndex"
                        >
                            <tr>
                                <td><strong>{{ employeeType }}</strong></td>
                                <template
                                    v-for="(data, index) of unternehmen.mitarbeiter"
                                    :key="index"
                                >
                                    <td>
                                        <template v-if="data.mitarbeiterTyp">
                                            <template
                                                v-for="(employeeTypeForYear, employeeTypeForYearIndex) of data.mitarbeiterTyp"
                                                :key="employeeTypeForYearIndex"
                                            >
                                                <div
                                                    v-if="employeeTypeForYear.art === employeeType"
                                                    class="text-align-right"
                                                >
                                                    <GeschaeftszahlenDekorationsTemplate :data="employeeTypeForYear" />
                                                    <span>{{ employeeTypeForYear.anzahl }}</span>
                                                </div>
                                            </template>
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </template>

                        <tr v-if="isDal && !getUsedEmployeeTypes(unternehmen.mitarbeiter).length"> 
                            <td :colspan="unternehmen.mitarbeiter.length + 1">
                                {{ $t('transaction.chapter.employees.no.further.details.available') }}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>
                                {{ $t('transaction.chapter.GESCHAEFTSZAHLEN.gesamt') }}
                            </th>
                            <template
                                v-for="(data, index) of unternehmen.mitarbeiter"
                                :key="index"
                            >
                                <th class="text-align-right">
                                    <GeschaeftszahlenDekorationsTemplate :data="data" />
                                    <span v-if="data.gesamt && data.dekoration !== 'UNBEKANNT'">{{ data.gesamt }}</span>
                                    <span v-if="data.bereich && data.dekoration !== 'UNBEKANNT'"> {{ data.bereich }}</span>
                                </th>
                            </template>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div class="row table-responsive hidden-md hidden-lg">
                <Accordion>
                    <div class="table-responsive__container">
                        <AccordionItem
                            :item-key="0"
                            :title="$t('transaction.chapter.GESCHAEFTSZAHLEN.mitarbeiter')"
                        >
                            <div
                                :class="`table-responsive__list management-list process-list-${unternehmen.type}`"
                            >
                                <template
                                    v-for="(data, index) of unternehmen.mitarbeiter"
                                    :key="index"
                                >
                                    <div class="table-responsive__item">
                                        <div class="row">
                                            <div class="col-6">
                                                <strong> {{ $t('transaction.chapter.GESCHAEFTSZAHLEN.geschäftsjahr') }} </strong>
                                            </div>
                                            <div class="col-6">
                                                <GeschaeftszahlenDatumsTemplate
                                                    :data="data"
                                                    :index="index"
                                                    :is-dal="isDal"
                                                />
                                            </div>
                                        </div>

                                        <template
                                            v-for="(mitarbeiterTyp, mitarbeiterTypIndex) of data.mitarbeiterTyp"
                                            :key="mitarbeiterTypIndex"
                                        >
                                            <div class="row">
                                                <div class="col-6">
                                                    <strong>{{ mitarbeiterTyp.art }}</strong>
                                                </div>
                                                <div class="col-6">
                                                    <GeschaeftszahlenDekorationsTemplate :data="data" />
                                                    <span>{{ mitarbeiterTyp.anzahl }}</span>
                                                </div>
                                            </div>
                                        </template>
                   
                                        <div
                                            v-if="isDal && data.mitarbeiterTyp && !data.mitarbeiterTyp.length || !data.mitarbeiterTyp"
                                            class="row"
                                        >
                                            <div class="col-12">
                                                {{ $t('transaction.chapter.employees.no.further.details.available') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table-responsive__item table-responsive__item--highlight">
                                        <div class="row ">
                                            <div class="col-6">
                                                <strong> {{ $t('transaction.chapter.GESCHAEFTSZAHLEN.gesamt') }} </strong>
                                            </div>
                                            <div class="col-6">
                                                <strong>
                                                    <GeschaeftszahlenDekorationsTemplate :data="data" />
                                                    <span v-if="data.gesamt && data.dekoration !== 'UNBEKANNT'">{{ data.gesamt }}</span>
                                                    <span v-if="data.bereich && data.dekoration !== 'UNBEKANNT'"> {{ data.bereich }}</span>
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </accordionitem>
                    </div>
                </Accordion>
            </div>
        </template>
    </template>
    <div
        v-if="unternehmen.hinweis"
        class="product-content-box__text"
    >
        <p>
            <strong> {{ $t('transaction.chapter.GESCHAEFTSZAHLEN.anmerkungenMitarbeiter') }}: </strong>
        </p>
        <p>
            <span
                data-qa="report-financialFigures-employees-text"
                v-html="helpers.nl2br(unternehmen.hinweis)"
            />
        </p>
    </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { CbraReportTransactionChapter, CbraReportTransactionMitarbeiterUmsatz } from '@/types/cbra/reports'
import GeschaeftszahlenDekorationsTemplate from '../GeschaeftszahlenDekorationsTemplate.vue'
import GeschaeftszahlenDatumsTemplate from '../GeschaeftszahlenDatumsTemplate.vue'
import helpers from '@/helper/misc'
import Accordion from '@/components/Accordion/Accordion.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'

defineProps({
    unternehmen: {
        type: Object as PropType<CbraReportTransactionChapter>,
        required: true,
    },
    productTypeEnum: {
        type: String,
        required: true,
    },
    isDal: {
        type: Boolean,
        required: true,
    },
})
 
function getUsedEmployeeTypes(mitarbeiter: CbraReportTransactionMitarbeiterUmsatz[]) {
    let mitarbeiterTypes = new Set() // Use Set to store unique values

    mitarbeiter.forEach(m => {
        // Use map to extract 'art' and add to the set
        m.mitarbeiterTyp?.map(mt => mt.art).forEach(art => mitarbeiterTypes.add(art))
    })

    // Convert the Set back to an array
    return Array.from(mitarbeiterTypes)
}

</script>