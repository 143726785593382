<template>
    <div class="crefo-input">
        <div
            class="input-group"
            :class="{
                'has-error': !!errorMessage,
                'is-valid': meta.valid && !!inputValue
            }"
        >
            <label
                v-if="!labelHidden"
                :for="name"
                class="input-label"
                :style="label ? '' : 'opacity:0;'"
            >{{ label }}<span v-if="required"> *</span></label>
            <div class="input-wrapper">
                <textarea
                    :id="name"
                    :name="name"
                    :value="inputValue"
                    :placeholder="placeholder"
                    :readonly="readonly"
                    :disabled="disabled"
                    :data-qa="dataQa"
                    data-no-crefo-ui="true"
                    :maxlength="maxLength"
                    :rows="rows"
                    @input="handleChange"
                    @blur="handleBlur"
                />
            </div>

            <p
                v-if="errorMessage"
                class="error-msg"
                :data-qa="errorMsgDataQaAttr"
            >
                {{ errorMessage }}
            </p>
        </div>
    </div>
</template>

<script setup lang='ts'>
import { toRef, PropType } from 'vue'
import { useField } from 'vee-validate'

const props = defineProps({
    modelValue: {
        type: [String, Number] as PropType<string | number | string[] | undefined>,
        default: undefined
    },
    name: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: true
    },
    placeholder: {
        type: String,
        default: ''
    },
    dataQa: {
        type: String,
        default: ''
    },
    errorMsgDataQa: {
        type: String,
        default: ''
    },
    readonly: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    maxLength: {
        type: Number,
        default: undefined
    },
    rows: {
        type: Number,
        default: undefined
    },
    labelHidden: {
        type: Boolean,
        default: false
    }
})

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(name, undefined, {
    initialValue: props.modelValue,
    syncVModel: true
})

const errorMsgDataQaAttr = props.errorMsgDataQa || (props.dataQa ? `${props.dataQa}-message` : '')
</script>

<style scoped>
textarea[readonly],
textarea[disabled] {
    cursor: not-allowed !important;
    background-color: rgb(248, 248, 248) !important;
}

.has-error {
    .input-label {
        color: var(--color-error);
    }
}
</style>
