/* eslint-disable @typescript-eslint/no-unused-vars */
import CONSTANTS from '@/data/constants'
import Storage from '@/helper/storage'
import { AxiosHeaderValue } from 'axios'

const FEATURES_KEY = 'featureflags_monorepo'

const VUE_STORAGE_TTL_FEATURE_FLAGS = 1 * 3600 * 1000 // 1 hour
const VUE_STORAGE_KEY_MAPPING: any = {
    'featureflag_finux': 'finuxActive',
    'featureflag_kp': 'kundenportfolioActive',
    'featureflag_did': 'digitalIdentityActive',
    'featureflag_drd': 'drdActive'
}

/**
 * Clears all stored feature data.
 *
 * @private
 */
function _clearFeatureCache() {
    Storage.set(FEATURES_KEY, null, true)
    Object.keys(VUE_STORAGE_KEY_MAPPING).forEach((vueFeatureKey) => {
        Storage.set(vueFeatureKey, null, true)
    })
}

/**
 * Get value of vue feature.
 *
 * @param {string} feature
 * @return {?Object}
 * @private
 */
function _getVueFeatureValue(feature: string) {
    const featureData = Storage.get(feature, true)
    if (featureData === null || typeof featureData.ttl !== 'number') {
        return null
    }

    return featureData
}

/**
 * Get state of vue feature.
 *
 * @param {string} feature
 * @return {boolean}
 * @private
 */
function _getVueFeatureState(feature: string) {
    const featureValue = _getVueFeatureValue(feature)
    if (featureValue === null || !featureValue.value) {
        return false
    }
    if (typeof featureValue.value[feature] !== 'boolean') {
        return false
    }
    return featureValue.value[feature]
}

// export function init() {
//     window.addEventListener(CONSTANTS.USER_SSO_BEFORE_LOGIN, () => {
//         _clearFeatureCache()
//     }
//     window.addEventListener(CONSTANTS.USER_SSO_LOGOUT, () => {
//         _clearFeatureCache()
//     }
// }

export function accessToFeature(feature: string) {
    if (Object.keys(VUE_STORAGE_KEY_MAPPING).includes(feature)) {
        return _getVueFeatureState(feature)
    }

    if (Object.values(VUE_STORAGE_KEY_MAPPING).includes(feature)) {
        const vueFeatureKey = Object.keys(VUE_STORAGE_KEY_MAPPING).find(key => VUE_STORAGE_KEY_MAPPING[key] === feature)
        if (vueFeatureKey) {
            return _getVueFeatureState(vueFeatureKey)
        } else {
            return false
        }
    }

    const features = Storage.get(FEATURES_KEY, true)
    if (features === null || typeof features !== 'object' || typeof features.value[feature] !== 'boolean') {
        return false
    }
    return features.value[feature]
}

/**
     * Returns viewScope header based on feature switch portfolioViewActive.
     */
export function getPortfolioHeader() {
    let headers: { [key: string]: AxiosHeaderValue} | undefined = undefined
    if (accessToFeature('portfolioViewActive')) {
        headers = {
            viewScope: 'MEMBER'
        }
    }
    return headers
}