<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        :id="id"
        :data-page-id="id"
        class="business-data-transaction"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'transactions' }">
                    {{ $t('transactions.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('detailTransaction.breadcrumb') }}</li>
        </Breadcrumb>

        <InfoBoxDisableMessage />
        <div
            v-if="isFamilyTree && !showLoadingSpinner"
            class="container family-tree-container"
            rendersubtree="activatable"
        >
            <div class="row">
                <iframe
                    :src="familyTreeUrl"
                    style="height: 90vh; overflow: hidden"
                    width="100%"
                    frameBorder="0"
                    scrolling="no"
                    data-qa="family-tree-iframe"
                />
                <input
                    id="businessDetailPageUrl"
                    type="hidden"
                    :value="udsLinks[locale]"
                >
                <input
                    id="reportOrderPageUrl"
                    type="hidden"
                    :value="reportsOrderFormLinks[locale]"
                >
                <input
                    id="consumerReportOrderPageUrl"
                    type="hidden"
                    :value="reportsLandingpageConsumerLinks[locale]"
                >
            </div>
        </div>

        <div
            v-if="legitimationInfo"
            class="container "
        >
            <div class="row">
                <div class="col-12">
                    <h1>{{ $t("transaction.legitimationinfo.1") }}</h1>
                    <p>{{ $t("transaction.legitimationinfo.2") }}</p>
                    <p><a :href="`${adsLinks[locale]}?no_cache=1&transactionId=${transactionId}`">{{ $t("transaction.legitimationinfo.3") }}</a> {{ $t("transaction.legitimationinfo.4") }}</p>
                    <p><a href="/">{{ $t("transaction.legitimationinfo.5") }}</a></p>
                </div>
            </div>
        </div>

        <div
            v-if="isReport"
            class="report-container"
        >
            <div
                v-if="updateData"
                class="business-data-transaction__update"
                data-qa="processBox-recallSolvencyReportAgain"
            >
                <div class="text-align-center box-spacing">
                    <h2 class="m0">
                        {{ updateData.hinweis }}
                    </h2>
                </div>
            </div>

            <!-- [ report title header ]-->
            <div class="container ">
                <LoadingRing v-if="showLoadingSpinner" />

                <div class="contentHead">
                    <div class="row">
                        <div class="col-md-8 col-12">
                            <div
                                v-if="!showLoadingSpinner && show"
                                class="h1 contentHead__title"
                            >
                                <font-awesome-icon
                                    :icon=" favourite ? 'fa-solid fa-star': 'fa-light fa-star' "
                                    size="sm"
                                    data-qa="inkasso-favourite"
                                    class="cursor-pointer"
                                    @click="toggleFavouriteTransaction"
                                />
                                <span
                                    data-qa="businessName"
                                    class="ml"
                                >{{ businessName }}</span>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 contentHead__metaData text-align-right">
                            <DownloadLink
                                v-if="reportId"
                                :url="downloadReportUrl"
                                :member-user-id="getMemberUserId()"
                                data-qa="process-downloadButton"
                                classes="btn btn-default"
                                :view-scope="viewScope"
                            >
                                <font-awesome-icon
                                    icon="fa-sharp fa-regular fa-download"
                                    size="lg"
                                />
                                {{ $t('shared.download.report') }}
                            </DownloadLink>
                        </div>
                    </div>
                </div>
            </div>
            <!-- [ /report title header ]-->

            <div
                v-if="!showLoadingSpinner && show"
                class="contentMain bg-brand-light-grey box-spacing"
            >
                <div class="container business-header">
                    <!-- [ box report header data ]-->
                    <template v-if="showHeaderData">
                        <div
                            v-if="showMonitoringEndInfo"
                            class="box box--pearl clearfix mb px-0"
                        >
                            <div class="col-12 col-sm-12 col-md-12 box-shadow-xy small-box-spacing bg-white py-4">
                                <div class="monitoringExpiration-container">
                                    <div><strong data-qa="monitoringExpirationHeadline">{{ $t("transaction.show.monitoring.expired.headline") }}</strong></div>
                                    <span
                                        class="monitoringExpiration-container--short"
                                        data-qa="monitoringExpirationTextShort"
                                    >{{ $t("transaction.show.monitoring.expired.description.1") }}&nbsp;</span>
                                    <a
                                        :href="'/bonitaetsauskunft/bonitaet-einer-firma-pruefen/detailseite-unternehmen/?businessId=' + businessId"
                                        class="legitimateInterest-container--button"
                                        data-qa="monitoringExpirationLink"
                                    >{{ $t("transaction.show.monitoring.expired.description.2") }}</a>.
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="row">
                        <div
                            class="col-12 col-md-4 box--product-data"
                            data-qa="processBox_2"
                        >
                            <div class="box-shadow-xy small-box-spacing bg-white h-100">
                                <h4>{{ $t('transaction.show.orderData') }}</h4>
                                <div class="box--product-data__container">
                                    <div
                                        v-if="productType"
                                        class="box--product-data__container--flex"
                                    >
                                        <span class="box--header-data__label box--header-data__label--width-increased">
                                            {{ $t('transaction.show.product') }}
                                        </span>
                                        <span
                                            class="box--header-data__value"
                                            data-qa="process-productType"
                                        >{{ productType }}</span>
                                    </div>
                                    <div class="pt-small box--product-data__container--flex">
                                        <span class="box--header-data__label box--header-data__label--width-increased">
                                            {{ $t('transaction.show.fetchDate') }}
                                        </span>
                                        <span
                                            class="box--header-data__value"
                                            data-qa="process-infoBoughtLastAt"
                                        >
                                            {{ formatDate(infoBoughtLastAt, 'L') }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="referenceNumber"
                                        class="pt-small box--product-data__container--flex"
                                    >
                                        <span class="box--header-data__label box--header-data__label--width-increased">
                                            {{ $t('shared.reference') }}
                                        </span>
                                        <span
                                            class="box--header-data__value"
                                            data-qa="process-referenceNumber-Value"
                                        >
                                            {{ referenceNumber }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="productOrderId"
                                        class="pt-small box--product-data__container--flex"
                                    >
                                        <span class="box--header-data__label box--header-data__label--width-increased">
                                            {{ $t('transaction.show.orderNumber') }}
                                        </span>
                                        <span
                                            class="box--header-data__value"
                                            data-qa="process-productOrderId"
                                        >
                                            {{ productOrderId }}
                                        </span>
                                    </div>

                                    <template v-if="featurePortfolioActive">
                                        <div
                                            v-if="orderName"
                                            class="pt-small box--product-data__container--flex"
                                        >
                                            <span
                                                class="box--header-data__label box--header-data__label--width-increased"
                                                data-qa="ordered-by"
                                            >
                                                {{ $t('transaction.list.transaction_getby') }}
                                            </span>
                                            <span
                                                class="box--header-data__value"
                                                data-qa="process-orderName"
                                            >
                                                {{ helpers.sbb(t, orderName) }}
                                            </span>
                                        </div>
                                    </template>

                                    <template v-if="isMonitoringCanceledShown">
                                        <div class="box--product-monitoring-data__container">
                                            <div
                                                class="text-info text-info--error-box text-align-center"
                                                style="margin: 1rem 0 1rem; background-color: white;"
                                            >
                                                <span
                                                    class="text-info text-info--small"
                                                    style="color: #183f6d;"
                                                    data-qa="monitoring-cancel-delayed-text"
                                                >
                                                    {{ $t('report.confirm.cancel.monitoring.delayed') }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="isReportTrackingShown">
                                        <div class="box--product-monitoring-data__container">
                                            <h4>
                                                {{ $t('transaction.reportTransactionSettings.headline') }}
                                            </h4>

                                            <div
                                                v-if="isShownInDal"
                                                class="pt-small"
                                                data-qa="process-Supplement"
                                            >
                                                <span class="box--header-data__label box--header-data__label--width-increased">
                                                    <span data-qa="process-Supplement-text">
                                                        {{ $t('watchlist.list.supplements') }}
                                                    </span>
                                                </span>
                                                <span class="box--header-data__value">
                                                    <span
                                                        :data-qa="supplementStateDataQa"
                                                        :class="supplementStateClass"
                                                    > {{ supplementStateText }} </span>
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            class="pt-small"
                                            data-qa="process-Monitoring"
                                        >
                                            <span class="box--header-data__label box--header-data__label--width-increased">
                                                <span data-qa="process-Monitoring-text">
                                                    {{ $t('transaction.data.monitoring') }}
                                                </span>
                                            </span>
                                            <span
                                                class="box--header-data__value"
                                            >
                                                <span
                                                    :data-qa="monitoringStateDataQa"
                                                    :class="monitoringStateClass"
                                                > {{ monitoringStateText }} </span>

                                            </span>
                                        </div>

                                        <template v-if="isShownInDal">
                                            <div
                                                class="pt-small"
                                                data-qa="process-MonitoringPlus"
                                            >
                                                <span class="box--header-data__label box--header-data__label--width-increased">
                                                    <span data-qa="process-MonitoringPlus-text">
                                                        {{ $t('transaction.data.monitoringPlus') }}
                                                    </span>
                                                </span>
                                                <span
                                                    class="box--header-data__value"
                                                >
                                                    <span
                                                        :data-qa="monitoringPlusStateDataQa"
                                                        :class="monitoringPlusStateClass"
                                                    > {{ monitoringPlusStateText }} </span>
                                                </span>
                                            </div>
                                        </template>

                                        <template v-if="isMonitoringOptionsShown">
                                            <div class="pt-small">
                                                <span class="box--header-data__label box--header-data__label--width-increased">&nbsp;</span>
                                                <span class="box--header-data__value">
                                                    <a
                                                        class="box--header-data__value--normal"
                                                        :class="isDetailsAndOptionsDisabled ? 'link link--disabled' : 'link'"
                                                        href="#"
                                                        :data-qa="isDetailsAndOptionsDisabled ? 'details-and-options-link-disabled' : 'details-and-options-link'"
                                                        :disabled="isDetailsAndOptionsDisabled"
                                                        @click.prevent="showSettings"
                                                    >
                                                        {{ $t('transaction.reportTransactionSettings.link.text') }}
                                                    </a>
                                                </span>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                                <!-- /ko -->

                                <div
                                    v-if="supplierinquiryId"
                                    class="box--product-supplierinquiry-data__container"
                                >
                                    <div class="pt-small">
                                        <span class="box--header-data__label box--header-data__label--width-increased">
                                            {{ $t('transaction.show.answerOrderQuery') }}
                                        </span>
                                        <a
                                            :href="isDifferentContext ? 'javascript:void(0);' : '/portal/lieferantenrueckfrage/lieferantenrueckfrage?supplierInquiryId=' +supplierinquiryId"
                                            :data-qa="isDifferentContext ? 'process-openSupplierinquiry-disabled' : 'process-openSupplierinquiry'"
                                        >
                                            {{ $t('transaction.show.supplierInquiryOpen') }}
                                        </a>
                                    </div>
                                </div>
                                <div class="mt clearfix d-flex">
                                    <span class="box--header-data__label box--header-data__label--width-increased">
                                        {{ $t('shared.myNote') }}
                                    </span>
                                    <div
                                        class="box--header-data__value mr"
                                    >
                                        <span
                                            v-if="!editMode"
                                            data-qa="process-myNote-Text"
                                            v-html="helpers.nl2br(myNote)"
                                        />
                                        <Textarea
                                            v-if="editMode"
                                            v-model="myNote"
                                            label=""
                                            name="additionalInfos"
                                            data-qa="process-myNote-Input"
                                            label-hidden
                                        />
                                    </div>
                                    <span
                                        v-if="!editMode"
                                        data-qa="process-myNote-Button"
                                        class="business-data-transaction__editButton"
                                        @click="startEditMode"
                                    >
                                        <font-awesome-icon
                                            icon="fa-light fa-pencil"
                                        />
                                    </span>
                                    <span
                                        v-if="editMode"
                                        data-qa="process-myNote-Button"
                                        class="business-data-transaction__editButton"
                                        @click="saveMyNote"
                                    >
                                        <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                                    </span>
                                </div>
                                <div
                                    v-if="!validation"
                                    class="validationMessage font-color--red"
                                    data-qa="process-myNote-ValidationMessage"
                                >
                                    {{ $t('transaction.report.validation.message') }}
                                </div>
                            </div>
                        </div>

                        <div
                            class="col-12 col-md-8 box--business-data"
                            data-qa="processBox_1"
                        >
                            <div class="box-shadow-xy small-box-spacing bg-white h-100">
                                <h3>
                                    {{ $t('transaction.chapter.FIRMENIDENTIFIKATION') }}
                                </h3>

                                <WatchlistIcon
                                    v-if="businessId && isDAL"
                                    class="text-align--right watchlist-icon"
                                    :business-id="businessId"
                                    :is-dal="true"
                                    :use-store="true"
                                />
                                <WatchlistIcon
                                    v-if="businessId && !isDAL"
                                    class="text-align--right watchlist-icon"
                                    :business-id="businessId"
                                    :is-dal="false"
                                    :use-store="true"
                                />
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div
                                            v-if="postalAddress"
                                            class="box--business-data__address-wrapper d-flex gap"
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-location-dot"
                                                class="location-icon"
                                            />
                                            <address class="box--business-data--address">
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-postalAddressStreet-Value"
                                                >
                                                    {{ postalAddress.street }}</span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-postalAddressHouseNumber-Value"
                                                >{{ postalAddress.houseNumber }}</span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-postalAddressHouseNumberSuffix-Value"
                                                >{{ postalAddress.houseNumberSuffix }}</span><br>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-postalAddressPostalCode-Value"
                                                >{{ postalAddress.zipCode }}&nbsp;</span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-postalAddressCity-Value"
                                                >{{ postalAddress.city }}</span>
                                                <br><span
                                                    class="box--header-data__value"
                                                    data-qa="process-postalAddressCountry-Value"
                                                >{{ postalAddress.country }}</span>
                                            </address>
                                        </div>
                                        <div class="box--business-data__phone-wrapper">
                                            <div
                                                v-if="phoneNumber"
                                                class="d-flex align-items-center gap"
                                            >
                                                <font-awesome-icon icon="fa-solid fa-phone" />
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-phoneNumber-Value"
                                                >
                                                    {{ phoneNumber }}</span>
                                            </div>
                                            <div
                                                v-if="faxNumber"
                                                class="d-flex align-items-center gap"
                                            >
                                                <font-awesome-icon icon="fa-solid fa-fax" />
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-faxNumber-Value"
                                                >
                                                    {{ faxNumber }}</span>
                                            </div>
                                            <div
                                                v-if="mobileNumber"
                                                class="d-flex align-items-center gap"
                                            >
                                                <font-awesome-icon icon="fa-solid fa-phone" />
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-mobileNumber-Value"
                                                >
                                                    {{ mobileNumber }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="box--business-data__phone-wrapper mt">
                                            <div v-if="emailAddress">
                                                <font-awesome-icon
                                                    icon="fa-light fa-envelope"
                                                    class="icon--inline-withlabel"
                                                />
                                                <span data-qa="process-eMailAddress-Value">{{ emailAddress }}</span>
                                            </div>
                                            <div v-if="internetAddress">
                                                <font-awesome-icon
                                                    icon="fa-light fa-globe"
                                                    class="icon--inline-withlabel"
                                                />
                                                <span data-qa="process-webSite-Value">{{ internetAddress }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="mt">
                                            <div>
                                                <template v-if="isDAL">
                                                    <span class="box--header-data__label box--header-data__label--width-increased">
                                                        {{ $t('shared.businessId') }}
                                                    </span>
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="businessId"
                                                    >
                                                        {{ businessIdDisplay }}
                                                    </span>
                                                </template>

                                                <template v-if="!isDAL">
                                                    <span class="box--header-data__label box--header-data__label--width-increased">
                                                        {{ $t('search.extended.identnumber') }}
                                                    </span>
                                                    <span
                                                        class="box--header-data__value"
                                                        data-qa="identnumber"
                                                    >
                                                        {{ businessIdDisplay }}
                                                    </span>
                                                </template>
                                            </div>
                                            <div v-if="status">
                                                <span class="box--header-data__label box--header-data__label--width-increased">
                                                    {{ $t('shared.status') }}
                                                </span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-status-Value"
                                                >
                                                    {{ status }}
                                                </span>
                                            </div>

                                            <div v-if="tax?.districtCourt">
                                                <span class="box--header-data__label box--header-data__label--width-increased">
                                                    {{ $t('transaction.show.districtCourt') }}
                                                </span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-districtCourt"
                                                >
                                                    {{ tax.districtCourt }}
                                                </span>
                                            </div>
                                            <div v-if="tax?.taxNumber">
                                                <span class="box--header-data__label box--header-data__label--width-increased">
                                                    {{ $t('transaction.show.taxNumber') }}
                                                </span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-taxNumber"
                                                >
                                                    {{ tax.taxNumber }}

                                                </span>
                                            </div>
                                            <div v-if="tax?.taxId">
                                                <span class="box--header-data__label box--header-data__label--width-increased">
                                                    {{ $t('transaction.show.taxId') }}
                                                </span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-taxID"
                                                >
                                                    {{ tax.taxId }}
                                                </span>
                                            </div>

                                            <div
                                                v-if="tradeName"
                                                class="vspace--3"
                                            >
                                                <span
                                                    class="box--header-data__label box--header-data__label--width-increased"
                                                    :title="$t('shared.handelsName')"
                                                >

                                                    {{ $t('shared.handelsName') }}

                                                </span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-tradeName-Value"
                                                >{{ tradeName }}</span>
                                            </div>

                                            <div
                                                v-if="commercialActivity"
                                                :class="!tradeName ? 'mt': ''"
                                            >
                                                <span
                                                    class="box--header-data__label box--header-data__label--width-increased"
                                                    :title="$t('shared.commercialActivity')"
                                                >
                                                    {{ $t('shared.commercialActivity') }}
                                                </span>
                                                <span
                                                    class="box--header-data__value"
                                                    data-qa="process-commercialActivity-Value"
                                                >{{ commercialActivity }}</span>
                                            </div>
                                            <div
                                                v-if="commercialActivity"
                                                :class="!commercialActivity ? 'mt' : ''"
                                            >
                                                <span
                                                    v-if="legalForm"
                                                    class="box--header-data__label"
                                                    :title="$t('shared.rechtsform')"
                                                >
                                                    {{ $t('shared.rechtsform') }}
                                                </span>
                                                <span
                                                    v-if="legalForm"
                                                    class="box--header-data__value"
                                                >
                                                    {{ legalForm.description }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box--header-data__infoText mt">
                                    <p
                                        v-if="negativeFactFound"
                                        class="box--header-data__value box--header-data__value--important"
                                        data-qa="process-importantNotice-Value"
                                    >
                                        {{ $t('transaction.collection.atleast.one.negative.fact.found') }}
                                    </p>
                                    <p v-if="chapters.length > 0">
                                        {{ $t('transaction.collection.pdf.contains.additional.information') }}
                                    </p>
                                    <p v-if="chapters.length == 0">
                                        {{ $t('transaction.collection.pdf.contains.full.information') }}
                                    </p>

                                    <div
                                        v-if="upgradeable"
                                        class="d-flex justify-content-between align-items-center gap"
                                    >
                                        <p
                                            v-if="upgradeableDAL"
                                            class="mr-3 ml-auto"
                                        >
                                            {{ $t('transaction.show.product.upgradable') }}
                                        </p>
                                        <p
                                            v-if="!upgradeableDAL"
                                            class="mr-3 ml-auto"
                                        >
                                            {{ $t('transaction.show.product.upgradable.international') }}
                                        </p>
                                        <a
                                            :href="upgradeUrl"
                                            data-qa="upgrade-product-button"
                                            class="btn btn-legacy-orange btn-default"
                                        >Upgrade</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- [ /box report header data ]-->
                    <div
                        v-if="statusantwort"
                        class="box-shadow-xy small-box-spacing bg-white mt"
                        data-qa="statusantwortBox"
                    >
                        <h3>{{ $t('transaction.chapter.STATUSANTWORT') }}</h3>
                        <p>
                            {{ statusantwort.grundStatusantwort }}
                        </p>
                        <template v-if="statusantwort.statusTextList">
                            <template
                                v-for="(statusText, index) in statusantwort.statusTextList"
                                :key="index"
                            >
                                <p>
                                    {{ statusText }}
                                </p>
                            </template>
                        </template>
                    </div>
                </div>

                <div
                    v-if="wkvShowOrderButton"
                    class="container wkv-offer-container mt"
                >
                    <div class="wkv-offer-image">
                        <img
                            width="1140"
                            src="@/assets/img/ads/banner-desktop.jpg"
                            class="wkv-offer-offerImage"
                        >
                        <img
                            class="wkv-offer-offerImage wkv-offer-offerMobileImage"
                            src="@/assets/img/ads/splashscreen-desktop.jpg"
                            width="1140"
                        >
                        <div class="wkv-offer-image-button-holder">
                            <div
                                id="wkvOfferTextTemplate"
                                class="wkv-offer-image-offerText"
                            >
                                {{ wkvMinPriceText }}
                            </div>
                            <div class="wkv-offer-image-buttonText">
                                <a
                                    :data-qa="isDifferentContext ? 'transaction_wkv_order_btn-disabled' : 'transaction_wkv_order_btn'"
                                    :class="isDifferentContext ? 'btn btn--disabled' : 'btn'"
                                    :href="!isDifferentContext ? `/bonitaetsauskunft/waren-kredit-versicherung/?transactionId=${transactionId}#calculator` : 'javascript:void(0);'"
                                    @click="sendClick('WKV_Details')"
                                >
                                    <span>Jetzt online berechnen</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    v-if="modalWKV"
                    :id="modalWKV.id"
                    :title="''"
                    :cancel-data-qa="modalWKV.cancelDataQa"
                    @on-close="modalWKV.onClose"
                >
                    <a
                        href="#"
                        :data-qa="modalWKV.confirmDataQa"
                        @click.prevent="modalWKV.onConfirm"
                    >
                        <img src="@/assets/img/wkv/splashscreen-desktop.jpg">
                    </a>
                </Modal>

                <div
                    v-if="wkvShowDetails && wkvCount"
                    class="container wkv-offer-container mt"
                >
                    <div class="wkv-offer-image wkv-offer-image-details">
                        <img
                            class="wkv-offer-offerImage"
                            src="@/assets/img/ads/banner-desktop.jpg"
                            width="1140"
                        >

                        <img
                            class="wkv-offer-offerImage wkv-offer-offerMobileImage"
                            src="@/assets/img/ads/banner-desktop.jpg"
                            width="1140"
                        >

                        <div class="wkv-insurance-box box--white box-shadow-xy small-box-spacing bg-white">
                            <div class="h3">
                                {{ $t('wkv.insurance.complete.title') }}
                            </div>

                            <div class="wkv-insurance-box__details row">
                                <div class="col-12 col-md-4 no-padding">
                                    <div>
                                        <span class="">{{ $t('wkv.insurance.complete.ordernumber') }}&nbsp;</span>
                                        <span
                                            class=""
                                            data-qa="wkv_details_insurance_ordernumber"
                                        >{{ wkvInsuranceOrderNr }}</span>
                                    </div>
                                    <div class="">
                                        <span class="">{{ $t('wkv.insurance.complete.sum') }}&nbsp;</span>
                                        <span
                                            class=""
                                            data-qa="wkv_details_insurance_amount"
                                        >{{ wkvInsuranceSum }}</span>
                                    </div>
                                    <div class="">
                                        <span class="">{{ $t('wkv.insurance.complete.period') }}&nbsp;</span>
                                        <span
                                            class=""
                                            data-qa="wkv_details_insurance_end"
                                        >{{ wkvInsuranceEndDate }}</span>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 no-padding">
                                    <div class="wkv-insurance-box__details__download--bg-soft-blue">
                                        <font-awesome-icon
                                            icon="fa-sharp fa-regular fa-download"
                                            size="2xl"
                                        />
                                        <DownloadLink
                                            :url="wkvInsuranceConfirmationDownload"
                                            data-qa="wkv_details_confirmation_downloadButton"
                                        >
                                            {{ $t('wkv.insurance.complete.download.text.0') }}
                                            <span>{{ $t('wkv.insurance.complete.download.text.1') }}</span>
                                        </DownloadLink>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 no-padding">
                                    <div :class="isDifferentContext ? 'wkv-insurance-box__details__inkasso--bg-soft-blue--disabled': 'wkv-insurance-box__details__inkasso--bg-soft-blue'">
                                        <a
                                            :data-qa="isDifferentContext ? 'wkv_details_collection_link-disabled' : 'wkv_details_collection_link'"
                                            :href="isDifferentContext ? 'javascript:void(0);' : `${inkassoUploadLinks[locale]}?wkvOrderNo=` + wkvInsuranceOrderNr"
                                        >
                                            {{ $t('wkv.insurance.complete.inkasso.text') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box box--pearl box--no-padding commercial-report__content mt">
                    <div
                        v-if="chapters.length > 3"
                        data-qa="processBox_chapterOverview"
                        class="bg-white box--sticky-top fixedsticky mt"
                    >
                        <AnchorLinks
                            :id="id + '.anchor-links'"
                            class="content-spacer"
                            :links="anchorLinks"
                            @change-selected="scrollTo($event.url)"
                        />
                    </div>

                    <div class="container container--crefo container--business-data">
                        <div data-bind="template: {name: 'supplementHistoryTemplate', if: $data.supplementHistory(), data: {boughtDate: infoBoughtLastAt, reportId: reportId, supplementHistory: $data.supplementHistory}}" />

                        <SupplementHistory
                            v-if="supplementHistory"
                            :supplement-history="supplementHistory"
                            :view-scope="viewScope"
                            class="mb"
                        />

                        <div
                            v-for="(chapter, index) of chapters"
                            :key="index"
                        >
                            <Bonitaet
                                v-if="chapter.type === 'BONITAET'"
                                :data="chapter"
                                class="mb"
                            />

                            <Bonitaetsentwicklung
                                v-if="chapter.type === 'BONITAETSENTWICKLUNG'"
                                :data="chapter"
                                class="mb"
                            />

                            <Bonitaetunternehmensinfo
                                v-if="chapter.type === 'BONITAETUNTERNEHMENSINFO'"
                                :data="chapter"
                                class="mb"
                            />

                            <StrukturdatenContainer
                                v-if="chapter.type === 'STRUKTURDATEN'"
                                :data="chapter"
                                class="mb"
                                :product-type-enum="productTypeEnum?.toString()"
                                :postal-address="postalAddress"
                                :is-disabled="isDisabled"
                            />

                            <GeschaeftstaetigkeitContainer
                                v-if="chapter.type === 'GESCHAEFTSTAETIGKEIT'"
                                :data="chapter"
                                class="mb"
                            />

                            <ZahlungsweiseKrediturteil
                                v-if="chapter.type === 'ZAHLUNGSWEISE_KREDITURTEIL'"
                                :data="chapter"
                                :footer="footer"
                                class="mb"
                            />
                            <BeteiligungsverhaeltnisseContainer
                                v-if="chapter.type === 'BETEILIGUNGSVERHAELTNISSE'"
                                :data="chapter"
                                class="mb"
                                :is-disabled="isDisabled"
                                :country-code="countryCode"
                                :postal-address="postalAddress"
                            />
                            <GeschaeftszahlenContainer
                                v-if="chapter.type === 'GESCHAEFTSZAHLEN'"
                                :data="chapter"
                                :product-type-enum="productTypeEnum"
                                :is-dal="isDAL"
                                class="mb"
                            />
                            <Unternehmensbilanz
                                v-if="chapter.type === 'UNTERNEHMENSBILANZ'"
                                :data="chapter"
                                :report-id="reportId"
                                :view-scope="viewScope"
                                class="mb"
                            />
                            <Konzernbilanz
                                v-if="chapter.type === 'KONZERNBILANZ'"
                                :data="chapter"
                                :report-id="reportId"
                                :view-scope="viewScope"
                                class="mb"
                            />
                            <ZusatzinformationenContainer
                                v-if="chapter.type === 'ZUSATZINFORMATIONEN'"
                                :data="chapter"
                                class="mb"
                            />
                            <ZahlungsinformationenUndBeurteilungContainer
                                v-if="chapter.type === 'ZAHLUNGSINFORMATIONEN_UND_BEURTEILUNG'"
                                :data="chapter"
                                :postal-address="postalAddress"
                                class="mb"
                            />

                            <HinweiseZurAuskunft
                                v-if="chapter.type === 'HINWEISE_ZUR_AUSKUNFT'"
                                :data="chapter"
                                class="mb"
                            />

                            <FehlberichtContainer
                                v-if="chapter.type === 'HINWEISE_ZUM_FEHLBERICHT'"
                                :data="chapter"
                                class="mb"
                            />

                            <Nachtragsdaten
                                v-if="chapter.type === 'NACHTRAGSDATEN'"
                                :data="chapter"
                                class="mb"
                            />
                        </div>

                        <Nachtragsdaten
                            v-if="nachtragsdaten"
                            :data="nachtragsdaten"
                            class="mb"
                        />

                        <div v-if="footer">
                            <div class="row ">
                                <div class="col-12 col-md-9">
                                    <small>{{ footer.text }}</small>
                                    <small>{{ footer.ergaenzung }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <template
        v-if="transactionData && reportTransaction && showSettingsOverlay"
    >
        <!-- display_1 -->
        <InNachtragsfristOhneMonitoring
            v-if="monitoringState === 'IN_NACHTRAGSFRIST_OHNE_MONITORING'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_2 -->
        <InNachtragsfristUnbefristetMonitoringOhnePlus
            v-if="monitoringState === 'IN_NACHTRAGSFRIST_UNBEFRISTET_MONITORING_OHNE_PLUS'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_3 -->
        <InNachtragsfristBefristetMonitoring
            v-if="monitoringState === 'IN_NACHTRAGSFRIST_BEFRISTET_MONITORING'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_4 -->
        <InNachtragsfristUnbefristetMonitoringBefristetPlus
            v-if="monitoringState === 'IN_NACHTRAGSFRIST_UNBEFRISTET_MONITORING_BEFRISTET_PLUS'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_5 -->
        <InNachtragsfristBefristetMonitoringMitPlus
            v-if="monitoringState === 'IN_NACHTRAGSFRIST_BEFRISTET_MONITORING_MIT_PLUS'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_6 -->
        <UnbefristetMonitoringOhnePlusAktiv
            v-if="monitoringState === 'UNBEFRISTET_MONITORING_OHNE_PLUS_AKTIV'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_7 -->
        <BefristetMonitoringAktiv
            v-if="monitoringState === 'BEFRISTET_MONITORING_AKTIV'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_8 -->
        <UnbefristetMonitoringBefristetPlusAktiv
            v-if="monitoringState === 'UNBEFRISTET_MONITORING_BEFRISTET_PLUS_AKTIV'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_9 -->
        <BefristetMonitoringMitPlusAktiv
            v-if="monitoringState === 'BEFRISTET_MONITORING_MIT_PLUS_AKTIV'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_10 -->
        <UnbefristetMonitoringAktivIntl
            v-if="monitoringState === 'UNBEFRISTET_MONITORING_AKTIV_INTL'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_11 -->
        <BefristetMonitoringAktivIntl
            v-if="monitoringState === 'BEFRISTET_MONITORING_AKTIV_INTL'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
        <!-- display_12 -->
        <InNachtragsfristUnbefristetMonitoringPlus
            v-if="monitoringState === 'IN_NACHTRAGSFRIST_UNBEFRISTET_MONITORING_UNBEFRISTET_PLUS'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />

        <!-- display_13 -->
        <UnbefristetMonitoringUnbefristetPlusAktiv
            v-if="monitoringState === 'UNBEFRISTET_MONITORING_UNBEFRISTET_PLUS_AKTIV'"
            :report-transaction="reportTransaction"
            :transaction-data="transactionData"
            @on-close="hideSettings"
        />
    </template>

    <LegitimationOverlay
        v-if="showLegitimationOverlay"
        :product-order-id="productOrderId"
        @abort="abortLegitimation"
        @success="onLegitimationSuccess"
        @failed="onLegitimationError"
    />

    <Modal
        v-if="modal"
        :id="modal.id"
        :title="modal.title"
        :cancel-text="modal.cancelText"
        :confirm-text="modal.confirmText"
        :error-context="modal.errorContext"
        :icon="modal.icon"
        @on-close="modal.onClose"
        @on-confirm="modal.onConfirm"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="modal.content" />
    </Modal>
</template>

<script lang="ts" setup>
import async from 'async'
import { computed, onMounted, ref, watch, } from 'vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useRoute } from 'vue-router'
import { FamilyTreeService } from '@/helper/services/familyTree'
import { useUserStore } from '@/stores/user'
import { differentContextActive, getMemberNavigationSession } from '@/helper/services/memberNavigation'
import { getCbraProductAvailability, getCbraTransaction, getCbraUserPreferences, getMonitoringStateFromCbra,getLatestWkvFromCbra , getCbraReportHistory } from '@/services'
import { showError, showModalError } from '@/helper/services/error'
import { CbraReportTransaction, CbraTransaction } from '@/types/cbra/transactions'
import CONSTANTS from '@/data/constants'
import { getReportOrderLegitimation } from '@/services'
import { ModalProps } from '@/types/components/modal'
import Modal from '@/components/Modal.vue'
import { useI18n } from 'vue-i18n'
import { checkUserRight } from '@/helper/services/userRights'
import LoadingRing from '@/components/LoadingRing.vue'
import { accessToFeature } from '@/helper/services/features'
import { getStatus } from '@/helper/reportTracking'
import { currency, formatDate } from '@/filters'
import Cookies from 'js-cookie'
import { saveNoteForTransaction, toggleFavourite } from '@/helper/services/transaction'
import { WKVHelper } from '@/helper/wkv'
import { getInsuranceMatrix } from '@/helper/services/WKV'
import { ProductService } from '@/helper/services/product'
import { SupplierInquiryService } from '@/helper/services/supplierInquiry'
import { type CbraReportTransactionData, type CbraReportTransactionDataHeaderData, type CbraReportTransactionChapter, type CbraReportTransactionNachtragsdaten, type CbraReportHistoryData } from '@/types/cbra/reports'
import helpers from '@/helper/misc'
import { isAxiosError } from 'axios'
import { countryByCode } from '@/helper/countryCodes'
import Textarea from '@/components/Input/Textarea.vue'
import WatchlistIcon from '@/components/Watchlist/WatchlistIcon.vue'
import Bonitaet from '@/components/Transactions/InfoChapter/Bonitaet.vue'
import Bonitaetsentwicklung from '@/components/Transactions/InfoChapter/Bonitaetsentwicklung.vue'
import HinweiseZurAuskunft from '@/components/Transactions/InfoChapter/HinweiseZurAuskunft.vue'
import Bonitaetunternehmensinfo from '@/components/Transactions/InfoChapter/Bonitaetunternehmensinfo.vue'
import StrukturdatenContainer from '@/components/Transactions/InfoChapter/Strukturdaten/StrukturdatenContainer.vue'
import ZahlungsweiseKrediturteil from '@/components/Transactions/InfoChapter/ZahlungsweiseKrediturteil.vue'
import GeschaeftstaetigkeitContainer from '@/components/Transactions/InfoChapter/Geschaeftstaetigkeit/GeschaeftstaetigkeitContainer.vue'
import Nachtragsdaten from '@/components/Transactions/InfoChapter/Nachtragsdaten.vue'
import SupplementHistory from '@/components/Transactions/InfoChapter/SupplementHistory/SupplementHistory.vue'
import BeteiligungsverhaeltnisseContainer from '@/components/Transactions/InfoChapter/Beteiligungsverhaeltnisse/BeteiligungsverhaeltnisseContainer.vue'
import GeschaeftszahlenContainer from '@/components/Transactions/InfoChapter/Geschaeftszahlen/GeschaeftszahlenContainer.vue'
import Unternehmensbilanz from '@/components/Transactions/InfoChapter/Unternehmensbilanz.vue'
import ZusatzinformationenContainer from '@/components/Transactions/InfoChapter/Zusatzinformationen/ZusatzinformationenContainer.vue'
import ZahlungsinformationenUndBeurteilungContainer from '@/components/Transactions/InfoChapter/ZahlungsinformationenUndBeurteilung/ZahlungsinformationenUndBeurteilungContainer.vue'
import Konzernbilanz from '@/components/Transactions/InfoChapter/Konzernbilanz.vue'
import DownloadLink from '@/components/DownloadLink.vue'
import { sendClick, trackClick } from '@/helper/webtrekk'
import InNachtragsfristOhneMonitoring from '@/components/Transactions/Overlays/ReportTracking/InNachtragsfristOhneMonitoring.vue'
import InNachtragsfristBefristetMonitoring from '@/components/Transactions/Overlays/ReportTracking/InNachtragsfristBefristetMonitoring.vue'
import InNachtragsfristBefristetMonitoringMitPlus from '@/components/Transactions/Overlays/ReportTracking/InNachtragsfristBefristetMonitoringMitPlus.vue'
import InNachtragsfristUnbefristetMonitoringOhnePlus from '@/components/Transactions/Overlays/ReportTracking/InNachtragsfristUnbefristetMonitoringOhnePlus.vue'
import InNachtragsfristUnbefristetMonitoringPlus from '@/components/Transactions/Overlays/ReportTracking/InNachtragsfristUnbefristetMonitoringPlus.vue'
import UnbefristetMonitoringUnbefristetPlusAktiv from '@/components/Transactions/Overlays/ReportTracking/UnbefristetMonitoringUnbefristetPlusAktiv.vue'
import BefristetMonitoringAktiv from '@/components/Transactions/Overlays/ReportTracking/BefristetMonitoringAktiv.vue'
import BefristetMonitoringAktivIntl from '@/components/Transactions/Overlays/ReportTracking/BefristetMonitoringAktivIntl.vue'
import UnbefristetMonitoringAktivIntl from '@/components/Transactions/Overlays/ReportTracking/UnbefristetMonitoringAktivIntl.vue'
import UnbefristetMonitoringBefristetPlusAktiv from '@/components/Transactions/Overlays/ReportTracking/UnbefristetMonitoringBefristetPlusAktiv.vue'
import InNachtragsfristUnbefristetMonitoringBefristetPlus from '@/components/Transactions/Overlays/ReportTracking/InNachtragsfristUnbefristetMonitoringBefristetPlus.vue'
import UnbefristetMonitoringOhnePlusAktiv from '@/components/Transactions/Overlays/ReportTracking/UnbefristetMonitoringOhnePlusAktiv.vue'
import BefristetMonitoringMitPlusAktiv from '@/components/Transactions/Overlays/ReportTracking/BefristetMonitoringMitPlusAktiv.vue'
import LegitimationOverlay from '@/components/Transactions/LegitimationOverlay.vue'
import AnchorLinks from '@/components/AnchorLinks.vue'
import { scrollTo } from '@/helper/scrollto'
import KapitelType from '@/data/kapitelType'
import InfoBoxDisableMessage from '@/components/Report/InfoBoxDisableMessage.vue'
import FamilyTreeListener from '@/listeners/FamilyTreeListener'
import { adsLinks, inkassoUploadLinks, reportsLandingpageConsumerLinks, reportsOrderFormLinks, udsLinks } from '@/data/typo3Links'
import FehlberichtContainer from '@/components/Transactions/InfoChapter/Fehlbericht/FehlberichtContainer.vue'

const id = 'portal.mc.detail-transaction'

const route = useRoute()
const userStore = useUserStore()
const { t, locale } = useI18n()

const CONSTS = {
    LIEFERART: {
        NACHTRAG: 'NACHTRAG',
        STATUSANTWORT: 'STATUSANTWORT',
        AUSKUNFT: 'AUSKUNFT'
    },
    TERMINATION_STATE: {
        IDLE: 'IDLE',
        SUCCESS: 'SUCCESS',
        ERROR: 'ERROR'
    },
    CBRA_TRANSLATIONS: {
        general: {
            unknown: t('transaction.chapter.shared.UNBEKANNT'),
            bestValue: t('transaction.chapter.BONITAET.bestValue'),
            worstValue: t('transaction.chapter.BONITAET.worstValue')
        },
        trafficLightDal: {
            h1: t('transaction.chapter.BONITAET.trafficLightDal.h1'),
            h2: t('transaction.chapter.BONITAET.trafficLightDal.h2')
        },
        indexDal: {
            h1: t('transaction.chapter.BONITAET.indexDal.h1'),
            h2: t('transaction.chapter.BONITAET.indexDal.h2')
        },
        riskClassDal: {
            h1: t('transaction.chapter.BONITAET.riskClassDal.h1')
        },
        trafficLightInt: {
            h1: t('transaction.chapter.BONITAET.trafficLightInt.h1')
        },
        indexInt: {
            h1: t('transaction.chapter.BONITAET.indexInt.h1')
        },
        riskClassInt: {
            h1: t('transaction.chapter.BONITAET.riskClassInt.h1')
        }
    }
}

const showLegitimationOverlay = ref(false)
const modalWKV = ref<ModalProps>()
const modal = ref<ModalProps>()
const showSettingsOverlay = ref(false)
const show = ref(true)
const showLoadingSpinner = ref(true)
const showPDFError = ref( route.query.pdfError?.toString()|| false)
const wkvOrderNoPDFError = ref( route.query.errorDocumentWkvId?.toString() || '')
// const active = ref(true)
const transactionId = ref(route.query.transactionId?.toString() ?? '')
const transactionData = ref()
const isReport = ref(false)

// const referrer = ref(document.referrer)

const isDisabled = ref(false)
const isDifferentContext = ref(false)

//transactionsData
const reportId = ref('')
const reportBusinessId = ref() // die reportBusinessId ist nötig, da reportID in DE gekürzt wird
const favourite = ref()
const reportTransaction = ref<CbraReportTransaction>()
const businessId = ref('')
const crefoId = computed(() => {
    if (!businessId.value) {
        return null
    } else {
        return helpers.businessIdToCrefoId(businessId.value)
    }
})
const businessName = ref('')
const postalAddress = ref()
const productOrderId = ref()
const expiryDate = ref()
const infoBoughtLastAt = ref()
const myNote = ref('')
const validation = computed(() => myNote.value.length <= 200)
const monitoring = ref()
const monitoringPlus = ref()
const supplementEndDate = ref<null | number>(null)
const monitoringEndTime = ref<null | number>(null)
const monitoringPlusEndTime = ref<null | number>(null)
const reportBusinessCase = ref('')
const earliestMonitoringCancelationDate = ref<null | number>(null)
const showTerminateMonitoring = ref(false)
const showOrderMonitoring = ref(true)
const isMonitoringCanceledShown = ref(false)
const monitoringExpired = ref(false)
const monitoringPlusExpired = ref(false)
const supplementClass = ref('')
const supplementMonitoringPlusClass = ref('')
const supplementMonitoringDesc = ref('')
const supplementMonitoringPlusDesc = ref('')

const downloadReportUrl = computed(() => ProductService.getReportDownloadPdfLink(reportId.value))

watch(monitoring, () => {
    if (monitoring.value) {
        if (monitoringEndTime.value === null && monitoringPlusEndTime.value === null) {
            showOrderMonitoring.value = false
            showTerminateMonitoring.value = true
        } else if (monitoringEndTime.value === 253392444000000) {
            supplementMonitoringDesc.value = ''
            showOrderMonitoring.value = false

            monitoring.value = null
            supplementEndDate.value = null
            monitoringEndTime.value = null
            isMonitoringCanceledShown.value = true
        } else if (monitoringPlusEndTime.value === 253392444000000) {
            supplementMonitoringDesc.value = ''
            showOrderMonitoring.value = false

            monitoring.value = null
            supplementEndDate.value = null
            monitoringEndTime.value = null
            isMonitoringCanceledShown.value = true
        } else {
            let status = getStatus(monitoringEndTime.value)
            supplementMonitoringDesc.value = t('transaction.data.monitoring')
            if (status === 'nearlyExpired') {
                monitoringExpired.value = false
                supplementClass.value = 'font-color--red'
            } else if (status === 'expired') {
                showOrderMonitoring.value =false
                monitoringExpired.value =true
                supplementClass.value ='font-color--red'
                return `${t('transaction.data.monitoring.expired')}`
            } else {
                supplementClass.value =''
            }
        }
    } else {
        if (monitoringEndTime.value && supplementEndDate.value && monitoringEndTime.value <= supplementEndDate.value || (monitoringEndTime.value === undefined || monitoringEndTime.value === null) && supplementEndDate.value ) {
            let status = getStatus(supplementEndDate.value)
            supplementMonitoringDesc.value = t('watchlist.list.supplements')
            if (status === 'nearlyExpired') {
                monitoringPlusExpired.value = false
                supplementMonitoringPlusClass.value = 'font-color--red'
            } else if (status === 'expired') {
                monitoringPlusExpired.value = true
                supplementClass.value = 'font-color--red'
                return `${t('transaction.data.monitoring.expired')}`
            } else {
                supplementClass.value = ''
            }
        } else {
            let status = getStatus(monitoringEndTime.value)
            supplementMonitoringDesc.value =t('transaction.data.monitoring')
            if (status === 'nearlyExpired') {
                monitoringExpired.value = false
                supplementClass.value = 'font-color--red'
            } else if (status === 'expired') {
                monitoringExpired.value = true
                showOrderMonitoring.value = false
                supplementClass.value = 'font-color--red'
                return `${t('transaction.data.monitoring.expired')}`
            } else {
                supplementClass.value =''
            }
        }
    }
})

const supplementMonitoringPlusValue = ref()
watch(monitoring, () => {
    if (monitoring.value) {
        if (monitoringPlus.value) {
            let returnValue = null
            supplementMonitoringPlusDesc.value = t('report.detail.monitoringPlus.ads.text')
            if (monitoringPlusEndTime.value) {
                let status = getStatus(monitoringPlusEndTime.value)
                if (status === 'nearlyExpired') {
                    monitoringPlusExpired.value =false
                    supplementMonitoringPlusClass.value ='font-color--red'
                } else if (status === 'expired') {
                    monitoringPlusExpired.value =true
                    supplementMonitoringPlusClass.value ='font-color--red'

                    supplementMonitoringPlusValue.value = `${t('transaction.data.monitoring.expired')}`
                } else {
                    supplementClass.value =''
                }
                returnValue = formatDate(monitoringPlusEndTime.value, 'L')
            } else {
                returnValue = t('report.detail.monitoring.unlimited')
            }

            supplementMonitoringPlusValue.value = returnValue
        }
    }
})

const showMonitoringEndInfo = computed(() => {
    return monitoringExpired.value
})

const supplierinquiryId = ref()
const upgradeable = ref()

const upgradeableDAL = computed(() => { // Used to show if upgrade is possible within 24 hours or until end of month
    return typeof reportBusinessCase.value === 'string' && reportBusinessCase.value.indexOf('_DAL') !== -1
})

const countryCode = ref()
const isDAL = computed(() => {
    return isDal()
})

const businessIdDisplay = computed(() => {
    return isDAL.value ? crefoId.value : businessId.value
})

//BusinessData
const department = ref()
const tradeName = ref() // possible synonymous with this.department
const commercialActivity = ref()
const status = ref()
const emailAddress = ref()
const internetAddress = ref()
const phoneNumber = ref()
const mobileNumber = ref()
const faxNumber = ref()
const tax = ref()
const showTaxRow = ref(true)

//headerData
const productType = ref()
const productTypeEnum = ref(route.query.productType)
const referenceNumber = ref()
const openSince = ref()
const orderNumber = ref()
const showHeaderData = ref(true)
const orderName = ref()

//single "chapters"
const footer = ref()
const updateData = ref()
const anfrageDaten = ref()
const statusantwort = ref()
const nachtragsdaten = ref<CbraReportTransactionNachtragsdaten>()
const legalForm = ref()

const editMode = ref(false)

const negativeFactFound = ref(false)

//chapters
const chapters = ref<CbraReportTransactionChapter[]>([])
const anchorLinks = computed(() => {
    const filteredChapters = chapters.value.filter((chapter) => {
        return getTemplateName(chapter)
    })

    const newChapters = filteredChapters.map((chapter) => {
        return {
            id: chapter.type,
            i18nKey: 'transaction.chapter.' + chapter.type,
            url: '#' + chapter.type,
            dataQa: 'boxLink-' + chapter.type
        }
    })
    if (nachtragsdaten.value) {
        newChapters.push({
            id: 'NACHTRAGSDATEN',
            i18nKey: t('transaction.chapter.NACHTRAGSDATEN'),
            url: '#NACHTRAGSDATEN',
            dataQa: 'boxLink-NACHTRAGSDATEN'

        })
    }
    return newChapters
})

//nachtragshistorie
const supplementHistory = ref<CbraReportHistoryData[]>([])

// wkv
const wkvFeatureActive = ref(false)
const wkvShowOrderButton = ref(false)
const wkvShowDetails = ref(false)
const wkvMinPrice = ref(0)
const wkvMaxPrice = ref(0)

const wkvMinPriceText = computed(() => {
    if (wkvMinPrice.value <= 0) {
        return t('transaction.report.wkv_teaser.offerText', { minPrice: currency(51) })
    }
    return t('transaction.report.wkv_teaser.offerText', { minPrice: currency(wkvMinPrice.value) })
})

const wkvMinMaxPriceText = ref()

const wkvCount = ref(0)
const wkvInsuranceOrderNr = ref('')
const wkvInsuranceSum = ref('')
const wkvInsurancePeriod = ref('')
const wkvInsuranceEnd = ref()
const wkvTimestamp = ref()
const wkvInsuranceEndDate = computed(() => {
    return wkvInsuranceEnd.value ? formatDate(wkvInsuranceEnd.value, 'L') : ''
})
const wkvInsuranceConfirmationDownload = ref('')
const wkvOverlayCookie = Cookies.get(CONSTANTS.COOKIES.WKV_OVERLAY_SHOWN)
const featurePortfolioActive = computed(() => {
    return accessToFeature('portfolioViewActive')
})

const isDetailsAndOptionsDisabled = ref(false)
const monitoringState = ref('')

const isShownInDal = computed(() => {
    return monitoringState.value !== 'UNBEFRISTET_MONITORING_AKTIV_INTL' && monitoringState.value !== 'BEFRISTET_MONITORING_AKTIV_INTL'
})

const isMonitoringOptionsShown = computed(() => {
    return Boolean(monitoringState.value) !== false && monitoringState.value !== 'NO_OPTIONS'
})

if (monitoring.value === true && monitoringEndTime.value === 253392444000000) {
    isMonitoringCanceledShown.value = true
    // isMonitoringOptionsShown.value = false
}

const supplementStateDataQa = ref('')
const supplementStateClass = ref('')
const supplementStateText = ref('')

watch(supplementEndDate, (newValue) => {
    if (newValue) {
        let status = getStatus(newValue)
        if (status === 'nearlyExpired') {
            supplementStateDataQa.value = 'process-Supplement-nearlyExpired'
            supplementStateClass.value = 'font-color--red'
            supplementStateText.value = t('transaction.reportTransactionSettings.until') + ' ' + formatDate(newValue, 'L')
        } else if (status === 'expired') {
            supplementStateDataQa.value = 'process-Supplement-expired'
            supplementStateClass.value = 'font-color--red'
            supplementStateText.value = t('transaction.data.monitoring.expired')
        } else {
            supplementStateDataQa.value = 'process-Supplement-value'
            supplementStateClass.value = ''
            supplementStateText.value = t('transaction.reportTransactionSettings.until') + ' ' + formatDate(supplementEndDate.value, 'L')
        }
    } else {
        supplementStateDataQa.value = ''
        supplementStateClass.value = ''
        supplementStateText.value = ''
    }
})

const monitoringStateDataQa = ref('')
const monitoringStateClass = ref('')
const monitoringStateText = ref('')

watch([monitoring, monitoringEndTime], () => {
    if (!monitoring.value && monitoringEndTime.value === null) {
        monitoringStateDataQa.value = 'process-Monitoring-inactive'
        monitoringStateClass.value = ''
        monitoringStateText.value = t('transaction.reportTransactionSettings.state.not.active')
    } else if (monitoring.value && monitoringEndTime.value) {
        let status = getStatus(monitoringEndTime.value)
        if (status === 'nearlyExpired') {
            monitoringStateDataQa.value = 'process-Monitoring-nearlyExpired'
            monitoringStateClass.value = 'font-color--red'
            monitoringStateText.value = t('transaction.reportTransactionSettings.until') + ' ' + formatDate(monitoringEndTime.value, 'L')
        } else if (status === 'expired') {
            monitoringStateDataQa.value = 'process-Monitoring-expired'
            monitoringStateClass.value = 'font-color--red'
            monitoringStateText.value = t('transaction.data.monitoring.expired')
        } else {
            monitoringStateDataQa.value = 'process-Monitoring-value'
            monitoringStateClass.value = ''
            monitoringStateText.value = t('transaction.reportTransactionSettings.until') + ' ' + formatDate(monitoringEndTime.value, 'L')
        }
    } else {
        monitoringStateDataQa.value = 'process-Monitoring-unlimited'
        monitoringStateClass.value = 'font-color--green'
        monitoringStateText.value = t('report.detail.monitoring.unlimited')
    }
},{ immediate: true })

const monitoringPlusStateDataQa = ref('')
const monitoringPlusStateClass = ref('')
const monitoringPlusStateText = ref('')

watch([monitoringPlus, monitoringPlusEndTime], () => {
    if (!monitoringPlus.value && monitoringPlusEndTime.value === null) {
        monitoringPlusStateDataQa.value = 'process-MonitoringPlus-inactive'
        monitoringPlusStateClass.value = ''
        monitoringPlusStateText.value = t('transaction.reportTransactionSettings.state.not.active')
    } else if (monitoringPlus.value && monitoringPlusEndTime.value) {
        let status = getStatus(monitoringPlusEndTime.value)
        if (status === 'nearlyExpired') {
            monitoringPlusStateDataQa.value = 'process-MonitoringPlus-nearlyExpired'
            monitoringPlusStateClass.value = 'font-color--red'
            monitoringPlusStateText.value = t('transaction.reportTransactionSettings.until') + ' ' +formatDate(monitoringPlusEndTime.value, 'L')
        } else if (status === 'expired') {
            monitoringPlusStateDataQa.value = 'process-MonitoringPlus-expired'
            monitoringPlusStateClass.value = 'font-color--red'
            monitoringPlusStateText.value = t('transaction.data.monitoringPlus.expired')
        } else {
            monitoringPlusStateDataQa.value = 'process-MonitoringPlus-value'
            monitoringPlusStateClass.value = ''
            monitoringPlusStateText.value = t('transaction.reportTransactionSettings.until') + ' ' + formatDate(monitoringPlusEndTime.value, 'L')
        }
    } else {
        monitoringPlusStateDataQa.value = 'process-MonitoringPlus-unlimited'
        monitoringPlusStateClass.value = 'font-color--green'
        monitoringPlusStateText.value = t('report.detail.monitoring.unlimited')
    }
},{ immediate: true })

const isReportTrackingShown = computed(() => {
    return monitoring.value || supplementEndDate.value || monitoringEndTime.value
})

const legitimateInterest = ref()
const legitimationInfo = ref(false)
const isLegitimationSaving = ref(false)
const reportLanguage = ref(route.query.reportLanguage)

if (productTypeEnum.value === 'FAMILYTREE_PLUS') {
    legitimateInterest.value = route.query.legitimateInterest
    reportLanguage.value = route.query.reportLanguage
    referenceNumber.value = undefined
    if (route.query.referenceNumber !== 'undefined') {
        referenceNumber.value = route.query.referenceNumber
    }
    businessId.value = String(route.query.businessId)
    monitoring.value = route.query.monitoring === 'true' ? true : false
}

const familyTreeUrl = ref()
const isFamilyTree = ref(false)

const upgradeUrl = computed(() => {
    return `${udsLinks[locale.value]}?country=${countryCode.value}&businessId=${reportBusinessId.value}`
})

const viewScope = ref('USER')

onMounted(async()=> {
    if (route.query.productType === 'FAMILYTREE_PLUS') {
        await loadCurrentFamilyTree()
    } else {
        await loadCurrentTransaction()
    }
})

function hide() {
    showLoadingSpinner.value = false
}

async function loadCurrentFamilyTree() {
    isFamilyTree.value = true
    let data = {
        legitimateInterest: legitimateInterest.value,
        reportLanguage: reportLanguage.value,
        productType: productTypeEnum.value,
        referenceNumber: referenceNumber.value,
        businessId: businessId.value,
        monitoring: monitoring.value
    }

    try {
        const response = await FamilyTreeService.getFamilyTreeUrl(data)
        if (helpers.isModalProps(response)) {
            const modalprops = response
            modalprops.onConfirm = () => modal.value = undefined
            modalprops.onClose = () => modal.value = undefined
            modal.value = modalprops
        } else {
            familyTreeUrl.value = response?.data.url
            FamilyTreeListener.injectEventListener()
        }
    } catch (error) {
        console.error('Error occurred while loading the family tree:', error)
    } finally {
        hide()
    }
}

async function loadCurrentTransaction() {
    if (!transactionId.value) return
    isReport.value = true

    let actFor = getMemberNavigationSession()

    if (actFor && userStore.user.cr_membernumber !== actFor) {
        isDisabled.value =true
        isDifferentContext.value = true
    } else {
        isDisabled.value = false
        isDifferentContext.value = false
    }

    // business logik:
    // 1. hole transaktion
    // 2. checke, ist es die eigene
    //   2.1 wenn ja, dann anzeigen
    //   2.2 wenn nein, checke recht auf portfolio-auskunft
    //     2.2.1 wenn recht portfolio-auskunft vorhanden, dann auskunft anzeigen MIT Abfrage nach berechtigtem Interesse
    //     2.2.2 wenn recht portfolio-auskunft NICHT vorhanden, dann auskunft NICHT anzeigen -> Fehler-Modal "fehlende Berechtigung anzeigen (offen: Was machen nach Klick auf OK? -> Weiterleitung auf Dashboard?)
    // 3. hole Infos zur Legitimation
    // 4. hole Produktinformationen, wenn ich in einem anderen Context unterwegs bin, da die Produktinformationen nicht von der Watchlist-Funktionalität bereitgestellt werden. WatchlistIcon wird nicht angezeigt, wenn in einem anderen Context

    //     try {
    //         const { data } = await getCbraTransaction(transactionId.value)
    //         transactionData.value = data
    //     } catch (error) {
    //         showLoadingSpinner.value =false
    //         if (showPDFError.value) {
    //             showModalError({ topic: 'ProductService', func: 'getReportTransactionData', xhr: { status: 404 } })
    //         } else {
    //             showError({ topic: 'SUPPLEMENT_HISTORIE', func: 'getReportHistory', xhr: xhr })
    //         }
    //     }

    // try {
    //     if(transactionData) {
    //                     getMonitoringStateFromCbra(transactionId())
    //                         .then((monitoringState) => {
    //                             this.monitoringState(monitoringState);
    //                             this.loadReportTrackingSettings(transactionData);
    //                             cb2(null, transactionData, monitoringState)
    //                         });
    //                 }
    // } catch (error) {

    // }

    async.waterfall([
        (cb1: CallableFunction) => {
            getCbraTransaction(transactionId.value).then(({ data }) => {
                transactionData.value = data
                cb1(null, data)
            }, (xhr) => {
                showLoadingSpinner.value = false
                if (showPDFError.value) {
                    const modalprops = showModalError({ topic: 'ProductService', func: 'getReportTransactionData', xhr: { status: 404 } })
                    if (modalprops) {
                        modalprops.onClose = () => modal.value = undefined
                        modalprops.onConfirm = () => modal.value = undefined
                        modal.value = modalprops
                    }
                } else {
                    const modalprops = showError({ topic: 'SUPPLEMENT_HISTORIE', func: 'getReportHistory', xhr: xhr })
                    if (modalprops) {
                        modalprops.onClose = () => modal.value = undefined
                        modalprops.onConfirm = () => modal.value = undefined
                        modal.value = modalprops
                    }
                }
                cb1(null, transactionData.value)
            })
        },
        (transactionData:CbraTransaction, cb2: CallableFunction) => {
            if (transactionData) {
                getMonitoringStateFromCbra(transactionId.value)
                    .then((response) => {
                        monitoringState.value = response.data
                        loadReportTrackingSettings()
                        cb2(null, transactionData, monitoringState)
                    })
            }
        },
        (transactionData: CbraTransaction, monitoringState: string, cb3: CallableFunction) => {
            if (transactionData) {
                let isOwnTransaction = (transactionData.transactionType !== 'COLLECTION' && transactionData.cbraUser.memberUserId !== transactionData?.reportTransaction?.memberUserId) ? false : true
                viewScope.value = isOwnTransaction ? 'USER' : 'MEMBER'

                cb3(null, isOwnTransaction, transactionData, monitoringState)
            } else {
                cb3(null, false, false)
            }
        },
        (isOwnTransaction: boolean, transactionData: CbraTransaction, monitoringState: string, cb5: CallableFunction) => {
            if (!transactionData) {
                cb5(null, false)
            } else {
                if (isOwnTransaction && transactionData.reportTransaction?.productOrderId) {
                    getReportOrderLegitimation(transactionData.reportTransaction.productOrderId).then(({ data }) => {
                        if (data.length === 0) {
                            legitimateInterest.value = '' // reset dropdown
                            loadUserPreferences()
                            isLegitimationSaving.value = false
                            showLegitimationOverlay.value = true
                            cb5(null, false)
                        } else {
                            cb5(null, true)
                        }
                    })
                } else {
                    const response = checkUserRight('portfolioReport')
                    if (response.grants === true && transactionData.reportTransaction?.productOrderId) {
                        getReportOrderLegitimation(transactionData.reportTransaction.productOrderId).then(({ data }) => {
                            if (data.length === 0) {
                                legitimateInterest.value = '' // reset dropdown
                                loadUserPreferences()
                                isLegitimationSaving.value = false
                                showLegitimationOverlay.value = true
                                cb5(null, false)
                            } else {
                                cb5(null, true)
                            }
                        })
                    } else {
                        showLoadingSpinner.value = false
                        modal.value = {
                            id: 'userrights-not-granted',
                            title: t('error.userrights.saveUnauthorized'),
                            content: t('error.userrights.saveUnauthorized.description'),
                            icon: 'error',
                            dataQa: 'userrights-not-granted',
                            onConfirm: () => {
                                window.location.href = '/'
                            }
                        }
                        cb5(null, false)
                    }
                }
            }
        },
        (legitimation: any, cb6: CallableFunction) => {
            if (isDifferentContext.value) {
                getProductInformation()
            }
            cb6(null, true)
        }
    ], (err, result) => {
        if (result) {
            showTransaction()
        }
    })
}

function isDal() {
    return ['DE', 'AT', 'LU'].includes(countryCode.value)
}

/**
 * Laden des jeweiligen Overlays für die Optionen des Überwachung
 *
 */
function loadReportTrackingSettings() {
    if (monitoringState.value) {
        switch (monitoringState.value) {
        case 'OPTIONS_DISABLED':
            isDetailsAndOptionsDisabled.value = true
            break
        default:
            break
        }
    }
}

async function getProductInformation() {
    if (transactionData.value && transactionData.value.reportTransaction && transactionData.value.reportTransaction.businessId) {
        try {
            const response = await getCbraProductAvailability(transactionData.value.reportTransaction.businessId)
            const businessData = response.data

            if (typeof businessData !== 'object' || businessData === null) {
                return Promise.reject()
            }

            if (typeof businessData.productInfos === 'object' && businessData.productInfos !== null) {
                // ko.postbox.publish(constants.PB.TRANSACTION_DATA_PRODUCT_INFOS, businessData.productInfos);
                // notifier.notifySubscribers(null, constants.EVENTS.TRANSACTION_DATA_PRODUCT_INFOS_LOADED);
            }

            return Promise.resolve(true)
        } catch (error) {
            return Promise.reject(error)
        }
    } else {
        return Promise.reject()
    }
}

async function loadUserPreferences() {
    try {
        const { data } = await getCbraUserPreferences()
        if (data && typeof data.defaultReportLegitimateInterest !== 'undefined' && data.defaultReportLegitimateInterest !== null) {
            legitimateInterest.value = data.defaultReportLegitimateInterest
        }
    } catch (error) {
        console.error(error)
    }
}

async function showTransaction() {
    await updateTransactionData(transactionData.value)
    await updateWkvOrderButton(transactionData.value)
    updateWkvOrderData(transactionData.value)
    let handleError = false

    try {
        const reportData = await ProductService
            .getReportTransactionData(
                transactionData.value.reportId,
                transactionData.value.reportTransaction ? transactionData.value.reportTransaction.reportBusinessCase : null,
                CONSTS.CBRA_TRANSLATIONS,
                handleError,
                transactionData.value
            )
        if (helpers.isModalProps(reportData)) {
            const modalprops = reportData
            modalprops.onConfirm = () => modal.value = undefined
            modalprops.onClose = () => modal.value = undefined
            modal.value = modalprops
        } else if (reportData) {
            updateReportData(reportData, transactionData.value)
        }
    } catch (error) {
        if (showPDFError.value) {
            const modalprops = showModalError({ topic: 'ProductService', func: 'showPDFError', xhr: { status: 404 } })
            modalprops.onClose = () => modal.value = undefined
            modalprops.onConfirm = () => modal.value = undefined
            modal.value = modalprops
        }
    }

    hide()
    await getReportHistory(transactionId.value)
}

async function updateTransactionData(jsonReportData: any) {
    transactionData.value = jsonReportData
    reportId.value = jsonReportData.reportId
    myNote.value = jsonReportData.note ?? ''
    monitoring.value = jsonReportData.monitoring
    monitoringPlus.value = jsonReportData.reportTransaction.monitoringPlus
    favourite.value =jsonReportData.favourite
    reportTransaction.value = jsonReportData.reportTransaction
    upgradeable.value = jsonReportData.upgradeable
    referenceNumber.value = jsonReportData.reportTransaction.referenceNumber
    orderName.value = jsonReportData.owner
    //fill data
    if (jsonReportData.reportTransaction) {
        monitoring.value =jsonReportData.reportTransaction.monitoring
        supplementEndDate.value = jsonReportData.reportTransaction.supplementEndDate
        monitoringEndTime.value = jsonReportData.reportTransaction.monitoringEndTime
        monitoringPlusEndTime.value = jsonReportData.reportTransaction.monitoringPlusEndTime
        expiryDate.value = jsonReportData.reportTransaction.supplementEndDate
        reportBusinessCase.value = jsonReportData.reportTransaction.reportBusinessCase
        earliestMonitoringCancelationDate.value = jsonReportData.reportTransaction.earliestMonitoringCancelationDate
        // get translation
        if (jsonReportData.reportTransaction.reportProductType) {
            productTypeEnum.value =jsonReportData.reportTransaction.reportProductType
            productType.value =t('shared.' + jsonReportData.reportTransaction.reportProductType)
        }
        infoBoughtLastAt.value = jsonReportData.reportTransaction.creationTime
        businessId.value =jsonReportData.reportTransaction.businessId

        if (jsonReportData.reportTransaction.businessName) {
            businessName.value = jsonReportData.reportTransaction.businessName
        } else if (jsonReportData.reportTransaction.surname && jsonReportData.reportTransaction.givenName) {
            businessName.value =`${jsonReportData.reportTransaction.givenName} ${jsonReportData.reportTransaction.surname}`
        } else if (jsonReportData.reportTransaction.surname && !jsonReportData.reportTransaction.givenName) {
            businessName.value = jsonReportData.reportTransaction.surname
        } else if (!jsonReportData.reportTransaction.surname && jsonReportData.reportTransaction.givenName) {
            businessName.value = jsonReportData.reportTransaction.givenName
        } else {
            businessName.value = ''
        }

        reportBusinessId.value =jsonReportData.reportTransaction.businessId
        referenceNumber.value = jsonReportData.reportTransaction.referenceNumber
        countryCode.value = jsonReportData.reportTransaction.postalAddress.country
        if (jsonReportData.reportTransaction.postalAddress && jsonReportData.reportTransaction.postalAddress.country) {
            const country = countryByCode(
                jsonReportData.reportTransaction.postalAddress.country
            )
            jsonReportData.reportTransaction.postalAddress.country = country?.i18nKey ? t(country.i18nKey) : ''
        }
        postalAddress.value = jsonReportData.reportTransaction.postalAddress
        productOrderId.value =jsonReportData.reportTransaction.productOrderId
        // get open supplier inquiry
        if (!isEmptyObject(productOrderId.value) && !differentContextActive()) {
            try {
                const response= await SupplierInquiryService.getSupplierInquiryByOrderNo(productOrderId.value)
                const supplierInquiryData = response.data
                if (supplierInquiryData.elements.length > 0) {
                    supplierinquiryId.value = supplierInquiryData.elements[0].id
                }
            } catch (error) {
                if (isAxiosError(error) && error?.response?.status !== 403) {
                    return showError({ topic: 'SupplierInquiryService', xhr: error })
                }
            }
        }
    }
}

async function getReportHistory(transactionId: string) {
    try {
        const { data } = await getCbraReportHistory(transactionId)
        supplementHistory.value = data
    } catch (error) {
        if (isAxiosError(error)) {
            const modalprops = showError({ topic: 'SUPPLEMENT_HISTORIE', func: 'getReportHistory', xhr: error })
            if (modalprops) {
                modalprops.onConfirm = () => modal.value = undefined
                modalprops.onClose = () => modal.value = undefined
                modal.value = modalprops
            }
        }
    }
}

function updateReportData(reportData: CbraReportTransactionData, transactionData: CbraTransaction) {
    if (reportData.firmenidentifikation) {
        updateReportBusinessData(reportData.firmenidentifikation)
    }
    if (reportData.kopfdaten) {
        updateReportHeaderData(reportData.kopfdaten)
    }

    // set single "chapters"
    footer.value = reportData.fussdaten
    if (typeof transactionData.reportTransaction === 'object' && transactionData.reportTransaction?.reportTransactionStatus === 'PRELIMINARY_REPORT_DELIVERED') {
        updateData.value = reportData.aktualisierungshinweis
    }

    chapters.value = reportData.kapitel.kapitelList

    negativeFactFound.value = reportData.kopfdaten.negativmerkmale

    if (reportData.kopfdaten.lieferart === CONSTS.LIEFERART.NACHTRAG) {
        nachtragsdaten.value = reportData.nachtragsdaten
    } else if (reportData.kopfdaten.lieferart === CONSTS.LIEFERART.STATUSANTWORT) {
        statusantwort.value = reportData.statusantwort
    }

    anfrageDaten.value = reportData.anfragedaten
    statusantwort.value = reportData.statusantwort
    showHeaderData.value = !isEmptyObject(reportData.firmenidentifikation) || !isEmptyObject(reportData.kopfdaten)
}

async function updateWkvOrderButton(jsonReportData: any) {
    if (accessToFeature('wkvActive') && !differentContextActive()) {
        const response = await WKVHelper.checkForWkvButton(jsonReportData)
        wkvFeatureActive.value = response
        wkvShowOrderButton.value = response
        if (response) {
            updateWkvOrderMinPrice(jsonReportData)
        }
    }
}

function updateWkvOrderMinPrice(jsonReportData: any) {
    if (accessToFeature('wkvActive')) {
        getInsuranceMatrix(jsonReportData.reportId).then(response => {
            if (response.minPrice) { wkvMinPrice.value = response.minPrice }
            if (response.maxPrice) { wkvMaxPrice.value = response.maxPrice }

            if (response.minPrice || response.maxPrice) {
                wkvMinMaxPriceText.value =
                    t('wkv.overlay.minMaxPrice', [
                        `${WKVHelper.currencyFormatter.format(response.minPrice)} €`,
                        `${WKVHelper.currencyFormatter.format(response.maxPrice)} €`
                    ]
                    )
            } else {
                wkvMinMaxPriceText.value = ''
            }

            if (!wkvOverlayCookie) {
                showWkvOverlay()
            }
        })
    }
}

async function saveMyNote() {
    if (validation.value) {
        editMode.value = false
        if (transactionData.value.myNote !== myNote.value) {
            // save
            transactionData.value.myNote = myNote.value
            const modalprops = await saveNoteForTransaction(transactionData.value.myNote, transactionId.value)
            if (modalprops) {
                modalprops.onConfirm = () => modal.value = undefined
                modalprops.onClose = () => modal.value = undefined
                modal.value = modalprops
            }
        }
    }
}

function updateWkvOrderData(jsonReportData: any) {
    if (!jsonReportData || !jsonReportData.reportTransaction || !jsonReportData.reportTransaction.productOrderId) {
        return
    }

    if (accessToFeature('wkvActive')) {
        wkvFeatureActive.value = true

        if (testWkvPDFError()) {
            const modalprops = showModalError({ topic: 'WkvService', func: 'showPDFError', xhr: { status: 404 } })
            modalprops.onConfirm = () => modal.value = undefined
            modalprops.onClose = () => modal.value = undefined
            modal.value = modalprops
        }

        getLatestWkvFromCbra(jsonReportData.reportTransaction.productOrderId).then(({ data }) => {
            const wkvOrders = data[0]
            if ( Object.prototype.toString.call(wkvOrders) === '[object Object]' && Object.prototype.toString.call(wkvOrders.orderRequest) === '[object Object]' ) {
                wkvCount.value = 1
                wkvShowDetails.value = true
                wkvInsuranceSum.value = t('wkv.order.insurance.coverage.' + wkvOrders.orderRequest.insuranceSum)
                wkvInsurancePeriod.value = t('wkv.order.insurance.time.' + wkvOrders.orderRequest.insurancePeriod)
                wkvTimestamp.value = wkvOrders.orderTimestamp
                wkvInsuranceOrderNr.value = wkvOrders.orderRequest.orderNo
                wkvInsuranceEnd.value = wkvOrders.insuranceEnd
                wkvInsuranceConfirmationDownload.value = WKVHelper.getWkvPdf(wkvOrders.orderRequest.orderNo, 'CONFIRMATION')
            } else {
                wkvCount.value =0
            }
        })
    }
}

function testWkvPDFError() {
    let pdfError = new RegExp('^WKV-', 'g')
    return (showPDFError.value && wkvOrderNoPDFError.value && pdfError.test(wkvOrderNoPDFError.value)) ? true : false
}

function updateReportBusinessData(jsonReportData: any) {
    /** override transaction data**/
    if (jsonReportData.firmenname) {
        businessName.value = jsonReportData.firmenname
    }
    if (jsonReportData.street && jsonReportData.zipcode && jsonReportData.city && jsonReportData.country) {
        postalAddress.value = {
            street: jsonReportData.street,
            zipCode: jsonReportData.zipcode,
            city: jsonReportData.city,
            country: jsonReportData.country
        }
    }
    department.value = jsonReportData.handelsName
    status.value = jsonReportData.firmenstatus
    emailAddress.value = jsonReportData.emailAdresse
    internetAddress.value = jsonReportData.internetAdresse
    phoneNumber.value = jsonReportData.telefonNummer
    faxNumber.value = jsonReportData.telefaxNummer
    mobileNumber.value = jsonReportData.mobilNummer
    if (!jsonReportData.umsatzsteuerId && !jsonReportData.amtsgericht && !jsonReportData.steuernummer) {
        showTaxRow.value = false
    }
    tax.value = {
        taxId: jsonReportData.umsatzsteuerId,
        districtCourt: jsonReportData.amtsgericht,
        taxNumber: jsonReportData.steuernummer
    }

    if (jsonReportData.handelsName) {
        tradeName.value = jsonReportData.handelsName
    }
    if (jsonReportData.gewerbetaetigkeit) {
        commercialActivity.value = jsonReportData.gewerbetaetigkeit
    }
}

function showWkvOverlay() {
    if (isDifferentContext.value || isDisabled.value) { return }

    Cookies.set(CONSTANTS.COOKIES.WKV_OVERLAY_SHOWN, 'true', { path: '/', secure: true, sameSite: 'Lax' })

    modalWKV.value = {
        id: 'wkv-overlay',
        title: '',
        confirmText: '',
        dataQa: 'productTeaserBox-backside',
        confirmDataQa: 'transaction_wkv_overlay_btn',
        cancelDataQa: 'transaction_wkv_overlay_close',
        onClose: () => {
            trackClick('WKV_overlay_click_close', undefined, 'wkv.overlay.click.close')
            modalWKV.value = undefined
        },
        onConfirm: () => {
            sendClick('WKV_overlay_click_jetzt_berechnen')
            window.location.href = `/bonitaetsauskunft/waren-kredit-versicherung/?transactionId=${transactionId.value}#calculator`
        },
    }
}

function updateReportHeaderData(headerData: CbraReportTransactionDataHeaderData) {
    /** override transaction data**/
    if (headerData) {
        if (headerData.produktbezeichnung) {
            productType.value = headerData.produktbezeichnung
        }
        if (headerData.ihrZeichen) {
            referenceNumber.value = headerData.ihrZeichen
        }
        if (headerData.zeitpunktGenerierung) {
            openSince.value = headerData.zeitpunktGenerierung
        }
        orderNumber.value = headerData.auftragsNummer
    }
}

function startEditMode() {
    editMode.value = true
}

function isEmptyObject(obj: any) {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false
        }
    }
    return true
}

function getMemberUserId() {
    return reportTransaction.value?.memberUserId
}

function toggleFavouriteTransaction() {
    favourite.value = !favourite.value
    transactionData.value.favourite = favourite.value
    return toggleFavourite(transactionData.value)
}

function showSettings() {
    if (isDetailsAndOptionsDisabled.value) {
        return
    }
    showSettingsOverlay.value = true
}

function hideSettings() {
    showSettingsOverlay.value = false
}

function onLegitimationSuccess() {
    isLegitimationSaving.value = true
    showLegitimationOverlay.value = false
    showTransaction()
}

function onLegitimationError() {
    showLegitimationOverlay.value = false
    modal.value = {
        id: 'error',
        title: t('error.transaction.notReady'),
        content: t('error.transaction.notReady.description'),
        icon: 'warning',
        confirmText: t('shared.modals.ok'),
        onClose: () => {
            modal.value = undefined
        },
        onConfirm() {
            modal.value = undefined
        },
    }
}

function getTemplateName(chapter: CbraReportTransactionChapter) {
    let templateName = null

    if (chapter.type in KapitelType) {
        const key = chapter.type as keyof typeof KapitelType
        const templateObject = KapitelType[key]

        if ('template' in templateObject) {
            templateName = templateObject.template
        }
    }

    return templateName
}

function abortLegitimation() {
    showLoadingSpinner.value = false
    showHeaderData.value = false
    legitimationInfo.value = true
    isReport.value = false
    showLegitimationOverlay.value = false
}

</script>

<style lang="less" scoped>
@light-navy: #183f6d;
:deep(.table-responsive table.table.product-content-box__table) {
    p, a, span {
        margin-bottom: 0;
        line-height: 1.36;
    }

    color: @light-navy;

    a.link {
        background-size: 6px 13px;
        padding-left: 13px;
    }

    thead tr th {
        border: none;
        vertical-align: middle;

        p {
            color: white;
        }
    }

    tbody td {
        padding: 12px;
    }
}

:deep(.product-content-box__text) {
    p:last-of-type {
        margin-bottom: 0;
    }
}

</style>

<style  lang="less">
@perfect-white: #ffffff;
@cerulean: #008dd9;
@pearl: #eaeaea;
@greyish-brown: #4a4a4a;
@screen-md-max: 1199px;
@screen-sm-max: 991px;
@screen-xs-max: 767px;
@lipstick: #e05469;
@light-navy: #183f6d;
@lime-dark-green: #35b235;
@duck-egg-blue:     #c7e4f3;
@trans-black:       rgba(0, 0, 0, 0.2);

.business-data-transaction, .family-tree-transaction {
  min-height: 400px;

  @media (max-width: @screen-sm-max) {
    .box--sm-space {
      .col-12:nth-child(n+2) {
        margin-top: 20px;
      }
    }
  }

  &__editButton {
    color: @cerulean;
    border-radius: 5px;
    border: 1px solid @cerulean;
    min-width: 25px;
    height: 25px;
    display: block;
    padding: 2px 0 0 5px;
    cursor: pointer;
    margin-left: auto;

    &:hover {
      color: @perfect-white;
      background-color: @cerulean;
    }

    &.glyphicon-pencil--disabled {
      cursor: not-allowed;
      opacity: 0.4;

      &:hover {
        background-color: @perfect-white;
        color: @cerulean;
        border: 1px solid @cerulean;
      }
    }
  }

  &__myNoteArea {
    max-width: 160px;
  }

  .business-header {
    h3 {
      margin-bottom: 1.5rem;
    }

    .box--business-data,
    .box--business-data {
      h3 {
        padding-right: 4.5rem;
      }

      .watchlist-icon {
        position: absolute;
        top: 1.9rem;
        right: 2rem;

        .icon--watchlist-add,
        .icon--watchlist-delete {
          cursor: pointer;
        }

        .ajaxloader__wrapper {
          top: -1.5rem;
          left: -1.4rem;
        }
      }
    }

    .box--product-data {

      &__container {
        border-bottom: 1px solid #cdd4db;
        margin-bottom: 17px;
        padding-bottom: 1rem;

        &--flex {
          display: flex;
        }
      }

      &__monitoring-info {
        .box--product-data__label {
          width: 91px;
        }

        .box--product-header__value {
          margin-left: 92px;
          &--normal {
            font-weight: normal;
          }
        }

        .icon {
          margin-top: 0;
        }
      }
    }

    .box--header-data {
      &__label {
        line-height: 1.5rem;
        width: 120px;
        display: inline-block;
        margin-right: 1rem;
        overflow: auto;

        &--width-increased {
          width: 130px;
          min-width: 130px;
        }
      }
      &__value.font-color--red {
        color: @lipstick;
      }

      &__value {
        line-height: 1.5rem;
        width: auto;
        max-width: 100%;
        overflow: auto;
        color: @light-navy;
        font-weight: 600;
        display: inline-block;
        word-break: break-all;
        &--important {
          color: @lipstick;
        }
        &--ellipsis {
          /* display: inline-flex;
          white-space: nowrap;
          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
          } */
          white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        }
      }

      &__infoText {
        font-size: 1.4rem;
        color: @greyish-brown;
        text-align: right;
      }
    }
  }

  .font-color--red {
    color: @lipstick;
  }

  .font-color--green {
    color: @lime-dark-green;
  }

  .icon--inline {
    margin-top: 7px;
    padding-left: 2.2rem;
    float: left;
  }

  .icon--inline-withlabel {
    margin-top: 4px;
    margin-right: 1rem;
  }

  &__update {
    background-color: @duck-egg-blue;
    margin-bottom: 2rem;
  }

  .box--business-data {

    .box--business-data__address-wrapper {
      address {
        display: inline-block;
        line-height: 2.5rem;
      }

      .icon--place {
        margin-top: 5px;
      }
    }
  }

  .product-type-wrapper {
    .icon {
      float: left;
    }

    div {
      margin-left: 62px;
    }
  }

  .commercial-report {
    &__appendix {
      &__title{
        color: @greyish-brown;
        font-weight: 600;
        font-size: 1.4rem;
      }
      strong {
        color: @greyish-brown;
      }
      .box--blue {
        background-color:@cerulean;
      }
      @media (max-width: @screen-xs-max) {
        background-color: @pearl;
      }
    }
    &__content {
      .box--sticky-top {
        border-bottom: 1px solid #eaeaea;
      }

      @media (max-width: @screen-xs-max) {
        .row--button {
          margin-top: -10px;
          padding: 10px 0 20px;
          button {
            width: 80%;
            margin: 20px auto;
            display: block;
          }
        }
      }
    }
    &__chapter-overview {
      padding: 5px 0 10px;
    }
    &__history-list {
      @media (max-width: @screen-xs-max) {
        .arrow_link {
          margin-left: 20px;
        }
      }
    }
  }

  /* common styles, icons, buttons and links */
  .box--product-data {
    .box--product-data__container {
      .box--product-supplierinquiry-data__container,
      .box--product-monitoring-data__container {
        border-top: 1px solid #cdd4db;
        padding-top: 1rem;
        margin-top: 1rem;

        .text-info--error-box {
          margin: 1rem -2rem 1.8rem;
        }
      }
    }
  }

  .box--product-data > h4 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .box--product-data .box--product-data__container {
    padding-bottom: 1.8rem;

    h4 {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.negativmerkmal-entry {
  &__indirekt {
    font-style: italic;
    &--bold {
      font-weight: bold;
    }
  }
}

.monitoring-tooltip {
  overflow: unset;
  white-space: normal;
  text-overflow: unset;

  .tooltip.in {
    opacity: 1;
  }

  .tooltip-inner {
    max-width: 300px;
    width: 300px;
  }
}

.wkv-offer-container {
  .wkv-offer-image {
    position: relative;

    @media (max-width: @screen-md-max) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &.wkv-offer-image-details {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .wkv-insurance-box {
      position: relative;
      top: auto;
      left: auto;
      padding: 20px;
      height: auto;
    }

    div {
      z-index: 5;
      color: @perfect-white;

      @media (max-width: @screen-xs-max) {
        z-index: inherit;
      }
    }

    .wkv-offer-offerImage {
      max-width: 100%;
      height: auto !important;
    }

    .wkv-offer-offerMobileImage {
      display: none;
    }

    .wkv-offer-image-teaser {
      position: absolute;
      top: 0;
      left: 20px;
      background-color: #f9e047;
      padding: 10px 12px 8px 12px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 2.4rem;
    }

    .wkv-offer-image-headline-holder {
      position: absolute;
      left: 20px;
      bottom: 25px;
      display: flex;
      flex-direction: column;

      .wkv-offer-image-headline {
        /* font-size: 2.4rem; */
        /* font-weight: bold; */
        margin-bottom: 2px;
        width: 190px;
        background: @perfect-white;
      }

      .wkv-offer-image-subline {
        /* font-size: 2.4rem; */
        margin-top: 0px;
      }

      span {
        background-color: #fff;
        padding: 5px 11px 3px 10px;
      }
    }

    .wkv-offer-image-button-holder {
      position: absolute;
      right: 85px;
      bottom: 25px;
      width: 410px;

      .wkv-offer-image-offerText {
        font-size: 1.2rem;
        line-height: 1.2rem;
        text-align: left;
        margin-bottom: 25px;
      }

      .wkv-offer-image-buttonText {
        color: #fff;
        text-align: left;

        a {
          background-color: #DDDF4B;
          color: #fff;
          text-transform: initial;
          font-size: 20px;
          font-weight: bold;
          min-height: 48px;
          line-height: 48px;

          /*border-radius: 3px;
          padding: 14px 25px 14px 63px;
          background-image: url("../img/icns/calc.png");
          background-position: 25px 50%;
          background-repeat: no-repeat;*/
        }

        a:hover, a:focus {
          /*background-color: #183f6d;*/
          color: #004884;
        }

        a.btn--disabled {
          cursor: not-allowed;
          &:hover, &:focus, &:visited, &:active {
            background-color: #0084cf;
          }
        }
      }
    }

    @media (max-width: @screen-md-max) {

      .wkv-offer-image-headline-holder,
      .wkv-offer-image-button-holder {
        bottom: 15px;
        right: 15px;
        width: 315px;
      }

      .wkv-offer-image-button-holder .wkv-offer-image-offerText {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 15px;
      }

      .wkv-offer-image-button-holder {
        .wkv-offer-image-buttonText {
          a {
            font-size: 1.8rem;
            letter-spacing: 0.1px;
            min-height: auto;
            line-height: 35px;
          }
        }
      }

    }

    @media (max-width: @screen-sm-max) {

      .wkv-offer-image-teaser,
      .wkv-offer-image-headline-holder .wkv-offer-image-headline,
      .wkv-offer-image-headline-holder .wkv-offer-image-subline {
        font-size: 14px;
      }

      .wkv-offer-image-button-holder {
        right: 18px;
        bottom: 5px;
        width: 250px;

        .wkv-offer-image-offerText,
        .wkv-offer-image-buttonText {
          font-size: 1.4rem;
          line-height: 18px;
          margin-bottom: 5px;
        }
      }

      .wkv-offer-image-button-holder {
        .wkv-offer-image-buttonText {
          a {
            font-size: 1.4rem;
            letter-spacing: 0.1px;
            min-height: auto;
            line-height: 28px;
          }
        }
      }

    }

    @media (max-width: @screen-xs-max) {

      .wkv-offer-image-button-holder {
        width: auto;
      }

      .wkv-offer-offerImage {
        display: none;
      }

      .wkv-offer-offerMobileImage {
        display: inline;
      }

      /* .wkv-offer-image-teaser,
      .wkv-offer-image-headline-holder .wkv-offer-image-headline,
      .wkv-offer-image-headline-holder .wkv-offer-image-subline {
        font-size: 2.4rem;
      } */

      .wkv-offer-image-offerText,
      .wkv-offer-image-buttonText {
        font-size: 1.6rem;
      }

      .wkv-offer-image-headline-holder,
      .wkv-offer-image-button-holder {
        position: static;
        bottom: unset;
        top: 0;
        margin: 0;
      }

      .wkv-offer-image-headline-holder {
        /* padding: 20px; */
        position: absolute;
        bottom: 150px;
        left: 20px;

        .wkv-offer-image-headline {
          /* margin-bottom: 8px; */

          span {
            background-color: transparent;
            padding: 0;
          }
        }

        .wkv-offer-image-subline {
          span {
            background-color: transparent;
            padding: 0;
          }
        }
      }

      .wkv-offer-image-button-holder {
        /* margin: 0 20px; */
        position: absolute;
        top: auto;
        bottom: 20px;
        left: 20px;

        .wkv-offer-image-offerText {
          text-align: left;
          font-size: 1.6rem;
          margin-bottom: 15px;
        }

        .wkv-offer-image-buttonText {
          text-align: center;

          a {
            line-height: 48px;
            font-size: 1.8rem;
            display: block;
            background-image: none;
          }
        }
      }
    }

    @media (max-width: 360px) {
      .wkv-offer-image-teaser,
      .wkv-offer-image-headline-holder .wkv-offer-image-headline,
      .wkv-offer-image-headline-holder .wkv-offer-image-subline {
        font-size: 1.8rem;
      }

      .wkv-offer-image-button-holder {
        .wkv-offer-image-offerText {
          font-size: 1.6rem;
        }

        .wkv-offer-image-buttonText {
          a {
            font-size: 1.4rem;
            letter-spacing: 0.1px;
          }
        }
      }
    }
  }
}

.wkv-offer-container .wkv-offer-image .wkv-insurance-box.box--white {
  div {
    color: @light-navy;
  }

  .col-xs-12.no-padding {
    @media (max-width: @screen-xs-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@duck-egg-blue-two: #e5f3fb;
@dark-cerulean:     #327AB7;
@perfect-black:     #000000;

.wkv-insurance-box {
  position: absolute;
  top: 20px;
  left: 20px;
  height: calc(~"100% - 40px");
  padding: 0 20px;
  min-width: 70%;

  @media (max-width: @screen-sm-max) {
    position: relative;
    left: auto;
    top: auto;
    height: auto;
    padding: 10px 15px;
    float: left;
    width: 100%;
  }

  @media (max-width: @screen-md-max) {
    /*position: absolute;
    top: 10px;
    left: 20px;
    height: calc(~"100% - 20px");
    padding: 0 20px;
    min-width: 70%;
    width: auto;*/
  }

  .h3 {
    margin: 10px 0;
  }

  span {
    font-size: 1.1rem;
    font-weight: 600;
  }

  &__details__download {
    &--bg-soft-blue{
      background-color: @duck-egg-blue-two;
      color: @dark-cerulean;
      font-weight: 700;
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: @screen-sm-max) {
        margin-top: 2rem;
        position: relative;
        justify-content: flex-start;
      }

      a {
        padding: 10px 0 10px 15px;
        font-size: 1.1rem;
        display: block;
        position: relative;

        @media (max-width: @screen-sm-max) {
          margin-left: 20px;
        }

        &:hover {
          text-decoration: none;
          span {
            color: @perfect-black;
          }
        }
        span {
          display: block;
          color: @greyish-brown;
        }
      }
    }
  }

  &__details__inkasso {

    &--bg-soft-blue,
    &--bg-soft-blue--disabled {
      background-color: @duck-egg-blue-two;
      color: @dark-cerulean;
      margin-left: 10px;
      text-align: center;
      font-weight: 700;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: @screen-sm-max) {
        margin-top: 2rem;
        margin-left: 0;
        position: relative;
        justify-content: flex-start;
      }

      a {
        padding: 10px;
        font-size: 1.1rem;
        display: block;

        @media (max-width: @screen-sm-max) {
          margin-left: 20px;
        }

        &:hover {
          text-decoration: none;
          span {
            color: @perfect-black;
          }
        }
        span {
          display: block;
          color: @greyish-brown;
        }
      }
    }
    &--bg-soft-blue--disabled,
    &--bg-soft-blue--disabled a {
        cursor: not-allowed;
        opacity: .4;

        &:hover {
          text-decoration: none;
          color: #009ee2;
          span {
            color: #009ee2;
          }
        }
    }
  }
}

@snow:              #f2f2f2;
@pinkish-grey:      #d1d1d1;
.table-list {
  overflow: hidden;
  background-color:@perfect-white;
  @media (max-width: @screen-sm-max) {
    &.table-list--origin
    &.table-list--historie {
      margin: 0px;
    }
  }
  &__row {
    background-color: @pearl;
    display: flex;
    &:nth-child(even) {
      background-color: @snow;
    }
    &.table--highlight {
      background-color: @pinkish-grey !important;
    }
    &--padding {
      margin-top: 5px;
      padding: 10px;
    }
    &--font {
      line-height: 1.25;
    }
    @media (max-width: @screen-sm-max) {
      display: block;
    }
  }
  &__td {
    padding: 10px;
    overflow: hidden;
    &--border {
      border: 2px solid @perfect-white;
    }
  }

  &__td-content {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &--flex {
    .table-list__row {
      background-color: transparent;

      @media (max-width: @screen-xs-max) {
        flex-direction: column;
      }

      &--break-sm {
        @media (max-width: @screen-sm-max) {
          flex-direction: column;
        }
      }

      &:nth-child(even) {
        background-color: transparent;
        .table-list__td {
          background-color: @snow;
        }
      }

      @media (max-width: @screen-sm-max) {
        display: flex;
      }
    }

    .table-list__td {
      background-color: @pearl;
      display: flex;
      align-items: center;

      &--w10 {
        width: 10%;
      }

      &--w20 {
        width: 20%;
      }

      &--w30 {
        width: 30%;
      }

      &--w40 {
        width: 40%;
      }

      &--w50 {
        width: 50%;
      }

      &--w60 {
        width: 60%;
      }

      &--w70 {
        width: 70%;
      }

      &--w80 {
        width: 80%;
      }

      &--w90 {
        width: 90%;
      }

      &--w100 {
        width: 100%;
      }

      &--w10,
      &--w20,
      &--w30,
      &--w40,
      &--w50,
      &--w60,
      &--w70,
      &--w80,
      &--w90,
      &--w100 {
        @media (max-width: @screen-sm-max) {
          width: 100%;
        }
      }

      &--center {
        justify-content: center;
      }

      &--no-bg {
        background-color: transparent !important;
        @media (max-width: @screen-sm-max) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .table-list__td + .table-list__td {
      margin-left: 2px;

      @media (max-width: @screen-xs-max) {
        margin-left: 0;
      }
    }

    .table-list__td--no-bg + .table-list__td--no-bg {
      margin-left: 0;
    }

    .table-list__row + .table-list__row {
      margin-top: 4px;
    }
  }
}

</style>
