<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { useI18n } from 'vue-i18n'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.credit-information-upgrade'

const { t } = useI18n()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tableHeaders = [
    t('UpgradeConcept.sections.2.table.headers.1'),
    t('UpgradeConcept.sections.2.table.headers.2')
]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tableRows = [
    [ t('UpgradeConcept.sections.2.table.product.1'), t('UpgradeConcept.sections.2.table.upgrade.1') ],
    [ t('UpgradeConcept.sections.2.table.product.2'), t('UpgradeConcept.sections.2.table.upgrade.2') ],
    [ t('UpgradeConcept.sections.2.table.product.3'), t('UpgradeConcept.sections.2.table.upgrade.3') ],
    [ t('UpgradeConcept.sections.2.table.product.4'), t('UpgradeConcept.sections.2.table.upgrade.4') ],
    [ t('UpgradeConcept.sections.2.table.product.5'), t('UpgradeConcept.sections.2.table.upgrade.5') ]
]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tableCaption = t('UpgradeConcept.sections.2.table.caption')
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'credit-information' }">
                    {{ $t('help.creditReports.breadcrumbs.creditReport') }}
                </router-link>
            </li>
            <li>{{ $t('help.creditReports.breadcrumbs.upgrade') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c2551"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-2551"
                    >
                        <div
                            id="toc2551"
                            class="toc fixedsticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-2552"
                                        class="toc__"
                                        href="/hilfe/bonitaetsauskunft/upgrade/#c2552"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2553"
                                                href="/hilfe/bonitaetsauskunft/#c2553"
                                                @click.prevent="scrollTo('#c2553')"
                                            >
                                                {{ $t('UpgradeConcept.sections.1.title') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2555"
                                                href="/hilfe/bonitaetsauskunft/#c2555"
                                                @click.prevent="scrollTo('#c2555')"
                                            >
                                                {{ $t('UpgradeConcept.sections.2.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-2551"
                                        class="toc__"
                                        href="/hilfe/bonitaetsauskunft/upgrade/#c2551"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c2552"
                        data-qa="grid-box-2552"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <h1 />
                                        <div
                                            id="c2553"
                                            class="ce-box   box"
                                            data-qa="ce-2553"
                                        >
                                            <div class="ce-textpic ce-textpic--left ce-textpic--intext">
                                                <div class="ce-header">
                                                    <h3>{{ $t('UpgradeConcept.sections.1.title') }}</h3>
                                                </div>
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/bonitaetsauskunft/upgrade-konzept.gif"
                                                                    width="420"
                                                                    height="280"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <div class="ce-textpic__bodytext">
                                                        <p>
                                                            <strong>{{ $t('UpgradeConcept.sections.1.subtitle') }}</strong>
                                                        </p>
                                                        <p>
                                                            {{ $t('UpgradeConcept.sections.1.ps.1') }}
                                                        </p>
                                                        <p>
                                                            {{ $t('UpgradeConcept.sections.1.ps.2') }}
                                                        </p>
                                                        <p>{{ $t('UpgradeConcept.sections.1.ps.3') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c2555"
                                            class="ce-box  box"
                                            data-qa="ce-2555"
                                        >
                                            <div class="ce-header">
                                                <h3>{{ $t('UpgradeConcept.sections.2.title') }}</h3>
                                            </div>
                                            <div class="hidden table-responsive ">
                                                <table class="table col-12">
                                                    <caption> {{ $t('UpgradeConcept.sections.2.table.caption') }}</caption>

                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {{ $t('UpgradeConcept.sections.2.table.headers.1') }}
                                                            </th>

                                                            <th>
                                                                {{ $t('UpgradeConcept.sections.2.table.headers.2') }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {{ $t('UpgradeConcept.sections.2.table.product.1') }}
                                                            </td>

                                                            <td>
                                                                {{ $t('UpgradeConcept.sections.2.table.upgrade.1') }}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {{ $t('UpgradeConcept.sections.2.table.product.2') }}
                                                            </td>

                                                            <td>
                                                                {{ $t('UpgradeConcept.sections.2.table.upgrade.2') }}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {{ $t('UpgradeConcept.sections.2.table.product.3') }}
                                                            </td>

                                                            <td>
                                                                {{ $t('UpgradeConcept.sections.2.table.upgrade.3') }}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {{ $t('UpgradeConcept.sections.2.table.product.4') }}
                                                            </td>

                                                            <td>
                                                                {{ $t('UpgradeConcept.sections.2.table.upgrade.4') }}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {{ $t('UpgradeConcept.sections.2.table.product.5') }}
                                                            </td>

                                                            <td>
                                                                {{ $t('UpgradeConcept.sections.2.table.upgrade.5') }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.ce-textpic{
    display: block;
    overflow: hidden;
}
.ce-textpic--left {
    .ce-textpic__gallery {
        float: right;
        max-width: 40%;
    }
    .ce-textpic__media {
        float: right;
        padding-left: 20px;
        padding-bottom: 20px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ce-textpic--left.ce-textpic--left {
    .ce-textpic__gallery {
        float: left;
    }
    .ce-textpic__media {
        float: left;
        padding-right: 20px;
        padding-left: 0;
    }
}
.ce-textpic--left.ce-textpic--intext {
    .ce-textpic__media {
        max-width: 100%;
    }
}
@media (max-width: 1199px) {
    .ce-textpic--left {
        .ce-textpic__gallery {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 0 2rem;
            float: none;
        }
    }
}
strong {
    color: var(--color-c-s3);
}
</style>
