import { defineRule, configure } from 'vee-validate'
import { required, email } from '@vee-validate/rules'
import i18n from '@/i18n'
const { t } = i18n.global
import dayjs from 'dayjs'
import iban from 'iban'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/de'
import 'dayjs/locale/fr'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

defineRule('required', required)
defineRule('email', email)

configure({
    generateMessage: context => {
        if (context.rule?.name! === 'required') {
            return t('errors.required')
        }
        if (context.rule?.name === 'email') {
            return t('errors.email')
        }
    }
})

defineRule('positiveNumber', (value: string|number) => {
    if (!value && value !== 0) {
        return true
    }
    if (typeof value === 'string') {
        value = parseFloat(value)
    }
    if (value > 0) {
        return true
    }
    return t('errors.positiveNumber')
})

defineRule('isAfterDate', (value: string | undefined, [target, errorText, isDate = false]: [string | Date, string, boolean], ctx) => {
    let pastDate

    if (isDate) {
        pastDate = target as string
    } else {
        pastDate = ctx.form[target as string] as string
    }
    if (!value || !pastDate) {
        return true
    }
    if (dayjs(value).isSameOrAfter(dayjs(pastDate))) {
        return true
    }
    return errorText
})

defineRule('isAfterDateWithAfterDateRequired', (value: string | undefined, [target, errorText, isDate = false]: [string | Date, string, boolean], ctx) => {
    let pastDate

    if (isDate) {
        pastDate = target as string
    } else {
        pastDate = ctx.form[target as string] as string
    }
    if (!value) {
        return true
    }
    if (!pastDate) {
        return errorText
    }
    if (dayjs(value).isSameOrAfter(dayjs(pastDate))) {
        return true
    }
    return errorText
})

defineRule('isNotInThePast', (value: string|undefined) => {
    if (!value) {
        return true
    }

    if (dayjs(value).isSameOrAfter(dayjs(), 'day')) {
        return true
    }
    return t('errors.isInThePast')
})

defineRule('isInThePast', (value: string|undefined) => {
    if (!value) {
        return true
    }

    if (dayjs(value).isSameOrBefore(dayjs(), 'day')) {
        return true
    }
    return t('errors.isInThePast')
})

defineRule('isNotOlderThan3years', (value: string|undefined) => {
    if (!value) {
        return true
    }

    const now = dayjs()
    if (now.diff(dayjs(value), 'year', true) <= 3) {
        return true
    }

    return t('errors.isNotOlderThan3years')
})

defineRule('iban', (value: string|undefined) => {
    if (!value) {
        return true
    }
    if (iban.isValid(value)) {
        return true
    }
    return t('errors.iban')
})

const memberIdStructure = new RegExp(/^[1-9]\d{8}$/)
defineRule('isNoMemberId', (value:string) => {
    if (memberIdStructure.test(value)) {
        return true
    }

    return t('errors.isNoMemberId')
})

defineRule('requiredIf', (value: string | undefined | null, [target]: [[string] | string], ctx) => {
    if (Array.isArray(target)) {
        let isRequired = false
        target.forEach((oneTarget) => {
            if (ctx.form[oneTarget]) {
                isRequired = true
            }
        })
        if (!isRequired) {
            return true
        }
    } else {
        const requiredField = ctx.form[target]
        if (!requiredField) {
            return true
        }
    }

    if (value) {
        return true
    }

    return t('errors.required')
})

defineRule('requiredIfValue', (value: string | undefined | null, [target, targetValue]: [string, string], ctx) => {
    const requiredField = ctx.form[target]
    if (!requiredField) {
        return true
    }

    if (requiredField !== targetValue) {
        return true
    }

    if (value) {
        return true
    }

    return t('errors.required')
})
