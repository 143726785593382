import { deleteCbraConsumerTransaction, postCbraConsumerPermissions, postConsumerProduct } from '@/services'
import { CbraReportConsumerPayload, CbraReportRequestConsumerPermissionPayload } from '@/types/cbra/reports'
import { checkUserRight } from './userRights'
import { CbraTransaction } from '@/types/cbra/transactions'

export namespace ReportPersonService {
    export const TOPIC = 'ReportPersonService'
     export const INTERNAL_ERROR_TYPES = {
         NO_MEMBER_USER_ID: 'noMemberUserId',
         WRONG_MEMBER_USER_ID: 'wrongMemberUserId',
         GET_PRODUCT_FAILED: 'getProductFailed',
         GET_PRODUCT_NO_RESPONSE: 'getProductNoResponse',
         GET_PRODUCT_PDF_FAILED: 'getProductPdfFailed',
         REQUEST_CONSUMER_PERMISSION_FAILED: 'requestConsumerPermissionFailed',
         REQUEST_CONSUMER_PERMISSION_REJECTED: 'requestConsumerPermissionRejected'
     }

     export function getProduct(requestData: CbraReportConsumerPayload) {
         return postConsumerProduct(requestData)
     }
     export function requestPermission(requestData: CbraReportRequestConsumerPermissionPayload) {
         return postCbraConsumerPermissions(requestData)
     }

     export function deleteReportByTransaction(transaction: CbraTransaction) {
         const reportId = encodeURIComponent(transaction.reportId)
         const transactionId = encodeURIComponent(transaction.crefoTransactionId)

         let portfolioReportHeader = { viewScope: 'USER' }

         const response = checkUserRight('portfolioReport')
         if (response.grants === true && transaction.consumerTransaction?.memberUserId !== transaction.cbraUser.memberUserId) {
             portfolioReportHeader = { viewScope: 'MEMBER' }
         }

         return deleteCbraConsumerTransaction(reportId, transactionId, portfolioReportHeader)
     }
}