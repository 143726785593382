<template>
    <div>
        <div 
            v-if="_transaction && _transaction.reportTransaction"
            :id="`transaction-${_transaction.reportTransaction.id}`"
            :key="_transaction.reportTransaction.id"
            class="results-list--item"
            :class="{ 'inactive': isProductInProgress(_transaction) }"
        >
            <div class="row">
                <div class="col-12">
                    <div class="bg-white">
                        <div class="row">
                            <div class="col-md-5 col-12">
                                <div class="row">
                                    <div
                                        v-if="!hideActions"
                                        class="col-1"
                                    >
                                        <div 
                                            class="icon-favourite" 
                                            data-qa="myTransactions-iconStar"
                                            @click="_toggleFavourite"
                                        >
                                            <font-awesome-icon
                                                :icon="`fa-light fa-star ${_transaction.favourite ? 'fa-solid' : ''}`"
                                                color="#009ee2"
                                            />
                                        </div>
                                        <div 
                                            v-if="_transaction.reportId !== null && transactionsStore.deleteOptionsAreShown"
                                            class="select-delete"
                                        >
                                            <Checkbox
                                                :id="`select-delete-transaction-${_transaction.crefoTransactionId}`"
                                                v-model="_transaction.checkForDeleting"
                                                :name="`select-delete-transaction-${_transaction.crefoTransactionId}`"
                                                label=""
                                                :disabled="!erasable"
                                                data-qa="activities-deleting-entry-checkbox"
                                            />
                                        </div>
                                        <div 
                                            v-else-if="_transaction.reportId !== null && !transactionsStore.deleteOptionsAreShown && !hideDeleteAction"
                                            class="icon-delete"
                                            data-qa="myTransactions-iconTrash"
                                            :class="{ 'disabled': !erasable }"
                                            @click="deleteTransaction"
                                        >
                                            <template v-if="erasable">
                                                <Tooltip position="bottom">
                                                    <font-awesome-icon
                                                        icon="fa-light fa-trash-can"
                                                        color="#009ee2"
                                                    />
                                                    <template #content>
                                                        <p>{{ $t('transaction.list.reports.delete.tooltop.erasable') }}</p>
                                                    </template>
                                                </Tooltip>
                                            </template>
                                            <template v-else>
                                                <Tooltip position="bottom">
                                                    <font-awesome-icon
                                                        icon="fa-light fa-trash-can"
                                                        color="#009ee2"
                                                    />
                                                    <template #content>
                                                        <p>{{ $t('transaction.list.reports.delete.tooltop.noterasable') }}</p>
                                                    </template>
                                                </Tooltip>
                                            </template>
                                        </div>
                                    </div>
                                    <div 
                                        class="col-11"
                                        @click.prevent="openDetailsPage"
                                    >
                                        <span class="h4 transaction-title">
                                            <span data-qa="myTransactions-searchResultList-businessName">
                                                {{ displayName }}
                                            </span>    
                                            <span 
                                                v-if="_transaction.reportTransaction.postalAddress !== null && 
                                                    _transaction.reportTransaction.postalAddress.city"
                                                data-qa="myTransactions-searchResultList-postalAddress-city"
                                            >, {{ _transaction.reportTransaction.postalAddress.city }}</span>
                                            <span 
                                                v-if="_transaction.reportTransaction.postalAddress !== null && 
                                                    _transaction.reportTransaction.postalAddress.country && 
                                                    _transaction.reportTransaction.postalAddress.country !== 'DE'"
                                                data-qa="myTransactions-searchResultList-postalAddress-country"
                                            >, {{ $t(`enums.countries.${_transaction.reportTransaction.postalAddress.country}`) }}</span>
                                        </span>
                                        <div 
                                            v-if="_transaction.reportTransaction?.referenceNumber"
                                            class="text-small"
                                        >
                                            <span 
                                                :class="{
                                                    'text-brand-grey': _transaction.reportId !== null,
                                                    'text-brand-dark-grey': _transaction.reportId === null
                                                }"
                                            >{{ $t('shared.reference') }}</span>&nbsp;
                                            <span 
                                                class="text-brand-darkest-blue text-bold"
                                                data-qa="myTransactions-searchResultList-referenceNumber"
                                            >{{ _transaction.reportTransaction.referenceNumber }}</span>
                                        </div>
                                        <div 
                                            v-if="featurePortfolioActive"
                                            class="text-small transaction_query_by"
                                        >
                                            <div class="icon baseline">
                                                <font-awesome-icon
                                                    icon="fa-solid fa-user"
                                                    color="#b5b5b5"
                                                />
                                            </div>
                                            <span 
                                                :class="{
                                                    'text-brand-grey': _transaction.reportId !== null,
                                                    'text-brand-dark-grey': _transaction.reportId === null
                                                }"
                                            >{{ $t('transaction.list.transaction_getby') }}</span>&nbsp;
                                            <span 
                                                class="text-brand-darkest-blue text-bold"
                                                data-qa="transaction_query_by"
                                            >{{ sbb(_transaction.owner) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                class="col-md-4 col-12"
                                @click.prevent="openDetailsPage"
                            >
                                <span 
                                    class="h4 transaction-title"
                                    data-qa="myTransactions-searchResultList-reportTransactionType"
                                >{{ `${$t(`shared.${_transaction.reportTransaction.reportProductType}`) === `shared.${_transaction.reportTransaction.reportProductType}` ? $t('shared.REPORT') : $t(`shared.${_transaction.reportTransaction.reportProductType}`)}` }}</span>
                                <div 
                                    v-if="_transaction.reportId === null"
                                    class="text-small"
                                >
                                    <span class="text-brand-dark-grey">
                                        ({{ $t('transaction.list.report.not.ready.yet') }})
                                    </span>
                                </div>
                                <div 
                                    v-if="_transaction.reportTransaction.lastModificationTime !== null"
                                    class="text-small"
                                >
                                    <span class="text-brand-grey">
                                        {{ $t('transaction.list.lastModificationTime') }}
                                    </span>&nbsp; 
                                    <span 
                                        class="text-bold last-modification-time"
                                        data-qa="myTransactions-searchResultList-lastModificationTime"
                                    >
                                        {{ formatDate(_transaction.reportTransaction.lastModificationTime, 'L') }}
                                    </span>
                                </div>
                                <div 
                                    v-if="_transaction.reportTransaction.lastSupplementReasonIdList &&
                                        _transaction.reportTransaction.lastSupplementReasonIdList.length > 0"
                                    class="text-small"
                                >
                                    <span class="text-brand-grey">
                                        {{ $t('transaction.show.supplement') }}
                                    </span>&nbsp; 
                                    <span 
                                        class="text-bold text-brand-darkest-blue"
                                        data-qa="myTransactions-searchResultList-lastModificationTime"
                                    >
                                        {{ $t(`watchlist.list.supplementReason.${_transaction.reportTransaction.lastSupplementReasonIdList[0]}`) }}
                                    </span>
                                    <span v-if="_transaction.reportTransaction.lastSupplementReasonIdList.length > 1">, &hellip;</span>
                                </div>
                            </div>
                            <div class="col-md-3 col-12 data p0">
                                <div @click.prevent="openDetailsPage">
                                    <div class="text-small">
                                        <span class="text-brand-grey">{{ $t('transaction.show.orderNumber') }}</span>&nbsp;
                                        <span 
                                            class="text-brand-darkest-blue text-bold"
                                            data-qa="myTransactions-searchResultList-productOrderId"
                                        >
                                            {{ _transaction.reportTransaction.productOrderId }}
                                        </span>
                                    </div>
                                    <div 
                                        v-if="_transaction.reportTransaction.creationTime"
                                        class="text-small"
                                    >
                                        <span class="text-brand-grey">{{ $t('transaction.list.creationTime') }}</span>&nbsp;
                                        <span 
                                            class="text-brand-darkest-blue text-bold"
                                            data-qa="myTransactions-searchResultList-creationTime"
                                        >
                                            {{ formatDate(_transaction.reportTransaction.creationTime, 'L') }}
                                        </span>
                                    </div>
                                    <div 
                                        v-if="isMonitoringShown"
                                        class="text-small text-brand-grey"
                                    >
                                        <!-- eslint-disable-next-line vue/no-v-html -->
                                        <span v-html="monitoringStatus" />
                                    </div>
                                </div>
                                <div v-if="showBuyNewReportButton && !hideBuyButton">
                                    <button 
                                        class="order-new-btn btn btn-secondary btn-small"
                                        data-qa="myTransactions-searchResultList-buyNewReport"
                                        @click.prevent="goToBuyReportPage"
                                    >
                                        {{ $t('transaction.list.link_to_uds') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LegitimationOverlay 
            v-if="showLegitimationOverlay"
            :product-order-id="_transaction!.reportTransaction!.productOrderId"
            @abort="showLegitimationOverlay = false"
            @success="onLegitimationSuccess"
            @error="onLegitimationError"
        />
        <Modal
            v-if="deleteModal"
            :id="deleteModal.id"
            :title="deleteModal.title"
            :confirm-text="deleteModal.confirmText"
            :cancel-text="deleteModal.cancelText"
            :icon="deleteModal.icon"
            :data-qa="deleteModal.dataQa"
            @on-confirm="onDeleteModalConfirm"
            @on-close="deleteModal = undefined"
        >
            <p>{{ deleteModal.content }}</p>
        </Modal>       
        <Modal
            v-if="notReadyModal"
            :id="notReadyModal.id"
            :title="notReadyModal.title"
            :confirm-text="notReadyModal.confirmText"
            :cancel-text="notReadyModal.cancelText"
            :icon="notReadyModal.icon"
            :data-qa="notReadyModal.dataQa"
            @on-confirm="notReadyModal = undefined"
            @on-close="notReadyModal = undefined"
        >
            <p>{{ notReadyModal.content }}</p>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import Modal from '@/components/Modal.vue'
import Tooltip from '@/components/Tooltip.vue'
import Checkbox from '@/components/Input/Checkbox.vue'
import LegitimationOverlay from '@/components/Transactions/LegitimationOverlay.vue'

import { useTransactionsStore } from '@/stores/transactions'

import { getReportOrderLegitimation } from '@/services'

import { trackClick } from '@/helper/webtrekk'
import { getReportTrackingHTML } from '@/helper/reportTracking'
import { accessToFeature } from '@/helper/services/features'
import {
    isProductInProgress,
    isReportTransactionErasable,
    toggleFavourite
} from '@/helper/services/transaction'

import { formatDate, sbb } from '@/filters'

import CONSTANTS from '@/data/constants'
import { udsLinks, adsLinks } from '@/data/typo3Links'

import type { CbraTransaction } from '@/types/cbra/transactions'
import type { ModalProps } from '@/types/components/modal'

const transactionsStore = useTransactionsStore()
const { t, locale } = useI18n()

const props = defineProps<{
    transaction: CbraTransaction | undefined
    hideActions?: boolean
    hideBuyButton?: boolean
    hideDeleteAction?: boolean
}>()

const _transaction = ref<CbraTransaction | undefined>(props.transaction)

const deleteModal = ref<ModalProps>()
const notReadyModal = ref<ModalProps>()
const showLegitimationOverlay = ref(false)
const urlToFollow = ref('')

const BUY_NEW_REPORT_STATES = [
    'MONITORING_BEENDET',
    'NACHTRAGSFRIST_BEENDET',
    'KEIN_TRACKINGSTATUS'
]

const displayName = computed(() => {
    if (!_transaction.value) {
        return ''
    }
    if (_transaction.value.reportTransaction!.businessName !== null) {
        return _transaction.value.reportTransaction!.businessName
    }

    let name = ''
    if (_transaction.value.reportTransaction!.givenName) {
        name += _transaction.value.reportTransaction!.givenName + ' '
    }

    if (_transaction.value.reportTransaction!.surname) {
        name += _transaction.value.reportTransaction!.surname
    }

    return name
})

// const isOwnTransaction = computed(() => {
//     return (
//         (
//             _transaction.value.transactionType !== CONSTANTS.TRANSACTION_TYPES.COLLECTION && 
//             _transaction.value.transactionType !== CONSTANTS.TRANSACTION_TYPES.CONSUMER
//         ) && 
//         _transaction.value.reportTransaction && 
//         _transaction.value.cbraUser.memberUserId !== 
//         _transaction.value.reportTransaction.memberUserId
//     ) ? false : true
// })

const featurePortfolioActive = computed(() => {
    return accessToFeature('portfolioViewActive')
})

const isMonitoringShown = computed(() => {
    return _transaction.value &&
        _transaction.value.reportTransaction &&
        (
            _transaction.value.reportTransaction.monitoring ||
            _transaction.value.reportTransaction.monitoringEndTime ||
            _transaction.value.reportTransaction.supplementEndDate
        )
})

const showBuyNewReportButton = computed(() => {
    let isShown = false
    if (_transaction.value && _transaction.value.transactionType === CONSTANTS.TRANSACTION_TYPES.REPORT) {
        // bestellstatus nicht IN_PROGRESS
        // keine oder abgelaufene Nachträge
        // kein Monitoring
        if (_transaction.value.reportTransaction!.reportTransactionStatus !== 'IN_PROGRESS') {
            isShown = !_transaction.value.reportTransaction!.reportTransactionTracking || _transaction.value.reportTransaction.reportTransactionTracking && BUY_NEW_REPORT_STATES.includes(_transaction.value.reportTransaction.reportTransactionTracking.trackingStatus)
        }
    }

    return isShown
})

const erasable = computed(() => {
    if (!_transaction.value) {
        return false
    }

    return isReportTransactionErasable(_transaction.value)
})

const monitoringStatus = computed(() => {
    if (!_transaction.value) {
        return ''
    }
    return getReportTrackingHTML(t, _transaction.value, 'TransactionList')
})

function goToBuyReportPage() { 
    if (!_transaction.value) return
    
    let linkParams = []
    if (_transaction.value.reportTransaction?.businessId) {
        linkParams.push(`businessId=${_transaction.value.reportTransaction.businessId}`)
    }
    if (_transaction.value.reportTransaction?.postalAddress?.country) {
        linkParams.push(`country=${_transaction.value.reportTransaction.postalAddress.country.toUpperCase()}`)
    }
    
    if (linkParams.length) {
        location.assign(`${udsLinks[locale.value]}?${linkParams.join('&')}`)
    } else {
        location.assign(`${udsLinks[locale.value]}`)
    }
}

function openDetailsPage() {
    if (!_transaction.value) return

    const url = `${adsLinks[locale.value]}?no_cache=1&transactionId=${_transaction.value.crefoTransactionId}`

    if (
        _transaction.value.reportTransaction !== null &&
        _transaction.value.transactionType === CONSTANTS.TRANSACTION_TYPES.REPORT && 
        _transaction.value.reportTransaction.productOrderId !== null &&
        !isProductInProgress(_transaction.value)
    ) {
        getReportOrderLegitimation(_transaction.value.reportTransaction.productOrderId).then((response: any) => {
            if (response.status === 200) {
                if (response.data.length === 0) {
                    trackClick('Zweitverwertung_von_Auskünften', 'Zweitverwertung_von_Auskünften', 'vorgangsliste.click.nicht.eigene.auskunft')
                    showLegitimationOverlay.value = true
                    urlToFollow.value = url
                } else {
                    location.assign(url)
                }
            } else if (response.status >= 400) {
                trackClick('Zweitverwertung_von_Auskünften', 'Zweitverwertung_von_Auskünften', 'vorgangsliste.click.nicht.eigene.auskunft')
                showLegitimationOverlay.value = true
                urlToFollow.value = url
            }
        }).catch((error) => {
            console.log('error', error)
            // const errorConf = errorService.getErrorMessages("wkv.order.notreachable");
            // uimodal.show({
            //     title: errorConf.headline,
            //     content: errorConf.description,
            //     icon: "warning"
            // })
        }) 
    } else if (isProductInProgress(_transaction.value)) {
        notReadyModal.value = {
            id: 'test',
            title: t('error.transaction.notReady'),
            content: t('error.transaction.notReady.description'),
            icon: 'warning',
            confirmText: t('shared.modals.ok')
        }
        return
    } else {
        location.assign(url)
    }
}

function onLegitimationSuccess() {
    if (urlToFollow.value === '') return
    showLegitimationOverlay.value = false
    location.assign(urlToFollow.value)
}

function onLegitimationError() {
    showLegitimationOverlay.value = false
    notReadyModal.value = {
        id: 'test',
        title: t('error.transaction.notReady'),
        content: t('error.transaction.notReady.description'),
        icon: 'warning',
        confirmText: t('shared.modals.ok')
    }
}

function _toggleFavourite() {
    if (!_transaction.value) return
    _transaction.value.favourite = !_transaction.value.favourite
    return toggleFavourite(_transaction.value)
}

const onDeleteModalConfirm = () => {
    deleteModal.value = undefined
    if (!_transaction.value) return
    transactionsStore.deleteTransactions([_transaction.value.crefoTransactionId.toString()])
}

function deleteTransaction() {
    if (!erasable.value) {
        return
    }
    deleteModal.value = {
        id: 'test',
        title: t('transaction.list.reports.delete.overlay.title'),
        content: t('transaction.list.reports.delete.overlay.question'),
        icon: 'warning',
        confirmText: t('transaction.list.reports.delete.overlay.confirm'),
        cancelText: t('transaction.list.reports.delete.overlay.cancel'),
        dataQa: 'delete-transaction'
    }
}
</script>

<style scoped lang="less">
.order-new-btn {
    margin-top: 10px;
}
</style>
