import { sendClick } from '../webtrekk'
import { differentContextActive } from '@/helper/services/memberNavigation'
import { useUserStore } from '@/stores/user'

export const PAGE_FUNCTIONS = {
    SUPERUSER_USERRIGHT: 'SuperUser_Userright',
    SUPERUSER_OVERLAY_NOT_AUTHORIZED: 'SuperUser_Overlay_Not_Authorized',
    MEMBER_NAVIGATION_OVERLAY_REVOKED_RIGHT: 'MemberNavigation_Overlay_Revoked_Rights',
    MEMBER_NAVIGATION_ACCESS: 'MemberNavigation_Access'
}

/*
 * SuperUserWebTrekkService
 */
export function superUserCheckboxClick(value: boolean, type: string) {
    const state = (value === false) ? 'unchecked' : 'checked'

    if (type !== '') {
        sendClick(`${PAGE_FUNCTIONS.SUPERUSER_USERRIGHT}_${state}_${type}`)
    }
}

export function superUserOverlayNotAuthorized(area: string) {
    sendClick(`${PAGE_FUNCTIONS.SUPERUSER_OVERLAY_NOT_AUTHORIZED}_${area}`)
}

export function memberNavigationRevokedRights() {
    sendClick(`${PAGE_FUNCTIONS.MEMBER_NAVIGATION_OVERLAY_REVOKED_RIGHT}`)
}

export function trackMemberNavigationAccess(area: string) {
    if (differentContextActive()) {
        const userStore = useUserStore()

        const pages: any= {
            'startseite': 'Startseite',
            'vorgaenge': 'Vorgangsübersicht',
            'inkasso_datei_upload': 'Inkasso Datei Upload',
            'inkasso_formular_upload': 'Inkasso Formular Upload',
            'inkasso_datei_upload_ohne_recht': 'Inkasso Datei Upload (ohne Recht)',
            'inkasso_formular_upload_ohne_recht': 'Inkasso Formular Upload (ohne Recht)'
        }

        sendClick(
            `${PAGE_FUNCTIONS.MEMBER_NAVIGATION_ACCESS}_${area}`,
            {
                2: '200 OK',
                4: userStore.cbraUser.clz,
                6: PAGE_FUNCTIONS.MEMBER_NAVIGATION_ACCESS,
                8: pages[area]
            }
        )
    }
}
