<template>
    <Hero
        :image="heroImage"
        :mobile-image="heroImageMobile"
    >
        <div class="row mb-large">
            <div class="col-12 col-md-12 col-xl-12">
                <h1 class="p0 marketing">
                    <span>{{ $t('transferNow.welcome') }}</span>
                </h1>
            </div>
            <div class="col-12 col-md-12 col-xl-12">
                <h2 class="p0">
                    {{ $t('transferNow.portal') }}
                </h2>
            </div>
            <div class="col-12 col-md-12 col-xl-12 mb-large">
                <button
                    data-qa="ce-header-"
                    class="btn btn-default"
                    @click.prevent="register"
                >
                    {{ $t('transferNow.buttonText') }}
                </button>
            </div>
        </div>
    </Hero>
</template>

<script setup lang="ts">
import Hero from '@/components/Hero.vue'

import heroImage from '@/assets/img/homepage/header-meine-cr-1663x265.png'
import heroImageMobile from '@/assets/img/homepage/header-meine-cr-750x255.png'

const selfcareBaseUrl = import.meta.env.VITE_APP_USER_MGMT_BASEURL

const register = () => {
    location.assign(`${selfcareBaseUrl}/registrieren/mitglied?prk=MC`)
}
</script>
