<!-- eslint-disable vue/no-v-html -->
<template>
    <div
        v-if="chapter.funktion"
        class="product-content-box__subchapter"
        data-qa="infoChapter-structuredata-functions"
    >
        <h3 class="mt mb-small">
            {{ $t('transaction.chapter.GESCHAEFTSFUEHRUNG_UND_VERTRETUNGSBEFUGNISSE') }}
        </h3>

        <!-- desktop -->
        <div class="row table-responsive hidden-xs hidden-sm">
            <table class="table product-content-box__table col-12 pt-small">
                <thead>
                    <th class="col-2">
                        {{ $t('shared.businessId') }}
                    </th>
                    <th colspan="2">
                        {{ $t('transaction.show.nameAddressStatus') }}
                    </th>
                    <th>{{ $t('transaction.show.participatingSince') }}</th>
                    <th class="text-align-right col-2">
                        {{ $t('transaction.show.powerOfRepresentation') }}
                    </th>
                </thead>
                <tbody>
                    <template
                        v-for="(data, index) of chapter.funktion"
                        :key="index"
                    >
                        <tr>
                            <th
                                scope="col"
                                colspan="5"
                            >
                                <span v-html="helpers.nl2br(data.bezeichnung)" />
                            </th>
                        </tr>
                        <template
                            v-for="(funktionsBeteiligter, funktionsBeteiligterIndex) of data.funktionsBeteiligter"
                            :key="funktionsBeteiligterIndex"
                        >
                            <template v-if="funktionsBeteiligter.firmendaten">
                                <tr
                                    v-if="funktionsBeteiligter.firmendaten"
                                >
                                    <StructureTable
                                        :data="funktionsBeteiligter.firmendaten"
                                        :colspan="2"
                                        :rowspan="getRowspanForVertretungsbefugnisse(funktionsBeteiligter.firmendaten.funktionenEbene2)"
                                        :postal-address="postalAddress"
                                        type="BUSINESS"
                                        :is-disabled="isDisabled"
                                    />
                                </tr>
                                <template v-if="!funktionsBeteiligter.firmendaten.funktionenEbene2.empty">
                                    <template
                                        v-for="(funktionEbene2, funktionEbene2Index) of funktionsBeteiligter.firmendaten.funktionenEbene2.funktionEbene2"
                                        :key="funktionEbene2Index"
                                    >
                                        <tr>
                                            <th
                                                scope="col"
                                                colspan="4"
                                            >
                                                <span v-html="helpers.nl2br(funktionEbene2.bezeichnung)" />
                                            </th>
                                        </tr>
                                        <template
                                            v-for="(funktionsBeteiligter2, funktionsBeteiligter2Index) of funktionEbene2.funktionsBeteiligter"
                                            :key="funktionsBeteiligter2Index"
                                        > 
                                            <tr
                                                v-if="funktionsBeteiligter2.firmendaten"
                                            >
                                                <StructureTable
                                                    :data="funktionsBeteiligter2.firmendaten"
                                                    :colspan="1"
                                                    :rowspan="1"
                                                    :postal-address="postalAddress"
                                                    type="BUSINESS"
                                                    :is-disabled="isDisabled"
                                                />
                                            </tr>
                                            <tr
                                                v-if="funktionsBeteiligter2.personendaten"
                                            >
                                                <StructureTable
                                                    :data="funktionsBeteiligter2.personendaten"
                                                    :colspan="1"
                                                    :rowspan="1"
                                                    :postal-address="postalAddress"
                                                    type="PERSON"
                                                    :is-disabled="isDisabled"
                                                />
                                            </tr>
                                            <tr
                                                v-if="funktionsBeteiligter2.auslandDaten"
                                            >
                                                <StructureTable
                                                    :data="funktionsBeteiligter2.auslandDaten"
                                                    :colspan="1"
                                                    :rowspan="1"
                                                    :postal-address="postalAddress"
                                                    type="INTERNATIONAL"
                                                    :is-disabled="isDisabled"
                                                />
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                            </template>

                            <tr
                                v-if="funktionsBeteiligter.personendaten"
                            >
                                <StructureTable
                                    :data="funktionsBeteiligter.personendaten"
                                    :colspan="2"
                                    :rowspan="1"
                                    :postal-address="postalAddress"
                                    type="PERSON"
                                    :is-disabled="isDisabled"
                                />
                            </tr>
                            <tr
                                v-if="funktionsBeteiligter.auslandDaten"
                            >
                                <StructureTable
                                    :data="funktionsBeteiligter.auslandDaten"
                                    :colspan="2"
                                    :rowspan="1"
                                    :postal-address="postalAddress"
                                    type="INTERNATIONAL"
                                    :is-disabled="isDisabled"
                                />
                            </tr>
                        </template>
                        <tr v-if="data.hinweis">
                            <td colspan="5">
                                <span v-html="helpers.nl2br(data.hinweis)" />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <!-- mobile  -->
        <div class="row table-responsive hidden-md hidden-lg">
            <Accordion>
                <template
                    v-for="(data, index) of chapter.funktion"
                    :key="index"
                >
                    <AccordionItem
                        :item-key="index"
                        :title="helpers.nl2br(data.bezeichnung)"
                    >
                        <div class="table-responsive__container">
                            <div
                                class="table-responsive__list management-list"
                            >
                                <template
                                    v-for="(funktionsBeteiligter, funktionsBeteiligterIndex) of data.funktionsBeteiligter"
                                    :key="funktionsBeteiligterIndex"
                                >
                                    <template v-if="funktionsBeteiligter.firmendaten">
                                        <div
                                            class="table-responsive__item table-responsive__item--highlight"
                                        >
                                            <StructureMobileTemplate
                                                :data="funktionsBeteiligter.firmendaten"
                                                type="BUSINESS"
                                                :postal-address="postalAddress"
                                                :is-disabled="isDisabled"
                                            />
                                        </div>
                                        <template v-if="!funktionsBeteiligter.firmendaten.funktionenEbene2.empty ">
                                            <template
                                                v-for="(funktionEbene2, funktionEbene2Index) of funktionsBeteiligter.firmendaten.funktionenEbene2.funktionEbene2"
                                                :key="funktionEbene2Index"
                                            >
                                                <div class="table-responsive__item">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <strong v-html="helpers.nl2br(data.bezeichnung)" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <template
                                                    v-for="(funktionsBeteiligter2, funktionsBeteiligter2Index) of funktionEbene2.funktionsBeteiligter"
                                                    :key="funktionsBeteiligter2Index"
                                                > 
                                                    <div
                                                        v-if="funktionsBeteiligter2.firmendaten"
                                                        class="table-responsive__item"
                                                    >
                                                        <StructureMobileTemplate
                                                            :data="funktionsBeteiligter2.firmendaten"
                                                            type="BUSINESS"
                                                            :postal-address="postalAddress"
                                                            :is-disabled="isDisabled"
                                                        />
                                                    </div>
                                                    <div
                                                        v-if="funktionsBeteiligter2.personendaten"
                                                        class="table-responsive__item"
                                                    >
                                                        <StructureMobileTemplate
                                                            :data="funktionsBeteiligter2.personendaten"
                                                            type="PERSON"
                                                            :postal-address="postalAddress"
                                                            :is-disabled="isDisabled"
                                                        />
                                                    </div>
                                                    <div
                                                        v-if="funktionsBeteiligter2.auslandDaten"
                                                        class="table-responsive__item"
                                                    >
                                                        <StructureMobileTemplate
                                                            :data="funktionsBeteiligter2.auslandDaten"
                                                            type="INTERNATIONAL"
                                                            :postal-address="postalAddress"
                                                            :is-disabled="isDisabled"
                                                        />
                                                    </div>
                                                </template>
                                            </template>
                                        </template>
                                    </template>

                                    <div
                                        v-if="funktionsBeteiligter.personendaten"
                                        class="table-responsive__item"
                                    >
                                        <StructureMobileTemplate
                                            :data="funktionsBeteiligter.personendaten"
                                            type="PERSON"
                                            :postal-address="postalAddress"
                                            :is-disabled="isDisabled"
                                        />
                                    </div>
                                    <div
                                        v-if="funktionsBeteiligter.auslandDaten"
                                        class="table-responsive__item"
                                    >
                                        <StructureMobileTemplate
                                            :data="funktionsBeteiligter.auslandDaten"
                                            type="INTERNATIONAL"
                                            :postal-address="postalAddress"
                                            :is-disabled="isDisabled"
                                        />
                                    </div>
                                </template>

                                <div
                                    v-if="data.hinweis"
                                    class="table-responsive__item"
                                >
                                    <div class="row">
                                        <div class="col-12">
                                            <strong v-html="helpers.nl2br(data.hinweis)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionItem>
                </template>
            </Accordion>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { CbraReportTransactionChapter, CbraReportTransactionFunktionenEbene2 } from '@/types/cbra/reports'
import { PropType } from 'vue'
import helpers from '@/helper/misc'
import { CbraPostalAddress } from '@/types/cbra/shared'
import StructureTable from './StructureTable.vue'
import StructureMobileTemplate from './StructureMobileTemplate.vue'
import Accordion from '@/components/Accordion/Accordion.vue'
import AccordionItem from '@/components/Accordion/AccordionItem.vue'

defineProps({
    chapter: {
        type: Object as PropType<CbraReportTransactionChapter>,
        required: true,
    },
    postalAddress: {
        type: Object as PropType<CbraPostalAddress>,
        required: true
    },
    isDisabled: {
        type: Boolean,
        required: true
    },
})

function getRowspanForVertretungsbefugnisse(funktionenEbene2: CbraReportTransactionFunktionenEbene2) {
    let counter = 1
    if (funktionenEbene2.funktionEbene2) {
        if (!funktionenEbene2.funktionEbene2) {counter = 2}
        for (let i = 0; i < funktionenEbene2.funktionEbene2.length; i++) {
            counter++
            for (let y = 0; y < funktionenEbene2.funktionEbene2[i].funktionsBeteiligter.length; y++) {
                counter++
            }
        }
    }
    return counter
}
</script>