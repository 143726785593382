import { ComposerTranslation } from 'vue-i18n'

type SelectOption = {
    label: string
    code: string | null
}

export function reportLanguageOptions(t: ComposerTranslation) {
    const reportLanguageOptions: SelectOption[] = [
        { label: t('report.detail.reportLanguage.de'), code: 'DE' },
        { label: t('report.detail.reportLanguage.en'), code: 'EN' },
        { label: t('report.detail.reportLanguage.fr'), code: 'FR' },
        { label: t('report.detail.reportLanguage.it'), code: 'IT' }
    ]

    return reportLanguageOptions
}