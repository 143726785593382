<template>
    <ListWidget
        :results="results"
        :widget="props.widget"
        :is-loading="isLoading"
        :sorting-type="search.sort"
        :header-name="$t('dashboard.widget.events.list.header.date')"
        :sort-name="$t('dashboard.widget.watchlist.list.header.date')"
        :page-size="search.pageSize"
        :page-start-index="search.pageStartIndex"
        :list-size="listSize"
        @on-sort="onSort"
        @on-previous-page="onPreviousPage"
        @on-next-page="onNextPage"
    />
</template>

<script setup lang="ts">
import { onMounted, PropType, reactive, ref } from 'vue'
import { EnhancedResults, Widget } from '@/types/widget'
import { getCbraEvents } from '@/services'
import ListWidget from './ListWidget.vue'
import { EventSort } from '@/enums/event'
import type { CbraEvent } from '@/types/cbra/event'
import { useI18n } from 'vue-i18n'

const props = defineProps({
    widget: {
        type: Object as PropType<Widget>,
        required: true
    },
})

const { locale } = useI18n()

const results = ref<EnhancedResults[]>([])
const listSize = ref(0)
const isLoading = ref(true)
const search = reactive({
    pageSize: 5,
    pageStartIndex: 0,
    sort: EventSort.START_DATE_ASC,
})

onMounted(async() => {
    await handleFetch()
})

async function onSort() {
    if (search.sort === EventSort.START_DATE_ASC) {
        search.sort = EventSort.START_DATE_DESC
    } else {
        search.sort = EventSort.START_DATE_ASC
    }
    await handleFetch()
}

async function handleFetch() {
    isLoading.value = true
    try {
        const { data } = await getCbraEvents(search)
        isLoading.value = false
        listSize.value = data.listSize
        results.value = data.events.map(enhanceItem)
    } catch (error) {
        console.error(error)
        isLoading.value = false
    }
}

function enhanceItem(item: CbraEvent) {
    const dateOptions: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit', 
        year: 'numeric'
    }

    const enhanedResult : EnhancedResults = {
        name: item.title,
        date: new Intl.DateTimeFormat(locale.value, dateOptions).format(new Date(item.startDate)),
        link: item.url
    }
    return enhanedResult
}

async function onPreviousPage() {
    if (isLoading.value) return
    search.pageStartIndex--
    await handleFetch()
}

async function onNextPage() {
    if (isLoading.value) return
    search.pageStartIndex++
    await handleFetch()
}

</script>