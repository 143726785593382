import { getCbraConsumerJSON, getCbraFamilyTreeReportJSON, getCbraReportTransactionData } from '@/services'
import { showError } from './error'
import { CbraTransaction } from '@/types/cbra/transactions'
import { isAxiosError } from 'axios'

export namespace ProductService {
    export const TOPIC = 'ProductService'
    export async function getReportTransactionData(reportId: string, reportBusinessCase= null, cbraTranslations={}, handleAjaxError=true, transaction:CbraTransaction) {
        let newHeader
        const isOwnTransaction = (transaction.transactionType !== 'COLLECTION' && transaction.cbraUser.memberUserId !== transaction?.reportTransaction?.memberUserId) ? false : true
     
        if (!isOwnTransaction) {
            newHeader = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'viewScope': 'MEMBER'
            }
        }
     
        let params= '?translations=' + encodeURIComponent(JSON.stringify(cbraTranslations))
        params += reportBusinessCase ? '&reportBusinessCase=' + reportBusinessCase : ''
 
        try {
            const { data } = await getCbraReportTransactionData(reportId, newHeader, params)
            return data
        } catch (error) {
            if (handleAjaxError && isAxiosError(error)) {
                return showError({ topic: TOPIC, func: 'getReportTransactionData', xhr: error })
            }
        }
    }
    /**
     * returns the URL for pdf download corresponding to the current reportId
     */
    export function getReportDownloadPdfLink(reportId: string) {
        return getReportPdf(reportId)
    }
    export function getConsumerReportPdf(reportId: string, transactionId: string) {
        return '/cbra/consumer/products/' + reportId + '/pdf?' + encodeURI('context=/vorgaenge/detailansicht-privatauskunft/?transactionId=' + transactionId)
    }
    export function getConsumerReportJSON(reportId: string) {
        return getCbraConsumerJSON(reportId)
    }
    export function getFamilyTreeReportJSON(reportId: string) {
        return getCbraFamilyTreeReportJSON(reportId)
    }
}

function getReportPdf(reportId: string) {
    return '/cbra/reports/' + reportId + '/pdf'
}

