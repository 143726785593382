<template>
    <!-- geschaeftszahlenDatumsTemplate -->
    <template v-if="index === 0">
        <template v-if="isDal">
            <span v-if="data.vonMonat != 0">{{ data.vonMonat + '/' }}</span><span v-if="data.vonJahr && !(data.vonJahr == data.bisJahr && data.vonMonat == 0)">{{ data.vonJahr + ' -' }}</span>
        </template>
        <template v-if="data.von && !isDal">
            <span>{{ getInternDateByStringForCaption(data.von) }}</span> -
        </template>
    </template>

    <template v-if="isDal">
        <span v-if="data.bisMonat != 0">{{ data.bisMonat + '/' }}</span><span v-if="data.bisJahr">{{ data.bisJahr }}</span>
    </template>

    <template v-if="data.bis && !isDal">
        <span>{{ getInternDateByStringForCaption(data.bis) }}</span>
    </template>
</template>

<script lang="ts" setup>
import { CbraReportTransactionMitarbeiterUmsatz } from '@/types/cbra/reports'
import dayjs from 'dayjs'
import { PropType } from 'vue'

defineProps({
    data: {
        type: Object as PropType<CbraReportTransactionMitarbeiterUmsatz>, 
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
    isDal: {
        type: Boolean,
        required: true,
    }
})

function getInternDateByStringForCaption(date: string) {
    let internDate = '' + date
    internDate = internDate.split('-')
    let result = ''
    let format = 'M/YYYY'
    if (typeof date !== 'undefined') {
        if (!Number.isInteger(date) && internDate.length < 3) {
            format = internDate.length === 1 ? 'YYYY' : 'M/YYYY'
        }
        result = dayjs(date).format(format)
    }
    return result
}
</script>