<template>
    <div
        v-if="activeTab === id"
        class="content-container"
        :data-qa="dataQa"
    >
        <slot />
    </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue'

const activeTab = inject('activeTab')

defineProps({
    id: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: true
    },
    dataQa: {
        type: String,
        required: false,
        default: undefined
    }
})
</script>