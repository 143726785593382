<template>
    <Modal
        v-if="wizardVisible"
        id="watchlistwizard"
        :title="modalTitle"
        :confirm-text="modalConfirmText"
        :confirm-disabled="!submitEnabled"
        :cancel-text="modalCancelText"
        @on-close="onCancel"
        @on-confirm="modalConfirmFunction"
    >
        <div v-if="selectionVisible">
            <div data-qa="modal-watchlistwizard-found-line1">
                <b>{{ entriesFound }}</b>
            </div>
            <div data-qa="modal-watchlistwizard-found-line2">
                <b>{{ $t('watchlist.wizard.found_2') }}</b>
            </div>
            <div
                class="mt"
                data-qa="modal-watchlistwizard-note"
            >
                {{ $t('watchlist.wizard.note') }}
            </div>

            <div
                v-if="entries.length > 0"
                class="watchlist-wizard-panel mt"
            >
                <ol
                    class="watchlist-wizard-list"
                    data-qa="modal-watchlistwizard-list"
                >
                    <li
                        v-for="(entry,index) in entries"
                        :key="index"
                        class="list-group-item watchlistWizardListItem"
                        :data-qa="`modal-watchlistwizard-list-entry-${index}`"
                    >
                        <div class="watchlist-wizard-list-item">
                            <div
                                class="watchlistWizardListItem-index"
                            >
                                {{ `${index + 1}.` }}
                            </div>
                            <div class=" watchlistWizardListItem-data">
                                <div>
                                    <div
                                        data-qa="modal-watchlistwizard-list-entry-name"
                                    >
                                        <b> {{ entry.companyName }}</b>
                                    </div>
                                    <div
                                        data-qa="modal-watchlistwizard-list-entry-address"
                                    >
                                        {{ getAddress(entry) }}
                                    </div>
                                </div>
                            </div>
                            <div class=" crefo-toggle-switch watchlistWizardListItem-select">
                                <label :for="`watchlistWizardEntry-${index}`">
                                    <input
                                        :id="`watchlistWizardEntry-${index}`"
                                        type="checkbox"
                                        :name="`watchlistWizardEntry-${index}`"
                                        :data-qa="`modal-watchlistwizard-list-entry-status-${index}`"
                                        :checked="entry.selected"
                                        @change="onToggleSelected(entry)"
                                    >
                                    <span class="knob" />
                                </label>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
        <div v-if="progressVisible">
            <LoadingRing v-if="showLoadingSpinner" />
            <div
                v-if="!showLoadingSpinner && processingSucceededCount"
                class="success"
                data-qa="modal-watchlistwizard-successCount"
            >
                {{ processingSucceededCount }}
            </div>
            <div
                v-if="!showLoadingSpinner && processingFailedCount"
                class="failed"
                data-qa="modal-watchlistwizard-failedCount"
            >
                {{ processingFailedCount }}
            </div>
            <div data-qa="modal-watchlistwizard-percentage">
                {{ `${progressPercentage}%` }}
            </div>
        </div>
    </Modal>
</template>

<script lang="ts" setup>
import { PropType, computed, ref,toRef, watch } from 'vue'
import Modal from '../Modal.vue'
import type { SignalSuggestion } from '@/types/cbra/signalsuggestion'
import { useI18n } from 'vue-i18n'
import { putCbraEntryOnWatchlist } from '@/services'
import LoadingRing from '../LoadingRing.vue'
import { SelectableSignalSuggestion } from '@/types/cbra/signalsuggestion'
import { countryByCode } from '@/helper/countryCodes'
import {
    placeNewWizardOpenAction,
    placeNewWizardAbortAction,
    placeNewWizardCloseAction,
    placeNewWizardSubmitAction,
    placeNewWizardResultAction
} from '@/helper/webtrekk/watchlist'

const props = defineProps({
    entries: {
        type: Array as PropType<SignalSuggestion[]>,
        required: true
    }
})

const HIDE_COUNTRY_NAME_IN_ADDRESS = ['DE']
const { t } = useI18n()

const entries = toRef<SelectableSignalSuggestion[]>(props.entries)
const wizardVisible = ref(true)
const selectionVisible = ref(true)
const progressVisible = ref(false)
const showLoadingSpinner = ref(false)
const selectedCountHasChanged = ref(false)

const progressPercentage = ref(0)
const submittedCount = ref(0)
const failedCount = ref(0)

const modalTitle = computed(() => {
    return progressVisible.value ? t('watchlist.wizard.headline_progress') : t('watchlist.wizard.headline')
})

const modalConfirmFunction = computed(()=> {
    return progressVisible.value && !showLoadingSpinner.value ? onConfirm : onSubmit
})
const modalConfirmText = computed(()=> {
    if (progressVisible.value && showLoadingSpinner.value) {
        return ''
    } else if (progressVisible.value && !showLoadingSpinner.value) {
        return t('shared.close')
    } else {
        return selectedCount.value
    }
})

const modalCancelText = computed(()=> {
    if (progressVisible.value && showLoadingSpinner.value) {
        return ''
    } else if (progressVisible.value && !showLoadingSpinner.value) {
        return ''
    } else {
        return t('watchlist.wizard.back')
    }
})

const entriesFound = computed(() => {
    let count = props.entries.length
    if (count === 1) {
        return t('watchlist.wizard.found_singular', { count })
    } else if (count > 1) {
        return t('watchlist.wizard.found_plural', { count })
    } else {
        return t('watchlist.wizard.found_zero',{ count })
    }
})

watch(entries, (oldValue, newValue) => {
    let count = newValue.filter(entry => entry.selected).length
    if (count > 0) {
        selectedCountHasChanged.value = true
    }
})

const selectedCount = computed(() => {
    let count = entries.value.filter(entry => entry.selected).length
    if (count === 1) {
        return t('watchlist.wizard.add_entries_singular', { count })
    } else if (count > 1) {
        return t('watchlist.wizard.add_entries_plural', { count })
    } else {
        return t('watchlist.wizard.add_entries_zero', { count })
    }
})

const submitEnabled = computed(() => {
    return entries.value.filter(entry => entry.selected).length > 0
})

const processingSucceededCount = computed(() => {
    if (submittedCount.value === 1) {
        return t('watchlist.wizard.added_entries_singular', { count: submittedCount.value })
    } else if (submittedCount.value > 1) {
        return t('watchlist.wizard.added_entries_plural', { count: submittedCount.value })
    } else {
        return t('watchlist.wizard.added_entries_zero', { count: submittedCount.value })
    }
})

const processingFailedCount = computed(() => {
    if (submittedCount.value === 0) {
        return ''
    }
    if (failedCount.value === 1) {
        return t('watchlist.wizard.failed_entries_singular', { count: failedCount.value })
    } else if (failedCount.value > 1) {
        return t('watchlist.wizard.failed_entries_plural', { count: failedCount.value })
    } else {
        return ''
    }
})

/**
     * Get Address from entry.
     *
     */
function getAddress(entry: SignalSuggestion) {
    let address = ''

    if (entry.zipCode) {
        address += entry.zipCode
    }
    if (entry.city) {
        address += address.length ? ' ' : ''
        address += entry.city
    }
    if (entry.countryCode && HIDE_COUNTRY_NAME_IN_ADDRESS.includes(entry.countryCode) === false) {
        address += address.length ? ', ' : ''
        const country = countryByCode(entry.countryCode) 
        if (country?.i18nKey) {
            address += t(country.i18nKey)
        }
    }

    return address
}

placeNewWizardOpenAction()

function onCancel() {
    wizardVisible.value = false

    if (selectedCountHasChanged.value) {
        placeNewWizardAbortAction(
            entries.value.filter(entry => entry.selected).length
        )
    } else {
        placeNewWizardCloseAction()
    }
}

function onSubmit() {
    selectionVisible.value = false
    progressVisible.value = true
    showLoadingSpinner.value = true
    progressPercentage.value = 0
    submitSelectedCompaniesToWatchlist()

    placeNewWizardSubmitAction(
        entries.value.filter(entry => entry.selected).length
    )
}

function onSubmitFinished() {
    showLoadingSpinner.value = false
    placeNewWizardResultAction(
        submittedCount.value,
        failedCount.value
    )
}

function onConfirm() {
    wizardVisible.value = false
    if (submittedCount.value) {
        window.location.reload()
    }
}

function submitSelectedCompaniesToWatchlist() {
    progressPercentage.value = calculateProcessedPercentage()

    if (entries.value.filter(entry => entry.selected).length === 0) {
        onSubmitFinished()
        return
    }

    entries.value.filter(entry => entry.selected).forEach((entry, index) => {
        window.setTimeout(() => {
            submitSingleCompanyToWatchlist(entry)
        }, 200 * index)
    })
}

/**
     * Adds a single watchlist entry.
     *
     */
async function submitSingleCompanyToWatchlist(entry: SelectableSignalSuggestion) {
    if (typeof entry.businessId !== 'string' || entry.businessId.length === 0) {
        return false
    }
        
    try {
        await putCbraEntryOnWatchlist({
            id: entry.businessId
        })
        const event = new Event('watchlistCountChanged')
        window.dispatchEvent(event)
        submittedCount.value = submittedCount.value + 1
    } catch (error) {
        failedCount.value = failedCount.value + 1
    }

    entry.processed = true
    progressPercentage.value =calculateProcessedPercentage()
    if (entries.value.filter(entry => entry.selected && !entry.processed).length === 0) {
        progressPercentage.value =calculateProcessedPercentage()
        onSubmitFinished()
    }

    return true
}

function calculateProcessedPercentage() {
    let selectedEntriesCount = entries.value.filter(entry => entry.selected).length
    return selectedEntriesCount > 0 ? Math.round((submittedCount.value) * 100 / selectedEntriesCount) : 100
}

function onToggleSelected(entry: SelectableSignalSuggestion) {
    const newEntries = entries.value.map(e => {
        if (e === entry) {
            e.selected = !e.selected
        }
        return e
    })
    entries.value = newEntries
}

</script>

<style lang="less" scoped>

.watchlist-wizard-panel {
    padding: 2px;
    border: 1px solid #4c4c4c;
}
.watchlist-wizard-list {
    list-style-type: none;
}

.watchlist-wizard-list-item {
    display: grid;
    grid-template-columns: 1fr 8fr 3fr;
    gap: 10px;
    padding: 10px 15px;
    align-items: center;
    text-align: left;
}

ol {
    margin-bottom: 0;
}
</style>