<template>
    <ListWidget
        :results="results"
        :widget="props.widget"
        :is-loading="isLoading"
        :sorting-type="search.sort"
        :header-name="$t('dashboard.widget.watchlist.list.header.name')"
        :header-changes="$t('dashboard.widget.watchlist.list.header.changes')"
        :sort-name="$t('dashboard.widget.watchlist.list.header.date')"
        :page-size="search.pageSize"
        :page-start-index="search.pageStartIndex"
        :list-size="listSize"
        @on-sort="onSort"
        @on-previous-page="onPreviousPage"
        @on-next-page="onNextPage"
    />
</template>

<script setup lang="ts">
import { onMounted, PropType, reactive, ref } from 'vue'
import { EnhancedResults, Widget } from '@/types/widget'
import { formatDate } from '@/filters'
import { useI18n } from 'vue-i18n'
import ListWidget from './ListWidget.vue'
import { WatchlistSearchOptions } from '@/types/cbra/watchlist'
import { WatchlistEntry } from '@/types/cbra/watchlist'
import { WatchlistSort } from '@/enums/watchlist'
import { getCbraWatchlistentries } from '@/services'
import { watchlistLinks } from '@/data/typo3Links'

const props = defineProps({
    widget: {
        type: Object as PropType<Widget>,
        required: true
    },
})
const listSize = ref(0)
const results = ref<EnhancedResults[]>([])
const isLoading = ref(true)
const search = reactive<WatchlistSearchOptions>({
    pageSize: 3,
    pageStartIndex: 0,
    searchText: '',
    sort: WatchlistSort.EVENT_TIMESTAMP_DESC,
    watchlistFilter: []
})

const { t, locale } = useI18n()

onMounted(async() => {
    await handleFetch()
})

async function onSort() {
    if (search.sort === WatchlistSort.EVENT_TIMESTAMP_ASC) {
        search.sort = WatchlistSort.EVENT_TIMESTAMP_DESC
    } else {
        search.sort = WatchlistSort.EVENT_TIMESTAMP_ASC
    }
    await handleFetch()
}

async function handleFetch() {
    isLoading.value = true
    try {
        const response = await getCbraWatchlistentries(search)
        const enhancedResults = response.data.watchlistEntries.map(enhanceItem)
        listSize.value = response.data.listSize
        results.value = enhancedResults
        isLoading.value = false
    } catch (error) {
        console.error(error)
        isLoading.value = false
    }
}

function generateLinkUrl(item: WatchlistEntry) {
    if (item.transactionId) {
        return `${props.widget.link?.url}?transactionId=${item.transactionId}`
    } else if (item.businessId) {
        return `${watchlistLinks[locale.value]}?businessId=${item.businessId}`
    }
    return ''
}

function enhanceItem(item: WatchlistEntry) {
    return {
        name: item.businessName,
        changeReason: findAndTranslateChangeReason(item),
        date: formatDate(item.lastEventTimestamp),
        transactionId: item.transactionId,
        businessId: item.businessId,
        link: generateLinkUrl(item)
    }
}

function findAndTranslateChangeReason(item: WatchlistEntry) {
    if (item.lastSupplementReasonIdList && item.lastSupplementReasonIdList.length > 0) {
        return t(`watchlist.list.supplementReason.${item.lastSupplementReasonIdList[0]}`)
    }   
    
    if (item.lastSignalReasonIdList && item.lastSignalReasonIdList.length > 0) {
        return t(`watchlist.list.signalReason.${item.lastSignalReasonIdList[0]}`)
    }
    return ''
}

async function onPreviousPage() {
    if (isLoading.value) return
    search.pageStartIndex--
    await handleFetch()
}

async function onNextPage() {
    if (isLoading.value) return
    search.pageStartIndex++
    await handleFetch()
}

</script>