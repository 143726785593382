<!-- eslint-disable vue/no-v-html -->
<template>
    <Modal
        id="reportTrackingSettings"
        :title="$t('transaction.reportTransactionSettings.dialog.headline')"
        cancel-data-qa="monitoring-terminate-cancel"
        confirm-data-qa="monitoring-terminate-ok"
        :cancel-text="$t('transaction.reportTransactionSettings.dialog.cancel')"
        :confirm-text="$t('transaction.reportTransactionSettings.dialog.save')"
        :confirm-disabled="!isValid || isLoading"
        @on-close="onClose"
        @on-confirm="onConfirm"
    >
        <div
            id="display_10"
            class="reportTrackingSettings--display_10 text-align-left"
        >
            <div class="row">
                <div class="col-12">
                    <h4>
                        {{ $t('transaction.reportTransactionSettings.monitoring.unlimited.active') }}
                        <Tooltip
                            id="display_10--supplement-inactive--info-text"
                            position="right"
                            data-qa="display_10--supplement-inactive--info-text"
                            class="ml"
                            wide
                        >
                            <font-awesome-icon
                                icon="fa-regular fa-circle-info" 
                                size="2x"
                                color="var(--color-c-p1)"
                            />
                            <template #content>
                                <span v-html="$t('transaction.reportTransactionSettings.display_10.infotext.1')" />
                            </template>
                        </Tooltip>
                    </h4>
                    <div class="row">
                        <div class="col-md-10 col-12 offset-md-1">
                            <h5>{{ $t('transaction.reportTransactionSettings.monitoring.cancel.options.headline') }}</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 col-12 offset-md-1">
                            <Checkbox
                                id="removeUnMonitoringCheckbox"
                                v-model="removeUnMonitoringCheckbox"
                                name="removeUnMonitoringCheckbox"
                                :label="$t('transaction.reportTransactionSettings.monitoring.cancel.text')"
                                data-qa="display_10--plus-option--checkbox"
                                :disabled="orderMonitoringLimitedCheckbox"
                            />
                        </div>
                    </div>

                    <div class="row small-content-spacer">
                        <div class="col-md-10 col-12 offset-md-1 borderTop small-content-spacer">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ $t('transaction.reportTransactionSettings.monitoring.change.options.headline') }}</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-5">
                                    <Checkbox
                                        id="orderMonitoringLimitedCheckbox"
                                        v-model="orderMonitoringLimitedCheckbox"
                                        name="orderMonitoringLimitedCheckbox"
                                        :label="$t('transaction.reportTransactionSettings.add.monitoring.limited.text')"
                                        data-qa="display_10--order-mon-limited-checkbox"
                                        :disabled="changeMonitoringOptionsDisabled"
                                    />
                                </div>
                                <div class="col-12 col-md-3">
                                    <Select
                                        id="befristungEndeMonat"
                                        v-model="befristungEndeMonat"
                                        :options="monitoringLimitedMonthOptions"
                                        data-qa="display_10-befristungEndeMonat"
                                        label=""
                                        :disabled="changeMonitoringOptionsDisabled"
                                        :searchable="false"
                                    />
                                </div>
                                <div class="col-12 col-md-3">
                                    <Select
                                        id="befristungEndeJahr"
                                        v-model="befristungEndeJahr"
                                        :options="monitoringLimitedYearOptions"
                                        data-qa="display_10-befristungEndeJahr"
                                        label=""
                                        :disabled="changeMonitoringOptionsDisabled"
                                        :searchable="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
    <Modal
        v-if="modal"
        :id="modal.id"
        :title="modal.title"
        :cancel-text="modal.cancelText"
        :confirm-text="modal.confirmText"
        :error-context="modal.errorContext"
        :icon="modal.icon"
        @on-close="modal.onClose"
        @on-confirm="modal.onConfirm"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="modal.content" />
    </Modal>
</template>

<script lang="ts" setup>
import Checkbox from '@/components/Input/Checkbox.vue'
import Select from '@/components/Input/Select.vue'
import Modal from '@/components/Modal.vue'
import Tooltip from '@/components/Tooltip.vue'
import CONSTANTS from '@/data/constants'
import helpers from '@/helper/misc'
import { CbraReportTransaction, CbraTransaction, CbraUpdateReportTrackingPayload } from '@/types/cbra/transactions'
import dayjs from 'dayjs'
import { PropType, computed, ref } from 'vue'
import { useReportTracking } from './UseReportTracking'

const props = defineProps({
    reportTransaction: {
        type: Object as PropType<CbraReportTransaction>,
        required: true
    },
    transactionData: {
        type: Object as PropType<CbraTransaction>,
        required: true
    },
})

const emit = defineEmits(['onClose'])

function onClose() {
    reset()
    emit('onClose')
}

const { modal, isLoading, updateReportTracking } = useReportTracking()

const productOrderId = ref(props.reportTransaction.productOrderId)

const changeMonitoringOptionsDisabled = computed(() => {
    return removeUnMonitoringCheckbox.value
})

const isValid = computed(() => {
    return removeUnMonitoringCheckbox.value ||
    orderMonitoringLimitedCheckbox.value 
})

const monitoringLimitedMonthOptions = CONSTANTS.LIMITED_MONITORING_MONTH_OPTIONS
const monitoringLimitedYearOptions = helpers.getMonitoringLimitedYearOptions()

const defaultMonth = dayjs().locale('en').format('MMMM').toUpperCase()
const defaultYearNonDal = dayjs().add(1, 'year').format('YYYY')

const removeUnMonitoringCheckbox = ref(false)
const orderMonitoringLimitedCheckbox = ref(false)
const befristungEndeMonat = ref(defaultMonth)
const befristungEndeJahr = ref(defaultYearNonDal)

function onConfirm() {
    sendRequest()
}

function sendRequest() {
    if (!isValid.value || !productOrderId.value) {
        return
    }

    let params: CbraUpdateReportTrackingPayload = { ueberwachung: null }
    if (removeUnMonitoringCheckbox.value) {
        params.ueberwachung = 'MONITORING_KUENDIGEN'
    } else if (orderMonitoringLimitedCheckbox.value) {
        params.ueberwachung = 'MONITORING_BEFRISTEN'
        params.befristungEndeMonat = befristungEndeMonat.value
        params.befristungEndeJahr = parseInt(befristungEndeJahr.value)
    }
    return updateReportTracking(productOrderId.value, params, props.transactionData)
}

function reset() {
    removeUnMonitoringCheckbox.value = false
    orderMonitoringLimitedCheckbox.value = false
    befristungEndeMonat.value = defaultMonth
    befristungEndeJahr.value = defaultYearNonDal
}

</script>