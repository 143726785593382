<template>
    <div
        v-if="!userStore.authenticated"
        class="contact bg-brand-light-grey"
    >
        <div class="container contact__container content-spacer">
            <div class="row mb-large">
                <div class="col-12 col-md-4 mb">
                    <div class="row">
                        <div class="col-2">
                            <h3>
                                <span class="text-brand-darkest-blue">
                                    <font-awesome-icon
                                        icon="fa-light fa-phone"
                                        size="xl"
                                    />
                                </span>
                            </h3>
                        </div>
                        <div
                            class="col-10"
                            data-qa="ce-1240"
                        >
                            <h3>{{ $t("Contact.technical.title") }}</h3>
                            <p class="mb-small">
                                {{ $t("Contact.technical.phoneNumber") }}
                            </p>
                            <p class="mb">
                                {{ $t("Contact.technical.text") }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 mb">
                    <div class="row">
                        <div class="col-2">
                            <h3>
                                <span class="text-brand-darkest-blue">
                                    <font-awesome-icon
                                        icon="fa-light fa-envelope"
                                        size="xl"
                                    />
                                </span>
                            </h3>
                        </div>
                        <div
                            class="col-10"
                            data-qa="ce-1241"
                        >
                            <h3>{{ $t("Contact.email.title") }}</h3>
                            <i18n-t
                                keypath="Contact.email.text"
                                tag="p"
                                class="mb"
                            >
                                <template #link>
                                    <a
                                        :href="`mailto:${$t('Contact.email.email')}`"
                                        title="Frage zu Meine Creditreform"
                                    >
                                        {{ $t("Contact.email.link") }}
                                    </a>
                                </template>
                            </i18n-t>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="row">
                        <div class="col-2">
                            <h3>
                                <span class="text-brand-darkest-blue">
                                    <font-awesome-icon
                                        icon="fa-light fa-location-dot"
                                        size="xl"
                                    />
                                </span>
                            </h3>
                        </div>
                        <div
                            class="col-10"
                            data-qa="ce-1239"
                        >
                            <h3>{{ $t("Contact.onSite.title") }}</h3>
                            <i18n-t
                                keypath="Contact.onSite.text"
                                tag="p"
                                class="mb"
                            >
                                <template #link>
                                    <a
                                        :href="$t('Contact.onSite.url')"
                                        rel="noopener"
                                        title="Creditreform vor Ort"
                                        target="_blank"
                                    >
                                        {{ $t("Contact.onSite.link") }}
                                    </a>
                                </template>
                            </i18n-t>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang='ts'>
import { useUserStore } from '@/stores/user'
const userStore = useUserStore()
</script>
