<template>
    <div class="contact bg-brand-light-grey">
        <div class="container normal-content-spacer">
            <div class="row mb">
                <div class="col-12 col-md-4 mb">
                    <div class="bg-white">
                        <h3>{{ $t('transactions.listFooter.item1.title') }}</h3>
                        <p>{{ $t('transactions.listFooter.item1.text') }}</p>
                    </div>
                </div>
                <div class="col-12 col-md-4 mb">
                    <div class="bg-white">
                        <h3>{{ $t('transactions.listFooter.item2.title') }}</h3>
                        <p>{{ $t('transactions.listFooter.item2.text') }}</p>
                    </div>
                </div>
                <div class="col-12 col-md-4 mb">
                    <div class="bg-white">
                        <h3>{{ $t('transactions.listFooter.item3.title') }}</h3>
                        <p>{{ $t('transactions.listFooter.item3.text') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.bg-white {
    padding: 20px;
}
</style>