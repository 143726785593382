interface Keycloak {
    idTokenParsed?: Object
    token?: string
}
export function hasKeycloakProperty(object: unknown): object is {
    keycloak: Keycloak
    updateKeycloakToken: () => Promise<void>
} {
    return Object.prototype.hasOwnProperty.call(object, 'keycloak')
}
export function hasCrefoUIProperty(object: unknown): object is { CrefoUI: any } {
    return Object.prototype.hasOwnProperty.call(object, 'CrefoUI')
}