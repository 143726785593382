import CONSTANTS from '@/data/constants'
import { TransformedProduct } from '@/data/products'
import { handleAxiosErrorReason } from '@/helper/errormessage'
import helpers from '@/helper/misc'
import { buyProduct } from '@/helper/product'
import { scrollTo } from '@/helper/scrollto'
import { getASKErrorKey, showError } from '@/helper/services/error'
import { ROLES, hasUserRole } from '@/helper/services/user'
import Storage from '@/helper/storage'
import { ReportWebTrekkService } from '@/helper/webtrekk/reportWeb'
import { superUserOverlayNotAuthorized } from '@/helper/webtrekk/superuser'
import { createCbraOrderReport, getCbraUserPreferences, postCbraOrderReport } from '@/services'
import { useProductStore } from '@/stores/product'
import { CbraOrderReportCreateData } from '@/types/cbra/advancedSearch'
import { BuyProductPayload } from '@/types/cbra/product'
import { CbraPostalAddress } from '@/types/cbra/shared'
import { CbraTransaction } from '@/types/cbra/transactions'
import { AxiosHeaderValue, isAxiosError } from 'axios'
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { adsLinks } from '@/data/typo3Links'
import i18n from '@/i18n'
import { differentContextActive } from '@/helper/services/memberNavigation'
import { checkUserRight } from '@/helper/services/userRights'
import { getPortfolioHeader } from '@/helper/services/features'

const TOPIC = 'ProductService'

export function useProduct(isDal?: boolean) {
    const productStore = useProductStore()
    const { modal , watchlistSupported, productType } = storeToRefs(productStore)
    const { t, locale } = useI18n()
    const route = useRoute()

    const productOrderId = ref('')
    const isNotMyContext = productStore.isNotMyContext
    const businessId = route.query.businessId?.toString()
    const reportLanguageOptions = CONSTANTS.LANGUAGE_OPTIONS
    const legitimateInterestOptions = CONSTANTS.LEGITIMATE_INTEREST_OPTIONS
    const reportLanguage = ref('DE')
    const additionalInfos = ref('')
    const legitimateInterest = ref<null | string>(null)
    const legitimateInterestChanged = ref(false)
    const monitoring = ref(false)
    const monitoringLimited = ref(false)
    const monitoringPlus = ref(false)
    const actionInProgress = ref(false)
    const showSpinner = ref(false)
    const transactionId = ref('')
    const bought = ref(false)
    const referenceNumber = ref('')
    const watchlist = ref(false)
    const defaultReportLegitimateInterest = ref('')
    const currentLocale = i18n.global.locale.value
    const currentLocaleLanguage = currentLocale.substring(0, 2).toUpperCase()

    if (reportLanguageOptions.findIndex(option => option.code === currentLocaleLanguage) !== undefined) {
        reportLanguage.value = currentLocaleLanguage
    }

    // Befristetes Monitoring begin
    const defaultMonth = dayjs().locale('en').format('MMMM').toUpperCase()
    const defaultYear = dayjs().add(3, 'years').format('YYYY')
    const defaultYearNonDal = dayjs().add(1, 'years').format('YYYY')
    const monitoringLimitedMonthOptions = CONSTANTS.LIMITED_MONITORING_MONTH_OPTIONS
    const befristungEndeMonat = ref(defaultMonth)

    const monitoringLimitedYearOptions = helpers.getMonitoringLimitedYearOptions()
    const befristungEndeJahr = ref(isDal ? defaultYear : defaultYearNonDal)
    // Befristetes Monitoring ende

    const isDisabled = computed(() => isNotMyContext)

    const showAcquireButton = computed(() => {
        return !bought.value || (bought.value && Boolean(transactionId.value))
    })

    watch(legitimateInterest, (newValue) => {
        if (defaultReportLegitimateInterest.value !== newValue) {
            legitimateInterestChanged.value = true
        }
    } )

    watch(productType, () => {
        befristungEndeJahr.value = isDal ? defaultYear : defaultYearNonDal
        befristungEndeMonat.value = defaultMonth
    })

    function resetLegitimateInterest() {
        if (defaultReportLegitimateInterest.value !== '') {
            legitimateInterest.value = defaultReportLegitimateInterest.value
        } else {
            legitimateInterest.value = null
        }
        legitimateInterestChanged.value = false
    }

    function resetMonitoringLimited() {
        monitoringLimited.value = false
        befristungEndeMonat.value = defaultMonth
        befristungEndeJahr.value = isDal ? defaultYear : defaultYearNonDal
    }

    function setMonitoringLimited() {
        if (isDisabled.value) return
        monitoring.value = false
        monitoringPlus.value = false
    }

    function setMonitoring(product: TransformedProduct) {
        if (isDisabled.value) return

        if (product.hasMonitoringRequired && !monitoringLimited.value) {
            monitoring.value = true
            return
        }

        resetMonitoringLimited()
    }

    onMounted(loadUserPreferences)

    async function loadUserPreferences() {
        const { data } = await getCbraUserPreferences()

        if (data) {
            const prefs = data
            if (typeof prefs.defaultReportLegitimateInterest !== 'undefined' && prefs.defaultReportLegitimateInterest !== null) {
                defaultReportLegitimateInterest.value = prefs.defaultReportLegitimateInterest
                await nextTick()
                legitimateInterest.value = prefs.defaultReportLegitimateInterest
            }
            if (typeof prefs.reportLanguage !== 'undefined' && prefs.reportLanguage !== null) {
                if (reportLanguageOptions.filter((languageOption) => languageOption.code === prefs.reportLanguage).length > 0) {
                    reportLanguage.value = prefs.reportLanguage
                }
            }
        }
    }

    function showTransactionNotice( payload: BuyProductPayload | CbraOrderReportCreateData, hasBusinessData: boolean, hasToBeOrdered?: boolean) {
        const confirmCallback = () => {
            modal.value = undefined
            hasToBeOrdered ? _orderProduct(payload, hasBusinessData) : _aquireProduct(payload, hasBusinessData)
        }

        const cancelCallback = () => {
            actionInProgress.value = false
            modal.value = undefined
            scrollTo('#searchResults')
        }

        modal.value = {
            id: 'transactionNotice',
            title: '',
            content: t('report.detail.quickbuyarea.modal.text'),
            icon: 'info',
            dataQa: hasToBeOrdered ? 'order-report-notice' : 'buy-report-notice',
            confirmText: hasToBeOrdered ? t('report.detail.orderReportNow.short') : t('report.detail.buyReportNow.short'),
            cancelText: t('report.orderForm.label.legitimateInterest.abort'),
            onConfirm: confirmCallback,
            onClose: cancelCallback,

        }
    }

    async function _aquireProduct( payload: BuyProductPayload, hasBusinessData: boolean) {
        const urlParameters = Object.keys(payload).map(function(k) {
            // @ts-ignore
            return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k])
        }).join('&')

        if (payload.productType === 'FAMILYTREE_PLUS') {
            ReportWebTrekkService.placeNewBuyReportRequest(payload)
            followUrl(urlParameters)
            return
        }

        try {
            const { data } = await buyProduct( payload, hasBusinessData )
            transactionId.value = data.transactionId?.toString()
            bought.value = true
            followUrl(urlParameters)
        } catch (error) {
            if (isAxiosError(error) && (error.response?.status === 401 || error.response?.status === 403)) {
                superUserOverlayNotAuthorized('orderReportQuickArea')
                modal.value = {
                    id: 'notGranted',
                    title: t('error.buyReport.buyWithoutGrants.title'),
                    content: t('error.buyReport.buyWithoutGrants.description'),
                    icon: 'error',
                    dataQa: 'report-not-granted',
                    onClose: () => modal.value = undefined,
                    onConfirm: () => modal.value = undefined,
                    confirmText: t('shared.modals.ok'),
                    errorContext: handleAxiosErrorReason(error)
                }
            } else if (isAxiosError(error) && error.response?.status === 422) {
                const ask_key = getASKErrorKey({ xhr: error })

                if (ask_key) {
                    if (ask_key === 'ASK_30001' || ask_key === 'ASK_30002' || ask_key === 'ASK_30003') {
                        if (ask_key === 'ASK_30001' || ask_key === 'ASK_30002') {
                            modal.value = {
                                id: 'ASK_30001',
                                title: t('error.ASK_30001'),
                                content: t('error.ASK_30001.description'),
                                icon: 'error',
                                dataQa: 'error_ASK_30001',
                                onClose: () => modal.value = undefined,
                                onConfirm: () => modal.value = undefined,
                                errorContext: handleAxiosErrorReason(error)
                            }
                        } else {
                            modal.value = {
                                id: 'ASK_30001',
                                title: t(`error.${ask_key}`),
                                content: t(`error.${ask_key}.description`),
                                icon: 'error',
                                dataQa: `error_${ask_key}`,
                                onClose: () => modal.value = undefined,
                                onConfirm: () => modal.value = undefined,
                                errorContext: handleAxiosErrorReason(error)
                            }
                        }
                    } else {
                        ReportWebTrekkService.placeNewBuyReportError(payload)
                        if (isAxiosError(error)) {
                            ReportWebTrekkService.placeNewBuyReportError(payload)
                            const modalprops = showError({ topic: TOPIC, func: 'buyReport', xhr: error })
                            if (modalprops) {
                                modalprops.errorContext = handleAxiosErrorReason(error)
                                modalprops.onClose = () => modal.value = undefined
                                modalprops.onConfirm = () => modal.value = undefined
                                modal.value = modalprops
                            }
                        }
                    }
                }
            } else {
                if (isAxiosError(error)) {
                    ReportWebTrekkService.placeNewBuyReportError(payload)
                    const modalprops = showError({ topic: TOPIC, func: 'buyReport', xhr: error })
                    if (modalprops) {
                        modalprops.errorContext = handleAxiosErrorReason(error)
                        modalprops.onClose = () => modal.value = undefined
                        modalprops.onConfirm = () => modal.value = undefined
                        modal.value = modalprops
                    }
                }
            }
            actionInProgress.value = false
        }
        showSpinner.value = false
    }

    function followUrl(urlParameters: string) {
        const followUrl = `${adsLinks[locale.value]}?no_cache=1&transactionId=`
        location.assign(followUrl + transactionId.value + '&' + urlParameters)
    }

    function acquireProduct(product: TransformedProduct, hasBusinessData: boolean, relatedTransactions: CbraTransaction[]) {
        if (!product || !businessId ||!legitimateInterest.value) return
        actionInProgress.value = true
        showSpinner.value = true

        const data: BuyProductPayload = {
            businessId: businessId,
            legitimateInterest: legitimateInterest.value,
            reportLanguage: reportLanguage.value,
            productType: product.type,
            monitoring: false,
            monitoringPlus: false,
            befristungEndeMonat: null,
            befristungEndeJahr: null,
            watchlist: false,
        }

        if (referenceNumber.value) {
            data.referenceNumber = referenceNumber.value
        }

        actionInProgress.value = true
        showSpinner.value = true
        if (product.hasMonitoring) {
            data.monitoring = monitoring.value
        }

        if (product.hasMonitoringPlus && monitoring.value) {
            data.monitoringPlus = monitoringPlus.value
        } else {
            data.monitoringPlus = false
        }

        if (monitoringLimited.value) {
            data.monitoring = true
            data.monitoringPlus = monitoringPlus.value
            data.befristungEndeMonat = befristungEndeMonat.value
            data.befristungEndeJahr = parseInt(befristungEndeJahr.value)
        } else {
            data.befristungEndeMonat = null
            data.befristungEndeJahr = null
        }

        if (watchlistSupported && product.isSignalProduct) {
            data.watchlist = watchlist.value
        } else {
            data.watchlist = false
        }

        if (relatedTransactions !== undefined && relatedTransactions.length > 0) {
            showTransactionNotice(data, hasBusinessData, product.hasToBeOrdered)
        } else {
            _aquireProduct(data, hasBusinessData)
        }
    }

    async function orderProduct(product: TransformedProduct, hasBusinessData: boolean, relatedTransactions: CbraTransaction[]) {
        const data: CbraOrderReportCreateData = {
            legitimateInterest: legitimateInterest.value,
            reportLanguage: reportLanguage.value,
            productType: product.type,
            referenceNumber: referenceNumber.value || undefined,
            monitoring: false,
            monitoringPlus: false,
            befristungEndeMonat: null,
            befristungEndeJahr: null,
            watchlist: false,
        }
        actionInProgress.value = true
        showSpinner.value = true
        if (product.hasMonitoring) {
            data.monitoring = monitoring.value
        }

        if (product.hasMonitoringPlus && monitoring.value) {
            data.monitoringPlus = monitoringPlus.value
        } else {
            data.monitoringPlus = false
        }

        if (monitoringLimited.value) {
            data.monitoring = true
            data.monitoringPlus = monitoringPlus.value
            data.befristungEndeMonat = befristungEndeMonat.value
            data.befristungEndeJahr = parseInt(befristungEndeJahr.value)
        } else {
            data.befristungEndeMonat = null
            data.befristungEndeJahr = null
        }

        data.businessId = businessId

        data.additionalInfos = additionalInfos.value

        if (relatedTransactions !== undefined && relatedTransactions.length > 0) {
            showTransactionNotice(data, hasBusinessData, product.hasToBeOrdered)
        } else {
            _orderProduct(data, hasBusinessData)
        }
    }

    async function _orderProduct(data: CbraOrderReportCreateData, hasBusinessData: boolean) {
        if (hasBusinessData) {
            const response = await orderReport(data)
            if (response) {
                productOrderId.value = response.productOrderId
                bought.value = true
            }
        } else {
            const businessItem = Storage.get(CONSTANTS.EVENTS.SAVED_BUSINESS_ITEM)
            if (!isEmptyObject(businessItem)) {
                delete data.businessId
                data.postalAddress =businessItem.postalAddress
                data.postalAddress = data.postalAddress ? stripCity(data.postalAddress) : data.postalAddress
                data.businessName = businessItem.businessName

                try {
                    const headers: {[key: string]: AxiosHeaderValue} = {}
                    if (hasUserRole(ROLES.PORTFOLIO_AUSKUNFT)) {
                        headers.viewScope = 'MEMBER'
                    }
                    const response = await createCbraOrderReport(data, headers)
                    productOrderId.value = response.data.productOrderId
                    bought.value = true
                } catch (error) {
                    if (isAxiosError(error) && error.response?.status === 422) {
                        const ask_key = getASKErrorKey({ xhr: error })

                        if (ask_key) {
                            modal.value = showError({ topic: 'ProductService', func: 'buyReport', xhr: error })
                            if (modal.value) {
                                modal.value.onConfirm = () => modal.value = undefined
                                modal.value.onClose = () => modal.value = undefined
                            }
                        } else {
                            // Product not available
                            modal.value = {
                                id: 'productNotAvailable',
                                title: t('error.ProductService.buyReportProductNotAvailable.title'),
                                content: t('error.ProductService.buyReportProductNotAvailable.description'),
                                confirmText: t('error.ProductService.buyReportProductNotAvailable.confirmButtonText'),
                                cancelText: t('report.orderForm.label.legitimateInterest.abort'),
                                onConfirm: () => modal.value = undefined,
                                icon: 'info',
                                onClose: () => modal.value = undefined,
                                errorContext: handleAxiosErrorReason(error)
                            }
                        }
                    } else if (isAxiosError(error)) {
                        modal.value = showError({ topic: 'AdvancedSearchService', func: 'orderReport', xhr: error })
                        if (modal.value) {
                            modal.value.onConfirm = () => modal.value = undefined
                            modal.value.onClose = () => modal.value = undefined
                        }
                    }
                    actionInProgress.value = false
                }
            }
        }
    }
    async function orderReport(orderReportRequest:CbraOrderReportCreateData ) {
        ReportWebTrekkService.placeNewOrderReportRequest(orderReportRequest)

        try {
            let headers: { [key: string]: AxiosHeaderValue} | undefined = undefined
            if (differentContextActive() && checkUserRight('portfolioReport')) {
                headers = getPortfolioHeader()
            }
            const { data } = await postCbraOrderReport(orderReportRequest, headers)
            ReportWebTrekkService.placeNewOrderReportSuccess(orderReportRequest)
            return data
        } catch (error) {
            if (isAxiosError(error) && (error.response?.status === 401 || error.response?.status === 403)) {
                superUserOverlayNotAuthorized('orderReport')
                // showError({ topic: TOPIC, func: 'orderReportUnauthorized', xhr: error, dataQaAttribute: 'userright-not-authorized' })
                modal.value = showError({ topic: TOPIC, func: 'orderReportUnauthorized', xhr: error })
                if (modal.value) {
                    modal.value.onConfirm = () => modal.value = undefined
                    modal.value.onClose = () => modal.value = undefined
                }
            } else if (isAxiosError(error) && error.response?.status === 422) { // Product not available
                const ask_key = getASKErrorKey({ xhr: error })

                if (ask_key) {
                    modal.value = showError({ topic: TOPIC, func: 'buyReport', xhr: error })
                    if (modal.value) {
                        modal.value.onConfirm = () => modal.value = undefined
                        modal.value.onClose = () => modal.value = undefined
                    }
                } else {
                    modal.value = {
                        id: 'productNotAvailable',
                        title: t('error.ProductService.buyReportProductNotAvailable.title'),
                        content: t('error.ProductService.buyReportProductNotAvailable.description'),
                        confirmText: t('error.ProductService.buyReportProductNotAvailable.confirmButtonText'),
                        cancelText: t('report.orderForm.label.legitimateInterest.abort'),
                        icon: 'info',
                        onClose: () => modal.value = undefined,
                        errorContext: handleAxiosErrorReason(error)
                    }
                }
            } else if (isAxiosError(error)) {
                ReportWebTrekkService.placeNewOrderReportError(orderReportRequest)
                modal.value = showError({ topic: TOPIC, func: 'orderReport', xhr: error })
                if (modal.value) {
                    modal.value.onConfirm = () => modal.value = undefined
                    modal.value.onClose = () => modal.value = undefined
                }
            }
            actionInProgress.value = false
        }
    }

    return { resetLegitimateInterest, legitimateInterestChanged, showSpinner,showAcquireButton, watchlist, watchlistSupported, businessId, acquireProduct, referenceNumber, bought,actionInProgress, transactionId, reportLanguageOptions,legitimateInterestOptions, reportLanguage, legitimateInterest, monitoringPlus, monitoring, monitoringLimited, monitoringLimitedMonthOptions, befristungEndeMonat, monitoringLimitedYearOptions, befristungEndeJahr, isDisabled, resetMonitoringLimited, setMonitoringLimited, setMonitoring, modal, orderProduct, productOrderId, additionalInfos }
}

function isEmptyObject(obj: any) {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false
        }
    }
    return true
}

function stripCity(postalAddress: CbraPostalAddress) {
    if (postalAddress.city && postalAddress.city.length > 40) {
        postalAddress.city = postalAddress.city.substr(0,40)
    }
    return postalAddress
}
