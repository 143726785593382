<!-- eslint-disable vue/no-v-html -->
<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.wkv'

</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('helpWKV.breadcrumb') }}</li>
        </Breadcrumb>

        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c2751"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-2751"
                    >
                        <div
                            id="toc2751"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2753"
                                                href="#"
                                                @click.prevent="scrollTo('#c9457')"
                                            >
                                                {{ $t('helpWKV.sections.1.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2765"
                                                href="#"
                                                @click.prevent="scrollTo('#c9461')"
                                            >
                                                {{ $t('helpWKV.sections.2.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-2766"
                                                href="#"
                                                @click.prevent="scrollTo('#c9465')"
                                            >
                                                {{ $t('helpWKV.sections.3.title') }}
                                            </a>
                                        </li>

                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-3549"
                                                href="#"
                                                @click.prevent="scrollTo('#c9469')"
                                            >
                                                {{ $t('helpWKV.sections.4.title') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <img
                        src="@/assets/img/help/wkv/mein_WKV_Einzelschutz_1000x250.jpg"
                    >
                    <div
                        id="c9457"
                        class="content-spacer"
                    >
                        <h3>{{ $t('helpWKV.sections.1.title') }}</h3>
                        <div v-html="$t('helpWKV.sections.1.text')" />
                    </div>
                    <div
                        id="c9461"
                        class="content-spacer"
                    >
                        <h3>{{ $t('helpWKV.sections.2.title') }}</h3>
                        <div v-html="$t('helpWKV.sections.2.text')" />
                    </div>
                    <div
                        id="c9465"
                        class="content-spacer"
                    >
                        <h3>{{ $t('helpWKV.sections.3.title') }}</h3>

                        <div v-html="$t('helpWKV.sections.3.text')" />
                    </div>
                    <div
                        id="c9469"
                        class="content-spacer"
                    >
                        <h3>{{ $t('helpWKV.sections.4.title') }}</h3>

                        <img
                            src="@/assets/img/help/wkv/Watchlist_mein_WKV_Einzelschutz.png"
                        >

                        <div
                            class="small-content-spacer"
                            v-html="$t('helpWKV.sections.4.text')"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

</style>