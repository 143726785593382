import { App as AppType, h, createApp } from 'vue'
import singleSpaVue from 'single-spa-vue'
import initVue from '@/initVue'

import 'vue-select/dist/vue-select.css'
import './main.less'

import App from '@/App.vue'

const vueLifecycles = singleSpaVue({
    createApp,
    appOptions: {
        name: '@portal-microfrontend/mc-portal',
        render() {
            return h(App)
        },
    },
    handleInstance: (app: AppType) => {
        initVue(app)
    }
})
export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
