<template>
    <div>
        <div 
            v-if="_transaction && _transaction.collectionTransaction"
            :id="`transaction-${_transaction.id}`"
            :key="_transaction.id"
            class="results-list--item"
            :class="{ 'inactive': isProductInProgress(_transaction) }"
        >
            <div class="row">
                <div class="col-12">
                    <div class="bg-white">
                        <div class="row">
                            <div class="col-12 col-md-5">
                                <div class="row">
                                    <div class="col-1">
                                        <div 
                                            class="icon-favourite" 
                                            data-qa="myTransactions-iconStar"
                                            @click="_toggleFavourite"
                                        >
                                            <font-awesome-icon
                                                :icon="`fa-light fa-star ${_transaction.favourite ? 'fa-solid' : ''}`"
                                                color="#009ee2"
                                            />
                                        </div>
                                    </div>
                                    <div 
                                        class="col-11"
                                        @click.prevent="openDetailsPage"
                                    >
                                        <span class="h4 transaction-title">
                                            <span
                                                v-if="_transaction.collectionTransaction.debtor.company &&
                                                    _transaction.collectionTransaction.debtor.company.businessName"
                                                data-qa="myTransactions-searchResultList-businessName"
                                            >{{ _transaction.collectionTransaction.debtor.company.businessName }}</span>
                                            <span
                                                v-if="_transaction.collectionTransaction.debtor.company &&
                                                    _transaction.collectionTransaction.debtor.company.businessName &&
                                                    _transaction.collectionTransaction.debtor.privatePerson &&
                                                    _transaction.collectionTransaction.debtor.privatePerson.givenName"
                                            >&nbsp;</span>
                                            <span data-qa="myTransactions-searchResultList-debtorFirtsName">
                                                {{ _transaction.collectionTransaction.debtor.privatePerson.givenName }}
                                            </span>
                                            <span
                                                v-if="_transaction.collectionTransaction.debtor.privatePerson &&
                                                    _transaction.collectionTransaction.debtor.privatePerson.givenName &&
                                                    _transaction.collectionTransaction.debtor.privatePerson.surname"
                                            >&nbsp;</span>
                                            <span data-qa="myTransactions-searchResultList-debtorLastName">
                                                {{ _transaction.collectionTransaction.debtor.privatePerson.surname }}
                                            </span>
                                            <span 
                                                v-if="_transaction.collectionTransaction.debtorCount > 1" 
                                                data-qa="myTransactions-searchResultList-multipleDebtors" 
                                            />
                                            <span
                                                v-show="_transaction.collectionTransaction.debtor.address &&
                                                    _transaction.collectionTransaction.debtor.address.city"
                                                data-qa="myTransactions-searchResultList-postalAddress-city"
                                            >, {{ _transaction.collectionTransaction.debtor.address.city }}</span>
                                            <span
                                                v-show="_transaction.collectionTransaction.debtor.address &&
                                                    _transaction.collectionTransaction.debtor.address.country &&
                                                    _transaction.collectionTransaction.debtor.address.country !== 'DE'"
                                                data-qa="myTransactions-searchResultList-postalAddress-country"
                                            >, {{ $t(`enums.countries.${_transaction.collectionTransaction.debtor.address.country}`) }}</span>
                                        </span>
                                        <div 
                                            v-if="_transaction.collectionTransaction?.referenceNumber"
                                            class="text-small"
                                        >
                                            <span 
                                                :class="{
                                                    'text-brand-grey': _transaction.reportId !== null,
                                                    'text-brand-dark-grey': _transaction.reportId === null
                                                }"
                                            >{{ $t('shared.reference') }}:</span>&nbsp;
                                            <span 
                                                class="text-brand-darkest-blue text-bold"
                                                data-qa="myTransactions-searchResultList-referenceNumber"
                                            >{{ _transaction.collectionTransaction.referenceNumber }}</span>
                                        </div>
                                        <div 
                                            v-if="_transaction.collectionTransaction?.collectionFileNo"
                                            class="text-small"
                                        >
                                            <span class="text-brand-dark-grey">
                                                {{ $t('transaction.data.aktennummer') }}:
                                            </span>&nbsp;
                                            <span 
                                                class="text-brand-darkest-blue text-bold"
                                                data-qa="myTransactions-searchResultList-collectionFileNo"
                                            >{{ _transaction.collectionTransaction.collectionFileNo }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                class="col-12 col-md-4"
                                @click.prevent="openDetailsPage"
                            >
                                <span 
                                    class="h4 transaction-title"
                                    data-qa="myTransactions-searchResultList-collectionTransactionType"
                                >{{ $t('transaction.type.COLLECTION') }}</span>
                                <div 
                                    v-if="_transaction.collectionTransaction.lastModificationTime !== null"
                                    class="text-small text-overflow-ellipsis last-modification-time--container"
                                >
                                    <span class="text-brand-grey">
                                        {{ $t('transaction.list.lastModificationTime') }}
                                    </span>&nbsp; 
                                    <span 
                                        class="text-bold text-brand-darkest-blue last-modification-time"
                                        data-qa="myTransactions-searchResultList-lastModificationTime"
                                    >
                                        {{ formatDate(_transaction.collectionTransaction.lastModificationTime, 'L') }}
                                    </span>
                                    <template v-if="_transaction.collectionTransaction.lastTimelineEntryDate && lastChangeText !== ''">
                                        <br>
                                        <span
                                            class="text-bold text-brand-darkest-blue"
                                            data-qa="myTransactions-searchResultList-lastTimelineEntryText"
                                        >{{ lastChangeText }}</span>
                                    </template>
                                    <template v-if="_transaction.collectionTransaction.collectionProcedureStage">
                                        <br><br>
                                        <!-- <span>{{ $t('transaction.data.collection.collectionProcedureStage') }}</span>&nbsp; -->
                                        <span data-qa="myTransactions-searchResultList-collectionProcedureStage">
                                            <Label :class="`${_transaction.collectionTransaction.collectionProcedureStage.toLowerCase()}`">
                                                {{ $t(`transaction.data.collection.procedureStage.${_transaction.collectionTransaction.collectionProcedureStage}`) }}
                                            </Label>
                                        </span>
                                    </template>
                                </div>
                            </div>
                            <div 
                                class="col-12 col-md-3 data"
                                @click.prevent="openDetailsPage"
                            >
                                <div class="text-small">
                                    <span class="text-brand-grey">{{ $t('transaction.data.collection.hauptforderung') }}</span>&nbsp;
                                    <span 
                                        class="text-brand-darkest-blue text-bold"
                                        data-qa="myTransactions-searchResultList-principalReceivableAmount"
                                    >
                                        {{ currency(_transaction.collectionTransaction.principalReceivableAmount.value) }}
                                    </span>
                                </div>
                                <div 
                                    v-if="_transaction.collectionTransaction.invoiceDate !== null"
                                    class="text-small"
                                >
                                    <span class="text-brand-grey">{{ $t('transaction.list.invoiceDate') }}</span>&nbsp;
                                    <span 
                                        class="text-brand-darkest-blue text-bold"
                                        data-qa="myTransactions-searchResultList-invoiceDate"
                                    >
                                        {{ formatDate(_transaction.collectionTransaction.invoiceDate, 'L') }}
                                    </span>
                                </div>
                                <div 
                                    v-if="_transaction.collectionTransaction.creationTime !== null"
                                    class="text-small"
                                >
                                    <span class="text-brand-grey">{{ $t('transaction.list.creationTime') }}</span>&nbsp;
                                    <span 
                                        class="text-brand-darkest-blue text-bold"
                                        data-qa="myTransactions-searchResultList-creationTime"
                                    >
                                        {{ formatDate(_transaction.collectionTransaction.creationTime, 'L') }}
                                    </span>
                                </div>
                                <div class="text-small">
                                    <span class="text-brand-grey">{{ $t('transaction.data.collection.getilgterBetrag') }}</span>&nbsp;
                                    <span 
                                        class="text-brand-darkest-blue text-bold"
                                        data-qa="myTransactions-searchResultList-redeemedPrincipalReceivableAmount"
                                    >
                                        {{ currency(_transaction.collectionTransaction.redeemedPrincipalReceivableAmount.value) }}
                                    </span>
                                </div>
                                <div 
                                    v-show="_transaction.collectionTransaction.invoiceDate === null"
                                    class="text-small"
                                >
                                    <span class="text-brand-grey">{{ $t('transaction.collection.fileInfo.fileNo') }}</span>&nbsp;
                                    <span 
                                        class="text-brand-darkest-blue text-bold"
                                        data-qa="myTransactions-searchResultList-collectionFileNo"
                                    >
                                        {{ _transaction.collectionTransaction.collectionFileNo }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            v-if="notReadyModal"
            :id="notReadyModal.id"
            :title="notReadyModal.title"
            :confirm-text="notReadyModal.confirmText"
            :cancel-text="notReadyModal.cancelText"
            :icon="notReadyModal.icon"
            :data-qa="notReadyModal.dataQa"
            @on-confirm="notReadyModal = undefined"
            @on-close="notReadyModal = undefined"
        >
            <p>{{ notReadyModal.content }}</p>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import Modal from '@/components/Modal.vue'
import Label from '@/components/Label.vue'

import { 
    isProductInProgress,
    toggleFavourite
} from '@/helper/services/transaction'

import { currency, formatDate } from '@/filters'

import type { CbraTransaction } from '@/types/cbra/transactions'
import type { ModalProps } from '@/types/components/modal'
import { idsLinks } from '@/data/typo3Links'

const { t, locale } = useI18n()

const props = defineProps<{
    transaction: CbraTransaction | undefined
}>()

const _transaction = ref<CbraTransaction | undefined>(props.transaction)

const notReadyModal = ref<ModalProps>()

const lastChangeText = computed(() => {
    if (!_transaction.value || !_transaction.value.collectionTransaction) return ''
    if (_transaction.value.collectionTransaction.lastTimelineEntryDate) {
        return t(`transaction.collection.timeline.type.${_transaction.value.collectionTransaction.lastTimelineEntryType}`)
    }
    return ''
})

function openDetailsPage() {
    if (!_transaction.value) return
    if (isProductInProgress(_transaction.value)) {
        notReadyModal.value = {
            id: 'test',
            title: t('error.transaction.notReady'),
            content: t('error.transaction.notReady.description'),
            icon: 'warning',
            confirmText: t('shared.modals.ok')
        }
        return
    }
    location.assign(`${idsLinks[locale.value]}?no_cache=1&transactionId=${_transaction.value.crefoTransactionId}`)
}

function _toggleFavourite() {
    if (!_transaction.value) return
    _transaction.value.favourite = !_transaction.value.favourite
    return toggleFavourite(_transaction.value)
}

</script>

<style scoped lang="less">
.results-list--item {
    .last-modification-time--container {
        display: block;
    }

    .crefo-label {
        margin-bottom: 0 !important;

        &.pre_litigation {
            background: var(--color-c-s2)
        }
        &.litigation {
            background: var(--color-c-t1)
        }
        &.post_litigation {
            background: var(--color-error)
        }
        &.dunning_service,
        &.monitoring {
            background: var(--color-warning)
        }
        &.closed {
            background: var(--color-success)
        }
    }

    .data {
        .text-brand-grey {
            min-width: 120px;
            display: inline-block;
        }
    }
}
</style>
