<template>
    <div
        data-qa="solvencyCheck-searchResult"
        class="searchresult__item"
        :class="{'searchresult__item--inactive' : !isActive}"
    >
        <div
            class="box-spacing bg-white box--searchresult"
            @click="followUrl()"
        >
            <div class="row">
                <div class="col-12 col-md-6">
                    <strong class="">
                        <span
                            data-qa="solvencyCheck-searchResult-businessName"
                            class="searchresult__item-title"
                        >
                            {{ business.businessName }}
                        </span>

                        <div
                            v-if="featurePortfolioActive && count > 0"
                            style="display: inline-block;"
                        >
                            <div class="searchresult__item-transactionCount">
                                <Tooltip
                                 
                                    placement="top"
                                    data-qa="solvencyCheck-searchResult-businessTransactionCount"
                                >   
                                    <span>{{ count }}</span>
                                    <template #content>
                                        <span>{{ $t('search.businessTransactionCount') }} </span>
                                    </template>        
                                </Tooltip>
                            </div>
                        </div>

                    </strong>
                </div>
                <div class="col-11 col-md-4 offset-md-1">
                    <span
                        data-qa="solvencyCheck-searchResult-address"
                    >
                        {{ formattedPostalAddress }}
                    </span>
                    <span
                        class="d-none"
                        data-qa="solvencyCheck-searchResult-street"
                    >
                        {{ business.postalAddress?.street }}
                    </span>
                    <span
                        class="d-none"
                        data-qa="solvencyCheck-searchResult-houseNumber"
                    >
                        {{ business.postalAddress?.houseNumber }}
                    </span>
                    
                    <span
                        class="d-none"
                        data-qa="solvencyCheck-searchResult-houseNumberSuffix"
                    >
                        {{ business.postalAddress?.houseNumberSuffix }}
                    </span>
                    <span
                        class="d-none"
                        data-qa="solvencyCheck-searchResult-zipCode"
                    >
                        {{ business.postalAddress?.zipCode }}
                    </span>
                    <span
                        class="d-none"
                        daa-qa="solvencyCheck-searchResult-city"
                    >
                        {{ business.postalAddress?.city }}
                    </span>
                </div>
                <div class="col-1 col-md-1 text-md-right text-lg-right">
                    <span class="pull-right">
                        <WatchlistIcon
                            v-if="business.businessId && showWatchListIcon"
                            class="text-align--right watchlist-icon"
                            :business-id="business.businessId"
                            :is-dal="isDal"
                            data-qa-add="searchresult__item__addToWatchlist__desktop"
                            data-qa-remove="searchresult__item__removeFromWatchlist__desktop"
                            data-qa-disabled="searchresult__item__deactivateFromWatchlist__desktop"
                        />
                    </span>
                </div>
            </div>
            <div
                v-if="business.aggregatedBusinessStatus && !isActive"
                class="row"
            >
                <div class="col-12">
                    <span class="tooltip-icon searchresult__item-important-msg">
                        <span class="tooltip-icon__container tooltip-icon__container--important tooltip-icon__container--small">!</span>
                                                
                        {{ $t('search.inactiveBusiness') }}
                    </span>
                </div>
            </div>
            <div
                v-if="!business.businessNameUpToDate && isActive"
                class="row"
            >
                <div class="col-12">
                    <span class="tooltip-icon searchresult__item-important-msg">
                        <span class="tooltip-icon__container tooltip-icon__container--important tooltip-icon__container--small">!</span>
                        {{ $t('search.businessNameUpToDate') }}<span v-if="newBusinessName">, {{ $t('search.current') }}:
                            <span data-qa="new-business-name">{{ newBusinessName }}</span></span>
                    </span>
                </div>
            </div>
            <div
                v-if="!business.postalAddressUpToDate && isActive"
                class="row"
            >
                <div class="col-12">
                    <span class="tooltip-icon searchresult__item-important-msg">
                        <span class="tooltip-icon__container tooltip-icon__container--important tooltip-icon__container--small">!</span>
                        {{ $t('search.postalAddressUpToDate') }}
                        <template v-if="newPostalAddress">
                            , {{ $t('search.current') }}:
                            <span data-qa="new-postal-address">{{ newPostalAddress }}</span>
                        </template>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { SimpleSearchResult } from '@/types/cbra/simplesearch'
import Tooltip from '../Tooltip.vue'
import { accessToFeature } from '@/helper/services/features'
import { computed, onMounted, ref } from 'vue'
import { getCbraProductAvailability } from '@/services'
import { CbraPostalAddress } from '@/types/cbra/shared'
import WatchlistIcon from '../Watchlist/WatchlistIcon.vue'
import { CbraTransactionCountResponse } from '@/types/cbra/transactions'
import Storage from '@/helper/storage'
import CONSTANTS from '@/data/constants'

const props = defineProps<{
    business: SimpleSearchResult
    isDal: boolean
    transactionsCount?: CbraTransactionCountResponse
    country: string
}>()

const featurePortfolioActive = computed(() => {
    return accessToFeature('portfolioViewActive')
})

const newPostalAddress = ref<CbraPostalAddress | string>()
const formattedPostalAddress = formatAddressData(props.business.postalAddress)
const newBusinessName = ref('')

const isActive = computed( ()=> props.business.aggregatedBusinessStatus ? props.business.aggregatedBusinessStatus.id !== '002' : false)

const showWatchListIcon = computed(() => props.isDal)

onMounted(() => {
    addCurrentInformationForBusinessItem()
})

const count = computed(()=> {
    const item = props.transactionsCount?.transactionCounts?.find((item) => item.businessId === props.business.businessId)
    return item?.businessId ? item.count : 0
})

async function addCurrentInformationForBusinessItem() {
    if ((!isActive.value || props.business.postalAddressUpToDate && props.business.businessNameUpToDate)) {
        return 
    }

    const response= await getCbraProductAvailability({ businessId: props.business.businessId })
    const businessData = response.data

    if (typeof businessData !== 'object' || businessData === null) {
        return
    }

    if (typeof businessData.postalAddress === 'object' && businessData.postalAddress !== null) {
        newPostalAddress.value = formatAddressData(businessData.postalAddress)
    } else {
        newPostalAddress.value = businessData.postalAddress
    }

    newBusinessName.value = businessData.businessName
}

/**
 * Adds a formatted address string to the address data which contains a comma
 * after street or house number
 *
 */
function formatAddressData(addressData?: CbraPostalAddress | null) {
    let formattedAddress = ''
    if (!addressData) return formattedAddress

    if (typeof addressData.street === 'string') {
        formattedAddress += addressData.street

        if (typeof addressData.houseNumber === 'number') {
            formattedAddress += formattedAddress.length ? ' ' : ''
            formattedAddress += String(addressData.houseNumber)
        }
        if (typeof addressData.houseNumberSuffix === 'string' && addressData.houseNumberSuffix.length) {
            if (formattedAddress.length && typeof addressData.houseNumber !== 'number') {
                formattedAddress += ' '
            }
            formattedAddress += addressData.houseNumberSuffix
        }

        if (addressData.zipCode || addressData.postOfficeBoxNumber || addressData.city || addressData.district) {
            formattedAddress += ', '
        }
    }

    if (typeof addressData.zipCode === 'string' && addressData.zipCode.length) {
        formattedAddress += addressData.zipCode + ' '
    } else if (typeof addressData.postOfficeBoxNumber === 'string' && addressData.postOfficeBoxNumber.length) {
        formattedAddress += addressData.postOfficeBoxNumber + ' '
    }
    if (typeof addressData.city === 'string' && addressData.city.length) {
        formattedAddress += addressData.city
    } else if (typeof addressData.district === 'string' && addressData.district.length) {
        formattedAddress += addressData.district
    }

    return formattedAddress
}

function followUrl() {
    // save business item in session store
    if (!isEmptyObject(props.business)) {
        Storage.set(CONSTANTS.EVENTS.SAVED_BUSINESS_ITEM, props.business)
    }
    const country = encodeURIComponent(props.country)
    window.location.href = `/bonitaetsauskunft/bonitaet-einer-firma-pruefen/detailseite-unternehmen/?country=${country}&businessId=${props.business.businessId}`
}

function isEmptyObject(obj: any) {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false
        }
    }
    return true
}

</script>

<style lang="less" scoped>

.searchresult__item {
    cursor: pointer;
    margin-top: 10px;
    &-transactionCount {
      background-color: var(--color-c-t1);
      color: #fff;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      margin-left: 10px;
    }
    &:hover {
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
    }
    &-title {
        font-size: 18px;
        color: var(--color-c-s3)
    }
    &--inactive {
        opacity: .7;
        color: #7d7e7f;
    }
}

.tooltip-icon__container {
    cursor: pointer;
    border-radius: 15px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    display: inline;
    font-size: 12px;
    padding: 0 7px;
    background-color: #008dd9;
    color: #fff;
    border-color: #008dd9;
}
</style>