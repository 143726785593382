<template>
    <div
        v-if="showMessage"
        class="container mb"
    >
        <div class="row">
            <div class="col-12">
                <Alert
                    :title="$t('infoboxmessages.ikaros.enabled.headline')"
                    data-qa="infoBoxMessage-ikaros-IkarosMessage"
                    data-qa-close="infoboxMessage-ikaros-box-icon-close"
                    @on-close="close"
                >
                    <div data-qa="infoBoxMessage-ikaros-IkarosMessage-text">
                        <a href="/portal/inkasso">
                            {{ $t('infoboxmessages.ikaros.startpage.link.text') }}
                        </a>
                    </div>
                </Alert>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import Alert from '@/components/Alert.vue'
import { InkassoType } from '@/enums/inkasso'
import Storage from '@/helper/storage'
import { isIkarosAvailableForMember } from '@/services'

const COOKIE_NAME_START = 'INFOBOX_IKAROS_START_HIDE'
const persistanceStorageAfterLogout = true

const showMessage = ref(false)

onMounted(async() => {
    await handleFetch()
})

async function handleFetch() {
    try {
        const { data } = await isIkarosAvailableForMember()
        if (data.inkassoType === InkassoType.IKAROS) {
            showInfoBox()
        }
    } catch (error) {
        showInfoBox()
        console.error(error)
    }
}

function showInfoBox() {
    if (Storage.get(COOKIE_NAME_START, persistanceStorageAfterLogout) === true) {
        showMessage.value = false
    } else {
        showMessage.value = true
    }
}

function close() {
    Storage.set(COOKIE_NAME_START, true, persistanceStorageAfterLogout)
    showMessage.value = false
}

</script>

