
export class FamilyTreeListener {
    constructor() {

    }

    receiveMessage(event: MessageEvent)
    {
        // Origin übers Typo3 einpflegen?
        /*if (event.origin !== "https://ask-dev.vfa.creditreform.de:8443") {
        }*/
        if (event.data !== 'unchanged') {
            const data = JSON.parse(event.data)
            if (typeof data === 'object') {
                let url = ''
                let params = ''
                const paramsArray = []
                if (typeof data.data !== 'undefined' && data.data !== null) {
                    data.data.forEach((d, i) => {
                        params += (i === 0) ? '?' : '&'
                        switch (d.key) {
                        case 'postcode':
                            params += 'zipCode' + '=' + encodeURIComponent(d.value)
                            paramsArray.zipCode = d.value
                            break
                        case 'companyname':
                            params += 'businessName' + '=' + encodeURIComponent(d.value)
                            paramsArray.businessName = d.value
                            break
                        default:
                            params += d.key + '=' + encodeURIComponent(d.value)
                            paramsArray[d.key] = d.value
                        }
                    })
                }
                switch (data.commandid) {
                case 'reportCommand':
                    url = document.getElementById('businessDetailPageUrl').value
                    url += '?businessId=' + encodeURIComponent(paramsArray.identificationnumber) +
                          '&country=' + encodeURIComponent(paramsArray.countryCode)
                    window.location.href = url
                    break
                case 'orderCommand':
                    url = document.getElementById('reportOrderPageUrl').value
                    url += params
                    window.location.href = url
                    break
                case 'consumerCommand':
                    url = document.getElementById('consumerReportOrderPageUrl').value
                    url += params
                    window.location.href = url
                    break
                case 'ping':
                    // userService.updateToken(true)
                    break
                }
            }
        }
    }

    injectEventListener() {
        //window.addEventListener("message", this.receiveMessage, false);

        // IE11 listener
        const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
        const eventer = window[eventMethod]
        const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
        eventer(messageEvent, this.receiveMessage, false)
    }
}

export default new FamilyTreeListener()
