<template>
    <Splide
        :has-track="false"
        aria-label="..."
        :options="options"
        data-qa="carousel-slider"
    >
        <div
            class="splide__arrows"
            data-qa="slider-arrows"
        >
            <button
                class="splide__arrow splide__arrow--prev"
                data-qa="slider-arrow-prev"
            >
                <i class="crefo-ui-icon icon-chevron-prev" />
            </button>
            <button
                class="splide__arrow splide__arrow--next"
                data-qa="slider-arrow-next"
            >
                <i class="crefo-ui-icon icon-chevron-next" />
            </button>
        </div>

        <SplideTrack>
            <slot />
        </SplideTrack>
    </Splide>
</template>

<script setup lang="ts">
import '@splidejs/splide/css/core'
import { Splide, SplideTrack, Options } from '@splidejs/vue-splide'

export interface Props {
    perPage?: number
    gap?: string
    pagination?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    perPage: 2,
    gap: '6rem',
    pagination: true
})

const options: Options = {
    perPage: props.perPage,
    gap: props.gap,
    pagination: props.pagination,
    perMove: 1,
    breakpoints: { 
        768: {
            perPage: 1,
        },
        992: {
            perPage: 2,
        }
    },
}
</script>

<style scoped lang="less">
.splide:not(.is-overflow) {

    .splide__arrows {
        display: none;
    }

    .splide__pagination {
        display: none;
    }
}

.splide {
    @media screen and (max-width: 768px) {
        max-width: 85%;
        margin: 0 auto;
    }
}

.splide__pagination {

    margin-top: 26px;

    li {
        padding: 0 3px;
    }

    .splide__pagination__page {
        background: var(--color-c-p1);
        min-width: 30px;
        min-height: 4px;
        width: 30px;
        height: 4px;

        &.is-active {
            background: var(--color-c-s2);
        }
    }
}

.splide__arrow {
    background: none !important;

    &.splide__arrow--prev {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateX(-75%) translateY(-50%);
    }

    &.splide__arrow--next {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateX(75%) translateY(-50%);
    }

    &[disabled] {
        display: none;
        .crefo-ui-icon {
            color: var(--color-c-lightgrey);
        }
    }

    @media screen and (max-width: 450px) {
        &.splide__arrow--prev {
            transform: translateX(calc(-50% - 35px)) translateY(-50%);
        }

        &.splide__arrow--next {
            transform: translateX(calc(50% + 35px)) translateY(-50%);
        }
    }
}

.icon-chevron-prev, .icon-chevron-next {
    font-size: 40px;
    font-weight: bold;
    color: var(--color-c-grey);
}

</style>
