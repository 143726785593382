import { CbraActivity } from '@/types/cbra/activity'
import { CbraCollectionTransaction } from '@/types/cbra/transactions'

export function getDebtorName(item: CbraCollectionTransaction | CbraActivity) {
    let debtor = item.debtor

    if (Array.isArray(debtor) && debtor.length) {
        debtor = debtor[0]
    }

    if (!debtor) return ''

    if (debtor?.company?.businessName) {
        return debtor.company.businessName
    }

    if (
        debtor.privatePerson?.surname && 
        debtor.privatePerson?.givenName
    ) {
        return debtor.privatePerson.givenName + ' ' + debtor.privatePerson.surname
    }

    return ''
}