<template>
    <div class="container small-content-spacer">
        <div class="row mb-large">
            <div class="col-12 col-md-8 offset-md-2">
                <h3>{{ $t("AdvantagesTeaser.myCreditreform.title") }}</h3>
                <i18n-t
                    keypath="AdvantagesTeaser.myCreditreform.text"
                    tag="p"
                >
                    <template #myCreditreform>
                        <i>{{ $t("AdvantagesTeaser.myCreditreform.myCreditreform") }}</i>
                    </template>
                    <template #learnMore>
                        <a
                            :href="$t('AdvantagesTeaser.myCreditreform.learnMoreLink')"
                            :title="$t('AdvantagesTeaser.myCreditreform.learnMore')"
                        >
                            {{ $t("AdvantagesTeaser.myCreditreform.learnMore") }}
                        </a>
                    </template>
                </i18n-t>
            </div>
        </div>
    </div>
</template>