<template>
    <EmptyWidgetContent
        v-if="isAuthorized && results.length <= 0"
        :widget="widget"
    />
    <NotAuthorized 
        v-if="!isAuthorized"
        data-qa="widget-inkasso-note-not-authorized"
    />

    <div 
        v-if="isAuthorized && results.length > 0"
        class="inkasso-widget"
    >
        <div
            v-for="(result, key) in results"
            :key="key"
            class="inkasso-widget-row"
            :data-qa="`widget-inkasso-row-${key}`"
        >
            <div
                class="inkasso-widget-title"
                :data-qa="`widget-inkasso-row-${key}-identifer`"
            >
                <span
                    :title="result.title"
                >{{ result.title }}</span>
            </div>
            <div :data-qa="`widget-inkasso-row-${key}-percent`">
                <span>{{ result.qualityValue }}</span>
            </div>            
            <div
                class="inkasso-widget-dot-wrapper"
                :data-qa="`widget-inkasso-row-${key}-color`"
            >
                <div
                    class="inkasso-widget-dot"
                    :class="`inkasso-widget-color--${result.color}`"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType, onMounted, ref } from 'vue'
import { getCbraQualityStats } from '@/services'
import type { CbraQualityStats, EnhancedQualityStats } from '@/types/cbra/qualityStats'
import { useI18n } from 'vue-i18n'
import { ROLES, hasUserRole } from '@/helper/services/user'
import axios from 'axios'
import { Widget } from '@/types/widget'
import EmptyWidgetContent from './EmptyWidgetContent.vue'
import NotAuthorized from './NotAuthorized.vue'

defineProps({
    widget: {
        type: Object as PropType<Widget>,
        required: true
    },
})

const { t } = useI18n()

const results = ref<EnhancedQualityStats[]>([])
const isAuthorized = ref(true)

async function handleFetch() {
    if (!hasUserRole(ROLES.INKASSO)) {
        isAuthorized.value = false
        return
    }
    try {
        const response = await getCbraQualityStats()
        const splittedItems = response.data.statisticItems.splice(0, 6)
        results.value = splittedItems.map(enhanceItem)
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
            isAuthorized.value = false
        }
        console.error(error)
    }
}

function enhanceItem(item: CbraQualityStats) {
    return {
        title: t(`dashboard.widget.inkasso.criteria.${item.criteria}`),
        qualityValue: item.value + ((item.criteria === 'RECEIVABLE_AGE') ? 'M' : '%'),
        color: item.color.toLowerCase()
    }
}

onMounted(async() => {
    await handleFetch()
})

</script>

<style scoped lang="less">
.inkasso-widget {
    font-size: 12px;
    font-weight: 600;
}

.inkasso-widget-row {
    border-bottom: 1px solid #d1d1d1;
    display: grid;
    grid-template-columns: 4fr repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    padding: 10px 0;
    &:first-of-type {
        border-top: 1px solid #d1d1d1;
    }
}

.inkasso-widget-title {
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

.inkasso-widget-dot-wrapper {
    text-align: right;
}

.inkasso-widget-dot {
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #d1d1d1;
    position: relative;
    top: 1px;
    margin-right: 7px;
}

.inkasso-widget-color--yellow {
    background-color: #f8b820;
}

.inkasso-widget-color--green {
    background-color: #9ccc40;
}

.inkasso-widget-color--red {
    background-color: #e05469;
}

</style>