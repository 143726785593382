<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1
                    class="marketing"
                    data-qa="headline-reg-user"
                >
                    <span>{{ $t("dashboard.regusers.headline") }}</span>
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <p class="mb">
                    <strong>{{ $t("dashboard.regusers.memberinfo.intro") }}</strong>
                    {{ $t('dashboard.regusers.memberinfo.text') }}
                </p>
                <a
                    class="btn btn-default"
                    :href="$t('dashboard.regusers.memberinfo.url')"
                    target="_blank"
                    rel="noopener"
                    data-qa="reg-user-dashboard--memberinfo-link"
                >
                    {{ $t('dashboard.regusers.memberinfo.buttontext') }}
                </a>
            </div>
            <div class="col-6">
                <p class="mb">
                    <strong>{{ $t("dashboard.regusers.upgrade.intro") }}</strong>
                    {{ $t('dashboard.regusers.upgrade.text') }}
                </p>
                <a
                    class="btn btn-default"
                    :href="linkToMemberData"
                    target="_blank"
                    rel="noopener"
                    data-qa="reg-user-dashboard--upgrade-link"
                >
                    {{ $t('dashboard.regusers.upgrade_memberdata.buttontext') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

const linkToMemberData = import.meta.env.VITE_APP_USER_MGMT_BASEURL + '/mitgliedschaft'

</script> 