export function countryZipCodeLength(countryCode: string) {
    let value = 0
    switch (countryCode) {
    case 'DE' : 
        value = 5 
        break
    case 'AT' : 
        value = 4
        break
    case 'CH' : 
        value = 4 
        break
    case 'LU' :
        value = 4 
        break
    }
    return value
}
