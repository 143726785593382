<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'
import { scrollTo } from '@/helper/scrollto'

const id = 'portal.mc.help.esg-services'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'help' }">
                    {{ $t('help.breadcrumb') }}
                </router-link>
            </li>
            <li>{{ $t('EsgServices.breadcrumb.esg') }}</li>
        </Breadcrumb>

        <div class="box box--no-padding container container--help">
            <div class="row">
                <div class="col-md-3">
                    <div
                        id="c8666"
                        class="ce-box box box--sticky-container"
                        data-qa="ce-8666"
                    >
                        <div
                            id="toc8666"
                            class="toc fixedsticky"
                            data-ready="true"
                            data-willow-name="Sticky"
                        >
                            <ul class="toc__list">
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-8669"
                                        class="toc__"
                                        href="#c8669"
                                        @click.prevent="scrollTo('#c8669')"
                                    />
                                    <ul class="toc__subList">
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-8670"
                                                href="#c8670"
                                                @click.prevent="scrollTo('#c8670')"
                                            >
                                                {{ $t('EsgServices.nav.li1') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-8671"
                                                href="#c8671"
                                                @click.prevent="scrollTo('#c8671')"
                                            >
                                                {{ $t('EsgServices.nav.li2') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-8673"
                                                href="#c8673"
                                                @click.prevent="scrollTo('#c8673')"
                                            >
                                                {{ $t('EsgServices.nav.li3') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-8674"
                                                href="#c8674"
                                                @click.prevent="scrollTo('#c8674')"
                                            >
                                                {{ $t('EsgServices.nav.li4') }}
                                            </a>
                                        </li>
                                        <li class="toc__subListItem">
                                            <a
                                                data-qa="toc-listItem-8675"
                                                href="#c8675"
                                                @click.prevent="scrollTo('#c8675')"
                                            >
                                                {{ $t('EsgServices.nav.li5') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="toc__listItem">
                                    <a
                                        data-qa="toc-listItem-8666"
                                        class="toc__"
                                        href="#c8666"
                                        @click.prevent="scrollTo('#c8666')"
                                    />
                                    <ul class="toc__subList" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div
                        id="c8669"
                        class=" clearfix grid-box grid-box--toc box"
                        data-qa="grid-box-8669"
                    >
                        <div>
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12">
                                        <div
                                            id="c8670"
                                            class="ce-box   box"
                                            data-qa="ce-8670"
                                        >
                                            <div class="ce-textpic ce-textpic--right ce-textpic--intext ce-textpic--nowrap">
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-textpic__row">
                                                        <div class="ce-textpic__column">
                                                            <div class="ce-textpic__media">
                                                                <img
                                                                    src="@/assets/img/help/esg/esg_200x300.jpeg"
                                                                    width="200"
                                                                    height="300"
                                                                    alt=""
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <div class="ce-header">
                                                        <h3>{{ $t('EsgServices.assess.title') }}</h3>
                                                    </div>
                                                    <p>{{ $t('EsgServices.assess.p1') }}</p>
                                                    <p>{{ $t('EsgServices.assess.p2') }}</p>
                                                    <p>{{ $t('EsgServices.assess.p3') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c8671"
                                            class="ce-box   box"
                                            data-qa="ce-8671"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h3>{{ $t('EsgServices.judge.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('EsgServices.judge.p1') }}</p>
                                                    <p>{{ $t('EsgServices.judge.p2') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c8672"
                                            class="ce-box   box"
                                            data-qa="ce-8672"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <img
                                                                            src="@/assets/img/help/esg/ESG-Basis-Score_Logo.jpeg"
                                                                            width="456"
                                                                            height="240"
                                                                            alt=""
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ce-textpic__bodytext" />
                                            </div>
                                        </div>
                                        <div
                                            id="c8673"
                                            class="ce-box  box"
                                            data-qa="ce-8673"
                                        >
                                            <div class="ce-header">
                                                <h3>{{ $t('EsgServices.productOverview.title') }}</h3>
                                            </div>
                                            <div class="table-responsive ">
                                                <table class="table col-12">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {{ $t('EsgServices.productOverview.lis.li1') }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{ $t('EsgServices.productOverview.lis.li2') }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{ $t('EsgServices.productOverview.lis.li3') }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{ $t('EsgServices.productOverview.lis.li4') }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{ $t('EsgServices.productOverview.lis.li5') }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            id="c8674"
                                            class="ce-box   box"
                                            data-qa="ce-8674"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h3>{{ $t('EsgServices.baseScore.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <ol class="list-ordered">
                                                        <li>
                                                            {{ $t('EsgServices.baseScore.lis.li1') }}
                                                        </li>
                                                        <li>
                                                            {{ $t('EsgServices.baseScore.lis.li2') }}
                                                        </li>
                                                        <li>
                                                            {{ $t('EsgServices.baseScore.lis.li3') }}
                                                        </li>
                                                    </ol>
                                                    <p>
                                                        <router-link
                                                            :to="{ name: 'report' }"
                                                            title="Opens internal link in current window"
                                                            class="internal-link"
                                                        >
                                                            {{ $t('EsgServices.baseScore.link') }}
                                                        </router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="c8675"
                                            class="ce-box  box box--pearl"
                                            data-qa="ce-8675"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--above">
                                                <div class="ce-header">
                                                    <h3>{{ $t('EsgServices.transparency.title') }}</h3>
                                                </div>
                                                <div class="ce-textpic__bodytext">
                                                    <p>{{ $t('EsgServices.transparency.p1') }}</p>
                                                    <p>{{ $t('EsgServices.transparency.p2') }}</p>
                                                    <p>
                                                        <a
                                                            href="https://meine.creditreform.de/portal/myesg"
                                                            title="Opens external link in new window"
                                                            target="_blank"
                                                            rel="noopener"
                                                            class="external-link-new-window"
                                                        >
                                                            {{ $t('EsgServices.transparency.link') }}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.box {
    padding: 1.5rem;
    position: relative;
    &.box--sticky-container {
        height: 100%;
        @media (max-width: 991px) {
            height: auto!important;
        }
    }
}
.grid-box {
    display: block;
}

.fixedsticky {
    top: 0;
    z-index: 100;
    position: sticky;
}
.toc {
    background-color: #fff;
    top: 40px;
    bottom: auto;
    z-index: 1;
    padding: 14px 0;
    .toc__list, .toc__subList {
        margin: 0;
        list-style-type: none;
    }
    .toc__list {
        padding: 0;
        .toc__subList {
            padding: 0;
            font-size: .9em;
            .toc__subListItem {
                padding: 0.5em 1em;
            }
        }
    }
}

.box--pearl {
    background-color: #edece5;
}

.ce-textpic {
    display: block;
    overflow: hidden;
    &.ce-textpic--right {
        .ce-textpic__gallery {
            float: right;
            .ce-textpic__media {
                float: right;
                padding-left: 20px;
            }
        }
    }
    &.ce-textpic--right {
        &.ce-textpic--intext .ce-textpic__media {
            max-width: 100%;
        }
        &.ce-textpic--nowrap {
            .ce-textpic__media {
                padding: 0 20px 20px;
                max-width: 100%;
            }
            .ce-textpic__bodytext {
                display: table;
            }
        }
        .ce-textpic__gallery {
            max-width: 40%;
            @media (max-width: 1199px) {
                float: none;
                max-width: 100%;
            }
            .ce-textpic__media {
                padding-bottom: 20px;
                @media (max-width: 1199px) {
                    padding: 0 0 2rem;
                    float: none;
                }
            }
        }
    }
    &.ce-textpic--center {
        .ce-textpic__gallery {
            text-align: center;
            .ce-textpic__media {
                padding: 20px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}
</style>