import { CbraOrderReportCreateData } from '@/types/cbra/advancedSearch'
import { sendClick } from '../webtrekk'

export const PAGE_FUNCTIONS = {
    REPORT: 'report'
}

export const BUY_AREA = {
    SLIDER: 'slider',
    QUICKBUY: 'quickbuy'
}

type placeNewBuyReportPerBuyAreaRequest = {
    productType: string
}

type placeNewBuyReportSuccess = {
    productType: string
}

type placeNewBuyReportError = {
    productType: string
}

export namespace ReportWebTrekkService {

    type placeNewBuyReportRequest = {
        productType: string
    }
   export function placeNewBuyReportRequest(data: placeNewBuyReportRequest) {
       sendClick(
           'report_buyRequest',
           {
               6: PAGE_FUNCTIONS.REPORT,
               7: 'buy',
               8: data.productType
           },
       )
   }

    export function placeNewBuyReportPerBuyAreaRequest(data: placeNewBuyReportPerBuyAreaRequest, buyArea: string) {
        const key = buyArea as keyof typeof BUY_AREA
        sendClick(
            `${BUY_AREA[key]}_report_buyRequest`,
            {
                6: PAGE_FUNCTIONS.REPORT,
                7: 'buy',
                8: data.productType
            },
        )
    }

    export function placeNewBuyReportSuccess(data: placeNewBuyReportSuccess) {
        // const params = {
        //     product: data.productType,
        //     productId: data.productType,
        //     productQuantity: '1',
        //     productCost: 0,
        //     productStatus: 'conf',
        //     productCategory: {
        //         1: 'reports',
        //         2: 'business_reports'
        //     },
        //     orderValue: 0,
        //     currency: 'EUR',
        //     customEcommerceParameter: {
        //         102: window.location.href,
        //         13: data.productType
        //     }
        // }

        // const customPageParameters = {
        //     105: 'business_reports',
        //     106: 'order'
        // }

        sendClick(
            'report_buySuccess',
            {
                6: PAGE_FUNCTIONS.REPORT,
                7: 'buy',
                8: data.productType,
                5: 'OK'
            },
        )
        // sendPageUpdate(params, customPageParameters, 'placeNewBuyReportSuccess')
    }

    export function placeNewBuyReportError(data: placeNewBuyReportError) {
        sendClick(
            'report_buyError',
            {
                6: PAGE_FUNCTIONS.REPORT,
                7: 'buy',
                8: data.productType,
                5: 'ERROR'
            },
        )
    }

    export function placeNewOrderReportRequest(data: CbraOrderReportCreateData) {
        sendClick(
            'report_orderRequest',
            {
                6: PAGE_FUNCTIONS.REPORT,
                7: 'order',
                8: data.productType
            },
        )
    }

    export function placeNewOrderReportSuccess(data: CbraOrderReportCreateData) {
        // const params = {
        //     product: data.productType,
        //     productId: data.productType,
        //     productQuantity: '1',
        //     productCost: 0,
        //     productStatus: 'conf',
        //     productCategory: {
        //         1: 'reports',
        //         2: 'business_reports'
        //     },
        //     orderValue: 0,
        //     currency: 'EUR',
        //     customEcommerceParameter: {
        //         102: window.location.href,
        //         13: data.productType
        //     }
        // }

        // const customPageParameters = {
        //     105: 'business_reports',
        //     106: 'order'
        // }

        sendClick(
            'report_orderSuccess',
            {
                6: PAGE_FUNCTIONS.REPORT,
                7: 'order',
                8: data.productType,
                5: 'OK'
            },
        )
        // sendPageUpdate(params, customPageParameters, 'placeNewOrderReportSuccess')
    }

    export function placeNewOrderReportError(data: CbraOrderReportCreateData) {
        sendClick(
            'report_buyError',
            {
                6: PAGE_FUNCTIONS.REPORT,
                7: 'order',
                8: data.productType,
                5: 'ERROR'
            },
        )
    }
}