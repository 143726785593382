<template>
    <div
        v-if="showMessage"
        class="container"
    >
        <div class="row">
            <div class="col-12">
                <Alert
                    :title="$t('Dashboard.message.title')"
                    data-qa-title="infoboxMessage-ovnb-box-message"
                    data-qa-close="infoboxMessage-ovnb-box-icon-close"
                    @on-close="close"
                >
                    <p data-qa="infoboxMessage-ovnb-box-helpText">
                        {{ $t('Dashboard.message.text') }}
                    </p>
                    <div class="text-align-right">
                        <button
                            class="btn btn-secondary btn-small"
                            data-qa="infoBoxMessage-OnvbMessage-button"
                            @click="hideInfoBox"
                        >
                            {{ $t('Dashboard.message.buttonText') }}
                        </button>
                    </div>
                </Alert>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Storage from '@/helper/storage'
import Cookies from 'js-cookie'
import { ref, watch } from 'vue'
import { hideCbraOnvbInfoBox } from '@/services'
import Alert from '../Alert.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

const COOKIE_NAME = 'INFOBOX_ONVP_HIDE'
const STORAGE_KEY = '/cbra/userPreferences'

const showMessage = ref(false)
const userStore = useUserStore()
const { userPreferences } = storeToRefs(userStore)

watch(userPreferences, (newValue) => {
    if (newValue && newValue.userAgreementDate === null) {
        showInfoBox()
    }
}, { immediate: true })

function showInfoBox() {
    if (!checkIfHidden()) {
        showMessage.value = true
    }
}

async function hideInfoBox() {
    try {
        await hideCbraOnvbInfoBox()
        showMessage.value = false
        Storage.remove(STORAGE_KEY, false)
    } catch (error) {
        showMessage.value = false
        Storage.remove(STORAGE_KEY, false)
        console.error(error)
    }
}

function close() {
    Cookies.set(COOKIE_NAME, 'true', { path: '/', secure: true, sameSite: 'Lax' })
    showMessage.value = false
}

function checkIfHidden() {
    return Cookies.get(COOKIE_NAME) === 'true'
}

</script>