<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="contentRegister contentReportPerson ">
        <LoadingRing v-if="isRequestingPermission" />

        <div
            v-if="showFormPage"
            :class="isRequestingPermission ? 'contentReportPerson--opacity': 'contentReportPerson--form'"
        >
            <form
                :class="{'has-errors': !isValid }"
                @submit.prevent="handleSubmit(submitForm)"
            >
                <div class="container container--crefo text-align-right">
                    <span class="pull-right">* = {{ $t('shared.required') }}</span>
                </div>
                <div class="box box--pearl">
                    <div class="container container--crefo">
                        <div class="row ">
                            <div class="col-12">
                                <div class="row align-items-center ">
                                    <div
                                        :class="'col-12'"
                                    >
                                        <div class="d-flex align-items-center gap">
                                            <Select
                                                id="product"
                                                v-model="product"
                                                name="product"
                                                :options="productOptions"
                                                data-qa="report-person-form-product"
                                                :label="$t('reportperson.form.product')"
                                                error-msg-data-qa="report-person-form-product-message"
                                                required
                                                :searchable="false"
                                                class="flex-fill"
                                            />
                                            <Tooltip
                                                v-if="productsAllowedCount === 0"
                                                position="bottom"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-regular fa-circle-info" 
                                                    size="2x"
                                                    color="var(--color-c-p1)"
                                                    data-qa="report-person-form-missingPermissions-tooltip"
                                                />
                                                <template #content>
                                                    <p>{{ permissionsNote }}</p>
                                                    <a
                                                        v-if="!hasRequestedPermission"
                                                        href="#"
                                                        @click.prevent="requestPermission"
                                                    >{{ $t('reportperson.form.missingPermissions.link') }}</a>
                                                </template>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-12">
                                <Select
                                    id="salutation"
                                    v-model="salutation"
                                    name="salutation"
                                    :options="salutationOptions"
                                    data-qa="report-person-form-salutatio"
                                    :label="$t('reportperson.form.salutation')"
                                    error-msg-data-qa="report-person-form-salutation-message"
                                    required
                                    :searchable="false"
                                />
                            </div>
                            <div class="col-sm-5 visible-sm" />
                            <div class="col-12 col-sm-7 col-md-4 text-info text-info--crefo" />
                        </div>
                        <div class="row ">
                            <div class="col-12">
                                <InputText
                                    v-model="lastname"
                                    :label="$t('reportperson.form.lastname')"
                                    name="lastname"
                                    data-qa="report-person-form-lastname"
                                    error-msg-data-qa="report-person-form-lastname-message"
                                    required
                                />
                            </div>
                            <div class="col-sm-5 visible-sm" />
                            <div class="col-12 col-sm-7 col-md-4 text-info text-info--crefo" />
                        </div>
                        <div class="row ">
                            <div class="col-12">
                                <InputText
                                    v-model="firstname"
                                    :label="$t('reportperson.form.firstname')"
                                    name="firstname"
                                    data-qa="report-person-form-firstname"
                                    error-msg-data-qa="report-person-form-firstname-message"
                                    required
                                />
                            </div>
                            <div class="col-sm-5 visible-sm" />
                            <div class="col-12 col-sm-7 col-md-4 text-info text-info--crefo" />
                        </div>
                        <div class="row ">
                            <div class="col-12">
                                <InputText
                                    v-model="birthname"
                                    :label="$t('reportperson.form.birthname')"
                                    name="birthname"
                                    data-qa="report-person-form-birthname"
                                    error-msg-data-qa="report-person-form-birthname-message"
                                />
                            </div>
                            <div class="col-sm-5 visible-sm" />
                            <div class="col-12 col-sm-7 col-md-4 text-info text-info--crefo" />
                        </div>
                        <div class="row ">
                            <div class="col-12 mb-small input-group">
                                <DatePicker
                                    id="birthdate"
                                    v-model="birthdate"
                                    name="birthdate"
                                    data-qa="report-person-form-birthdate"
                                    :label="$t('reportperson.form.birthdate')"
                                    error-msg-data-qa="report-person-form-birthdate-message"
                                    :default-value="dayjs().subtract(18, 'years').toDate()"
                                    :disabled-date="(date) => date > dayjs().subtract(7, 'years').toDate() || date < dayjs().subtract(115, 'years').toDate()"
                                />
                            </div>
                            <div class="col-sm-5 visible-sm" />
                            <div class="col-12">
                                <div class="crefo-ui-alert info">
                                    {{ $t('reportperson.form.hint.pleaseAddBirthdate') }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-8">
                                <InputText
                                    v-model="street"
                                    :label="$t('reportperson.form.street')"
                                    name="street"
                                    data-qa="report-person-form-street"
                                    error-msg-data-qa="report-person-form-street-message"
                                    required
                                />     
                            </div>
                            <div class="col-12 col-sm-4">
                                <InputText
                                    v-model="houseNumber"
                                    :label="$t('reportperson.form.housenumber')"
                                    name="houseNumber"
                                    data-qa="report-person-form-houseNumber"
                                    error-msg-data-qa="report-person-form-houseNumber-message"
                                    required
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <InputText
                                    v-model="zip"
                                    :label="$t('reportperson.form.zip')"
                                    name="zip"
                                    data-qa="report-person-form-zip"
                                    error-msg-data-qa="report-person-form-zip-message"
                                    required
                                />  
                            </div>
                            <div class="col-12 col-sm-4">
                                <InputText
                                    v-model="city"
                                    :label="$t('reportperson.form.city')"
                                    name="city"
                                    data-qa="report-person-form-city"
                                    error-msg-data-qa="report-person-form-city-message"
                                    required
                                />
                            </div>
                            <div class="col-12 col-sm-4">
                                <InputText
                                    v-model="country"
                                    name="country"
                                    data-qa="report-person-form-country"
                                    hidden
                                />
                                <InputText
                                    :label="$t('reportperson.form.country')"
                                    name="countryDisplay"
                                    :model-value="$t('report.search.country.de')"
                                    data-qa="report-person-form-countryDisplay"
                                    readonly
                                    no-valid-class
                                />
                            </div>
                        </div>
                        
                        <div>
                            <div class="mt mb">
                                <a
                                    class="panel-title panel__toggleHandle"
                                    href="#"
                                    @click.prevent="toggleShowOptional"
                                >

                                    <font-awesome-icon
                                        v-if="!showOptional"
                                        icon="fa-light fa-circle-plus"
                                    />

                                    <font-awesome-icon
                                        v-if="showOptional"
                                        icon="fa-light fa-circle-minus"
                                    />
                                  
                                    <span class="ml">{{ $t('reportperson.form.secondAddress') }}</span>
                                   
                                </a>
                            </div>
                          
                            <div
                                v-if="showOptional"
                                class="box-spacing bg-white"
                            >
                                <div class="row ">
                                    <div class="col-12 col-sm-8">
                                        <InputText
                                            v-model="secondStreet"
                                            :label="$t('reportperson.form.street')"
                                            name="secondStreet"
                                            data-qa="report-person-form-second-street"
                                            error-msg-data-qa="report-person-form-second-street-message"
                                            required
                                        />     
                                    </div>
                                    <div class="col-12 col-sm-4"> 
                                        <InputText
                                            v-model="secondHouseNumber"
                                            :label="$t('reportperson.form.housenumber')"
                                            name="secondHouseNumber"
                                            data-qa="report-person-form-second-houseNumber"
                                            error-msg-data-qa="report-person-form-second-houseNumber-message"
                                            required
                                        />
                                    </div>
                                    <div class="col-sm-5 visible-sm" />
                                    <div class="col-12 col-sm-7 col-md-4 text-info text-info--crefo" />
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-4">
                                        <InputText
                                            id="secondZip"
                                            v-model="secondZip"
                                            :label="$t('reportperson.form.zip')"
                                            name="secondZip"
                                            data-qa="report-person-form-second-zip"
                                            error-msg-data-qa="report-person-form-second-zip-message"
                                            required
                                        />    
                                    </div>
                                    <div class="col-12 col-sm-4"> 
                                        <InputText
                                            v-model="secondCity"
                                            :label="$t('reportperson.form.city')"
                                            name="secondCity"
                                            data-qa="report-person-form-second-city"
                                            error-msg-data-qa="report-person-form-second-city-message"
                                            required
                                        />
                                    </div>
                                    <div class="col-12 col-sm-4"> 
                                        <InputText
                                            v-model="secondCountry"
                                            name="secondCountry"
                                            data-qa="report-person-form-second-country"
                                            hidden
                                        />
                                        <InputText
                                            :label="$t('reportperson.form.country')"
                                            name="secondCountryDisplay"
                                            :model-value="$t('report.search.country.de')"
                                            data-qa="report-person-form-second-countryDisplay"
                                            readonly
                                            no-valid-class
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box box--white">
                    <div class="container container--crefo">
                        <div class="row ">
                            <div class="col-12">
                                <InputText
                                    v-model="reference"
                                    :label="$t('reportperson.form.reference')"
                                    name="reference"
                                    data-qa="report-person-form-reference"
                                    error-msg-data-qa="report-person-form-reference-message"
                                />
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-12">
                                <Select
                                    id="legitimateInterest"
                                    v-model="legitimateInterest"
                                    :options="legitimateInterestOptions"
                                    data-qa="report-person-form-legitimateInterest"
                                    :label="$t('reportperson.form.legitimateInterest')"
                                    error-msg-data-qa="report-person-form-legitimateInterest-message"
                                    required
                                    :searchable="false"
                                />
                            </div>
                            <div class="col-sm-5 visible-sm" />
                            <div class="col-12">
                                <div
                                    class="crefo-ui-alert info"
                                    v-html="$t('reportperson.form.hint.pleaseAddInterest')"
                                />
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-12 legitimateInterests-container-holder">
                                <LegitimateNote
                                    v-if="legitimateInterest"
                                    :legitimate-interest="legitimateInterest"
                                    :expandable="false"
                                    translation-prefix="reportperson.legitimateInterest."
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box box--pearl">
                    <div class="container container--crefo">
                        <div class="row">
                            <div class="col-12 text-align-center">
                                <button
                                    id="reportPersonOrderButton"
                                    class="btn btn-legacy-orange btn--disabled"
                                    :disabled="submitButtonDisabled"
                                    data-qa="report-person-form-submitButton"
                                    @click="submitForm"
                                >
                                    {{ $t('reportperson.form.buyReportNow') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div
            v-if="showResultPage"
            id="reportPersonResultPage"
            class="contentReportPerson--result"
        >
            <div class="box box--pearl">
                <div
                    v-if="showResultSuccess"
                    class="container container--crefo container--padding-bottom"
                >
                    <div class="row row--narrow vspace--2">
                        <div class="ce-header">
                            <h3 data-qa="report-person-result-subheadline">
                                {{ $t('reportperson.result.subheadline') }}
                            </h3>
                        </div>
                    </div>
                    <div class="row row--narrow vspace--2">
                        <div class="btn--holder">
                            <form
                                method="post"
                                action="/cbra/consumer/rendering/pdfs/create"
                            >
                                <input
                                    id="reportPersonProductType"
                                    type="hidden"
                                    name="productType"
                                    value=""
                                    data-qa="report-person-result-productType"
                                >
                                <input
                                    id="reportPersonOrderNumber"
                                    type="hidden"
                                    name="orderNumber"
                                    value=""
                                    data-qa="report-person-result-orderNumber"
                                >
                                <textarea
                                    id="reportPersonXmlProduct"
                                    name="xmlProduct"
                                    data-qa="report-person-result-xmlProduct"
                                    style="display: none;"
                                />
                                <button
                                    id="reportPersonDownloadButton"
                                    type="submit"
                                    data-bind="webTrekkEvent: 'consumerReport_downloadPdf'"
                                    class="btn btn--standOut btn--disabled"
                                    :disabled="true"
                                    data-qa="report-person-result-downloadButton"
                                >
                                    {{ $t('reportperson.result.download') }}
                                </button>
                            </form>
                        </div>
                    </div>
                    <div
                        class="row row--narrow vspace--2"
                        data-qa="report-person-result-note"
                    >
                        <strong>{{ $t('reportperson.result.note1') }}</strong>
                        {{ $t('reportperson.result.note2') }}
                    </div>
                </div>
                <div
                    v-if="showResultError"
                    class="container container--crefo container--padding-bottom"
                >
                    <div class="row row--narrow vspace--2">
                        <div class="ce-header ce-header--error">
                            <h3 data-qa="report-person-result-errorheadline">
                                {{ errorHeadline }}
                            </h3>
                        </div>
                    </div>
                    <div
                        class="row row--narrow vspace--2"
                        data-qa="report-person-result-errorNote"
                    >
                        <strong v-html="errorNote" />
                    </div>
                </div>
                <div class="container container--crefo container--padding-bottom">
                    <div class="row row--narrow vspace--2">
                        <a
                            href="/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen/"
                            class="link pull-left"
                            data-qa="report-person-result-backLink"
                        >{{ $t('reportperson.newRequest') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal
        v-if="modal"
        :id="modal.id"
        :title="modal.title"
        :cancel-text="modal.cancelText"
        :confirm-text="modal.confirmText"
        :error-context="modal.errorContext"
        :icon="modal.icon"
        @on-close="modal.onClose"
        @on-confirm="modal.onConfirm"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="modal.content" />
    </Modal>
</template>

<script lang="ts" setup>
import DatePicker from '@/components/Input/DatePicker.vue'
import InputText from '@/components/Input/InputText.vue'
import Select from '@/components/Input/Select.vue'
import LegitimateNote from '@/components/LegitimateNote.vue'
import LoadingRing from '@/components/LoadingRing.vue'
import Modal from '@/components/Modal.vue'
import Tooltip from '@/components/Tooltip.vue'
import CONSTANTS from '@/data/constants'
import { reportsDetailConsumerLinks } from '@/data/typo3Links'
import { useScrollToFormError } from '@/helper/forms'
import { scrollTo } from '@/helper/scrollto'
import { showError } from '@/helper/services/error'
import { differentContextActive, removeMemberNavigationSession } from '@/helper/services/memberNavigation'
import { ReportPersonService } from '@/helper/services/reportPerson'
import { sendClick } from '@/helper/webtrekk'
import { ConsumerReportWebTrekkService } from '@/helper/webtrekk/consumer'
import { DATE, REGEXES, REQUIRED_STRING_VALIDATION } from '@/helper/zodSchema'
import { getCbraProductpermissions } from '@/services'
import { useUserStore } from '@/stores/user'
import { CbraReportConsumerPayload } from '@/types/cbra/reports'
import { ModalProps } from '@/types/components/modal'
import { toTypedSchema } from '@vee-validate/zod'
import { AxiosError, isAxiosError } from 'axios'
import dayjs from 'dayjs'
import { useForm, useIsFormValid } from 'vee-validate'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { literal, object, string, } from 'zod'

const userStore = useUserStore()
const { t, locale } = useI18n()
const route = useRoute()

onMounted(async() => {
    await initProducts()
})

// Workaround to add event handler to link that comes from locale file
onMounted(() => {
    const textLinkCallbackArray = () => {
        removeMemberNavigationSession()
        window.location.reload()
    }
    const link = document.querySelector('#resetMemberNavigation')
    if (link) {
        link.addEventListener('click', textLinkCallbackArray)
    }
})

const reportPersonOrderButtonDisabled = ref(true)
const modal = ref<ModalProps>()
// Init "page" to show
const showPdfError = route.query.pdfError || false
const showFormPage = ref(false)
const showResultPage = ref(false)
const showResultSuccess = ref(false)
const showResultError = ref(false)

const hasRequestedPermission = ref(false)
const isRequestingPermission = ref(false)

// Init error texts
const errorHeadline = ref('')
const errorNote = ref('')

// Init fields and validators
const products = ref({})
const productsAllowedCount = ref(-1)

// Init when form sent
const orderFormSent = ref(false)

// START:  Defines form fields and their validation
const product = ref('')
const productOptions = ref([
    { label: t('reportperson.form.product.choose'), code: '' },
])

const salutation = ref(route.query.salutation ? String(route.query.salutation) : 'MR_MS')
const salutationOptions = [
    { label: t('reportperson.form.salutation.mrsmr'), code: 'MR_MS' },
    { label: t('reportperson.form.salutation.mrs'), code: 'MS' },
    { label: t('reportperson.form.salutation.mr'), code: 'MR' }
]

const lastname = ref(route.query.surname ? String(route.query.surname) : undefined)
const firstname = ref(route.query.firstname ? String(route.query.firstname) : '')
const birthname = ref<string | null>(route.query.birthname ? String(route.query.birthname) : '')

const mld = dayjs.localeData()
const birthdateParam = route.query.birthdate ? String(route.query.birthdate) : undefined
const birthdateParamDate = birthdateParam ? dayjs(birthdateParam, mld.longDateFormat('L')).toDate() : undefined
const birthdate = ref(birthdateParamDate)
const street = ref(route.query.street ? String(route.query.street) : '')
const houseNumber = ref(route.query.housenumber ? String(route.query.housenumber) : '')
const zip = ref(route.query.zipCode ? String(route.query.zipCode) : '')
const city = ref(route.query.city ? String(route.query.city) : '')
const country = ref(route.query.countryCode ? String(route.query.countryCode) : 'DE')
const secondStreet = ref()
const secondHouseNumber = ref()
const secondZip = ref()
const secondCity = ref()
const secondCountry = ref('DE')
const reference = ref<string | null>(route.query.referencenumber ? String(route.query.referencenumber) : '')
const legitimateInterest = ref<string | null>(null)
const legitimateInterestOptions = CONSTANTS.LEGITIMATE_INTEREST_OPTIONS_PRIVATE
const legitimateInterestConfirmed = ref(false)

// Check against error
if (showPdfError) {
    showResultPage.value = true
    showResultError.value = true
    showReportResultPage({ internalError: ReportPersonService.INTERNAL_ERROR_TYPES.GET_PRODUCT_PDF_FAILED, hasErrors: true } )
} else {
    handleContextPermission()
}

const isSecondAddressRequired = computed(() => {
    return isSecondAddressEmpty() === false
})

const dateBefore115Years = dayjs().subtract(115, 'years')
const formValidationSchema = computed(() => toTypedSchema(
    object({
        product: REQUIRED_STRING_VALIDATION(t),
        firstname: REQUIRED_STRING_VALIDATION(t).min(2, t('errors.minLength', { min: 2 })).max(30, { message: t('errors.maxLength', { max: 30 }) }),
        birthname: string().min(2, { message: t('errors.minLength', { min: 2 }) }).max(30, { message: t('errors.maxLength', { max: 30 }) }).optional().or(literal('')),
        birthdate: DATE(t).min(dateBefore115Years.toDate(), t('errors.minDate', { min: dateBefore115Years.format(mld.longDateFormat('L')) })).max(dayjs().toDate(), t('errors.maxDate', { max: dayjs().format(mld.longDateFormat('L')) })).optional().or(literal('')).nullable(),
        street: REQUIRED_STRING_VALIDATION(t).min(2, { message: t('errors.minLength', { min: 2 }) }).max(46, { message: t('errors.maxLength', { max: 46 }) }),
        salutation: REQUIRED_STRING_VALIDATION(t),
        lastname: REQUIRED_STRING_VALIDATION(t).min(2, { message: t('errors.minLength', { min: 2 }) }).max(30, { message: t('errors.maxLength', { max: 30 }) }),
        houseNumber: REQUIRED_STRING_VALIDATION(t).min(1, { message: t('errors.minLength', { min: 1 }) }).max(10, { message: t('errors.maxLength', { max: 10 }) }),
        zip: REQUIRED_STRING_VALIDATION(t).regex(REGEXES.numOnly, { message: t('reportperson.form.zip.digit') }).min(5, t('reportperson.form.zip.minLength')).max(5, t('reportperson.form.zip.maxLength')),
        city: REQUIRED_STRING_VALIDATION(t).min(2, t('errors.minLength', { min: 2 }) ).max(40, { message: t('errors.maxLength', { max: 40 }) }),
        country: REQUIRED_STRING_VALIDATION(t),
        secondHouseNumber: isSecondAddressRequired.value ? REQUIRED_STRING_VALIDATION(t).min(1, t('errors.minLength', { min: 1 })).max(10,t('errors.maxLength', { max: 10 }) ) : string().min(1, t('errors.minLength', { min: 1 }) ).max(10, t('errors.maxLength', { max: 10 }) ).optional().or(literal('')),
        secondCity: isSecondAddressRequired.value ? REQUIRED_STRING_VALIDATION(t).min(2, { message: t('errors.minLength', { min: 2 }) }).max(40,{ message: t('errors.maxLength', { max: 40 }) }) : string().min(2, { message: t('errors.minLength', { min: 2 }) }).max(40, { message: t('errors.maxLength', { max: 40 }) }).optional().or(literal('')),
        secondStreet: isSecondAddressRequired.value ? REQUIRED_STRING_VALIDATION(t).min(2, t('errors.minLength', { min: 2 }) ).max(46, t('errors.maxLength', { max: 46 })) : string().min(2, { message: t('errors.minLength', { min: 2 }) }).max(46, t('errors.maxLength', { max: 46 }) ).optional().or(literal('')),
        secondCountry: isSecondAddressRequired.value ? REQUIRED_STRING_VALIDATION(t) : string().optional().or(literal('')),
        secondZip: isSecondAddressRequired.value ? REQUIRED_STRING_VALIDATION(t).regex(REGEXES.numOnly, { message: t('reportperson.form.zip.digit') }).min(5, t('reportperson.form.zip.minLength')).max(5, t('reportperson.form.zip.maxLength')) : string().regex(REGEXES.numOnly, { message: t('reportperson.form.zip.digit') }).min(5, t('reportperson.form.zip.minLength')).max(5, t('reportperson.form.zip.maxLength')).optional().or(literal('')),
        reference: string().max(20, t('errors.maxLength', { max: 20 })),
        legitimateInterest: REQUIRED_STRING_VALIDATION(t),
    }),
))

const { handleSubmit, errors, isSubmitting } = useForm({ 
    validationSchema: formValidationSchema 
})

const isValid = useIsFormValid()

watch(isSubmitting, () => {
    if (!isValid.value) {
        useScrollToFormError(errors.value)
    }
})

const submitButtonDisabled = computed(() => {
    if (isSubmitting.value) return true

    if (!isValid.value) return true

    return false
})

const showOptional = ref(false)

watch(legitimateInterest, (newInterest) => {
    legitimateInterestConfirmed.value = false
    if (newInterest === 'CONDITIONS_INQUIRY') {
        modal.value = {
            id: 'reportPerson-legitimateInterest-modal',
            title: t('reportperson.legitimateInterest.CONDITIONS_INQUIRY.modal.title'),
            content: t('reportperson.legitimateInterest.CONDITIONS_INQUIRY.modal.content'),
            icon: 'info',
            dataQa: 'report-person-form-legitimateInterest-modal',
            confirmText: t('reportperson.legitimateInterest.CONDITIONS_INQUIRY.modal.confirm'),
            cancelText: t('reportperson.legitimateInterest.CONDITIONS_INQUIRY.modal.cancel'),
            onConfirm: () => {
                legitimateInterestConfirmed.value = true
                modal.value = undefined
            },
            onClose: () => {
                legitimateInterest.value = null
                modal.value = undefined
            }
        }
    }
})
// END:  Defines form fields and their validation

function handleContextPermission() {
    if (differentContextActive()) {
        showFormPage.value = false
        redirectToPermissionMemberNavigationPage()
    } else {
        showFormPage.value = true
        ConsumerReportWebTrekkService.placeNewConsumerReportView(getWebTrekkProductName())
    }
}

function redirectToPermissionMemberNavigationPage() {
    const modalCallback = () => {
        window.location.href = '/'
    }

    modal.value = {
        id: 'reportPerson-memberNavigation-dialog',
        title: t('error.memberNavigation.reportPerson.commercial.report.no.rights.headline'),
        content: t('error.memberNavigation.reportPerson.commercial.report.no.rights.description'),
        icon: 'info',
        dataQa: 'reportPerson-memberNavigation-dialog',
        confirmText: t('shared.modals.ok'),
        onConfirm: modalCallback,
        onClose: modalCallback,
    }
}

/**
 * Checks if second address is empty.
 *
 */
function isSecondAddressEmpty() {
    if (secondStreet.value) {
        return false
    }
    if (secondHouseNumber.value) {
        return false
    }
    if (secondZip.value) {
        return false
    }
    if (secondCity.value) {
        return false
    }

    return true
}

/**
 * Get all products and their permissions.
 */
async function initProducts() {
    // featuresService.areFeaturesReadyToRead().then(async() => {
    try {
        const response = await getCbraProductpermissions()
        const productpermissions = response.data
        if (productpermissions instanceof Array) {
            let defaultProduct = ''
            let productsNew = {}
            let productsAllowedCountNew = 0
            productpermissions.forEach((productPermission, index) => {
                if (typeof productPermission === 'object' && productPermission !== null) {
                    productsNew[productPermission.productType] = productPermission.allowed
                    if (productPermission.allowed) {
                        ++productsAllowedCountNew
                        if (defaultProduct === '') {
                            defaultProduct = productPermission.productType
                        }
                        productOptions.value.push({
                            label: t('reportperson.form.product.' + productPermission.productType),
                            code: productPermission.productType
                        })
                    }
                }
            })
            products.value = productsNew
            productsAllowedCount.value = productsAllowedCountNew
            product.value = defaultProduct
        }
    } catch (error) {
        if (isAxiosError(error)) {
            modal.value = showError({ topic: ReportPersonService.TOPIC, xhr: error })
        }
    }
    // })
}

function showReportResultPage({ error, hasErrors, internalError }: {error?: AxiosError<any, any>; hasErrors: boolean; internalError: string}) {
    showFormPage.value = false
    showResultPage.value = true

    if (hasErrors) {
        showResultError.value = true
        let contactPageUrl = '/kontakt/'
        let errorWithSuccessfullProductRequest = true
        let responseJson: any = {}
        switch (internalError) {
        case ReportPersonService.INTERNAL_ERROR_TYPES.NO_MEMBER_USER_ID:
        case ReportPersonService.INTERNAL_ERROR_TYPES.WRONG_MEMBER_USER_ID:
            errorHeadline.value = t('reportperson.error.generalGetProductFailed.headline')
            errorNote.value = t('reportperson.error.generalGetProductFailed.note', {
                link: '<a href="' + contactPageUrl + '">' + t('reportperson.error.contactName') + '</a>'
            })
            ConsumerReportWebTrekkService.placeNewConsumerReportOrderFailed(getWebTrekkProductName(), errorHeadline.value)
            break
        case ReportPersonService.INTERNAL_ERROR_TYPES.GET_PRODUCT_FAILED:
        case ReportPersonService.INTERNAL_ERROR_TYPES.GET_PRODUCT_NO_RESPONSE:
            if (typeof error?.response === 'object') {
                responseJson = error.response.data
            } 

            if (typeof responseJson.askStatus === 'object') {
                if (responseJson.askStatus.httpStatus == 422 || responseJson.askStatus.httpStatus == 500) {
                    if (responseJson.askStatus.statusElements instanceof Array) {
                        for (let i = 0; i < responseJson.askStatus.statusElements.length; i++) {
                            if (responseJson.askStatus.statusElements[i].statusCode === 'ASK_20201' || responseJson.askStatus.statusElements[i].statusCode === 'ASK_20202') {
                                errorWithSuccessfullProductRequest = false
                            } else {
                                errorWithSuccessfullProductRequest = true
                                break
                            }
                        }
                    }
                } else if (responseJson.askStatus.httpStatus == 400) {
                    errorWithSuccessfullProductRequest = false
                }
            }

            if (errorWithSuccessfullProductRequest) {
                errorHeadline.value = t('reportperson.error.internalGetProductFailed.headline')
                errorNote.value = (t('reportperson.error.internalGetProductFailed.note', {
                    link: '<a href="' + contactPageUrl + '">' + t('reportperson.error.contactName') + '</a>'
                }
                ))
            } else {
                errorHeadline.value = (t('reportperson.error.generalGetProductFailed.headline'))
                errorNote.value = t('reportperson.error.generalGetProductFailed.note', {
                    link: '<a href="' + contactPageUrl + '">' + t('reportperson.error.contactName') + '</a>'
                }
                )
            }
            ConsumerReportWebTrekkService.placeNewConsumerReportOrderFailed(getWebTrekkProductName(), errorHeadline.value)
            break
        case ReportPersonService.INTERNAL_ERROR_TYPES.GET_PRODUCT_PDF_FAILED:
            errorHeadline.value = t('reportperson.error.getProductPdfFailed.headline')
            errorNote.value = t('reportperson.error.getProductPdfFailed.note', {
                link: '<a href="' + contactPageUrl + '">' + t('reportperson.error.contactName') + '</a>'
            }
            )
            if (error?.response?.data?.productType) {
                product.value = error.response.data.productType
            }
            ConsumerReportWebTrekkService.placeNewConsumerReportDownloadFailed(getWebTrekkProductName() !== '' ? getWebTrekkProductName() : 'unknown', errorHeadline.value)
            break
        default:
            errorHeadline.value = t('reportperson.error.unknown.headline')
            errorNote.value = t('reportperson.error.unknown.note', {
                link: '<a href="' + contactPageUrl + '">' + t('reportperson.error.contactName') + '</a>'
            }
            )
            ConsumerReportWebTrekkService.placeNewConsumerReportOrderFailed(getWebTrekkProductName(), errorHeadline.value)
            break
        }
    }
    nextTick(() => {
        scrollTo('#reportPersonResultPage')
    })
}

async function submitForm() {
    sendClick('consumerReport_submitOrder')
    const followUrl = reportsDetailConsumerLinks[locale.value] 
    if (orderFormSent.value) {
        return
    }
    if (!legitimateInterestConfirmed.value && legitimateInterest.value === 'CONDITIONS_INQUIRY') {
        return
    }
    orderFormSent.value = true

    if (isValid) {
        reportPersonOrderButtonDisabled.value = true
        
        try {
            const response = await getReportForRequest()
            ConsumerReportWebTrekkService.placeNewConsumerReportOrder(getWebTrekkProductName(), response.data.reportId)
            window.location.href = followUrl + '?transactionId=' + encodeURIComponent(response.data.transactionId)
        } catch (error) {
            console.error(error)
            if (isAxiosError(error)) {
                showReportResultPage({ error, hasErrors: true, internalError: ReportPersonService.INTERNAL_ERROR_TYPES.GET_PRODUCT_FAILED })
            }
        }
    } else {
        orderFormSent.value = false
    }
}

/**
 * Send report request and fetch answer.
 *
 */
function getReportForRequest() {
    let request: CbraReportConsumerPayload = {
        memberUserId: userStore.cbraUser.memberUserId,
        productType: product.value,
        salutation: salutation.value,
        surname: lastname.value,
        givenName: firstname.value,
        birthName: birthname.value,
        address: {
            zipCode: zip.value,
            city: city.value,
            street: street.value,
            housenumber: houseNumber.value
        },
        address2: null,
        referenceNumber: reference.value,
        legitimateInterest: legitimateInterest.value,
        consentClause: null,
    }

    // Convert birthdate to js timestamp
    if (birthdate.value) {
        const newBirthDate = dayjs(birthdate.value).format('DD.MM.YYYY')
        const birthDateArray = newBirthDate.split('.')
        request.dateOfBirth = new Date(
            parseInt(birthDateArray[2]),
            parseInt(birthDateArray[1])-1,
            parseInt(birthDateArray[0]),
            12, 0).getTime()
    }

    // Convert birthdate to js timestamp
    // if (newBirthDate && newBirthDate.length > 0) {
    //     /* Bug: MC-4528
    //         const mld = moment.localeData(moment.locale());
    //         request.dateOfBirth = moment(birthDate, mld._longDateFormat.L).unix() * 1000;
    //         */
    //     const birthDateArray = newBirthDate.split('.')
    //     request.dateOfBirth = new Date(
    //         parseInt(birthDateArray[2]),
    //         parseInt(birthDateArray[1])-1,
    //         parseInt(birthDateArray[0]),
    //         12, 0).getTime()
    // }
    
    // Add secondary address
    if (secondCity.value || secondStreet.value || secondHouseNumber.value || secondZip.value) {
        request.address2 = {
            zipCode: secondZip.value,
            city: secondCity.value,
            street: secondStreet.value,
            housenumber: secondHouseNumber.value
        }
    }

    // Set empty optional fields to null
    request.referenceNumber = request.referenceNumber?.length === 0 ? null : request.referenceNumber
    request.birthName = request.birthName?.length === 0 ? null : request.birthName

    return ReportPersonService.getProduct(request)
}

function toggleShowOptional() {
    showOptional.value = !showOptional.value
}

function getWebTrekkProductName() {
    let productType =product.value
    if (typeof productType !== 'string' || productType.length === 0) {
        return ''
    } else {
        return t('reportperson.form.product.' + productType + '.webTrekk')
    }
}

const permissionsNote = computed(() => {
    return hasRequestedPermission.value ? t('reportperson.form.missingPermissions.noteAfter') : t('reportperson.form.missingPermissions.note')
})

function requestPermission() {
    if (!hasRequestedPermission.value) {
        isRequestingPermission.value = true
        hasRequestedPermission.value = true

        const payload = {
            memberUserId: userStore.cbraUser.memberUserId,
            memberId: userStore.cbraUser.crMembernumber
        }

        ReportPersonService.requestPermission(payload).then((response) => {
            isRequestingPermission.value = false
            modal.value = {
                id: 'reportPerson-requestPermission-modal',
                title: t('reportperson.form.missingPermissions.requested.title'),
                content: t('reportperson.form.missingPermissions.requested.description'),
                icon: 'info',
                confirmText: t('shared.modals.ok'),
                onConfirm: () => modal.value = undefined,
                onClose: () => modal.value = undefined
            }
            ConsumerReportWebTrekkService.placeNewConsumerReportPermissionRequest(getWebTrekkProductName())
        }).catch((response) => {
            isRequestingPermission.value = false
            hasRequestedPermission.value = false
            modal.value = {
                id: 'reportPerson-requestPermission-failed-modal',
                title: t('reportperson.form.missingPermissions.requestFailed.title'),
                content: t('reportperson.form.missingPermissions.requestFailed.description'),
                icon: 'error',
                confirmText: t('shared.modals.ok'),
                onConfirm: () => modal.value = undefined,
                onClose: () => modal.value = undefined
            }
            ConsumerReportWebTrekkService.placeNewConsumerReportPermissionFailure(getWebTrekkProductName())
        })
    }
}

</script>   