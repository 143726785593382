export const xmlContent = `<?xml version="1.0" encoding="UTF-8"?>
<auskunftsexport xmlns="meine.creditreform.de/api/auskunftsexport/v1_0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="meine.creditreform.de/api/auskunftsexport/v1_0 https://meine.creditreform.de/api/auskunftsexport/xsd/auskunftsexport_1_0.xsd">
	<auskunftsextrakt>
		<auftragsdaten>
			<mitgliedskennung>403011423001</mitgliedskennung>
			<auftragsreferenz>40200882</auftragsreferenz>
			<produkttyp>
				<bezeichnung>Wirtschaftsauskunft</bezeichnung>
			</produkttyp>
			<generierungsdatum>2024-08-19Z</generierungsdatum>
		</auftragsdaten>
		<identifikation>
			<identnummer>04034030054370</identnummer>
			<firma>
				<firmierung>Frank Schmidt Dachdecker</firmierung>
			</firma>
			<adresse>
				<strasse>Hemdenweg</strasse>
				<hausnummer>14</hausnummer>
				<land>
					<iso>DE</iso>
					<bezeichnung>Deutschland</bezeichnung>
				</land>
				<postleitzahl>46399</postleitzahl>
				<ort>Bocholt</ort>
			</adresse>
		</identifikation>
		<bonitaet>
			<bonitaetsindex>
				<index>257</index>
				<bedeutung>Das Unternehmen wird mit einer Wahrscheinlichkeit von 99,40 % nicht ausfallen.</bedeutung>
				<pd>0.60</pd>
				<pd-durchschnitt>1.63</pd-durchschnitt>
			</bonitaetsindex>
		</bonitaet>
		<zahlungsweisekrediturteil>
			<zahlungsweise>
				<id>22</id>
				<bezeichnung>Innerhalb vereinbarter Ziele.</bezeichnung>
			</zahlungsweise>
			<krediturteil>
				<id>31</id>
				<bezeichnung>Kredite werden nicht abgelehnt. Eine Geschäftsverbindung gilt als zulässig.</bezeichnung>
			</krediturteil>
		</zahlungsweisekrediturteil>
		<branchen>
			<branchenart>
				<id>WZ 2008</id>
				<branche>
					<code>01.13</code>
					<bezeichnung>Anbau von Gemüse und Melonen sowie Wurzeln und Knollen</bezeichnung>
				</branche>
				<branche>
					<code>01.16.0</code>
					<bezeichnung>Anbau von Faserpflanzen</bezeichnung>
				</branche>
			</branchenart>
			<branchenart>
				<id>WZ 2003</id>
				<branche>
					<code>01.11</code>
					<bezeichnung>Ackerbau</bezeichnung>
				</branche>
				<branche>
					<code>01.11.2</code>
					<bezeichnung>Allgemeiner Ackerbau</bezeichnung>
				</branche>
			</branchenart>
		</branchen>
	</auskunftsextrakt>
	<auskunftsextrakt>
		<auftragsdaten>
			<mitgliedskennung>403011423001</mitgliedskennung>
			<auftragsreferenz>40200883</auftragsreferenz>
			<produkttyp>
				<bezeichnung>Wirtschaftsauskunft</bezeichnung>
			</produkttyp>
			<generierungsdatum>2024-08-19Z</generierungsdatum>
		</auftragsdaten>
		<identifikation>
			<identnummer>04034030062481</identnummer>
			<firma>
				<firmierung>Klaus Meier Dachdeckerei</firmierung>
			</firma>
			<adresse>
				<strasse>Seestr.</strasse>
				<hausnummer>4</hausnummer>
				<hausnummerzusatz>b</hausnummerzusatz>
				<land>
					<iso>DE</iso>
					<bezeichnung>Deutschland</bezeichnung>
				</land>
				<postleitzahl>46395</postleitzahl>
				<ort>Bocholt</ort>
			</adresse>
		</identifikation>
		<bonitaet>
			<bonitaetsindex>
				<index>226</index>
				<bedeutung>Das Unternehmen wird mit einer Wahrscheinlichkeit von 99,65 % nicht ausfallen.</bedeutung>
				<pd>0.35</pd>
				<pd-durchschnitt>1.63</pd-durchschnitt>
			</bonitaetsindex>
		</bonitaet>
		<zahlungsweisekrediturteil>
			<zahlungsweise>
				<id>22</id>
				<bezeichnung>Innerhalb vereinbarter Ziele.</bezeichnung>
			</zahlungsweise>
			<krediturteil>
				<id>21</id>
				<bezeichnung>Kredite und Geschäftsverbindung sind zulässig.</bezeichnung>
			</krediturteil>
		</zahlungsweisekrediturteil>
		<branchen>
			<branchenart>
				<id>WZ 2008</id>
				<branche>
					<code>43.91.1</code>
					<bezeichnung>Dachdeckerei und Bauspenglerei</bezeichnung>
				</branche>
			</branchenart>
			<branchenart>
				<id>WZ 2003</id>
				<branche>
					<code>45.22.1</code>
					<bezeichnung>Dachdeckerei und Bauspenglerei</bezeichnung>
				</branche>
			</branchenart>
		</branchen>
	</auskunftsextrakt>
	<auskunftsextrakt>
		<auftragsdaten>
			<mitgliedskennung>403011423001</mitgliedskennung>
			<auftragsreferenz>40200884</auftragsreferenz>
			<produkttyp>
				<bezeichnung>Wirtschaftsauskunft</bezeichnung>
			</produkttyp>
			<generierungsdatum>2024-08-19Z</generierungsdatum>
		</auftragsdaten>
		<identifikation>
			<identnummer>04034032003241</identnummer>
			<firma>
				<firmierung>Sanitär Schmitz</firmierung>
			</firma>
			<adresse>
				<strasse>Wollstraße</strasse>
				<hausnummer>4</hausnummer>
				<land>
					<iso>DE</iso>
					<bezeichnung>Deutschland</bezeichnung>
				</land>
				<postleitzahl>46399</postleitzahl>
				<ort>Bocholt</ort>
			</adresse>
		</identifikation>
		<bonitaet>
			<bonitaetsindex>
				<index>145</index>
				<bedeutung>Das Unternehmen wird mit einer Wahrscheinlichkeit von 99,89 % nicht ausfallen.</bedeutung>
				<pd>0.11</pd>
				<pd-durchschnitt>1.63</pd-durchschnitt>
			</bonitaetsindex>
		</bonitaet>
		<zahlungsweisekrediturteil>
			<zahlungsweise>
				<id>11</id>
				<bezeichnung>Skontoausnutzung (sofern vom Lieferanten angeboten)</bezeichnung>
			</zahlungsweise>
			<krediturteil>
				<id>21</id>
				<bezeichnung>Kredite und Geschäftsverbindung sind zulässig.</bezeichnung>
			</krediturteil>
		</zahlungsweisekrediturteil>
		<branchen>
			<branchenart>
				<id>WZ 2008</id>
				<branche>
					<code>94.11.0</code>
					<bezeichnung>Wirtschafts- und Arbeitgeberverbände</bezeichnung>
				</branche>
			</branchenart>
			<branchenart>
				<id>WZ 2003</id>
				<branche>
					<code>91.11.1</code>
					<bezeichnung>Wirtschaftsverbände (ohne öffentlich-rechtliche Wirtschaftsvertretungen)</bezeichnung>
				</branche>
			</branchenart>
		</branchen>
	</auskunftsextrakt>
</auskunftsexport>`
