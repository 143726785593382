<template>
    <!-- zusatzinfosZurRechtsformGenosseBoxTemplate -->
    <template v-if="(rechtsform.gesellschaftskapital || rechtsform.mitglieder || rechtsform.anteile || rechtsform.anteilshohe) ">
        <h3 class="mt">
            {{ $t('transaction.chapter.ZUSATZINFOS_ZUR_RECHTSFORM_GENOSSE') }}
        </h3>

        <div class="table-list table-list--origin">
            <template
                v-for="(property, index) of properties"
                :key="index"
            >
                <div
                    v-if="rechtsform.hasOwnProperty(property)"
                    class="table-list__row table-list__row--font"
                >
                    <ZusatzinfosZurRechtsformContentTemplate
                        :rechtsform="rechtsform"
                        :property="property"
                    />
                </div>
            </template>
        </div>
    </template>
</template>

<script lang="ts" setup>
import { CbraReportTransactionChapter } from '@/types/cbra/reports'
import { PropType } from 'vue'
import ZusatzinfosZurRechtsformContentTemplate from './ZusatzinfosZurRechtsformContentTemplate.vue'

defineProps({
    rechtsform: {
        type: Object as PropType<CbraReportTransactionChapter>,
        required: true,
    }
})

const properties = ['gesellschaftskapital','mitglieder','anteile','anteilshohe']

</script>