<template>
    <div>
        <LoadingOverlay
            v-if="loading"
            id="product-settings"
        />

        <div 
            v-if="!loading && error"
            class="crefo-ui-alert error mb"
        >
            {{ $t('globalMisc.error.errorLoadingData.message') }}
        </div>

        <div v-if="!loading && !error && userSettings !== null">
            <form>
                <div class="row">
                    <div class="col-12">
                        <Select
                            id="reportlanguage"
                            v-model="userSettings.reportLanguage"
                            :label="$t('profile.settings.productSettingsForm.fields.reportLanguage.label')"
                            :options="reportLanguageOptions"
                            :searchable="false"
                            :disabled="submitting || !reportPermitted"
                            data-qa="selfcare-userdata-settings-reportlanguage"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <Select
                            id="defaultreport"
                            v-model="userSettings.defaultReport"
                            :label="$t('profile.settings.productSettingsForm.fields.defaultReport.label')"
                            :options="defaultReportOptions"
                            :searchable="false"
                            :disabled="submitting || !productDALisRequired"
                            data-qa="selfcare-userdata-settings-defaultreport-dal"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <Select
                            id="defaultreportInternational"
                            v-model="userSettings.defaultReportInternational"
                            :label="$t('profile.settings.productSettingsForm.fields.defaultReportInternational.label')"
                            :options="defaultReportsInternationalOptions"
                            :searchable="false"
                            :disabled="submitting || !productINTisRequired"
                            data-qa="selfcare-userdata-settings-defaultproduct-int"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <Select
                            id="defaultReportLegitimateInterest"
                            v-model="userSettings.defaultReportLegitimateInterest"
                            :label="$t('profile.settings.productSettingsForm.fields.defaultReportLegitimateInterest.label')"
                            :options="CONSTANTS.LEGITIMATE_INTEREST_OPTIONS"
                            :searchable="false"
                            :disabled="submitting"
                            data-qa="selfcare-userdata-settings-default-report-legitimate-interest"
                        />
                    </div>
                </div>
                <div class="row mb">
                    <div class="col-12">
                        <Select
                            id="solvencyCheck-selectCountry"
                            v-model="userSettings.defaultSearchCountry"
                            :label="$t('profile.settings.productSettingsForm.fields.defaultSearchCountry.label')"
                            :options="countries"
                            :disabled="submitting"
                            data-qa="solvencyCheck-selectCountry"
                        />
                    </div>
                </div> 
                <div class="row mb">
                    <div class="col-12">
                        <Checkbox
                            id="test"
                            v-model="userSettings.filterPreference"
                            name="input-name"
                            :label="$t('profile.settings.productSettingsForm.fields.filterPreference.label')"
                            data-qa="selfcare-userdata-filterPreference"
                            :disabled="submitting"
                            error-message=""
                            error-message-data-qa="test-error-data-qa"
                        />
                    </div>
                </div> 
                <div class="row">
                    <div class="col-12">
                        <button 
                            class="btn btn-default"
                            :disabled="submitting"
                            data-qa="selfcare-userdata-settings-submitButton"
                            @click.prevent="submit()"
                        >
                            {{ $t('globalMisc.saveChanges') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <Modal
            v-if="modal"
            :id="modal.id"
            :title="modal.title"
            :confirm-text="modal.confirmText"
            :icon="modal.icon"
            :data-qa="modal.dataQa"
            @on-confirm="modal = undefined"
            @on-close="modal = undefined"
        >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="modal.content" />
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import LoadingOverlay from '@/components/LoadingRing.vue'
import Select from '@/components/Input/Select.vue'
import Checkbox from '@/components/Input/Checkbox.vue'
import Modal from '@/components/Modal.vue'

import countries from '@/data/countries'
import reportProducts from '@/data/reportProducts'
import CONSTANTS from '@/data/constants'

import { 
    getCbraPermissions, 
    getCbraUserPreferences, 
    updateCbraUserPreferences 
} from '@/services'

import { hasUserRole, ROLES } from '@/helper/services/user'
import { getCookie } from '@/helper/storage'
import { PAGE_FUNCTIONS, trackClick } from '@/helper/webtrekk'

import type { CbraUserPreferences } from '@/types/cbra/userPreferences'
import type { ModalProps } from '@/types/components/modal'
import type { ReportProduct } from '@/data/reportProducts'

const { t } = useI18n()

let reportPermitted = ref(false)
let loading = ref(true)
let submitting = ref(false)
let error = ref(false)
let userSettings = ref<CbraUserPreferences | null>(null)

const modal = ref<ModalProps>()

const productDALisRequired = computed(() => {
    return reportPermitted.value && dalProducts.value.length > 0
})

const productINTisRequired = computed(() => {
    return reportPermitted.value && nonDalProducts.value.length > 0
})

const nullOption = {
    label: '',
    code: null,
    i18nKey: 'globalMisc.pleaseSelect'
}

const reportLanguageOptions = [
    nullOption,
    ...CONSTANTS.LANGUAGE_OPTIONS
]

const dalProducts = ref<Array<ReportProduct>>([])

const nonDalProducts = computed(() => {
    return dalProducts.value.filter((productType) => {
        if (!productType.isAvailableOnlyForDALCountries) {
            return {
                label: productType.label,
                code: productType.code,
                i18nKey: productType.i18nKey
            }
        }
    })
})

const defaultReportOptions = computed(() => {
    return [
        nullOption,
        ...dalProducts.value
    ]
})

const defaultReportsInternationalOptions = computed(() => {
    return [
        nullOption,
        ...nonDalProducts.value
    ]
})

const submit = async() => {
    if (userSettings.value === null) {
        return
    }

    submitting.value = true

    if (userSettings.value.filterPreference) {
        let _cookie: any = getCookie('TRANSACTION_LIST_COOKIE')
        if (_cookie !== null) {
            userSettings.value.transactionFilter = decodeURIComponent(_cookie)
        }
    } else {
        userSettings.value.transactionFilter = null
    }

    const _modal = {
        id: 'modal',
        title: t('profile.settings.modal.success.title'),
        content: t('profile.settings.modal.success.content'),
        confirmText: 'Ok',
        icon: 'info',
        dataQa: 'modal-selfcare-onSavePreferences'
    }

    try {
        const result = await updateCbraUserPreferences(userSettings.value)
        if (result.status !== 200) {
            _modal.content = t('profile.settings.modal.error.content')
            _modal.icon = 'error'
        }
    } catch (e) {
        _modal.content = t('profile.settings.modal.error.content')
        _modal.icon = 'error'
    }

    trackClick(PAGE_FUNCTIONS.SELFCARE + '.preferences.save', PAGE_FUNCTIONS.SELFCARE, 'save.preferences.click')

    submitting.value = false

    modal.value = _modal
}

async function loadUserPreferences() {
    loading.value = true
    error.value = false

    let initWithPermissions = false
    if (hasUserRole(ROLES.AUSKUNFT)) {
        reportPermitted.value = true
        initWithPermissions = true
    }

    try {
        const settings = await getCbraUserPreferences()
        if (settings.status === 200) {
            let data = settings.data
            if (data?.defaultSearchCountry === null) {
                data.defaultSearchCountry = 'DE'
            }

            if (initWithPermissions) {
                const permissions = await getCbraPermissions()
                if (permissions.status === 200) {
                    const perms = permissions.data
                    dalProducts.value = reportProducts.filter((productType) => {
                        if (perms.permissions.includes(productType.code)) {
                            return {
                                label: productType.label,
                                code: productType.code,
                                i18nKey: productType.i18nKey
                            }
                        }
                    })

                    if (
                        data !== null && data.defaultReport !== null && 
                        data.defaultReport !== '' &&
                        !perms.permissions.includes(data.defaultReport)
                    ) {
                        data.defaultReport = null
                    }
                    if (
                        data !== null && data.defaultReportInternational !== null &&
                        data.defaultReportInternational !== '' &&
                        !perms.permissions.includes(data.defaultReportInternational)
                    ) {
                        data.defaultReportInternational = null
                    }
                } else {
                    dalProducts.value = reportProducts
                }
            }

            userSettings.value = data
        } else {
            error.value = true
        }
    } catch (e) {
        error.value = true
    }

    loading.value = false
}

onMounted(loadUserPreferences)
</script>
