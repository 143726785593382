<template>
    <Checkbox
        :id="widget.id.toString()"
        :key="widget.id"
        v-model="checkbox"
        :name="widget.id.toString()"
        :label="$t(widget.title)"
        data-qa="widgetCatalog-categoryCheckbox"
    />
</template>

<script lang="ts" setup>
import { Widget } from '@/types/widget'
import { PropType, ref, watch } from 'vue'
import Checkbox from '../Input/Checkbox.vue'
import { useDashboardStore } from '@/stores/dashboard'

const emit = defineEmits(['addWidget', 'removeWidget'])

const props = defineProps({
    widget: {
        type: Object as PropType<Widget>,
        required: true
    },
})

const store = useDashboardStore()

const checkbox = ref(isWidgetSaved())

function isWidgetSaved() {
    if (!store.dashboardConfig) return false
    const found = store.dashboardConfig.find((config) => config.externalId === props.widget.id)
    return found ? true : false
}

function addWidget() {
    emit('addWidget', props.widget)
}

function removeWidget() {
    emit('removeWidget', props.widget)
}

watch(checkbox, async(newValue) => {
    if (newValue) {
        addWidget()
    } else {
        removeWidget()
    }
})

</script>