<script setup lang="ts">
import { inject, ref, Ref, watch } from 'vue'

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    itemKey: {
        type: Number,
        required: true
    }
})

/* VISIBILITY */
const activeItemKey = inject('activeItemKey') as Ref<number | undefined>
const visible = ref(activeItemKey.value === props.itemKey)
const toggleVisibility = () => {
    if (!visible.value) {
        activeItemKey.value = props.itemKey
    }
    visible.value = !visible.value
}
watch(activeItemKey, () => (visible.value = activeItemKey.value === props.itemKey))
</script>

<template>
    <div
        class="accordion-item"
        :class="{ 'active': visible }"
        data-qa="accordion-item"
    >
        <div
            class="toggle"
            data-qa="toggle"
            @click="toggleVisibility"
        >
            <h3 class="accordion-title">
                {{ title }}
            </h3>
            <div class="icon-container">
                <font-awesome-icon
                    icon="fa-sharp fa-solid fa-chevron-down"
                    size="lg"
                    class="accordion-icon"
                />
            </div>
        </div>
        <div class="panel">
            <slot />
        </div>
    </div>
</template>

<style scoped lang="less">
@break-mobile: ~"screen and (max-width: 767px)";

.accordion-item {
    position: relative;
    padding: 24px 40px;
    @media @break-mobile {
        padding: 24px 16px;
    }
    margin: 0;
    border-bottom: 1px solid var(--color-c-linegrey);
    animation: flipdown 0.5s ease both;

    &:not(.active) {
        .panel {
            margin-top: 0;
            max-height: 0;
            opacity: 0;
            transform: translate(0, 50%);
        }
    }

    &.active {
        .toggle {
            .accordion-title {
                color: var(--color-c-s3);
            }
            .accordion-icon {
                transform: rotate(180deg);
            }
        }
    }

    .toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media @break-mobile {
            overflow-wrap: anywhere;
            hyphens: auto;
        }
        .icon-container {
            padding: 0 24px 0 32px;
            @media @break-mobile {
                padding-left: 16px;
                padding-right: 0;
            }
            .accordion-icon {
                color: var(--color-c-p1);
                transition: .3s all ease-in-out;
            }
        }
        &:hover {
            cursor: pointer;
            .accordion-title {
                color: var(--color-c-s3);
            }
        }
        .accordion-title {
            color: var(--color-c-p1);
            margin: 0;
            &:hover {
                color: var(--color-c-s3);
            }
        }
    }

    .panel {
        position: relative;
        overflow: hidden;
        max-height: 1600px;
        opacity: 1;
        transform: translate(0, 0);
        transition: all 0.25s ease-in-out;
        margin-top: 24px;
        z-index: 2;
    }
}

@keyframes flipdown {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }
    5% {
        opacity: 1;
    }
    80% {
        transform: rotateX(8deg);
    }
    83% {
        transform: rotateX(6deg);
    }
    92% {
        transform: rotateX(-3deg);
    }
    100% {
        transform-origin: top center;
        transform: rotateX(0deg);
    }
}
</style>