function transformRequest(data, headers) {
    delete headers.Pragma
    delete headers.Expires
    delete headers['X-B3-TraceId']
    delete headers['X-B3-SpanId']
    delete headers['X-B3-Sampled']
    delete headers['X-Act-For-Member-Id']
    headers['Content-Type'] = 'application/json'
    return JSON.stringify(data)
}

const authorization = window.keycloak?.token ? `Bearer ${window.keycloak.token}` : ''

export const requestConfig = {
    headers: {
        Authorization: authorization
    },
    dataType: 'json',
    contentType: 'application/json',
    transformRequest: [transformRequest],
}
