
<template>
    <!-- zusatzinfosZurRechtsformContentTemplate -->
    <div class="table-list__td table-list__td--border col-xs-12 col-sm-12 col-md-4">
        <strong><span>{{ $t('transaction.chapter.ZUSATZINFOS_ZUR_RECHTSFORM.' + property) }}</span></strong>
    </div>
    <div class="table-list__td table-list__td--border col-xs-12 col-sm-12 col-md-8">
        <span v-if="property === 'isin'">{{ $t('transaction.chapter.ZUSATZINFOS_ZUR_RECHTSFORM.isinName') }}&nbsp;</span>
        <span v-if="!rechtsform[rechtsformIndexW] && typeof(rechtsform[rechtsformIndex]) !== 'boolean' && rechtsform[rechtsformIndex] !== 'UNBEKANNT' ">{{ rechtsform[rechtsformIndex] }}</span>
        <span v-if="typeof(rechtsform[rechtsformIndex]) === 'boolean' ">{{ $t('transaction.chapter.ZUSATZINFOS_ZUR_RECHTSFORM.' + rechtsform[rechtsformIndex]) }}</span>
        <span v-if="rechtsform[rechtsformIndex] === 'UNBEKANNT'">{{ $t('transaction.chapter.shared.UNBEKANNT') }}</span>
        <span v-if="rechtsform[rechtsformIndexW] && rechtsform[rechtsformIndex] !== 'UNBEKANNT'">{{ currency(props.rechtsform[rechtsformIndex].toString()) }}</span>
        <span v-if="['streubesitz','belegschaftsanteile'].indexOf(property) != -1">&nbsp;%</span>
    </div>
</template>

<script lang="ts" setup>
import { CbraReportTransactionChapter } from '@/types/cbra/reports'
import { PropType } from 'vue'
import { currency } from '@/filters'

const props = defineProps({
    rechtsform: {
        type: Object as PropType<CbraReportTransactionChapter>,
        required: true,
    },
    property: {
        type: String,
        required: true,
    }
})

const rechtsformIndex = props.property as keyof typeof props.rechtsform
const rechtsformIndexW = props.property + 'W' as keyof typeof props.rechtsform

</script>