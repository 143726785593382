<template>
    <div 
        v-if="!userStore.authenticated && isKeycloakStatusChecked"
        :id="id"
        :data-page-id="id"
    >
        <Hero />

        <AdvantagesTeaser />

        <LearnMoreTeaser />

        <BigImageTeaser />

        <Register />

        <Quotes />

        <Contact />
    </div>
</template>

<script lang="ts" setup>
import { onMounted, onUpdated, watch, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'

import Hero from '@/components/Homepage/Hero.vue'
import AdvantagesTeaser from '@/components/Homepage/AdvantagesTeaser.vue'
import BigImageTeaser from '@/components/Homepage/BigImageTeaser.vue'
import LearnMoreTeaser from '@/components/Homepage/LearnMoreTeaser.vue'
import Quotes from '@/components/Homepage/Quotes.vue'

import Contact from '@/components/Teaser/Contact.vue'
import Register from '@/components/Teaser/Register.vue'

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const isKeycloakStatusChecked = ref(false)

const redirectAuthenticatedUserToDashboard = () => {
    if (userStore.authenticated) {
        router.push({ name: 'dashboard' })
    }
}

onMounted(() => {
    if (route.query.login === '1') {
        window.location.href = '/portal/mc/dashboard' + window.location.search
    }

    const intervalId = setInterval(async() => {
        if (window.isAuthenticated != undefined && typeof window.isAuthenticated === 'function') {
            clearInterval(intervalId)
            window.isAuthenticated(false).then(() => isKeycloakStatusChecked.value = true).catch(() => isKeycloakStatusChecked.value = true)
        }
    }, 100)
})

onMounted(redirectAuthenticatedUserToDashboard)
onUpdated(redirectAuthenticatedUserToDashboard)

watch(
    () => userStore.authenticated,
    () => {
        redirectAuthenticatedUserToDashboard()
    }
)

const id = 'portal.mc.homepage'
</script>

<style>
.p0 {
    padding: 0;
}
</style>
