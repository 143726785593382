<template>
    <div class="container content-spacer">
        <div class="row mb-large">
            <div class="col-12 col-lg-3 mb">
                <div class="box-shadow-xy bg-white chapter-links-box">
                    <div class="box-spacing">
                        <ChapterLinks 
                            section="Einstellungen"
                            :links="chapter"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-9 mb-large">
                <div
                    v-for="(item, index) in chapter"
                    :id="item.href.substring(1)"
                    :key="'chapter-' + index"
                    :data-qa="item.dataQa"
                    class="box-shadow-xy bg-white"
                    :class="index < chapter.length - 1 ? 'mb' : ''"
                >
                    <div class="box-spacing">
                        <h3>{{ $t(item.i18nKey) }}</h3>
                        <component :is="item.component" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, markRaw, onMounted } from 'vue'

import ChapterLinks from '@/components/Profile/partials/ChapterLinks.vue'
import ProductSettingsForm from '@/components/Profile/partials/Settings/ProductSettingsForm.vue'
import NotificationConfigForm from '@/components/Profile/partials/Settings/NotificationConfigForm.vue'
import SurrogatesForm from '@/components/Profile/partials/Settings/SurrogatesForm.vue'

import { useCbraSettingsStore } from '@/stores/cbraSettings'
import { useSurrogatesStore } from '@/stores/surrogates'

const cbraSettingsStore = useCbraSettingsStore()
const cbraSurrogatesStore = useSurrogatesStore()

const chapter = ref([
    {
        i18nKey: 'profile.settings.productSettingsForm.title',
        href: '#userdata-product',
        component: markRaw(ProductSettingsForm),
        dataQa: 'selfcare-userdata-productSettings'
    }
])

onMounted(async() => {
    await cbraSettingsStore.getCbraFeatures()
    if (cbraSettingsStore.features.askNotificationActive) {
        chapter.value.push({
            i18nKey: 'profile.settings.notificationConfigForm.title',
            href: '#userdata-notificationConfig',
            component: markRaw(NotificationConfigForm),
            dataQa: 'selfcare-userdata-notificationConfig'
        })
    }

    await cbraSurrogatesStore.getSurrogates()
    if (cbraSurrogatesStore.isVisibleInNavigation) {
        chapter.value.push({
            i18nKey: 'profile.settings.surrogatesForm.title',
            href: '#userdata-surrogates',
            component: markRaw(SurrogatesForm),
            dataQa: 'selfcare-userdata-mailSurrogates'
        })
    }
})
</script>
