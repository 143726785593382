<script setup lang="ts">
import Breadcrumb from '@/components/Breadcrumb.vue'

const id = 'portal.mc.about-creditreform.marketing-addresses'
</script>

<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>
                <router-link :to="{ name: 'about-creditreform' }">
                    {{ $t('menu.servicemenu.about.title') }}
                </router-link>
            </li>
            <li>{{ $t('MarketingAddresses.breadcrumbTitle') }}</li>
        </Breadcrumb>

        <div
            id="c3374"
            class=" clearfix grid-box grid-box--stageLeft box"
            data-qa="grid-box-3374"
        >
            <div class="container container--crefo">
                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div
                            id="c3376"
                            class="ce-box   box"
                            data-qa="ce-3376"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext ce-textpic--nowrap">
                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/about-creditreform/marketing-addresses/CBM_Logo.jpeg"
                                                    width="333"
                                                    height="108"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <div class="ce-header">
                                        <h3>{{ $t('MarketingAddresses.businessMarketing.title') }}</h3>
                                    </div>

                                    <p>{{ $t('MarketingAddresses.businessMarketing.text') }}</p>
                                    <ul class="list-unordered">
                                        <li>{{ $t('MarketingAddresses.businessMarketing.lis.li1') }}</li>
                                        <li>{{ $t('MarketingAddresses.businessMarketing.lis.li2') }}</li>
                                        <li>{{ $t('MarketingAddresses.businessMarketing.lis.li3') }}</li>
                                        <li>{{ $t('MarketingAddresses.businessMarketing.lis.li4') }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            id="c3377"
                            class="ce-box   box"
                            data-qa="ce-3377"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext ce-textpic--nowrap">
                                <div class="ce-textpic__bodytext">
                                    <div class="ce-header">
                                        <h3>{{ $t('MarketingAddresses.webshop.title') }}</h3>
                                    </div>

                                    <ul class="list-unordered">
                                        <li>{{ $t('MarketingAddresses.webshop.lis.li1') }}</li>
                                        <li>{{ $t('MarketingAddresses.webshop.lis.li2') }}</li>
                                        <li>{{ $t('MarketingAddresses.webshop.lis.li3') }}</li>
                                        <li>{{ $t('MarketingAddresses.webshop.lis.li4') }}</li>
                                        <li>{{ $t('MarketingAddresses.webshop.lis.li5') }}</li>
                                        <li>{{ $t('MarketingAddresses.webshop.lis.li6') }}</li>
                                        <li>{{ $t('MarketingAddresses.webshop.lis.li7') }}</li>
                                    </ul>
                                    <i18n-t
                                        keypath="MarketingAddresses.crefoAddress.title"
                                        tag="h3"
                                    >
                                        <a
                                            href="https://crefoaddress.de/"
                                            title="Opens internal link in current window"
                                            target="_blank"
                                            rel="noopener"
                                            class="external-link-new-window"
                                        >
                                            {{ $t('MarketingAddresses.crefoAddress.link') }}
                                        </a>
                                    </i18n-t>
                                    <ul />
                                </div>
                            </div>
                        </div>

                        <div
                            id="c3423"
                            class=" clearfix grid-box grid-box--stageCenter box"
                            data-qa="grid-box-3423"
                        >
                            <div class="container container--crefo">
                                <div class="row">
                                    <div class="col-12 col-md-8 offset-md-2">
                                        <div
                                            id="c3419"
                                            class="ce-box   box"
                                            data-qa="ce-3419"
                                        >
                                            <div class="ce-textpic ce-textpic--center ce-textpic--below">
                                                <div class="ce-textpic__bodytext" />

                                                <div
                                                    class="ce-textpic__gallery"
                                                    data-ce-columns="1"
                                                    data-ce-images="1"
                                                >
                                                    <div class="ce-outer">
                                                        <div class="ce-inner">
                                                            <div class="ce-textpic__row">
                                                                <div class="ce-textpic__column">
                                                                    <div class="ce-textpic__media">
                                                                        <a href="http://www.crefoaddress.de">

                                                                            <img
                                                                                src="@/assets/img/about-creditreform/marketing-addresses/BannerCA480x100.gif"
                                                                                class="ce-textpic__img"
                                                                                width="480"
                                                                                height="100"
                                                                                alt=""
                                                                            >

                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            id="c3421"
                            class="ce-box   box"
                            data-qa="ce-3421"
                        >
                            <div class="ce-textpic ce-textpic--right ce-textpic--intext ce-textpic--nowrap">
                                <div
                                    class="ce-textpic__gallery"
                                    data-ce-columns="1"
                                    data-ce-images="1"
                                >
                                    <div class="ce-textpic__row">
                                        <div class="ce-textpic__column">
                                            <div class="ce-textpic__media">
                                                <img
                                                    src="@/assets/img/about-creditreform/marketing-addresses/Leistungskreis_bedirect_700x600.png"
                                                    width="700"
                                                    height="600"
                                                    alt=""
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="ce-textpic__bodytext">
                                    <div class="ce-header">
                                        <h3>{{ $t('MarketingAddresses.customerAcquisition.title') }}</h3>
                                    </div>

                                    <p>
                                        <b>
                                            {{ $t('MarketingAddresses.customerAcquisition.text1.title') }}
                                        </b>
                                        <br>
                                        <i18n-t
                                            keypath="MarketingAddresses.customerAcquisition.text1.text"
                                            tag="span"
                                        >
                                            <a
                                                href="https://www.bedirect-online.de/adressen-mieten/#block_02"
                                                title="Opens external link in new window"
                                                target="_blank"
                                                rel="noopener"
                                                class="external-link-new-window"
                                            >
                                                {{ $t('MarketingAddresses.customerAcquisition.text1.link') }}
                                            </a>
                                        </i18n-t>
                                    </p>
                                    <p>
                                        <b>
                                            {{ $t('MarketingAddresses.customerAcquisition.text2.title') }}<br>
                                        </b>
                                        <i18n-t
                                            keypath="MarketingAddresses.customerAcquisition.text2.text"
                                            tag="span"
                                        >
                                            <a
                                                href="https://www.bedirect-online.de/adressen-mieten/#block_05"
                                                title="Opens external link in new window"
                                                target="_blank"
                                                rel="noopener"
                                                class="external-link-new-window"
                                            >
                                                {{ $t('MarketingAddresses.customerAcquisition.text2.link') }}
                                            </a>
                                        </i18n-t>
                                        <br>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.grid-box {
    display: block;
    margin: 3rem 0 2rem;
    .grid-box {
        margin: 0;
    }
}
.box {
    padding: 1.5rem;
    position: relative;
}
.ce-textpic {
    display: block;
    overflow: hidden;
    .ce-textpic__gallery {
        float: right;
        @media (max-width: 1199px) {
            float: none;
            max-width: 100%;
        }
        .ce-textpic__media {
            padding: 0 20px 20px;
            max-width: 100%;
            @media (max-width: 1199px) {
                padding: 0 0 2rem;
                float: none;
            }
        }
        .ce-textpic__bodytext {
            display: table;
            .ce-header {
                margin-bottom: 2rem;
            }
        }
    }
    .ce-textpic--center {
        .ce-textpic__gallery {
            text-align: center;
        }
        .ce-textpic__media {
            padding: 20px;
            .ce-textpic__img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    &.ce-textpic--right {
        .ce-textpic__gallery {
            float: right;
            max-width: 40%;
            @media (max-width: 1199px) {
                float: none;
                max-width: 100%;
            }
        }
    }
}
</style>