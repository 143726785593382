<template>
    <div>
        <LoadingOverlay 
            v-if="loading" 
            id="notificationConfig-settings"
        />

        <div 
            v-if="!loading && error"
            class="crefo-ui-alert error mb"
        >
            {{ $t('globalMisc.error.errorLoadingData.message') }}
        </div>

        <div
            v-if="!loading && !error && notificationConfig !== null"
        >
            <p>
                <a 
                    class="link-icon-next"
                    target="_blank"
                    rel="noopener"
                    href="/hilfe/mein-profil/#c4161"
                >
                    <span>{{ $t('selfcare.show.section.nutzerdaten.benachrichtigungen.description') }}</span>
                </a>
            </p>
            <form>
                <div 
                    v-for="(item, index) in notificationConfig.configItems"
                    :key="index"
                    class="row"
                >
                    <div class="col-12">
                        <Select
                            :id="`notificationConfig-${item.id}`"
                            v-model="item.notificationFilterCriteria"
                            :label="$t(`enums.notificationCategories.${item.eventCategoryName}`)"
                            :options="CONSTANTS.NOTIFICATION_OPTIONS"
                            :searchable="false"
                            :data-qa="`userdata-notificationConfigFilterCriteria-${item.eventCategoryName}-${item.notificationChannelName}`"
                        />
                    </div>
                </div>
                <div class="row mb">
                    <div class="col-12">
                        <Select
                            id="notificationConfigPreferredLanguage"
                            v-model="notificationConfig.preferredLanguage"
                            :label="$t('globalMisc.language')"
                            :options="CONSTANTS.NOTIFICATION_LANGUAGE_OPTIONS"
                            :searchable="false"
                            data-qa="userdata-notificationConfigPreferredLanguage"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button 
                            class="btn btn-default"
                            :disabled="submitting"
                            data-qa="userdata-notificationConfig-submitButton"
                            @click.prevent="submit()"
                        >
                            {{ $t('globalMisc.saveChanges') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <Modal
            v-if="modal"
            :id="modal.id"
            :title="modal.title"
            :confirm-text="modal.confirmText"
            :icon="modal.icon"
            :data-qa="modal.dataQa"
            @on-confirm="modal = undefined"
            @on-close="modal = undefined"
        >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="modal.content" />
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import LoadingOverlay from '@/components/LoadingRing.vue'
import Modal from '@/components/Modal.vue'
import Select from '@/components/Input/Select.vue'

import CONSTANTS from '@/data/constants'

import { 
    getCbraNotificationUserConfig,
    updateCbraNotificationUserConfig
} from '@/services'

import type { CbraNotificationUserConfig } from '@/types/cbra/notificationUserConfig'
import type { ModalProps } from '@/types/components/modal'

import { PAGE_FUNCTIONS, trackClick } from '@/helper/webtrekk'

const { t } = useI18n()

let loading = ref(true)
let submitting = ref(false)
let error = ref(false)
let notificationConfig = ref<CbraNotificationUserConfig | null>(null)

const modal = ref<ModalProps>()

const submit = async() => {
    if (notificationConfig.value === null) {
        return
    }

    submitting.value = true

    const _modal = {
        id: 'modal',
        title: t('profile.settings.modal.success.title'),
        content: t('profile.settings.modal.success.content'),
        confirmText: 'Ok',
        icon: 'info',
        dataQa: 'modal-selfcare-onSaveNotificationConfig'
    }

    try {
        const result = await updateCbraNotificationUserConfig(notificationConfig.value)
        if (result.status !== 200) {
            _modal.content = t('profile.settings.modal.error.content')
            _modal.icon = 'error'
        }
    } catch (e) {
        _modal.content = t('profile.settings.modal.error.content')
        _modal.icon = 'error'
    }

    trackClick(PAGE_FUNCTIONS.SELFCARE + '.preferences.save', PAGE_FUNCTIONS.SELFCARE, 'save.preferences.click')

    submitting.value = false

    modal.value = _modal
}

async function loadNotificationUserConfig() {
    loading.value = true
    error.value = false

    try {
        const config = await getCbraNotificationUserConfig()
        if (config.status === 200) {
            notificationConfig.value = config.data
        } else {
            error.value = true
        }
    } catch (e) {
        error.value = true
    }

    loading.value = false
}

onMounted(loadNotificationUserConfig)
</script>
