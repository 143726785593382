<template>
    <div class="selfcare--chapter-links">
        <a 
            v-for="(link, index) of displayLinks"
            :key="'chapter-link-' + index"
            :href="link.href"
            data-qa="toc-listItem"
            @click="scroll"
        >
            <span>{{ $t(link.i18nKey) }}</span>
        </a>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { scrollTo } from '@/helper/scrollto'
import { placeSelfcareSubnavigationClick } from '@/helper/webtrekk/selfcare'
import type { ChapterLink, ChapterLinks } from '@/types/chapterLink'

const props = defineProps<{
    links: ChapterLinks
    section: string
}>()

const displayLinks = computed(() => {
    return props.links.filter((link: ChapterLink) => {
        return link.hideAsAnchor !== true
    })
})

function scroll(event: Event) {
    event.preventDefault()
    const target = event.target as any
    const href = target.parentNode?.getAttribute('href')
    if (href) {
        scrollTo(href)
    }
    placeSelfcareSubnavigationClick(
        props.section,
        event.target.innerText
    )
}
</script>

<style>
.selfcare--chapter-links a {
    display: block;
    padding-bottom: 5px;
}
</style>
