<template>
    <Doughnut
        id="status-monitoring-chart"
        :options="chartOptions"
        :data="chartData"
        :plugins="chartPlugins"
        class="chart"
        data-qa="widgetCanvas"
    />
</template>

<script setup lang="ts">

import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement , Chart, Plugin, ChartData, ChartOptions } from 'chart.js'
import { computed, onMounted, ref } from 'vue'
import { getCbraMonitoringStats } from '@/services'
import type { CbraMonitoringStatsResponse } from '@/types/cbra/monitoringStats'
import { useI18n } from 'vue-i18n'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

const { t } = useI18n()

const chartOptions = ref<ChartOptions<'doughnut'>>({
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 10,
                color: '#4a4a4a',
                font: {
                    weight: '600',
                    family: "'Open Sans', sans-serif",
                }
            },
        },
        tooltip: {
            callbacks: {
                title: () => (''),
                label: (tooltipItem) => chartData.value.labels ? chartData.value.labels[tooltipItem.dataIndex] as string : ''
            }
        }
    }
})

const isLoading = ref(true)
const monitoringStats = ref<CbraMonitoringStatsResponse>()

async function handleFetch() {
    try {
        isLoading.value = true
        const { data } = await getCbraMonitoringStats()
        monitoringStats.value = data
    } catch (error) {
        console.error(error)
    }
    isLoading.value = false
}

onMounted(async()=> {
    await handleFetch()
})

const chartData = computed(() => {
    let chartData: ChartData<'doughnut'> = {
        labels: [],
        datasets: []
    }
    
    if (!monitoringStats.value) {
        return chartData
    }

    const unlimitedMonitoringStatisticItem = monitoringStats.value.unlimitedMonitoringStatisticItem ?monitoringStats.value.unlimitedMonitoringStatisticItem.monitoringCount : 0
    const limitedMonitoringStatisticItem = monitoringStats.value.limitedMonitoringStatisticItem ? monitoringStats.value.limitedMonitoringStatisticItem.monitoringCount : 0
    const expiringMonitoringStatisticItem = monitoringStats.value.expiringMonitoringStatisticItem ? monitoringStats.value.expiringMonitoringStatisticItem.monitoringCount : 0
    chartData = {
        labels: [
            '',
            t('dashboard.widget.monitoring.label.unlimitedMonitoringStatisticItem', { count: unlimitedMonitoringStatisticItem }),
            t('dashboard.widget.monitoring.label.limitedMonitoringStatisticItem' ,{ count: limitedMonitoringStatisticItem }),
            t('dashboard.widget.monitoring.label.expiringMonitoringStatisticItem',{ count: expiringMonitoringStatisticItem })
        ],
        datasets: [ { 
            data: [
                unlimitedMonitoringStatisticItem + limitedMonitoringStatisticItem - expiringMonitoringStatisticItem,
                0,
                0,
                expiringMonitoringStatisticItem
            ],
            backgroundColor: [
                'rgba(0, 141, 217, 0.0)',
                'rgba(0, 141, 217, 0.1)',
                'rgba(0, 141, 217, 0.7)',
                'rgba(0, 141, 217, 1.0)',
            ],
            borderWidth: [
                0,
                0,
                0,
                0
            ],
            weight: 2
        },
        {
            data: [
                0,
                unlimitedMonitoringStatisticItem,
                limitedMonitoringStatisticItem,
                0
            ],
            backgroundColor: [
                'rgba(0, 141, 217, 0.0)',
                'rgba(0, 141, 217, 0.1)',
                'rgba(0, 141, 217, 0.7)',
                'rgba(0, 141, 217, 1.0)'
            ],
            borderWidth: [
                0,
                0,
                0,
                0
            ],
            weight: 6
        }
        ],
    }
    return chartData
})

const chartPlugins = computed(() => {
    const plugins: Plugin<'doughnut'>[] = [ {
        id: 'label-plugin',
        beforeDraw: (chartInstance: Chart) => {
            if (!monitoringStats.value) return
            const datasetCount = chartInstance.getVisibleDatasetCount()
            const chartMeta = chartInstance.getDatasetMeta(datasetCount-1).data.at(0)
            if (!chartMeta) return
            const { innerRadius } = chartMeta.getProps(['innerRadius'])

            let canvas = chartInstance
            let ctx = canvas.ctx
            let txt = monitoringStats.value.monitoringCount.toString()
            let x = (chartInstance.chartArea.right - chartInstance.chartArea.left ) / 2
            let y = (chartInstance.chartArea.bottom - chartInstance.chartArea.top ) / 2
            let fontSize = Math.floor(innerRadius / 1.5) + 'px'

            ctx.font = `${fontSize} OpenSans`
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = '#4a4a4a'
            ctx.fillText(txt, x, y)
        },
    },
    ] 
    return plugins
})

</script>