/*
 * WebTrekkService
 */

/*
 * customClickParameters
 *
 * 2    MC - Cbra HTTP Status Code	Alle Seiten
 * 3    MC - Cbra HTTP Fehlermeldung	Alle Seiten
 * 4	MC - CLZ	Alle Seiten
 * 5	MC - Aktionsstatus	Alle Seiten
 * 6	MC - Seitenfunktion	Alle Seiten
 * 7	MC - Seitenaktion	Alle Seiten
 * 8	MC - Aktionsbeschreibung	Alle Seiten
 * 9	MC - Aktionswert1	Alle Seiten
 * 10	MC - Aktionswert2	Alle Seiten
 * 11	MC - Aktionswert3	Alle Seiten
 * 12	MC - Aktionswert4	Alle Seiten
 * 13	MC - ASK HTTP Status Code	Alle Seiten
 * 14	MC - ASK Status	Alle Seiten
 * 15	MC - ASK Status Message	Alle Seiten
 * 16	MC - ASK Status Hint	Alle Seiten
 * 17	MC - Fehler	Alle Seiten
 * 104	WKV Betrag	Alle Seiten
 * 102	WKV Bestell URL	Alle Seiten
 * 105	WKV-Order-No	Alle Seiten
 * 110	Referrer-Url	Alle Seiten
 */

export function sendClick(linkId: string, customClickParameters = {}) {
    trackAction(linkId, customClickParameters)
}

export function trackAction(
    name: string = 'action',
    parameters = {}
) {
    if (!isWebtrekkActive()) {
        return
    }

    const sessionParameters = getSessionParameters()

    window.wtSmart.session.data.set({
        loginStatus: sessionParameters[4] === '-' ? 'not_logged_in' : 'logged_in',
        parameter: sessionParameters
    })

    window.wtSmart.action.data.set({
        name: name,
        parameter: parameters
    })
    // console.log('[WebTrekkVue] trackAction: ', JSON.stringify(window.wtSmart.action.data.get()))
    window.wtSmart.track()
}

export function sendGoal(linkId: string, customEcommerceParameters = {}) {
    trackGoal(linkId, customEcommerceParameters, null)
}

export function trackGoal(
    name: string = 'goal',
    goalParameters: object = {},
    actionParameters: object | null = null
) {
    if (!isWebtrekkActive()) {
        return
    }

    const sessionParameters = getSessionParameters()

    window.wtSmart.session.data.set({
        loginStatus: sessionParameters[4] === '-' ? 'not_logged_in' : 'logged_in',
        parameter: sessionParameters
    })

    if (typeof actionParameters === 'object' && actionParameters !== null) {
        window.wtSmart.action.data.set({
            name: name,
            goal: goalParameters,
            parameter: actionParameters
        })
    } else {
        window.wtSmart.action.data.set({
            name: name,
            goal: goalParameters
        })
    }

    // console.log('[WebTrekkVue] trackGoal: ', JSON.stringify(window.wtSmart.action.data.get()))
    window.wtSmart.track()
}

export function sendPage(params = {}, customPageParameters = {}) {
    trackPage(params, customPageParameters)
}

export function trackPage(
    params: object = {},
    customPageParameters: object = {}
) {
    if (!isWebtrekkActive()) {
        return
    }

    const sessionParameters = getSessionParameters()

    window.wtSmart.session.data.set({
        loginStatus: sessionParameters[4] === '-' ? 'not_logged_in' : 'logged_in',
        parameter: sessionParameters
    })

    const webTrekkData = getWebTrekkDataObject(params, customPageParameters)

    window.wtSmart.page.data.add({ parameter: webTrekkData.customParameter })

    /*try {
        console.log('[WebTrekkVue] trackPage (params): ', JSON.stringify(params))
        // console.log('[WebTrekkVue] trackPage (customPageParameters): ', JSON.stringify(customPageParameters))
        console.log('[WebTrekkVue] trackPage (webTrekkData): ', JSON.stringify(webTrekkData))
        console.log('[WebTrekkVue] trackPage (wtSmart page data): ', JSON.stringify(window.wtSmart.page.data.get()))
    } catch (error) {
        console.error('[WebTrekkVue] trackPage error: ', error)
    }*/

    if (webTrekkData.productId) {
        const trackType = webTrekkData.productStatus === 'view' ? 'view' : 'confirmation'
        window.wtSmart.product[trackType].data.set([{
            id: webTrekkData.productId,
            currency: 'EUR',
            quantity: 1,
            cost: 0,
            category: webTrekkData.productCategory,
            parameter: webTrekkData.customEcommerceParameter
        }])

        // console.log('[WebTrekkVue] trackPage (wtSmart product data): ', JSON.stringify(window.wtSmart.product[trackType].data.get()))
    }

    window.wtSmart.track()
}

/*
 * TrackClickWebTrekkService
 */
export const PAGE_FUNCTIONS = {
    WIDGET: 'widget',
    ACTIVITIES: 'activities',
    MAIN_MENU: 'main_menu',
    SELFCARE: 'selfcare',
    REPORT: 'report'
}

export function trackClick(
    linkId: string,
    seitenfunktion: string = '',
    seitenaktion: string = '',
    aktionsbeschreibung: string = ''
) {
    if (!isWebtrekkActive()) {
        return
    }

    sendClick(linkId, {
        6: seitenfunktion,
        7: seitenaktion,
        8: aktionsbeschreibung
    })
}

/*
 * internal helper functions
 */
function maskCrid(crid: string) {
    return crid.substr(0, 1)
        + crid.substr(1, 4)
            .replace(new RegExp('[0-4]', 'g'), 'x')
            .replace(new RegExp('[5-9]', 'g'), 'X')
        + 'xxxxxxxxxxx'
}

function getSessionParameters() {
    const parameters = {
        2: '-',
        3: '-',
        4: '-'
    }

    if (typeof window.keycloak === 'object' && window.keycloak !== null) {
        if (typeof window.keycloak.tokenParsed === 'object' && window.keycloak.tokenParsed !== null) {
            if (typeof window.keycloak.tokenParsed.cr_membernumber === 'string' && window.keycloak.tokenParsed.cr_membernumber.length) {
                parameters[2] = window.keycloak.tokenParsed.cr_membernumber.substr(0, 3)
                parameters[3] = window.keycloak.tokenParsed.cr_membernumber
            }
            if (typeof window.keycloak.tokenParsed.cr_id === 'string' && window.keycloak.tokenParsed.cr_id.length) {
                parameters[4] = maskCrid(window.keycloak.tokenParsed.cr_id)
            }
        }
    }

    return parameters
}

function isWebtrekkActive() {
    return typeof window.wtSmart === 'object' && window.wtSmart !== null
}

function getWebTrekkDataObject(optionalParams: any, customPageParameters: any) {
    const webTrekkData: any = {}

    // Merge optional params to default params
    if (typeof optionalParams === 'object' && optionalParams !== null && !(optionalParams instanceof Array)) {
        let appendParams = null
        if (typeof optionalParams.append === 'object' && optionalParams.append !== null) {
            appendParams = optionalParams.append
            delete (optionalParams.append)
        }

        for (const property in optionalParams) {
            webTrekkData[property] = optionalParams[property]
        }

        // Append values of existing params
        if (appendParams) {
            for (const property in appendParams) {
                if (typeof webTrekkData[property] === 'string' || typeof webTrekkData[property] === 'number') {
                    webTrekkData[property] += appendParams[property]
                }
            }
        }
    }

    // Set custom page params
    if (typeof customPageParameters === 'object' && customPageParameters !== null && !(customPageParameters instanceof Array)) {
        webTrekkData.customParameter = customPageParameters
    }

    return webTrekkData
}
