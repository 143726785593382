<template>
    <LoadingRing v-if="isLoading" />
    <LoadingOverlay
        v-if="isSaving"
        id="wkv-loading-overlay"
    />

    <div class="content-spacer">
        <div
            v-if="showOrder"
            id="wkv-calculator"
            class="bg-brand-light-grey big-box-spacing"
        >
            <div class="container">
                <div class="row">
                    <div class="col-12 text-align--center">
                        <div class="new-badge mb-small">
                            {{ $t('wkv.order.new') }}
                        </div>
                        <div class="so-gehts">
                            <span class="hidden-xs hidden-sm">{{ $t('wkv.order.so_gehts') }}</span>
                            <font-awesome-icon 
                                icon="fa-solid fa-caret-down"
                                size="xl"
                            />
                        </div>
                        <h3>{{ $t('wkv.order.headline') }}</h3>
                        <h3>{{ $t('wkv.order.subHeadline') }}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div>{{ $t('wkv.order.customer') }}</div>
                        <div
                            data-qa="wkv-userdata-givenname"
                            class="h3 m0"
                        >
                            {{ givenName }}
                        </div>
                        <div class="d-flex gap mb-small mt">
                            <div class="text-semibold hidden-xs">
                                {{ $t('wkv.order.insurance.coverage.choose') }}
                            </div>
                            <div class="text-semibold visible-xs">
                                {{ $t('wkv.order.insurance.coverage.choose_short') }}
                            </div>
                            <Tooltip data-qa="wkv-order-insurance-coverage-tooltip">
                                <font-awesome-icon
                                    icon="fa-regular fa-circle-info"
                                />
                                <template #content>
                                    <p>{{ $t('wkv.order.insurance.coverage.tooltip') }}</p>
                                </template>
                            </Tooltip>
                        </div>
                        <Select
                            id="coverage-amount"
                            v-model="coverageAmount"
                            label=""
                            :options="coverageAmountOptions()"
                            :searchable="false"
                            data-qa="wkv-order-coverage"
                        />
                        <div class="d-flex gap mt">
                            <div class="text-semibold">
                                {{ $t('wkv.order.insurance.time.choose') }}
                            </div>
                            <Tooltip data-qa="wkv-order-insurance-time-tooltip">
                                <font-awesome-icon
                                    icon="fa-regular fa-circle-info"
                                />
                                <template #content>
                                    <p>{{ $t('wkv.order.insurance.time.tooltip') }}</p>
                                </template>
                            </Tooltip>
                        </div>
                        <Radio
                            id="coverage-time"
                            v-model="coverageTime"
                            radio-group-name="coverage-time"
                            :radio-options="coverageTimeOptions()"
                            :help-text="$t('wkv.order.insurance.time.choose.infotext')"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="box-shadow-xy small-box-spacing bg-white text-semibold">
                            <div class="disturber hidden-xs hidden-sm">
                                <span>
                                    {{ $t('wkv.order.summary.disturber') }}
                                </span>
                            </div>
                            <h3>{{ $t('wkv.order.summary.headline') }}</h3>
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ $t('wkv.order.insurance.price') }}</span>
                                <span data-qa="wkv-order-insurance-price-net"> {{ wkvInsurancePriceNet }}</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ $t('wkv.order.insurance.tax') }}</span>
                                <span data-qa="wkv-order-insurance-tax">{{ wkvInsuranceTax }}</span>
                            </div>
                            <hr class="mt mb">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><b>{{ $t('wkv.order.insurance.totalprice') }}</b></span>
                                <span
                                    class="calculator-total-price"
                                    data-qa="wkv-order-insurance-price"
                                >{{ wkvInsurancePrice }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt">
                    <div class="col-12">
                        <Checkbox
                            id="tems-and-conditions"
                            v-model="termsAndConditionsConfirmed"
                            :label="getGtcConfirmation()"
                            data-qa="wkv_order_gtc_confirmation_infotext"
                        />
                        <Checkbox
                            id="no-external-insurance"
                            v-model="extInsChecked"
                            :label="$t('wkv.order.no_external_insurance.checkbox.text')"
                            data-qa="wkv_order_no_external_insurance"
                        />
                        <Checkbox
                            id="gic-confirmation"
                            v-model="inkassoConfirmed"
                            :label="getInkassoConfirmation()"
                            data-qa="wkv_order_gic_confirmation"
                        />
                    </div>
                </div>
                <div class="row text-align--center">
                    <div class="col-12 col-md-4 offset-md-2">
                        <a
                            id="wkv-back-link"
                            :href="backLink"
                            class="btn btn-secondary btn-icon-prev mb-small"
                            data-qa="wkv-back-link"
                            @click.prevent="showSurvey(backLink)"
                        >
                            <span>
                                {{ $t('wkv.order.back') }}
                            </span>
                        </a>
                    </div>
                    <div class="col-12 col-md-4">
                        <button
                            class="btn btn-default mb-small"
                            data-qa="wkv-oder-submit"
                            :disabled="isOrderDisabled"
                            @click="onSave"
                        >
                            <i
                                class="crefo-ui-icon icon-check"
                                aria-hidden="true"
                            />
                            <span class="ml-10">
                                {{ $t('wkv.order.submit') }}
                            </span>
                        </button>
                    </div>
                    <div class="col-12 text-align--left">
                        * Pflichtfelder
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SurveyModal
        v-if="isOverlayVisible"
        :return-url="returnUrl"
    />
    <Modal
        v-if="errorAlert"
        :id="errorAlert.id"
        :title="errorAlert.title"
        :confirm-text="$t('shared.modals.ok')"
        :error-context="errorAlert.errorContext"
        :icon="errorAlert.icon"
        @on-close="errorAlert = undefined"
        @on-confirm="errorAlert = undefined"
    >
        <p>{{ errorAlert.content }}</p>
    </Modal>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getCbraTransaction } from '@/services'
import { getInsuranceMatrix } from '@/helper/services/WKV'
import { scrollTo } from '@/helper/scrollto'
import { CbraTransaction } from '@/types/cbra/transactions'
import Select from '../Input/Select.vue'
import CONSTANTS from '@/data/constants'
import { useI18n } from 'vue-i18n'
import Radio from '../Input/Radio.vue'
import { getFileUrlWKV } from '@/helper/image'
import Checkbox from '../Input/Checkbox.vue'
import SurveyModal from './SurveyModal.vue'
import { createCbraWKVOrder } from '@/services'
import Modal from '../Modal.vue'
import { ModalProps } from '@/types/components/modal'
import { getASKErrorKey, getErrorMessages } from '@/helper/services/error'
import { isAxiosError } from 'axios'
import LoadingRing from '../LoadingRing.vue'
import { WKVHelper } from '@/helper/wkv'
import Tooltip from '../Tooltip.vue'
import { trackClick } from '@/helper/webtrekk'
import { placeNewWkvOrder, placeNewWkvOrderFailedRequest, placeNewWkvView } from '@/helper/webtrekk/wkv'
import { useCbraSettingsStore } from '@/stores/cbraSettings'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import { adsLinks } from '@/data/typo3Links'

const errorAlert = ref<ModalProps>()

const { t, locale } = useI18n()
const route = useRoute()
const cbraSettingsStore = useCbraSettingsStore()
const transactionId = route.query.transactionId
const backLink = computed(() => `${adsLinks[locale.value]}?no_cache=1&transactionId=${transactionId}`)
const agbFile = getFileUrlWKV('AGB_und_Informationspflichten.pdf')
const versicherungsbedingungenFile = getFileUrlWKV('Versicherungsbedingungen.pdf')
const inkasseVereinbarungFile = getFileUrlWKV('Inkassovereinbarung.pdf')
const datenschutzFile = '/portal/mc/datenschutz'
const confirmationUrl = '/portal/mc/bonitaetsauskunft/waren-kredit-versicherung/waren-kredit-versicherung-bestaetigung'

const isOverlayVisible = ref(false)

const isLoading = ref(true)
const isSaving = ref(false)
const extInsChecked = ref(false)
const termsAndConditionsConfirmed = ref(false)
const inkassoConfirmed = ref(false)

const returnUrl = ref('')

// Transaction data
const transaction = ref()
const givenName = ref()
const businessId = ref()
const productOrderId = ref()
const reportId = ref()

const calcMatrix = ref<any>({})

const coverageAmount = ref()
const coverageTime = ref()

const wkvInsurancePriceNet = computed(() => {
    return WKVHelper.getWkvPrices(coverageAmount.value, coverageTime.value, 'netInsurancePremium', calcMatrix.value)
})

const wkvInsurancePrice = computed(() => {
    return WKVHelper.getWkvPrices(coverageAmount.value, coverageTime.value, 'grossInsurancePremium', calcMatrix.value)
})

const wkvInsuranceTax = computed(() => {
    return WKVHelper.getWkvPrices(coverageAmount.value, coverageTime.value, 'insuranceTax', calcMatrix.value)
})

const isOrderDisabled = computed(() => {
    return !extInsChecked.value || !termsAndConditionsConfirmed.value || !inkassoConfirmed.value
})

const showOrder = ref(false)

onMounted(async() => {
    await cbraSettingsStore.getCbraFeatures()
    if (cbraSettingsStore.features.wkvActive) {
        await handleFetch()
    } else {
        window.history.back()
    }
})

async function handleFetch() {
    if (!transactionId) return
    try {
        const { data } = await getCbraTransaction(transactionId.toString())
        transaction.value = data
        const matrix = await getInsuranceMatrix(data.reportId)
        calcMatrix.value = matrix
        initInsurancePrice()
        showData(data)
    } catch (error) {
        console.error(error)
        let errorConf = getErrorMessages('wkv.order.notreachable')
        errorAlert.value = {
            id: 'wkv-order-error',
            title: errorConf.headline,
            content: errorConf.description,
            icon: 'warning'
        }
    }
}

function showData(transactionData: CbraTransaction) {
    if (!transactionData.reportTransaction) return
    givenName.value = transactionData.reportTransaction.businessName
    businessId.value = transactionData.reportTransaction.businessId
    productOrderId.value = transactionData.reportTransaction.productOrderId
    reportId.value = transactionData.reportId

    addSurveyOverlayEventListeners()

    placeNewWkvView({
        productCost: 0,
        orderValue: 0
    })

    showOrder.value = true
    isLoading.value = false

    if (window.location.hash.includes('#calculator')) {
        nextTick(() => {
            scrollToCalculator()
        })
    }
}

function scrollToCalculator() {
    scrollTo('#wkv-calculator')
}

function coverageAmountOptions() {
    const options = CONSTANTS.WKV_INSURANCES_AMOUNT.map(item => {
        return {
            label: t('wkv.order.insurance.coverage.' + item),
            code: item
        }
    })
    return options
}

function coverageTimeOptions() {
    return [
        { value: 'DAYS_30', label: t('wkv.order.insurance.time.DAYS_30'), id: 'importRadioTrue', dataQa: 'wkv-insurance-coverage-DAYS_30' },
        { value: 'DAYS_60', label: t('wkv.order.insurance.time.DAYS_60'), id: 'importRadioFalse', dataQa: 'wkv-insurance-coverage-DAYS_60' },
        { value: 'DAYS_90', label: t('wkv.order.insurance.time.DAYS_90'), id: 'importRadioFalse', dataQa: 'wkv-insurance-coverage-DAYS_90' }
    ]
}

function initInsurancePrice() {
    coverageAmount.value = CONSTANTS.WKV_INSURANCES_AMOUNT[3]
    coverageTime.value = CONSTANTS.WKV_INSURANCES_TIME[1]
}

function getGtcConfirmation() {
    return t('wkv.order.gtc_confirmation.checkbox.text', {
        versicherungsbedingungen: `<a href="${versicherungsbedingungenFile}" target="_blank">${t('wkv.order.gtc_confirmation.checkbox.versicherungsbedingungen')}</a>`,
        agbtext: `<a href="${agbFile}" target="_blank">${t('wkv.order.gtc_confirmation.checkbox.agbtext')}</a>`,
        datenschutztext: `<a href="${datenschutzFile}" target="_blank">${t('wkv.order.gtc_confirmation.checkbox.datenschutztext')}</a>`
    })
}

function getInkassoConfirmation() {
    return t('wkv.order.gic_confirmation.checkbox.inkassovereinbarung.text', {
        inkassovereinbarung: `<a href="${inkasseVereinbarungFile}" target="_blank">${t('wkv.order.gic_confirmation.checkbox.inkassovereinbarung')}</a>`
    })
}

async function onSave() {
    if (isSaving.value) {
        return
    }
    isSaving.value = true

    let params = {
        insurancePeriod: coverageTime.value, 
        insuranceSum: coverageAmount.value,
        reportId: reportId.value,
        singleInsuranceConfirmed: extInsChecked.value ? 'YES' : 'NO',
        termsAndConditionsConfirmed: termsAndConditionsConfirmed.value ? 'YES' : 'NO',
        collectionTermsConfirmed: inkassoConfirmed.value ? 'YES' : 'NO',
    }

    trackClick('WKV_Abschluss_Button', 'WKV_Abschluss_Button', 'wkv.order.btn')
    try {
        const { status, data } = await createCbraWKVOrder(params)
        if (data.orderNo !== undefined && data.orderNo !== null) {
            placeNewWkvOrder({
                ...params,
                productCost: WKVHelper.getWkvPrices(coverageAmount.value, coverageTime.value, 'grossInsurancePremium', calcMatrix.value),
                orderValue: WKVHelper.getWkvPrices(coverageAmount.value, coverageTime.value, 'grossInsurancePremium', calcMatrix.value),
                orderId: 'WKV-Order__' + data.orderNo + '__' + Date.now(),
                orderNo: data.orderNo
            })
            location.assign(`${window.location.protocol}//${document.location.hostname}${confirmationUrl}?transactionId=${transactionId}&orderNo=${data.orderNo}`)
        } else {
            let errorConf = getErrorMessages('wkv.order.notreachable')
            errorAlert.value = {
                id: 'wkv-order-error',
                title: errorConf.headline,
                content: errorConf.description,
                icon: 'warning'
            }

            placeNewWkvOrderFailedRequest({ status: status }, errorConf.description)
            isSaving.value = false
        }
    } catch (error) {
        if (isAxiosError(error)) {
            let errorMessage = getASKErrorKey({ xhr: error })
            let errorConf = {
                headline: '',
                description: ''
            }
            if (errorMessage) {
                errorConf = getErrorMessages(errorMessage)
            } else {
                errorConf = getErrorMessages('wkv.order.notreachable')
            }
            isSaving.value = false
            errorAlert.value = {
                id: 'wkv-order-error',
                title: errorConf.headline,
                content: errorConf.description,
                icon: 'warning'
            }
        }
    }
}

function addSurveyOverlayEventListeners() {
    // Get all the elements you want to add the event listener to
    const elements = document.querySelectorAll('.sidebar-navigation__link, .footer-menu a, .service-menu a, .breadcrumbs a, .main-menu a')

    // Define the event listener function
    function clickHandler(e: Event) {
        e.preventDefault()
        e.stopPropagation()
        const targetElement = e.target as HTMLAnchorElement
        const url = targetElement.href || (targetElement.parentElement as HTMLAnchorElement)?.getAttribute('href') || ''
        showSurvey(url)
    }

    // Add the event listener to each selected element
    elements.forEach(function(element) {
        element.addEventListener('click', clickHandler)
    })
}

function showSurvey(url: string) {
    returnUrl.value = url
    isOverlayVisible.value = true
}

</script>

<style lang="less" scoped>
.calculator-total-price {
    color: var(--color-c-p1);
    font-size: 1.6rem;
    line-height: 1.6rem;
    font-weight: 700;
}

.disturber {
    position: absolute;
    right: -110px;
    top: -110px;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    background: var(--color-c-p1);
    align-items: center;
    border-radius: 50%;
    transform: rotate(5deg);
    span {
        color: var(--color-c-white);
        text-align: center;
        padding: 50%;
    }
}

.ml-10 {
    margin-left: 10px;
}
</style>
