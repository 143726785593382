import countries from '@/data/countries'
import { ComposerTranslation } from 'vue-i18n'

export function countryByCode(countryCode: string) {
    return countries.find(country => country.code === countryCode)
}

export function getCountryByName(t: ComposerTranslation, countryName: string) {
    return countries.find(country => t(country.i18nKey) === countryName)
}
