<!-- eslint-disable vue/no-v-html -->

<template>
    <form 
        v-if="!isOrdered && !hasNoPermissions"
        ref="formElement"
        class="advanced-search-form"
        :class="{'has-errors': !isValid }"
        action="/erweiterte-suche/suchergebnisliste/"
        method="GET"
        @submit.prevent="onSubmit"
    >
        <div :class="{'bg-brand-light-grey box-spacing': !isSearchForm}">
            <div class="container">
                <div class="row">
                    <div class="col-0 col-md-2" />
                    <div class="col-12 col-md-8">
                        <div v-if="!isSearchForm && !isOrderView">
                            <strong>{{ $t('report.orderForm.notice.label') }}</strong>
                            <p>{{ $t('report.orderForm.notice.isNotBigNet.text') }}</p>
                        </div>
                        <div v-if="isOrderView">
                            <strong>{{ $t('report.orderForm.notice.label') }}</strong>
                            <p>{{ $t('report.orderForm.notice.text') }}</p>
                        </div>
                        <Select
                            id="country"
                            v-model="country"
                            :options="countries"
                            :data-qa="isOrderView ? 'orderForm-country' : 'extendedSearch_country'"
                            :label="$t('advancedSearch.country')"
                            :searchable="true"
                            :required="isFieldRequired('country')"
                        />
                        <input
                            v-if="advancedSearchQuery !== ''" 
                            type="hidden" 
                            name="advancedSearchQuery" 
                            :value="advancedSearchQuery"
                        >
                        <InputText
                            v-if="fieldExists('businessName')"
                            v-model="businessName"
                            :label="isSearchForm ? $t('search.extended.companyname') : $t('report.orderForm.label.tradeName')"
                            name="businessName"
                            :data-qa="isOrderView ? 'orderForm-firmierung': 'extendedSearch_company'"
                            :error-msg-data-qa="isOrderView ? 'orderForm-businessName-ValidationMessage' : 'extendedSearch_company_validation'"
                            :required="isFieldRequired('businessName')"
                            :highlight="highlightPrefilledFields"
                        />
                        <input 
                            type="hidden" 
                            name="query" 
                            :value="businessName" 
                        >
                        <div
                            v-if="fieldExists('street')" 
                            class="row"
                        >
                            <div class="col-8">
                                <InputText
                                    v-model="street"
                                    :label="$t('advancedSearch.street')"
                                    name="street"
                                    :data-qa="isOrderView ? 'orderForm-street' : 'extendedSearch_street'"
                                    :required="isFieldRequired('street')"
                                />
                            </div>
                            <div class="col-4">
                                <InputText
                                    v-model="housenumber"
                                    :label="$t('advancedSearch.housenumber')"
                                    name="housenumber"
                                    :data-qa="isOrderView ? 'orderForm-streetnumber' : 'extendedSearch_streetnumber'"
                                    error-msg-data-qa="advanced-search-housenumber"
                                    :required="isFieldRequired('housenumber')"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <InputText
                                    v-if="fieldExists('zipCode')"
                                    v-model="zipCode"
                                    :label="$t('advancedSearch.zipCode')"
                                    name="zipCode"
                                    :data-qa="isOrderView ? 'orderForm-zip' : 'extendedSearch_zip'"
                                    :error-msg-data-qa="isOrderView ? 'orderForm-zipCode-ValidationMessage' : 'extendedSearch_zip_validation'"
                                    :required="isFieldRequired('zipCode')"
                                />
                            </div>
                            <div class="col-8">
                                <InputText
                                    v-if="fieldExists('city')"
                                    v-model="city"
                                    :label="$t('advancedSearch.city')"
                                    name="city"
                                    :data-qa="isOrderView ? 'orderForm-city' : 'extendedSearch_city'"
                                    :error-msg-data-qa="isOrderView ? 'orderForm-city-ValidationMessage' : 'advanced-search-city'"
                                    :required="isFieldRequired('city')"
                                />
                            </div>
                        </div>
                        <div class="col-0 col-md-2" />
                    </div>
                </div>
            </div>
        </div>
        <!-- DAL fields -->
        <div
            v-if="isSearchForm"
            id="search-fields"
            class="container small-content-spacer"
        >
            <div class="row">
                <div class="col-0 col-md-2" />
                <div class="col-12 col-md-8">
                    <div
                        v-if="fieldExists('areaNummer') && fieldExists('subscriberNummer')" 
                        class="row"
                    >
                        <div class="col-4">
                            <InputText
                                v-model="areaNummer"
                                :label="$t('advancedSearch.areaNummer')"
                                name="areaNummer"
                                data-qa="extendedSearch_areaNummer"
                                error-msg-data-qa="extendedSearch_areaNummer_validation"
                                :required="isFieldRequired('areaNummer')"
                            />
                        </div>
                        <div class="col-8">
                            <InputText
                                v-model="subscriberNummer"
                                :label="$t('advancedSearch.subscriberNummer')"
                                name="subscriberNummer"
                                data-qa="extendedSearch_subscriberNummer"
                                error-msg-data-qa="extendedSearch_subscriberNummer_validation"
                                :required="isFieldRequired('subscriberNummer')"
                            />
                        </div>
                    </div> 
                    <InputText
                        v-if="fieldExists('businessId')"
                        v-model="businessId"
                        :label="isDAL(country) ? $t('search.extended.crefonumber') : $t('search.extended.identnumber')"
                        name="businessId"
                        data-qa="extendedSearch_crefonumber"
                        error-msg-data-qa="extendedSearch_crefonumber_validation"
                        :required="isFieldRequired('businessId')"
                    />

                    <InputText
                        v-if="fieldExists('internetAddress')"
                        v-model="internetAddress"
                        :label="$t('advancedSearch.internetAddress')"
                        name="internetAddress"
                        data-qa="extendedSearch_internetAddress"
                        error-msg-data-qa="extendedSearch_internetAddress_validation"
                        :required="isFieldRequired('internetAddress')"
                    />    
                    <InputText
                        v-if="fieldExists('eMailAddress')"
                        v-model="eMailAddress"
                        :label="$t('advancedSearch.eMailAddress')"
                        name="eMailAddress"
                        data-qa="extendedSearch_eMailAddress"
                        error-msg-data-qa="extendedSearch_eMailAddress_validation"
                        :required="isFieldRequired('eMailAddress')"
                    />
                    <Select
                        v-if="fieldExists('rechtsform')"
                        id="rechtsform"
                        v-model="rechtsform"
                        :options="legalFormOptions"
                        data-qa="advancedSearch-rechtsform"
                        :label="$t('advancedSearch.rechtsform')"
                        :placeholder="$t('advancedSearch.rechtsformPlaceholder')"
                        :searchable="true"
                        :required="isFieldRequired('rechtsform')"
                    />
                    <Select
                        v-if="fieldExists('registerArt')"
                        id="registerArt"
                        v-model="registerArt"
                        :options="registerTypeOptions"
                        data-qa="advancedSearch-registerArt"
                        :label="$t('advancedSearch.registerArt')"
                        :searchable="false"
                        :required="isFieldRequired('registerArt')"
                    />
                    <InputText
                        v-if="fieldExists('registerNummer')"
                        v-model="registerNummer"
                        :label="$t('advancedSearch.registerNummer')"
                        name="registerNummer"
                        data-qa="extendedSearch_registerNummer"
                        error-msg-data-qa="extendedSearch_registerNummer_validation"
                        :required="isFieldRequired('registerNummer')"
                    />
                    <InputText
                        v-if="fieldExists('vatNumber')"
                        v-model="vatNumber"
                        :label="$t('advancedSearch.vatNumber')"
                        name="vatNumber"
                        data-qa="extendedSearch_tradeID"
                        error-msg-data-data-qa="extendedSearch_tradeID_validation"
                        :required="isFieldRequired('vatNumber')"
                    />
                </div>
            </div>
            
            <div class="col-0 col-md-2" />
        </div>

        <!-- Not DAL fields -->
        <div
            v-else
            id="order-report-fields"
            class="container small-content-spacer"
        >
            <div class="row">
                <div class="col-0 col-md-2" />
                <div class="col-12 col-md-8">
                    <Select
                        id="legitimateInterest"
                        v-model="legitimateInterest"
                        :options="legitimateInterestOptions"
                        data-qa="advancedSearch-legitimateInterest"
                        :label="$t('report.detail.legitimateInterest.label')"
                        :required="isFieldRequired('legitimateInterest')"
                    />

                    <LegitimateNote
                        v-if="legitimateInterest && isLegitimateInterestChanged"
                        :legitimate-interest="legitimateInterest"
                    />
                    <div 
                        class="row"
                    >
                        <div class="col-4">
                            <Checkbox
                                id="monitoring"
                                v-model="monitoring"
                                name="monitoring"
                                :label="$t('advancedSearch.monitoringUnlimited')"
                                data-qa="orderForm-automaticRequest"
                                :required="isFieldRequired('monitoring')"
                                :disabled="monitoringPlus"
                                @change="setMonitoring"
                            />
                        </div>
                        <div class="col-4">
                            <Checkbox
                                id="monitoringLimited"
                                v-model="monitoringLimited"
                                name="monitoringLimited"
                                :label="$t('advancedSearch.monitoringLimited')"
                                data-qa="orderForm-automaticRequest-limited"
                                :disabled="monitoringPlus"
                                @change="setMonitoringLimited"
                            />
                        </div>

                        <div :class="isDAL(country) ? 'col-4 text-align-right' : 'col-1'">
                            <Tooltip
                                position="left"
                                :wide="true"
                            >
                                <font-awesome-icon
                                    icon="fa-regular fa-circle-info" 
                                    size="2x"
                                    color="var(--color-c-p1)"
                                />
                                <template #content>
                                    <p
                                        v-if="isDAL(country)"
                                        v-html="$t('report.addMonitoringToOrder.tooltip')"
                                    />
                                    <p
                                        v-if="!isDAL(country)"
                                        v-html="$t('report.addMonitoringToOrderNonDal.tooltip')"
                                    />
                                </template>
                            </Tooltip>
                        </div>
                        <div
                            v-if="!isBigNet"
                            class="col-12 col-md-3 mb-small"
                        >
                            <small>{{ $t('search.extended.monitoring.additionalInfo') }}</small>
                        </div>
                    </div>
                    <div
                        v-if="monitoringLimited"
                        class="row"
                    >
                        <div class="col-8">
                            <Select
                                id="befristungEndeMonat"
                                v-model="befristungEndeMonat"
                                :options="monitoringLimitedMonthOptions"
                                data-qa="orderForm-befristungEndeMonat"
                                label=""
                            />
                        </div>
                        <div class="col-4">
                            <Select
                                id="befristungEndeJahr"
                                v-model="befristungEndeJahr"
                                :options="monitoringLimitedYearOptions"
                                data-qa="orderForm-befristungEndeJahr"
                                label=""
                            />
                        </div>
                    </div>
                    <Checkbox
                        v-if="showMonitoringPlus"
                        id="monitoringPlus"
                        v-model="monitoringPlus"
                        name="monitoringPlus"
                        :label="$t('report.detail.addMonitoringPlusToOrder.QuickBuy')"
                        data-qa="orderForm-automaticRequest-monitoringPlus"
                    />
                    <Checkbox
                        v-if="isExpressDeliveryAvailable"
                        id="express"
                        v-model="express"
                        name="express"
                        :label="`${$t('search.extended.express')}: ${$t('search.extended.express.description')}`"
                        data-qa="orderForm-express"
                    />
                    <InputText
                        v-model="referenceNumber"
                        :label="$t('report.orderForm.label.creditorNumber')"
                        name="referenceNumber"
                        :data-qa="isOrderView ? 'orderForm-myNumber' : 'advancedSearch-referenceNumber'"
                        :required="isFieldRequired('referenceNumber')"
                        :help-text="$t('report.detail.cannotBeChangedLater')"
                    />
                    <Select
                        id="reportLanguage"
                        v-model="reportLanguage"
                        :options="reportLanugageOptions"
                        data-qa="advancedSearch-reportLanguage"
                        :label="$t('report.orderForm.label.reportLanguage')"
                        :required="isFieldRequired('reportLanguage')"
                        :help-text="showTranslationCostNotice ? $t('report.detail.reportLanguage.note') : undefined"
                    />
                    <InputText
                        v-if="isOrderView && isDAL(country)"
                        v-model="additionalInfos"
                        :label="$t('report.orderForm.label.additionalInfos')"
                        name="additionalInfos"
                        :data-qa="isOrderView ? 'orderForm-additionalInformation' : 'advanced-search-additionalInfos'"
                        :required="isFieldRequired('additionalInfos')"
                    />
                </div>
                <div class="col-0 col-md-2" />
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-0 col-md-2" />
                <div class="col-12 col-md-8">
                    <button
                        class="btn btn-default"
                        :disabled="submitButtonDisabled"
                        :data-qa="submitButtonDataQA"
                    >
                        {{ submitButtonText }}
                    </button>
                </div>
                <div class="col-0 col-md-2" />
            </div>
        </div>
    </form>
    <div v-if="isOrdered">
        <div class="bg-brand-light-grey box-spacing">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3>{{ $t('report.orderForm.confirm.headline') }}</h3>
                        <p>{{ $t('report.orderForm.confirm.text_1') }}</p>
                        <p>{{ $t('report.orderForm.confirm.text_2') }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-center">
                            <button
                                class="btn btn-default"
                                data-qa="advancedSearch-buyNewReportNow"
                                @click.prevent="onClickOrderAnotherReport"
                            >
                                {{ $t('report.orderForm.label.orderNewReport') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal
        v-if="errorAlert"
        :id="errorAlert.id"
        :title="errorAlert.title"
        :confirm-text="$t('shared.modals.ok')"
        :error-context="errorAlert.errorContext"
        :icon="errorAlert.icon"
        @on-close="closeErrorAlert"
        @on-confirm="confirmErrorAlert"
    >
        <p v-html="errorAlert.content" />
    </Modal>
</template>

<script lang="ts" setup>
import countries from '@/data/countries'

import { computed, ref, PropType, toRefs, watch, onMounted, nextTick, getCurrentInstance, } from 'vue'
import { useForm, useIsFormValid , } from 'vee-validate'
import { useRoute } from 'vue-router'
import { toTypedSchema } from '@vee-validate/zod'
import { boolean, literal, object, ZodString } from 'zod'
import { useScrollToFormError } from '@/helper/forms'
import InputText from '@/components/Input/InputText.vue'
import type { CbraUserPreferences } from '@/types/cbra/userPreferences'
import type { ModalProps } from '@/types/components/modal'

import { registerArtOptions } from '@/data/registerArt'
import { rechtsformOptions } from '@/data/rechtsform'
import { reportLanguageOptions } from '@/data/reportLanguage'
import Select from '../Input/Select.vue'
import { countryZipCodeLength } from '@/helper/country'
import { useI18n } from 'vue-i18n'
import Checkbox from '../Input/Checkbox.vue'
import CONSTANTS from '@/data/constants'
import Storage from '@/helper/storage'
import axios from 'axios'
import { scrollTo } from '@/helper/scrollto'
import { REGEXES, REQUIRED_STRING_VALIDATION } from '@/helper/zodSchema'
import Modal from '../Modal.vue'
import dayjs from 'dayjs'
import helpers from '@/helper/misc'
import Tooltip from '@/components/Tooltip.vue'
import type { CbraAdvancedSearchCreateData, CbraOrderReportCreateData } from '@/types/cbra/advancedSearch'
import { showError } from '@/helper/services/error'
import LegitimateNote from '../LegitimateNote.vue'
import { differentContextActive, getReportAct4SbNumberSession, removeMemberNavigationSession } from '@/helper/services/memberNavigation'
import { checkUserRight } from '@/helper/services/userRights'
import { AdvancedSearchService } from '@/helper/services/advancedSearch'

const props = defineProps({
    userPreferences: {
        type: Object as PropType<CbraUserPreferences>,
        required: true
    },
    searchPreferences: {
        type: Object as PropType<CbraSearchPreferences>,
        required: false,
        default: undefined
    },
    productAvailability: {
        type: Object,
        required: false,
        default: undefined
    },
    isOrderView: {
        type: Boolean,
        default: false
    }
})

defineEmits(['getIsSearchForm'])

const searchFieldValidationDefaultConfigDAL: CbraSearchFieldConfigWithFieldNameKey = {
    businessName: { 'minLength': 2, 'maxLength': 90, 'mandatory': false, 'fieldName': 'businessName' },
    businessId: { 'minLength': 10, 'maxLength': 10, 'mandatory': false, 'fieldName': 'businessId' },
    zipCode: { 'maxLength': 9, 'mandatory': false, 'fieldName': 'zipCode' },
    city: { 'maxLength': 25, 'mandatory': true, 'fieldName': 'city' },
    street: { 'maxLength': 30, 'mandatory': false, 'fieldName': 'street' },
    housenumber: { 'maxLength': 15, 'mandatory': false, 'fieldName': 'housenumber' },
    vatNumber: { 'minLength': 2, 'maxLength': 25, 'mandatory': false, 'fieldName': 'vatNumber' },
}

const searchFieldValidationDefaultConfigOnlyDAL: CbraSearchFieldConfigWithFieldNameKey = {
    ...searchFieldValidationDefaultConfigDAL,
    areaNummer: { 'mandatory': false, 'maxLength': 7, 'fieldName': 'areaNummer' },
    subscriberNummer: { 'mandatory': false, 'maxLength': 12, 'fieldName': 'subscriberNummer' },
    internetAddress: { 'mandatory': false, 'maxLength': 50, 'fieldName': 'internetAddress' },
    eMailAddress: { 'mandatory': false, 'maxLength': 255, 'fieldName': 'eMailAddress' },
    rechtsform: { 'mandatory': false, 'fieldName': 'rechtsform' },
    registerArt: { 'mandatory': false, 'fieldName': 'registerArt' },
    registerNummer: { 'mandatory': false, 'maxLength': 18, 'fieldName': 'registerNummer' }
}

const searchFieldValidationDefaultConfigCH: CbraSearchFieldConfigWithFieldNameKey = {
    ...searchFieldValidationDefaultConfigDAL,
    businessId: { 'minLength': 5, 'maxLength': 21, 'mandatory': false, 'fieldName': 'businessId' },
    zipCode: { 'maxLength': 4, 'mandatory': true, 'fieldName': 'zipCode' }
}

/**
 * field validation config based on DAL for other countries.
 * used for search form and might be overwritten by search preferences.
 */
const searchFieldValidationDefaultConfigOther: CbraSearchFieldConfigWithFieldNameKey = {
    ...searchFieldValidationDefaultConfigDAL,
    businessId: { 'minLength': 5, 'maxLength': 21, 'mandatory': false, 'fieldName': 'businessId' }
}

const orderfieldValidationConfigDAL: CbraSearchFieldConfigWithFieldNameKey = {
    country: { 'minLength': 2, 'mandatory': true, 'fieldName': 'country' },
    businessName: { 'minLength': 1, 'maxLength': 90, 'mandatory': true, 'fieldName': 'businessName' },
    housenumber: { 'maxLength': 15, 'mandatory': false, 'fieldName': 'housenumber' },
    street: { 'maxLength': 255, 'mandatory': false, 'fieldName': 'street' },
    zipCode: { 'mandatory': true, 'fieldName': 'zipCode' },
    city: { 'maxLength': 25, 'mandatory': true, 'fieldName': 'city' },
    reportLanguage: { 'maxLength': 2, 'mandatory': true, 'fieldName': 'reportLanguage' },
    referenceNumber: { 'maxLength': 32, 'mandatory': false, 'fieldName': 'referenceNumber' },
    monitoring: { 'mandatory': false, 'fieldName': 'monitoring' },
    express: { 'mandatory': false, 'fieldName': 'express' },
    legitimateInterest: { 'mandatory': true, 'fieldName': 'legitimateInterest' }
}

const orderfieldValidationConfigOnlyDAL: CbraSearchFieldConfigWithFieldNameKey = {
    ...orderfieldValidationConfigDAL,
    additionalInfos: { 'mandatory': false, 'maxLength': 364, 'fieldName': 'additionalInfos' }
}

const orderfieldValidationConfigCH: CbraSearchFieldConfigWithFieldNameKey = {
    ...orderfieldValidationConfigDAL,
    street: { 'maxLength': 40, 'mandatory': false, 'fieldName': 'street' },
    zipCode: { 'maxLength': 4, 'mandatory': true, 'fieldName': 'zipCode' }
}

const orderfieldValidationConfigOther: CbraSearchFieldConfigWithFieldNameKey = {
    ...orderfieldValidationConfigDAL,
    street: { 'maxLength': 40, 'mandatory': false, 'fieldName': 'street' },
    zipCode: { 'mandatory': false, 'fieldName': 'zipCode' }
}

const FORM_DATA_STORAGE = 'AdvancedSearch.FormData'
const FORM_DATA_ORDER_STORAGE = 'AdvancedSearch.FormDataOrder'

const { t } = useI18n()

const route = useRoute()

const highlightPrefilledFields = route.query.highlightPrefilledFields === 'true'

let defaultCountry = 'DE'
if (props.userPreferences) {
    const userPreferences = toRefs(props.userPreferences)
    if (userPreferences.defaultSearchCountry.value !== null) {
        defaultCountry = userPreferences.defaultSearchCountry.value
    }
}
if (route.query.country) {
    defaultCountry = route.query.country.toString()
}

const hasNoPermissions = ref(false)

const reportLanugageOptions = reportLanguageOptions(t)
const legitimateInterestOptions = CONSTANTS.LEGITIMATE_INTEREST_OPTIONS
const isOrdered = ref(false)
const formElement = ref<HTMLFormElement>()
const errorAlert = ref<ModalProps>()
const closeErrorAlert = () => errorAlert.value?.onClose?.() || (errorAlert.value = undefined)
const confirmErrorAlert = () => errorAlert.value?.onConfirm?.() || (errorAlert.value = undefined)

// DAL fields
const country = ref(defaultCountry)
const businessName = ref(route.query.query?.toString() || route.query.businessName?.toString() || '')
const street = ref(route.query.street?.toString() || '')
const housenumber = ref(route.query.housenumber?.toString() || '')
const zipCode = ref(route.query.zipCode?.toString() || '')
const city = ref(route.query.city?.toString() || '')
const areaNummer = ref('')
const subscriberNummer = ref('')
const businessId = ref('')
const internetAddress = ref('')
const eMailAddress = ref('')
const rechtsform = ref('')
const registerArt = ref('')
const registerNummer = ref('')
const vatNumber = ref('')

// Non DAL fields
const legitimateInterest = ref(props.userPreferences.defaultReportLegitimateInterest)
const isLegitimateInterestChanged = ref(false)
const monitoring = ref(false)
const monitoringLimited = ref(false)
const monitoringPlus = ref(false)
const express = ref(false)
const referenceNumber = ref('')
const reportLanguageExist = reportLanugageOptions.find(option => option.code === props.userPreferences.reportLanguage)
const reportLanguage = ref(reportLanguageExist && props.userPreferences.reportLanguage ? props.userPreferences.reportLanguage : 'DE')
const productType = ref('COMMERCIAL_REPORT')
const additionalInfos = ref('')

// START: Befristetes Monitoring 
const defaultMonth = dayjs().locale('en').format('MMMM').toUpperCase()
const defaultYear = dayjs().add(3, 'year').format('YYYY')
const defaultYearNonDal = dayjs().add(1, 'years').format('YYYY')
const monitoringLimitedMonthOptions = CONSTANTS.LIMITED_MONITORING_MONTH_OPTIONS
const befristungEndeMonat = ref(defaultMonth)

const monitoringLimitedYearOptions = helpers.getMonitoringLimitedYearOptions()
const befristungEndeJahr = ref(isDAL(country.value) ? defaultYear : defaultYearNonDal)
// END: Befristetes Monitoring 

watch(legitimateInterest, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        isLegitimateInterestChanged.value = true
    }
})

watch(country, (newValue) => {
    if (!isDAL(newValue)) {
        resetMonitoringLimited()
    }
})

const showMonitoringPlus = computed(() => {
    return props.isOrderView && isDAL(country.value) && (monitoring.value || monitoringLimited.value)
})

const isSearchForm = computed(() => {
    if (props.isOrderView) return false
    const isSearchFormValue = dynamicValidationConfig.value.length > 0
    const instance = getCurrentInstance()
    instance?.emit('getIsSearchForm', isSearchFormValue)
    return isSearchFormValue
})

const advancedSearchQuery = computed(() => {
    const params: any = {}
    const payload = createSearchPayload()
    for (const [key, value] of Object.entries(payload)) {
        if (value) {
            if (key === 'registerData') {
                params.registerData = {}
                if (value.registerArt) {
                    params.registerData.registerArt = value.registerArt
                }
                if (value.registerNummer) {
                    params.registerData.registerNummer = value.registerNummer
                }
                if (isEmpty(params.registerData) ) {
                    delete params.registerData 
                }
            } else {
                params[key] = value
            }
        }
    }
    return Object.entries(params).length ? JSON.stringify(params) : ''
})

const isEmpty = (obj: object) => {
    return Object.keys(obj).length === 0
}

const isExpressDeliveryAvailable = computed(() => {
    return !isDAL(country.value)
})

const dynamicValidationConfig = computed(() => {
    let validationConfig:CbraSearchFieldConfig[] = []
    if (props.searchPreferences) {
        validationConfig = props.searchPreferences.countries[country.value]
    }
    return validationConfig || []
})

const registerTypeOptions = computed(() => {
    const optionsByCountry = registerArtOptions(t, country.value)
    const sortedOptions = optionsByCountry.sort((a, b) => a.label.localeCompare(b.label))
    const defaultOption = { label: t('advancedSearch.options.registerArt.ALL'), code: '' }
    sortedOptions.unshift(defaultOption)
    return sortedOptions
})

const legalFormOptions = computed(() => {
    const optionsByCountry = rechtsformOptions(t, country.value)
    const sortedOptions = optionsByCountry.sort((a, b) => a.label.localeCompare(b.label))
    const defaultOption = { label: t('advancedSearch.options.rechtsform.ALL'), code: '' }
    sortedOptions.unshift(defaultOption)
    return sortedOptions
})

const mergedValidationConfig = computed(() => {
    let defaultConfig: CbraSearchFieldConfigWithFieldNameKey = []

    // look for search preferences -> extended search form
    if (!props.isOrderView) {
        if (isDAL(country.value)) {
            defaultConfig = searchFieldValidationDefaultConfigOnlyDAL
        } else if (isCH(country.value)) {
            defaultConfig = searchFieldValidationDefaultConfigCH
        } else {
            defaultConfig = searchFieldValidationDefaultConfigOther
        }
    }

    // use order preferences -> order form
    if (!isSearchForm.value) {
        if (isDAL(country.value)) {
            defaultConfig = orderfieldValidationConfigOnlyDAL
        } else if (isCH(country.value)) {
            defaultConfig = orderfieldValidationConfigCH
        } else {
            defaultConfig = orderfieldValidationConfigOther
        }
    }

    // when order form
    if (!isSearchForm.value) {
        return Object.values(defaultConfig)
    }

    const combinedArray = dynamicValidationConfig.value.map(override => {
        const { fieldName, maxLength, mandatory } = override
        const config = defaultConfig[fieldName]

        if (!config) {
            return override
        }

        const mergedConfig = { ...config }

        mergedConfig.mandatory = mandatory
        
        if (maxLength !== null && maxLength !== undefined) {
            mergedConfig.maxLength = maxLength
        }
        if (maxLength !== null && maxLength !== undefined) {
            mergedConfig.maxLength = maxLength
        }
        return mergedConfig
    })

    const merged = combinedArray
    return merged
})

const formValidationSchema = computed(() => toTypedSchema(
    object({
        country: generateFieldSchema({ fieldName: 'country' }),
        businessName: generateFieldSchema({ fieldName: 'businessName' }),
        street: generateFieldSchema({ fieldName: 'street' }),
        housenumber: generateHousenumberSchema(),
        zipCode: generateZipCodeSchema(),
        city: generateFieldSchema({ fieldName: 'city' }),
        areaNummer: generateAreaNummerSchema(),
        subscriberNummer: generateSubscriberNummerSchema(),
        businessId: generateBusinessIdSchema(),
        internetAddress: generateInternetAddressSchema(),
        eMailAddress: generateEmailaddressSchema(),
        rechtsform: generateFieldSchema({ fieldName: 'rechtsform' }),
        registerArt: generateFieldSchema({ fieldName: 'registerArt' }),
        registerNummer: generateFieldSchema({ fieldName: 'registerNummer' }),
        vatNumber: generateFieldSchema({ fieldName: 'vatNumber' }),
        // order fields
        legitimateInterest: generateFieldSchema({ fieldName: 'legitimateInterest' }),
        monitoring: isFieldRequired('monitoring') ? boolean() : boolean().optional(),
        monitoringLimited: boolean().optional(),
        monitoringPlus: boolean().optional(),
        reportLanguage: generateFieldSchema({ fieldName: 'reportLanguage' }),
        referenceNumber: generateFieldSchema({ fieldName: 'referenceNumber' }),
        express: isFieldRequired('express') ? boolean() : boolean().optional(),
        additionalInfos: generateFieldSchema({ fieldName: 'additionalInfos' }),
    }),
))

const { handleSubmit, errors, isSubmitting, controlledValues, resetForm } = useForm({ 
    validationSchema: formValidationSchema 
})

const isValid = useIsFormValid()

watch(isSubmitting, () => {
    if (!isValid.value) {
        useScrollToFormError(errors.value)
    }
})

const onSubmit = handleSubmit(async() => {
    if (isSearchForm.value) {
        handleSearchSubmit()
    } else {
        await handleOrderSubmit()
    }
})

function isDAL(country: string) {
    return country=== 'DE' || country === 'AT' || country === 'LU'
}

function isCH(country: string) {
    return country === 'CH' 
}

const submitButtonDisabled = computed(() => {
    if (isSubmitting.value) return true

    if (!isValid.value) return true

    let isAtleastOneFieldFilled = false
    for (const key in controlledValues.value) {
        const keyWithType = key as keyof typeof controlledValues.value
        const value = controlledValues.value[keyWithType]
        if (key === 'country' || key === 'registerArt' || key === 'rechtsform') continue 
        if (value !== undefined && value !== null && value !== '') {
            isAtleastOneFieldFilled = true
        }
    }
    return !isAtleastOneFieldFilled
})

const submitButtonText = computed(() => {
    return isSearchForm.value ? t('advancedSearch.submit') : t('report.orderForm.label.buyReportNow')
})
const submitButtonDataQA = computed(() => {
    if (props.isOrderView) return 'orderForm-submitButton'
    return isSearchForm.value ? 'extendedSearch_button' : 'advancedSearch-buyReportNow' 
})

const showTranslationCostNotice = computed(() => {
    return !isDAL(country.value) && reportLanguage.value !== 'DE'
})

const searchPrefCountries = computed(() => props.searchPreferences ? Object.keys(props.searchPreferences.countries) : null)
const isBigNet = computed(() => {
    return searchPrefCountries.value?.includes(country.value)
})

function generateFieldSchema({ fieldName, initialSchema = REQUIRED_STRING_VALIDATION(t), minLength, maxLength, regex, regexMessage }: { fieldName: string; initialSchema?: ZodString; minLength?: number; maxLength?: number; regex?: RegExp; regexMessage?: string}) {
    const validationConfig = mergedValidationConfig.value.find((config) => config.fieldName === fieldName)
    let schema = initialSchema
        
    if (validationConfig?.minLength || minLength) {
        schema = schema.min(validationConfig.minLength || minLength, { message: t('errors.minLength', { min: validationConfig.minLength || minLength }) })
    }
    if (validationConfig?.maxLength || maxLength) {
        schema = schema.max(validationConfig.maxLength || maxLength, { message: t('errors.maxLength', { max: validationConfig.maxLength || maxLength }) })
    }

    if (regex) {
        schema = schema.regex(regex, { message: regexMessage ?? t('errors.invalid') })
    }

    if (validationConfig?.mandatory) {
        schema = schema.min(1, t('errors.required'))
    } else {
        return schema.optional().or(literal(''))
    }
    
    return schema
}

function isFieldRequired(fieldName: string) {
    const validationConfig = mergedValidationConfig.value.find((config) => config.fieldName === fieldName)
    return validationConfig?.mandatory
}

function generateBusinessIdSchema() {
    let schema = generateFieldSchema({ fieldName: 'businessId', initialSchema: REQUIRED_STRING_VALIDATION(t).regex(/^[0-9]*$/, { message: t('errors.onlyNumbers') }) })

    if (!isDAL(country.value)) {
        schema = generateFieldSchema({ fieldName: 'businessId', initialSchema: REQUIRED_STRING_VALIDATION(t).regex(/(^[0-9]{0,4})([a-zA-Z0-9]{0,10})/, { message: t('errors.invalid') }) })
    }
    return schema
}

function generateZipCodeSchema() {
    const defaultMaxLength = 10
    let schema = REQUIRED_STRING_VALIDATION(t).max(defaultMaxLength, { message: t('errors.maxLength', { max: defaultMaxLength }) })
    if (!isDAL(country.value) && !isCH(country.value)) {
        return generateFieldSchema({ fieldName: 'zipCode', initialSchema: schema.regex(REGEXES.zipCode) })
    }

    const zipCodeLength = countryZipCodeLength(country.value)

    if (zipCodeLength) {
        schema = schema
            .min(zipCodeLength, { message: t('errors.lengthZipCode', { country: t(`enums.countries.${country.value}`) , length: zipCodeLength }) })
            .max(zipCodeLength, { message: t('errors.lengthZipCode', { country: t(`enums.countries.${country.value}`) , length: zipCodeLength }) })
    } 

    return generateFieldSchema({ fieldName: 'zipCode', initialSchema: schema, regex: /^-?\d+(\.\d+)?$/, regexMessage: t('errors.lengthZipCode', { country: t(`enums.countries.${country.value}`) , length: zipCodeLength }) })
}

function generateHousenumberSchema() {
    let schema = generateFieldSchema({ fieldName: 'housenumber', initialSchema: REQUIRED_STRING_VALIDATION(t), regex: REGEXES.houseNumber })
    return schema.nullish()
}

function generateAreaNummerSchema() {
    return generateFieldSchema({ fieldName: 'areaNummer', initialSchema: REQUIRED_STRING_VALIDATION(t).regex(REGEXES.areaNummer, { message: t('errors.invalid') }) })
}

function generateSubscriberNummerSchema() {
    return generateFieldSchema({ fieldName: 'subscriberNummer', initialSchema: REQUIRED_STRING_VALIDATION(t).regex(REGEXES.phoneSubscriberNumberWithExtension, { message: t('errors.invalid') }) })
}

function generateInternetAddressSchema() {
    return generateFieldSchema({ fieldName: 'internetAddress', initialSchema: REQUIRED_STRING_VALIDATION(t).regex(REGEXES.internetAddress, { message: t('errors.invalid') }) })
}

function generateEmailaddressSchema() {
    return generateFieldSchema({ fieldName: 'eMailAddress', initialSchema: REQUIRED_STRING_VALIDATION(t).email({ message: t('errors.noValidEmail') }) })
}
function handleSearchSubmit() {
    const payload = createSearchPayload()
    Storage.set(FORM_DATA_STORAGE, payload)
    if (formElement.value) {
        formElement.value.submit()
    }
}

async function handleOrderSubmit() {
    const payload = createOrderPayload()
    Storage.set(FORM_DATA_ORDER_STORAGE, payload)
    try {
        await AdvancedSearchService.orderReport(payload)
        resetForm()
        nextTick(() => {
            isOrdered.value = true
            scrollTo('body')
        })
    } catch (error) {
        if (axios.isAxiosError(error)) {
            errorAlert.value = showError({ topic: AdvancedSearchService.CONSTS.TOPIC, func: 'orderReport', xhr: error })
        }
    }
}

function prefillFieldsWithStorage() {
    const data = Storage.get(FORM_DATA_STORAGE)

    if (!data) return
    businessName.value = data.businessName
    businessId.value = data.businessId
    if (data.reportLanguage) {
        reportLanguage.value = data.reportLanguage
    }
    if (data.productType) {
        productType.value = data.productType
    }
    if (data.legitimateInterest) {
        legitimateInterest.value = data.legitimateInterest
    }
    monitoring.value = data.monitoring
    monitoringPlus.value = data.monitoringPlus
    express.value = data.express
    referenceNumber.value = data.referenceNumber

    if (data.postalAddress) {
        country.value = data.postalAddress.country
        zipCode.value = data.postalAddress.zipCode
        city.value = data.postalAddress.city
        street.value = data.postalAddress.street
        housenumber.value = data.postalAddress.houseNumber
    } else {
        country.value = data.country
        zipCode.value = data.zipCode
        city.value = data.city
        street.value = data.street
        housenumber.value = data.houseNumber
    }

    vatNumber.value = data.vatNumber
    areaNummer.value = data.areaNummer
    subscriberNummer.value = data.subscriberNummer
    internetAddress.value = data.internetAddress
    eMailAddress.value = data.eMailAddress
    rechtsform.value = data.rechtsform

    if (data.registerData) {
        registerArt.value = data.registerData.registerArt
        registerNummer.value = data.registerData.registerNummer
    } else {
        registerArt.value = data.registerArt
        registerNummer.value = data.registerNummer
    }
}

async function prefillWithPreselectedData() {
    if (!props.productAvailability) return
    const data = props.productAvailability

    if (typeof data.businessId === 'string') {
        businessId.value = data.businessId
    }
    if (typeof data.businessName === 'string') {
        businessName.value = data.businessName
    }

    if (data.postalAddress) {
        if (typeof data.postalAddress.country === 'string') {
            country.value =data.postalAddress.country
        }
        if (typeof data.postalAddress.zipCode === 'string') {
            zipCode.value = data.postalAddress.zipCode
        }
        if (typeof data.postalAddress.city === 'string') {
            city.value = data.postalAddress.city
        }
        if (typeof data.postalAddress.street === 'string') {
            street.value =data.postalAddress.street
        }
        if (typeof data.postalAddress.houseNumber === 'string') {
            housenumber.value = data.postalAddress.houseNumber
        }
        if (typeof data.postalAddress.houseNumberSuffix === 'string') {
            if (typeof housenumber.value === 'string' && housenumber.value.length) {
                housenumber.value = housenumber.value + data.postalAddress.houseNumberSuffix
            } else {
                housenumber.value = data.postalAddress.houseNumberSuffix
            }
        }
    }
    
    // adding additional data when comming from uds and clicks in error-message "order form"
    const orderStorage = Storage.get(FORM_DATA_ORDER_STORAGE)
    if (orderStorage) {
        monitoring.value = orderStorage.monitoring
        monitoringPlus.value = orderStorage.monitoringPlus
        referenceNumber.value = orderStorage.referenceNumber
        legitimateInterest.value = orderStorage.legitimateInterest
        reportLanguage.value = orderStorage.reportLanguage
        Storage.remove(FORM_DATA_ORDER_STORAGE)
    }
}

function onClickOrderAnotherReport() {
    isOrdered.value = false
}

function createOrderPayload() {
    const payload: CbraOrderReportCreateData = {
        businessName: businessName.value,
        legitimateInterest: legitimateInterest.value,
        reportLanguage: reportLanguage.value,
        productType: productType.value,
        monitoring: monitoring.value || false,
        monitoringPlus: monitoring.value && monitoringPlus.value ? monitoringPlus.value : false,
        express: isExpressDeliveryAvailable.value ? express.value : false,
        referenceNumber: referenceNumber.value || undefined,
        additionalInfos: additionalInfos.value || undefined,
        postalAddress: {
            country: country.value,
            zipCode: zipCode.value || undefined,
            city: city.value,
            street: street.value || undefined,
            houseNumber: housenumber.value ? housenumber.value.replace(/(^[0-9]{0,5})(.*)/, '$1') : undefined,
            houseNumberSuffix: housenumber.value ? housenumber.value.replace(/(^[0-9]{0,5})(.*)/, '$2').trim() : undefined
        }
    }

    if (monitoringLimited.value) {
        payload.monitoring = true
        payload.monitoringPlus = monitoringPlus.value
        payload.befristungEndeMonat = befristungEndeMonat.value
        payload.befristungEndeJahr = parseInt(befristungEndeJahr.value)
    }

    return payload
}

function createSearchPayload() {
    const payload: CbraAdvancedSearchCreateData = {
        businessName: businessName.value,
        businessId: businessId.value,
        areaNummer: areaNummer.value,
        subscriberNummer: subscriberNummer.value,
        internetAddress: internetAddress.value,
        eMailAddress: eMailAddress.value,
        rechtsform: rechtsform.value,
        vatNumber: vatNumber.value,
        registerData: {
            registerArt: registerArt.value,
            registerNummer: registerNummer.value
        },
        country: country.value,
        zipCode: zipCode.value,
        city: city.value,
        street: street.value,
        housenumber: housenumber.value ? housenumber.value.replace(/(^[0-9]{0,5})(.*)/, '$1') : undefined
    }

    return payload
}
onMounted(() => {
    if (route.query.clearStorage) {
        clearAdvancedSearchStorage()
    }
})
onMounted(checkPermissions)
onMounted(async()=> {
    if (props.productAvailability) {
        await prefillWithPreselectedData()
    } else {
        prefillFieldsWithStorage()
    }
})

function resetMonitoringLimited() {
    monitoringLimited.value = false
    befristungEndeMonat.value = defaultMonth
    befristungEndeJahr.value = isDAL(country.value) ? defaultYear : defaultYearNonDal
}

function setMonitoringLimited() {
    monitoring.value = false
    monitoringPlus.value = false
}

function setMonitoring() {
    resetMonitoringLimited()
}

function fieldExists(name: string) {
    if (!isSearchForm.value) return true
    return dynamicValidationConfig.value.some((config) => config.fieldName === name)
}

function checkPermissions() {
    if (props.isOrderView) {
        if ( differentContextActive() && !getReportAct4SbNumberSession()) {
            redirectToPermissionMemberNavigationPage()
            hasNoPermissions.value = true
        }

        const access = checkUserRight('report')
        if (!access.grants) {
            errorAlert.value = {
                id: 'report-not-granted',
                title: t('error.buyReport.buyWithoutGrants.title'),
                content: t('error.buyReport.buyWithoutGrants.description'),
                icon: 'error',
                dataQa: 'report-not-granted',
                onClose: () => window.location.href = '/',
                onConfirm: () => window.location.href = '/',
                confirmText: t('shared.modals.ok'),
            }
        } 
    } 
}

function redirectToPermissionMemberNavigationPage() {
    errorAlert.value = {
        id: 'advancedSearch-form-memberNavigation-dialog',
        title: t('error.memberNavigation.commercial.report.no.rights.headline'),
        content: t('error.memberNavigation.commercial.report.no.rights.description'),
        icon: 'info',
        dataQa: 'advancedSearch-form-memberNavigation-dialog',
        confirmText: t('shared.modals.ok'),
        onConfirm: () => window.history.back(),
        onClose: () => window.history.back(),
    }

    nextTick(() => {
        const textLinkCallbackArray = () => {
            removeMemberNavigationSession()
            window.location.reload()
        }
        const link = document.querySelector('#resetMemberNavigation')
        if (link) {
            link.addEventListener('click', textLinkCallbackArray)
        }
    })
}

function clearAdvancedSearchStorage() {
    Storage.remove(FORM_DATA_STORAGE)
}

</script>

<style scoped>  
.btn {
    margin-top: 30px !important;
}
</style>
