import { ROLES, hasUserRole, hasSuperUserRole, getCurrentUserCbra } from './user'
import { CbraUser } from '@/types/cbra/user'
import { AxiosError, AxiosResponse } from 'axios'
import axios from '@/services/tracing-axios'
import standardAxios from 'axios'
import { requestConfig } from '@/helper/requestConfig'
import i18n from '@/i18n'
const { t } = i18n.global
import { UserSearch } from '@/types/user-management/userSearch'
import { UserRights } from '@/types/cbra/userRights'
import { RoleAssignment } from '@/types/user-management/roleAssignment'
import { ModalProps } from '@/types/components/modal'
import { superUserOverlayNotAuthorized } from '@/helper/webtrekk/superuser'

export const getUserRights = () => {
    const userRights: UserRights = {
        'report': false,
        'debtCollection': false,
        'portfolioReport': false,
        'businessData': false,
        'membershipData': false,
        'memberstructureNavigation': false,
    }
    return userRights
}

/**
 * check if user is authorized
 */
export function checkUserRight(right: string) {
    const mappingTable = {
        'report': ROLES.AUSKUNFT,
        'debtCollection': ROLES.INKASSO,
        'portfolioReport': ROLES.PORTFOLIO_AUSKUNFT,
        'membershipData': ROLES.MITGLIEDSDATEN,
        'businessData': ROLES.UNTERNEHMENSDATEN,
        'memberstructureNavigation': ROLES.UNTERNEHMENSNAVIGATION
    }

    return { grants: hasUserRole(mappingTable[right as keyof typeof mappingTable ]) }
}

/**
 * checkAllUserRightsOfCurrentUser
 *
 * @return  {Promise<unknown>}
 */
export function checkAllUserRightsOfCurrentUser() {
    return new Promise((resolve) => {
        resolve({
            report: hasUserRole(ROLES.AUSKUNFT),
            debtCollection: hasUserRole(ROLES.INKASSO),
            portfolioReport: hasUserRole(ROLES.PORTFOLIO_AUSKUNFT),
            membershipData: hasUserRole(ROLES.MITGLIEDSDATEN),
            businessData: hasUserRole(ROLES.UNTERNEHMENSDATEN),
            memberstructureNavigation: hasUserRole(ROLES.UNTERNEHMENSNAVIGATION)
        })
    })
}

export async function checkSuperUserRole() {
    const response: AxiosResponse | AxiosError | undefined = await getCurrentUserCbra()
    const cbraUser: CbraUser | undefined = (typeof response !== 'undefined' && !standardAxios.isAxiosError(response)) ? response.data : undefined
    return hasSuperUserRole(cbraUser?.crMembernumber || '')
}

export async function getRoleAssignmentsFromUserManagement(user: CbraUser) {
    /**
     * Transformiert das Antwortformat der User Management API
     * in das bisherige Format der CBRA
     * @param {*} response
     */
    const transformResponseToGrants = (response: RoleAssignment[]) => {
        const grants = {
            'report': false, // CP.AUSKUNFT
            'portfolioReport': false, // CP.PORTFOLIO_AUSKUNFT
            'debtCollection': false, // CP.INKASSO
            'businessData': false, // CP.UNTERNEHMENSDATEN
            'membershipData': false, // CP.MITGLIEDSDATEN
            'memberstructureNavigation': false // CP.UNTERNEHMENSNAVIGATION
        }

        if (response && response.length) {
            let i

            for (i = 0; i < response.length; i++) {
                const role = response[i]
                if (role.applicationName === 'CP') {
                    if (role.roleName === 'AUSKUNFT') {
                        grants.report = role.active
                    } else if (role.roleName === 'PORTFOLIO_AUSKUNFT') {
                        grants.portfolioReport = role.active
                    } else if (role.roleName === 'INKASSO') {
                        grants.debtCollection = role.active
                    } else if (role.roleName === 'UNTERNEHMENSDATEN') {
                        grants.businessData = role.active
                    } else if (role.roleName === 'MITGLIEDSDATEN') {
                        grants.membershipData = role.active
                    } else if (role.roleName === 'UNTERNEHMENSNAVIGATION') {
                        grants.memberstructureNavigation = role.active
                    }
                }
            }
        }

        return grants
    }

    /**
     * Transformiert das Antwortformat der User Management API
     * in das bisherige Format der CBRA
     * @param {*} response
     */
    const transformResponseToRoles = (response: RoleAssignment[]) => {
        const result: string[] = []

        if (response && response.length) {
            let i
            for (i = 0; i < response.length; i++) {
                result.push(response[i].applicationName + '.' + response[i].roleName)
            }
        }

        return result
    }

    try {
        const response = await axios.get(`${import.meta.env.VITE_APP_USER_MGMT_API_BASEURL}/persons/${user.crId}/role-assignments`, requestConfig)
        user.grants = transformResponseToGrants(response.data)
        user.roles = transformResponseToRoles(response.data)
    } catch (error: any) {
        let translateKeyErrorTitle = 'error.userrights.roleassignment.failure'
        let translateKeyErrorContent = 'error.userrights.roleassignment.failure.description'
        if (error.response.status === 409) {
            translateKeyErrorTitle = 'error.userrights.roleassignment.conflict'
            translateKeyErrorContent = 'error.userrights.roleassignment.conflict.description'
        }

        superUserOverlayNotAuthorized('getGrants')
        user.grants = null
        const modal: ModalProps = {
            id: 'userrights-get-grants-roleassignment-failure',
            title: t(translateKeyErrorTitle),
            content: t(translateKeyErrorContent),
            icon: 'error',
            dataQa: 'userrights-get-grants-roleassignment-failure',
            confirmText: t('shared.modals.ok')
        }
        throw modal
    }
}

export async function setRoleAssignmentsInUserManagement(crId: string, userRights: UserRights) {
    const roles = [
        { applicationName: 'CP', roleName: 'AUSKUNFT', active: userRights.report },
        { applicationName: 'CP', roleName: 'PORTFOLIO_AUSKUNFT', active: userRights.portfolioReport },
        { applicationName: 'CP', roleName: 'INKASSO', active: userRights.debtCollection },
        { applicationName: 'CP', roleName: 'UNTERNEHMENSDATEN', active: userRights.businessData },
        { applicationName: 'CP', roleName: 'MITGLIEDSDATEN', active: userRights.membershipData },
        { applicationName: 'CP', roleName: 'UNTERNEHMENSNAVIGATION', active: userRights.memberstructureNavigation }
    ]

    return new Promise((resolve, reject) => {
        const checkAuthServerUrlExists = setInterval(function() {
            axios.put(`${import.meta.env.VITE_APP_USER_MGMT_API_BASEURL}/persons/${crId}/role-assignments`, roles, requestConfig)
                .then(response => {
                    resolve(response)
                })
                .catch((error) => {
                    if (error.status !== 401 && error.status !== 403) {
                        reject({
                            title: t('error.userrights.saveFailure'),
                            content: t('error.userrights.saveFailure.description'),
                            icon: 'error',
                            dataQa: 'userrights-set-grants-failure',
                            xhr: error
                        })
                    } else {
                        superUserOverlayNotAuthorized('setGrants')
                        reject({
                            title: t('error.userrights.saveUnauthorized'),
                            content: t('error.userrights.saveUnauthorized.description'),
                            icon: 'error',
                            dataQa: 'userrights-set-grants-not-authorized',
                            xhr: error
                        })
                    }
                })

            clearInterval(checkAuthServerUrlExists)
        }, 50)
    })
}

export async function getUsersOfMember(memberId: string) {
    /**
     * Transformiert das Antwortformat der User Management API
     * in das bisherige Format der CBRA
     */
    const transformResponse = (response: UserSearch) => {
        const result: CbraUser[] = []
        if (response.searchHits && response.searchHits.length) {
            let i

            for (i = 0; i < response.searchHits.length; i++) {
                const person = response.searchHits[i]
                result.push({
                    crId: person.crid || null,
                    crMembernumber: response.searchCriteria.mitgliedsnummer || '',
                    email: person.email || '',
                    givenName: person.givenName || '',
                    familyName: person.surName || '',
                    name: `${person.givenName} ${person.surName}` || '',
                    memberUserId: person.mitgliedskennungen[0] || '',
                    locked: person.locked || false,
                    roles: [''],
                    currentLoginDate: 0
                })
            }
        }

        return result
    }

    try {
        const reqData = {
            searchCriteria: {
                mitgliedsnummer: memberId
            }
        }

        const response = await axios.post(`${import.meta.env.VITE_APP_USER_MGMT_API_BASEURL}/persons/search`, reqData, requestConfig)

        return transformResponse(response.data)
    } catch (error: any) {
        superUserOverlayNotAuthorized('getMember')
        const modal: ModalProps = {
            id: 'userrights-get-users-failure',
            title: t('error.userrights.saveUnauthorized'),
            content: t('error.userrights.saveUnauthorized.description'),
            icon: 'error',
            dataQa: 'userrights-get-member-not-authorized'
        }
        return modal
    }
}
