<template>
    <div
        v-if="userStore.authenticated"
        :id="id"
        :data-page-id="id"
    >
        <Breadcrumb :home="true">
            <li>{{ dynamicTitle }}</li>
        </Breadcrumb>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>{{ dynamicTitle }}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="text-align--right">
                        {{ $t('advancedSearch.requiredInfo') }}
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="userPreferences && searchPreferences"
        >
            <SearchForm
                :user-preferences="userPreferences"
                :search-preferences="searchPreferences"
                @get-is-search-form="getIsSearchForm"
            />
        </div>
        <div class="content-spacer">
            <div class="bg-brand-light-grey box-spacing">
                <div class="container ">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="box-shadow-xy bg-white small-box-spacing">
                                <h3>{{ $t('advancedSearch.boxes.box1.header') }}</h3>
                                <p>
                                    {{ $t('advancedSearch.boxes.box1.description') }}
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="box-shadow-xy bg-white small-box-spacing  ">
                                <h3>
                                    {{ $t("advancedSearch.boxes.box2.header") }}
                                </h3>
                                <p>
                                    {{ $t("advancedSearch.boxes.box2.description") }}
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="box-shadow-xy bg-white small-box-spacing">
                                <h3>
                                    {{ $t("advancedSearch.boxes.box3.header") }}
                                </h3>
                                <p>
                                    {{ $t("advancedSearch.boxes.box3.description") }}
                                    <a
                                        :href="$t('advancedSearch.boxes.box3.linkUrl')"
                                    >
                                        {{ $t("advancedSearch.boxes.box3.linkText") }}
                                    </a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue'

import { useUserStore } from '@/stores/user'
import { computed, onMounted, ref } from 'vue'
import { getCbraSearchPreferences, getCbraUserPreferences } from '@/services'
import { CbraUserPreferences } from '@/types/cbra/userPreferences'
import SearchForm from '@/components/AdvancedSearch/SearchForm.vue'
import { useI18n } from 'vue-i18n'

const userStore = useUserStore()
const id = 'portal.mc.advanced-search'

const { t } = useI18n()

let isLoading = ref({
    userPreferences: true,
    searchPreferences: true,
})

const isSearchForm = ref(true)

let userPreferences = ref<CbraUserPreferences | undefined | null>(undefined)
let searchPreferences= ref<CbraSearchPreferences | undefined>(undefined)

const loadUserPreferences = async() => {
    isLoading.value.userPreferences = true

    try {
        const response = await getCbraUserPreferences()
        userPreferences.value = response.data
    } catch (error) {
        console.error(error)
    }
    isLoading.value.userPreferences = false
}

const loadSearchPreferences = async() => {
    try {
        isLoading.value.searchPreferences = true

        const response = await getCbraSearchPreferences()
        searchPreferences.value = response.data
    } catch (error) {
        console.error(error)
    }
    isLoading.value.searchPreferences = false
}

const getIsSearchForm = (isSearchFormValue: boolean) => {
    isSearchForm.value = isSearchFormValue
}

const dynamicTitle = computed(()=> {
    return isSearchForm.value ? t('advancedSearch.breadcrumb') : t('orderReport.breadcrumb')
})

onMounted(() => {
    loadUserPreferences()
    loadSearchPreferences()
})
</script>
