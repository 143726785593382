<template>
    <div 
        class="transactions__search_results mb-large"
    >
        <AnchorLinks
            v-if="transactionsStore.showTabs"
            id="transaction-anchor-links"
            :links="anchorLinks"
            :selected-link="transactionsStore.currentTabId"
            justify-content=""
            @change-selected="changeTab($event)"
        />

        <ExportLink v-if="transactionsStore.currentCount > 0" />

        <div class="bg-brand-light-grey">
            <div class="container">
                <div 
                    v-if="showSearchResults"    
                    class="searchresult"
                >
                    <CollectionFilter v-if="collectionFilterCond" />
                    <ReportFilter v-if="reportFilterCond" />

                    <div 
                        v-if="transactionsStore.countUser === 0 && transactionsStore.isMember === false"
                        class="row searchresult__empty"
                    >
                        <div class="col-12">
                            Keine Vorgänge gefunden.
                        </div>
                    </div>
                    
                    <template v-else>
                        <ReportMassDelete v-if="transactionsStore.filterMode === CONSTS.TRANSACTION_TYPE.REPORT" />

                        <div class="row mb">
                            <div class="col-12">
                                <ResultsList v-if="transactionsStore.currentTabId === '1'" />
                                <ResultsList v-if="transactionsStore.currentTabId === '2'" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb">
                                <div
                                    class="pagination-wrapper"
                                >
                                    <Paginate
                                        :id="'overview-paginate'"
                                        v-model="currentPage"
                                        :page-count="totalPages"
                                        class="pagination"
                                        :click-handler="changePage"
                                        active-class="active pager-pagingCurrent-desktop"
                                    >
                                        <template #prevBtnText>
                                            <i
                                                :id="'prev-page'"
                                                class="crefo-ui-icon icon-arrow-prev"
                                                data-qa="pager-pagingPrev-desktop"
                                            />
                                        </template>
                                        <template #nextBtnText>
                                            <i
                                                :id="'next-page'"
                                                class="crefo-ui-icon icon-arrow-next"
                                                data-qa="pager-pagingNext-desktop"
                                            />
                                        </template>
                                    </Paginate>
                                </div>
                                <SimpleDropdown
                                    v-model="transactionsStore.pager.pageSize"
                                    class="range-dropdown"
                                    data-qa="pager-selectPageSize"
                                    @select-option="selectRange"
                                />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, } from 'vue'

import AnchorLinks from '@/components/AnchorLinks.vue'
import CollectionFilter from '@/components/Transactions/CollectionFilter.vue'
import ExportLink from '@/components/Transactions/ExportLink.vue'
import ReportFilter from '@/components/Transactions/ReportFilter.vue'
import ReportMassDelete from '@/components/Transactions/ReportMassDelete.vue'
import ResultsList from '@/components/Transactions/ResultsList.vue'
import { VuePaginate as Paginate } from '@svifty7/vue-paginate'

import { CONSTS } from '@/helper/services/transaction'

import { useTransactionsStore } from '@/stores/transactions'
const transactionsStore = useTransactionsStore()
const { pager } = storeToRefs(transactionsStore)

import { useI18n } from 'vue-i18n'
import SimpleDropdown from '../Input/SimpleDropdown.vue'
import { storeToRefs } from 'pinia'
const { t } = useI18n()

type AnchorLink = {
    id: string
    html: string
    dataQa?: string
    show?: boolean
    viewScope?: string
}

const collectionFilterCond = computed(() => {
    return (transactionsStore.filterMode === CONSTS.TRANSACTION_TYPE.COLLECTION && transactionsStore.hasNoResults && transactionsStore.listFilter?.activeCollectionFilters.value.length > 0) || (transactionsStore.filterMode === CONSTS.TRANSACTION_TYPE.COLLECTION && !transactionsStore.hasNoResults)
})

const reportFilterCond = computed(() => {
    return (transactionsStore.filterMode === CONSTS.TRANSACTION_TYPE.REPORT && transactionsStore.hasNoResults && transactionsStore.listFilter?.activeReportFilters.value.length > 0) || (transactionsStore.filterMode === CONSTS.TRANSACTION_TYPE.REPORT && !transactionsStore.hasNoResults)
})

const anchor2Text = computed(() => {
    if (transactionsStore.hasRights.portfolioReport) {
        if (transactionsStore.isDifferentContext) {
            return t('transaction.list.differentContextOwnTransactions')
        } 
        if (!transactionsStore.isDifferentContext) {
            return t('transaction.list.ownTransactions')
        }
        if (transactionsStore.countMember === transactionsStore.countUser) { 
            return t('transaction.list.ownTransactions')
        }
    }
    
    if (!transactionsStore.hasRights.portfolioReport) {
        return t('transaction.list.ownTransactions')
    }
    return ''
})

const anchorLinks: AnchorLink[] = computed(() => [
    {
        id: '1',
        html: `<span>${t(transactionsStore.isDifferentContext ? 'transaction.list.differentContextAllTransactions' : 'transaction.list.allTransactions')}</span> <span style="display:${transactionsStore.countMember > 0 ? 'initial' : 'none'}" data-qa="businessResultSearchHits-desktop">(${transactionsStore.countMember})</span>`,
        dataQa: 'businessResult-desktop',
        show: transactionsStore.hasRights.portfolioReport && transactionsStore.countMember > transactionsStore.countUser, // So steht's im TYPO3. ich frage mich: warum nicht einfach > 0?
        viewScope: 'MEMBER'
    },
    {
        id: '2',
        html: `<span>${anchor2Text.value}</span> <span style="display:${transactionsStore.countUser > 0 ? 'initial' : 'none'}" data-qa="websiteResultSearchHits-desktop">(${transactionsStore.countUser})</span>`,
        dataQa: 'websiteResult-desktop',
        viewScope: 'USER'
        // show: transactionsStore.countUser > 0
    }
])

const currentPage = ref(transactionsStore.pager.pageIndex + 1)
watch(pager, (newVal) => {
    currentPage.value = newVal.pageIndex + 1
}, { deep: true })

const totalPages = computed(() => {
    return Math.ceil(transactionsStore.pager.totalCount / transactionsStore.pager.pageSize)
})

const showSearchResults = computed(() => {
    return !transactionsStore.hasNoResults && 
        !transactionsStore.loading || 
        (
            transactionsStore.hasNoResults && 
            transactionsStore.hasActiveFilters
        ) && 
        !transactionsStore.loading
})

const changeTab = (tab: AnchorLink) => {
    transactionsStore.currentTabId = tab.id
    
    if (tab.viewScope === 'MEMBER') {
        transactionsStore.isMember = true
    } else {
        transactionsStore.isMember = false
    }
}

const changePage = (newPage: number) => {
    transactionsStore.pager.pageIndex = newPage - 1
}

function selectRange(range: number) {
    transactionsStore.pager.pageSize = range
    transactionsStore.pager.pageIndex = 0
    currentPage.value = 1
}

</script>

<style>
.transactions__search_results {
    padding-top: 2rem;
}

@media screen and (min-width: 992px){
    .transactions__search_results {
    padding-top: 0
}
}

.searchresult__empty > .col-12 {
    justify-content: center;
    display: flex;
    padding: 60px;
}

.range-dropdown {
    position: relative;
    text-align: center;
}

@media screen and (min-width: 767px){
.range-dropdown {
    position: absolute;
    right: 22px;
    top: 25px;
}
}
</style>
