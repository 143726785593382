<template>
    <div class="crefo-input">
        <div 
            class="input-group" 
            :class="{ 
                'has-error': !!errorMessage, 
                'is-valid': meta.valid && !!inputValue && !noValidClass
            }"
        >
            <label
                :for="name"
                class="input-label"
                :class="{ 'no-margin': (!label || label === '') && !marginOnEmptyLabel }"
                :style="label ? '' : 'opacity:0;'"
            >{{ label }}<span v-if="required"> *</span></label>
            <div class="input-wrapper">
                <input
                    :id="name"
                    :name="name"
                    :type="type"
                    :value="inputValue"
                    :placeholder="placeholder"
                    :readonly="readonly"
                    :disabled="disabled"
                    :data-qa="dataQa"
                    data-no-crefo-ui="true"
                    :autocomplete="autocomplete"
                    :class="highlight ? 'highlight' : ''"
                    @input="handleChange"
                    @blur="handleBlur"
                >
                <i
                    class="crefo-ui-icon icon-check icon-color-blue"
                    aria-hidden="true"
                />
            </div>
            <div
                v-if="helpText"
                class="help-text"
            >
                {{ helpText }}
            </div>

            <p
                v-if="errorMessage"
                class="error-msg"
                :data-qa="errorMsgDataQaAttr"
            >
                {{ errorMessage }}
            </p>
        </div>
    </div>
</template>

<script setup lang='ts'>
import { toRef, PropType } from 'vue'
import { useField } from 'vee-validate'

const props = defineProps({
    type: {
        type: String as PropType<'text' | 'number' | 'email' | 'tel' | 'password'>,
        default: 'text'
    },
    modelValue: {
        type: [String, Number] as PropType<string | number | null | undefined>,
        default: undefined
    },
    name: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: false,
        default: undefined
    },
    helpText: {
        type: String,
        required: false,
        default: undefined
    },
    placeholder: {
        type: String,
        default: ''
    },
    dataQa: {
        type: String,
        default: ''
    },
    errorMsgDataQa: {
        type: String,
        default: ''
    },
    readonly: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    noValidClass: {
        type: Boolean,
        default: false
    },
    marginOnEmptyLabel: {
        type: Boolean,
        default: false
    },
    autocomplete: {
        type: String,
        default: 'on'
    },
    highlight: {
        type: Boolean,
        default: false
    }
})

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(name, undefined, {
    initialValue: props.modelValue,
    syncVModel: true
})

const errorMsgDataQaAttr = props.errorMsgDataQa || (props.dataQa ? `${props.dataQa}-message` : '')
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[readonly],
input[disabled] {
    cursor: not-allowed !important;
    background-color: rgb(248, 248, 248) !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.has-error {
    .input-label {
        color: var(--color-error);
    }
}

.no-margin {
    margin: 0 !important;
}

.help-text {
    font-size: 12px;
    color: var(--color-c-grey2);
    margin-top: 5px;
}

.highlight {
    color: #006eb7 !important;
    font-weight: 700 !important;
}
</style>
