<template>
    <div 
        :id="id"
        :data-page-id="id"
    > 
        <Breadcrumb :home="true">
            <li>{{ $t('impressum.breadcrumb') }}</li>
        </Breadcrumb>
        <div class="container mb">
            <div class="row">
                <div class="col-12">
                    <h1 class="mb h1">
                        {{ $t('impressum.title') }}
                    </h1>
                    <template v-if="$i18n.locale !== 'de-AT'">
                        <h3 class="h3">
                            {{ $t('impressum.providerPublisher') }}
                        </h3>
                        <p>{{ $t('impressum.internetOffer') }}</p>
                        <span>{{ $t('impressum.associationCreditreform') }}</span>
                        <br>
                        <span>{{ $t('impressum.address') }}</span>
                        <br>
                        <span>{{ $t('impressum.zipCity') }}</span>
                        <br>
                        <span>{{ $t('impressum.contact') }}</span>
                        <br>
                        <p>{{ $t('impressum.fax') }}</p>
                        <br>
                        <a :href="'mailto:' + $t('impressum.email')">{{ $t('impressum.email') }}</a>
                        <br>
                        <a :href="'/'">{{ $t('impressum.internet') }}</a>
                        <p class="h2 mt">
                            {{ $t('impressum.authorizedRepresentatives') }}
                        </p>
                        <p>{{ $t('impressum.authorizedRepresentativesInfo') }}</p>
                        <span>{{ $t('impressum.president') }}</span>
                        <ul style="list-style-type: none;">
                            <li>{{ $t('impressum.boardMembers.person1') }}</li>
                            <li>{{ $t('impressum.boardMembers.person2') }}</li>
                            <li>{{ $t('impressum.boardMembers.person3') }}</li>
                        </ul>

                        <span>{{ $t('impressum.registryNeuss') }}</span>
                        <br>
                        <span>{{ $t('impressum.registryNumber') }}</span>
                        <p>{{ $t('impressum.vatId') }}</p>
                    </template>
                    <template v-else>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div v-html="$t('impressum.all_at')" />
                    </template>
                </div>
            </div>
        </div>
        <Contact />
    </div>
</template>

<script setup>
import Breadcrumb from '@/components/Breadcrumb.vue'
import Contact from '@/components/Teaser/Contact.vue'

const id = 'portal.mc.impressum'
</script>
