import { sendClick } from '../webtrekk'

export const PAGE_FUNCTIONS = {
    TRANSACTIONS_LIST: 'transactions_list',
    TRANSACTIONS_LIST_EXPORT: 'transactions_list_export'
}

/*
 * TransactionsTrekkService
 */
export function placeNewTransactionsListRequest(
    query: string,
    filterMode: string,
    sortMode: string,
    filterParameters: any,
    page: number,
    isExport: boolean = false,
    isError: boolean | number = false
) {
    let requestParameterIds = ''

    if (typeof query === 'string' && query.length > 0) {
        requestParameterIds = requestParameterIds.concat('|Query|')
    }
    if (typeof filterMode === 'string') {
        switch (filterMode) {
        case 'ALL':
            requestParameterIds = requestParameterIds.concat('|Filter0|')
            break
        case 'FAVOURITES':
            requestParameterIds = requestParameterIds.concat('|Filter1|')
            break
        case 'REPORT':
            requestParameterIds = requestParameterIds.concat('|Filter2|')
            break
        case 'COLLECTION':
            requestParameterIds = requestParameterIds.concat('|Filter3|')
            break
        case 'CONSUMER':
            requestParameterIds = requestParameterIds.concat('|Filter4|')
            break
        }
    }
    if (typeof sortMode === 'string') {
        switch (sortMode) {
        case 'CREATION_TIME_ASC':
            requestParameterIds = requestParameterIds.concat('|Sort0|')
            break
        case 'CREATION_TIME_DESC':
            requestParameterIds = requestParameterIds.concat('|Sort1|')
            break
        case 'MODIFICATION_TIME_ASC':
            requestParameterIds = requestParameterIds.concat('|Sort2|')
            break
        case 'MODIFICATION_TIME_DESC':
            requestParameterIds = requestParameterIds.concat('|Sort3|')
            break
        case 'OBSERVATION_END_TIME_ASC':
            requestParameterIds = requestParameterIds.concat('|Sort4|')
            break
        case 'OBSERVATION_END_TIME_DESC':
            requestParameterIds = requestParameterIds.concat('|Sort5|')
            break
        }
    }
    if (typeof filterParameters === 'object' && filterParameters !== null) {
        if (typeof filterParameters['modificationDateFrom'] === 'string') {
            switch (filterParameters['modificationDateFrom']) {
            case 'ALL':
                break
            case 'LAST_30_DAYS':
                requestParameterIds = requestParameterIds.concat('|MTF0|')
                break
            case 'LAST_60_DAYS':
                requestParameterIds = requestParameterIds.concat('|MTF1|')
                break
            case 'LAST_90_DAYS':
                requestParameterIds = requestParameterIds.concat('|MTF2|')
                break
            case 'CURRENT_YEAR':
                requestParameterIds = requestParameterIds.concat('|MTF3|')
                break
            case 'PREVIOUS_YEAR':
                requestParameterIds = requestParameterIds.concat('|MTF4|')
                break
            default:
                requestParameterIds = requestParameterIds.concat('|MTFF|')
                break
            }
        }
        if (typeof filterParameters['creationDateFrom'] === 'string') {
            switch (filterParameters['creationDateFrom']) {
            case 'ALL':
                break
            case 'LAST_30_DAYS':
                requestParameterIds = requestParameterIds.concat('|CTF0|')
                break
            case 'LAST_60_DAYS':
                requestParameterIds = requestParameterIds.concat('|CTF1|')
                break
            case 'LAST_90_DAYS':
                requestParameterIds = requestParameterIds.concat('|CTF2|')
                break
            case 'CURRENT_YEAR':
                requestParameterIds = requestParameterIds.concat('|CTF3|')
                break
            case 'PREVIOUS_YEAR':
                requestParameterIds = requestParameterIds.concat('|CTF4|')
                break
            default:
                requestParameterIds = requestParameterIds.concat('|CTFF|')
                break
            }
        }
        if (typeof filterParameters['invoiceDateFrom'] === 'string') {
            switch (filterParameters['invoiceDateFrom']) {
            case 'ALL':
                break
            case 'LAST_30_DAYS':
                requestParameterIds = requestParameterIds.concat('|ITF0|')
                break
            case 'LAST_60_DAYS':
                requestParameterIds = requestParameterIds.concat('|ITF1|')
                break
            case 'LAST_90_DAYS':
                requestParameterIds = requestParameterIds.concat('|ITF2|')
                break
            case 'CURRENT_YEAR':
                requestParameterIds = requestParameterIds.concat('|ITF3|')
                break
            case 'PREVIOUS_YEAR':
                requestParameterIds = requestParameterIds.concat('|ITF4|')
                break
            default:
                requestParameterIds = requestParameterIds.concat('|ITFF|')
                break
            }
        }
        if (typeof filterParameters['collectionProcedureStage'] === 'string') {
            switch (filterParameters['collectionProcedureStage']) {
            case 'DUNNING_SERVICE':
                requestParameterIds = requestParameterIds.concat('|CPS0|')
                break
            case 'PRE_LITIGATION':
                requestParameterIds = requestParameterIds.concat('|CPS1|')
                break
            case 'LITIGATION':
                requestParameterIds = requestParameterIds.concat('|CPS2|')
                break
            case 'POST_LITIGATION':
                requestParameterIds = requestParameterIds.concat('|CPS3|')
                break
            case 'MONITORING':
                requestParameterIds = requestParameterIds.concat('|CPS4|')
                break
            case 'FINISH':
                requestParameterIds = requestParameterIds.concat('|CPS5|')
                break
            }
        }
        if (typeof filterParameters['collectionFileClosed'] === 'boolean') {
            requestParameterIds = requestParameterIds.concat(filterParameters['collectionFileClosed'] ? '|CFC1|' : '|CFC0|')
        }
        if (typeof filterParameters['observationType'] === 'string') {
            switch (filterParameters['observationType']) {
            case 'ALL':
                requestParameterIds = requestParameterIds.concat('|ROT0|')
                break
            case 'ALL_OBSERVATION':
                requestParameterIds = requestParameterIds.concat('|ROT1|')
                break
            case 'WITHOUT_OBSERVATION':
                requestParameterIds = requestParameterIds.concat('|ROT2|')
                break
            case 'FREE_OBSERVATION':
                requestParameterIds = requestParameterIds.concat('|ROT3|')
                break
            case 'NON_FREE_OBSERVATION':
                requestParameterIds = requestParameterIds.concat('|ROT4|')
                break
            }
        }
        if (typeof filterParameters['observationExpirationType'] === 'string') {
            switch (filterParameters['observationExpirationType']) {
            case 'EXPIRED_OBSERVATIONS':
                requestParameterIds = requestParameterIds.concat('|ROET0|')
                break
            case 'EXPIRING_OBSERVATIONS':
                requestParameterIds = requestParameterIds.concat('|ROET1|')
                break
            }
        }
    }
    if (typeof page === 'number') {
        requestParameterIds = requestParameterIds.concat('|Page' + (page + 1).toString() + '|')
    } else {
        requestParameterIds = requestParameterIds.concat('|Page1|')
    }
    if (isError === true) {
        requestParameterIds = requestParameterIds.concat('|Error|')
    } else if (typeof isError === 'number') {
        requestParameterIds = requestParameterIds.concat('|ExportLimit|')
        requestParameterIds = requestParameterIds.concat('|ExportLimit:' + isError + '|')
    }

    sendClick(
        PAGE_FUNCTIONS.TRANSACTIONS_LIST + '_request',
        {
            6: isExport ? PAGE_FUNCTIONS.TRANSACTIONS_LIST_EXPORT : PAGE_FUNCTIONS.TRANSACTIONS_LIST,
            7: 'request',
            8: requestParameterIds
        }
    ) 
}