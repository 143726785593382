<template>
    <div
        v-if="cbraUser.crId"
        :id="id"
        :data-page-id="id"
    >
        <div class="container pt mb">
            <div class="row breadcrumb-container">
                <Breadcrumb
                    :home="true"
                    :no-container="true"
                >
                    <li>{{ $t('profile.breadcrumbTitle') }}</li>
                </Breadcrumb>
                <span
                    v-if="cbraUser.lastLoginDate"
                    class="selfcare__lastLogin col-6"
                >
                    {{ $t('profile.loggedInSince') }}: {{ formatDateTime(cbraUser.lastLoginDate) }}
                </span>
            </div>
        </div>

        <AnchorLinks
            v-if="loadedActiveTabs"
            :id="id + '.anchor-links'"
            :links="anchorLinks"
            :selected-link="selectedLink.id"
            @change-selected="changeTab($event)"
        />

        <div class="bg-brand-light-grey">
            <component
                :is="selectedLink.component"
                v-if="selectedLink.component"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import AnchorLinks from '@/components/AnchorLinks.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Settings from '@/components/Profile/Settings.vue'
import Memberdata from '@/components/Profile/Memberdata.vue'
import Businessdata from '@/components/Profile/Businessdata.vue'
import Administration from '@/components/Profile/Administration.vue'

import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { isSuperUser } from '@/helper/services/user'
import { scrollTo } from '@/helper/scrollto'
import { getCbraMemberStructure } from '@/services'
import { isSuperUserAdministrationAllowed } from '@/helper/services/memberNavigation'
import { placeSelfcareNavigationClick } from '@/helper/webtrekk/selfcare'

import { formatDateTime } from '@/filters'

type AnchorLink = {
    id: string
    t3Ids?: string[]
    i18nKey: string
    url?: string
    dataQa?: string
    show?: boolean
    component?: Object
}

const pageIdPrefix = 'portal.mc.profile'
const id = ref(pageIdPrefix + '.home')

const i18n = useI18n()
const { t } = i18n
const router = useRouter()
const userStore = useUserStore()
const { cbraUser } = storeToRefs(userStore)

let loadedActiveTabs = ref(false)
const anchorLinks: AnchorLink[] = [
    {
        id: 'unternehmensdaten',
        t3Ids: [
            'unternehmensdaten',
            'business%20data',
            'business data'
        ],
        i18nKey: 'profile.tabs.businessData',
        url: '/portal/mc/mein-profil/unternehmensdaten',
        dataQa: 'selfcare-tab-link--unternehmensdaten',
        component: Businessdata
    },
    {
        id: 'mitgliedsdaten',
        t3Ids: [
            'mitgliedsdaten',
            'member%20data',
            'member data'
        ],
        i18nKey: 'profile.tabs.memberData',
        url: '/portal/mc/mein-profil/mitgliedsdaten',
        dataQa: 'selfcare-tab-link--mitgliedsdaten',
        component: Memberdata
    },
    {
        id: 'administration',
        t3Ids: [
            'administration'
        ],
        i18nKey: 'profile.tabs.administration',
        url: '/portal/mc/mein-profil/administration',
        dataQa: 'selfcare-tab-link--administration',
        show: false,
        component: Administration
    },
    {
        id: 'einstellungen',
        i18nKey: 'profile.tabs.settings',
        url: '/portal/mc/mein-profil',
        dataQa: 'selfcare-tab-link--einstellungen',
        component: Settings
    }
]

let selectedLink = anchorLinks[anchorLinks.length - 1]

const setActiveTab = () => {
    const activeTab = anchorLinks.find((link: AnchorLink) => link.id === router.currentRoute?.value.params.section)
    if (activeTab) {
        selectedLink = activeTab
        id.value = pageIdPrefix + '.' + activeTab.id
    }
    if (location.hash && !location.hash.startsWith('#state')) {
        setTimeout(() => {
            scrollTo(location.hash.split('&')[0]) // strip keycloak params
        }, 500)
    }
    placeSelfcareNavigationClick(t(selectedLink.i18nKey))
}

watch(() => router.currentRoute?.value.params.section, () => {
    setActiveTab()
})

const redirectToSettingsIfRequired = () => {
    if (router.currentRoute?.value.params.section === 'administration') {
        router.push({ name: 'profile', params: { section: 'einstellungen' } })
    }
}

onMounted(async() => {
    if (isSuperUser()) {
        const memberStructure = await getCbraMemberStructure()
        if (memberStructure.status === 200) {
            if (isSuperUserAdministrationAllowed(memberStructure.data)) {
                anchorLinks.find(link => link.id === 'administration')!.show = true
            } else {
                redirectToSettingsIfRequired()
            }
        } else {
            redirectToSettingsIfRequired()
        }
        loadedActiveTabs.value = true
    } else {
        redirectToSettingsIfRequired()
        loadedActiveTabs.value = true
    }
    setActiveTab()

    // Redirects from TYPO3 include TYPO3 Query Parameters.
    // Since Nginx rewrite rules can not nicely work with
    // query parameters, we need to handle them here.
    if (router.currentRoute?.value.query.area) {
        const routeToGo = anchorLinks.find((link: AnchorLink) => link.t3Ids?.includes(router.currentRoute?.value.query.area))
        routeToGo && changeTab(routeToGo)
    }
})

const changeTab = (link: AnchorLink) => {
    if (link?.url && link.url.startsWith('/portal')) {
        selectedLink = link
        id.value = pageIdPrefix + '.' + link.id
        router.push(link.url)
    }
}
</script>

<style scoped>
.selfcare__lastLogin {
    text-align: right;
    padding: 0.625rem 0 0 0;
    font-size: 12px;
    color: #666;
}

.breadcrumb-container {
    justify-content: space-between;
    margin: 0px;
}
</style>

<style scoped lang="less">
:deep(.chapter-links-box) {
    position: sticky;
    top: 62px;
}
</style>
