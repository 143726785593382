import { AxiosError } from 'axios'
import i18n from '@/i18n'
import { ModalProps } from '@/types/components/modal'
import { handleAxiosErrorReason } from '../errormessage'
const { t } = i18n.global

/**
 * Error handling.
 *
 * Error message is build with the languageKeys of:
 *
 *   headline = "error.{errorKey}
 *   description = "error.{errorKey}.description
 *
 *     (When no translation is found for headline, then default errorKey = 500 is used)
 *
 * The errorKey is build like that:
 *
 *   If translation is available for "error.{error.xhr.responseText.askStatus.statusElements[0].statusCode}_{statusHint}
 *     then, errorKey = {error.xhr.responseText.askStatus.statusElements[0].statusCode}_{statusHint}
 *       You can give an array of statusHints in the error object. The first text of statusHints that is found inside
 *       the response statusHint text is used (see ASK_10104_Land_nicht_für_Auskunftsabrufe_zugelassen).
 *
 *   If translation is available for "error.{error.xhr.responseText.askStatus.statusElements[0].statusCode}"
 *     then, errorKey = {error.xhr.responseText.askStatus.statusElements[0].statusCode}
 *
 *   If translation is available for "error.{error.topic}.{error.func}"
 *     then, errorKey = {error.topic}.{error.func}
 *
 *   If none of the two translations are available, then it falls back to
 *      then, errorKey = {error.xhr.status}
 */

const CONSTS = {
        ENVIROMENTS: {
            DEVELOPMENT: 'dev',
            INTEGRATION: 'integration',
            LIVE: 'live'
        },
        ERROR_TYPES: {
            // translation keys
            TIMEOUT: 202,
            DEFAULT: 500,
            AJAX: 403
        },
        HTTP_STATUS: {
            ACCEPTED: 202, //used for timeout or something
            INTERNAL_SERVER_ERROR: 500
        },
        ERROR_PRIORITY: {
            FATAL: 'FATAL',
            ERROR: 'ERROR',
            WARN: 'WARN',
            INFO: 'INFO',
            DEBUG: 'DEBUG'
        },
        ERROR_PREFIX: 'error.',
        ERROR_DESCRIPTION_SUFFIX: '.description'
    },
    ENV = window.cp?.config?.stage || ''
const defaultConfig = {
    headline: t(CONSTS.ERROR_PREFIX + CONSTS.ERROR_TYPES.DEFAULT),
    description: t(CONSTS.ERROR_PREFIX + CONSTS.ERROR_TYPES.DEFAULT + CONSTS.ERROR_DESCRIPTION_SUFFIX)
}

type ErrorServiceError = {
    topic?: string
    xhr: AxiosError<AxiosErrorData, any>
    func?: string
}

type AxiosErrorData = {
    dataQaAttribute?: string
    askStatus?: {
        statusElements?: Array<{
            statusCode?: number
            statusHint?: string
        }>
        statusHint?: string
    }
}

export function showError(error: ErrorServiceError, modalOptsOverride = null) {
    if (typeof error !== 'object' || error === null) {
        return
    }
    if (typeof error.xhr === 'object' && error.xhr !== null) {
        if (error.xhr.response?.status === 0) {
            if (['', 'error', 'abort', 'canceled'].includes(error.xhr.response?.statusText)) {
                return
            }
        }
    }

    switch (ENV) {
    case CONSTS.ENVIROMENTS.DEVELOPMENT:
    case CONSTS.ENVIROMENTS.INTEGRATION:
        return showModalError(error, modalOptsOverride)
    default:
        return showModalError(error, modalOptsOverride)
    }
}

export function showModalError(error: ErrorServiceError, modalOptsOverride = null) {
    if (typeof error.topic !== 'string') {
        error.topic = ''
    }
    if (typeof error.func !== 'string') {
        error.func = ''
    }

    let errorContext: ErrorContext | undefined = undefined

    if (typeof error.xhr === 'object' && error.xhr !== null) {
        errorContext = handleAxiosErrorReason(error.xhr)
    }

    let modalOpts: ModalProps | null = null
    if (typeof modalOptsOverride === 'object' && modalOptsOverride !== null) {
        modalOpts = modalOptsOverride
    } else {
        const errorConfig = getErrorMessages(getErrorKey(error))
        modalOpts = {
            id: 'error-modal',
            title: errorConfig.headline,
            content: errorConfig.description,
            icon: 'error',
            confirmText: t('shared.modals.ok'),
            errorContext
        }
    }

    if (error.xhr.response?.data.dataQaAttribute && error.xhr?.response.data.dataQaAttribute?.length) {
        modalOpts.dataQa = error.xhr.response?.data.dataQaAttribute || 'error-modal'
    }

    return modalOpts

    // TODO: Add tracking (also see comment further up)
    /*errorWebTrekkService.placeNewErrorMessage(
        correlationId,
        statusCode,
        requestUrl,
        modalOpts.title,
        this._getXhrResponseData(error.xhr)
    )*/
}

export function getErrorMessages(key: string) {
    const headline = t(CONSTS.ERROR_PREFIX + key)
    if (headline === ' ') {
        return defaultErrorMessage()
    }
    let description = t(CONSTS.ERROR_PREFIX + key + CONSTS.ERROR_DESCRIPTION_SUFFIX)
    if (description === ' ') {
        description = ''
    }

    return {
        headline: headline,
        description: description
    }
}

function defaultErrorMessage() {
    return defaultConfig
}

function getErrorKey(error: ErrorServiceError) {
    const key = error.xhr.response?.status
    // get specific ask error translations
    const askErrorKey = getASKErrorKey(error)
    if (askErrorKey) {
        return askErrorKey
    }
    // get service function error translation
    const ajaxFuncErrorKey = error.topic + '.' + error.func
    const headline = t(CONSTS.ERROR_PREFIX + ajaxFuncErrorKey)
    // Wenn Übersetzung gefunden wird
    if (headline !== (CONSTS.ERROR_PREFIX + ajaxFuncErrorKey)) {
        return ajaxFuncErrorKey
    }
    return key ? key.toString() : ''
}

export function getASKErrorKey(error: ErrorServiceError) {
    const askStatus = getASKStatusElement(error)
    let errorKey: string | null = null

    if (askStatus !== null) {
        if (askStatus.statusHint.length) {
            const translationPrefix = CONSTS.ERROR_PREFIX + askStatus.statusCode
            const hintKey = t(translationPrefix)
            const search = hintKey.substr(translationPrefix.length).split('_').join(' ')
            if (errorKey === null && askStatus.statusHint.includes(search)) {
                const translationHint = t(hintKey)
                if (translationHint !== hintKey) {
                    errorKey = hintKey.substr(CONSTS.ERROR_PREFIX.length)
                }
            }
        }

        if (errorKey === null) {
            const translation = t(CONSTS.ERROR_PREFIX + askStatus.statusCode)
            if (translation !== (CONSTS.ERROR_PREFIX + askStatus.statusCode)) {
                errorKey = askStatus.statusCode
            }
        }
    }

    return errorKey
}

function getASKStatusElement(error: ErrorServiceError) {
    const crefoResponse = error.xhr.response?.data as any
    if (crefoResponse && typeof crefoResponse.askStatus === 'object') {
        if (Array.isArray(crefoResponse.askStatus.statusElements) && crefoResponse.askStatus.statusElements.length > 0) {
            const statusElement = crefoResponse.askStatus.statusElements[0]
            if (typeof crefoResponse.askStatus.statusHint === 'string') {
                statusElement.statusHint = crefoResponse.askStatus.statusHint
            } else {
                statusElement.statusHint = ''
            }
            return statusElement
        }
    }
    return null
}
