import { CbraReportHistoryData } from '@/types/cbra/reports'
import { ModalProps } from '@/types/components/modal'
import { SelectOption } from '@/types/components/select'
import dayjs from 'dayjs'
import { ComposerTranslation } from 'vue-i18n'

const helpers = {
    getMonitoringLimitedYearOptions() {
        let time = dayjs().format('YYYY')
        const yearOptions: SelectOption[] = []
        for (let i = 0; i < 11; i++) {
            yearOptions.push({ label: `${time}`, code: `${time}` })
            time = dayjs(time).add(1, 'year').format('YYYY')
        }
        return yearOptions
    },
    isModalProps(modal: any): modal is ModalProps {
        return (typeof modal.id === 'string' && typeof modal.title === 'string' && typeof modal.content === 'string')
    },
    nl2br(str: string | string[], is_xhtml = false) {
        if (typeof str === 'undefined' || str === null) {
            return ''
        }

        if (str instanceof Array) {
            str = str.join('\n')
        }

        str = str.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;')

        str = str.replace(/\n/g, '<br />').replace(/\r/g, '<br />')
            .replace(/\\n/g, '<br />').replace(/\\r/g, '<br />')

        return str
    },
    getIdentnumber(identnumber: string) {
        // Test if identnumber is a String and if identnumber has a leading zero for DAL
        // nonDAl has a 4-digit in front like e.g. 1024 for switzerland with non leading zero
        if (typeof identnumber === 'string' && identnumber.charAt(0) === '0') {
            return identnumber.substring(4, identnumber.length)
        }
        return identnumber
    },
    sbb(t: ComposerTranslation, sbbName: string ) {
        let result = ''

        const numberTest = new RegExp('^[0-9]*$', 'g')

        if (numberTest.test(sbbName)) {
            const sbbNo = sbbName.substr(sbbName.length - 3, 3)
            result = `${t('shared.sachbearbeiter')} ${parseInt(sbbNo)}`
        } else {
            result = sbbName
        }
        return result
    },
    getReportHistoryReason(t: ComposerTranslation, reportHistoryData: CbraReportHistoryData) {
        const deliveryType = typeof reportHistoryData.deliveryType === 'string' ? reportHistoryData.deliveryType : ''
        const supplementReason = typeof reportHistoryData.supplementReason === 'string' ? reportHistoryData.supplementReason : ''

        switch (deliveryType) {
        case '':
            return t('watchlist.list.deliveryType.' + 'A000')
        case 'A001':
        case 'LLFAR-1':
            return t('watchlist.list.deliveryType.' + 'A001')
        case 'A002':
        case 'LLFAR-2':
            return t('watchlist.list.deliveryType.' + 'A002')
        case 'A003':
        case 'LLFAR-3':
            return t('watchlist.list.deliveryType.' + 'A003')
        case 'A004':
        case 'LLFAR-4':
            return t('watchlist.list.supplementReason.' + supplementReason)
        case 'A006':
        case 'LLFAR-6':
            return t('watchlist.list.deliveryType.' + 'A006')
        default:
            return ''
        }
    },
    businessIdToCrefoId(businessId: string) {
        if (typeof businessId !== 'string') {
            return businessId
        }
    
        if (businessId.length <= 10) {
            return businessId
        }
    
        return businessId.substr(4)
    },
    isDate(variable: any): boolean {
        return variable instanceof Date && !isNaN(variable.getTime())
    }
}

export default helpers