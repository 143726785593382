import axios, { AxiosInstance } from 'axios'
//@ts-ignore
import wrapAxios from 'zipkin-instrumentation-axiosjs'
import { Tracer, ExplicitContext } from 'zipkin'
import { hasKeycloakProperty } from '@/types/InterfaceGuards'

const createTracingAxios = () => {
    // Set default headers to prevent caching
    axios.defaults.headers.common = {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
    }

    const ctxImpl = new ExplicitContext() // the in-process context
    // very silent Recorder:
    const recorder = { record: () => { /* "do nothing" recorder */ } }
    const tracer = new Tracer({ ctxImpl, recorder })

    const localTracingAxios = wrapAxios(axios, { tracer }) as AxiosInstance

    localTracingAxios.interceptors.request.use((config) => {
        if (config.headers) {
            const actForMemberId = sessionStorage.getItem('actForMemberId')
            if (actForMemberId !== null) {
                config.headers = Object.assign(config.headers, { ['X-Act-For-Member-Id']: actForMemberId })
            } else {
                delete (config.headers as any)['X-Act-For-Member-Id']
            }

            if (hasKeycloakProperty(window) && window.keycloak.token) {
                config.headers = Object.assign(config.headers, { Authorization: `Bearer ${window.keycloak.token}` })
            } else {
                console.log('[axios] no central keycloak or without token')
            }
        }
        return config
    })

    return localTracingAxios
}

const tracingAxios = createTracingAxios()

export default tracingAxios
