const selectNullOption = {
    label: '',
    code: null,
    i18nKey: 'globalMisc.pleaseSelect'
}

const selectEmptyOption = {
    label: '',
    code: null,
    i18nKey: 'globalMisc.pleaseSelect'
}

// from: portal-frontend-releases/build/ux-prototype/webapp/public/js/utilities/constants.js
const CONSTANTS = {
    HIDE_OVERLAY_EVENT: 'HIDE_OVERLAY_EVENT',
    SHOW_OVERLAY_EVENT: 'SHOW_OVERLAY_EVENT',
    HIDE_LEGIT_OVERLAY_EVENT: 'HIDE_LEGIT_OVERLAY_EVENT',
    SHOW_LEGIT_OVERLAY_EVENT: 'SHOW_LEGIT_OVERLAY_EVENT',
    ADD_WIDGET_TO_DASHBOARD: 'ADD_WIDGET_TO_DASHBOARD',
    ADDED_WIDGET_TO_DASHBOARD: 'ADDED_WIDGET_TO_DASHBOARD',

    REMOVED_WIDGET_FROM_DASHBOARD: 'REMOVED_WIDGET_FROM_DASHBOARD',
    SAVE_DASHBOARD: 'SAVE_DASHBOARD',

    USER_COOKIES_DELETED: 'USER_COOKIES_DELETED',
    USER_SSO_BEFORE_LOGIN: 'USER_SSO_BEFORE_LOGIN',
    USER_SSO_LOGIN_CHECKED: 'USER_SSO_LOGIN_CHECKED',
    USER_SSO_LOGOUT: 'USER_SSO_LOGIN_LOGOUT',
    FEATURES_READY_TO_READ: 'FEATURES_READY_TO_READ',

    COOKIES: {
        INFOBOX_CLOSED: 'INFOBOX_CLOSED',
        WKV_OVERLAY_SHOWN: 'WKV_OVERLAY_SHOWN',
        MEMBERSTRUCTURE_LAST_UPDATE: 'MEMBERSTRUCTURE_LAST_UPDATE'
    },

    EVENTS: {
        SAVE_WIDGETS: 'SAVE_WIDGETS',
        OVERLAY_HIDDEN: 'OVERLAY_HIDDEN',
        UPDATE_WIDGETS_ON_DASHBOARD: 'UPDATE_WIDGETS_ON_DASHBOARD',
        REMOVE_WIDGET_FROM_DASHBOARD: 'REMOVE_WIDGET_FROM_DASHBOARD',
        SHOW_WIDGET_CATALOG: 'SHOW_WIDGET_CATALOG',
        CLOSE_CONTEXT_MENU: 'CLOSE_CONTEXT_MENU',
        BUSINESS_DATA_LOADED: 'BUSINESS_DATA_LOADED',
        UPDATE_WATCHLIST_ENTRIES_EVENT: 'updated_watchlist_entries',
        UPDATE_MESSAGES_COUNT_EVENT: 'updated_messages_count',
        AJAX_ERROR_403: 'ajax_error_403',
        NEW_ERROR_OCCURRED: 'new_error_occurred',
        SAVED_BUSINESS_ITEM: 'SAVED_BUSINESS_ITEM',
        RESENT_ADDING_TO_WATCHLIST_AS_SIGNAL_SUPPORTED: 'RESENT_ADDING_TO_WATCHLIST_AS_SIGNAL_SUPPORTED',
        TRANSACTION_DATA_PRODUCT_INFOS_LOADED: 'TRANSACTION_DATA_PRODUCT_INFOS_LOADED'
    },

    PB: {
        BUSINESS_DATA_BUSINESS_ID: 'BUSINESS_DATA_BUSINESS_ID',
        BUSINESS_DATA_PRODUCT_INFOS: 'BUSINESS_DATA_PRODUCT_INFOS',
        BUSINESS_DATA_QUICK_PRODUCTS: 'BUSINESS_DATA_QUICK_PRODUCTS',
        BUSINESS_DATA_HAS_DATA: 'BUSINESS_DATA_HAS_DATA',
        BUSINESS_DATA_TRANSACTION_REPORT_ID: 'BUSINESS_DATA_TRANSACTION_REPORT_ID',
        BUSINESS_DATA_RELATED_TRANSACTIONS: 'BUSINESS_DATA_RELATED_TRANSACTIONS',
        INKASSO_DATA_COLLECTION_ID: 'INKASSO_DATA_COLLECTION_ID',
        LAST_LOGIN_DATE: 'LAST_LOGIN_DATE',
        ADDING_TO_WATCHLIST_AS_SIGNAL_SUPPORTED: 'ADDING_TO_WATCHLIST_AS_SIGNAL_SUPPORTED',
        MEMBERSTRUCTURE_NAVIGATIONSELECTED: 'MEMBERSTRUCTURE_NAVIGATIONSELECTED',
        TRANSACTION_DATA_PRODUCT_INFOS: 'TRANSACTION_DATA_PRODUCT_INFOS'
    },

    LANGUAGE_OPTIONS: [
        { label: '', code: 'DE', i18nKey: 'enums.languageOptions.DE' },
        { label: '', code: 'EN', i18nKey: 'enums.languageOptions.EN' },
        { label: '', code: 'FR', i18nKey: 'enums.languageOptions.FR' },
        { label: '', code: 'IT', i18nKey: 'enums.languageOptions.IT' }
    ],

    NOTIFICATION_OPTIONS: [
        { label: '', code: 'ALL', i18nKey: 'enums.notificationOptions.ALL' },
        { label: '', code: 'IMPORTANT', i18nKey: 'enums.notificationOptions.IMPORTANT' },
        { label: '', code: 'NONE', i18nKey: 'enums.notificationOptions.NONE' } 
    ],

    NOTIFICATION_LANGUAGE_OPTIONS: [
        { label: '', code: 'de', i18nKey: 'enums.languageOptions.DE' },
        { label: '', code: 'en', i18nKey: 'enums.languageOptions.EN' },
        { label: '', code: 'fr', i18nKey: 'enums.languageOptions.FR' }
    ],

    REPORT_TYPES: [
        'TRAFFIC_LIGHT_REPORT',
        'BRIEF_REPORT',
        'COMPACT_REPORT',
        'COMMERCIAL_REPORT',
        'PREMIUM_REPORT',
        'COMPANY_REPORT_COMPACT',
        'COMPANY_REPORT_PREMIUM',
        'ESG_BASIS_REPORT',
        'RISIKO_CHECK',
        'CREFO_IDENT',
        'REPORT_WITHOUT_SUPPLEMENT',
        'COMMERCIAL_REPORT_MONITORING_WITHOUT_REPORT',
        'COMPANY_REPORT_PREMIUM_MONITORING_WITHOUT_REPORT'
    ],

    CONSUMER_REPORT_TYPES: [
        'CONSUMER_PLUS_WITHOUT_GEODATA'
    ],

    TRANSACTION_TYPES: {
        REPORT: 'REPORT',
        COLLECTION: 'COLLECTION',
        CONSUMER: 'CONSUMER',
        INVOLVEMENTDATA: 'INVOLVEMENTDATA'
    },

    LEGITIMATE_INTEREST_OPTIONS: [
        selectNullOption,
        { label: '', code: 'CREDIT_DECISION', i18nKey: 'enums.legitimateInterestOptions.CREDIT_DECISION' },
        { label: '', code: 'SOLVENCY_CHECK', i18nKey: 'enums.legitimateInterestOptions.SOLVENCY_CHECK' },
        { label: '', code: 'BUSINESS_INITIATION', i18nKey: 'enums.legitimateInterestOptions.BUSINESS_INITIATION' },
        { label: '', code: 'DEBT', i18nKey: 'enums.legitimateInterestOptions.DEBT' },
        { label: '', code: 'DELINQUENCIES', i18nKey: 'enums.legitimateInterestOptions.DELINQUENCIES' },
        { label: '', code: 'INSURANCE_CONTRACT', i18nKey: 'enums.legitimateInterestOptions.INSURANCE_CONTRACT' },
        { label: '', code: 'PARTICIPATIONS', i18nKey: 'enums.legitimateInterestOptions.PARTICIPATIONS' },
        { label: '', code: 'INFORMATION_ON_WRITS_OF_EXECUTION', i18nKey: 'enums.legitimateInterestOptions.INFORMATION_ON_WRITS_OF_EXECUTION' }
    ],

    LEGITIMATE_INTEREST_OPTIONS_PRIVATE: [
        selectEmptyOption,
        { label: '', code: 'CREDIT_DECISION', i18nKey: 'enums.legitimateInterestOptions.CREDIT_DECISION' },
        { label: '', code: 'SOLVENCY_CHECK', i18nKey: 'enums.legitimateInterestOptions.SOLVENCY_CHECK' },
        { label: '', code: 'BUSINESS_INITIATION', i18nKey: 'enums.legitimateInterestOptions.BUSINESS_INITIATION' },
        { label: '', code: 'DEBT', i18nKey: 'enums.legitimateInterestOptions.DEBT' },
        { label: '', code: 'INSURANCE_CONTRACT', i18nKey: 'enums.legitimateInterestOptions.INSURANCE_CONTRACT' },
        { label: '', code: 'PARTICIPATIONS', i18nKey: 'enums.legitimateInterestOptions.PARTICIPATIONS' },
        { label: '', code: 'DELINQUENCIES', i18nKey: 'enums.legitimateInterestOptions.DELINQUENCIES' },
        { label: '', code: 'INFORMATION_ON_WRITS_OF_EXECUTION', i18nKey: 'enums.legitimateInterestOptions.INFORMATION_ON_WRITS_OF_EXECUTION' },
        { label: '', code: 'CONDITIONS_INQUIRY', i18nKey: 'enums.legitimateInterestOptions.CONDITIONS_INQUIRY' }
    ],

    WKV_INSURANCES_AMOUNT: ['EUR_5000', 'EUR_10000', 'EUR_15000', 'EUR_20000'],
    WKV_INSURANCES_TIME: ['DAYS_30', 'DAYS_60', 'DAYS_90'],

    POSTAL_ADDRESS_TYPES: {
        DELIVERY_ADDRESS: 'DELIVERY_ADDRESS', 
        POST_OFFICE_BOX: 'POST_OFFICE_BOX', 
        POSTCODE: 'POSTCODE'
    },

    POSTAL_ADDRESS_TYPE_OPTIONS: [
        selectNullOption,
        { label: '', code: 'DELIVERY_ADDRESS', i18nKey: 'enums.addressTypeOptions.DELIVERY_ADDRESS' },
        { label: '', code: 'POST_OFFICE_BOX', i18nKey: 'enums.addressTypeOptions.POST_OFFICE_BOX' },
        { label: '', code: 'POSTCODE', i18nKey: 'enums.addressTypeOptions.POSTCODE' }
    ],

    BUSINESS_LOCATION_TYPES: {
        BUSINESS_PREMISES: 'BUSINESS_PREMISES',
        POST_OFFICE_BOX: 'BRANCH_OFFICE',
        BUSINESS_ADDRESS: 'BUSINESS_ADDRESS',
        STOREHOUSE: 'STOREHOUSE',
        BRANCH: 'BRANCH',
        ADMINISTRATIVE_OFFICE: 'ADMINISTRATIVE_OFFICE'
    },

    BUSINESS_LOCATION_TYPE_OPTIONS: [
        selectNullOption,
        { label: '', code: 'BUSINESS_PREMISES', i18nKey: 'selfcare.locationType.BUSINESS_PREMISES' },
        { label: '', code: 'BRANCH_OFFICE', i18nKey: 'selfcare.locationType.BRANCH_OFFICE' },
        { label: '', code: 'BUSINESS_ADDRESS', i18nKey: 'selfcare.locationType.BUSINESS_ADDRESS' },
        { label: '', code: 'STOREHOUSE', i18nKey: 'selfcare.locationType.STOREHOUSE' },
        { label: '', code: 'BRANCH', i18nKey: 'selfcare.locationType.BRANCH' },
        { label: '', code: 'ADMINISTRATIVE_OFFICE', i18nKey: 'selfcare.locationType.ADMINISTRATIVE_OFFICE' }
    ],
    LIMITED_MONITORING_MONTH_OPTIONS: [
        { label: '', code: 'JANUARY', i18nKey: 'enums.months.JANUARY' },
        { label: '', code: 'FEBRUARY', i18nKey: 'enums.months.FEBRUARY' },
        { label: '', code: 'MARCH', i18nKey: 'enums.months.MARCH' },
        { label: '', code: 'APRIL', i18nKey: 'enums.months.APRIL' },
        { label: '', code: 'MAY', i18nKey: 'enums.months.MAY' },
        { label: '', code: 'JUNE', i18nKey: 'enums.months.JUNE' },
        { label: '', code: 'JULY', i18nKey: 'enums.months.JULY' },
        { label: '', code: 'AUGUST', i18nKey: 'enums.months.AUGUST' },
        { label: '', code: 'SEPTEMBER', i18nKey: 'enums.months.SEPTEMBER' },
        { label: '', code: 'OCTOBER', i18nKey: 'enums.months.OCTOBER' },
        { label: '', code: 'NOVEMBER', i18nKey: 'enums.months.NOVEMBER' },
        { label: '', code: 'DECEMBER', i18nKey: 'enums.months.DECEMBER' },
    ]
}

export default CONSTANTS
