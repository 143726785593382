<template>
    <form @submit="onSubmit">
        <div class="row mb-small">
            <div class="col-12 mb-small">
                <p>
                    <span :data-qa="`selfcare-userdata-mailSurrogates-surrogateName-${index + 1}`">{{ thisSurrogate.surrogateMemberUserName }}</span>&nbsp;
                    <span :data-qa="`selfcare-userdata-mailSurrogates-surrogateEmail-${index + 1}`">({{ thisSurrogate.surrogateEmail }})</span><br> 
                    <span :data-qa="`selfcare-userdata-mailSurrogates-surrogateMemberUserId-${index + 1}`">{{ thisSurrogate.surrogateMemberUserId }}</span>
                </p>
            </div>
            <div class="col-12 col-md-6 mb-small">
                <Datepicker
                    :id="`selfcare-userdata-mailSurrogates-validFrom-${index + 1}`"
                    v-model="thisSurrogate.validFrom"
                    :name="`selfcare-userdata-mailSurrogates-validFrom-${index + 1}`"
                    :label="$t('profile.settings.surrogatesForm.fields.validFrom.label')"
                    :disabled="submitting"
                    :data-qa="`selfcare-userdata-mailSurrogates-validFrom-${index + 1}`"
                    required
                />
            </div>
            <div class="col-12 col-md-6">
                <Datepicker
                    :id="`selfcare-userdata-mailSurrogates-validTo-${index + 1}`"
                    v-model="thisSurrogate.validTo"
                    :name="`selfcare-userdata-mailSurrogates-validTo-${index + 1}`"
                    :label="$t('profile.settings.surrogatesForm.fields.validTo.label')"
                    :disabled="submitting"
                    :data-qa="`selfcare-userdata-mailSurrogates-validTo-${index+1}`"
                />
            </div>
        </div>
        <div class="row mb">
            <div class="col-12">
                {{ $t('profile.settings.surrogatesForm.activeLabel') }}: 
                <span 
                    v-if="thisSurrogate.active"
                    :data-qa="`selfcare-userdata-mailSurrogates-active-${index}-yes`"
                >{{ $t('globalMisc.yes') }}</span>
                <span 
                    v-else
                    :data-qa="`selfcare-userdata-mailSurrogates-active-${index}-no`"
                >{{ $t('globalMisc.no') }}</span>
            </div>
        </div> 
        <div class="row">
            <div class="col-12 col-md-2">
                <button 
                    class="btn btn-small btn-min btn-secondary"
                    :data-qa="`selfcare-userdata-mailSurrogates-delete-${index}`"
                    :disabled="submitting"
                    @click.prevent="deleteById(thisSurrogate.id)"
                >
                    {{ $t('profile.settings.surrogatesForm.btns.remove') }}
                </button>
            </div>
            <div class="col-12 col-md-3 offset-md-7 text-align-right-md">
                <button 
                    class="btn btn-default btn-small btn-min"
                    :disabled="submitting"
                    :data-qa="`selfcare-userdata-mailSurrogates-save-${index}`"
                >
                    {{ $t('profile.settings.surrogatesForm.btns.save') }}
                </button>
            </div>
        </div> 
    </form>
</template>

<script lang="ts" setup>
import { onMounted, toRef, ref, watch, computed } from 'vue'
import { useForm } from 'vee-validate'

import Datepicker from '@/components/Input/DatePicker.vue'

import { useSurrogatesStore } from '@/stores/surrogates'

import type { 
    CbraMailSurrogate, 
    CbraUpdateMailSurrogate 
} from '@/types/cbra/surrogates'
import { toTypedSchema } from '@vee-validate/zod'
import { object, date } from 'zod'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    surrogate: CbraMailSurrogate
    index: number
}>()

const { t } = useI18n()

const cbraSurrogatesStore = useSurrogatesStore()

const thisSurrogate = toRef(props, 'surrogate')

const formValidationSchema = computed(() => toTypedSchema(
    object({
        [`selfcare-userdata-mailSurrogates-validFrom-${props.index + 1}`]: date({ required_error: t('errors.required'), invalid_type_error: t('errors.required') }),
        [`selfcare-userdata-mailSurrogates-validTo-${props.index + 1}`]: date({ required_error: t('errors.required'), invalid_type_error: t('error.form.pattern') }).min(thisSurrogate.value.validFrom ? dayjs(thisSurrogate.value.validFrom).toDate() : dayjs().toDate(), t('selfcare.show.section.nutzerdaten.urlaubsvertretung.endDateBeforeStartDate')).nullable(),
    })
))

const {
    handleSubmit, 
    resetForm,
    validate 
} = useForm({ 
    validationSchema: formValidationSchema 
})

let submitting = ref(false)

const changeDate = () => {
    thisSurrogate.value.validFrom = new Date(thisSurrogate.value.validFrom)
    if (thisSurrogate.value.validTo !== null) {
        thisSurrogate.value.validTo = new Date(thisSurrogate.value.validTo)
    }
}

const deleteById = async(id: number) => {
    submitting.value = true
    await cbraSurrogatesStore.deleteMailSurrogateById(id)
    submitting.value = false

    resetForm()
}

const onSubmit = handleSubmit(async() => {
    submitting.value = true
    const payload: CbraUpdateMailSurrogate = {
        id: props.surrogate.id,
        surrogateCrid: props.surrogate.surrogateCrid,
        userCrid: props.surrogate.userCrid,
        validFrom: props.surrogate.validFrom,
        validTo: props.surrogate.validTo
    }
    await cbraSurrogatesStore.updateMailSurrogate(payload)
    submitting.value = false

    resetForm()
})

onMounted(changeDate)

watch(thisSurrogate.value, (value) => {
    if (value) {
        validate()
    }
})
</script>
