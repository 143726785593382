<template>
    <Hero
        :image="heroImage"
        :mobile-image="heroImageMobile"
    >
        <div class="row mb-large">
            <div class="col-12 col-md-12 col-xl-12">
                <h1 class="p0 marketing">
                    <span>{{ $t('AppName') }}</span>
                </h1>
            </div>
            <div class="col-12 col-md-12 col-xl-12">
                <h2 class="p0">
                    {{ $t('Homepage.hero.subline') }}
                </h2>
            </div>
            <div class="col-12 col-md-12 col-xl-12 mb-large">
                <button
                    data-qa="ce-header-"
                    class="btn btn-default"
                    @click.prevent="login"
                >
                    {{ $t('Homepage.hero.buttonText') }}
                </button>
            </div>
        </div>
    </Hero>
</template>

<script setup lang="ts">
import Hero from '@/components/Hero.vue'

import heroImage from '@/assets/img/homepage/header-meine-cr-1663x265.png'
import heroImageMobile from '@/assets/img/homepage/header-meine-cr-750x255.png'

const login = () => {
    const event = new Event('redirectToLogin')
    window.dispatchEvent(event)
}
</script>
