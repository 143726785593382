<template>
    <div
        :id="id"
        :data-page-id="id"
    >
        <Hero />

        <div class="container content-spacer">
            <div class="row mb">
                <div class="col-12 col-md-8 offset-md-2">
                    <h3>{{ $t("transferNow.title") }}</h3>
                    <p>{{ $t("transferNow.header") }}</p>
                    <div class="offset-md-1">
                        <ul class="list-unordered">
                            <li>{{ $t("transferNow.firstItem") }}</li>
                            <li>{{ $t("transferNow.secondItem") }}</li>
                            <li>{{ $t("transferNow.thirdItem") }}</li>
                            <li>{{ $t("transferNow.fourthItem") }}</li>
                            <li>{{ $t("transferNow.fifthItem") }}</li>
                        </ul>
                    </div>
                    <router-link to="/portal/mc/ueber-creditreform/meine-creditreform">
                        {{ $t("transferNow.learnMore") }}
                    </router-link>
                </div>
            </div>
        </div>

        <Register />

        <Contact />
    </div>
</template>

<script setup>
import { onMounted, onUpdated, watch } from 'vue'
// import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'

import Hero from '@/components/Teaser/TransferNowHero.vue'
import Contact from '@/components/Teaser/Contact.vue'
import Register from '@/components/Teaser/Register.vue'

// const router = useRouter()
const userStore = useUserStore()

const redirectAuthenticatedUserToDashboard = () => {
    if (userStore.authenticated) {
        // router.push({ name: 'dashboard' })
        location.assign('/?login=1')
    }
}

onMounted(redirectAuthenticatedUserToDashboard)
onUpdated(redirectAuthenticatedUserToDashboard)

watch(
    () => userStore.authenticated,
    () => {
        redirectAuthenticatedUserToDashboard()
    }
)
const id = 'portal.mc.transfer-now'
</script>

