<template>
    <div class="row">
        <div class="col-12">
            <div class="filter_box bg-white">
                <div class="row">
                    <div class="col-12">
                        <h4 data-qa="listfilter_report_title">
                            {{ $t('transaction.listfilter.headline.report') }}
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <Select
                            id="observationType"
                            v-model="filter.OBSERVATION_TYPE"
                            :label="$t('transaction.listfilter.label.observationType')"
                            :options="observationTypeOptions"
                            :searchable="false"
                            data-qa="listfilter_selectedReportObservationType"
                        />
                    </div>
                    <div class="col-12 col-md-6">
                        <div 
                            class="row" 
                            style="height:100%"
                        >
                            <div class="col-md-6 col-12 align-flex-end">
                                <Checkbox
                                    id="test"
                                    v-model="filter.EXPIRING_OBSERVATIONS"
                                    name="input-name"
                                    :label="$t('transaction.listfilter.label.selectReportObservationExpiring')"
                                    data-qa="listfilter_selectedReportObservationExpiring"
                                    error-message=""
                                    error-message-data-qa="test-error-data-qa"
                                />
                            </div>
                            <div class="col-md-6 col-12 align-flex-end">
                                <Checkbox
                                    id="test2"
                                    v-model="filter.EXPIRED_OBSERVATIONS"
                                    name="input-name2"
                                    :label="$t('transaction.listfilter.label.selectReportObservationExpired')"
                                    data-qa="listfilter_selectedReportObservationExpired"
                                    error-message=""
                                    error-message-data-qa="test-error-data-qa"
                                />
                            </div>
                        </div>
                    </div> 
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col-12">
                                <Select
                                    id="creationDate"
                                    v-model="filter.CREATION_DATE"
                                    :label="$t('transaction.listfilter.label.creationDateFrom')"
                                    :options="creationDateOptions"
                                    :searchable="false"
                                    data-qa="listfilter_selectedReportCreationDateFrom"
                                />
                            </div>
                            <div
                                v-if="filter.CREATION_DATE === 'CUSTOM'"
                                class="col-12 mb-small"
                            >
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <Datepicker
                                            id="dp1716888138332"
                                            v-model="filter.CREATION_DATE_START"
                                            :label="$t('transaction.listfilter.label.creationDateFrom.start')"
                                            data-qa="listfilter_selectedReportCreationDateFrom_start"
                                        />
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <Datepicker
                                            id="dp1716888138333"
                                            v-model="filter.CREATION_DATE_END"
                                            :label="$t('transaction.listfilter.label.creationDateFrom.end')"
                                            data-qa="listfilter_selectedReportCreationDateFrom_end"
                                            :disabled-date="(date) => filter.CREATION_DATE_START ? date < filter.CREATION_DATE_START || date > new Date() : date < new Date()"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col-12">
                                <Select
                                    id="modificationDate"
                                    v-model="filter.MODIFICATION_DATE"
                                    :label="$t('transaction.listfilter.label.modificationDateFrom')"
                                    :options="modificationDateOptions"
                                    :searchable="false"
                                    data-qa="listfilter_selectedReportModificationDateFrom"
                                />
                            </div>
                            <div
                                v-if="filter.MODIFICATION_DATE === 'CUSTOM'"
                                class="col-12"
                            >
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <Datepicker
                                            id="dp1716888138330"
                                            v-model="filter.MODIFICATION_DATE_START"
                                            :label="$t('transaction.listfilter.label.modificationDateFrom.start')"
                                            data-qa="listfilter_selectedReportModificationDateFrom_start"
                                        />
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <Datepicker
                                            id="dp1716888138331"
                                            v-model="filter.MODIFICATION_DATE_END"
                                            :label="$t('transaction.listfilter.label.modificationDateFrom.end')"
                                            data-qa="listfilter_selectedReportModificationDateFrom_end"
                                            :disabled-date="(date) => filter.MODIFICATION_DATE_START ? date < filter.MODIFICATION_DATE_START || date > new Date() : date < new Date()"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div 
                    v-if="transactionsStore.listFilter.activeReportFilters.value.length > 0"
                    class="row active-report-filters"
                >
                    <div class="col-12 col-md-9">
                        <FilterLabel
                            v-for="(activeFilter, index) in transactionsStore.listFilter.activeReportFilters.value"
                            :key="`activeReportFilters-${index}`"
                            :data-qa="`listfilter_report_reset_${activeFilter.filter}`"
                            @click="transactionsStore.listFilter.resetAction(activeFilter.filter)"
                        >
                            {{ activeFilter.label }}
                        </FilterLabel>
                    </div>
                    <div class="col-12 col-md-3 text-align-right">
                        <button 
                            class="btn btn-default btn-small btn-min" 
                            data-qa="listfilter_report_reset_button"
                            @click="transactionsStore.listFilter.reset"
                        >
                            {{ $t('transaction.listfilter.label.reset.button') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import Checkbox from '@/components/Input/Checkbox.vue'
import Select from '@/components/Input/Select.vue'
import Datepicker from '@/components/Input/DatePicker.vue'
import FilterLabel from '@/components/FilterLabel.vue'

import { useTransactionsStore } from '@/stores/transactions'
const transactionsStore = useTransactionsStore()

// type FilterSettings = {
//     observationType: 'ALL_OBSERVATION' | 'FREE_OBSERVATION' | 'NON_FREE_OBSERVATION' | 'WITHOUT_OBSERVATION' | null
//     observationExpirationType: 'EXPIRED_OBSERVATIONS' | 'EXPIRING_OBSERVATIONS' | null
//     reportModificationDateFrom: 'LAST_30_DAYS' | 'LAST_60_DAYS' | 'LAST_90_DAYS' | 'CURRENT_YEAR' | 'PREVIOUS_YEAR' | string | null
//     reportModificationDateTo: string | null
//     reportCreationDateFrom: 'LAST_30_DAYS' | 'LAST_60_DAYS' | 'LAST_90_DAYS' | 'CURRENT_YEAR' | 'PREVIOUS_YEAR' | string | null
//     reportCreationDateTo: string | null
// }

const observationTypeOptions = [
    { code: 'ALL', i18nKey: 'transaction.listfilter.observationType.ALL', label: '' },
    { code: 'ALL_OBSERVATION', i18nKey: 'transaction.listfilter.observationType.ALL_OBSERVATION', label: '' },
    { code: 'FREE_OBSERVATION', i18nKey: 'transaction.listfilter.observationType.FREE_OBSERVATION', label: '' },
    { code: 'NON_FREE_OBSERVATION', i18nKey: 'transaction.listfilter.observationType.NON_FREE_OBSERVATION', label: '' },
    { code: 'WITHOUT_OBSERVATION', i18nKey: 'transaction.listfilter.observationType.WITHOUT_OBSERVATION', label: '' }
]

const creationDateOptions = [
    { code: 'ALL', i18nKey: 'transaction.listfilter.creationDateFrom.ALL', label: '' }, 
    { code: 'LAST_30_DAYS', i18nKey: 'transaction.listfilter.creationDateFrom.LAST_30_DAYS', label: '' },
    { code: 'LAST_60_DAYS', i18nKey: 'transaction.listfilter.creationDateFrom.LAST_60_DAYS', label: '' },
    { code: 'LAST_90_DAYS', i18nKey: 'transaction.listfilter.creationDateFrom.LAST_90_DAYS', label: '' },
    { code: 'CURRENT_YEAR', i18nKey: 'transaction.listfilter.creationDateFrom.CURRENT_YEAR', label: '' },
    { code: 'PREVIOUS_YEAR', i18nKey: 'transaction.listfilter.creationDateFrom.PREVIOUS_YEAR', label: '' },
    { code: 'CUSTOM', i18nKey: 'transaction.listfilter.creationDateFrom.CUSTOM', label: '' }
]

const modificationDateOptions = [
    { code: 'ALL', i18nKey: 'transaction.listfilter.modificationDateFrom.ALL', label: '' },
    { code: 'LAST_30_DAYS', i18nKey: 'transaction.listfilter.modificationDateFrom.LAST_30_DAYS', label: '' },
    { code: 'LAST_60_DAYS', i18nKey: 'transaction.listfilter.modificationDateFrom.LAST_60_DAYS', label: '' },
    { code: 'LAST_90_DAYS', i18nKey: 'transaction.listfilter.modificationDateFrom.LAST_90_DAYS', label: '' },
    { code: 'CURRENT_YEAR', i18nKey: 'transaction.listfilter.modificationDateFrom.CURRENT_YEAR', label: '' },
    { code: 'PREVIOUS_YEAR', i18nKey: 'transaction.listfilter.modificationDateFrom.PREVIOUS_YEAR', label: '' },
    { code: 'CUSTOM', i18nKey: 'transaction.listfilter.modificationDateFrom.CUSTOM', label: '' }
]

const filter = ref({
    EXPIRED_OBSERVATIONS: transactionsStore.listFilter.selectedReportObservationExpireType.value === 'EXPIRED_OBSERVATIONS',
    EXPIRING_OBSERVATIONS: transactionsStore.listFilter.selectedReportObservationExpireType.value === 'EXPIRING_OBSERVATIONS',
    OBSERVATION_TYPE: transactionsStore.listFilter.selectedReportObservationType.value,
    CREATION_DATE: transactionsStore.listFilter.selectedReportCreationDateFrom.value,
    CREATION_DATE_START: transactionsStore.listFilter.selectedReportCreationDateFromStartDate.value,
    CREATION_DATE_END: transactionsStore.listFilter.selectedReportCreationDateFromEndDate.value,
    MODIFICATION_DATE: transactionsStore.listFilter.selectedReportModificationDateFrom.value,
    MODIFICATION_DATE_START: transactionsStore.listFilter.selectedReportModificationDateFromStartDate.value,
    MODIFICATION_DATE_END: transactionsStore.listFilter.selectedReportModificationDateFromEndDate.value
})

watch(() => filter.value, (newValue) => {
    if (newValue.EXPIRED_OBSERVATIONS === true && newValue.EXPIRING_OBSERVATIONS === true) {
        if (transactionsStore.listFilter.selectedReportObservationExpireType.value === 'EXPIRED_OBSERVATIONS') {
            transactionsStore.listFilter.setSelectedFilterParameter('observationExpirationType', 'EXPIRING_OBSERVATIONS', true)
        } else {
            transactionsStore.listFilter.setSelectedFilterParameter('observationExpirationType', 'EXPIRED_OBSERVATIONS', true)
        }
    } else {
        if (newValue.EXPIRED_OBSERVATIONS === true) {
            transactionsStore.listFilter.setSelectedFilterParameter('observationExpirationType', 'EXPIRED_OBSERVATIONS', true)
        } else if (newValue.EXPIRING_OBSERVATIONS === true) {
            transactionsStore.listFilter.setSelectedFilterParameter('observationExpirationType', 'EXPIRING_OBSERVATIONS', true)
        } else {
            transactionsStore.listFilter.setSelectedFilterParameter('observationExpirationType', null, true)
        }

        if (newValue.OBSERVATION_TYPE) {
            transactionsStore.listFilter.setSelectedFilterParameter('observationType', newValue.OBSERVATION_TYPE, true)
        }

        if (newValue.CREATION_DATE) {
            transactionsStore.listFilter.setSelectedFilterParameter('reportCreationDateFrom', newValue.CREATION_DATE, true)
            transactionsStore.listFilter.setSelectedFilterParameter('reportCreationDateTo', newValue.CREATION_DATE, true)
        }

        if (newValue.CREATION_DATE === 'CUSTOM' && newValue.CREATION_DATE_START) {
            transactionsStore.listFilter.setSelectedFilterParameter('reportCreationDateFrom', (newValue.CREATION_DATE_START), true)
        }

        if (newValue.CREATION_DATE === 'CUSTOM' && newValue.CREATION_DATE_END) {
            transactionsStore.listFilter.setSelectedFilterParameter('reportCreationDateTo', (newValue.CREATION_DATE_END), true)
        }

        if (newValue.MODIFICATION_DATE) {
            transactionsStore.listFilter.setSelectedFilterParameter('reportModificationDateFrom', newValue.MODIFICATION_DATE, true)
            transactionsStore.listFilter.setSelectedFilterParameter('reportModificationDateTo', newValue.MODIFICATION_DATE, true)
        }

        if (newValue.MODIFICATION_DATE === 'CUSTOM' && newValue.MODIFICATION_DATE_START) {
            transactionsStore.listFilter.setSelectedFilterParameter('reportModificationDateFrom', newValue.MODIFICATION_DATE_START, true)
        }

        if (newValue.MODIFICATION_DATE === 'CUSTOM' && newValue.MODIFICATION_DATE_END) {
            transactionsStore.listFilter.setSelectedFilterParameter('reportModificationDateTo', newValue.MODIFICATION_DATE_END, true)
        }
    }
}, { deep: true })
</script>

<style scoped lang="less">
.filter_box {
    margin: 10px 0 0;
    padding: 15px;

    h4 {
        margin-bottom: 15px;
        font-size: 1.1rem;
    }

    .active-report-filters {
        margin-top: 1rem;
    }

    .align-flex-end {
        align-self: flex-end;
    }
}
</style>