<template>
    <div
        :id="id"
        class="member-navigation custom-select"
        data-qa="member-navigation"
    >
        <slot />
        <label
            :id="id + '.label'"
            class="input-label"
        >
            {{ t('MemberNavigation.label') }}
        </label>
        <v-select
            :id="id + '.select'"
            v-model="input"
            :options="options"
            :reduce="reduceOption"
            :searchable="searchable"
            :filter="filterOptions"
            :clearable="false"
            :get-option-label="getOptionLabel"
            :data-qa="dataQa"
            :disabled="disabled"
            @option:selected="select"
        >
            <template #open-indicator="{ attributes }">
                <span v-bind="attributes" />
            </template>
            <template #selected-option="{ outputFirstLine, outputSecondLine }">
                <div style="display: flex; align-items: baseline; flex-direction: column">
                    <span
                        style="font-weight: bold;"
                        data-qa="output-first-line"
                    >{{ outputFirstLine }}</span>
                    <span>{{ outputSecondLine }}</span>
                </div>
            </template>
            <template #search="{ attributes, events }">
                <input
                    maxlength="1"
                    class="vs__search"
                    v-bind="attributes"
                    data-qa="comboBox-search-input"
                    v-on="events"
                >
            </template>
            <template #option="{ mainMember, outputFirstLine, outputSecondLine }">
                <div
                    style="display: flex; align-items: baseline; flex-direction: column"
                    data-qa="comboBox-list-item"
                >
                    <span
                        v-if="mainMember"
                        data-qa="comboBox-list-item-main-member"
                    >
                        {{ $t('MemberNavigation.upperMember') }}:
                    </span>
                    <span
                        style="font-weight: bold;"
                        data-qa="comboBox-list-item-first-line"
                    >
                        {{ outputFirstLine }}
                    </span>
                    <span data-qa="comboBox-list-item-second-line">{{ outputSecondLine }}</span>
                </div>
            </template>
            <template #no-options>
                <div />
            </template>
        </v-select>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, toRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMembersStore } from '@/stores/members'
import { EnhancedCbraMember } from '@/types/cbra/member'
import { useField } from 'vee-validate'
import { ROLES, hasUserRole } from '@/helper/services/user'
import {
    getMemberNavigationSession,
    removeMemberNavigationSession,
    getMembersForMe,
    prepareMemberListForMemberNavigation,
    differentContextActive
} from '@/helper/services/memberNavigation'

const { t } = useI18n()
const membersStore = useMembersStore()

const props = withDefaults(defineProps<{
id: string
modelValue: EnhancedCbraMember | undefined | null
options: EnhancedCbraMember[]
dataQa?: string
name?: string
disabled?: boolean
searchable?: boolean
}>(), {
    dataQa: 'userRightsMemberNavigation-selection',
    name: '',
    searchable: true,
    disabled: false,
})

const name = toRef(props, 'id')
const {
    value: input,
} = useField(name, undefined, {
    initialValue: toRef(() => props.modelValue).value,
    syncVModel: true,
})

const emit = defineEmits<{
(e: 'option:label', value: string | boolean | number): void
(e: 'update:modelValue', value: EnhancedCbraMember): void
(e: 'change', value: EnhancedCbraMember): void
}>()

function reduceOption(option: EnhancedCbraMember) {
    return option.displayName
}

function getOptionLabel(option: EnhancedCbraMember) {
    return option.displayName
}

function filterOptions(options: EnhancedCbraMember[], search: string) {
    return options.filter(option => {
        const labels = [option.outputFirstLine, option.outputSecondLine, option.memberId]
        return labels.some(label => filterBy(label, search))
    })
}

function filterBy(label: string, search: string) {
    return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
}

async function checkPermissionForMemberNavigation() {
    const permission = hasUserRole(ROLES.UNTERNEHMENSNAVIGATION)
    if (permission && differentContextActive()) {
        const response = await getMembersForMe(true)
        membersStore.cbraMember.memberId = response?.memberId || ''
        if (response && response.members) {
            const callFromSUMainMember = response.members?.mainMember?.callFromSUMainMember || false
            const preparedMembers = prepareMemberListForMemberNavigation(response.members, callFromSUMainMember)
            if (preparedMembers.length > 0) {
                membersStore.memberList = preparedMembers
            }
        }
    } else {
        if (getMemberNavigationSession()) {
            removeMemberNavigationSession()
            window.location.href = '/?revoked=true'
        }
    }
}

onMounted(async() => {
    await checkPermissionForMemberNavigation()
    props.options.forEach((option) => {
        if (option.memberId === input.value?.memberId) {
            select(option)
        }
    })
})

async function select(option: EnhancedCbraMember) {
    await checkPermissionForMemberNavigation()
    emit('change', option)
    emit('update:modelValue', option)
}

defineExpose({ input, select })
</script>

<style scoped>
label {
    margin-bottom: 12px;
    line-height: 26px;
}
.has-error {
.input-label {
    color: var(--color-error);
}
}
</style>

<style lang="less">
:deep(.member-navigation) {
    &.custom-select .v-select {
        background: var(--color-c-white);
    }
    &.custom-select .vs__dropdown-toggle {
        border-radius: 0;
        border-color: #b5b5b5;
        padding: 1rem 1.2rem;
        line-height: 24px;
        max-height: 56px;
    }

    &.custom-select .vs__selected {
        margin: 0;
    }

    &.custom-select .vs__selected-options {
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
        width: fit-content;
        white-space: nowrap;
    }

    &.custom-select .vs__search,
    &.custom-select .vs__search:focus {
        margin-top: 0;
    }

    &.custom-select .vs__actions {
        padding: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
        right: 1.2rem;
        align-self: center;
    }

    &.custom-select .vs__actions:after {
        content: "\e909";
        font-family: crefo-ui-icons, sans-serif !important;
        font-size: 24px;
        display: block;
        position: absolute;
        color: #b5b5b5;
        transform: rotate(0);
        transition: all .15s ease-in-out;
    }

    &.custom-select .vs__actions:hover:after {
        color: #009ee2;
    }

    &.custom-select .vs__dropdown-toggle:hover .vs__actions:after {
        color: #009ee2;
    }

    &.custom-select .vs__dropdown-toggle:hover,
    &.custom-select .vs--open .vs__dropdown-toggle {
        border-color: #009ee2;
    }

    &.custom-select .vs__dropdown-toggle {
        max-height: none;
    }

    &.custom-select .vs--open .vs__actions:after {
        transform: rotate(180deg);
    }

    &.custom-select .vs__dropdown-option {
        white-space: normal;
        border: 1px solid transparent;
        border-color: transparent transparent rgba(0, 0, 0, .1) transparent;
        font-size: 15px;
        font-weight: 400;
        line-height: 26px;
        padding: 1rem 1.2rem;
    }

    &.custom-select .vs__dropdown-option:last-child {
        border: 0;
    }

    &.custom-select .vs__dropdown-menu {
        padding-bottom: 0;
        padding-top: 0;
        border-radius: 0;
        border: 1px solid #009ee2;
        border-top: 1px solid #ccc;
        box-shadow: none;
    }

    &.custom-select .vs__dropdown-option--selected {
        background: #009ee2;
        color: #fff;
    }
    &.custom-select .vs__dropdown-option--highlight {
        background: #009ee2;
    }

    &.custom-select {
        margin-bottom: 15px;
    }

    .error-msg {
        color: #e05469;
        line-height: 20px;
        font-size: 14px;
        padding-top: 10px;
        margin-bottom: 0;
    }

    .has-error .vs__dropdown-toggle {
        border-color: #e05469;
    }

    .help-text {
        font-size: 12px;
        color: var(--c-grey-2);
        margin-top: 5px;
    }
}
</style>
