<template>
    <div
        v-if="!isLoading"
        class="col-12 col-md-5 userrights-col"
        data-qa="vertical-tabs-list"
    >
        <div>
            <ul
                v-if="!showNoResult && users.length"
                class="userrights-list"
                data-qa="vertical-tabs-list-list"
            >
                <li
                    v-for="user in users"
                    :id="user.memberUserId"
                    :key="user.memberUserId"
                    :class="['userrights-list-item', {'active-tab': activeTab === user.memberUserId}]"
                    data-qa="tab in-box-messageListEntry"
                    @click="selectTab"
                >
                    <div
                        v-if="user.email"
                        class="userrights-list-item-container"
                        :class="{'locked-user': user.locked}"
                    >
                        <span
                            class="user-name"
                            data-qa="user-name UserItemName"
                        >
                            {{ user.familyName }}, {{ user.givenName }}
                        </span>
                        <span
                            class="email"
                            data-qa="user-email UserItemEmail"
                        >
                            {{ `E-Mail: ${user.email}` }}
                        </span>
                        <span
                            v-if="user.locked"
                            class="bubble-locked"
                        >
                            {{ $t('UserRights.status.locked') }}
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, PropType } from 'vue'
import type { CbraUser } from '@/types/cbra/user'

const emit = defineEmits(['selectUser'])
const props = defineProps({
    users: {
        type: Array as PropType<Array<CbraUser>>,
        required: true
    },
    selectFirstTabOnMount: {
        type: Boolean,
        default: true
    },
    showNoResult: {
        type: Boolean,
        default: false
    },
    isLoading: {
        type: Boolean,
        default: false
    }
})

const activeTab = ref<string | null>(null)

function selectTab(event: Event): void {
    const selectedMemberUserID = (event.currentTarget as HTMLElement).id
    activeTab.value = selectedMemberUserID
    const selectedUser = props.users.find(user => user.memberUserId === selectedMemberUserID)
    emit('selectUser', selectedUser)
}

function selectFirstTab(): void {
    const firstUser = props.users?.[0] || null
    if (firstUser) {
        activeTab.value = firstUser.memberUserId
        emit('selectUser', firstUser)
    }
}

onMounted(() => {
    if (props.selectFirstTabOnMount) {
        selectFirstTab()
    }
})
</script>

<style scoped>
.userrights-col {
    position: relative;
    padding-right: 0;
    padding-left: 0;
    height: 700px;
    overflow-x: hidden;
}

.userrights-list {
    min-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

.userrights-list-item {
    display: block;
    min-width: 100%;
    width: 100%;
    padding: 15px;
    margin-bottom: 0;
    background-color: white;
    cursor: pointer;
    border-bottom: 1px solid #d1d1d1;

    &:hover:not(.active-tab) {
        background-color: var(--color-c-t-lightblue);
    }

    &.active-tab span {
        color: white;
    }
}

.user-name {
    display: block;
    color: var(--color-c-p1);
    font-size: 1.2rem;
    font-weight: 700;
    white-space: normal;
    margin-bottom: 5px;
}

.email {
    color: #183f6d;
}

.active-tab {
    background-color: var(--color-c-p1);
}

.locked-user {
    cursor: default;

    .user-name, .email {
        opacity: .6;
    }
}

.bubble-locked {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-top: 10px;
    background: var(--color-c-t2);
    color: white;
    border-radius: 3px;
    padding: 2px 5px 3px;
    font-size: 85%;
}
</style>
